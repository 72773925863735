import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import { getCookie, getDomainInfo } from '../../Helpers/basic';

export const mockId = {
  id: 7
}

axios.interceptors.request.use(
  config => {
    const token = getCookie('hosting_token');
    if (token) {
      config.headers.Authorization = `Token ${token}`;
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  error => Promise.reject(error)
);

export const addingCourse = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.addCourseWelcome}`
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const uploadScormCourse = (formData, courseType, successCallBack, failureCallBack) => {

  const domainInfo = getDomainInfo();
  const token = getCookie(domainInfo.domainTokenLabel);
  const auth = `Token ${token}`;
  // const url = `${apiConfig.addingCourse.uploadScorm}`


  let url = `${apiConfig.addingCourse.uploadXapiCourse}?courseType=${courseType}`
  if (courseType === 'scorm') {
    url = `${apiConfig.addingCourse.uploadScormCourse}`
  }
  console.log(formData)



  axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      console.log(percentCompleted)
      successCallBack(percentCompleted)
    }
  })
    .then(function (response) {
      successCallBack('Successfully Deployed');
    })
    .catch(function (error) {
      failureCallBack(error)
    });

  const configureScorm = (fileName) => {
    const domainInfo = getDomainInfo();
    const token = getCookie(domainInfo.domainTokenLabel);
    const auth = `Token ${token}`;
    var eventSource = new EventSource(`${apiConfig.addingCourse.uploadNodeScormStatus}?&fileName=${fileName}&auth=${auth}`);

    eventSource.onmessage = (e) => {
      const msg = JSON.parse(e.data).status;
      console.log('---in on message---');
      console.log(e);
      successCallBack(msg)
      if (msg === 'Successfully Deployed') {
        closeEventSourse();
      }
    }
    eventSource.addEventListener('error', function (e) {
      console.log('---in on eventSource error ---222---');
      console.log(e)
      let state = ''
      if (e.eventPhase == EventSource.CLOSED) {
        state = 'Closed'
        eventSource.close()
      }
      if (e.target.readyState == EventSource.CLOSED) {
        state = "Disconnected"
      }
      else if (e.target.readyState == EventSource.CONNECTING) {
        state = "Connecting..."
      }
      console.log(state)
    })
    function closeEventSourse() {
      eventSource.close();
    }
  }

  /*
  // var eventSource = new EventSource(`${apiConfig.addingCourse.uploadNodeScormStatus}`);
  function startEventSourse() {
    eventSource.onmessage = (e) => {
      console.log('---in on message---');
      console.log(e);
    }
  }

  function closeEventSourse() {
    eventSource.close();
  }
  */
}

export const updatingCourse = (payLoad, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.updatingCourseWelcome}${payLoad.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const courseDetailsFetch = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.courseDetailsfetch}`
  axios.get(url, {
    params: payLoad,
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const courseCategory = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.CourseCategory}`
  axios.get(url, {
    params: payLoad,
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchCourseTagsList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.fetchCourseTags}`
  axios.get(url, {
    params: payload,
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchCourseLeftMenu = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.fetchCourseLeftMenu}`
  axios.get(url, {
    params: payLoad,
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const publishCourse = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.coursePublish.publishCourse}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const submitForReview = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.coursePublish.submitReview}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchDepartments = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.coursePublish.fetchDepartments}`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchTeamsList = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.coursePublish.fetchTeamsList}?reporting_members=false`
  axios.get(url)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR MANDATING THE COURSE
export const mandateCourseTo = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.coursePublish.mandateCourseTo}`
  axios.post(
    url,
    payLoad
  )
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING THE DOCS LIBRARY DATA FOR LINK DOCUMENTS/VIDEO
export const fetchDocsLibraryFiles = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.fetchDocsLibraryFiles}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR SEARCH QUERY DOCS FILES
export const searchDocsLibraryFiles = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.searchDocsLibraryFiles}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error.data)
    })
}


const mockDesignations = {
  "results": [
    { id: 1, name: 'Backend developer' },
    { id: 2, name: 'Frontend developer' },
    { id: 3, name: 'AI Engineer' },
    { id: 4, name: 'Product Manager' },
    { id: 5, name: 'Machine Learning Developer' },
    { id: 6, name: 'Product Owner' },
    { id: 7, name: 'Quality Engineer' },
    { id: 8, name: 'Administrative Assistant' }
  ]
}

//FOR FETCHING THE ORG DESIGNATIONS LIST
export const fetchOrgDesignations = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addingCourse.fetchOrgDesignations}`
  axios.get(url, { params: payload })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockDesignations)
    })
}

//FOR FETCHING THE ORG DESIGNATIONS LIST
export const updateCourseAudience = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.coursePublish.publishCourse}`
  axios.patch(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
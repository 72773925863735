import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { getCourseOpenModeType } from '../../Helpers/basic';

function Header(props) {
  const courseInfo = props.courseInfo
  const moduleInfo = props.moduleInfo
  const courseData = props.courseDetails
  const assignmentDetails = props.assignmentDetails
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  const navigateToCourseLevel = () => {
    if (props.match.params.lessonId) {
      if (courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode') {
        return (
          <div>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}?${base64EncodeValue}`}>
                {courseInfo && courseInfo.title && courseInfo.title.length > 20 ?
                  (
                    'Course : ' + courseInfo.title.substr(0, 25) + '...'
                  ) : (
                    'Course : ' + courseInfo.title
                  )}
              </Link>
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}?${base64EncodeValue}`}>
                {moduleInfo && moduleInfo.title && moduleInfo.title.length > 20 ?
                  (
                    'Module : ' + moduleInfo.title.substr(0, 25) + '...'
                  ) : (
                    'Module : ' + moduleInfo.title
                  )}
              </Link>
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}?${base64EncodeValue}`}>
                {courseData && courseData.title && courseData.title.length > 20 ?
                  (
                    'Lesson : ' + courseData.title.substr(0, 25) + '...'
                  ) : (
                    'Lesson : ' + courseData.title
                  )}
              </Link>
            </Breadcrumbs>
          </div>
        )
      } else {
        return (
          <div>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}`}>
                {courseInfo && courseInfo.title && courseInfo.title.length > 20 ?
                  (
                    'Course : ' + courseInfo.title.substr(0, 25) + '...'
                  ) : (
                    'Course : ' + courseInfo.title
                  )}
              </Link>
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}`}>
                {moduleInfo && moduleInfo.title && moduleInfo.title.length > 20 ?
                  (
                    'Module : ' + moduleInfo.title.substr(0, 25) + '...'
                  ) : (
                    'Module : ' + moduleInfo.title
                  )}
              </Link>
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}`}>
                {courseData && courseData.title && courseData.title.length > 20 ?
                  (
                    'Lesson : ' + courseData.title.substr(0, 25) + '...'
                  ) : (
                    'Lesson : ' + courseData.title
                  )}
              </Link>
            </Breadcrumbs>
          </div>
        )
      }
    }
    else if (props.match.params.moduleId) {
      if (courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode') {
        return (
          <div>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}?${base64EncodeValue}`}>
                {courseInfo && courseInfo.title && courseInfo.title.length > 20 ?
                  (
                    'Course : ' + courseInfo.title.substr(0, 25) + '...'
                  ) : (
                    'Course : ' + courseInfo.title
                  )}
              </Link>
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}/${courseData.id}?${base64EncodeValue}`}>
                {courseData && courseData.title && courseData.title.length > 20 ?
                  (
                    'Module : ' + courseData.title.substr(0, 25) + '...'
                  ) : (
                    'Module : ' + courseData.title
                  )}
              </Link>
            </Breadcrumbs>
          </div>
        )
      } else {
        return (
          <div>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}`}>
                {courseInfo && courseInfo.title && courseInfo.title.length > 20 ?
                  (
                    'Course : ' + courseInfo.title.substr(0, 25) + '...'
                  ) : (
                    'Course : ' + courseInfo.title
                  )}
              </Link>
              <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${props.match.params.courseId}/${courseData.id}`}>
                {courseData && courseData.title && courseData.title.length > 20 ?
                  (
                    'Module : ' + courseData.title.substr(0, 25) + '...'
                  ) : (
                    'Module : ' + courseData.title
                  )}
              </Link>
            </Breadcrumbs>
          </div>
        )
      }
    }
    else if (props.match.params.courseId) {
      if (courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode') {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${courseData.id}?${base64EncodeValue}`}>
            {courseData && courseData.title && courseData.title.length > 20 ?
              (
                'Course : ' + courseData.title.substr(0, 25) + '...'
              ) : (
                'Course : ' + courseData.title
              )}
          </Link>
        )
      } else if (courseOpenedMode === 'from_mycourses') {
        return (
          <div color="textPrimary">
            {courseData && courseData.title && courseData.title}
          </div>
        )
      } else {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${courseData.id}`}>
            {courseData && courseData.title && courseData.title.length > 20 ?
              (
                'Course : ' + courseData.title.substr(0, 25) + '...'
              ) : (
                'Course : ' + courseData.title
              )}
          </Link>
        )
      }
    }
  }

  const navigateToAssignmentLevel = () => {
    if (props.match.params.lessonId) {
      if (base64EncodeValue) {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/lessonassignment/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}?${base64EncodeValue}`}>
            {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
              (
                props.assignmentDetails.title.substr(0, 25) + '...'
              ) :
              props.assignmentDetails.title
            }
          </Link>
        )
      } else {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/lessonassignment/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}`}>
            {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
              (
                props.assignmentDetails.title.substr(0, 25) + '...'
              ) :
              props.assignmentDetails.title
            }
          </Link>
        )
      }
    }
    else if (props.match.params.moduleId) {
      if (base64EncodeValue) {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/moduleassignment/${props.match.params.courseId}/${courseData.id}?${base64EncodeValue}`}>
            {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
              (
                props.assignmentDetails.title.substr(0, 25) + '...'
              ) :
              props.assignmentDetails.title
            }
          </Link>
        )
      } else {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/moduleassignment/${props.match.params.courseId}/${courseData.id}`}>
            {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
              (
                props.assignmentDetails.title.substr(0, 25) + '...'
              ) :
              props.assignmentDetails.title
            }
          </Link>
        )
      }
    }
    else if (props.match.params.courseId) {
      if (base64EncodeValue) {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/assignment/${courseData.id}?${base64EncodeValue}`}>
            {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
              (
                props.assignmentDetails.title.substr(0, 25) + '...'
              ) :
              props.assignmentDetails.title
            }
          </Link>
        )
      } else {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/assignment/${courseData.id}`}>
            {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
              (
                props.assignmentDetails.title.substr(0, 25) + '...'
              ) :
              props.assignmentDetails.title
            }
          </Link>
        )
      }
    } else if (props.match.params.assignmentId) {
      return (
        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/externalassignments`}>
          {props.assignmentDetails && props.assignmentDetails.title && props.assignmentDetails.title.length > 20 ?
            (
              props.assignmentDetails.title.substr(0, 25) + '...'
            ) :
            props.assignmentDetails.title
          }
        </Link>
      )
    }
  }

  return (
    <Route>
      {
        (
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
            <Link style={{ textDecoration: 'none' }} color="inherit" to="/mycourse">
              Home
            </Link>
            {navigateToCourseLevel()}

            {assignmentDetails && assignmentDetails.title ? (
              navigateToAssignmentLevel()
            ) :
              (
                <div color="textPrimary">
                  Assignment
                </div>
              )
            }
            {assignmentDetails && assignmentDetails.title ? (
              <div color="textPrimary">
                Assignment Report
              </div>
            ) : null}
          </Breadcrumbs>
        )
      }
    </Route>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(Header);
import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function helpViewDialog(props) {
  const openPopup = props.openHelpImageView
  return (
      <Dialog
        open={openPopup}
        onClose={props.closeHelpView}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="title"
        aria-describedby="description"
      >
        {/* <Button style={{justifyContent: 'flex-end', marginBottom: '-58px'}} onClick={props.closeHelpView} color="primary">
          <CloseIcon style={{ fontSize: 40}}/>
        </Button> */}
        <img style={{ width: '600px', height: '155px' }} src={props.imgUrl} alt="helpView"/>
      </Dialog>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(helpViewDialog);
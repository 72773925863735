import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Header from './header'
import AddDiscussion from './addDiscussion'
import DiscussionList from './discussionListView'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'
import { getDomainInfo } from '../../Helpers/basic';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_FETCHING_DISCUSSIONS } from '../Common/Constants/errorMessages';
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator'
import { listDiscussions } from '../../store/Discussions/actionCreator'
//FOR FINDING THE CO WORKERS OF THIS COURSE
import { postCourseWorkingUsersInfo } from '../../store/Authors/actionCreator'
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 65
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  breadCrumbcontainer: {
    padding: '15px',
    marginLeft: '10px',
    justifyContent: "center",
    marginTop: '10px'
  },
  button: {
    flexGrow: 1,
    marginTop: '10px',
    textTransform: 'none',
    backgroundColor: '#2e8eec',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2e8eec',
    },
  },
}))

function CourseLevelDiscussions(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const [courseDetails, setCourseDetails] = React.useState({})
  const [discussionList, setDiscussionList] = React.useState({})
  const [addDiscussionMode, setAddDiscussionMode] = React.useState(false)
  const [inProgress, setInprogress] = React.useState(false)
  const courseOpenedMode = getCourseOpenModeType();

  const successCallBack = res => {
    setDiscussionList(res.results)
    setInprogress(false)
  }

  const failureCallBack = error => {
    setDiscussionList({})
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_DISCUSSIONS,
        type: 'error'
      }
    )
  }

  const listAllDiscussions = () => {
    setInprogress(true)
    const payload = {
      course_id: props.match.params.courseId
    }
    listDiscussions(payload, successCallBack, failureCallBack)
  }

  const courseSuccessCallBack = res => {
    setCourseDetails(res.results)
    listAllDiscussions()
  }

  const courseFailureCallBack = error => {
    setCourseDetails({})
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_DISCUSSIONS,
        type: 'error'
      }
    )
  }

  //FOR FETCHING USER INFO WHO ARE WORKING ON COURSE MODULES CALLBACKS
  const successPostUserInfo = res => { }
  const failurePostUsersInfo = err => { }

  useEffect(() => {
    setInprogress(true)
    courseDetailsFetch({
      id: props.match.params.courseId
    }, courseSuccessCallBack, courseFailureCallBack)

    //FOR POSTING THE USERS WHO ARE WORKING ON SAME COURSE
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        url: window.location.href,
        id: props.match.params.courseId,
        type: 'discussions',
        course_id: props.match.params.courseId,
      }
      postCourseWorkingUsersInfo(payload, successPostUserInfo, failurePostUsersInfo)
    }
  }, [])

  const triggerAddDiscussion = () => {
    setAddDiscussionMode(true)
  }

  const cancelAddDiscussion = () => {
    setAddDiscussionMode(false)
  }

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 4; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.topButtonNav}>
        <div className={classes.breadCrumbcontainer}>
          <Grid container>
            <Header match={props.match} history={props.history} courseDetails={courseDetails} />
          </Grid>
        </div>
      </Paper>
      {inProgress ? (
        listLoader()
      ) : (
        <React.Fragment>
          {addDiscussionMode ? (
            <AddDiscussion match={props.match} history={props.history} cancelAddDiscussion={cancelAddDiscussion} listAllDiscussions={listAllDiscussions} />
          ) : (
            <React.Fragment>
              {courseOpenedMode !== 'visit_mode' && discussionList && discussionList.length ?
                (
                  <div style={{ textAlign: 'right', marginRight: '21px' }}>
                    <Button
                      variant="contained"
                      aria-label="add discussion"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={triggerAddDiscussion}
                    >
                      Discussion
                    </Button>
                  </div>
                ) : null}
            </React.Fragment>
          )}
          {discussionList && !addDiscussionMode ? (
            <React.Fragment>
              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <h1>Course level discussions listing view</h1>
              </div>
              <DiscussionList discussionList={discussionList} history={props.history} match={props.match} listAllDiscussions={listAllDiscussions} triggerAddDiscussion={triggerAddDiscussion} />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )}

    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(CourseLevelDiscussions);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { fetchAssessmentReport } from '../../store/GradeBook/actionCreator';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_FETCHING_ASSESSMENTS } from '../Common/Constants/errorMessages';
import { style } from '../../Styles/theme';

const useStyles = makeStyles(theme => ({
    score: {
        color: style.fontColour._blue,
        fontSize: "1.5rem",
        padding: '0.4rem 0 0 0',
        textAlign: 'center',
        //display: 'flex'
    },
    percentile: {
        color: style.fontColour._green,
        fontSize: "2rem",
        padding: '0 0 0 0.5rem',
        fontWeight: 600
    },
    rprtHeader: {
        backgroundColor: '#fff',
    },
    crctIcon: {
        color: style.fontColour._green,
        fontSize: '1.5rem',
    },
    wrngIcon: {
        color: style.fontColour._red,
        fontSize: '1.5rem',
    },
    resultGrid: {
        backgroundColor: "#fff"
    },
    correctAns: {
        padding: '0.5rem 0 0 0',
        color: style.fontColour._darkBlue,
        fontSize: style.fontSize._sideHeading,
        fontFamily: style.fontFamily._appFont
    },
    resultStatus: {
        color: style.fontColour._green,
        paddingTop: '0.2rem',
        fontSize: style.fontSize._sideHeading,
        fontFamily: style.fontFamily._appFont
    },
    ansLabel: {
        padding: '0 1rem 0 0',
        color: style.fontColour._green,
        fontSize: style.fontSize._sideHeading,
        fontFamily: style.fontFamily._appFont
    },
    resultIcon: {
        paddingLeft: '0.5rem'
    },
    resultContainer: {
        padding: '1rem 1rem 1rem 1rem',
    },
    root: {
        width: '100%',
    },
    card: {
        padding: '1rem'
    },
    title: {
        color: style.fontColour._darkBlue,
        fontSize: style.fontSize._heading,
        textAlign: 'center',
        fontFamily: style.fontFamily._appFont
    },
    container: {
        paddingTop: '4rem'
    },
    questionNo: {
        padding: '0 0 0 2rem',
        color: style.fontColour._darkBlue,
        fontSize: '1rem',
    },
    label: {
        padding: '1rem 0 0 2rem',
        color: style.fontColour._blue,
        fontSize: '1.2rem',
    },
    scenario: {
        padding: '0.5rem 0 0 2rem',
        color: style.fontColour._darkBlue,
        fontSize: '1rem',
    },
    assmntDesc: {
        padding: '0.5rem 0 1rem 2rem',
        color: style.fontColour._darkBlue,
        fontSize: '1rem',
        textAlign: 'justify'
    },
    assmntTitle: {
        padding: '0.5rem 0 1rem 2rem',
        color: style.fontColour._darkBlue,
        fontSize: '2rem'
    },
    question: {
        padding: '0.5rem 0 0 2rem',
        color: style.fontColour._darkBlue,
        fontSize: '1rem',
    },
    radioOptions: {
        padding: '0.5rem 0 0 2rem',
        color: style.fontColour._blue,
        fontSize: '1rem',
    },
    checkOptions: {
        padding: '0.5rem 0 0 2rem',
        color: style.fontColour._blue,
        fontSize: '1rem',
    },
    textArea: {
        padding: '0.5rem 0 0 2rem',
        color: style.fontColour._blue,
        fontSize: '1rem',
        width: '70%'
    },
    textAreaAnswer: {
        padding: '0.5rem 0 0 0',
        color: style.fontColour._darkBlue,
        fontSize: '1rem',
        width: '90%'
    }
}));

function Report(props) {
    const classes = useStyles();
    const [assessmentSummary, setAssessmentSummary] = useState([]);
    const successCallBack = res => {
        setAssessmentSummary(res.results);
    }
    const failureCallBack = error => {
        props.showSnackBar(
            {
                state: false,
                message: ERROR_FETCHING_ASSESSMENTS,
                type: 'error'
            }
        )
    }
    useEffect(() => {
        let assessmentData = props && props.data && props.data.assessmentData && Object.keys(props.data.assessmentData) && Object.keys(props.data.assessmentData).length && props.data.assessmentData
        fetchAssessmentReport({
            user_id: props.data && props.data.studentData && props.data.studentData.user_id,
            assessment_id: assessmentData && assessmentData.assessment_id
        }, successCallBack, failureCallBack);
    }, [])
    const renderAnswerContainer = (type, options, answer) => {
        if (type === 'objective') {
            return (
                <FormControl component="fieldset">
                    <RadioGroup aria-label="answers" value={answer.toString()}>

                        {options.map((item, index) => {
                            return (<FormControlLabel key={index} className={classes.radioOptions} value={item.id} control={<Radio color="secondary" />} label={`${item.option}`} />)
                        })
                        }
                    </RadioGroup>
                </FormControl>
            )
        }
        else if (type === 'checkbox') {
            return (
                <FormControl component="fieldset">
                    <Grid container>
                        {options.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={12} md={4} lg={4}>

                                    <FormControlLabel
                                        value={index}
                                        control={<Checkbox id={`check_${index}`} color="secondary" checked={answer.indexOf(index) > -1} />}
                                        label={`${item.option}`}
                                        labelPlacement="end"
                                        className={classes.checkOptions}
                                    />
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </FormControl>
            )
        }
        else if (type === 'text') {
            return (
                <div className={classes.textArea}>Ans: {answer}</div>
            )
        }
    }
    const renderCard = () => {
        let dataArr = [];
        assessmentSummary.forEach((item, index) => {
            let checkBoxAns = [], answer = [], checkBoxOpt = [];
            if (item.type === 'checkbox') {
                let selectedCorrectAnswers = item.user_answer;
                let formatedCorrectAnswers = [];
                for (let i in selectedCorrectAnswers) {
                    formatedCorrectAnswers.push(Number(selectedCorrectAnswers[i]))
                }
                answer = formatedCorrectAnswers.toString();
                if (item.correct_answer.length > 0) {
                    item.correct_answer && item.correct_answer.forEach((iter, index) => {
                        checkBoxAns.push(iter);
                        /*eslint radix: ["error", "as-needed"]*/
                        checkBoxOpt.push(item.options[parseInt([iter])].option)
                    })
                    checkBoxAns = checkBoxAns.toString();
                }
            } else if (item.type === 'objective') {
                answer = item.user_answer
            }
            dataArr.push(
                <React.Fragment key={index}>
                    <br />
                    <Card className={classes.card}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={7} lg={8}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.questionNo}>Question: {index + 1} of {assessmentSummary.length}</div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    {item.scenario && <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.label} >Scenario</div>
                                        <div className={classes.scenario}>{item.scenario}</div>
                                    </Grid>
                                    }
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className={classes.label}>Question</div>
                                        <div className={classes.question}>{item.question} </div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {
                                            renderAnswerContainer(item.type, item.options, answer)
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                <div style={{ display: 'flex', paddingLeft: '1rem' }}>
                                    <div className={classes.resultStatus}>
                                        Your answer is :
                                    </div>
                                    <div className={classes.resultIcon}>
                                        {item.iscorrect && item.iscorrect ? <CheckCircleIcon className={classes.crctIcon} /> : <CancelIcon className={classes.wrngIcon} />}
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '1rem' }}>
                                    {item.type !== 'text' &&
                                        <React.Fragment>
                                            <div className={classes.ansLabel}>
                                                Correct Answer:
                                            </div>
                                            <div className={classes.correctAns}>
                                                {item.correct_answer && item.type === 'checkbox' ? checkBoxOpt.toString() : null}
                                                {item.correct_answer && item.type === 'objective' ? item.options[parseInt(answer)].option : null}
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </React.Fragment >
            )
        })
        return dataArr;

    }
    return (
        <Dialog
            open={true}
            onClose={props.onClose}
            fullWidth
            maxWidth='xl'
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            aria-label="quiz report dialog"
        >
            <DialogTitle id="scroll-dialog-title">Quiz Report</DialogTitle>
            <DialogContent dividers={true} className={classes.reportContainer}>
                <Grid container className={classes.rprtHeader}>
                    <Grid item lg={9} md={9} sm={12} xs={12} >
                        <div className={classes.assmntTitle}>{props.data.assessmentData.assessment_name}</div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}  >
                        <div className={classes.score} >Your Score <div className={classes.percentile}>{props.data.assessmentData.assessment_grade}%</div> </div>
                    </Grid>
                </Grid>
                {assessmentSummary.length && renderCard()}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {}
}

export default connect(
    mapStateToProps, { showSnackBar }
)(Report);

import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImgprvDialog(props) {
  const openPopup = props.openImgPrvDialog
  return (
      <Dialog
        style={{height: '400px', marginTop: '100px'}}
        fullWidth={true}
        maxWidth="xl"
        open={openPopup}
        onClose={props.closeUploadedBannerImagePopup}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="title"
        aria-describedby="description"
      >
        {/* <Button style={{ }} onClick={props.closeUploadedBannerImagePopup} color="primary">
          <CloseIcon style={{ fontSize: 40}}/>
        </Button> */}
        <img src={props.imgUrl} alt="BannerImage"/>
      </Dialog>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(ImgprvDialog);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: '#777'
  },
}))
export default function AlertDialog(props) {
  const classes = useStyles();
  const loader = props && props.loader

  const handleClose = () => {
    props.onCloseDeleteDialog(props.assessment)
  };
  const handleAggree = () => {
    props.onAggreeDeleteDialog(props.assessment)
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>Do you really want to delete this assessment?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.assessment.title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { loader ? (
            <CircularProgress size={25} />
          ) : null }
          <Button disabled={loader} onClick={handleAggree} color="primary">
            Delete
          </Button>
          <Button disabled={loader} onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import VimeoPlayer from '../../Player/vimeoPlayer';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    height: '42px',
    backgroundColor: '#F5F5F5',
  },
  title: {
    flex: 1,
    marginTop: '-24px',
    color: 'black'
  },
  iconWrapper: {
    marginTop: '-24px'
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 1098,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '20px'
    },
  },
  uploadedImgWrapper: {
    width: '1056px',
    height: '147px'
  },
  videoPlayerWrapper: {
    width: '1056px',
    height: '252px'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  detailsHead: {
    fontSize: '15px'
  },
  headWrapper: {
    fontSize: '15px',
    color: '#2e8eec'
  },
  readings: {
    backgroundColor: '#fff'
  },
  detailTagsWrapper: {
    border: '1px solid',
    padding: '3px',
    marginRight: '15px',
    borderRadius: '20px',
    borderColor: 'cornflowerblue'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPopup(props) {
  const classes = useStyles();
  const open = props.openFormPrev
  const courseLessonDetails = {
    title: props.lessonTitle ? props.lessonTitle : null,
    desc: props.lessonDesc ? props.lessonDesc : null,
    banner_url: props.bannerImageDetails ? props.bannerImageDetails : null,
    video_url: props.introVideoFileDetails ? props.introVideoFileDetails : null,
    transcript: props.transcriptFileDetails ? props.transcriptFileDetails : null,
    readings_data: props.readingsData ? props.readingsData : []
  }
  function createMarkup(data) {
    return {
      __html: data
    };
  }

  const renderDownoadableFile = (data, index) => {
    let arr = [];
    let fileType = data && data.file_name && data.file_name.split(/\.(?=[^\.]+$)/)[1]
    let type = '';
    if (fileType === 'ppt' || fileType === 'pptx') {
      type = 'ppt';
    } else if (fileType === 'doc' || fileType === 'docx') {
      type = 'doc';
    }
    else if (fileType === 'pdf') {
      type = 'pdf';
    }
    arr.push(
      <React.Fragment key={index} >
        <Tooltip title='readings'>
          <IconButton aria-label="Download Document">
            <a href={data.file_url} target='_blank' rel="noopener noreferrer">{type === 'pdf' ? <PictureAsPdfIcon /> : type === 'ppt' ? <PictureInPictureIcon /> : type === 'doc' ? <DescriptionIcon /> : <InsertDriveFileIcon />}</a>
          </IconButton>
        </Tooltip>
        <p>{data && data.file_name && data.file_name}</p>
      </React.Fragment>
    )
    return arr;
  }
  return (
    <div>
      <Dialog fullScreen open={open} onClose={props.closeFormPreview} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title} aria-hidden="true">
              Lesson Preview
            </Typography>
            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
              <h1>Lesson details preview popup</h1>
            </div>
            <IconButton edge="start" className={classes.iconWrapper} onClick={props.closeFormPreview} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                {courseLessonDetails.banner_url ? <img className={classes.uploadedImgWrapper} src={courseLessonDetails.banner_url} alt="BannerImage" /> : null}
              </Grid>
              <Grid item xs={12}>
                {courseLessonDetails && courseLessonDetails.video_url && courseLessonDetails.video_url.includes('blob') ? (
                  <video className={classes.videoPlayerWrapper} id="player" playsInline controls>
                    <source src={courseLessonDetails.video_url} type="video/mp4" />
                  </video>
                ) : (
                  <React.Fragment>
                    {courseLessonDetails && courseLessonDetails.video_url && courseLessonDetails.video_url ? (
                      <VimeoPlayer videoUrl={courseLessonDetails.video_url} />
                    ) : null}
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" display="block" className={classes.infoBlock}>
                  <span className={classes.detailsHead}><b>{courseLessonDetails.title ? courseLessonDetails.title : null}</b> </span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" display="block" className={classes.infoBlock}>
                  <span className={classes.detailsHead}><div dangerouslySetInnerHTML={createMarkup(courseLessonDetails.desc)}></div></span>
                </Typography>
              </Grid>
              {courseLessonDetails && courseLessonDetails.readings_data && courseLessonDetails.readings_data.length ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Accordion defaultExpanded className={classes.readings}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className={classes.headWrapper}>{`Readings (${courseLessonDetails.readings_data.length})`}</div>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: "grid" }}>
                        <div>
                          {courseLessonDetails.readings_data.map((data, index) => (
                            <React.Fragment key={index}>
                              <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                  <span className={classes.headWrapper}><b>{data.reading_title ? data.reading_title : null}</b> </span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                  <span className={classes.detailsHead}>{data.reading_desc ? data.reading_desc : null}</span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <div style={{ display: 'flex' }}>
                                    <div className={classes.header} style={{ paddingTop: '0.2rem' }}>Readings:</div>
                                    <div style={{ display: 'flex', marginTop: '-13px' }}>
                                      {renderDownoadableFile(data, index)}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                              <Divider style={{ marginBottom: '20px' }} />
                            </React.Fragment>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </Paper>
        </main>
      </Dialog>
    </div>
  );
}
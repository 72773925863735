import axios from "axios";
import { apiConfig } from "../../Configs/apiConfig";
import { allStores } from './stores.js'

export const fetchAllStores = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.fetchAllStores}`;
  axios
    .get(url)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
      //successCallBack(allStores)
    });
};

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { showSnackBar } from '../../../store/AlertMessages/actionCreator';
import { deleteNotice } from '../../../store/NoticeBoard/actionCreator';
import { ERROR_DELETE_NOTICE_MESSAGE } from '../../Common/Constants/errorMessages';
import { SUCCESS_DELETE_NOTICE_MESSAGE } from '../../Common/Constants/successMessages';
const useStyles = makeStyles(theme => ({
  btnWrapper: {
    color: '#2e8eec',
    textTransform: 'none'
  }
}))

function NoticeDelete(props) {
  const classes = useStyles();
  const open = props && props.data && props.data.dialog ? true : false;
  const [loader, setLoader] = React.useState(false);

  const handleClose = () => {
    props.closePopup();
  };

  const successDeleteCB = res => {
    setLoader(false);
    props.showSnackBar({
      state: true,
      message: SUCCESS_DELETE_NOTICE_MESSAGE,
      type: 'success'
    });
    props.closePopup();
    props.fetchNoticesList();
  }
  const failureDeleteCB = err => {
    setLoader(false);
    props.showSnackBar({
      state: true,
      message: ERROR_DELETE_NOTICE_MESSAGE,
      type: 'error'
    });
  }

  const handleDelete = () => {
    setLoader(true);
    const payload = {
      notice_id: props.data.noticeId
    }
    deleteNotice(payload, successDeleteCB, failureDeleteCB)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete notice dialog"
      aria-describedby="delete notice confirmation"
    >
      <DialogTitle id="delete notice dialog" className={classes.btnWrapper}>{"Are you sure you want to delete notice?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The notice you delete will also removed from the Student's list.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loader ? (
          <CircularProgress size={28} className={classes.btnWrapper} />
        ) : null}
        <Button onClick={handleDelete} disabled={loader} className={classes.btnWrapper}>
          Confirm
        </Button>
        <Button onClick={handleClose} disabled={loader} className={classes.btnWrapper}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(NoticeDelete)
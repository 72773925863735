export const ERROR_CREATE_ASSESSMENT = 'Sorry, Something went wrong while creating assessment. Please try again later.'
export const ERROR_UPDATE_ASSESSMENT = 'Sorry, Something went wrong while updating assessment. Please try again later.'
export const ERROR_ADD_COURSE = 'Sorry, Something went wrong. Please try again!'
export const ERROR_SCORM_FILE_INVALID = 'Sorry, Invalid file type. Please upload a file with valid format!'
export const ERROR_FETCH_COURSE_DETAILS = 'Something went wrong while fetching course information'
export const ERROR_FETCH_MODULE_DETAILS = 'Something went wrong while fetching module information'
export const ERROR_FETCH_LESSON_DETAILS = 'Something went wrong while fetching lesson information'
export const ERROR_ADD_MODULE = 'Sorry, Something went wrong while adding module'
export const ERROR_ADD_LESSON = 'Sorry, Something went wrong while adding lesson'
export const ERROR_UPDATE_COURSE_INFO = 'Something went wrong while updating course details'
export const ERROR_UPDATE_MODULE_INFO = 'Something went wrong while updating module details'
export const ERROR_UPDATE_LESSON_INFO = 'Something went wrong while updating lesson details'
export const ERROR_ADD_QUESTION = 'Something went wrong while adding question.'
export const ERROR_FETCHING_QUESTIONS = 'Something went wrong while fetching question.'
export const ERROR_FETCHING_ASSESSMENTS = 'Something went wrong while fetching assessments.'
export const ERROR_DELETE_ASSESSMENT = 'Sorry, Error while deleting the assessment. Please try again later'
export const ERROR_ASSESSMENTBTN_CLICK_WITHOUT_MODULE = 'Please add module to create assessment'
export const ERROR_ASSESSMENTBTN_CLICK_WITHOUT_LESSON = 'Please add lesson to create assessment'
export const ERROR_FETCHING_MYCOURSE_LIST = 'Something went wrong while fetching mycourse list'
export const ERROR_COURSE_PUBLISH = 'Something went wrong while publishing the course'
export const ERROR_COURSE_UNPUBLISH = 'Something went wrong while unpublishing the course'
export const ERROR_FETCHING_ASSIGNMENT = 'Something went wrong while fetching assignment'
export const ERROR_CREATE_ASSIGNMENT = 'Sorry, Something went wrong while creating assignment'
export const ERROR_DELETE_ASSIGNMENT = 'Sorry something went wrong while deleting assignment'
export const ERROR_UPDATE_ASSIGNMENT = 'Sorry something went wrong while updating assignment'
export const ERROR_ASSIGNMENT_PUBLISH = 'Sorry something went wrong while publishing the assignment'
export const ERROR_ASSIGNMENT_UNPUBLISH = 'Sorry something went wrong while unPublishing the assignment'
export const ERROR_FETCHING_ASSIGNMENT_REPORT = 'Sorry something went wrong while fetching assignment report'
export const ERROR_FETCHING_STUDENT_SUBMISSIONS = 'Sorry something went wrong while fetching student submissions'
export const ERROR_SUBMIT_ASSIGNMENT_GRADE = 'Sorry something went wrong while adding grade'
export const ERROR_UPDATE_ASSIGNMENT_GRADE = 'Sorry something went wrong while updating grade'
export const ERROR_FETCH_GRADE_BOOK = 'Something went wrong while fetching course book. Please try again later.'
export const ERROR_UPDATE_GRADE = 'Something went wrong while updating grade. Please try again later.'
export const ERROR_ASSIGNMENTBTN_CLICK_WITHOUT_MODULE = 'Please add module to create assignment'
export const ERROR_ASSIGNMENTBTN_CLICK_WITHOUT_LESSON = 'Please add lesson to create assignment'
export const ERROR_FETCHING_PROFILE = 'Something went wrong while fetching user details'
export const ERROR_FETCH_DEPARTMENTS_LIST = 'Something went wrong while fetching departments'
export const ERROR_FETCH_SCORM_REPORT = 'Something went wrong while fetching report!'
export const ERROR_COURSE_SUBMIT_REVIEW = 'Something went wrong while submitting for review!'
export const ERROR_FETCHING_DISCUSSIONS = 'Something went wrong while fetching discussions!'
export const ERROR_CREATE_DISCUSSION = 'Something went wrong while creating discussion!'
export const ERROR_UPDATE_DISCUSSION = 'Something went wrong while updating discussion!'
export const ERROR_DELETE_DISCUSSION = 'Something went wrong while deleting discussion!'
export const ERROR_DISCUSSION_PUBLISH = 'Something went wrong while publishing discussion!'
export const ERROR_DISCUSSION_UNPUBLISH = 'Something went wrong while unpublishing discussion!'
export const ERROR_FETCHING_DISCUSSION_RESPONSE = 'Something went wrong while fecthing discussion responses!'
export const ERROR_FETCHING_DISCUSSION = 'Something went wrong while fetching discussion details!'
export const ERROR_FETCHING_COMMENTS = 'Something went wrong while fetching comments'
export const ERROR_POST_REPLY = 'Something went wrong while posting reponse!'
export const ERROR_FETCHING_SUBMITTED_USERS_LIST = 'Something went wrong while fetching responded users!'
export const ERROR_FETCHING_STUDENT_RESPONSES = 'Something went wrong while fetching responses!'
export const ERROR_SUBMIT_DISCUSSION_GRADE = 'Something went wrong while adding grade!'
export const ERROR_UPDATE_DISCUSSION_GRADE = 'Something went wrong while updating grade!'
export const ERROR_POST_COMMENT = 'Something went wrong while posting comment!'
export const ERROR_FETCHING_PINNED_RESPONSE = 'Something went wrong while fetching pinned responses!'
export const ERROR_PIN_DISCUSSION = 'Something went wrong while pin the response!'
export const ERROR_UNPIN_DISCUSSION = 'Something went wrong while unpin the response!'
export const ERROR_CHECKING_PLAGIARISM = 'Something went wrong while checking Plagiarism!'
export const ERROR_FETCHING_VIDEOS = 'Something went wrong while fetching videos!'
export const ERROR_SUBMIT_VIDEO = 'Something went wrong while uploading video!'
export const ERROR_UPDATE_VIDEO = 'Something went wrong while updating video!'
export const ERROR_ASSESSMENT_PUBLISH = 'Sorry something went wrong while publishing the assessment'
export const ERROR_ASSESSMENT_UNPUBLISH = 'Sorry something went wrong while unPublishing the assessment'
export const ERROR_FETCH_ASSESSMENT_REPORT = 'Sorry something went wrong while fetching assessment report'
export const ERROR_MANAGEGRP_CREATION = 'Sorry something went wrong while creating group!'
export const ERROR_MANAGEGRP_UPDATE = 'Sorry something went wrong while updating group!'
export const ERROR_FETCH_GROUPS_LIST = 'Sorry something went wrong while fetching groups!'
export const ERROR_FETCH_GROUP_DATA = 'Sorry something went wrong while fetching group data!'
export const ERROR_FETCHING_GRP_STUDENTS_INFO = 'Sorry something went wrong while fetching students info!'
export const ERROR_FETCHING_GRP_MENTORS_INFO = 'Sorry something went wrong while fetching mentors info!'
export const ERROR_FETCHING_GRP_INSTRUCTORS_INFO = 'Sorry something went wrong while fetching instructors info!'
export const ERROR_FETCHING_GRP_TABCOUNT_INFO = 'Sorry something went wrong while fetching tab counts!'
export const ERR_ADD_MEMBERS_TO_GRP = 'Sorry something went wrong while adding members!'
export const ERR_FETCHING_COURSES_LIST = 'Sorry something went wrong while fetching courses list!'
export const ERR_ASSIGN_MENTOR_TO_STUDENT = 'Sorry something went wrong while assigning mentor!'
export const ERR_FETCH_STUDENTS_UNDER_MENTOR = 'Sorry something went while fetching students!'
export const ERR_ASSIGN_STUDENTS_TO_MENTOR = 'Sorry something went wrong while assigning students!'
export const ERROR_DISCUSSION_ASSIGN = 'Sorry something went wrong while assigning discussion!'
export const ERROR_SEND_NOTICE = 'Something went wrong while sending notice. Please try again!'
export const ERROR_UPDATE_NOTICE_MESSAGE = 'Something went wrong while updating notice!'
export const ERROR_DELETE_NOTICE_MESSAGE = 'Something went wrong while deleting notice!'
export const ERROR_FETCHING_NOTICE = 'Something went wrong while fetching notices. Please try again!'
export const ERROR_ADD_ZOOM_SECRET_KEYS = 'Something went wrong! While adding zoom keys!'
export const ERROR_UPDATE_ZOOM_SECRET_KEYS = 'Something went wrong! while updating zoom keys!'
export const ERROR_ASSIGN_INSTRUCOTRS_TO_COURSE = 'Something went wrong while adding contributors to this course!'
import React from 'react';
import { Router } from "react-router-dom";
import 'typeface-roboto';
import Layout from './Layout'
import history from './history'
import './App.css';
import axios from 'axios';
import { getDomainInfo } from '../src/Helpers/basic';

axios.interceptors.request.use(
  config => {
    const domainDet = getDomainInfo();
    let token = '';
    if (domainDet.domainTokenLabel !== undefined) {
      var cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (domainDet.domainTokenLabel === cookiePair[0].trim()) {
          token = decodeURIComponent(cookiePair[1]);
        }
      }
    }
    // token = '220014b1528fe4fc5018d8eb61550024e97e3d83'
    //token = 'd85ddb87a1567c1159f426858e62967261c056b1'
    //token = '912e0a22bad9e9d9cce724797935325592e43d14'
    //token = '7062c17dbe547805a1b3ab7f3225db3385882b97' //ansrsource laukik
    if (token !== '') {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

function App() {
  return (
    <Router history={history}>
      <div className="App">
        <Layout history={history} />
      </div>
    </Router>
  );
}
export default App;

import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const mockLoginData = {
  key: '0036df086c30c2ade68dba1b76312bc859d0a2cd',
  user: {
    id: '12',
    username: 'kalyan.kumar@continualengine.com',
    email: 'kalyan.kumar@continualengine.com',
    firstname: 'kalyan',
    lastname: 'kumar',
    organization: {
      id: 1,
      name: 'Continual Engine'
    },
    roles: ['Author'],
    userType: 'Instructor'
  },
}


export const login = (payload, successCallBack, failureCallBack) => {

  // Mock Login Flow
  // successCallBack(mockLoginData)
  //return

  const url = `${apiConfig.userAuth.loginUrl}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockLoginData)
    })
}

export const fetchProfile = (payload, successCallBack, failureCallBack) => {

  // Mock Login Flow
  // successCallBack(mockLoginData)
  // return

  const url = `${apiConfig.userAuth.fetchProfile}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockLoginData)
    })
}

export const updateProfile = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userAuth.updateProfile}`
  axios.put(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const requestForgotPassword = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userAuth.reqChangePasswordUrl}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const changePassword = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.userAuth.changePasswordUrl}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button'
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { style } from '../../Styles/theme'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      marginTop: '10px',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%'
  },
  titleWrapper: {
    fontSize: '29px',
    fontWeight: '500',
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  button: {
    flexGrow: 1,
    marginRight: '38px',
    textTransform: 'none',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  }
}))

export default function Redirections(props) {
  const classes = useStyles()
  const maxWidth700 = useMediaQuery('(max-width:700px)')

  const triggerRediction = type => {
    if(type === 'assignments'){
      props.history.push('/externalassignments')
    }else{
      props.history.push('/externalassessments')
    }
  }
  return(
    <div className={classes.root}>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.titleWrapper} aria-hidden="true">Manage Assessments</Typography>
            <Divider style={{ marginBottom: '20px'}} aria-hidden="true"/>
            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
              <h1>Manage Redirections view</h1>
            </div>
            <Grid container>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <React.Fragment>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography style={{ marginTop: '12px', color: '#546e7a'}}>Please click button to redirect assignments and get started</Typography>
                    <Typography style={{ fontSize: '13px', marginTop: '3px', marginBottom: '21px', color: '#9e9e9e', fontWeight: '500'}}>Assignments you created will show up in assignments page.</Typography>
                  </div>
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<AssignmentIcon />}
                    onClick={()=>{triggerRediction('assignments')}}
                  >
                    <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
                    Assignments
                  </Button>
                </React.Fragment>
              </Grid>
              <Grid item sm={1} md={1} lg={1} xl={1}/>
                <Divider orientation={ !maxWidth700 ? "vertical" : null } flexItem aria-hidden="true"/>
              <Grid item xs={12} sm={5} md={5} lg={5} xl={5} style={{ paddingLeft: !maxWidth700 ? '79px' : null }}>
                <React.Fragment>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography style={{ marginTop: '12px', color: '#546e7a'}}>Please click button to redirect assessments and get started</Typography>
                    <Typography style={{ fontSize: '13px', marginTop: '3px', marginBottom: '21px', color: '#9e9e9e', fontWeight: '500'}}>Assignments you created will show up in assessments page.</Typography>
                  </div>
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<AssignmentTurnedInIcon />}
                    onClick={()=>{triggerRediction('assessments')}}
                  >
                    <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
                    Assessments
                  </Button>
                </React.Fragment>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
//LOCAL IMPORTS
import TextEditor from '../Common/TextEditor';
import ImagePreview from '../Common/Previews/imagePreview'
import VideoPreview from '../Common/Previews/videoPreview'
import HelpView from '../Common/Previews/helpView'
import ModulePreview from '../Common/OpeningDialogView/ModulePreview'
import AlertMessages from '../Common/AlertMessages'
import { apiConfig } from '../../Configs/apiConfig'
import { bytesToSize, getDomainInfo } from '../../Helpers/basic';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { addModule, updateModuleInfo, deleteUploadedReadings } from '../../store/AddModule/actionCreator'
import { colorStyles } from '../../Styles/theme';
import myAthinaLogo from '../../static/myAthinaLogoWhite.png';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { SUCCESS_ADD_MODULE, SUCCESS_UPDATE_MODULE_INFO } from '../Common/Constants/successMessages'
import { ERROR_ADD_MODULE, ERROR_UPDATE_MODULE_INFO, ERROR_ASSESSMENTBTN_CLICK_WITHOUT_MODULE, ERROR_ASSIGNMENTBTN_CLICK_WITHOUT_MODULE } from '../Common/Constants/errorMessages'
import RestrictUsersPopup from '../Common/OpeningDialogView/userRestrictPopup';
//FOR FINDING THE COWORKER OF COURSE DETAILS
import { fetchCourseWorkingUsersInfo } from '../../store/Authors/actionCreator';
import { getCourseOpenModeType } from '../../Helpers/basic';
import DocsLibrarySelectionPopup from '../Common/OpeningDialogView/docsLibrarySelection';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  buttons: {
    textTransform: 'none',
    backgroundColor: colorStyles.btnColor,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
    color: colorStyles.btnText
  },
  deleteButton: {
    textTransform: 'none',
    '&:hover': {
      color: colorStyles.btnColor,
    },
    color: colorStyles.btnColor
  },
  uploadIcon: {
    color: '#2e8eec',
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedImg: {
    width: 62,
    marginLeft: 20,
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  errorMsg: {
    color: 'red',
    textAlign: 'left',
    marginBottom: '-10px'
  },
  uploadedVideo: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  buttonProgress: {
    marginBottom: '10px',
    marginRight: '10px',
    color: colorStyles.fontColor._blue
  },
  hours: {
    minWidth: '150px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 231,
  },
  iconButtonMargin: {
    float: 'right',
    marginBottom: '10px',
    marginLeft: '10px'
  },
  readingsButton: {
    float: 'right',
    marginBottom: '10px',
    marginLeft: '10px',
    marginTop: '15px',
    color: '#3f51b5'
  },
  helperText: {
    color: '#999'
  },
  iconWrapper: {
    fontSize: '24px',
    marginRight: '10px',
  },
  deleteIconWrapper: {
    color: '#2e8eec',
  },
  link: {
    color: '#2e8eec',
    marginTop: '19px'
  },
}));

function AddingModule(props) {
  const classes = useStyles()
  const domainInfo = getDomainInfo();
  const showHelpButton = false;
  const readingsUploadCount = 3;
  const showRestrictPopupInfo = props.showRestrictPopup
  const defaultModuleTitle = props.courseModuleDetails && props.courseModuleDetails.title ? props.courseModuleDetails.title : ''
  const [moduleTitle, setModuleTitle] = useState(defaultModuleTitle)
  const [moduleDesc, setModuleDesc] = useState('');
  const [moduleDurationDays, setModuleDurationDays] = useState(0)
  const [moduleDurationHours, setModuleDurationHours] = useState(0)
  const [moduleDurationMin, setModuleDurationMin] = useState(0)
  const [bannerImageDetails, setbannerImageDetails] = useState({})
  const [uploadedImagePreview, setUploadedImagePreview] = useState('')
  const [introVideoFileDetails, setIntroVideoFileDetails] = useState({})
  const [uploadedIntroVideoPreview, setUplodedIntroVideoPreview] = useState('')
  const [transcriptFileDetails, setTranscriptFileDetails] = useState({})
  const [uploadedTranscriptFile, setUploadedTranscriptFile] = useState('')
  const [validationError, setValidationError] = useState({
    error: false,
    errorMessage: ''
  })
  const [inProgress, setInProgress] = useState(false);
  const [openImgPrvDialog, setOpenImgPrvDialog] = useState(false)
  const [openIntroPrvDialog, setOpenIntroPrvDialog] = useState(false)
  const [openHelpImageView, setOpenHelpImageView] = useState(false)
  const [openFormPrev, setOpenFormPrev] = useState(false)
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  //FOR ALL READING RELATED SETSTATES
  const [readingMaterialData, setReadingMaterialData] = useState([])
  const [readings, setReadings] = useState([])
  const [readingFileDetails, setReadingFileDetails] = useState([])
  const defaultBtn = props.courseModuleDetails && props.courseModuleDetails.reading_file_count && props.courseModuleDetails.reading_file_count >= 3 ? true : false
  const [addReadingsBtn, setAddReadingsBtn] = useState(defaultBtn)
  const [showDeletePopup, setShowDeletePopup] = useState({
    index: 0,
    popup: false,
    loader: false
  })
  const [readingsErr, setReadingsErr] = useState({
    id: '',
    error: false,
    message: '',
    type: ''
  })
  //FOR SELECTING THE FILES FROM DOCS LIBRARY
  const [openDocsLibrary, setOpenDocsLibrary] = useState({
    type: '',
    dialog: false,
    key: ''
  })

  useEffect(() => {
    if (props && props.courseModuleDetails && props.courseModuleDetails.readinginfo) {
      setReadingMaterialData(props.courseModuleDetails.readinginfo)
      setReadings(props.courseModuleDetails.readinginfo)
      setReadingFileDetails(props.courseModuleDetails.readinginfo)
    }
    //FOR DEFAULT SETSTATES
    if (props && props.courseModuleDetails) {
      setUploadedImagePreview(props.courseModuleDetails && props.courseModuleDetails.banner_url && props.courseModuleDetails.banner_url)
      setUplodedIntroVideoPreview(props.courseModuleDetails && props.courseModuleDetails.video_url && props.courseModuleDetails.video_url)
      setModuleDesc(props.courseModuleDetails && props.courseModuleDetails.desc && props.courseModuleDetails.desc)
      setModuleDurationDays(props.courseModuleDetails && props.courseModuleDetails.days && props.courseModuleDetails.days)
      setModuleDurationMin(props.courseModuleDetails && props.courseModuleDetails.minutes && props.courseModuleDetails.minutes)
      setModuleDurationHours(props.courseModuleDetails && props.courseModuleDetails.hours && props.courseModuleDetails.hours)
      setUploadedTranscriptFile(props.courseModuleDetails && props.courseModuleDetails.transcript && props.courseModuleDetails.transcript)
    }
  }, [props])

  useEffect(() => {
    let disabledFlag = false
    if (readings && readings.length === readingsUploadCount) {
      disabledFlag = true
    } else if (readings && (readings.length > readingMaterialData.length)) {
      disabledFlag = true
    } else if (readingMaterialData && readingMaterialData.length) {
      readingMaterialData.forEach((data, index) => {
        if (data && data.file_name && !data.reading_title) {
          disabledFlag = true
        } else if (data && data.file_name && !data.reading_desc) {
          disabledFlag = true
        } else if (data && (data.reading_title || data.reading_desc) && !data.file_name) {
          disabledFlag = true
        }
      })
    }
    setAddReadingsBtn(disabledFlag)
  }, [readingMaterialData, readings])

  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      moduleTitle: {
        error: false
      },
      moduleDesc: {
        error: false
      }
    }
  );

  const values = {
    module_title: props.courseModuleDetails && props.courseModuleDetails.title ? props.courseModuleDetails.title : null,
    module_desc: props.courseModuleDetails && props.courseModuleDetails.desc ? props.courseModuleDetails.desc : null,
    module_bannerImage: props.courseModuleDetails && props.courseModuleDetails.banner_url ? props.courseModuleDetails.banner_url : null,
    module_introVideo: props.courseModuleDetails && props.courseModuleDetails.video_url ? props.courseModuleDetails.video_url : null,
    module_video_filename: props.courseModuleDetails && props.courseModuleDetails.video_filename ? props.courseModuleDetails.video_filename : '',
    module_transcript: props.courseModuleDetails && props.courseModuleDetails.transcript ? props.courseModuleDetails.transcript : null,
    module_transcript_filename: props.courseModuleDetails && props.courseModuleDetails.transcript_filename ? props.courseModuleDetails.transcript_filename : '',
    module_days: props.courseModuleDetails && props.courseModuleDetails.days ? props.courseModuleDetails.days : null,
    module_hours: props.courseModuleDetails && props.courseModuleDetails.hours ? props.courseModuleDetails.hours : null,
    module_minutes: props.courseModuleDetails && props.courseModuleDetails.minutes ? props.courseModuleDetails.minutes : null,
    reading_files: props.courseModuleDetails && props.courseModuleDetails.readinginfo ? props.courseModuleDetails.readinginfo : []
  }

  const moduleVideoHours = [];
  for (let i = 0; i <= 24; i++) { moduleVideoHours.push(i); }

  const moduleVideoMinutes = [];
  for (let j = 0; j <= 60; j++) { moduleVideoMinutes.push(j); }

  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const validatedFile = (type, key) => {
    const selectedFile = document.getElementById(type).files;
    let fileDetails = {
      fileError: false,
      fileErrorErrorMsg: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMsg: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'bannerImage' && file.size > apiConfig.addingCourse.acceptedImageFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 2MB'
        }
      } else if (type === 'introVideo' && file.size > apiConfig.addingCourse.acceptedVideoFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 600MB'
        }
      } else if (type === 'transcriptFile' && file.size > apiConfig.addingCourse.acceptedTranScriptSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less  than 200MB'
        }
      } else if (type === `readingFile_${key}` && file.size > apiConfig.addingCourse.acceptedReadingFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less  than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'bannerImage') {
            return apiConfig.addingCourse.acceptedImageFileFormats
          } else if (type === 'introVideo') {
            return apiConfig.addingCourse.acceptedVideoFileFormats
          } else if (type === 'transcriptFile') {
            return apiConfig.addingCourse.acceptedTranscriptFileFormats
          } else {
            return apiConfig.addingCourse.acceptedReadingFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const fileExtension = file && file.name && file.name.lastIndexOf('.')
        const currentExtension = file && file.name && file.name.slice(fileExtension) && file.name.slice(fileExtension).split('.')[1] && file.name.slice(fileExtension).split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMsg: 'Invalid file format.'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMsg: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }
  const handleChangeCourseTitle = evnt => {
    setModuleTitle(evnt.target.value)
    setFormFieldStatus(
      {
        moduleTitle: {
          error: false
        },
        moduleDesc: {
          error: false
        }
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const onChangeEditorContent = data => {
    setModuleDesc(data)
    setFormFieldStatus(
      {
        moduleTitle: {
          error: false
        },
        moduleDesc: {
          error: false
        }
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }
  const onBannerImageUpload = event => {
    const certificateFile = validatedFile('bannerImage')
    setbannerImageDetails(certificateFile)
    setUploadedImagePreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }
  const onIntroVideoUpload = event => {
    const videoFile = validatedFile('introVideo')
    setIntroVideoFileDetails(videoFile)
    setUplodedIntroVideoPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }
  const onTranscriptUpload = event => {
    const transcriptFile = validatedFile('transcriptFile')
    setTranscriptFileDetails(transcriptFile)
    setUploadedTranscriptFile(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  //FOR ADDING READING TITLE HANDLE CHANG FUNCTION
  const handleChangeReadings = (event, key) => {
    let readingsData = [...readingMaterialData]
    let item = readingsData[key];
    item = { ...item, [event.target.name]: event.target.value };
    readingsData[key] = item;
    setReadingMaterialData(readingsData)
    setReadingsErr({
      id: '',
      error: false,
      message: '',
      type: ''
    })
  }

  //ON READINGS FILE UPLOAD
  const onReadingsUpload = (event, key) => {
    const readingFile = validatedFile(`readingFile_${key}`, key)
    let allFile = [...readingFileDetails]
    if (typeof allFile[key] !== 'undefined') {
      allFile[key] = readingFile
    } else {
      allFile.push(readingFile)
    }
    setReadingFileDetails(allFile)

    let readingsData = [...readingMaterialData]
    let item = readingsData[key];
    item = {
      ...item,
      [event.target.name]: readingFile && readingFile.name && readingFile.name,
      file_url: event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null,
      file_data: readingFile
    };
    readingsData[key] = item;
    setReadingMaterialData(readingsData)
  }

  //ON READINGS DELETE
  const removeReadingsFile = (index, type) => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setReadingsErr({
      id: '',
      error: false,
      message: '',
      type: ''
    })
    if (type === 'local') {
      readings.splice(index, 1)
      readingMaterialData.splice(index, 1)
      setReadings([...readings])
      setReadingMaterialData([...readingMaterialData])
      if (readings.length === readingsUploadCount) {
        setAddReadingsBtn(true)
        setValidationError({
          error: true,
          errorMessage: `You can add only ${readingsUploadCount} readings`
        })
      } else {
        setAddReadingsBtn(false)
        setValidationError({
          error: false,
          errorMessage: ''
        })
      }
    } else {
      setShowDeletePopup({
        index: index,
        popup: true,
        loader: false,
      })
    }
  }
  //FOR CLOSING THE DELETE POPUP
  const closeDeletePopup = () => {
    setShowDeletePopup({
      index: 0,
      popup: false,
      loader: false
    })
  }

  //DELETE READINGS CALLBACKS
  const successDeleteReading = res => {
    props.onCourseModuleMenuClick({ module_id: props.match.params.moduleId })
    if (res.length === readingsUploadCount) {
      setAddReadingsBtn(true)
      setValidationError({
        error: true,
        errorMessage: `You can add only ${readingsUploadCount} readings`
      })
    } else {
      setAddReadingsBtn(false)
      setValidationError({
        error: false,
        errorMessage: ''
      })
    }
    props.showSnackBar({
      state: true,
      message: "Readings Deleted successfully",
      type: "success",
    });
    closeDeletePopup()
  }
  const failureDeleteReading = err => {
    setShowDeletePopup({
      loader: false,
      index: 0,
      popup: false
    });
    props.showSnackBar({
      state: false,
      message: "Something went wrong while deleting readings!",
      type: "error",
    });
  }

  //FOR DELETING READINGS THROUGHT API
  const deleteReadingsSelected = () => {
    setShowDeletePopup({
      index: showDeletePopup.index,
      loader: true,
      popup: true
    });
    const payload = {
      index: showDeletePopup.index,
      module_id: props.match.params.moduleId
    }
    deleteUploadedReadings(payload, successDeleteReading, failureDeleteReading)
  }

  const handleChangeModuleDurationDays = event => {
    if (event.target.value >= 0) {
      setModuleDurationDays(event.target.value)
    }
  }
  const handleChangeModuleDurationHrs = event => {
    setModuleDurationHours(event.target.value)
  }
  const handleChangeModuleDurationMin = event => {
    setModuleDurationMin(event.target.value)
  }

  const successAddModule = res => {
    setInProgress(false)
    const moduleId = res.module_id ? res.module_id : null
    const courseId = props.match.params.courseId ? props.match.params.courseId : null
    props.history.push(`/mycourse/${courseId}/${moduleId}`)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ADD_MODULE,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    props.courseLeftMenuTrigger()
    props.onCourseModuleMenuClick({ module_id: moduleId })
  }
  const successAddModuleAndAddLesson = res => {
    setInProgress(false)
    const moduleId = res.module_id ? res.module_id : null
    const courseId = props.match.params.courseId ? props.match.params.courseId : null
    props.history.push(`/mycourse/${courseId}/${moduleId}`)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ADD_MODULE,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    props.triggerAddLessonForm()
    props.courseLeftMenuTrigger()
  }
  const failureAddingModule = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ADD_MODULE,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const successUpdateModuleInfo = res => {
    const moduleId = props.match.params.moduleId ? props.match.params.moduleId : null
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_UPDATE_MODULE_INFO,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setInProgress(false)
    props.courseLeftMenuTrigger()
    props.onCourseModuleMenuClick({ module_id: moduleId })
  }
  const successUpdateModuleInfoAndAddLesson = res => {
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_UPDATE_MODULE_INFO,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setInProgress(false)
    props.triggerAddLessonForm()
    props.courseLeftMenuTrigger()
  }
  const failureUpdateModuleInfo = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UPDATE_MODULE_INFO,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setInProgress(false)
  }
  const openFormPreview = () => {
    setInProgress(false)
    setOpenFormPrev(true)
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  //SUBMIT MODULE FORM ADD/UPDATE
  const submitForm = (evnt, type) => {
    evnt.preventDefault()

    let isNewIntroVideoValid = true
    if (introVideoFileDetails.fileError) {
      isNewIntroVideoValid = false
    }

    let readingsErr = {
      id: '',
      error: false,
      message: '',
      type: ''
    }
    let uploadedReadingFiles = []
    readingMaterialData && readingMaterialData.length && readingMaterialData.forEach((data, index) => {
      uploadedReadingFiles.push(data && data.file_data && data.file_data.file)
      if (data && data.file_name && !data.reading_title) {
        readingsErr = {
          id: index,
          error: true,
          message: 'Please add readings title!',
          type: 'title'
        }
        setValidationError({
          error: false,
          errorMessage: ''
        })
      } else if (data && data.file_name && !data.reading_desc) {
        readingsErr = {
          id: index,
          error: true,
          message: 'Please add readings description!',
          type: 'desc'
        }
        setValidationError({
          error: false,
          errorMessage: ''
        })
      } else if (data && (data.reading_title || data.reading_desc) && !data.file_name) {
        readingsErr = {
          id: index,
          error: true,
          message: `Please upload attachment for readings - ${index + 1}!`,
          type: 'file'
        }
        setValidationError({
          error: false,
          errorMessage: ''
        })
      }
    })

    //for sending uploaded files data
    let UploadedFilesArr = []
    readingMaterialData && readingMaterialData.length && readingMaterialData.forEach((data) => {
      if (data && data.doc_id && !data.file_url.includes('blob')) {
        UploadedFilesArr.push({ 'doc_id': data.doc_id, 'file_name': data.file_name, 'file_url': data.file_url ? data.file_url : " ", 'reading_title': data.reading_title, 'reading_desc': data.reading_desc })
      } else {
        UploadedFilesArr.push({ 'file_name': data.file_name, 'file_url': data.file_url ? data.file_url : " ", 'reading_title': data.reading_title, 'reading_desc': data.reading_desc })
      }
    })

    if (!moduleTitle) {
      setFormFieldStatus(
        {
          moduleTitle: {
            error: true
          },
          moduleDesc: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Module Title!'
      })
    } else if (!moduleDesc) {
      setFormFieldStatus(
        {
          moduleTitle: {
            error: false
          },
          moduleDesc: {
            error: true
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Module Description!'
      })
    } else if (!isNewIntroVideoValid) {
      setFormFieldStatus(
        {
          moduleTitle: {
            error: false
          },
          moduleDesc: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Intro Video!'
      })
    }
    else if (readingsErr.error) {
      setReadingsErr({
        id: readingsErr.id,
        error: readingsErr.error,
        message: readingsErr.message,
        type: readingsErr.type
      })
    } else {
      setFormFieldStatus(
        {
          moduleTitle: {
            error: false
          },
          moduleDesc: {
            error: false
          }
        }
      )
      setValidationError({
        error: false,
        errorMessage: ''
      })
      setReadingsErr({
        id: '',
        error: false,
        message: '',
        type: ''
      })
      setInProgress(true)
      let formData = new FormData()
      formData.append('course_id', props.match.params.courseId ? props.match.params.courseId : null)
      formData.append('title', moduleTitle)
      formData.append('desc', moduleDesc)
      formData.append('days', moduleDurationDays ? moduleDurationDays : 0)
      formData.append('hours', moduleDurationHours ? moduleDurationHours : 0)
      formData.append('minutes', moduleDurationMin ? moduleDurationMin : 0)
      formData.append('banner', bannerImageDetails.file ? bannerImageDetails.file : null)
      formData.append('video', introVideoFileDetails.file ? introVideoFileDetails.file : null)
      formData.append('transcript', transcriptFileDetails.file ? transcriptFileDetails.file : null)
      formData.append('readingCount', readingMaterialData.length)

      if (introVideoFileDetails && introVideoFileDetails.fileId && introVideoFileDetails.fileId) {
        formData.append('is_doc_video', true)
        formData.append('doc_video_id', introVideoFileDetails.fileId ? introVideoFileDetails.fileId : null)
      }

      formData.append('readings', JSON.stringify(UploadedFilesArr))
      for (let i = 0; i <= uploadedReadingFiles.length - 1; i++) {
        formData.append(`reading_${i + 1}`, uploadedReadingFiles[i] ? uploadedReadingFiles[i] : null)
      }

      if (type === 'update' || type === 'update&AddLesson') {
        const payload = {
          id: props.match.params.moduleId ? props.match.params.moduleId : null
        }
        updateModuleInfo(payload, formData, type === 'update' ? successUpdateModuleInfo : successUpdateModuleInfoAndAddLesson, failureUpdateModuleInfo)
      } else if (type === 'showPreview') {
        openFormPreview()
      } else if (type === 'save' || type === 'save&AddLesson') {
        addModule(formData, type === 'save' ? successAddModule : successAddModuleAndAddLesson, failureAddingModule)
      }
    }
  }
  const openUploadedBannerImagePopup = () => {
    setOpenImgPrvDialog(true)
  }
  const closeUploadedBannerImagePopup = () => {
    setOpenImgPrvDialog(false)
  }
  const openUploadedIntroVideoPopup = () => {
    setOpenIntroPrvDialog(true)
  }
  const closeUploadedIntroVideoPopup = () => {
    setOpenIntroPrvDialog(false)
  }

  const openHelpView = () => {
    setOpenHelpImageView(true)
  }
  const closeHelpView = () => {
    setOpenHelpImageView(false)
  }
  const closeFormPreview = () => {
    setOpenFormPrev(false)
  }

  //FOR CHECKING THE READINGS LENGTH AND ADDING MORE THAN 3 CONDITION
  const addReadings = () => {
    let allReadings = [...readings]
    allReadings.push({ readings: '' })
    setReadings(allReadings)
    if (readings.length >= readingsUploadCount - 1) {
      setAddReadingsBtn(true)
      setValidationError({
        error: true,
        errorMessage: `You can add only ${readingsUploadCount} readings`
      })
    }
  }

  //FOR CHECKING THE CO WORKER FOR ASSESSMENTS
  const successCoworkAssessment = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The assessments page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/moduleassessment/${props.match.params.courseId}/${props.match.params.moduleId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/moduleassessment/${props.match.params.courseId}/${props.match.params.moduleId}`)
      }
    }
  }
  const failureCoworkAssessment = err => { }

  const landToModuleAssessment = () => {
    if (courseOpenedMode !== 'visit_mode' && defaultModuleTitle && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.moduleId,
        type: 'modulelevelassessments'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworkAssessment, failureCoworkAssessment)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/moduleassessment/${props.match.params.courseId}/${props.match.params.moduleId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/moduleassessment/${props.match.params.courseId}/${props.match.params.moduleId}`)
      }
    }
  }

  //FOR CHECKING THE CO WORKER FOR ASSIGNMENT
  const successCoworkAssignMent = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The assignments page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/moduleassignment/${props.match.params.courseId}/${props.match.params.moduleId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/moduleassignment/${props.match.params.courseId}/${props.match.params.moduleId}`)
      }
    }
  }
  const failureCoworkAssignment = err => { }

  const landToModuleAssignment = () => {
    //FOR MODULE ASSIGNMENT NAVIGATION
    if (courseOpenedMode !== 'visit_mode' && defaultModuleTitle && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.moduleId,
        type: 'assignments'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworkAssignMent, failureCoworkAssignment)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/moduleassignment/${props.match.params.courseId}/${props.match.params.moduleId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/moduleassignment/${props.match.params.courseId}/${props.match.params.moduleId}`)
      }
    }
  }
  const moduleAssessment = () => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ASSESSMENTBTN_CLICK_WITHOUT_MODULE,
        type: 'error'
      }
    )
  }

  const moduleAssignment = () => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ASSIGNMENTBTN_CLICK_WITHOUT_MODULE,
        type: 'error'
      }
    )
  }

  //FOR LINKING THE FILE FROM DOCS LIBRARY
  const linkAFileDocsLibrary = (type, key) => {
    setOpenDocsLibrary({
      type: type,
      dialog: true,
      key: key ? key : 0
    })
  }

  //ON SELECTION FROM DOCS LIBRARY
  const onSelecteDocsLibraryFile = (data, fileType) => {
    if (fileType === 'other_docs') {
      let key = openDocsLibrary.key
      let readingsData = [...readingMaterialData]
      let item = readingsData[key];
      item = { ...item, 'from': 'docs_library', 'doc_id': data && data.id, 'file_name': data && data.original_filename, 'file_url': data && data.doc_url, 'reading_title': data && data.title && data.title.substr(0, 100), 'reading_desc': data && data.desc && data.desc.substr(0, 500) };
      readingsData[key] = item;
      setReadingMaterialData(readingsData)
      setReadingsErr({
        id: '',
        error: false,
        message: '',
        type: ''
      })
    } else {
      let videoFileDetails = {
        fileError: false,
        fileErrorErrorMsg: '',
        name: data && data.video_filename && data && data.video_filename,
        type: 'video/mp4',
        file: data && data.video,
        fileId: data && data.id
      }
      let transcriptFileDetails = {
        fileError: false,
        fileErrorErrorMsg: '',
        name: data && data.transcript_filename && data && data.transcript_filename,
        type: '',
        file: data && data.transcript
      }
      setIntroVideoFileDetails(videoFileDetails)
      setUplodedIntroVideoPreview(data && data.video)
      setTranscriptFileDetails(transcriptFileDetails)
      setUploadedTranscriptFile(data && data.transcript)
    }
  }

  //FOR CLOSING THE DOCS LIBRARY SELECTION POPUP
  const closeDocsLibrayPopup = () => {
    setOpenDocsLibrary({
      type: '',
      dialog: false,
      key: ''
    })
  }

  const getFileDeleteType = (key) => {
    if (readingMaterialData && readingMaterialData.length) {
      if (readingMaterialData[key] && readingMaterialData[key].file_url && readingMaterialData[key].file_url.includes('amazonaws')) {
        if (readingMaterialData[key] && readingMaterialData[key].hasOwnProperty('from')) {
          return 'local'
        } else {
          return 'remote'
        }
      } else {
        return 'local'
      }
    } else {
      return 'local'
    }
  }
  return (
    <Grid item xs={12}>
      {showRestrictPopupInfo && showRestrictPopupInfo.open ? (
        <RestrictUsersPopup data={showRestrictPopupInfo} />
      ) : null}
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>{props.courseModuleDetails ? 'Update Module datails form' : 'Add Module details details form'}</h1>
      </div>
      <Paper className={classes.paper}>
        <Typography style={{ marginBottom: '15px' }} component="h1" variant="h4" align="center" aria-hidden="true">
          {props.courseModuleDetails ? 'Update Module' : 'Add Module'}
        </Typography>
        {showHelpButton ? (
          <Tooltip title="Help">
            <IconButton edge="start" color="primary" className={classes.iconButtonMargin} onClick={openHelpView} aria-label="close">
              <HelpOutlineIcon style={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        ) : null}
        {openHelpImageView ? (
          <HelpView
            openHelpImageView={openHelpImageView}
            closeHelpView={closeHelpView}
            imgUrl={myAthinaLogo}
          />
        ) : null}
        {validationError.error ? (
          <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block' }}>
            <AlertMessages status="error" message={validationError.errorMessage} />
          </div>
        ) : (
          <React.Fragment>
            {readingsErr.error ? (
              <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block' }}>
                <AlertMessages status="error" message={readingsErr.message} />
              </div>
            ) : null}
          </React.Fragment>
        )}
        <form autoComplete="off" onSubmit={evnt => { evnt.preventDefault() }} >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                variant="outlined"
                id="moduletitle"
                name="moduletitle"
                label="Module Title"
                error={formFieldStatus.moduleTitle.error}
                defaultValue={values.module_title}
                fullWidth
                helperText={`max ${apiConfig.addingCourse.courseTitleLimit} char`}
                inputProps={{
                  maxLength: apiConfig.addingCourse.courseTitleLimit
                }}
                onChange={handleChangeCourseTitle}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px' }}>
                About this module
                <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk" aria-hidden="true">&thinsp;*</span>
              </Typography>
              <TextEditor onChangeEditorContent={onChangeEditorContent} description={values.module_desc} />
            </Grid>
            <Typography style={{ marginLeft: '13px', marginTop: '25px', fontSize: '14px' }}>
              Module Estimated Duration:
            </Typography>
            <Grid item xs={12} sm={3}>
              <TextField
                margin="dense"
                id="days"
                label="Days"
                type="Number"
                variant="outlined"
                value={moduleDurationDays}
                onChange={handleChangeModuleDurationDays}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.hours}
                margin="dense"
                id="hours"
                select
                label="Hours"
                variant="outlined"
                value={moduleDurationHours}
                SelectProps={{
                  native: true
                }}
                onChange={handleChangeModuleDurationHrs}
              >
                {moduleVideoHours.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                className={classes.hours}
                margin="dense"
                id="minutes"
                select
                label="Minutes"
                name="minutes"
                value={moduleDurationMin}
                onChange={handleChangeModuleDurationMin}
                variant="outlined"
                SelectProps={{
                  native: true
                }}
              >
                {moduleVideoMinutes.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            {apiConfig.addModule.showImageFileds ? (
              <React.Fragment>
                <Grid item xs={12} sm={12}>
                  <div style={{ float: 'left' }}>
                    {bannerImageDetails.fileError ? (
                      <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                        {bannerImageDetails.fileErrorErrorMsg}
                      </Typography>
                    ) : null}
                    <Typography gutterBottom variant="body2">
                      <span className={classes.uploadLabel} aria-hidden="true">
                        Upload Banner Image :
                      </span>
                      <IconButton aria-label="Upload thumbnail" onClick={() => { onFileToUploadClick('bannerImage') }}>
                        <CloudUploadIcon className={classes.uploadIcon} />
                      </IconButton>
                      {bannerImageDetails && bannerImageDetails.file && bannerImageDetails.file.name ? (
                        <span>{`${bannerImageDetails && bannerImageDetails.file && bannerImageDetails.file.name && bannerImageDetails.file.name.substr(0, 10) + '...'}, ${bannerImageDetails && bannerImageDetails.file && bannerImageDetails.file.size}`}</span>
                      ) : null}
                      <span className={classes.helperText}>( max.size: 2MB, format: jpg, jpeg, png )</span>
                      {uploadedImagePreview ? (
                        <img className={classes.uploadedImg} alt="bannerimage" src={uploadedImagePreview} onClick={() => { openUploadedBannerImagePopup(uploadedImagePreview) }} />
                      ) : null}
                      {openImgPrvDialog ? (
                        <ImagePreview
                          openImgPrvDialog={openImgPrvDialog}
                          imgUrl={uploadedImagePreview}
                          closeUploadedBannerImagePopup={closeUploadedBannerImagePopup}
                        />
                      ) : null}
                    </Typography>
                    <input type="file" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} id="bannerImage" onChange={onBannerImageUpload} />
                    <span style={{ fontSize: '12px', float: 'inherit', marginTop: '15px', color: '#999' }}>Recommended Image size (1024x210)</span>
                  </div>
                </Grid>
              </React.Fragment>
            ) : null}
            <Grid item xs={12} sm={12}>
              <Grid container>
                <Grid item xs={6} sm={6}>
                  {introVideoFileDetails.fileError ? (
                    <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                      {introVideoFileDetails.fileErrorErrorMsg}
                    </Typography>
                  ) : null}
                  <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                    <span className={classes.uploadLabel} aria-hidden="true">
                      Upload Module Intro Video :
                    </span>
                    <Tooltip title="Upload from Computer">
                      <IconButton aria-label="upload video" onClick={() => { onFileToUploadClick('introVideo') }}>
                        <CloudUploadIcon className={classes.uploadIcon} />
                      </IconButton>
                    </Tooltip>
                    {domainInfo.hasMyAthinaCorpAcc && domainInfo.enableDocsLibraryUpload ? (
                      <React.Fragment>
                        <span style={{ marginLeft: '7px', marginRight: '7px' }}>or</span>
                        <Tooltip title="Upload from Docs Library">
                          <IconButton aria-label="Upload video" onClick={() => { linkAFileDocsLibrary('videos') }}>
                            <InsertLinkIcon className={classes.uploadIcon} />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ) : null}
                  </Typography>
                  <div style={{ color: '#999', fontSize: '12px', textAlign: 'left', marginTop: '-11px' }}>max.size: 600MB, format: mp4, optional</div>
                </Grid>
                <Grid item xs={6} sm={6} style={{ paddingTop: '16px', textAlign: 'left' }}>
                  {introVideoFileDetails && introVideoFileDetails.name && uploadedIntroVideoPreview ? (
                    <React.Fragment>
                      <VideoLibraryIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px' }} />
                      <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>
                        {introVideoFileDetails && introVideoFileDetails.file && introVideoFileDetails.name && introVideoFileDetails.name.length > 30 ? (
                          introVideoFileDetails.name.substr(0, 25) + '...'
                        ) :
                          introVideoFileDetails && introVideoFileDetails.name && introVideoFileDetails.name
                        }
                      </span>
                    </React.Fragment>
                  ) : values.module_introVideo ? (
                    <React.Fragment>
                      <VideoLibraryIcon onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px', cursor: 'pointer' }} />
                      {values && values.module_video_filename && values.module_video_filename && <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>{values.module_video_filename}</span>}
                    </React.Fragment>
                  ) : null}
                  {openIntroPrvDialog ? (
                    <VideoPreview
                      openIntroPrvDialog={openIntroPrvDialog}
                      videoUrl={uploadedIntroVideoPreview}
                      closeUploadedIntroVideoPopup={closeUploadedIntroVideoPopup}
                    />
                  ) : null}
                </Grid>
                <input type="file" accept=".mp4" style={{ display: 'none' }} id="introVideo" onChange={onIntroVideoUpload} />
              </Grid>
            </Grid>
            {(introVideoFileDetails && introVideoFileDetails.name) || values.module_introVideo ? (
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    {transcriptFileDetails.fileError ? (
                      <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                        {transcriptFileDetails.fileErrorErrorMsg}
                      </Typography>
                    ) : null}
                    <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                      <span className={classes.uploadLabel} aria-hidden="true">
                        Upload TranscriptFile :
                      </span>
                      <Tooltip title="Upload from Computer">
                        <IconButton aria-label="upload transcript" aria-required="true" onClick={() => { onFileToUploadClick('transcriptFile') }}>
                          <CloudUploadIcon className={classes.uploadIcon} />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    <div style={{ color: '#999', fontSize: '12px', textAlign: 'left', marginTop: '-11px' }}>Optional, max.size: 200MB, format: vtt, srt</div>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ paddingTop: '16px', textAlign: 'left' }}>
                    {transcriptFileDetails.file && transcriptFileDetails.name && uploadedTranscriptFile ? (
                      <React.Fragment>
                        <VideoLibraryIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px' }} />
                        <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>
                          {transcriptFileDetails && transcriptFileDetails.file && transcriptFileDetails.name && transcriptFileDetails.name.length > 30 ? (
                            transcriptFileDetails.name.substr(0, 25) + '...'
                          ) :
                            transcriptFileDetails && transcriptFileDetails.file && transcriptFileDetails.name && transcriptFileDetails.name
                          }
                        </span>
                      </React.Fragment>
                    ) : values.module_transcript ? (
                      <React.Fragment>
                        <VideoLibraryIcon onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px', cursor: 'pointer' }} />
                        {values && values.module_transcript_filename && values.module_transcript_filename && <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>{values.module_transcript_filename}</span>}
                      </React.Fragment>
                    ) : null}
                    {openIntroPrvDialog ? (
                      <VideoPreview
                        openIntroPrvDialog={openIntroPrvDialog}
                        videoUrl={uploadedIntroVideoPreview}
                        closeUploadedIntroVideoPopup={closeUploadedIntroVideoPopup}
                        transcriptFileDetails={transcriptFileDetails}
                      />
                    ) : null}
                  </Grid>
                  <input type="file" accept=".srt, .vtt" style={{ display: 'none' }} id="transcriptFile" onChange={onTranscriptUpload} />
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} style={{ maxHeight: '100vh', overflowY: 'auto' }}>
              {readings && readings.length ? (
                <div>
                  {readings && readings.length && readings.map((row, key) => (
                    <div key={key} style={{ width: '100%' }}>
                      <Grid container>
                        <Grid item xs={5} sm={5}>
                          {key < readingFileDetails.length && readingFileDetails[key].fileError ? (
                            <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                              {readingFileDetails[key].fileErrorErrorMsg}
                            </Typography>
                          ) : null}
                          <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                            <span className={classes.uploadLabel}>
                              {`Upload Readings_${key + 1}`} :
                            </span>
                            <Tooltip title="Upload from Computer">
                              <IconButton aria-label="Upload readings" onClick={() => { onFileToUploadClick(`readingFile_${key}`) }}>
                                <CloudUploadIcon className={classes.uploadIcon} />
                              </IconButton>
                            </Tooltip>
                            {domainInfo.hasMyAthinaCorpAcc && domainInfo.enableDocsLibraryUpload ? (
                              <React.Fragment>
                                <span style={{ marginLeft: '7px', marginRight: '7px' }}>or</span>
                                <Tooltip title="Upload from Docs Library">
                                  <IconButton aria-label="Upload readings" onClick={() => { linkAFileDocsLibrary('other_docs', key) }}>
                                    <InsertLinkIcon className={classes.uploadIcon} />
                                  </IconButton>
                                </Tooltip>
                              </React.Fragment>
                            ) : null}
                          </Typography>
                          <div style={{ color: '#999', fontSize: '12px', textAlign: 'left', marginBottom: '17px', marginTop: '-11px' }}>max.size: 10MB, format: pdf, pptx, docx, xlsx, csv</div>
                        </Grid>
                        <Grid item xs={7} sm={7} style={{ paddingTop: '15px', textAlign: 'left' }}>
                          <React.Fragment key={key}>
                            {key < readingMaterialData.length && readingMaterialData[key] && readingMaterialData[key].file_name ? (
                              <React.Fragment>
                                {readingMaterialData && readingMaterialData.length && readingMaterialData[key].file_name && readingMaterialData[key].file_name.split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-10px' }} /> : readingMaterialData[key].file_name.split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-10px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-10px' }} />}
                                <a href={readingMaterialData[key] && readingMaterialData[key].file_url && readingMaterialData[key].file_url} target='_blank' className={classes.uploadedVideo}>
                                  {readingMaterialData[key] && readingMaterialData[key].file_name && readingMaterialData[key].file_name.length > 30 ? (
                                    readingMaterialData[key] && readingMaterialData[key].file_name && readingMaterialData[key].file_name && readingMaterialData[key].file_name.substr(0, 25) + '...'
                                  ) :
                                    readingMaterialData[key] && readingMaterialData[key].file_name && readingMaterialData[key].file_name && readingMaterialData[key].file_name
                                  }
                                </a>
                              </React.Fragment>
                            ) : null}
                          </React.Fragment>
                        </Grid>
                        <input type="file" accept=".pdf, .pptx, .docx, .xlsx, .csv" style={{ display: 'none' }} name="file_name" id={`readingFile_${key}`} onChange={(evnt) => { onReadingsUpload(evnt, key) }} />
                      </Grid>
                      <React.Fragment>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            autoFocus={(readingMaterialData && readingMaterialData[key] && readingMaterialData[key].reading_title) ? false : true}
                            variant="outlined"
                            id={`readings_${key + 1}`}
                            name="reading_title"
                            label={`Readings Title_${key + 1}`}
                            value={(readingMaterialData && readingMaterialData[key] && readingMaterialData[key].reading_title) || ''}
                            error={readingsErr.id === key && readingsErr.type === 'title' && readingsErr.error}
                            fullWidth
                            helperText={`max ${apiConfig.addingCourse.readingTitleLimit} char`}
                            inputProps={{
                              maxLength: apiConfig.addingCourse.readingTitleLimit
                            }}
                            onChange={(event) => { handleChangeReadings(event, key) }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: '15px' }}>
                          <TextField
                            required
                            variant="outlined"
                            id={`readingsdesc_${key + 1}`}
                            multiline
                            rows="2"
                            name="reading_desc"
                            label={`Readings Description_${key + 1}`}
                            value={(readingMaterialData && readingMaterialData[key] && readingMaterialData[key].reading_desc) || ''}
                            error={readingsErr.id === key && readingsErr.type === 'desc' && readingsErr.error}
                            fullWidth
                            helperText={`max ${apiConfig.addingCourse.readingDescLimit} char`}
                            inputProps={{
                              maxLength: apiConfig.addingCourse.readingDescLimit
                            }}
                            onChange={(event) => { handleChangeReadings(event, key) }}
                          />
                        </Grid>
                      </React.Fragment>
                      {courseOpenedMode !== 'visit_mode' && <div style={{ textAlign: 'right', marginTop: '-18px', marginBottom: '12px' }}>
                        <Button className={classes.deleteButton}
                          variant="outlined"
                          onClick={() => {
                            removeReadingsFile(key, getFileDeleteType(key));
                          }}
                          startIcon={<DeleteIcon />}
                          size="small"
                          disabled={inProgress}
                        >
                          {`Readings_${key + 1}`}
                        </Button>
                      </div>}
                      <Divider style={{ marginBottom: '20px' }} />
                    </div>
                  ))}
                </div>
              ) : null}
            </Grid>
            {inProgress && (
              <CircularProgress size={30} className={classes.buttonProgress} />
            )}
            <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
              {props.courseModuleDetails ? (
                <React.Fragment>
                  {courseOpenedMode !== 'visit_mode' ? (
                    <React.Fragment>
                      <Button className={classes.buttons} variant="contained" onClick={(evnt) => { submitForm(evnt, 'update') }} disabled={inProgress}> Update </Button>&thinsp;&thinsp;&thinsp;
                      <Button className={classes.buttons} variant="contained" onClick={(evnt) => { submitForm(evnt, 'update&AddLesson') }} disabled={inProgress}>{`Update & Add Lesson`}</Button>&thinsp;&thinsp;&thinsp;
                      <Button className={classes.buttons} variant="contained" disabled={addReadingsBtn || inProgress} endIcon={<AddCircleIcon />} onClick={addReadings}>
                        Readings
                      </Button>&thinsp;&thinsp;&thinsp;
                    </React.Fragment>
                  ) : null}
                  <Button className={classes.buttons} variant="contained" disabled={inProgress} onClick={landToModuleAssessment} endIcon={<AssignmentTurnedInIcon className={classes.iconWrapper} />} > Quiz </Button>&thinsp;&thinsp;&thinsp;
                  <Button className={classes.buttons} variant="contained" disabled={inProgress} onClick={landToModuleAssignment} endIcon={<AssignmentOutlinedIcon className={classes.iconWrapper} />} > Assignment </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button className={classes.buttons} variant="contained" onClick={(evnt) => { submitForm(evnt, 'save') }} disabled={inProgress}> Save </Button>&thinsp;&thinsp;&thinsp;
                  <Button className={classes.buttons} variant="contained" onClick={(evnt) => { submitForm(evnt, 'save&AddLesson') }} disabled={inProgress}>{`Save & Add Lesson`}</Button>&thinsp;&thinsp;&thinsp;
                  <Button className={classes.buttons} variant="contained" disabled={addReadingsBtn || inProgress} endIcon={<AddCircleIcon />} onClick={addReadings}>
                    Readings
                  </Button>&thinsp;&thinsp;&thinsp;
                  <Button className={classes.buttons} variant="contained" disabled={inProgress} onClick={moduleAssessment}> Quiz </Button>&thinsp;&thinsp;&thinsp;
                  <Button className={classes.buttons} variant="contained" disabled={inProgress} onClick={moduleAssignment}> Assignment </Button>
                </React.Fragment>
              )}
            </div>
            <Tooltip title="Form Preview">
              <IconButton edge="start" className={classes.iconButtonMargin} onClick={(evnt) => { submitForm(evnt, 'showPreview') }} aria-label="form preview" disabled={inProgress}>
                <VisibilityIcon style={{ fontSize: 20, marginTop: '-3px', color: '#2e8eec' }} />
              </IconButton>
            </Tooltip>
            {openFormPrev ? (
              <div>
                <ModulePreview openFormPrev={openFormPrev} closeFormPreview={closeFormPreview} moduleTitle={moduleTitle} moduleDesc={moduleDesc} moduleDurationDays={moduleDurationDays} moduleDurationHours={moduleDurationHours} moduleDurationMin={moduleDurationMin} bannerImageDetails={uploadedImagePreview} introVideoFileDetails={uploadedIntroVideoPreview} transcriptFileDetails={uploadedTranscriptFile} readingsData={readingMaterialData} />
              </div>
            ) : null}
          </Grid>
        </form>
        {showDeletePopup.popup && <Dialog open={showDeletePopup.popup} onClose={closeDeletePopup} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{`Are you sure you want to delete readings ${showDeletePopup.index + 1} ?`} </DialogContentText>
          </DialogContent>
          <DialogActions>
            {showDeletePopup.loader && (
              <CircularProgress size={25} />
            )}
            <Button onClick={deleteReadingsSelected} disabled={showDeletePopup.loader} color="primary" autoFocus>
              Ok
            </Button>
            <Button onClick={closeDeletePopup} disabled={showDeletePopup.loader} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>}
        {openDocsLibrary.dialog &&
          <DocsLibrarySelectionPopup
            title={moduleTitle}
            data={openDocsLibrary}
            close={closeDocsLibrayPopup}
            onSelecteDocsLibraryFile={onSelecteDocsLibraryFile}
          />
        }
      </Paper>
    </Grid >
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AddingModule);

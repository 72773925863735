import React from 'react';
import Skeleton from 'react-skeleton-loader';
import Grid from '@material-ui/core/Grid';

export default function CardListingLoader(props) {
  return(
    <div style={{ marginTop: '20px', paddingLeft: '50px', paddingRight: '50px', paddingTop: '20px' }}>
        <Grid container>
            <Grid item xs={8} sm={10} md={8} lg={10}>
              <Skeleton widthRandomness={0} height="80px" width="100%" color="#ccc" />
            </Grid>
            <Grid item xs={8} sm={10} md={8} lg={10}>
              <Skeleton widthRandomness={0} height="50px" width="100%" color="#ccc" />
            </Grid>
            <Grid item xs={8} sm={10} md={8} lg={10}>
              <Skeleton widthRandomness={0} height="30px" width="100%" color="#ccc" />
            </Grid>
            <Grid item xs={8} sm={10} md={8} lg={10}>
              <Skeleton widthRandomness={0} count={5} height="20px" width="100%" color="#ccc" />
            </Grid>
        </Grid>
    </div>
  )
}
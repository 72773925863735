import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '200px'
  },
  imagWrapper: {
    marginTop: '20px',
  },
  txtWrapper: {
    width: '100%',
    marginTop: '10px',
  },
  searchWrapper: {
    backgroundColor: '#f5f5f5',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 6,
    width: '100%'
  },
  paperWrapper: {
    backgroundColor: '#f5f5f5',
    width: '238px',
    height: '330px',
    display: 'inline-block'
  }
}))

export default function CourseListingLoader(props) {
    const classes = useStyles();
    return (
      <div>
        <div style={{ marginBottom: '20px'}}>
          <Skeleton widthRandomness={0} height="55px" width="92%" color="#ccc" />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '150px'}}>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} xm={12} md={3} lg={3}>
            <div className={classes.wrapper}>
              <Paper className={classes.paperWrapper}>
                <div className={classes.imagWrapper} >
                  <Skeleton height="100px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.imagWrapper} >
                  <Skeleton height="45px" width="200px" widthRandomness={0} heightRandomness={0} color="#ccc" />
                </div>
                <div className={classes.txtWrapper}>
                  <Skeleton widthRandomness={0} heightRandomness={0} count={4} width="70%" color="#ccc" />
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </div>
    )
}
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { xapiUserActivityFetch } from '../../store/MycourseListing/actionCreator'
import { convertUTCDatewithTime } from '../../Helpers/basic'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';

function UserActivityReport(props) {
  const [open, setOpen] = React.useState(props.open);
  const [scroll, setScroll] = React.useState('paper');
  const [activities, setActivities] = React.useState([]);
  const [activitiesInProgress, setActivitiesInProgress] = React.useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    props.closeActivity();
  };

  const descriptionElementRef = React.useRef(null);

  const xapiUserActivityFetchSuccessCB = res => {
    setActivitiesInProgress(false)
    setActivities(res.results)
  }
  const xapiUserActivityFetchFailureCB = error => {
    props.showSnackBar(
      {
        state: false,
        message: 'Sorry! something went wrong while fetching activities. Please Try Again.',
        type: 'error'
      }
    )
    setActivitiesInProgress(false)
    setActivities([])
  }
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      setActivitiesInProgress(true)
      xapiUserActivityFetch({
        course_id: props.courseDetails.id,
        user_id: props.userDetails.user_id
      }, xapiUserActivityFetchSuccessCB, xapiUserActivityFetchFailureCB
    )
    }

  }, [open]);


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" style={{ color: '#777' }}>
          {props.userDetails.first_name} {props.userDetails.last_name},
            <div style={{ fontSize: 12, marginTop: '-19px' }}><br />{props.userDetails.department_name}
            <br />{props.userDetails.email_id}
            </div>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          <React.Fragment>
            {activitiesInProgress ? 'Loading ...' : null}

              {activities.length ? (
                <div>
                  <List>
                  {activities.map((row, index) => (
                    <ListItem>
                      <span style={{ display: 'block', fontSize: 10 }}>&nbsp;On&nbsp;{convertUTCDatewithTime(row.timestamp)}</span>
                      ,&nbsp;&nbsp;<b>{row.verb.display['en-US']}</b>&nbsp;&nbsp;{row.object.definition.description.und}
                    </ListItem>
                  ))}
                  </List>
                </div>
              ) : activitiesInProgress ? '' : 'No data found'}
            </React.Fragment>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(UserActivityReport);

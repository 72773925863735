import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { deleteAssignment, deleteExternalAssignment } from '../../store/Assignments/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_DELETE_ASSIGNMENT } from '../Common/Constants/errorMessages'
import { SUCCESS_DELETE_ASSIGNMENT } from '../Common/Constants/successMessages'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x'
  }
}));

function DeleteAssignmentPopup(props) {
  const classes = useStyles();
  const open = props.openDeleteConfirm
  const [inProgress, setInprogress] = React.useState(false)

  const handleClose = () => {
    props.closeDeleteDialog();
  };

  const successCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_DELETE_ASSIGNMENT,
        type: 'success'
      }
    )
    props.listAllAssignment()
    handleClose()
  }

  const failureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_DELETE_ASSIGNMENT,
        type: 'error'
      }
    )  
  }
  const deleteConfirm = () => {
    setInprogress(true)
    if(props && props.from === 'externalType'){
      const payLoad = {
        assignment_id: props.assignmentData && props.assignmentData.id ? props.assignmentData.id : null
      }
      deleteExternalAssignment(payLoad, successCallBack, failureCallBack)
    }else{
      const payLoad = {
        assignment_id: props.assignmentData && props.assignmentData.assignment_id ? props.assignmentData.assignment_id : null
      }
      deleteAssignment(payLoad, successCallBack, failureCallBack)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title"> Are you sure you want to delete assignment?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { props.assignmentData && props.assignmentData.title && props.assignmentData.title.length > 40 ?
              (
                props.assignmentData.title.substr(0,30) + '...'
              ) : 
                props.assignmentData && props.assignmentData.title ? props.assignmentData.title : null
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        { inProgress ? (
          <CircularProgress size={30} className={classes.buttonProgress} />
        ): null }
          <Button onClick={deleteConfirm} disabled={inProgress} color="primary" variant="contained" autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose} disabled={inProgress} color="primary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
    
export default connect(
  mapStateToProps, { showSnackBar }
)(DeleteAssignmentPopup);

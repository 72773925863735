import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LeftMenu from '../AppLayout/leftMenu'
import MyVideosView from './myVideos'

function MyVideos(props){
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      <div>
        <Grid>
          <MyVideosView history={props.history} match={props.match}/>
        </Grid>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(MyVideos);
import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ManageAuthors from './manageAuthors'
import LeftMenu from '../AppLayout/leftMenu'

function Authors(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      <div>
        <ManageAuthors onCloseManageAuthors={props.onCloseManageAuthors} match={props.match} history={props.history} />
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(Authors);
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'
import Python from '../../static/dummyCourseImgs/python.jpg'
import java from '../../static/dummyCourseImgs/java.jpg'
import javascript from '../../static/dummyCourseImgs/javascript.jpg'
import reactjs from '../../static/dummyCourseImgs/reactjs.jpg'

export const mockListVideos = {
  results: [
    {
      id: 1,
      title: 'Test video1',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 1,
      level: 1,
      tags: ["Python","Development"],
      thumbnail_url: Python,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200508103006903822_python_download.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HSv7gGIqBx0CWXSbhZrTVsOUhDo%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-05-08T11:05:49.540000Z',
      views_count: '120',
      duration: '10:30'
    },
    {
      id: 2,
      title: 'Test video2',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 2,
      level: 2,
      tags: ["java","Development"],
      thumbnail_url: java,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-05-08T11:05:49.540000Z',
      views_count: '1K',
      duration: '30:00'
    },
    {
      id: 3,
      title: 'Test video3',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 3,
      level: 3,
      tags: ["javascript"],
      thumbnail_url: javascript,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200512142753606619_javascript_beginer_tutorial.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=AeCacZjpb0bBNlK%2BqVQ%2BAMO1V0Q%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-07-08T11:05:49.540000Z',
      views_count: '1.5K',
      duration: '10:00'
    },
    {
      id: 4,
      title: 'Test video4',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 7,
      level: 2,
      tags: ["Reactjs"],
      thumbnail_url: reactjs,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507153842400913_reactjs.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=iBRJvw0XULtRHoW2jp0UVvwQwYE%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-09-08T11:05:49.540000Z',
      views_count: '150',
      duration: '10:20'
    },
    {
      id: 5,
      title: 'Test video5',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 2,
      level: 2,
      tags: ["Java"],
      thumbnail_url: java,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-10-08T11:05:49.540000Z',
      views_count: '800',
      duration: '01:05:10'
    },
    {
      id: 1,
      title: 'Test video1',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 1,
      level: 1,
      tags: ["Python","Development"],
      thumbnail_url: Python,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200508103006903822_python_download.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HSv7gGIqBx0CWXSbhZrTVsOUhDo%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-05-08T11:05:49.540000Z',
      views_count: '120',
      duration: '10:30'
    },
    {
      id: 5,
      title: 'Test video5',
      description: `<p><span style="background-color:rgb(255,255,255);color:rgb(55,58,60);">In this class, you will learn about the most effective machine learning techniques, and gain practice implementing them and getting them to work for yourself. More importantly, you'll learn about not only the theoretical underpinnings of learning, but also gain the practical know-how needed to quickly and powerfully apply these techniques to new problems. Finally, you'll learn about some of Silicon Valley's best practices in innovation as it pertains to machine learning and AI.</span></p>`,
      category: 2,
      level: 2,
      tags: ["Java"],
      thumbnail_url: java,//"https://cedb-c.s3.amazonaws.com/media/hosting/banner/20200507133317243123_java_banner.jpg?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=HuZWfcccqjC4jS4sZoVI3XWAnh4%3D&Expires=1596471050",
      video_url: 'test.mp4',
      transcript: 'test.srt',
      created_date: '2020-10-08T11:05:49.540000Z',
      views_count: '800',
      duration: '01:05:10'
    }
  ]
}

export const uploadVideo = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.myVideos.uploadVideo}`
  axios.post(url, formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack()
  })
}

export const updateVideo = (payLoad, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.myVideos.updateVideo}${payLoad.id}/`
  axios.put(url, formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack()
  })
}

export const listVideos = (payLoad, successCallBack, failureCallBack) => {
  successCallBack(mockListVideos)
  //successCallBack({results:[]})
  // const url =`${apiConfig.myVideos.listVideos}`
  // axios.get(url, {
  //   params: payLoad
  // })
  // .then(function (response) {
  //   successCallBack(response.data)
  // })
  // .catch(function (error) {
  //   //failureCallBack(error)
  //   successCallBack(mockListVideos)
  // })
}
import { getEnv } from "../Helpers/basic";
const apiDomain = (env) => {
  switch (env) {
    case "prod":
      return "//api.myathina.com/";
    case "clone":
      return "//104.211.75.247/";
    case "dev":
    case "test":
    default:
      return "https://cmns.api.moocpedia.com/";
  }
};
const domain = apiDomain(getEnv());

export const apiConfig = {
  userAuth: {
    loginUrl: `${domain}accounts/auth/`,
    fetchProfile: `${domain}accounts/auth/profile/`,
    updateProfile: `${domain}accounts/profile/updatekeys/`,
    reqChangePasswordUrl: `${domain}api/password_reset/`,
    changePasswordUrl: `${domain}api/password_reset/confirm/`,
  },
  addingCourse: {
    acceptedImageFileSize: 2097152,
    acceptedVideoFileSize: 629145600,
    acceptedTranScriptSize: 209715200,
    acceptedLessonVideoFileSize: 629145600,
    courseTitleLimit: 500,
    readingTitleLimit: 100,
    readingDescLimit: 500,
    descSubTitleLimit: 500,
    descTitleLimit: 500,
    descriptionLimit: 3000,
    redirectPageTime: 3000,
    acceptedReadingFileSize: 11097152,
    acceptedImageFileFormats: ["jpg", "jpeg", "png"],
    acceptedVideoFileFormats: ["mp4"],
    acceptedTranscriptFileFormats: ["vtt", "srt"],
    acceptedReadingFileFormats: ["pdf", "docx", "pptx", "xlsx", "csv"],
    acceptedScormUploadFileFormats: ["zip", "tar.gz", "gz", "7z"],
    addCourseWelcome: `${domain}hosting/hostcourse/`,
    updatingCourseWelcome: `${domain}hosting/editcourse/`,
    CourseCategory: `${domain}hosting/category/`,
    courseDetailsfetch: `${domain}hosting/hostcourse/`,
    fetchCourseTags: `${domain}hosting/skill/`,
    fetchCourseLeftMenu: `${domain}hosting/display/`,
    uploadScorm: `${domain}hosting/uploadScrom/`,

    // uploadNodeScorm: `http://127.0.0.1:5000/upload`,
    uploadXapiCourse: `${domain}hosting/xapicourse/`,
    uploadScormCourse: `${domain}hosting/scormcourse/`,
    uploadNodeScormStatus: `http://127.0.0.1:5000/scormstatus/`,
    // uploadNodeScorm: `http://localhost:9000/.netlify/functions/index/upload/`,
    // uploadNodeScormStatus: `http://localhost:9000/.netlify/functions/index/scormstatus/`,
    //FOR DOCS LIBRARY CHANGES

    fetchDocsLibraryFiles: `${domain}docs/files/`, //FOR FETCHING THE DOCS LIBRARY DATA BASED ON QUERY PARAMS
    searchDocsLibraryFiles: `${domain}docs/search/`, //FOR SEARCH QUERY ON ALL THE TABS

    fetchOrgDesignations: `${domain}hosting/desinations/view/`//FOR FETCHING THE ORG DESIGNATIONS LIST
  },
  addModule: {
    showImageFileds: false,
    initiateModule: `${domain}hosting/module/`,
    updateModuleInfo: `${domain}hosting/editmodule/`,
    fetchModuleDetails: `${domain}hosting/module/`,
    deleteUploadedReadings: `${domain}hosting/module/` //FOR DELETING THE UPLOADED READINGS
  },
  addLesson: {
    initiateLesson: `${domain}hosting/lesson/`,
    updateLessonInfo: `${domain}hosting/editlesson/`,
    fetchLessonDetails: `${domain}hosting/lesson/`,
    deleteUploadedReadings: `${domain}hosting/lesson/` //FOR DELETING THE UPLOADED READINGS
  },
  assessment: {
    listAssessments: `${domain}hosting/assessment/`,
    create: `${domain}hosting/assessment/`,
    // fetchAssessment: `${domain}hosting/fetch/`,
    fetchAssessment: `${domain}hosting/question/display/`,
    update: `${domain}hosting/assessment/`,
    deleteAssessment: `${domain}hosting/assessment/delete/`,
    question: `${domain}hosting/assessment/question/`,
    allQuestions: `${domain}hosting/assessment/display/`,

    //FOR EXTERNAL ASSESSMENTS
    fetchExternalAssessments: `${domain}hosting/externalassessment/`,
    publishAssessment: `${domain}assessment/status/`,
    fetchAssessmentReport: `${domain}assessment/report/`,
    fetchAssRptTabCount: `${domain}assessement/report/tabcount/`

  },
  authorInfo: {
    acceptedImageFileSize: 2097152,
    acceptedImageFileFormats: ["jpg", "jpeg", "png"],
    fetchAuthors: `${domain}authors/fetch/`,
    assignAuthorsToCourse: `${domain}hosting/course/publish/`, //FOR ASSIGNING CONTRIBUTORS TO COURSE
    fetchCourseContributors: `${domain}hosting/course/instructors/`, //FOR FETCHING CONTRIBUTORS OF A COURSE USING COURSE ID
    postCourseWorkingUsersInfo: `${domain}hosting/course/modify/`, //FOR POSTING THE USERS INFO WHO ARE WORKING ON COURSE
    fetchCourseWorkingUsersInfo: `${domain}hosting/course/modify/`, //FOR FETCHING THE USERS INFO WHO ARE WORKING ON COURSE
    deleteCourseWorkingUserInfo: `${domain}hosting/course/modify/`, //FOR DELETING THE USER INFO WHO ARE DONE WITH WORK
    showMultiAuthorWarnPopup: `${domain}hosting/course/warnpopup/` //FOR NOT SHOWING THE MULTI AUTHOR WARN POPUP AGAIN AND AGAIN
  },
  myCourseListing: {
    courseListFetch: `${domain}hosting/course/publish/v2/`, //`${domain}hosting/course/publish/`,
    scormReportFetch: `${domain}hosting/scorm/report/`,
    xapiCountReportFetch: `${domain}hosting/xapicourse_count/`,
    xapiFetchLearnerList: `${domain}hosting/xapicourse_count/`,
    xapiUserActivityFetch: `${domain}hosting/xapi/allstatements/`,
    scormCountReportFetch: `${domain}hosting/scormcourse_count/`,
    scormFetchLearnerList: `${domain}hosting/scormcourse_count/`,
  },
  coursePublish: {
    publishCourse: `${domain}hosting/course/publish/v2/`,
    fetchDepartments: `${domain}docs/deptlist/`,
    submitReview: `${domain}hosting/review/`,
    mandateCourseTo: `${domain}accounts/usercourse/recommend/`,
    fetchTeamsList: `${domain}accounts/fetch_team_list/`
  },
  assignment: {
    acceptedAttachmentFileSize: 11097152,
    acceptedAssignmentFileFormats: ["pdf", "docx", "ppt"],
    listAssignments: `${domain}assignment/collective/assignment/`,
    fetchAssignment: `${domain}assignment/fetch/`,
    create: `${domain}assignment/create/`,
    update: `${domain}assignment/update/`,
    deleteAssignment: `${domain}assignment/delete/`,
    publishAssignment: `${domain}assignment/status/`,
    assignmentReport: `${domain}assignment/user/`,
    fetchStudentSubmissions: `${domain}assignment/answer/fetch/`,
    submitGrade: `${domain}assignment/review/`,
    //FOR EXTERNAL ASSIGNMENTS
    fetchExternalAssignments: `${domain}hosting/externalassignment/`,
    createExternalAssignment: `${domain}hosting/externalassignment/`,
    updateExternalAssignment: `${domain}hosting/externalassignment/`,
    deleteExternalAssignment: `${domain}hosting/externalassignment/`,
    publishExternalAssignment: `${domain}hosting/externalassignment/publish/`,
    fetchExternalAssignmentData: `${domain}hosting/externalassignment/`,
    fetchExternalAssignmentReport: `${domain}assignment/external/user/`,
    fetchExternalAssRptTabCount: `${domain}assignment/report/tabcount/`
  },
  gradebook: {
    list: `${domain}assignment/gradebook/`,
    update: `${domain}assignment/gradebook/update/`,
    assessmentReport: `${domain}hosting/assessment/result/`,
    fetchStudentSubmissions: `${domain}assignment/gradebook/submission/`,
  },
  discussions: {
    create: `${domain}discussion/create/`,
    listDiscussions: `${domain}discussion/view/`,
    publishDiscussion: `${domain}discussion/publish/`,
    deleteDiscussion: `${domain}discussion/delete/`,
    update: `${domain}discussion/update/`,
    fetchDiscussion: `${domain}discussion/fetch/`,
    postReply: `${domain}discussion/reply/`,
    postComment: `${domain}discussion/comment/`,
    submitGrade: `${domain}discussion/grading/`,
    updateGrade: `${domain}discussion/grading/update/`,
    respondedStudents: `${domain}discussion/panel/`,
    fetchStudentResponses: `${domain}discussion/user/reply/`,
    fetchResponses: `${domain}discussion/list/reply/paginate/`,
    fetchSubReplies: `${domain}discussion/list/comment/paginate/`,
    fetchPinnedResponses: `${domain}discussion/pin/listing/`,
    pinDiscussion: `${domain}discussion/pin/`,
    //EXTERNAL DISCUSSION BOARD API CALL
    createExternalDiscussion: `${domain}discussion/external/`, //FOR CREATING DISCUSSION
    updateExternalDiscussion: `${domain}discussion/external/`, //FOR UPDATING DISCUSSION
    deleteExternalDiscussion: `${domain}discussion/external/`, //FOR DISCUSSION DELETE
    fetchAllExternalDiscussions: `${domain}discussion/external/`, //FOR FETCHING ALL THE DISCUSSIONS
    assignDiscussionToGrps: `${domain}discussion/external/`, //for assigning discussion to grps
    publishExternalDiscussion: `${domain}discussion/external/`, //FOR PUBLISH/UNPUBLISH DISCUSSION
    fetchExternalTabCounts: `${domain}discussion/external/`, //FOR FETCHING TAB COUNTS
    fetchExternalDiscussion: `${domain}discussion/external/`, //FOR FETCHING SINGLE DISCUSSOIN DETAILS
    externalPostReply: `${domain}discussion/external/reply/`, //FOR POSTING REPLY TO A DISCUSSION
    fetchExternalResponses: `${domain}discussion/external/reply/`, //FOR FETCHING DISCUSSION RESPONSES
    pinExternalDiscussion: `${domain}discussion/external/pin/`, //for pinning external discussion
    fetchExternalPinnedResponses: `${domain}discussion/external/pin/listing/`, //FOR FETCHING EXTERNAL PINNED RESPONSES
    externalPostComment: `${domain}discussion/external/comment/`, //FOR POSTING COMMENT TO A RESPONSE
    fetchExternalSubReplies: `${domain}discussion/external/comment/listing/`, //FOR FETCHING SUB REPLIES FOR DISCUSSION
    externalRespondedStudents: `${domain}discussion/external/panel/`, //FOR FETCHING RESPONDED STUDENTS LIST
    fetchExternalStudentResponses: `${domain}discussion/external/user/reply/`, //FOR FETCHING STUDENT RESPONSES
    externalSumbitGrade: `${domain}discussion/external/grading/`, //FOR ADDING GRADE TO A DISCUSSION
    externalUpdateGrade: `${domain}discussion/external/grading/update/`, //FOR UPDATE GRADE TO A DISCUSSION
  },
  meetings: {
    createEvent: `${domain}live_events/live_event/`,
    modifyEvent: `${domain}live_events/live_event/`,
    getEventList: `${domain}live_events/list_event/`,
    getCourseList: `${domain}hosting/course/publish/`,
    deleteEvent: `${domain}live_events/live_event/`,
    fetchEventLabels: `${domain}live_events/labels/`,
    fetchAllStores: `${domain}offices/venues/`,
  },
  plagiarism: {
    checkPlagiarism: `${domain}assignment/plagiarism/check/`,
  },
  myVideos: {
    uploadVideo: `${domain}videos/upload/`,
    updateVideo: `${domain}videos/update/`,
    listVideos: `${domain}videos/listvideos/`
  },
  videoClasses: {
    fetchRecordings: `${domain}live_events/recorded_events/`,
    fetchGroups: `${domain}groups/`,
    assignedClasses: `${domain}live_events/assign_recordings/`,
    assignRecordings: `${domain}live_events/assign_recordings/`,
    updateRecordings: `${domain}live_events/assign_recordings/`,
    classesAssignedToMe: `${domain}live_events/shared_recordings/`,
    fetchUsers: `${domain}accounts/mentor_list/`,
    archiveRecs: `${domain}live_events/archive/`,
  },
  manageGroups: {
    createGroup: `${domain}accounts/user_group/`, //FOR CREATING GROUP DATA
    fetchGroupsList: `${domain}accounts/user_group/`, //FOR FETCHING ALL THE GRPS
    fetchSingleGrpData: `${domain}accounts/user_group/`, //FOR FETHCING SINGLE GRP
    updateGroup: `${domain}accounts/user_group/`, //FOR UPDATING GROUP DATA
    fetchUsersDataTabCnt: `${domain}accounts/members_tab_count/`, //FOR FETCHING USERDATA TAB COUNT
    fetchOrgMembersListing: `${domain}accounts/list_members/`, //FOR FETCHING STUDENTS, MENTORS, INSTRUCTORS LISTING
    assignMentorToStudent: `${domain}accounts/assign/mentor/`, //FOR ASSIGNING/CHANGE MENTOR TO STUDENT
    assignStudentsToMentor: `${domain}accounts/assign/students/`, //FOR ASSIGNING STUDENTS TO A MENTOR
    submitGrpMembers: `${domain}accounts/add_group_members/`, //FOR SUBMITTING THE SELECTED MEMBERS IN GRP
    fetchGrpStructure: `${domain}accounts/group_structure/`, //FOR FETCHING THE ENTIRE GRP STRUCTURE
    fetchMentorStudentsList: `${domain}accounts/mentor_student_list/`, //FOR FETCHING STUDENTS UNDER MENTOR
    fetchOrgGrpsList: `${domain}accounts/group_list/`, //for dropdown grp structure selection
  },
  noticeBoard: {
    fetchAllGroup: `${domain}groups/fetchlist/`,
    sendNotice: `${domain}notices/notice/`,
    fetchSentNotices: `${domain}notices/notice/`,
    fetchMyNotices: `${domain}notices/notice/`,
    updateViewNoticeStatus: `${domain}notices/update_notice_read_status/`,
    fetchHeaderNoticeCount: `${domain}notices/notice_read_count/`,
    deleteNotice: `${domain}notices/delete_notice/`,
    updateNotice: `${domain}notices/update_notice/`
  },
};

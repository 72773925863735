import React from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';

const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    info: ErrorIcon,
  };
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fcfcfc',
    width: '100%',
    marginTop: 100,
  },
  info: {
    color: 'orange',
    border: '1px solid orange',
    padding: 10,
  },
  infoText: {
    letterSpacing: '1px',
    fontWeight: 700,
    color: 'orange'
  },
  success: {
    backgroundColor: '#d0edd0',
    color: '#333',
    border: '1px solid green',
    padding: 10
  },
  error: {
    textAlign: 'center',
    color: '#ee5250',
    width: '100%',
    padding: '6px',
    border: '1px solid #ee5250',
    boxShadow: 'none'
  },
  icon: {
    fontSize: 20,
    verticalAlign: '-5px'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  }
}));
export default function AlertMessages(props) {
  const classes = useStyles();
  const Icon = variantIcon[props.status];

  return(
    <Paper className={clsx(classes[props.status])}>
      <Typography variant="caption" display="block" gutterBottom className={props.status === 'info' ? classes.infoText : ''}>
      <Icon className={clsx(classes.icon, classes.iconVariant)} />
        {props.message ? props.message : 'Sorry! Something went wrong. Please try again.'}
      </Typography>
    </Paper>
  )
}
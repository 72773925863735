import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
//local imports
import { style } from '../../Styles/theme';


const useStyles = makeStyles(theme => ({
  highlightText: {
    fontSize: '1rem',
    fontWeight: 700
  },
  headerWrapper: {
    color: style.fontColour._blue
  },
  button: {
    color: style.fontColour._blue
  },
  chipWrapper: {
    color: style.fontColour._blue,
    borderColor: style.fontColour._blue,
    marginRight: '10px',
    marginBottom: '10px'
  }
}));

function ViewMentorStudents(props) {
  const classes = useStyles()
  const open = props && props.open 
  const data = props && props.data && props.data.length ? props.data : []

  return(
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={props.close}
        aria-labelledby={ props.type === 'showmentees' ? "My Students Information" : "My Group Information" }
        aria-describedby={ props.type === 'showmentees' ? "assigned students for mentor" : "My Group Information" } 
      >
        <DialogTitle id="alert-dialog-title" className={classes.headerWrapper}>{ props.type === 'showmentees' ? 'My Students Information' : 'My Group Information'}</DialogTitle>
        <DialogContent style={{ height: data.length ? 200 : '', overflowY: 'scroll' }}>
          { data && data.length ? (
            <React.Fragment>
              { data.map((row, index) => (
                <Chip
                  key={index}
                  className={classes.chipWrapper}
                  label={row}
                  variant="outlined"
                />
              ))}
            </React.Fragment>
          ) : (
            <p style={{ fontSize: '18px', textAlign: 'center' }}>No data found!</p>
          ) }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} className={classes.button}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(ViewMentorStudents);
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
import dummyPython from '../../static/dummyPython.png'
import dummyJava from '../../static/dummyjava.png'

export const mockCourseListing = {
  results: [
    {
      banner_url: dummyPython,
      category: 4,
      id: 2,
      isscorm: false,
      level: 1,
      published: false,
      remark: null,
      review_status: null,
      scorm_url: null,
      tags: ["python"],
      title: "Python Basics",
      is_author: false,
      author_name: 'Test user'
    },
    {
      banner_url: dummyPython,
      category: 4,
      id: 2,
      isscorm: false,
      level: 1,
      published: false,
      remark: null,
      review_status: 'pending',
      scorm_url: null,
      tags: ["python"],
      title: "Python Basics",
      is_author: false,
      author_name: 'Test user'
    },
    {
      banner_url: dummyPython,
      category: 4,
      id: 2,
      isscorm: false,
      level: 1,
      published: false,
      remark: 'files are corrupted',
      review_status: 'rejected',
      scorm_url: null,
      tags: ["python"],
      title: "Python Basics",
      is_author: false,
      author_name: 'Test user'
    },
    {
      id: 2,
      title: 'scorm course2',
      banner_url: dummyPython,
      level: 2,
      category: 5,
      tags: ["javascript"],
      published: true,
      remark: null,
      review_status: null,
      isscorm: true,
      scorm_url: "https://rendering.moocpedia.com/mdb-aa050520/ansrCourse1/scormcontent/index.html#/",
      is_author: false,
      author_name: 'Test user'
    },
    {
      banner_url: dummyJava,
      category: 4,
      id: 2,
      isscorm: false,
      level: 1,
      published: true,
      remark: null,
      review_status: null,
      scorm_url: null,
      tags: ["python"],
      title: "Python Basics",
      is_author: false,
      author_name: 'Test user'
    },
  ]
}

export const reportData = {
  "results": [
      {
          "id": 283,
          "course_id": 1234,
          "first_name": "user",
          "last_name": "organization",
          "scorm_data": {
              "percent": 100,
              "lesson": [
                  {
                      "percent": 100
                  },
                  {
                      "percent": 20
                  }
              ]
          },
          "visited_date": "2020-05-13T13:14:10.156770Z"
      },
      {
          "id": 284,
          "course_id": 1234,
          "first_name": "",
          "last_name": "",
          "scorm_data": {
              "percent": 100,
              "lesson": [
                  {
                      "percent": 100
                  },
                  {
                      "percent": 20
                  }
              ]
          },
          "visited_date": "2020-05-13T13:16:18.198709Z"
      }
  ],
  "total_visitor": 2
}


export const fetchMycourseList = (payLoad, successCallBack, failureCallBack) => {
    const url =`${apiConfig.myCourseListing.courseListFetch}`
    axios.get(url, {
      params: payLoad})
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
      //successCallBack(mockCourseListing)
    })
  }

export const scormReportFetch = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.myCourseListing.scormReportFetch}`
  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    //successCallBack(reportData)
  })
}
export const scormCountReportFetch = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.myCourseListing.scormCountReportFetch}`

  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    // successCallBack({results:{"total": 250, "completed": 30, "inprogress": 20}})
  })
}
export const xapiCountReportFetch = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.myCourseListing.xapiCountReportFetch}`

  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    console.log(error)
    failureCallBack(error)
    // successCallBack({results:{"total": 250, "completed": 30, "inprogress": 20}})
  })
}
export const scormFetchLearnerList = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.myCourseListing.scormFetchLearnerList}`
  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    // successCallBack(mockData)
  })
}
export const xapiFetchLearnerList = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.myCourseListing.xapiFetchLearnerList}`
  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    // successCallBack(mockData)
  })
}
export const xapiUserActivityFetch = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.myCourseListing.xapiUserActivityFetch}`
  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    // successCallBack(mockData)
  })
}

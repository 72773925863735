import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { publishCourse } from '../../store/AddingCourse/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_COURSE_UNPUBLISH } from '../Common/Constants/errorMessages'
import { SUCCESS_COURSE_UNPUBLISH } from '../Common/Constants/successMessages'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x'
  },
  btnWrapper: {
    color: '#fff',
    backgroundColor: '#2e8eec',
    '&:hover': {
      backgroundColor: '#2e8eec'
    },
    textTransform: 'none'
  }
}));

function CourseUnPublishPopup(props) {
  const classes = useStyles();
  const open = props.openUnpublishPopup
  const courseId = props.unPublishCourseId ? props.unPublishCourseId : null
  const [inProgress, setInprogress] = React.useState(false)

  const handleClose = () => {
    props.closeunPublishPopup();
  };

  const successCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_COURSE_UNPUBLISH,
        type: 'success'
      }
    )
    props.courseListFetch()
    handleClose()
  }

  const failureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_COURSE_UNPUBLISH,
        type: 'error'
      }
    )
  }
  const unPublishCourseonClick = () => {
    setInprogress(true)
    const payload = {
      course_id: courseId,
      published: false
    }
    publishCourse(payload, successCallBack, failureCallBack)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title"> Are you sure you want to Unpublish this course?</DialogTitle>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={30} className={classes.buttonProgress} />
          ) : null}
          <Button onClick={unPublishCourseonClick} disabled={inProgress} variant="contained" className={classes.btnWrapper}>
            Yes
          </Button>
          <Button onClick={handleClose} disabled={inProgress} variant="outlined" style={{ color: '#2e8eec', borderColor: '#2e8eec' }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(CourseUnPublishPopup);

import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DummyBannerImg from '../../static/bannerDummy.jpg'
import { courseLevel } from '../../Configs/courseLevels'
import { find } from 'lodash'
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  courseInfo: {
    position: 'absolute',
    top: 0,
    color: '#f5f5f5',
    backgroundColor: '#cccccc59',
    width: '100%',
    height: '100%',
    padding: 10
  },
}))
function Header(props) {
  const courseData = props.courseData
  const classes = useStyles();
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  const getCourseLevel = levelId => {
    if (levelId) {
      return find(courseLevel, { 'id': levelId }).name || null
    }
  }
  const navigateToCourse = () => {
    if (props.match.params.lessonId) {
      if (courseOpenedMode) {
        props.history.push(`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}`)
      }
    }
    else if (props.match.params.moduleId) {
      if (courseOpenedMode) {
        props.history.push(`/mycourse/${props.match.params.courseId}/${courseData.id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${props.match.params.courseId}/${courseData.id}`)
      }
    } else if (props.match.params.courseId) {
      if (courseOpenedMode) {
        props.history.push(`/mycourse/${courseData.id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${courseData.id}`)
      }
    }
  }
  return (
    <div style={{ position: 'relative', marginTop: '-1px', textAlign: 'left' }}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <p>Course navigation</p>
      </div>
      <img
        title={courseData.title}
        alt={courseData.title}
        src={DummyBannerImg}
        style={{
          width: '100%',
          height: 75
        }}
      />
      <div className={classes.courseInfo}>
        <Typography variant="h4" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={navigateToCourse}>
          {courseData && courseData.title && courseData.title}
        </Typography>
        <Typography variant="subtitle1">
          {getCourseLevel(courseData && courseData.level && courseData.level)}
        </Typography>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(Header);

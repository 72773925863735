import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LeftMenu from '../AppLayout/leftMenu'
import AddAssignmentCourseLevel from './courseLevelAssignment'
import AddAssignmentModuleLevel from './moduleLevelAssignment'
import AddAssignmentLessonLevel from './lessonLevelAssignment'
import AssignmentReportView from './assignmentReport'

function AddAssignmentInput(props){
  const renderReportView = () => {
    if(props.match.params.assignmentId){
      return(
        <AssignmentReportView history={props.history} match={props.match}/>
      )
    }else if(props.match.params.lessonId){
      return(
        <AddAssignmentLessonLevel history={props.history} match={props.match}/>
      )
    }else if(props.match.params.moduleId){
      return(
        <AddAssignmentModuleLevel history={props.history} match={props.match}/>
      )
    }
    else if(props.match.params.courseId){
      return(
        <AddAssignmentCourseLevel history={props.history} match={props.match}/>
      )
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      <div>
        {renderReportView()}
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
   return {}
 }

 export default connect(
   mapStateToProps, {}
 )(AddAssignmentInput);

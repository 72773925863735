import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { find, groupBy } from 'lodash';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import AlertMessages from '../Common/AlertMessages';
import AddDocButton from './addDocButton'
import { addQuestion } from '../../store/Assessments/actionCreator'
import { ERROR_ADD_QUESTION } from '../Common/Constants/errorMessages';
import { SUCCESS_ADD_QUESTION } from '../Common/Constants/successMessages';
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    border: '3px solid #f5f5f5',
    marginTop: 20
  },
  form: {
    '& .MuiTextField-root': {
      margin: 20,
    },
  },
  field: {
    width: '90%',
    '&.option': {
      width: '40%',
      margin: 0
    }
  },
  duplicate: {
    color: 'red',
    marginRight: 10,
    display: 'none',
    '&.show': {
      display: 'inline-block'
    }
  },
  addOptionButton: {
    textAlign: 'left',
    marginLeft: 50
  }

}));

function MultiSelect(props) {
  const questionDetails = props.questionDetails
  const classes = useStyles();
  const [inProgress, setInProgress] = React.useState(false)
  const [options, setOptions] = React.useState(questionDetails.options || [])
  let selectedCorrectAnswers = questionDetails.correct_answer || []
  let formatedCorrectAnswers = []
  for (let i in selectedCorrectAnswers) {
    formatedCorrectAnswers.push(Number(selectedCorrectAnswers[i]))
  }
  const [correctOption, setCorrectOption] = React.useState(formatedCorrectAnswers || []);
  const [validationStatus, setValidationStatus] = React.useState({
    isValid: true,
    errorMessages: '',
    isDuplicateOption: false
  });
  const courseOpenedMode = getCourseOpenModeType();

  const addOption = () => {
    let allOptions = [...options]
    allOptions.push({ option: '' })
    setOptions(allOptions)
  }
  const onChangeCorrectOption = event => {
    const selectedVal = Number(event.target.value)

    let newCorrectOption = []
    if (correctOption.indexOf(selectedVal) > -1) {
      newCorrectOption = correctOption.filter(function (value, index, arr) { return value !== selectedVal })
    } else {
      newCorrectOption = [...correctOption]
      newCorrectOption.push(selectedVal)
    }
    setCorrectOption(newCorrectOption);
  }
  const successCallBack = res => {
    setInProgress(false)
    props.onQuestionAction()
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ADD_QUESTION,
        type: 'success'
      }
    )
  }
  const failureCallBack = error => {
    setInProgress(false)
    props.onQuestionAction()
    props.showSnackBar(
      {
        state: true,
        message: ERROR_ADD_QUESTION,
        type: 'error'
      }
    )
  }
  const formatOptions = () => {
    let enteredOptions = []
    for (let i in options) {
      const option = document.getElementById('option_' + i).value
      enteredOptions.push({ id: i, option: option })
    }
    setOptions(enteredOptions)
    return enteredOptions
  }
  const removePrevDuplicates = () => {
    const duplicates = document.getElementsByClassName("show");
    if (duplicates.length) {
      for (let i in duplicates) {
        if (duplicates[i] && duplicates[i].classList) {
          duplicates[i].classList.remove('show')
        }
      }
    }
  }
  const getCorrectOptions = enteredOptions => {
    let correctOptions = []
    for (let i in enteredOptions) {
      const elem = document.getElementById('check_' + i)
      if (elem.checked) {
        correctOptions.push(Number(elem.value))
      }
    }
    return correctOptions
  }
  const triggerAddQuestion = evnt => {
    evnt.preventDefault()
    setValidationStatus({
      isValid: true,
      errorMessages: '',
    })
    removePrevDuplicates()
    const question = document.getElementById('question').value
    const enteredOptions = formatOptions()
    const emptyOption = find(enteredOptions, { option: '' })
    const groupedBy = groupBy(enteredOptions, 'option')
    let isDuplicateOption = false
    for (let i in groupedBy) {
      if (groupedBy[i].length > 1) {
        isDuplicateOption = true
        const lastElem = groupedBy[i].length - 1
        const element = document.getElementById('duplicate_' + groupedBy[i][lastElem].id)
        element.classList.add('show');
      }
    }
    if (emptyOption || question === '') {
      setValidationStatus({
        isValid: false,
        errorMessages: 'Please fill all required fields!',
      })
      return
    }
    if (options.length < 2) {
      setValidationStatus({
        isValid: false,
        errorMessages: 'Please add minimum 2 options!',
      })
      return
    }
    if (isDuplicateOption) {
      setValidationStatus({
        isValid: false,
        errorMessages: 'Please avoid duplicate entries!',
      })
      return
    }
    const correctOptions = getCorrectOptions(enteredOptions)
    if (!correctOptions || !correctOptions.length) {
      setValidationStatus({
        isValid: false,
        errorMessages: 'Please select correct option!',
      })
      return
    }
    setInProgress(true)
    if (props.lessonId) {
      const payload = {
        lesson_id: Number(props.lessonId),
        assessment_id: props.assessmentId,
        type: 'checkbox',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        correctAnswer: correctOptions,
        options: enteredOptions
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    }
    else if (props.moduleId) {
      const payload = {
        module_id: Number(props.moduleId),
        assessment_id: props.assessmentId,
        type: 'checkbox',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        correctAnswer: correctOptions,
        options: enteredOptions
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    }
    else if (props.courseId) {
      const payload = {
        course_id: Number(props.courseId),
        assessment_id: props.assessmentId,
        type: 'checkbox',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        correctAnswer: correctOptions,
        options: enteredOptions
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    } else if (props && props.from === 'externalType') {
      const payload = {
        assessment_id: props.assessmentId,
        type: 'checkbox',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        correctAnswer: correctOptions,
        options: enteredOptions
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    }
  }
  const isSelectedChecked = key => {
    return correctOption.indexOf(key) > -1
  }
  return (
    <Paper elevation={0} className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={triggerAddQuestion}>
        {!validationStatus.isValid ? (
          <div style={{ marginTop: '20px', width: '42%', marginBottom: '18px', display: 'inline-block' }}>
            <AlertMessages status="error" message={validationStatus.errorMessages} />
          </div>
        ) : null}
        <TextField
          id="scenario"
          label="Scenario"
          aria-label="Add Question scenario"
          defaultValue={questionDetails.scenario || ''}
          multiline
          rows="4"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 1000 }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText="max: 1000 chars"
          autoFocus={true}
        />
        <TextField
          id="question"
          defaultValue={questionDetails.question || ''}
          required={true}
          label="Question"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 500 }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText="max: 500 chars"
        />
        {options.length ? (
          <div>
            {options.map((row, key) => (
              <div key={key} style={{ width: '100%', marginBottom: 30 }}>
                <span id={`duplicate_${key}`} className={classes.duplicate} aria-label="Duplicate entry">duplicate</span>
                <TextField
                  required
                  autoFocus={true}
                  id={`option_${key}`}
                  label={`Option ${key + 1}`}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={row.option}
                  placeholder={`Enter option ${key + 1}`}
                  inputProps={{ maxLength: 250, required: true }}
                  helperText="Max 250 Characters"
                  className={classes.field + ' option'}
                />
                <FormControlLabel
                  value={key}
                  control={<Checkbox id={`check_${key}`} color="primary" checked={isSelectedChecked(key)} />}
                  label="select as Correct Answer"
                  labelPlacement="end"
                  style={{ marginLeft: 20 }}
                  onChange={onChangeCorrectOption}
                />
              </div>
            ))}
          </div>
        ) : null}
        <div style={{ width: '40%', marginLeft: 33 }}>
          {courseOpenedMode !== 'visit_mode' ? (
            <React.Fragment>
              <AddDocButton onClick={addOption} />
              <Link
                component="button"
                variant="body2"
                onClick={addOption}
              >Add Option
              </Link>
            </React.Fragment>
          ) : null}
        </div>
        <div>
          {courseOpenedMode !== 'visit_mode' ? (
            <React.Fragment>
              <Button variant="contained" color="primary" onClick={triggerAddQuestion} disabled={inProgress}>
                {inProgress ? (
                  <CircularProgress size={30} style={{ marginRight: 10 }} />
                ) : null}
                {questionDetails.id ? 'Update question' : 'Add question'}
              </Button>
            </React.Fragment>
          ) : null}
        </div>
      </form>
    </Paper>
  )

}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(MultiSelect);

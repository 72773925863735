import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { xapiCountReportFetch, xapiFetchLearnerList } from '../../store/MycourseListing/actionCreator'
import { ERROR_FETCH_COURSE_DETAILS, ERROR_FETCH_SCORM_REPORT } from '../Common/Constants/errorMessages'
import { convertUTCDatewithTime } from '../../Helpers/basic'
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator'
import Header from './header'
import UserActivityReport from './userActivityReport'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'
const useStyles = makeStyles({
  courseChart: {
    position: 'relative',
    // height: '140px',
    width: '281px',
    float: 'left'
  },
  courseChartDataWrapper: {
    display: 'inline-block',
    marginTop: '-15px'
  },
  chart: {
    marginTop: '-23px',
    display: 'inline-block',
    width: '242px',
    marginLeft: '-17px',
    position: 'relative'
  },
  dataPoint: {
    fontSize: '20px'
  },
  tblCell: {
    color: '#777'
  },
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    paddingTop: '8px',
    backgroundColor: '#f5f5f5'
  },
  tableHeadingCell: {
    padding: '12px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#333',
    backgroundColor: '#dedede'
  },
  mainWrapper: {
    paddingLeft: '120px',
    paddingRight: '120px',
    width: '100%',
    marginTop: '2rem'
  },
  buttonProgress: {
    marginTop: '10px'
  },
  textWrap: {
    textAlign: 'initial',
    marginBottom: '10px',
    color: '#3e98c8',
    fontWeight: 500
  },
  visitorContainer: {
    margin: '0.5rem'
  },
  tableCell: {
    padding: "12px"
  }

});

function CourseReport(props) {
  const classes = useStyles();
  const [courseData, setCourseData] = React.useState({});
  const [reportData, setReportData] = React.useState([])
  const [inProgress, setInProgress] = React.useState(true)
  const [learnerListInProgress, setLearnerListInProgress] = React.useState(false)
  const [learnerList, setLearnerList] = React.useState([])
  const [selectedType, setSelectedType] = React.useState('')
  const [activityStatus, setActivityStatus] = React.useState({
    open: false,
    userDetails: {}
  })
  const lableArr = ['Not Started', 'In Progress', 'Completed']
  const listLoader = count => {
    let children = []
    for (let i = 0; i < 3; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  const courseSuccessCallBack = res => {
    setCourseData(res.results)
  }
  const courseFailureCallBack = error => {
    setCourseData({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_COURSE_DETAILS,
        type: 'error'
      }
    )
  }
  const fetchLearnerListSuccessCB = res => {
    setLearnerListInProgress(false)
    setLearnerList(res.learner_list)
  }
  const fetchLearnerListFailureCB = err => {
    props.showSnackBar(
      {
        state: false,
        message: 'Sorry, something went wrong while fetching learners list. Please Try Again!',
        type: 'error'
      }
    )
    setLearnerListInProgress(false)
    setLearnerList([])
  }
  const onChartItemClick = (e, arg2, chartElement) => {

    let listType = 'in-progress'
    listType = (lableArr[arg2[0].index] === 'Not Started') ? 'yet-to-start' : listType
    listType = (lableArr[arg2[0].index] === 'In Progres') ? 'in-progress' : listType
    listType = (lableArr[arg2[0].index] === 'Completed') ? 'completed' : listType
    setSelectedType(lableArr[arg2[0].index])

    setLearnerListInProgress(true)
    xapiFetchLearnerList({
      type: listType,
      course_id: props.match.params.courseId,
      isxApi: true
    },
      fetchLearnerListSuccessCB, fetchLearnerListFailureCB
    )
  }
  const configureCountReportChart = res => {
    const courseChart = document.getElementById('countReportChart')

    const dataArr = [res.total - (res.completed + res.inprogress), res.inprogress, res.completed]
    const chart = new Chart(courseChart, {
      type: 'doughnut',
      data: {
        labels: lableArr,
        datasets: [{
          label: '# of Votes',
          data: dataArr,
          backgroundColor: [
            "#ffff00",
            '#45c490',
            '#008d93',
            '#F4A540',
            '#ff8f8f',
            '#ccc'
          ],
          borderColor: [
            "#ffff00",
            '#45c490',
            '#008d93',
            '#F4A540',
            '#ff8f8f',
            '#ccc'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: false
        },
        legend: {
          onHover: function (e) {
            e.target.style.cursor = 'pointer';
          }
        },
        onHover: function (e, arg2) {
          var el = document.getElementById("countReportChart");
          el.style.cursor = arg2[0] ? "pointer" : "default";
        },
        onClick: onChartItemClick,
        tooltips: {
          enabled: true
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        }
      }
    });
  }
  const reportSuccessCallBack = res => {
    setInProgress(false)
    setReportData(res)
    configureCountReportChart(res)
  }

  const reportFailureCallBack = error => {
    props.showSnackBar(
      {
        state: false,
        message: 'Sorry, something went wrong while fetching report. Please Try Again!',
        type: 'error'
      }
    )
    setReportData([])
    setInProgress(false)
  }

  useEffect(() => {
    setInProgress(true)
    courseDetailsFetch({
      id: props.match.params.courseId
    }, courseSuccessCallBack, courseFailureCallBack)
    xapiCountReportFetch({
      course_id: props.match.params.courseId,
      isxApi: true
    }, reportSuccessCallBack, reportFailureCallBack)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  const showActivity = obj => {
    setActivityStatus({
      open: true,
      userDetails: obj
    })
  }
  const closeActivity = () => {
    setActivityStatus({
      open: false,
      userDetails: {}
    })
  }
  return (
    <div >
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Course report Page</h1>
      </div>
      <div style={{ marginTop: '-80px' }}>
        <Header courseData={courseData} match={props.match} history={props.history} />
      </div>
      <div className={classes.mainWrapper}>
        {inProgress ? (
          listLoader()
        ) : (
          <React.Fragment>
            {reportData && Object.keys(reportData) ? (
              <React.Fragment>
                <div >
                  <Paper style={{ display: 'inline-block', padding: 20 }}>
                    <div className={classes.courseChart}>
                      <canvas id="countReportChart" className={classes.chart} aria-label={`${courseData.title} report chart`} role="img" ></canvas>
                      <Typography variant="caption" display="block" style={{ marginTop: 10, color: '#777' }}>
                        Click on the chart area to get detailed report
                      </Typography>
                    </div>
                    <div className={classes.courseChartDataWrapper}>
                      <Typography variant="h5" gutterBottom component="div" style={{ color: '#777' }}>{courseData.title}</Typography>
                      <TableContainer component={Paper} style={{ backgroundColor: '#f5f5f5' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableBody>
                            <TableRow>
                              <TableCell align="left" className={classes.tblCell}>Published On</TableCell>
                              <TableCell align="left" className={classes.tblCell}>{convertUTCDatewithTime(reportData.publish_date)}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" className={classes.tblCell}>Assigned to</TableCell>
                              <TableCell align="left" className={classes.tblCell}>
                                <span className={classes.dataPoint}>{reportData.total}</span> members
                                <div>
                                  <span style={{ fontStyle: 'italic', fontSize: 12 }}>In Progress:</span> {reportData.inprogress}
                                </div>
                                <div>
                                  <span style={{ fontStyle: 'italic', fontSize: 12 }}>Completed:</span> {reportData.completed}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" className={classes.tblCell}>Average time of completion</TableCell>
                              <TableCell align="left" className={classes.tblCell}><span className={classes.dataPoint}>{Number(reportData.average_time).toFixed(2)}</span> Hours</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" className={classes.tblCell}>Average score</TableCell>
                              <TableCell align="left" className={classes.tblCell}><span className={classes.dataPoint}>70%</span></TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" className={classes.tblCell}>Last Activity</TableCell>
                              {reportData.last_statement && reportData.last_statement.actor ? (
                                <TableCell align="left" className={classes.tblCell}>
                                  {reportData.last_statement.actor.name} <b>{reportData.last_statement.verb.display['en-US']}</b> {reportData.last_statement.object.definition.description.und}
                                  <span style={{ display: 'block', fontSize: 10 }}>On {convertUTCDatewithTime(reportData.last_statement.timestamp)}</span>
                                </TableCell>
                              ) : (
                                <TableCell align="left" className={classes.tblCell}>-</TableCell>
                              )}

                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Paper>
                </div>
                {learnerListInProgress ? 'Loading...' : (
                  <React.Fragment>
                    {learnerList.length ? (
                      <div style={{ marginTop: 30 }}>
                        <Typography variant="h6" component="h6" display="block" style={{ textAlign: 'left', marginTop: 10, marginBottom: 10, color: '#777', }}>
                          {selectedType} ({learnerList.length})
                        </Typography>

                        <TableContainer component={Paper} style={{ backgroundColor: '#f5f5f5' }}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Learner</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Department</TableCell>
                                <TableCell align="left">Started Time</TableCell>
                                <TableCell align="left">Last Activity Time</TableCell>
                                <TableCell align="left">Report</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {learnerList.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left" className={classes.tblCell}>
                                    {row && row.first_name && row.first_name} {row && row.last_name && row.last_name}
                                  </TableCell>
                                  <TableCell align="left" className={classes.tblCell}>{row && row.email_id && row.email_id ? row.email_id : 'NA'}</TableCell>
                                  <TableCell align="left" className={classes.tblCell}>{row && row.department_name && row.department_name ? row.department_name : 'NA'}</TableCell>
                                  <TableCell align="left" className={classes.tblCell}>{row && row.start_time ? convertUTCDatewithTime(row.start_time) : 'NA'}</TableCell>
                                  <TableCell align="left" className={classes.tblCell}>{row && row.end_time ? convertUTCDatewithTime(row.end_time) : 'NA'}</TableCell>
                                  <TableCell align="left">
                                    <Link
                                      component="button"
                                      variant="body2"
                                      onClick={() => { showActivity(row) }}
                                    >
                                      View
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              ))}

                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : null}
                  </React.Fragment>

                )}

              </React.Fragment>
            ) : (
              <Typography style={{ textAlign: 'center', fontSize: '25px' }}>No data found</Typography>
            )}
          </React.Fragment>
        )}
        {activityStatus.open ? (
          <UserActivityReport
            open={activityStatus.open}
            userDetails={activityStatus.userDetails}
            courseDetails={courseData}
            closeActivity={closeActivity}
          />
        ) : null}
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(CourseReport);

import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CardHeader from "@material-ui/core/CardHeader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Popover from '@material-ui/core/Popover';
import { find } from 'lodash';
//local imports
import { style } from '../../Styles/theme';
import { goToCourseView } from '../../Helpers/courseNavigation';
import { redirectTo, getDomainInfo, convertUTCTimeZone, getUserDetails } from '../../Helpers/basic'
import UnpublishPopup from './unPublishPopup'
import ViewRejectReason from './viewReasonPopup'
import { colorStyles } from '../../Styles/theme';
import ViewGrpDetailsPopup from './publishedToViewPopup';
import CoursePublishPopup from '../MyCourse/coursePublishPopup';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    {...props}
  />
));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: '14px',
    marginRight: '14px',
    width: '100%'
  },
  headerTab: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  selectedTab: {
    fontSize: '1rem',
    textTransform: 'none',
    color: '#2e8eec',
    fontWeight: 500
  },
  tabCtr: {
    color: colorStyles.tabHeaderFnt
  },
  dataCount: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: '1.2rem'
  },
  avatar: {
    backgroundColor: "#2e8eec",
    color: "#FFF",
  },
  label: {
    fontWeight: 500,
    color: '#999'
  },
  lstWrapper: {
    padding: '0.5rem',
    maxHeight: '250px',
    overflowY: 'scroll'
  },
  itemblck: {
    display: 'flex',
    margin: '0.3rem'
  },
  lstItemName: {
    color: '#15528d',
    fontSize: '1rem',
    marginRight: '0.5rem',
  },
  lstItemDesig: {
    color: '#000',
    fontSize: '0.8rem',
    marginLeft: '0.2rem',
    marginTop: '3px'
  },
  instrName: {
    fontWeight: 500,
    color: '#999',
    fontSize: '0.9rem'
  },
  moreLabel: {
    fontSize: '0.8rem',
    color: '#2e8eec',
    margin: '0.2rem 0 0 0.2rem',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  buttonWrapper: {
    width: '100%',
    textTransform: 'none',
    color: '#2e8eec'
  }
}));

export default function TabView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const shoeDesignationBased = true //FOR DESIGNATION BASED PUBLISH EDIT
  const scrollView = useMediaQuery('(max-width:500px)');
  const domainInfo = getDomainInfo()
  const [value, setValue] = React.useState(0);
  const myCourses = props.myCourseDetails;
  const [openUnpublishPopup, setOpenUnpublishPopup] = React.useState(false)
  const [unPublishCourseId, setUnpublishCourseId] = React.useState('')
  const [viewReasonPopup, setOpenViewResonPopup] = React.useState(false)
  const [rejectReason, setReason] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openOptionData, setOpenOptionData] = React.useState({})
  const menuOpen = Boolean(anchorEl);
  const [showGrpMembersPopup, setShowGrpMembersPopup] = React.useState(false)
  const [publishedGrpsData, setPublishedGrpsData] = React.useState([])
  //FOR SHOWING MORE THAN TWO AUTHOR NAMES SETSTATES
  const [showInstrList, setShowInstrList] = React.useState(null);
  const [instructorsList, setInstructorsList] = React.useState([])

  //FOR DESIGNATION UPDATE PUBLISH
  const [showCrsPublish, setShowCrsPublish] = React.useState({
    dialog: false,
    crsData: {}
  })

  const handlePopoverOpen = (event, data) => {
    setShowInstrList(event.currentTarget);

    let sortedList = data.sort(function (a, b) {
      if (a.instructor_name < b.instructor_name) { return -1 }
      if (a.instructor_name > b.instructor_name) { return 1 }
      return 0;
    })
    setInstructorsList(sortedList && sortedList.length && sortedList);
  };
  const handlePopoverClose = () => {
    setShowInstrList(null);
  };
  const open = Boolean(showInstrList);
  const id = open ? 'simple-popover' : undefined;

  const openMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setOpenOptionData(row)
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setOpenOptionData({})
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const tabObj = {
    unPublished: {
      label: 'Unpublished Courses',
      count: myCourses.unPublished ? myCourses.unPublished.length : 0,
      list: myCourses.unPublished
    },
    published: {
      label: 'Published Courses',
      count: myCourses.published ? myCourses.published.length : 0,
      list: myCourses.published
    }
  }
  const renderTabs = () => {
    const label = (str, count) => {
      return (
        <div>
          <span className={classes.tabName}>{str}</span>
          <span className={classes.dataCount}>({count})</span>
        </div>
      )
    }
    const tabs = Object.keys(tabObj).map((key, index) => (
      <Tab className={value === index ? classes.selectedTab : classes.headerTab} label={label(tabObj[key].label, tabObj[key].count)} {...a11yProps(key)} key={key} />
    ))
    return tabs
  }
  const onPreviewClick = (courseId) => {
    goToCourseView(courseId)
  }

  const onUnpublishClick = (courseId) => {
    setUnpublishCourseId(courseId)
    setOpenUnpublishPopup(true)
  }
  const closeunPublishPopup = () => {
    setOpenUnpublishPopup(false)
  }

  const navigateTo = (type, courseId) => {
    switch (type) {
      case 'visit_published_course': {
        props.history.push(`/mycourse/${courseId}?Y291cnNlX3Zpc2l0X21vZGU=`)
        return
      }
      case 'gradebook': {
        if (value === 1) {
          props.history.push(`/gradebook/${courseId}?ZnJvbV9teWNvdXJzZXM=`)
        } else {
          props.history.push(`/gradebook/${courseId}`)
        }
        return
      }
      case 'discussions': {
        if (value === 1) {
          props.history.push(`discussions/${courseId}?ZnJvbV9teWNvdXJzZXM=`)
        } else {
          props.history.push(`discussions/${courseId}`)
        }
        return
      }
      case 'assignment':
      default: {
        if (value === 1) {
          props.history.push(`/assignment/${courseId}?ZnJvbV9teWNvdXJzZXM=`)
        } else {
          props.history.push(`/assignment/${courseId}`)
        }
        return
      }
    }
  }

  const onScormPreviewClick = link => {
    const url = link
    redirectTo(url, true)
  }
  const onScormReportClick = (info) => {

    let url = `/coursereport/${info.id}`
    if (info.isscorm && !info.isxApi) {
      url = `/scormnewreport/${info.id}`
    }
    if (info.isxApi) {
      url = `/xapireport/${info.id}`
    }
    if (value === 1) {
      props.history.push(`${url}?ZnJvbV9teWNvdXJzZXM=`)
    } else {
      props.history.push(`${url}`)
    }
  }

  const onEditPublishedCourseClick = courseId => {
    props.history.push(`/mycourse/${courseId}?ZWRpdF9jb3Vyc2U=`)
  }

  //reject reason popup for b2c course submission
  const onRejectViewClick = (reason) => {
    setOpenViewResonPopup(true)
    setReason(reason)
  }
  const closeRejectViewPopup = () => {
    setOpenViewResonPopup(false)
    setReason('')
  }

  const renderTitle = (title) => {
    return (
      <Typography style={{ color: '#2e8eec' }}>
        {title && title.length > 20 ? (title.substr(0, 20) + "...") : (title ? title : null)}
      </Typography>
    )
  }

  //ON DESIGNATION PUBLISH UPDATE CLICK
  const onUpdateDesignClick = (data) => {
    setShowCrsPublish({
      dialog: true,
      crsData: { ...data, 'dialog_from': 'edit_audience' }
    })
  }
  //CLOSE DESIGNATION POPUP
  const closeDesignationPublish = () => {
    setShowCrsPublish({
      dialog: false,
      crsData: {}
    })
    closeMenu()
  }

  const cardMenuList = () => {
    let contriButor = false;
    if (openOptionData && Object.keys(openOptionData) && Object.keys(openOptionData).length) {
      const userData = openOptionData.author_list && openOptionData.author_list.length && find(openOptionData.author_list, { 'id': getUserDetails().id })
      if (userData && Object.keys(userData) && Object.keys(userData).length) {
        contriButor = true
      } else {
        contriButor = false
      }
      return (
        <div>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            onClose={closeMenu}
          >
            {!openOptionData.isscorm ? (
              <div>
                <MenuItem onClick={() => { navigateTo('gradebook', openOptionData.id) }}>Grade Book</MenuItem>
                <MenuItem onClick={() => { navigateTo('assignment', openOptionData.id) }}>Assignments</MenuItem>
              </div>
            ) : null}
            {domainInfo.type === 'b2b' ? (
              <div>
                {value === 1 ? (
                  <div>
                    {openOptionData && !openOptionData.isscorm ? (
                      <MenuItem onClick={() => { navigateTo('discussions', openOptionData.id) }}>
                        Discussions
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={() => { onScormReportClick(openOptionData) }}>
                      View Report
                    </MenuItem>
                    {!openOptionData.isscorm || contriButor ? (
                      <React.Fragment>
                        <MenuItem onClick={() => { onEditPublishedCourseClick(openOptionData.id) }}>
                          Edit Course
                        </MenuItem>
                        <MenuItem onClick={() => { navigateTo('visit_published_course', openOptionData.id) }}>
                          Visit Course
                        </MenuItem>
                        {shoeDesignationBased &&
                          <MenuItem onClick={() => { onUpdateDesignClick(openOptionData) }}>
                            Edit Audience
                          </MenuItem>
                        }
                      </React.Fragment>
                    ) : null}
                  </div>
                ) : (
                  <MenuItem onClick={() => { navigateTo('discussions', openOptionData.id) }}>
                    Discussions
                  </MenuItem>
                )}
              </div>
            ) : (
              <React.Fragment>
                {openOptionData.published ? (
                  <MenuItem onClick={() => { onScormReportClick(openOptionData) }}>
                    View Report
                  </MenuItem>
                ) : null}
              </React.Fragment>
            )}
          </StyledMenu>
        </div>
      );
    }
  };

  //for showing published to grp members data
  const onViewGrpMembers = (data) => {
    setShowGrpMembersPopup(true)
    setPublishedGrpsData(data)
  }
  const closeGrpMembersView = () => {
    setShowGrpMembersPopup(false)
    setPublishedGrpsData([])
  }

  const renderTabContentPanel = () => {
    const tabs = Object.keys(tabObj).map(key =>
      <TabPanel value={key} index={key} dir={theme.direction} key={key}>
        {tabObj[key].count ? (
          <div>
            <List>
              <Grid container spacing={2}>
                {tabObj[key].list && tabObj[key].list.map((row, cardKey) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={4} key={cardKey}>
                    <React.Fragment>
                      <ListItem>
                        <Card style={{ position: 'relative', height: 400, maxWidth: '300px', minWidth: '300px', marginBottom: '25px' }}>
                          <CardHeader
                            avatar={
                              <Avatar aria-label="User Name" className={classes.avatar}>
                                {row && row.title && row.title.substr(0, 1)}
                              </Avatar>
                            }
                            action={
                              <IconButton aria-label="View Options" onClick={(event) => { openMenu(event, row) }}>
                                <MoreVertIcon />
                              </IconButton>
                            }
                            disableTypography={true}
                            title={renderTitle(row.title)}
                          />
                          {cardMenuList()}
                          <CardMedia
                            component="img"
                            alt={row.title ? row.title : null}
                            height="140"
                            image={row.banner_url}
                            title={row.title ? row.title : null}
                          />
                          <CardContent style={{ width: '100%', textAlign: 'left' }}>
                            {domainInfo && domainInfo.name === 'rmbl' ? (
                              <React.Fragment>
                                {row && row.author_list && (row.author_list.length - 1) > 0 ? (
                                  <div style={{ display: 'flex' }}>
                                    <div className={classes.instrName} aria-label="InstructorsList">{`Instructor(s):  ${row.author_name}`}</div>
                                    {(row.author_list.length - 1) > 0 && <div className={classes.moreLabel} aria-describedby={id} onClick={(event) => { handlePopoverOpen(event, row.author_list) }} > + {row.author_list.length - 1} more</div>}
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    {row && row.author_name && row.author_name.length ? (
                                      <Typography style={{ marginTop: '6px' }} variant="body2" color="textSecondary" component="p">
                                        <span className={classes.label}>Instructor: </span>
                                        {row && row.author_name && row.author_name.length && row.author_name}
                                      </Typography>
                                    ) : null}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            ) : (
                              <Typography style={{ marginTop: '6px' }} variant="body2" color="textSecondary" component="p">
                                {row && row.author_name && row.author_name.length ? (
                                  <React.Fragment>
                                    <span className={classes.label}>Instructor: </span>
                                    {row && row.author_name && row.author_name.length && row.author_name}
                                  </React.Fragment>
                                ) : null}
                              </Typography>
                            )}
                            {row && row.created_date ? (
                              <Typography style={{ marginTop: '6px' }} variant="body2" color="textSecondary" component="p">
                                <span className={classes.label}>Created Date: </span>
                                {convertUTCTimeZone(row.created_date)}
                              </Typography>
                            ) : null}
                            {domainInfo && domainInfo.name !== 'rmbl' ? (
                              <React.Fragment>
                                <Typography style={{ marginTop: '6px' }} variant="body2" color="textSecondary" component="p">
                                  <span className={classes.label}>Level: </span>
                                  {props.getCourseLevel(row.level) ? props.getCourseLevel(row.level) : null}
                                </Typography>
                                <Typography style={{ marginTop: '6px' }} variant="body2" color="textSecondary" component="p">
                                  <span className={classes.label}>Category: </span>
                                  {props.getCourseCategory(row.category) ? props.getCourseCategory(row.category) : null}
                                </Typography>
                              </React.Fragment>
                            ) : null}
                            {domainInfo && domainInfo.name === 'rmbl' && value === 1 && row && row.published_to && row.published_to.length ? (
                              <Typography style={{ marginTop: '6px' }} variant="body2" color="textSecondary" component="p">
                                <span className={classes.label}>Published to: </span>
                                <Button size="small" onClick={() => { onViewGrpMembers(row.published_to) }} style={{ marginLeft: '3px', textTransform: 'none', color: '#2e8eec' }}>View Info</Button>
                              </Typography>
                            ) : null}
                          </CardContent>
                          <CardActions style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                            {domainInfo.type === 'b2b' ? (
                              <React.Fragment>
                                {row.published ?
                                  (
                                    <React.Fragment>
                                      {row.isscorm ? (
                                        <Button endIcon={<VisibilityIcon />} onClick={() => { onScormPreviewClick(row.scorm_url) }} size="small" className={classes.buttonWrapper}>
                                          Course Preview
                                        </Button>
                                      ) : (
                                        <Button endIcon={<VisibilityIcon />} onClick={() => { onPreviewClick(row.id) }} size="small" className={classes.buttonWrapper}>
                                          Preview
                                        </Button>
                                      )}
                                      {!row.isscorm ? (
                                        <Button endIcon={<NotInterestedIcon />} onClick={() => { onUnpublishClick(row.id) }} size="small" className={classes.buttonWrapper}>
                                          Unpublish
                                        </Button>
                                      ) : null}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Button onClick={() => { props.onViewCourseClick(row.id) }} size="small" className={classes.buttonWrapper}>
                                        View/Edit Course
                                      </Button>
                                    </React.Fragment>
                                  )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {row.published ? (
                                  <React.Fragment>
                                    {row.isscorm ? (
                                      <Button endIcon={<VisibilityIcon />} onClick={() => { onScormPreviewClick(row.scorm_url) }} size="small" className={classes.buttonWrapper}>
                                        Course Preview
                                      </Button>
                                    ) : (
                                      <Button endIcon={<VisibilityIcon />} onClick={() => { onPreviewClick(row.id) }} size="small" className={classes.buttonWrapper}>
                                        Course Preview
                                      </Button>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {row.review_status === 'pending' ? (
                                      <Button onClick={() => { onPreviewClick(row.id) }} size="small" className={classes.buttonWrapper}>
                                        Preview
                                      </Button>
                                    ) : null}
                                    {row.review_status === 'pending' ? (
                                      <div style={{ width: '100%', fontSize: '14px', textAlign: 'center' }}>Approval pending</div>
                                    ) : (
                                      <React.Fragment>
                                        {row.review_status !== 'rejected' ? (
                                          <React.Fragment>
                                            <Button onClick={() => { props.onViewCourseClick(row.id) }} size="small" className={classes.buttonWrapper}>
                                              View Course
                                            </Button>
                                          </React.Fragment>
                                        ) : null}
                                      </React.Fragment>
                                    )}
                                    {row.review_status === 'rejected' ? (
                                      <React.Fragment>
                                        {row.remark ? (
                                          <Button onClick={() => { onRejectViewClick(row.remark) }} size="small" className={classes.buttonWrapper}>
                                            View Reason
                                          </Button>
                                        ) : null}
                                        <div style={{ width: '100%', fontSize: '15px', textAlign: 'center' }}>Rejected</div>
                                      </React.Fragment>
                                    ) : null}
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                          </CardActions>
                        </Card>
                      </ListItem>
                    </React.Fragment>
                  </Grid>
                ))}
              </Grid>
            </List>
          </div>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ textAlign: 'initial' }}>No data found</div>
          </Grid>
        )}
      </TabPanel>
    )
    return tabs
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: colorStyles.tabHeaderBg }}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: '#2e8eec', height: '5px', borderRadius: '3px' } }}
          className={classes.tabCtr}
          variant={scrollView ? "scrollable" : 'fullWidth'}
          scrollButtons="auto"
          aria-label="MyCourse listing tabs"
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {renderTabContentPanel()}
      </SwipeableViews>
      {openUnpublishPopup ? (
        <UnpublishPopup
          courseListFetch={props.courseListFetch}
          openUnpublishPopup={openUnpublishPopup}
          closeunPublishPopup={closeunPublishPopup}
          unPublishCourseId={unPublishCourseId}
        />
      ) : null}
      {viewReasonPopup ? (
        <ViewRejectReason
          history={props.history}
          viewReasonPopup={viewReasonPopup}
          closeRejectViewPopup={closeRejectViewPopup}
          rejectReason={rejectReason}
        />
      ) : null}
      {showGrpMembersPopup ? (
        <ViewGrpDetailsPopup
          open={showGrpMembersPopup}
          close={closeGrpMembersView}
          data={publishedGrpsData}
        />
      ) : null}
      {showCrsPublish.dialog ? (
        <CoursePublishPopup
          openCoursePublish={showCrsPublish.dialog}
          closeCoursePopup={closeDesignationPublish}
          courseData={showCrsPublish.crsData}
          courseListFetch={props.courseListFetch}
        />
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={showInstrList}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.lstWrapper}>
          {instructorsList && instructorsList.length > 0 && instructorsList.map((item, index) => {
            return (
              <div className={classes.itemblck} key={index}><div className={classes.lstItemName}>{`${item.instructor_name} (${item.email})`}</div></div>
            )
          })}
        </div>
      </Popover>
    </div>
  );
}

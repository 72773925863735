import React, { useState, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';

export default function VimeoPlayer(props) {
    const [startTime, setStartTime] = useState(0.01);
    const [isError, setIsError] = useState(false);
    const videoUrl = props && props.data && props.data.videoURL ? props.data.videoURL : props.videoUrl

    useEffect(() => {
        setIsError(false);
    }, [props])

    return (
        <React.Fragment>
            {!isError ? <React.Fragment>
                {startTime > 0 && <Vimeo
                    video={videoUrl}
                    onError={() => { setIsError(true) }}
                    autoplay={false}
                    controls={true}
                    responsive={true}
                    style={{ justifyContent: 'center', padding: '1rem', backgroundColor: '#e9f6f6' }}
                    start={startTime}
                />}
            </React.Fragment> : <div style={{ backgroundColor: '#e9f6f6', textAlign: 'center', padding: '1.5rem' }}><VideocamOffOutlinedIcon style={{ color: '#2e8eec', fontSize: "40px" }} /> <div style={{ color: '#2e8eec', fontSize: '1rem' }} >Oops! Error fetching video.</div><div style={{ color: '#2e8eec', marginTop: '0.3rem', fontSize: '0.9rem' }} >Please try again later.</div></div>}
        </React.Fragment>

    );
}


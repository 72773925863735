import React from 'react';
import Skeleton from 'react-skeleton-loader';

export default function CardListingLoader(props) {
  return(
    <div>
      <div style={{ marginBottom: '20px'}}>
        <Skeleton widthRandomness={0} count={1} height="90px" width="90%" color="#ccc" />
      </div>
      <div style={{ marginBottom: '20px'}}>
        <Skeleton widthRandomness={0} count={2} height="60px" width="90%" color="#ccc" />
      </div>
      <div style={{ marginBottom: '20px'}}>
        <Skeleton widthRandomness={0} count={5} height="40px" width="90%" color="#ccc" />
      </div>
    </div>
  )
}
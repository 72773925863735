import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { listAssignments } from '../../store/Assignments/actionCreator'
import { fetchLessonDetails } from '../../store/AddLesson/actionCreator'
import { fetchModuleDetails } from '../../store/AddModule/actionCreator'
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator'
import { ERROR_FETCHING_ASSIGNMENT, ERROR_FETCH_COURSE_DETAILS, ERROR_FETCH_MODULE_DETAILS } from '../Common/Constants/errorMessages';
import Header from './header'
import AddAssignment from './addAssignment'
import AssignmentList from './assignmentListView'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 65
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  button: {
    marginTop: '10px',
    flexGrow: 1,
    marginRight: '38px',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  },
  breadCrumbcontainer: {
    padding: '15px',
    marginLeft: '10px',
    justifyContent: "center",
    marginTop: '10px'
  },
}))
function CourseLevelAssessment(props) {
  const classes = useStyles();
  const [courseDetails, setCourseDetails] = React.useState({})
  const [courseInfo, setCourseInfo] = React.useState({})
  const [moduleInfo, setModuleInfo] = React.useState({})
  const [assignmentList, setAssignmentList] = React.useState({})
  const [showAddAssignmentBtn, setShowAddAssignmentBtn] = React.useState(true)
  const [addAssignmentMode, setAddAssignmentMode] = React.useState(false)
  const [inProgress, setInProgress] = React.useState(false)
  const courseOpenedMode = getCourseOpenModeType();

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 4; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  const successCallBack = res => {
    setAssignmentList(res.course_assignment)
    setInProgress(false)
  }

  const failureCallBack = error => {
    setAssignmentList({})
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_ASSIGNMENT,
        type: 'error'
      }
    )
  }

  const listAllAssignment = () => {
    setInProgress(true)
    const payload = {
      course_id: props.match.params.courseId
    }
    listAssignments(payload, successCallBack, failureCallBack)
  }

  const courseSuccessCallBack = res => {
    listAllAssignment()
    setCourseDetails(res.results)
  }

  const courseFailureCallBack = error => {
    setCourseDetails({})
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_ASSIGNMENT,
        type: 'error'
      }
    )
  }

  const moduleInfoSuccessCallBack = res => {
    setModuleInfo(res.results)
  }

  const moduleInfoFailureCallBack = error => {
    setModuleInfo({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_MODULE_DETAILS,
        type: 'error'
      }
    )
  }

  const courseInfoSuccessCallBack = res => {
    setCourseInfo(res.results)
  }

  const courseInfoFailureCallBack = error => {
    setCourseInfo({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_COURSE_DETAILS,
        type: 'error'
      }
    )
  }

  useEffect(() => {
    setInProgress(true)
    const payload = {
      course_id: props.match.params.courseId,
      module_id: props.match.params.moduleId,
      lesson_id: props.match.params.lessonId,
    }
    fetchLessonDetails(payload, courseSuccessCallBack, courseFailureCallBack)
    const payLoad = {
      course_id: props.match.params.courseId,
      module_id: props.match.params.moduleId
    }
    fetchModuleDetails(payLoad, moduleInfoSuccessCallBack, moduleInfoFailureCallBack)
    courseDetailsFetch({
      id: props.match.params.courseId
    }, courseInfoSuccessCallBack, courseInfoFailureCallBack)
  }, [])

  const triggerAddAssignment = () => {
    setAddAssignmentMode(true)
  }

  const cancelAddAssignment = () => {
    setAddAssignmentMode(false)
  }
  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.topButtonNav}>
        <div className={classes.breadCrumbcontainer}>
          <Grid container>
            <Header match={props.match} history={props.history} courseDetails={courseDetails} courseInfo={courseInfo} moduleInfo={moduleInfo} />
          </Grid>
        </div>
      </Paper>
      {inProgress ? (
        listLoader()
      ) : (
        <React.Fragment>
          {addAssignmentMode ? (
            <AddAssignment match={props.match} history={props.history} cancelAddAssignment={cancelAddAssignment} listAllAssignment={listAllAssignment} />
          ) : (
            <React.Fragment>
              {showAddAssignmentBtn && courseOpenedMode !== 'visit_mode' ? (
                <div style={{ textAlign: 'left', marginLeft: '21px' }}>
                  <Button
                    variant="contained"
                    aria-label="add assignment"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={triggerAddAssignment}
                  >
                    Assignment
                  </Button>
                </div>
              ) : null}
              {assignmentList && !assignmentList.length ? (
                <div style={{ marginTop: 30 }}>
                  <div style={{ marginBottom: 10 }}>No Assignments added yet</div>
                  {courseOpenedMode !== 'visit_mode' && <Button
                    variant="contained"
                    aria-label="add assignment"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={triggerAddAssignment}
                  >
                    Assignment
                  </Button>}
                </div>
              ) : (
                <AssignmentList assignmentList={assignmentList} history={props.history} match={props.match} listAllAssignment={listAllAssignment} setShowAddAssignmentBtn={setShowAddAssignmentBtn} />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(CourseLevelAssessment);
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
//local imports
import { style } from '../../Styles/theme';


const useStyles = makeStyles(theme => ({
  highlightText: {
    fontSize: '1rem',
    fontWeight: 700
  },
  headerWrapper: {
    color: style.fontColour._blue
  },
  button: {
    color: style.fontColour._blue
  },
  chipWrapper: {
    color: style.fontColour._blue,
    borderColor: style.fontColour._blue,
    marginRight: '10px',
    marginBottom: '10px'
  }
}));

function SentToInfoViewPopup(props) {
  const classes = useStyles()
  const open = props && props.data && props.data.showPopup
  const noticeData = props && props.data && props.data.selectedNoticeData && Object.keys(props.data.selectedNoticeData) && Object.keys(props.data.selectedNoticeData).length ? props.data.selectedNoticeData : {}

  //FOR RENDERING SELECTED TEAM NAME
  const renderGrpMemChip = (teamsList) => {
    if (teamsList.length > 0) {
      let arr = [];
      teamsList.forEach((item, index) => {
        let label = item.title;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              key={index}
              className={classes.chipWrapper}
              label={label}
              variant="outlined"
            />
          </React.Fragment>
        );
      });
      return arr;
    } else {
      return (
        <p style={{ fontSize: '11px', textAlign: 'center' }}>No groups found!</p>
      )
    }
  };

  const getRenderType = () => {
    if (noticeData && noticeData.sent_to && noticeData.sent_to.user_type && Object.keys(noticeData.sent_to.user_type) && Object.keys(noticeData.sent_to.user_type).length) {
      if (noticeData.sent_to.user_type.select_by_groups) {
        return renderGrpMemChip(noticeData.groups)
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        onClose={props.close}
        aria-labelledby={"notice sent to information"}
        aria-describedby={"notice sent to information"}
      >
        <DialogTitle id="alert-dialog-title" className={classes.headerWrapper}>Notice Sent To information</DialogTitle>
        <DialogContent style={{ maxHeight: 400, overflowY: 'scroll' }}>
          {getRenderType()}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} className={classes.button}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(SentToInfoViewPopup);
import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
//local imports
import LeftMenu from '../AppLayout/leftMenu';
import AddingToGroup from './addingToGroups';
import ManageGroups from './manageGroups';
import { style } from '../../Styles/theme';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: style.backgroundColor._lightWhite
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
}));

function ManageGroupsView(props) {
  const classes = useStyles();

  const getRenderView = () => {
    if (props && props.match.params.groupId) {
      return (
        <AddingToGroup match={props.match} history={props.history} />
      )
    } else {
      return (
        <ManageGroups match={props.match} history={props.history} />
      )
    }
  }

  return (
    <div className={classes.root}>
      <React.Fragment>
        <CssBaseline />
        <main>
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
              <LeftMenu history={props.history} />
            </Grid>
          </Container>
        </main>
        <div>
          {getRenderView()}
        </div>
      </React.Fragment>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(ManageGroupsView);
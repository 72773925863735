import { redirectTo } from './basic';
import { getDomainInfo } from "./basic";

export const goToCourseView = courseId => {
  let domainDet = getDomainInfo();
  // //const clientName = domainDet && domainDet.hostName.split('.')[0]
  // let url = ''
  // if(clientName === 'ansr'){
  //     url = `https://${clientName}.rendering.moocpedia.com/`
  // }else{
  //   url = `https://rendering.moocpedia.com/`
  // }
 // let url = `https://${clientName}.rendering.moocpedia.com/`
  redirectTo(`${domainDet.renderingURL}/course/${courseId}/`, true)

}

import React from 'react';
import Skeleton from 'react-skeleton-loader';
import Grid from '@material-ui/core/Grid';

export default function CardListingLoader(props) {
  return(
    <div style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Skeleton widthRandomness={0} height="60px" width="100%" color="#ccc" />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Skeleton widthRandomness={0} count={4} height="30px" width="100%" color="#ccc" />
            </Grid>
        </Grid>
    </div>
  )
}
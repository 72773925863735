import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { deleteDiscussion, deleteExternalDiscussion } from '../../store/Discussions/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_DELETE_DISCUSSION } from '../Common/Constants/errorMessages'
import { SUCCESS_DELETE_DISCUSSION } from '../Common/Constants/successMessages'
import { style } from '../../Styles/theme'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x',
    color: style.fontColour._blue
  },
  btnWrapper: {
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    }
  }
}));

function DeleteDiscussionPopup(props) {
  const classes = useStyles();
  const open = props.openDeleteConfirm
  const discussionId = props.discussionData && props.discussionData.id ? props.discussionData.id : null
  const [inProgress, setInprogress] = React.useState(false)

  const handleClose = () => {
    props.closeDeleteDialog();
  };

  const successCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_DELETE_DISCUSSION,
        type: 'success'
      }
    )
    props.listAllDiscussions()
    handleClose()
    if(props && props.from === 'externalType'){
      props.fetchTabCounts()
    }
  }

  const failureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_DELETE_DISCUSSION,
        type: 'error'
      }
    )  
  }
  const deleteConfirm = () => {
    setInprogress(true)
    const payLoad = {
      id: discussionId
    }
    if(props && props.from === 'externalType'){
      deleteExternalDiscussion(payLoad, successCallBack, failureCallBack)
    }else{
      deleteDiscussion(payLoad, successCallBack, failureCallBack)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Discussion delete dialog"
      >
        <DialogTitle id="alert-dialog-title"> Are you sure you want to delete discussion?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { props.discussionData && props.discussionData.title && props.discussionData.title.length > 50 ?
              (
                props.discussionData.title.substr(0,45) + '...'
              ) : 
                props.discussionData && props.discussionData.title ? props.discussionData.title : null
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        { inProgress ? (
          <CircularProgress size={30} className={classes.buttonProgress} />
        ): null }
          <Button onClick={deleteConfirm} disabled={inProgress} className={classes.btnWrapper} variant="contained" autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose} disabled={inProgress} style={{ textTransform: 'none' }} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
    
export default connect(
  mapStateToProps, { showSnackBar }
)(DeleteDiscussionPopup);

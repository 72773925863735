import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
//local imports
import { style } from '../../Styles/theme';


const useStyles = makeStyles(theme => ({
  highlightText: {
    fontSize: '1rem',
    fontWeight: 700
  },
  headerWrapper: {
    color: style.fontColour._blue
  },
  button: {
    color: style.fontColour._blue
  },
  chipWrapper: {
    color: style.fontColour._blue,
    borderColor: style.fontColour._blue,
    marginRight: '10px',
    marginBottom: '10px'
  }
}));

function PublishedToViewPopup(props) {
  const classes = useStyles()
  const open = props && props.open
  const data = props && props.data && props.data.length ? props.data : []

  //FOR RENDERING SELECTED TEAM NAME
  const renderGrpMemChip = (teamsList) => {
    if (teamsList.length > 0) {
      let arr = [];
      teamsList.forEach((item, index) => {
        let label = item.name + " - " + item.email + " - " + item.role;
        let labelName = label.length > 100 ? label.substring(0, 705) + "..." : label;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              key={index}
              className={classes.chipWrapper}
              label={labelName}
              variant="outlined"
            />
          </React.Fragment>
        );
      });
      return arr;
    } else {
      return (
        <p style={{ fontSize: '11px', textAlign: 'center' }}>No members found!</p>
      )
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={props.close}
        aria-labelledby={"Course published to Information"}
        aria-describedby={"Course published to Information"}
      >
        <DialogTitle id="alert-dialog-title" className={classes.headerWrapper}>Course published to groups information</DialogTitle>
        <DialogContent style={{ height: data.length > 2 ? 400 : 200, overflowY: 'scroll' }}>
          {data && data.length ? (
            <React.Fragment>
              {data.map((row, index) => (
                <React.Fragment key={index}>
                  <div style={{ fontSize: '15px', textAlign: 'center', marginBottom: '14px', fontWeight: 500 }} key={index}>{`${row.group_title} - (${row.members_count})`}</div>
                  <div>{renderGrpMemChip(row.group_members)}</div>
                  <hr />
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : (
            <p style={{ fontSize: '13px', textAlign: 'center' }}>No data found!</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} className={classes.button}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(PublishedToViewPopup);
import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '350px',
    height: '200px'
  },
  headerWrapper: {
    marginTop: '20px',
    marginLeft: '10px'
  },
  skeletonWrapper: {
    width: '235%',
    height: '30px',
    marginLeft: '10px',
    marginTop: '20px',
    textAlign: 'initial',
  },
})
)
export default function AssignmentSumissionFetchLoader(props) {
  const classes = useStyles();
  return(
    <div className={classes.wrapper}>
      <div className={classes.skeletonWrapper}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Skeleton widthRandomness={0} count={7} height="25px" width="300px" color="#e0e0e0" />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, TextField, CircularProgress, Button } from '@material-ui/core';
import { Input, Avatar } from '@material-ui/core';
import clsx from 'clsx';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { find } from 'lodash'
import { apiConfig } from '../../Configs/apiConfig';
import { bytesToSize } from '../../Helpers/basic';
import { fetchAuthorDetails } from '../../store/Authors/actionCreator';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  uploadedImg: {
    width: 62,
    marginLeft: 20,
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  uploadIcon: {
    color: '#2e8eec',
    marginLeft: 15,
    marginRight: 5,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadButtonContainer: {
    padding: '1rem 0 0 1rem'
  },
  erromMsg: {
    color: 'red'
  },
  authorDesc: {
    textAlign: "justify",
    fontStyle: 'italic'
  },
  avatarCntr: {
    padding: '0.5rem'
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundColor: "#6bc787",
  },
  authorCntr: {
    backgroundColor: '#f9f9f9',
    padding: '1rem 1rem 1rem 0',
    marginBottom: '1rem'
  },
  authorList: {
    paddingTop: '2rem'
  },
  buttonContainer: {
    textAlign: 'end',
    paddingBottom: '1rem'
  },
  buttons: {
    backgroundColor: '#2e8eec',
    color: '#fff',
    marginRight: '1rem'
  },
  container: {
    justifyContent: 'center'
  },
  authorName: {
    paddingTop: '1rem',
    fontWeight: 600,
    float: 'left'
  }
}))

function ManageAuthors(props) {
  const classes = useStyles();
  const [authorsList, setAuthorsList] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [profileImage, setProfileImage] = useState();
  const [authDesc, setAuthDesc] = useState();
  const [addNewAuthor, setAddNewAuthor] = useState();
  const [profileImgPreview, setProfileImgPreview] = useState()
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }

  useEffect(() => {
    const courseId = 65
    const payload = {
      id: courseId
    }
    fetchAuthorDetails(payload, successCallBack, failureCallBack)
  }, [])

  const successCallBack = (res) => {
    setAuthorsList(res);
  }

  const failureCallBack = () => {

  }

  const editAuthInfo = (authorId) => {
    let authorInfo = find(authorsList, { id: authorId })
    setAddNewAuthor(true);
    setFirstName(authorInfo.firstName);
    setLastName(authorInfo.lastName);
    setEmail(authorInfo.email);
    setAuthDesc(authorInfo.desc);
  }


  const validatedFile = () => {
    const selectedFile = document.getElementById('profileImage').files;
    let fileDetails = {
      fileError: false,
      fileErrorMsg: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorMsg: ''
      }
    } else {
      const file = selectedFile[0]
      if (file.size > apiConfig.authorInfo.acceptedImageFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorMsg: 'File size should be less than 2MB'
        }
      }
      else {
        const fileFormats = () => {
          return apiConfig.authorInfo.acceptedImageFileFormats
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorMsg: 'Invalid file format.'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorMsg: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }

  const onProfileImageUpload = event => {
    const profileImage = validatedFile();
    setProfileImage(profileImage);
    setProfileImgPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }


  // const renderAuthorList = () => {
  //   let authArr = [];
  //   authorsList.map((author, index) => {
  //     authArr.push(
  //       <Paper className={classes.authorCntr}>
  //         <Grid key={index} container>
  //           <Grid item sm={2} md={2} lg={2}>
  //             <div className={classes.avatarCntr}>
  //               <Avatar alt={author.firstName, author.lastName} src="https://raw.githubusercontent.com/mui-org/material-ui/master/docs/public/static/images/avatar/2.jpg" className={classes.avatar} />
  //               <div>{author.firstName}</div>
  //               <div>{author.lastName}</div>
  //             </div>
  //           </Grid>
  //           <Grid item sm={10} md={10} lg={10}>
  //             <div className={classes.authorDesc}>{`"${author.desc}"`}</div>
  //           </Grid>
  //         </Grid>
  //       </Paper>
  //     )
  //   })
  //   return authArr
  // }

  const renderNewAuthorList = () => {
    let authArr = [];
    authorsList.map((author, index) => {
      let avatarName = author.firstName.charAt(0) + author.lastName.charAt(0);
      authArr.push(
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card>
            <Grid container>
              <Grid item xs={2} sm={3} md={3} lg={2} className={classes.avatarCntr}>
                <Avatar aria-label="recipe" src={author.profileImage != '' ? author.profileImage : ''} className={classes.avatar}>
                  {author.profileImage == '' ? avatarName : ''}
                </Avatar>
              </Grid>
              <Grid item xs={6} sm={5} md={5} lg={7} >
                <div className={classes.authorName}>{author.firstName + ' ' + author.lastName}</div>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={3} >
                <IconButton aria-label="settings">
                  <EditIcon onClick={() => { editAuthInfo(author.id) }} style={{ fontSize: 18 }} />
                </IconButton>
                <IconButton aria-label="settings">
                  <DeleteIcon style={{ fontSize: 18 }} />
                </IconButton>
              </Grid>
            </Grid>
            <CardContent>
              <Typography className={classes.authorDesc} variant="body2" color="textSecondary" component="p">
                {`"${author.desc}"`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    })
    return authArr;
  }


  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} lg={11} style={{ paddingTop: '5rem' }}>

        <Paper className={classes.paper}>
          <div className={classes.buttonContainer}>
            <Button className={classes.buttons} variant="contained" color="primary" onClick={() => { setAddNewAuthor(true) }}>
              Add Author
            </Button>
          </div>
          <form autoComplete="off" style={{ display: addNewAuthor ? '' : 'none', paddingBottom: '2rem' }} onSubmit={evnt => { evnt.preventDefault() }} >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required
                  variant="outlined"
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  defaultValue={firstName}
                  value={firstName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required
                  variant="outlined"
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  defaultValue={lastName}
                  value={lastName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  required
                  variant="outlined"
                  id="email"
                  name="email"
                  label="E-mail"
                  defaultValue={email}
                  value={email}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="About Author"
                  multiline
                  fullWidth
                  rows="4"
                  defaultValue={authDesc}
                  value={authDesc}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <div className={classes.uploadButtonContainer}>
                  <Typography gutterBottom variant="body2">
                    <span className={classes.uploadLabel}>
                      Upload Author Profile Image :
                    </span>
                    <CloudUploadIcon className={classes.uploadIcon} onClick={() => { onFileToUploadClick('profileImage') }} />
                    <span className={classes.helperText}>( max.size: 2MB, format: jpg, jpeg, png )</span>
                    {profileImage && profileImage.fileError ? (
                      <Typography variant="caption" display="block" gutterBottom className={classes.erromMsg}>
                        {profileImage.fileErrorMsg}
                      </Typography>
                    ) : null}
                    {profileImage && !profileImage.fileError && profileImgPreview && (
                      <img className={classes.uploadedImg} alt="profileImage" src={profileImgPreview} />
                    )}
                  </Typography>
                  <Input type="file" style={{ display: 'none' }} id="profileImage" onChange={onProfileImageUpload} />
                </div>
              </Grid>
            </Grid>
            <div className={classes.formButtonContainer}>
              <Button className={classes.buttons} color='primary' variant="contained"> Save </Button>
              <Button className={classes.buttons} color='primary' variant="contained" onClick={() => { setAddNewAuthor(false) }}> Cancel </Button>
            </div>
          </form>
          {/* {authorsList && authorsList.length > 0 ? <div className={classes.authorList}>{renderAuthorList()}</div> : <div>No Records to Display</div>}
          {authorsList && authorsList.length > 0 ? <Grid container className={classes.authorGridCntr} spacing={3}>{renderNewAuthorList()}</Grid> : null} */}
        </Paper>
      </Grid>
    </Grid>
  )
}


const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(ManageAuthors);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
//local imports
import { style } from '../../Styles/theme';
import myAthinaLogo from '../../static/athinaLogo.png'
import { login, fetchProfile } from '../../store/Auth/actionCreator';
import AlertMessages from '../Common/AlertMessages';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { clientImgMap } from '../../Configs/clientImgConfig';
import { generateJwtToken, getDomainInfo } from '../../Helpers/basic';
import { ERROR_FETCHING_PROFILE } from '../Common/Constants/errorMessages'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: '15%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #ccc',
        padding: '14px 13px 21px 14px'
    },
    myAthinaLogo: {
        width: '180px',
        verticalAlign: 'top',
        marginTop: '23px',
        marginLeft: 10
    },
    logoImg: {
        width: '70px',
        marginTop: '12px'
    },
    logoImgRMBL: {
        width: '80px',
        height: '61px',
        marginTop: '18px'
    },
    textField: {

    },
    buttonProgress: {
        color: style.fontColour._blue,
        marginRight: '10px'
    },
    submit: {
        flexGrow: 1,
        textTransform: 'none',
        marginRight: '10px',
        marginTop: '10px',
        backgroundColor: style.fontColour._blue,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: style.fontColour._blue,
        },
    },
    forgotpassword: {
        textAlign: 'right',
        color: style.fontColour._blue
    },
    textFiledWrapper: {
        borderWidth: "1px",
        borderColor: "#2e8eec !important"
    }
}));
function LoginForm(props) {
    const classes = useStyles();
    const [userName, setUserName] = React.useState('')
    const [passWord, setPassWord] = React.useState('')
    const [inProgress, setInprogress] = React.useState(false)
    const [validationError, setValidationError] = React.useState({
        error: false,
        errorMessage: ''
    })
    const url = window.location.href
    const subDomain = url.slice(url.indexOf('//') + 2).split('.')
    const clientImg = clientImgMap[subDomain[0]] && clientImgMap[subDomain[0]].img ? clientImgMap[subDomain[0]].img : null

    const onUserNamehandleChange = event => {
        setValidationError({
            error: false,
            errorMessage: ''
        })
        setUserName(event.target.value)
    }
    const onPasswordhandleChange = event => {
        setValidationError({
            error: false,
            errorMessage: ''
        })
        setPassWord(event.target.value)
    }

    const successFetchProfile = res => {
        var cookieUserValue = generateJwtToken(res.user)
        const domainDetails = getDomainInfo()
        let myDate = new Date();
        myDate.setMonth(myDate.getMonth() + 1);
        document.cookie = domainDetails.domainUserLabel + "=" + cookieUserValue + ";expires=" + myDate + ";domain=" + domainDetails.domainName;
        if (domainDetails.type === 'b2b') {
            // (res.user.roles.includes('Author') || res.user.roles.includes('Mentor') || res.user.roles.includes('Instructor'))
            // if (res.user.roles.length > 0 && res.user.roles.includes('Author')) {
            if (res && res.user && res.user.roles.length > 0 && (res.user.roles.includes('Author') || res.user.roles.includes('Mentor') || res.user.roles.includes('Instructor') || res.user.roles.includes('Admin'))) {
                if (domainDetails.client === 'rmbl') {
                    props.history.push('/noticeboard')
                } else {
                    props.history.push('/mycourse')
                }
            }
            else if (res.user.roles.length > 0 && res.user.roles.includes('Student')) {
                if (domainDetails.client === 'rmbl') {
                    window.location = `${domainDetails.renderingURL}/noticeboard`
                    return
                } else {
                    window.location = `${domainDetails.renderingURL}/profile`
                    return
                }
            }
        }
        window.FreshworksWidget('hide')
    }

    const failureFetchProfile = error => {
        props.showSnackBar({
            state: false,
            message: ERROR_FETCHING_PROFILE,
            type: 'error'
        })
        let cookieDetails = getDomainInfo();
        document.cookie = cookieDetails.domainTokenLabel + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=' + cookieDetails.domainName;
        props.history.push('/')
    }

    const successCallBack = res => {
        const domainDetails = getDomainInfo()
        if (res.key) {
            let cookieTokenValue = res.key;
            let myDate = new Date();
            myDate.setMonth(myDate.getMonth() + 1);
            document.cookie = domainDetails.domainTokenLabel + "=" + cookieTokenValue + ";expires=" + myDate + ";domain=" + domainDetails.domainName;
            fetchProfile({}, successFetchProfile, failureFetchProfile)
        }
        setInprogress(false)
    }

    const failureCallBack = error => {
        setValidationError({
            error: true,
            errorMessage: 'Invalid credentials!'
        })
        document.getElementById('errormessages') && document.getElementById('errormessages').focus()
        setInprogress(false)
    }

    const onLogin = event => {
        event.preventDefault()
        setValidationError({
            error: false,
            errorMessage: ''
        })
        setInprogress(true)
        if (userName !== '' && passWord !== '') {
            const payload = {
                user_name: userName,
                pwd: passWord
            }
            login(payload, successCallBack, failureCallBack)
        }
        else {
            setValidationError({
                error: true,
                errorMessage: 'Please fill all required fields!'
            })
            document.getElementById('errormessages') && document.getElementById('errormessages').focus()
            setInprogress(false)
        }
    }
    // https://caseys.rendering.localhost:3000.com

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {clientImg ? (
                        <img src={clientImg} className={subDomain && subDomain.length > 0 && subDomain[0] && subDomain[0] == 'rmbl' ? classes.logoImgRMBL : classes.logoImg} alt={`${getDomainInfo().client} logo`} />
                    ) : null}
                    <img src={myAthinaLogo} className={classes.myAthinaLogo} alt="myathina" />
                </Typography>
                <Typography style={{ margin: 10 }}>
                    Welcome to {window.location.host}
                </Typography>
                {validationError.error ? (
                    <React.Fragment>
                        <div id="errormessages" tabIndex="-1" className="accessibility">
                            <h1>Error Message</h1>
                        </div>
                        <div style={{ marginTop: '20px', width: '73%', marginBottom: '18px', display: 'inline-block' }}>
                            <AlertMessages status="error" message={validationError.errorMessage} />
                        </div>
                    </React.Fragment>
                ) : null}
                <form className={classes.form} noValidate onSubmit={evnt => { evnt.preventDefault() }} >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        className={classes.textField}
                        fullWidth
                        id="userName"
                        placeholder="User Name"
                        name="User Name"
                        onChange={onUserNamehandleChange}
                        autoFocus
                        InputProps={{
                            classes: {
                                notchedOutline: classes.textFiledWrapper
                            },
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        className={classes.textField}
                        fullWidth
                        name="password"
                        placeholder="Password"
                        type="password"
                        InputProps={{
                            classes: {
                                notchedOutline: classes.textFiledWrapper
                            },
                        }}
                        id="password"
                        onChange={onPasswordhandleChange}
                    />
                    {getDomainInfo().client === 'rmbl' ? (
                        <div className={classes.forgotpassword}>
                            <Link href="/forgotpassword" variant="body2">
                                Forgot / Reset password?
                            </Link>
                        </div>
                    ) : null}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={onLogin}
                        disabled={inProgress}
                    >
                        {inProgress && (
                            <CircularProgress size={22} className={classes.buttonProgress} />
                        )}
                        Sign In
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}
const mapStateToProps = state => {
    return {}
}
export default connect(
    mapStateToProps, { showSnackBar }
)(LoginForm);

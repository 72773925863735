import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import myAthinaLogo from '../../static/myAthinaLogoWhite.png';
import Grid from '@material-ui/core/Grid';
import './arrows.css';
import { apiConfig } from '../../Configs/apiConfig'
import { getUrlParams, redirectTo } from '../../Helpers/basic'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    verticalAlign: 'middle',
    width: '197px',
    borderRadius: 2,
    backgroundColor: '#fefefe',
    marginTop: '249px',
    marginLeft: '-71px'
  },
  typo: {
    marginTop: '30px',
    marginLeft: '-35px',
    width: '500px'
  },
}));

export default function RedirectPage() {
  const classes = useStyles();
  const url = getUrlParams(window.location.href, 'url')
  const courseId = getUrlParams(window.location.href, 'courseId')
  useEffect(() => {
    setTimeout(() => {
      redirectTo(`${url}/course/${courseId}`, false)
    }, apiConfig.addingCourse.redirectPageTime)
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div >
        <Grid container spacing={6}>
          <Grid item xs>
            <img src={myAthinaLogo} className={classes.image} id='MyathinaLogo' alt="myathinaHosting" />
          </Grid>
          <Grid item xs>
            <div className="indicatorArrowAnimation">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Grid>
          <Grid item xs={2}>
            <img src={myAthinaLogo} className={classes.image} id='MyathinaLogo' alt="myathinaView" />
          </Grid>
        </Grid>
      </div>
      <Typography className={classes.typo} component="h3" variant="h5" >
        You are redirecting from <b>Myathina - Hosting</b><br />
        To <b>Myathina - CourseView</b> website
      </Typography>
    </Container>
  );
}
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'
import { recordingList, groups, assignedClasses, zoomClasses } from './mockData';


export const getMyRecordingsList = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.fetchRecordings}`
    axios.post(url, payLoad)
        .then(function (response) {
            successCallBack(response.data.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}


export const postAssignRecList = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.assignRecordings}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const updateAssignRecList = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.updateRecordings}`
    axios.put(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}


export const fetchAssignedClasses = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.assignedClasses}`
    axios.get(url, {
        params: payLoad
    })
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
            //successCallBack(assignedClasses)
        })
}


export const fetchZoomClasses = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.classesAssignedToMe}`
    axios.get(url, {
        params: payLoad
    })
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const fetchUsers = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.fetchUsers}`
    axios.get(url)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

export const archiveRecording = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.videoClasses.archiveRecs}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}



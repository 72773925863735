import React from 'react';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress'

export default function DownloadButton(props) {
  const loader = props.reportLoader ? props.reportLoader : false

  const exportCSV = () => {
    props.onClickExport()
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        {loader ? (
          <CircularProgress size={23} style={{ color: '#2e8eec', marginBottom: '-6px' }} />
        ) : null}
        <Tooltip title="Export as CSV">
          <IconButton disabled={loader} aria-label="export" onClick={() => { exportCSV() }}>
            <GetAppIcon style={{ fontSize: 22, color: '#0000FF' }} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { publishDiscussion, publishExternalDiscussion } from '../../store/Discussions/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_DISCUSSION_PUBLISH, ERROR_DISCUSSION_UNPUBLISH } from '../Common/Constants/errorMessages'
import { SUCCESS_DISCUSSION_PUBLISH, SUCCESS_DISCUSSION_UNPUBLISH } from '../Common/Constants/successMessages'
import { style } from '../../Styles/theme'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x',
    color: style.fontColour._blue
  },
  btnWrapper: {
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    }
  }
}));

function DiscussionPublishPopup(props) {
  const classes = useStyles();
  const open = props.openMode === 'unPublish' ? props.openUnPublishPopup : props.openPublishPopup
  const [inProgress, setInprogress] = React.useState(false)

  const handleClose = () => {
    if(props.openMode === 'unPublish'){
      props.closeUnpublish()
    }else{
      props.closePublish();
    }
   };

  const publishSuccessCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_DISCUSSION_PUBLISH,
        type: 'success'
      }
    )
    handleClose()
    props.listAllDiscussions()
  }

  const publishFailureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_DISCUSSION_PUBLISH,
        type: 'error'
      }
    )  
  }
  const unPublishsuccessCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_DISCUSSION_UNPUBLISH,
        type: 'success'
      }
    )
    handleClose()
    props.listAllDiscussions()
  }

  const unPublishfailureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_DISCUSSION_UNPUBLISH,
        type: 'error'
      }
    )
  }

  const publishDiscussionClick = () => {
    setInprogress(true)
    if(props.openMode === 'unPublish'){
      const payload = {
        id: props.discussionData.id ? props.discussionData.id : null,
        published: false
      }
      if(props.from === 'externalType'){
        let formData = new FormData()
        formData.append('published', false)
        publishExternalDiscussion(payload, formData, unPublishsuccessCallBack ,unPublishfailureCallBack)
      }else{
        publishDiscussion(payload, unPublishsuccessCallBack, unPublishfailureCallBack)
      }
    }
    else{
      const payload = {
        id: props.discussionData.id ? props.discussionData.id : null,
        published: true
      }
      if(props && props.from === 'externalType'){
        let formData = new FormData()
        formData.append('published', true)
        publishExternalDiscussion(payload, formData, publishSuccessCallBack ,publishFailureCallBack)
      }else{
        publishDiscussion(payload, publishSuccessCallBack, publishFailureCallBack)
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Discussion publish dialog"
      >
        <DialogTitle id="alert-dialog-title"> 
          { props.openMode !== 'unPublish' ? 
            (
              'Are you sure you want to publish this Discussion?'
            ) : 
            (
              'Are you sure you want to Unpublish this Discussion?'
            )
          }
        </DialogTitle>
        { props.openMode !== 'unPublish' ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once you publish this Discussion you can't edit the Discussion
            </DialogContentText>
          </DialogContent>
        ) : null }
        <DialogActions>
        { inProgress ? (
          <CircularProgress size={30} className={classes.buttonProgress} />
        ): null }
          <Button onClick={publishDiscussionClick} disabled={inProgress} className={classes.btnWrapper} variant="contained" autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose} disabled={inProgress} style={{ textTransform: 'none' }} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
    
export default connect(
  mapStateToProps, { showSnackBar }
)(DiscussionPublishPopup);

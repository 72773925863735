import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
//local imports
import { style } from '../../Styles/theme';
import AlertMessages from '../Common/AlertMessages';
import { clientImgMap } from '../../Configs/clientImgConfig';
import myAthinaLogo from '../../static/athinaLogo.png'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { changePassword } from '../../store/Auth/actionCreator';
import { getDomainInfo } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '15%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '14px 13px 21px 14px'
  },
  myAthinaLogoWrapper: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '23px',
    marginLeft: 10
  },
  logoImg: {
    width: '43px'
  },
  buttonProgress: {
    color: style.fontColour._blue,
  },
  label: {
    width: '100%',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: '-3px',
    color: '#777'
  },
  logoImg: {
    width: '70px',
    marginTop: '12px'
  },
  logoImgRMBL: {
    width: '87px',
    height: '67px',
    marginTop: '12px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancel: {
    margin: theme.spacing(3, 1, 2),
  },
  iconWrapper: {
    fontSize: '18px',
    color: style.fontColour._blue
  },
  textFiledWrapper: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    marginRight: '10px',
    marginTop: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  }
}));

function ChangePassword(props){
  const classes = useStyles();
  const url = window.location.href
  const domainDetails = getDomainInfo()
  const subDomain = url.slice(url.indexOf('//') + 2).split('.')
  const clientImg = clientImgMap[subDomain[0]] && clientImgMap[subDomain[0]].img ? clientImgMap[subDomain[0]].img : null
  const [inProgress, setInProgress] = useState(false)
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  })
  const [errMsg, setErrMsg] = useState({
    err: false,
    msg: ''
  })

  useEffect(()=>{
    window.FreshworksWidget('hide')
  },[])

  const successChangePassword = res => {
    setErrMsg({
      err: false,
      msg: ''
    })
    setInProgress(false)
    props.history.push('/')
    props.showSnackBar({
      state: true,
      message: 'Password updated successfully!',
      type: 'success'
    })
  }
  const failureChangePassword = err => {
    setErrMsg({
      err: false,
      msg: ''
    })
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: 'Something went wrong! While changing password!',
      type: 'error'
    })
  }

  const onChangePassword = event => {
    event.preventDefault()
    const password = document.getElementById('password').value
    const confirmPassword = document.getElementById('confirmPassword').value
    if(password === '' || confirmPassword === '') {
      setErrMsg({
        err: true,
        msg: 'Please enter required information.'
      })
      document.getElementById('errormessages') && document.getElementById('errormessages').focus()
    }else if(password !== confirmPassword) {
      setErrMsg({
        err: true,
        msg: 'Both passwords should be same!'
      })
      document.getElementById('errormessages') && document.getElementById('errormessages').focus()
    }else{
      const payload = {
        password: password,
        token: props.match.params.userToken
      }
      setInProgress(true)
      changePassword(payload, successChangePassword, failureChangePassword)
    }
  }

  const handleClickShowPassword = (type) => {
    if(type === 'password'){
      setShowPassword({
        password: !showPassword.password,
        confirmPassword: showPassword.confirmPassword
      })
    }else{
      setShowPassword({
        password: showPassword.password,
        confirmPassword: !showPassword.confirmPassword
      })
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return(
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          {clientImg ? (
            <img src={clientImg} className={subDomain.length > 0 && subDomain[0] && subDomain[0] == 'rmbl' ? classes.logoImgRMBL : classes.logoImg} alt={`${domainDetails.client} logo`} />
          ) : null}
          <img src={myAthinaLogo} className={classes.myAthinaLogoWrapper} alt="myathina" />
        </Typography>
        {errMsg.err ? (
          <React.Fragment>
            <div id="errormessages" tabIndex="-1" className="accessibility">
                <h1>Error Message</h1>
            </div>
            <div style={{marginTop: '20px',width: '67%'}}>
              <AlertMessages status={errMsg.err ? 'error' : 'success'} message={errMsg.msg} />
            </div>
          </React.Fragment>
        ) : null}
        <React.Fragment>
          <Typography component="h2" variant="h6" className={classes.label}>Create new password</Typography>
          <form className={classes.form} noValidate onSubmit={(event)=>{onChangePassword(event)}} autoComplete="off">
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              name="password"
              placeholder="Password"
              type={showPassword.password ? 'text' : 'password'}
              id="password"
              InputProps={{
                classes: {
                  notchedOutline: classes.textFiledWrapper
                },
                endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle secret visibility"
                      onClick={()=>{handleClickShowPassword('password')}}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.password ? <Visibility className={classes.iconWrapper}/> : <VisibilityOff className={classes.iconWrapper}/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              name="confirmPassword"
              placeholder="Confirm Password"
              type={showPassword.confirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              InputProps={{
                classes: {
                  notchedOutline: classes.textFiledWrapper
                },
                endAdornment: 
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle secret visibility"
                      onClick={()=>{handleClickShowPassword('confirmpassword')}}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.confirmPassword ? <Visibility className={classes.iconWrapper}/> : <VisibilityOff className={classes.iconWrapper}/>}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={(event)=>{onChangePassword(event)}}
              disabled={inProgress}
            >
              {inProgress && (
                <CircularProgress size={25} className={classes.buttonProgress} />
              )}
              Reset password
            </Button>
          </form>
        </React.Fragment>
      </Paper>
    </Container>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {showSnackBar}
)(ChangePassword);
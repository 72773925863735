import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
//Local imports
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { fetchOrgMembersListing, fetchMemberDataListTabCnt, fetchSingleGrpData, fetchGrpStructure } from '../../store/ManageGroups/actionCreator';
import { ERROR_FETCH_GROUP_DATA, ERROR_FETCHING_GRP_STUDENTS_INFO, ERROR_FETCHING_GRP_MENTORS_INFO, ERROR_FETCHING_GRP_INSTRUCTORS_INFO, ERROR_FETCHING_GRP_TABCOUNT_INFO } from '../Common/Constants/errorMessages';
import ManageGrpLoader from '../Common/skeletonLoaders/manageGrpLoader';
import TabView from './tabView'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: style.backgroundColor._lightWhite,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  breadCrumb: {
    color: '#333',
    padding: 6
  },
  links: {
    color: style.fontColour._blue
  }
}))

function AddGroup(props) {
  const classes = useStyles()
  const [grpData, setGrpData] = useState({})
  const [inProgress, setInProgress] = useState(true)
  const [tabCounts, setTabCounts] = useState({
    students: 0,
    mentors: 0,
    instructors: 0
  })
  const [studentsData, setStudentsData] = useState([])
  const [mentorsData, setMentorsData] = useState([])
  const [instructorsData, setInstructorsData] = useState([])
  const [paginationLoader, setPaginationLoader] = useState(true)
  const [grpStructureInfo, setGrpStructureInfo] = useState({})
  const [openMode, setOpenMode] = useState('addMode')

  //for fetching group structure info callbacks
  const successStructureCB = res => {
    let openMode = res && res.data && Object.keys(res.data) && Object.keys(res.data).length ? 'editMode' : 'addMode'
    setOpenMode(openMode)
    setGrpStructureInfo(res && res.data && res.data)
  }
  const failureStructureCB = err => {
    setGrpStructureInfo({})
  }

  //SINGLE GRP DATA CALLBACKS
  const successFetchData = res => {
    setGrpData(res && res.data)
    if (res && res.data.members_count) {
      const payload = {
        group_id: props.match.params.groupId
      }
      fetchGrpStructure(payload, successStructureCB, failureStructureCB)
    }
  }
  const failureFetchData = err => {
    setGrpData({})
    props.showSnackBar({
      state: false,
      message: ERROR_FETCH_GROUP_DATA,
      type: 'error'
    })
  }

  //FOR STUDENT DATA CALLBACKS
  const successStudentsData = res => {
    setStudentsData(res && res.data && res.data)
    setPaginationLoader(false)
  }
  const failureStudentsData = err => {
    setStudentsData([])
    setPaginationLoader(false)
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_GRP_STUDENTS_INFO,
      type: 'error'
    })
  }
  //FOR MENTORS DATA CALLBACKS
  const successMentorsData = res => {
    setMentorsData(res && res.data && res.data)
    setPaginationLoader(false)
  }
  const failureMentorsData = err => {
    setMentorsData([])
    setPaginationLoader(false)
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_GRP_MENTORS_INFO,
      type: 'error'
    })
  }
  //FOR INSTRUCTORS DATA CALLBACKS
  const successInstructorsData = res => {
    setInstructorsData(res && res.data && res.data)
    setPaginationLoader(false)
  }
  const failureInstructorsData = err => {
    setInstructorsData([])
    setPaginationLoader(false)
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_GRP_INSTRUCTORS_INFO,
      type: 'error'
    })
  }

  //TAB COUNTS CALLBACKS
  const successTabCnt = res => {
    setInProgress(false)
    setTabCounts({
      students: res && res.students,
      mentors: res && res.mentors,
      instructors: res && res.instructors
    })
  }
  const failureTabCnt = err => {
    setInProgress(false)
    setTabCounts({
      students: 0,
      mentors: 0,
      instructors: 0
    })
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_GRP_TABCOUNT_INFO,
      type: 'error'
    })
  }

  const fetchOrgMembersData = (type) => {
    setPaginationLoader(true)
    if (type === 'Mentor') {
      const payload = {
        group: 'Mentor'
      }
      fetchOrgMembersListing(payload, successMentorsData, failureMentorsData)
    } else {
      const payload = {
        group: 'Student'
      }
      fetchOrgMembersListing(payload, successStudentsData, failureStudentsData)
    }
  }

  useEffect(() => {
    setInProgress(true)
    const payload = {
      group_id: props.match.params.groupId
    }
    fetchSingleGrpData(payload, successFetchData, failureFetchData)
    fetchMemberDataListTabCnt({}, successTabCnt, failureTabCnt)
    fetchOrgMembersData()
  }, [])

  const onTabChange = tabKey => {
    setPaginationLoader(true)
    if (tabKey === 0) {
      const payload = {
        group: 'Student'
      }
      fetchOrgMembersListing(payload, successStudentsData, failureStudentsData)
    } else if (tabKey === 1) {
      const payload = {
        group: 'Mentor'
      }
      fetchOrgMembersListing(payload, successMentorsData, failureMentorsData)
    } else {
      const payload = {
        group: 'Instructor'
      }
      fetchOrgMembersListing(payload, successInstructorsData, failureInstructorsData)
    }
  }

  const getBreadCrumbs = () => {
    return (
      <div>
        <div className={classes.breadCrumb}>
          <Route>
            {(
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className={classes.links} aria-label="Breadcrumb">
                <Link style={{ textDecoration: 'none', color: style.fontColour._blue }} color="inherit" to="/managegroups">
                  Manage Groups
                </Link>
                <div color="textPrimary" style={{ color: style.fontColour._grey, fontWeight: style.fontWeight._sideHeading }}>
                  {grpData && grpData.group_title ? grpData.group_title : 'NA'}
                </div>
              </Breadcrumbs>
            )}
          </Route>
        </div>
      </div>
    )
  }

  return (
    <div>
      { inProgress ? (
        <ManageGrpLoader />
      ) : (
          <React.Fragment>
            {getBreadCrumbs()}
            <div className={classes.root}>
              <Grid container spcaing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.titleWrapper} aria-hidden="true">{grpData && grpData.group_title ? grpData.group_title : ''}</Typography>
                    <Typography style={{ fontSize: '13px', marginTop: '3px', color: '#9e9e9e', fontWeight: '500' }}>{grpData && grpData.group_desc ? grpData.group_desc : ''}</Typography>
                    <Divider style={{ marginBottom: '20px', marginTop: '10px' }} aria-hidden="true" />
                    <TabView
                      groupInfo={grpData}
                      studentsData={studentsData}
                      mentorsData={mentorsData}
                      instructorsData={instructorsData}
                      tabDataCounts={tabCounts}
                      onTabChange={onTabChange}
                      paginationLoader={paginationLoader}
                      grpStructureInfo={grpStructureInfo}
                      fetchOrgMembersData={fetchOrgMembersData}
                      openMode={openMode}
                      match={props.match}
                      history={props.history}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        )}
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AddGroup);


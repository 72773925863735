import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'
import { fetchExternalAssessments } from '../../store/Assessments/actionCreator'
import AddAssessmentForm from '../Assessments/addAssessment'
import AssessmentListing from './assessmentListing'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  button: {
    flexGrow: 1,
    marginRight: '38px',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  },
}))

function MyAssessments(props) {
  const classes = useStyles()
  const rowsPerPage = 10
  const offset = 0
  const [page, setPage] = useState(0);
  const [inProgress, setInProgress] = useState(false)
  const [addAssessmentMode, setAddAssessmentMode] = useState(false)
  const [assessmentData, setAssessmentsData] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const successFetchCB = res => {
    setInProgress(false)
    setAssessmentsData(res && res.results)
    setTotalCount(res && res.totalCount)
  }
  const failureFetchCB = err => {
    setInProgress(false)
    setAssessmentsData([])
    setTotalCount(0)
  }

  const listAssessments = () => {
    const payload = {
      offset: offset,
      number: 10
    }
    fetchExternalAssessments(payload, successFetchCB, failureFetchCB)
  }

  useEffect(()=>{
    setInProgress(true)
    listAssessments()
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  },[])

  const listLoader = () => {
    let children = []
    for (let i = 0; i < 4; i++) {
     children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader/></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  const triggerAddAssessment = () => {
    setAddAssessmentMode(true)
  }

  const closeAddForm = () => {
    setAddAssessmentMode(false)
  }

  const onPagination = (pageNum) => {
    setInProgress(true)
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    const payload = {
      offset: offset,
      number: number
    }
    fetchExternalAssessments(payload, successFetchCB, failureFetchCB)
  }

  return(
    <div className={classes.root}>
      {inProgress ? listLoader() : (
        <React.Fragment>
          { addAssessmentMode ? (
            <div style={{ marginTop: '-86px' }}>
              <AddAssessmentForm match={props.match} history={props.history} cancelAddAssessment={closeAddForm} listAllAssessment={listAssessments} from="externalType"/>
            </div>
          ) : (
            <React.Fragment>
              { assessmentData && assessmentData.length ? (
                <div style={{ textAlign: 'right', marginRight: '21px' }}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={triggerAddAssessment}
                  >
                    <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
                      Assessment
                  </Button>
                </div>
              ) : null }
            </React.Fragment>
          )}
          { inProgress ? listLoader() : (
            <React.Fragment>
              { assessmentData && !addAssessmentMode ? (
                <AssessmentListing 
                  assessmentData={assessmentData}
                  history={props.history}
                  match={props.match}
                  listAllAssessment={listAssessments}
                  triggerAddAssessment={triggerAddAssessment}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPaginationHit={onPagination}
                  totalCount={totalCount}
                />
              ) : null }
            </React.Fragment>
          )}
        </React.Fragment>
      ) }
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(MyAssessments);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import Tooltip from '@material-ui/core/Tooltip'
import { convertUTCTimeZone } from '../../../../Helpers/basic'


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    height: '42px',
    backgroundColor: '#F5F5F5',
  },
  title: {
    flex: 1,
    marginTop: '-24px',
    color: 'black'
  },
  iconWrapper: {
    marginTop: '-24px'
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        width: 1098,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    marginTop: '20px'
  },
  textWrapper: {
    fontWeight: 500,
    color: '#2e8eec',
    fontSize: '17px',
    textAlign: 'center'
  },
  detailsHead: {
    fontSize: '15px'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPopup(props) {
  const classes = useStyles();
  const open = props.openPreview
  const assignmentDetails = {
    title: props.previewDetails && props.previewDetails.title ? props.previewDetails.title : '',
    desc: props.previewDetails && props.previewDetails.desc ? props.previewDetails.desc : '',
    duration_details: props.previewDetails && props.previewDetails.end_date ? props.previewDetails.end_date : '',
    file: props.previewDetails && props.previewDetails.attachment ? props.previewDetails.attachment : null,
    assignment_id: props.previewDetails && props.previewDetails.id ? props.previewDetails.id : null,
    points: props.previewDetails && props.previewDetails.points ? props.previewDetails.points : 0,
  }

  const assessmentDetails = {
    title: props.previewDetails && props.previewDetails.title ? props.previewDetails.title : '',
    desc: props.previewDetails && props.previewDetails.desc ? props.previewDetails.desc : '',
    test_score: props.previewDetails && props.previewDetails.percent ? props.previewDetails.percent : 0,
    ques_count: props.previewDetails && props.previewDetails.ques_count,
    test_type: props.previewDetails && props.previewDetails.test_type ? props.previewDetails.test_type : '',
    due_date: props.previewDetails && props.previewDetails.due_date ? props.previewDetails.due_date : 'NA'
  }
  
  function createMarkup(data) {
    return {
      __html: data
    };
  }

  const fileType = () => {
    if(props.previewDetails && props.previewDetails.attachment){
        let subName = assignmentDetails && assignmentDetails.file.split('?')[0];
        var lastIndex = subName && subName.lastIndexOf('.');
        let fileType = subName && subName.slice(lastIndex);
        let type = '';
        if (fileType === '.ppt' || fileType === '.pptx') {
          type = 'ppt';
        } else if (fileType === '.doc' || fileType === '.docx') {
          type = 'doc';
        }
        else if (fileType === '.pdf') {
          type = 'pdf';
        }
        return type
      }
  }

  const dateFormat = (due_days) => {
    return(convertUTCTimeZone(due_days))
  }

  return (
    <div>
      <Dialog fullScreen open={open} onClose={props.closeAssignmentPreivew} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title} aria-hidden="true">
              { props && props.fromType === 'assessment' ? 'Assessment Preview' : 'Assignment Preview' }
            </Typography>
            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
              { props && props.fromType === 'assessment' ? (
                <h1>assessment details preview popup</h1>
              ) : (
                <h1>assingment details preview popup</h1>
              )}
            </div>
            <IconButton edge="start" className={classes.iconWrapper} onClick={props.closeAssignmentPreivew} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.textWrapper}>
                  { props && props.fromType === 'assessment' ? (
                    assessmentDetails.title ? assessmentDetails.title : '' 
                  ) : (
                    assignmentDetails.title ? assignmentDetails.title : '' 
                  )}
                </Typography>
              </Grid>
              {  props && props.fromType === 'assessment' ? (
                <React.Fragment>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>Test Type:</b> </span>
                    <span>{assessmentDetails.test_type}</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>Questions count:</b> </span>
                    <span>{assessmentDetails.ques_count}</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>Assessment score:</b> </span>
                    <span>{`${assessmentDetails.test_score}%`}</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '10px'}}>
                    <span><b>Due Date:</b> </span>
                    <span>{dateFormat(assessmentDetails.due_date)}</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '10px'}}>
                    <span><b>Description:</b> </span>
                    <span className={classes.detailsHead}>{assessmentDetails.desc}</span>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>Due date:</b> </span>
                    <span>{dateFormat(assignmentDetails.duration_details)}</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '16px'}}>
                    <span ><b>Points:</b> </span>
                    <span>{assignmentDetails.points} pts</span>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '10px'}}>
                    {/* <Typography variant="caption" display="block" className={classes.infoBlock}> */}
                      <span><b>Description:</b> </span>
                      <span className={classes.detailsHead}><div dangerouslySetInnerHTML={createMarkup(assignmentDetails.desc)}></div></span>
                    {/* </Typography> */}
                  </Grid>
                  { assignmentDetails.file ? (
                    <Grid item xs={12}>
                      <span ><b>Uploaded Attachment:</b> </span>
                      <Tooltip title='Uploaded Attachment'>
                        <IconButton aria-label="Download Document" color="primary">
                          <a href={assignmentDetails.file} target={fileType() === 'pdf' ? '_blank' : ''}>{fileType() === 'pdf' ? <PictureAsPdfIcon /> : fileType() === 'ppt' ? <PictureInPictureIcon /> : <DescriptionIcon/> }</a>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null }
                </React.Fragment>
              )}
            </Grid>
          </Paper>
        </main>
      </Dialog>
    </div>
  );
}
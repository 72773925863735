import React from 'react';
import { connect } from 'react-redux';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { getCourseOpenModeType } from '../../Helpers/basic';

function Header(props) {
  const courseData = props.courseDetails
  const discussionData = props.discussionData
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  const navigateToDiscussionLevel = () => {
    if (props.match.params.courseId) {
      if (base64EncodeValue) {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/discussions/${courseData.id}?${base64EncodeValue}`}>
            {props.discussionData && props.discussionData.title && props.discussionData.title.length > 20 ?
              (
                props.discussionData.title.substr(0, 25) + '...'
              ) :
              props.discussionData.title
            }
          </Link>
        )
      } else {
        return (
          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/discussions/${courseData.id}`}>
            {props.discussionData && props.discussionData.title && props.discussionData.title.length > 20 ?
              (
                props.discussionData.title.substr(0, 25) + '...'
              ) :
              props.discussionData.title
            }
          </Link>
        )
      }
    } else if (props && props.from === 'externalDiscussions') {
      return (
        <Link style={{ textDecoration: 'none' }} color="inherit" to={`/externaldiscussions`}>
          Manage Discussions
        </Link>
      )
    }
  }
  return (
    <div>
      <div>
        <Route>
          {
            (
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb Navigation">
                <Link style={{ textDecoration: 'none' }} color="inherit" to="/mycourse">
                  Home
                </Link>
                {props && props.from !== 'externalDiscussions' ? (
                  <React.Fragment>
                    {courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode' ? (
                      <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${courseData && courseData.id}?${base64EncodeValue}`}>
                        {courseData && courseData.title ? `Course: ${courseData.title}` : 'NA'}
                      </Link>
                    ) : (
                      <React.Fragment>
                        {courseOpenedMode === 'from_mycourses' ? (
                          <div color="textPrimary">
                            {courseData && courseData.title ? `Course: ${courseData.title}` : 'NA'}
                          </div>
                        ) : (
                          <Link style={{ textDecoration: 'none' }} color="inherit" to={`/mycourse/${courseData && courseData.id}`}>
                            {courseData && courseData.title ? `Course: ${courseData.title}` : 'NA'}
                          </Link>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : null}
                {discussionData && discussionData.title ? (
                  navigateToDiscussionLevel()
                ) :
                  (
                    <div color="textPrimary">
                      Discussions
                    </div>
                  )
                }
                {discussionData && discussionData.title ? (
                  <div color="textPrimary">
                    Discussion Report
                  </div>
                ) : null}
              </Breadcrumbs>
            )
          }
        </Route>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(Header);
import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '../ExternalAssignments/pagination'
import { convertUTCTimeZone } from '../../Helpers/basic'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    marginBottom: '29px'
  },
  headerTab: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  dataCount: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: '1.5rem'
  },
  pendingApprovalWrapper: {
    paddingTop: '8px',
  },
  tableHeadingCell: {
    padding: 10,
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#333',
    backgroundColor:'#dedede'
  },
  tableCell: {
    padding: 10,
    fontSize: '0.8rem',
    textAlign: 'center',
    backgroundColor: '#fafafa'
  },
  loadWrapper: {
    textAlign: 'left',
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '8px',
    color: '#2e8eec',
    marginLeft: '16px'
  },
}));

export default function TabView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0)
  const reportData = props.assessmentReportData && props.assessmentReportData.length ? props.assessmentReportData : []

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onTabChange(newValue)
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const tabObjData = {
    yet_to_start: {
      label: 'yet-to-start',
      count: props.tabCount.yet_to_start,
      list: reportData
    },
    pending: {
      label: 'In-progress',
      count: props.tabCount.inProgress,
      list: reportData
    },
    completed: {
      label: 'completed',
      count: props.tabCount.completed,
      list: reportData
    }
  }

  const tabObj = tabObjData
  const renderTabs = () => {
    const label = (str, count) => {
      return (
        <div>
          <span className={classes.tabName}>{str}</span>
          <span className={classes.dataCount}>({count})</span>
        </div>
      )
    }
    const tabs = Object.keys(tabObj).map(key =>
      <Tab className={classes.headerTab} label={label(tabObj[key].label, tabObj[key].count)} {...a11yProps(key)} key={key} />
    )
    return tabs
  }

  const renderTabContentPanel = () => {
    const tabs = Object.keys(tabObj).map(key =>
      <TabPanel value={key} index={key} dir={theme.direction} key={key}>
        {tabObj[key].count ? (
          <div className={classes.pendingApprovalWrapper}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="assignment report">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableHeadingCell}>User Name</TableCell>
                    { value === 0 ? (
                      <React.Fragment>
                        <TableCell align="center" className={classes.tableHeadingCell}>Department</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Assignment Status</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Due Date</TableCell>
                      </React.Fragment>
                    ): (
                      <React.Fragment>
                        <TableCell align="center" className={classes.tableHeadingCell}>Department</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>User Score</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Due Date</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Completed Date</TableCell>
                      </React.Fragment>
                    ) }
                  </TableRow>
                </TableHead>
                <TableBody>
                  { props.paginationLoader ? (
                    <Typography className={classes.loadWrapper}>Loading....</Typography>
                  ) : (
                    <React.Fragment>
                      {tabObj[key].list && tabObj[key].list.length ? (
                        <React.Fragment>
                          {tabObj[key].list.map((row, reqKey) => (
                            <TableRow key={reqKey}>
                              <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                {row && row.user_name && row.user_name}
                              </TableCell>
                              { value === 0 ? (
                                <React.Fragment>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {row && row.department && row.department.name && row.department.name}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {row && row.status === 1 ? 'yet-to-start' : row.status === 2 ? 'in-Progress' : 'completed' }
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {row && row.due_date ? convertUTCTimeZone(row.due_date) : 'NA' }
                                  </TableCell>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {row && row.department && row.department.name && row.department.name}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {`${row && row.score_percent}%`}
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {row && row.due_date ? convertUTCTimeZone(row.due_date) : 'NA' }
                                  </TableCell>
                                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                    {row && row.completed_date ? convertUTCTimeZone(row.due_date) : 'NA' }
                                  </TableCell>
                                </React.Fragment>
                              ) }
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ) : (
                        <div>No data found</div>
                      ) }
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <React.Fragment>
            <div>No data found</div>
          </React.Fragment>
        )}
      </TabPanel>
    )
    return tabs
  }

  const getTabCount = () => {
    if(value === 0){
      return props.tabCount.yet_to_start
    }else if(value === 1){
      return props.tabCount.inProgress
    }else{
      return props.tabCount.completed
    }
  }

  return(
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {renderTabContentPanel()}
      </SwipeableViews>
      { getTabCount() > 10 && !props.hidePagination ? (
        <Pagination type={value} onPagination={props.onPagination} enableRowsPerPage={false} page={props.page} rowsPerPage={props.rowsPerPage} totalCount={getTabCount()}/>
      ): null }
    </div>
  )
}
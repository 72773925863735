import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge';
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ReplyIcon from '@material-ui/icons/Reply'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCommentIcon from '@material-ui/icons/AddComment'
import SmsIcon from '@material-ui/icons/Sms'
import Button from '@material-ui/core/Button'
import InfiniteScroll from "react-infinite-scroll-component";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
//LOCAL IMPORTS
import Header from '../DiscussionBoard/header';
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import AlertMessages from '../Common/AlertMessages'
import TextEditor from '../Common/TextEditor'
import { fetchExternalDiscussion, fetchExternalResponses, externalRespondedStudents, fetchExternalSubReplies, externalPostReply, pinExternalDiscussion, externalPostComment, fetchExternalStudentResponses, externalSumbitGrade, externalUpdateGrade, fetchExternalPinnedResponses } from '../../store/Discussions/actionCreator'
import { ERROR_FETCHING_DISCUSSION_RESPONSE, ERROR_PIN_DISCUSSION, ERROR_UNPIN_DISCUSSION, ERROR_FETCHING_PINNED_RESPONSE, ERROR_UPDATE_DISCUSSION_GRADE, ERROR_SUBMIT_DISCUSSION_GRADE, ERROR_FETCHING_SUBMITTED_USERS_LIST, ERROR_FETCHING_DISCUSSION, ERROR_FETCHING_COMMENTS, ERROR_POST_REPLY, ERROR_FETCHING_STUDENT_RESPONSES, ERROR_POST_COMMENT } from '../Common/Constants/errorMessages';
import { SUCCESS_POST_REPLY, SUCCESS_SUBMIT_DISCUSSION_GRADE, SUCCESS_PIN_DISCUSSION, SUCCESS_UNPIN_DISCUSSION, SUCCESS_UPDATE_DISCUSSION_GRADE, SUCCESS_POST_COMMENT } from '../Common/Constants/successMessages'
import { colorStyles, style } from '../../Styles/theme'
import { convertUTCDatewithTime } from '../../Helpers/basic'
import SubRepliesLoader from '../Common/skeletonLoaders/subRepliesLoader'
import DiscussionLoader from '../Common/skeletonLoaders/discussionLoader'
import ResponsesLoader from '../Common/skeletonLoaders/discussionReponsesLoader'
import GradeViewLoader from '../Common/skeletonLoaders/gradeViewLoader'
import pinIcon from '../../static/pinIcon.png'
import unPinIcon from '../../static/unPinIcon.png'
import responseIcon from '../../static/responseIcon.png'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { bytesToSize } from '../../Helpers/basic';
import { apiConfig } from '../../Configs/apiConfig'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    flexGrow: 1,
    marginTop: '10px'
  },
  breadCrumbcontainer: {
    padding: '8px',
    marginTop: '-12px',
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingRight: '20px',
      marginLeft: '-38px',
      marginRight: 'auto',
    },
    marginTop: '20px'
  },
  paperHeader: {
    marginTop: '20px',
    background: colorStyles.background
  },
  commentsWrapper: {
    padding: '1rem',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '20px',
      paddingRight: '20px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  containerWrapper: {
    display: 'flex',
    paddingLeft: '20px'
  },
  title: {
    fontSize: '19px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'left',
    lineBreak: 'anywhere'
  },
  discussionTitle: {
    fontSize: '19px',
    textAlign: 'initial',
    padding: '0.5rem',
    fontWeight: '500',
    color: style.fontColour._blue,
  },
  discussionPoints: {
    fontSize: '14px',
    textAlign: 'initial',
    padding: '0.5rem',
    fontWeight: '500',
    color: style.fontColour._blue,
  },
  itemInfo: {
    padding: '0.2rem',
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._naviBlue,
    textAlign: 'left',
    lineBreak: 'anywhere'
  },
  repliesWrapper: {
    fontSize: '15px',
    marginBottom: '10px',
    color: style.fontColour._blue,
  },
  gradeitemInfo: {
    padding: '0.2rem',
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._naviBlue,
    textAlign: 'center'
  },
  descWrapper: {
    textAlign: 'left',
    margin: '1rem',
    lineBreak: 'anywhere'
  },
  avatarComments: {
    backgroundColor: '#6cb5f9'
  },
  avatarReplies: {
    backgroundColor: style.fontColour._naviBlue,
  },
  titleReplies: {
    fontSize: '19px',
    fontWeight: '500',
    color: style.fontColour._naviBlue,
    textAlign: 'left'
  },
  BtnWrapper: {
    marginTop: '10px',
    float: 'right'
  },
  replyIcon: {
    fontSize: '20px',
    paddingRight: '2px',
  },
  UserListWrapper: {
    height: '500px',
    overflow: 'auto',
    border: '18px',
    width: '83%',
    boxShadow: '1px 2px 3px 1px #ccc',
    borderRadius: '5px',
    marginTop: '20px'
  },
  postBtnWrap: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
    borderRadius: '5px',
    border: '1px solid #999',
    padding: '5px',
  },
  countIcon: {
    color: style.fontColour._blue,
    fontSize: '20px',
    marginTop: '10px',
  },
  gradeWrapper: {
    marginBottom: '30px',
    marginTop: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  gradeTitle: {
    fontSize: '19px',
    marginTop: '20px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'center'
  },
  infoBlock: {
    color: style.fontColour._blue,
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  closeGradeWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    float: 'right',
    marginRight: '1px',
    marginLeft: '20px',
    borderRadius: '5px',
    border: '1px solid #999',
    padding: '5px'
  },
  iconWrapper: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
  },
  readingsButton: {
    marginTop: '9px',
    color: style.fontColour._blue
  },
  infoItemsCtr: {
    display: 'flex',
    justifyContent: 'center'
  },
  infoItems: {
    padding: "0.5rem"
  },
  infoIcon: {
    color: style.iconColor._color,
    marginTop: '0.5rem'
  },
  infoBtn: {
    padding: "0.5rem"
  },
  pinResCtr: {
    marginTop: '20px',
    background: colorStyles.background
  },
  resEditForm: {
    marginTop: "1rem",
    padding: '0.5rem'
  },
  replyMsg: {
    marginTop: '-18px',
    marginLeft: '73px',
    textAlign: 'initial',
    marginRight: '1px',
    lineBreak: 'anywhere'
  },
  replyMsgMob: {
    marginTop: '-18px',
    marginLeft: '25px',
    textAlign: 'initial',
    marginRight: '1px',
    lineBreak: 'anywhere'
  },
  vwMore: {
    display: 'block',
    marginLeft: '74px',
    marginTop: '7px',
    marginBottom: '8px',
    fontSize: '0.7rem',
    color: style.fontColour._blue,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  vwMoreMob: {
    display: 'block',
    marginLeft: '25px',
    marginTop: '7px',
    marginBottom: '8px',
    fontSize: '0.7rem',
    color: style.fontColour._blue,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  replyInpMob: {
    width: '100%',
    color: style.fontColour._blue,
  },
  replyInp: {
    width: "70%",
    color: style.fontColour._blue,
  },
  replyCtr: {
    marginLeft: '60px',
    marginTop: '20px'
  },
  replyCtrMob: {
    marginLeft: '20px',
    marginTop: '20px'
  },
  subReplyCmt: {
    marginLeft: '73px',
    textAlign: 'initial',
    lineBreak: 'anywhere'
  },
  subReplyCmtMob: {
    marginLeft: '25px',
    textAlign: 'initial',
    lineBreak: 'anywhere'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 186,
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    border: '2px solid #ccc',
  },
  uploadLabel: {
    marginTop: '5px'
  },
  uploadIcon: {
    marginTop: '-3px',
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedVideo: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    cursor: 'pointer'
  },
}))

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 11,
    backgroundColor: '#2e8eec',
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '4px 4px',
  },
}))(Badge);

function DiscussionReport(props) {
  const classes = useStyles();
  const mobileView = useMediaQuery('(max-width:600px)');
  const [discussionData, setDiscussionData] = useState({})
  const [discussionReplyCount, setDiscussionReplyCount] = useState(0)
  const [discussionLoader, setDiscussionLoader] = useState(false)
  const [openGiveGradeView, setOpenGiveGradeView] = useState(false)
  const [gradeViewLoader, setGradeViewLoader] = useState(false)
  const [openPostResponseFiled, setOpenPostResponseField] = useState(false)
  const [gradeBtnLoader, setGradeBtnLoader] = useState(false)
  const [postBtnLoader, setPostBtnLoader] = useState(false)
  const [responsesLoader, setResponsesLoader] = useState(false)
  const [subRepliesLoader, setSubRepliesLoader] = useState(false)
  const [loadMoreBtnPrgs, setLoadMoreBtnPrgs] = useState(false)
  const [respondedStudentList, setRespondedStudentList] = useState([])
  const [studentResponses, setStudentResponses] = useState({})
  const [postResponse, setPostResponse] = useState('')
  const defaultPoints = studentResponses && studentResponses.user_score !== null ? studentResponses.user_score : null
  const [studentGrade, setStudentGrade] = useState(defaultPoints)
  const [pinnedResponseData, setPinnedResponseData] = useState([])
  const [responseData, setResponseData] = useState([])
  const rowsPerPage = 10
  const [hasMoreData, setHasMoreData] = useState(true)
  const [pageNum, setPageNum] = useState(0)
  const [responseId, setResponseId] = useState('')
  const [postReplyData, setpostReplyData] = useState('')
  const [subReplies, setSubReplies] = useState([])
  const [viewCommentsIndex, setViewCommentsIndex] = useState('')
  const [pageNumForSubReplies, setPageNumForSubReplies] = useState(0)
  const [selectedDropdownUser, setSelectedDropdownUser] = useState('')
  const [validationDiscussionError, setValidationDiscussionError] = useState({
    error: false,
    errorMessage: ''
  })
  const [gradeValidation, setGradeValidation] = useState({
    error: false,
    errorMessage: ''
  })
  const [postReplyValidationError, setPostReplyValidation] = useState({
    error: false,
    errorMessage: ''
  })

  //FOR ATTACHEMENT UPLOAD
  const [attachmentFileDetails, setAttachmentFileDetails] = React.useState({});
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState('')

  //FOR FILE UPLOAD on responses
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'attachmentfile' && file.size > apiConfig.addingCourse.acceptedReadingFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'attachmentfile') {
            return apiConfig.addingCourse.acceptedReadingFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }
  const onAttachmentUpload = event => {
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    const attachment = validatedFile('attachmentfile')
    setAttachmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  //DISCUSSION DETAILS FETCH CALLBACKS
  const discussionSuccessCB = res => {
    setDiscussionLoader(false)
    setDiscussionData(res && res.data && Object.keys(res.data) && Object.keys(res.data).length ? res.data : {})
    setDiscussionReplyCount(res && res.data && res.data.reply_count)
  }
  const discussionFailureCB = error => {
    setDiscussionLoader(false)
    setDiscussionData({})
    setDiscussionReplyCount(0)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_DISCUSSION,
        type: 'error'
      }
    )
  }
  //FETCH SINGLE DISCUSSION DETAILS BASED ON ID
  const discussionDetailsFetch = () => {
    setDiscussionLoader(true)
    const payload = {
      id: props.match.params.discussionId
    }
    fetchExternalDiscussion(payload, discussionSuccessCB, discussionFailureCB)
  }

  //RESPONDED STUDENTS FETCH CALLBACKS
  const successStudentListCB = res => {
    setRespondedStudentList(res && res.results && res.results.length && res.results)
  }
  const failureStudentsListCB = error => {
    setRespondedStudentList([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_SUBMITTED_USERS_LIST,
        type: 'error'
      }
    )
  }
  //FETCH RESPONDED STUDENT LIST FOR DISCUSSION
  const fetchRespondedStudentList = () => {
    const payLoad = {
      forum_id: props.match.params.discussionId
    }
    externalRespondedStudents(payLoad, successStudentListCB, failureStudentsListCB)
  }

  //FETCH PINNED RESPONSES CALLBACKS
  const successPinnedResponse = res => {
    setPinnedResponseData(res && res.results && res.results)
  }
  const failurePinnedResponse = error => {
    setPinnedResponseData([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_PINNED_RESPONSE,
        type: 'error'
      }
    )
  }
  //FETCH PINNED RESPONSES
  const pinnedResponsesFetch = () => {
    fetchExternalPinnedResponses({
      forum_id: props.match.params.discussionId,
    }, successPinnedResponse, failurePinnedResponse)
  }

  //DISCUSSION RESPONSES CALLBACKS
  const responseSuccess = res => {
    setResponsesLoader(false)
    setResponseData(res && res.results && res.results)
  }
  const responseFailure = error => {
    setResponsesLoader(false)
    setResponseData([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_DISCUSSION_RESPONSE,
        type: 'error'
      }
    )
  }
  //FETCH DISCUSSION RESPONSES
  const discussionReportFetch = (pageNum) => {
    setResponsesLoader(true)
    fetchExternalResponses({
      forum_id: props.match.params.discussionId,
      number: rowsPerPage,
      offset: pageNum * rowsPerPage,
    }, responseSuccess, responseFailure)
  }

  useEffect(() => {
    discussionReportFetch(0)
    fetchRespondedStudentList()
    pinnedResponsesFetch()
    discussionDetailsFetch()
  }, [])

  //FOR SETTING UP THE STUDENT DEFAULT DISCUSSION GRADE POINTS
  useEffect(() => {
    if (studentResponses && Object.keys(studentResponses) && Object.keys(studentResponses).length) {
      const defaultPoints = studentResponses && studentResponses.user_score !== null ? studentResponses.user_score : null
      setStudentGrade(defaultPoints)
    }
  }, [studentResponses])

  function createMarkup(data) {
    return {
      __html: data
    };
  }
  const dateTimeFormat = (due_days) => {
    return (convertUTCDatewithTime(due_days))
  }
  //ON DESCRIPTION EDIT CHANGE
  const onChangeEditorContent = data => {
    setPostResponse(data)
  }
  //FOR CLOSING THE GRADE VIEW OF A STUDENT
  const closeGradeView = () => {
    setOpenGiveGradeView(false)
    setSelectedDropdownUser('')
    setStudentResponses({})
    setStudentGrade('')
    setGradeValidation({
      error: false,
      errorMessage: ''
    })
  }

  //ONSTUDENT NAME CLICK FUNCTION CALLBACKS
  const successFetchStudentResponse = res => {
    closeGradeView()
    setGradeViewLoader(false)
    setStudentResponses(res.results)
    setOpenGiveGradeView(true)
  }
  const failureFetchStudentResponse = error => {
    setGradeViewLoader(false)
    setOpenGiveGradeView(false)
    setStudentResponses({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_STUDENT_RESPONSES,
        type: 'error'
      }
    )
  }
  //FOR FETCHING RESPONDED USER COMMENTS AND RESPONSES OF A DISCUSSION
  const onStudentNameClick = (userId) => {
    setGradeValidation({
      error: false,
      errorMessage: ''
    })
    setGradeViewLoader(true)
    const payload = {
      forum_id: Number(props.match.params.discussionId),
      user_id: userId,
    }
    fetchExternalStudentResponses(payload, successFetchStudentResponse, failureFetchStudentResponse)
  }

  //FOR HANDLE CHANGE OF RESPONDED USERS DROPDOWN
  const handleRespondedUserChange = (event) => {
    setSelectedDropdownUser(event.target.value)
    if (event.target.value) {
      onStudentNameClick(event.target.value)
    }
  }

  //ON CLICK FOR POSTING A RESPONSE
  const onClickPostResponse = () => {
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    setOpenPostResponseField(true)
  }
  //CLOSING POST RESPONSE FIELDS
  const closePostResponseField = () => {
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    setOpenPostResponseField(false)
    setPostResponse('')
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
  }

  //CALLBACKS FOR POSTING RESPONSE TO A DISCUSSION
  const successPostResponse = res => {
    setDiscussionReplyCount(res && res.reply_count)
    setPostBtnLoader(false)
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: true,
      message: SUCCESS_POST_REPLY,
      type: 'success'
    })
    closePostResponseField()
    // discussionDetailsFetch()
    fetchRespondedStudentList()
    discussionReportFetch(0)
    setPostResponse('')
    setHasMoreData(true)
    setPageNum(0)
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
  }
  const failurePostResponse = error => {
    setPostBtnLoader(false)
    setValidationDiscussionError({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar(
      {
        state: false,
        message: ERROR_POST_REPLY,
        type: 'error'
      }
    )
  }

  //ONCLICK FOR SUBMITTING RESPONSE TO A DISCUSSION
  const onTriggerSubmmitResponse = (event) => {
    if (postResponse === '') {
      setValidationDiscussionError({
        error: true,
        errorMessage: 'Please add response and then post!'
      })
    } else if (attachmentFileDetails.fileError) {
      setValidationDiscussionError({
        error: true,
        errorMessage: 'Please add supported file only!'
      })
    }
    else {
      setValidationDiscussionError({
        error: false,
        errorMessage: ''
      })
      setPostBtnLoader(true)
      const payload = {
        forum_id: Number(props.match.params.discussionId),
        reply_text: postResponse ? postResponse : ''
      }
      // let formData = new FormData()
      // formData.append('forum_id', Number(props.match.params.discussionId))
      // formData.append('reply_text', postResponse ? postResponse : '')
      // formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
      externalPostReply(payload, successPostResponse, failurePostResponse)
    }
  }

  //FOR GIVING GRADE FUNCTIONS START HERE
  const handleChangeGrade = (event) => {
    setStudentGrade(event.target.value)
  }

  //FOR SUBMITTING GRADE TO A STUDENT CALLBACKS
  const submitGradeSuccessCB = res => {
    setGradeBtnLoader(false)
    setGradeValidation({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: true,
      message: SUCCESS_SUBMIT_DISCUSSION_GRADE,
      type: 'success'
    })
    fetchRespondedStudentList()
    closeGradeView()
  }
  const submitGradeFailureCB = error => {
    setGradeBtnLoader(false)
    setGradeValidation({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: false,
      message: ERROR_SUBMIT_DISCUSSION_GRADE,
      type: 'error'
    })
  }

  //FOR UPDATING GRADE TO A STUDENT CALLBACKS
  const updateGradeSuccsessCB = res => {
    setGradeBtnLoader(false)
    setGradeValidation({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_UPDATE_DISCUSSION_GRADE,
        type: 'success'
      }
    )
    fetchRespondedStudentList()
    closeGradeView()
  }
  const updateGradeFailureCB = error => {
    setGradeBtnLoader(false)
    setGradeValidation({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: false,
      message: ERROR_UPDATE_DISCUSSION_GRADE,
      type: 'error'
    })
  }

  //SUBMITTING GRADE TO A STUDENT
  const submitStudenGrade = event => {
    event.preventDefault()
    if (studentGrade === null || studentGrade === '') {
      setGradeValidation({
        error: true,
        errorMessage: 'Please add grade points!'
      })
    }
    else if (studentGrade > (discussionData && discussionData.points)) {
      setGradeValidation({
        error: true,
        errorMessage: `grade should be less than ${discussionData.points} pts`
      })
    }
    else if (isNaN(studentGrade) || studentGrade < 0 || studentGrade > 100) {
      setGradeValidation({
        error: true,
        errorMessage: 'Please give valid points'
      })
    }
    else {
      setGradeBtnLoader(true)
      if (studentResponses.user_score !== null) {
        const payload = {
          forum_id: Number(props.match.params.discussionId),
          grade_id: studentResponses.grade_id,
          score: Number(studentGrade),
        }
        externalUpdateGrade(payload, updateGradeSuccsessCB, updateGradeFailureCB)
      }
      else {
        const payload = {
          forum_id: Number(props.match.params.discussionId),
          user_id: studentResponses.user_id,
          score: Number(studentGrade),
        }
        externalSumbitGrade(payload, submitGradeSuccessCB, submitGradeFailureCB)
      }
    }
  }

  //FOR RESPONSE PIN CALLBACKS
  const pinSuccessCallBack = res => {
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_PIN_DISCUSSION,
        type: 'success'
      }
    )
    setPostBtnLoader(false)
    pinnedResponsesFetch()
    discussionReportFetch(0)
  }
  const pinFailureCallBack = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_PIN_DISCUSSION,
        type: 'error'
      }
    )
    setPostBtnLoader(false)
  }

  //FOR RESPONSE UNPIN CALLBACKS
  const unPinSuccessCallBack = res => {
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_UNPIN_DISCUSSION,
        type: 'success'
      }
    )
    setPostBtnLoader(false)
    pinnedResponsesFetch()
    discussionReportFetch(0)
  }
  const unPinFailureCallBack = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UNPIN_DISCUSSION,
        type: 'error'
      }
    )
    setPostBtnLoader(false)
  }

  //FOR PIN/UNPIN RESPONSES OF A DISCUSSION
  const triggerPinResponse = (data, type) => {
    if (type === 'pin') {
      const payload = {
        reply_id: data.id ? data.id : null,
        pinned: true
      }
      setPostBtnLoader(true)
      pinExternalDiscussion(payload, pinSuccessCallBack, pinFailureCallBack)
    }
    else if (type === 'unPin') {
      const payload = {
        reply_id: data.id ? data.id : null,
        pinned: false
      }
      setPostBtnLoader(true)
      pinExternalDiscussion(payload, unPinSuccessCallBack, unPinFailureCallBack)
    }
  }

  //FOR FETCHING MORE RESPONSES OF A DISCUSSION PAGINATION CALLBACKS
  const responseScrollSuccess = res => {
    if (res && res.results && res.results.length) {
      let newData = [...responseData]
      res.results.forEach((data, index) => {
        newData.push(data)
      })
      setResponseData(newData)
    }
  }
  const responseScrollFailure = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_DISCUSSION_RESPONSE,
        type: 'error'
      }
    )
  }

  //FOR FETCHING MORE RESPONSES OF A DISCUSSION ON SCROLL DOWN
  const fetchMoreData = () => {
    if (responseData.length >= (discussionReplyCount)) {
      setHasMoreData(false)
      return
    }
    setPageNum(pageNum + 10)
    fetchExternalResponses({
      forum_id: props.match.params.discussionId,
      number: rowsPerPage,
      offset: pageNum + rowsPerPage,
    }, responseScrollSuccess, responseScrollFailure)
  };

  //FOR ADDING REPLY TO A RESPONSE
  const onReplyTrigger = (dataId) => {
    setPageNum(0)
    setpostReplyData('')
    setPostReplyValidation({
      error: false,
      errorMessage: ''
    })
    setResponseId(dataId)
    closePostResponseField()
  }

  //HANDLE CHANGE REPLY
  const handleChangeReply = (event) => {
    setPostReplyValidation({
      error: false,
      errorMessage: ''
    })
    setpostReplyData(event.target.value)
  }

  //FOR ADDING COMMENTS TO A RESPONSE CALLBACKS
  const successPostComment = res => {
    setPostReplyValidation({
      error: false,
      errorMessage: ''
    })
    setPostBtnLoader(false)
    props.showSnackBar({
      state: true,
      message: SUCCESS_POST_COMMENT,
      type: 'success'
    })
    setResponseId(null)
    setViewCommentsIndex(null)
    discussionReportFetch(0)
    setHasMoreData(true)
    setPageNum(0)
  }
  const failurePostComment = error => {
    setPostBtnLoader(false)
    setPostReplyValidation({
      error: false,
      errorMessage: ''
    })
    props.showSnackBar({
      state: false,
      message: ERROR_POST_COMMENT,
      type: 'error'
    })
  }

  //ON CLICK FOR SUBMMITIN A COMMENT FOR RESPONSE
  const triggerPostComment = (data) => {
    if (postReplyData === '') {
      setPostReplyValidation({
        error: true,
        errorMessage: 'Please Add Reply And Then Post!'
      })
    }
    else {
      setPostReplyValidation({
        error: false,
        errorMessage: ''
      })
      setPostBtnLoader(true)
      const payload = {
        reply_id: Number(data.id),
        comment_text: postReplyData ? postReplyData : ''
      }
      externalPostComment(payload, successPostComment, failurePostComment)
    }
  }

  //ONCLICK VIEW MORE COMMENTS OF A RESPONSE CALLBACKS
  const successSubReplies = res => {
    setSubRepliesLoader(false)
    setSubReplies(res && res.results && res.results)
  }
  const failureSubReplies = error => {
    setSubRepliesLoader(false)
    setSubReplies([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_COMMENTS,
        type: 'error'
      }
    )
  }

  //ONCLICK EVENT FOR FETCHING MORE COMMENTS OF A SINGLE RESPONSE
  const onViewCommentsClick = (dataId, pageNumForSubReplies) => {
    setPageNumForSubReplies(0)
    setSubRepliesLoader(true)
    setViewCommentsIndex(dataId)
    const payload = {
      number: rowsPerPage,
      offset: pageNumForSubReplies * rowsPerPage,
      reply_id: dataId
    }
    fetchExternalSubReplies(payload, successSubReplies, failureSubReplies)
  }

  //FOR FETCHING MORE COMMENTS OF RESPONSE ON SCROLL CALLBACKS
  const subRepliesScrollSuccess = res => {
    setLoadMoreBtnPrgs(false)
    if (res && res.results.length) {
      let newData = [...subReplies]
      res.results.forEach((data, index) => {
        newData.push(data)
      })
      setSubReplies(newData)
    }
  }
  const subRepliesScrollFailure = error => {
    setLoadMoreBtnPrgs(false)
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_COMMENTS,
      type: 'error'
    })
  }

  //FOR FETCHING MORE COMMENTS OF RESPONSE ON SCROLL
  const fetchMoreSubReplies = (dataId) => {
    setLoadMoreBtnPrgs(true)
    setPageNumForSubReplies(pageNumForSubReplies + 10)
    fetchExternalSubReplies({
      reply_id: dataId ? dataId : null,
      number: rowsPerPage,
      offset: pageNumForSubReplies + rowsPerPage
    }, subRepliesScrollSuccess, subRepliesScrollFailure)
  }

  //FOR RENDERING COMMENTS OF A RESPONSE
  const renderSubReplies = (data) => {
    let btnValid = false
    if (loadMoreBtnPrgs) {
      btnValid = true
    }
    else if (subReplies.length >= data.comment_count) {
      btnValid = true
    }
    return (
      <List>
        {subReplies && subReplies.length ? (
          <React.Fragment>
            {subReplies.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.avatarReplies}>
                      {item.first_name && item.first_name.substring(0, 1)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText className={classes.titleReplies} primary={`${item.first_name && item.first_name} ${item.last_name && item.last_name} (${item.role && item.role})`} secondary={dateTimeFormat(item.submission_date && item.submission_date)} />
                </ListItem>
                <div className={mobileView ? classes.subReplyCmtMob : classes.subReplyCmt} dangerouslySetInnerHTML={createMarkup(item.comment_text && item.comment_text)}></div>
                <Divider variant="inset" component="li" aria-hidden="true" />
              </React.Fragment>
            ))}
            {data.comment_count >= 10 ? (
              <React.Fragment>
                {loadMoreBtnPrgs ? (
                  <CircularProgress size={20} style={{ marginBottom: '-9px' }} />
                ) : null}
                <Button disabled={btnValid} style={{ marginTop: '10px', color: btnValid ? '#f5f5f5' : colorStyles.btnColor, textTransform: 'none', flexGrow: 1, }} variant="outlined" size="small" color="primary" onClick={() => { fetchMoreSubReplies(data.id) }}>
                  Load More Comments
                </Button>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <Typography>No Comments Found</Typography>
        )}
      </List>
    )
  }

  let showPinBtn = false
  if (pinnedResponseData && pinnedResponseData.length === 3) {
    showPinBtn = true
  }
  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Discussion report view</h1>
      </div>
      <Paper elevation={0} className={classes.topButtonNav}>
        <div className={classes.breadCrumbcontainer}>
          <Header histoy={props.history} match={props.match} from="externalDiscussions" discussionData={discussionData} />
        </div>
      </Paper>
      <Grid container className={classes.containerWrapper}>
        <Hidden only={['xs', 'sm', 'md']}>
          <Grid item lg={1}>

          </Grid>
        </Hidden>

        <Grid item xs={12} sm={12} md={12} lg={10}>
          {!openGiveGradeView ? (
            <React.Fragment>
              {discussionLoader ? (
                <DiscussionLoader />
              ) : (
                <React.Fragment>
                  <Paper evlevation={2} className={classes.paperHeader}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4} md={6} lg={6}>
                            <div className={classes.discussionTitle}>{discussionData && discussionData.title ? discussionData.title : ''}</div>
                            <div className={classes.discussionPoints}>{discussionData && discussionData.points ? `Points: ${discussionData.points}` : ''}</div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="Responded users list">Responded users</InputLabel>
                              <Select
                                labelId="discussion responded users"
                                id="responded users selection"
                                value={selectedDropdownUser}
                                onChange={handleRespondedUserChange}
                                label="Responded users"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {respondedStudentList && respondedStudentList.map((item, i) => {
                                  return <MenuItem key={i} value={item.user_id}>{`${item.first_name} ${item.last_name}`}</MenuItem>
                                })}
                              </Select>
                            </FormControl>
                            {gradeViewLoader ? (
                              <CircularProgress size={30} style={{ color: colorStyles.btnColor, marginTop: '20px' }} />
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                            <div className={classes.infoItemsCtr}>
                              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                                <p>{`discussion responses count ${discussionReplyCount}`}</p>
                              </div>
                              <div className={classes.infoItems}>
                                <Tooltip title={`${discussionReplyCount} responses`} aria-hidden="true">
                                  <StyledBadge badgeContent={discussionReplyCount} color='secondary'>
                                    <img src={responseIcon} alt="response count" style={{ height: '24px', marginTop: '11px' }} />
                                  </StyledBadge>
                                </Tooltip>
                              </div>
                              {discussionData && discussionData.attachment ? (
                                <React.Fragment>
                                  <div className={classes.infoItems}>
                                    <Tooltip title="Attachment">
                                      <a href={discussionData && discussionData.attachment && discussionData.attachment} target='_blank' edge="start" color="primary" className={classes.readingsButton} aria-label="download attachment">
                                        {discussionData && discussionData.attachment && discussionData.attachment.split('?')[0] && discussionData.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon className={classes.infoIcon} /> : discussionData && discussionData.attachment && discussionData.attachment.split('?')[0] && discussionData.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon className={classes.infoIcon} /> : <DescriptionIcon className={classes.infoIcon} />}
                                      </a>
                                    </Tooltip>
                                  </div>
                                </React.Fragment>
                              ) : null}
                              <div className={classes.infoItems}>
                                <IconButton disabled={postBtnLoader} edge="end" style={{ marginTop: '5px', borderRadius: '5px', border: '1px solid #999', padding: '5px', marginLeft: '5px' }} aria-label="post response to discussion" onClick={() => { onClickPostResponse() }}>
                                  <AddCommentIcon className={classes.commentIcon} /> <div style={{ fontSize: '10px' }}>Post a Response</div>
                                </IconButton>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.descWrapper} dangerouslySetInnerHTML={createMarkup(discussionData && discussionData.description)} />
                      </Grid>
                    </Grid>
                  </Paper>
                </React.Fragment>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {openPostResponseFiled ? (
                  <div className={classes.resEditForm}>
                    <form autoComplete="off" onSubmit={evnt => { evnt.preventDefault() }} >
                      <Grid container >
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                          {validationDiscussionError.error ? (
                            <div style={{ marginBottom: '20px' }}>
                              <AlertMessages status="error" message={validationDiscussionError.errorMessage} />
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <TextEditor onChangeEditorContent={onChangeEditorContent} helperText={'Add response here and then post!'} />
                      {/* <div>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                          {attachmentFileDetails.fileError ? (
                            <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                              {attachmentFileDetails.fileErrorErrorMessage}
                            </Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div style={{ display: 'flex', marginTop: '11px' }}>
                            <span className={classes.uploadLabel} aria-hidden="true">
                              Upload Attachment
                            </span>
                            <IconButton style={{ padding: '2px 8px' }} aria-label="upload attachment" onClick={() => { onFileToUploadClick('attachmentfile') }}>
                              <CloudUploadIcon className={classes.uploadIcon} />
                            </IconButton>
                            {attachmentFileDetails.file && attachmentFileDetails.file.name && uploadedAttachmentPreview ? (
                              <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                                {attachmentFileDetails.file.name.length > 25 ? (
                                  attachmentFileDetails.file.name.substr(0, 20) + '....'
                                ) :
                                  attachmentFileDetails.file.name
                                }
                              </a>
                            ) : null}
                          </div>
                          <div style={{ display: 'flex', marginTop: '5px' }}>
                            <span style={{ color: '#999' }}>Optional, max.size: 10MB, format: pdf, docx, pptx</span>
                          </div>
                        </Grid>
                        <input type="file" accept=".pptx,.pdf,.docx" style={{ display: 'none' }} id="attachmentfile" onChange={onAttachmentUpload} />
                      </div> */}
                      {postBtnLoader ? (
                        <CircularProgress size={20} style={{ marginBottom: '-6px' }} />
                      ) : null}
                      <IconButton disabled={postBtnLoader} edge="end" aria-label="Post response" className={classes.postBtnWrap} onClick={(event) => { onTriggerSubmmitResponse(event) }}>
                        <SmsIcon className={classes.replyIcon} /> <div style={{ fontSize: '10px' }}>Post response</div>
                      </IconButton>
                      <IconButton disabled={postBtnLoader} edge="end" aria-label="Cancel" className={classes.postBtnWrap} onClick={(event) => { closePostResponseField(event) }}>
                        <CancelIcon className={classes.replyIcon} /> <div style={{ fontSize: '10px' }}>Cancel</div>
                      </IconButton>
                    </form>
                    <Divider style={{ marginLeft: '-38px' }} aria-hidden="true" />
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '1px' }}>
                {!discussionLoader && pinnedResponseData && pinnedResponseData.length ? (
                  <Paper evlevation={2} className={classes.pinResCtr}>
                    <div className={classes.commentsWrapper}>
                      <Typography className={classes.title}>Pinned Responses</Typography>
                    </div>
                    <List>
                      {pinnedResponseData && pinnedResponseData.map((data, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <Grid container>
                              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <div style={{ display: 'flex' }}>
                                  <ListItemAvatar>
                                    <Avatar className={classes.avatarReplies}>
                                      {data.first_name && data.first_name.substring(0, 1)}
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText className={classes.titleReplies} primary={`${data.first_name && data.first_name} ${data.last_name && data.last_name} (${data.role && data.role})`} secondary={dateTimeFormat(data.submission_date && data.submission_date)} />
                                </div>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'end' }}>
                                {data && data.attachment_details && data.attachment_details.file_name ? (
                                  <React.Fragment>
                                    {data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px' }} /> : data.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px' }} />}
                                    <a href={data.attachment_details && data.attachment_details.file_url && data.attachment_details.file_url} target='_blank' className={classes.uploadedVideo}>
                                      {data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name.length > 15 ? (
                                        data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name && data.attachment_details.file_name.substr(0, 15) + '...'
                                      ) :
                                        data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name && data.attachment_details.file_name
                                      }
                                    </a>
                                  </React.Fragment>
                                ) : null}
                              </Grid>
                              <ListItemSecondaryAction>
                                <Tooltip title="Unpin response">
                                  <IconButton disabled={postBtnLoader} onClick={() => { triggerPinResponse(data, 'unPin') }} >
                                    <img src={unPinIcon} alt="Unpin discussion" style={{ height: '21px' }} aria-hidden="true" />
                                  </IconButton>
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </Grid>
                          </ListItem>
                          <div style={{ marginLeft: '73px', textAlign: 'initial', lineBreak: 'anywhere', marginTop: '-18px' }} dangerouslySetInnerHTML={createMarkup(data.reply_text && data.reply_text)}></div>
                          <Divider variant="inset" component="li" aria-hidden="true" />
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                ) : null}
                {responsesLoader ? (
                  <ResponsesLoader />
                ) : (
                  <React.Fragment>
                    <div className={classes.commentsWrapper}>
                      <Typography className={classes.title}>Responses</Typography>
                    </div>
                    {responseData && responseData.length ? (
                      <List>
                        <InfiniteScroll
                          dataLength={responseData.length}
                          next={() => { fetchMoreData() }}
                          hasMore={hasMoreData}
                          loader={<h4>Loading...</h4>}
                          scrollableTarget="scrollableDiv"
                          height={500}
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          {responseData && responseData.map((data, index) => (
                            <React.Fragment key={index}>
                              <ListItem>
                                <Grid container>
                                  <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                    <div style={{ display: 'flex' }}>
                                      <ListItemAvatar>
                                        <Avatar className={classes.avatarComments} aria-label="user avatar">
                                          {data.first_name && data.first_name.substring(0, 1)}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText className={classes.title} primary={`${data.first_name && data.first_name} ${data.last_name && data.last_name} (${data.role && data.role})`} secondary={dateTimeFormat(data.submission_date && data.submission_date)} />
                                    </div>
                                  </Grid>
                                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'end' }}>
                                    {data && data.attachment_details && data.attachment_details.file_name ? (
                                      <React.Fragment>
                                        {data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px' }} /> : data.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '10px', marginRight: '-10px' }} />}
                                        <a href={data.attachment_details && data.attachment_details.file_url && data.attachment_details.file_url} target='_blank' className={classes.uploadedVideo}>
                                          {data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name.length > 15 ? (
                                            data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name && data.attachment_details.file_name.substr(0, 15) + '...'
                                          ) :
                                            data.attachment_details && data.attachment_details.file_name && data.attachment_details.file_name && data.attachment_details.file_name
                                          }
                                        </a>
                                      </React.Fragment>
                                    ) : null}
                                  </Grid>
                                  <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                    <ListItemSecondaryAction>
                                      {pinnedResponseData && pinnedResponseData.length !== 3 ? (
                                        <Tooltip title="Pin response">
                                          <IconButton disabled={showPinBtn || postBtnLoader} onClick={() => { triggerPinResponse(data, 'pin') }}>
                                            <img src={pinIcon} alt="Pin discussion" style={{ height: '21px' }} aria-hidden="true" />
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                      {discussionData.threaded_reply ? (
                                        <IconButton disabled={postBtnLoader} edge="end" style={{ borderRadius: '5px', border: '1px solid #999', padding: '5px', marginRight: '2px' }} aria-label="reply" onClick={() => { onReplyTrigger(data.id) }}>
                                          <ReplyIcon className={classes.replyIcon} /> <div style={{ fontSize: '10px' }}>Reply</div>
                                        </IconButton>
                                      ) : null}
                                    </ListItemSecondaryAction>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <div className={mobileView ? classes.replyMsgMob : classes.replyMsg} dangerouslySetInnerHTML={createMarkup(data.reply_text && data.reply_text)}></div>
                              {discussionData.threaded_reply && data.has_comments && data.comment_count > 0 && data.id !== viewCommentsIndex ? (
                                <Link component="button" onClick={() => { onViewCommentsClick(data.id, 0) }} className={mobileView ? classes.vwMoreMob : classes.vwMore}>
                                  {`View ${data.comment_count} more comments`}
                                </Link>
                              ) : (
                                <React.Fragment>
                                  {discussionData.threaded_reply && data.has_comments && data.comment_count > 0 && data.id === viewCommentsIndex ? (
                                    <Link component="button" onClick={() => { setViewCommentsIndex(null) }} className={mobileView ? classes.vwMoreMob : classes.vwMore}>
                                      hide comments
                                    </Link>
                                  ) : null}
                                </React.Fragment>
                              )}
                              <Grid item xs={12} sm={12} md={8} lg={8} className={mobileView ? classes.replyCtrMob : classes.replyCtr}>
                                {data.id === responseId ? (
                                  <form autoComplete="off" onSubmit={evnt => { evnt.preventDefault() }} >
                                    <Grid container >
                                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        {postReplyValidationError.error ? (
                                          <div style={{ marginBottom: '20px' }}>
                                            <AlertMessages status="error" message={postReplyValidationError.errorMessage} />
                                          </div>
                                        ) : null}
                                      </Grid>
                                    </Grid>
                                    <TextField
                                      id="title"
                                      autoFocus={true}
                                      label="Add Reply Here!"
                                      helperText="max: 1000 chars"
                                      required
                                      size="small"
                                      variant="outlined"
                                      inputProps={{
                                        maxLength: 1000,
                                      }}
                                      className={mobileView ? classes.replyInpMob : classes.replyInp}
                                      onChange={handleChangeReply}
                                    />
                                    {postBtnLoader ? (
                                      <CircularProgress size={24} style={{ marginBottom: '4px', marginLeft: '10px' }} />
                                    ) : null}
                                    <IconButton disabled={postBtnLoader} edge="end" style={{ marginBottom: '30px', marginLeft: '20px', borderRadius: '5px', border: '1px solid #999', padding: '5px' }} aria-label="Post reply" onClick={() => { triggerPostComment(data) }}>
                                      <SmsIcon className={classes.replyIcon} /> <div style={{ fontSize: '10px' }}>Post reply</div>
                                    </IconButton>
                                    <IconButton disabled={postBtnLoader} edge="end" style={{ marginBottom: '30px', marginLeft: '20px', borderRadius: '5px', border: '1px solid #999', padding: '5px' }} aria-label="cancel" onClick={() => { setResponseId(null) }}>
                                      <CancelIcon className={classes.replyIcon} /> <div style={{ fontSize: '10px' }}>Cancel</div>
                                    </IconButton>
                                  </form>
                                ) : null}
                              </Grid>
                              <div style={{ marginLeft: '40px', marginRight: '10px' }}>
                                {data.id === viewCommentsIndex ? (
                                  <React.Fragment>
                                    {subRepliesLoader ? (
                                      <SubRepliesLoader />
                                    ) : (
                                      renderSubReplies(data)
                                    )}
                                  </React.Fragment>
                                ) : null}
                              </div>
                              <Divider variant="inset" component="li" aria-hidden="hidden" />
                            </React.Fragment>
                          ))}
                        </InfiniteScroll>
                      </List>
                    ) : (
                      <Typography>Responses not yet added!</Typography>
                    )}
                  </React.Fragment>
                )}
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Paper className={classes.gradeWrapper}>
                  {gradeViewLoader ? (
                    <GradeViewLoader />
                  ) : (
                    <React.Fragment>
                      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                        <p>{`discussion responses count ${studentResponses.reply_count}`}</p>
                      </div>
                      <Tooltip title={`${studentResponses.reply_count && studentResponses.reply_count} responses`} aria-hidden="true">
                        <IconButton style={{ float: 'left' }}>
                          <StyledBadge badgeContent={studentResponses.reply_count && studentResponses.reply_count} color='secondary'>
                            <img src={responseIcon} alt="response count" style={{ height: '24px', marginTop: '11px' }} aria-hidden="true" />
                          </StyledBadge>
                        </IconButton>
                      </Tooltip>
                      {discussionData && discussionData.attachment ? (
                        <Tooltip title="Attachment">
                          <a href={discussionData && discussionData.attachment && discussionData.attachment} target='_blank' edge="start" style={{ color: '#2e8eec', float: 'left', marginTop: '21px', marginLeft: '12px' }} aria-label="close">
                            {discussionData && discussionData.attachment && discussionData.attachment.split('?')[0] && discussionData.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec' }} /> : discussionData && discussionData.attachment && discussionData.attachment.split('?')[0] && discussionData.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec' }} /> : <DescriptionIcon style={{ color: '#2e8eec' }} />}
                          </a>
                        </Tooltip>
                      ) : null}
                      <IconButton edge="end" aria-label="Close Grade View" className={classes.closeGradeWrapper} onClick={() => { closeGradeView() }}>
                        <CancelIcon className={classes.replyIcon} /> <div style={{ fontSize: '10px' }}>Close</div>
                      </IconButton>
                      <Typography className={classes.gradeTitle}>
                        {discussionData && discussionData.title && discussionData.title.length > 95 ? (
                          discussionData.title.substr(0, 90) + '...'
                        ) : (
                          discussionData.title
                        )}
                      </Typography>
                      <div className={classes.gradeitemInfo}>
                        {discussionData && discussionData.graded_reply && discussionData.points ? (`Points: ${discussionData.points}`) : null} &nbsp;&nbsp;
                      </div>
                      <Grid container>
                        <Grid item xs={12} md={12} lg={6} sm={6}>
                          <Typography variant="caption" display="block" className={classes.infoBlock}>
                            {`${studentResponses.first_name ? studentResponses.first_name : ''} ${studentResponses.last_name ? studentResponses.last_name : ''}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} sm={6} >
                          {discussionData && discussionData.graded_reply ? (
                            <React.Fragment>
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                {gradeValidation.error ? (
                                  <div style={{ marginBottom: '20px' }}>
                                    <AlertMessages status="error" message={gradeValidation.errorMessage} />
                                  </div>
                                ) : null}
                              </Grid>
                              <form autoComplete="off" onSubmit={evnt => { evnt.preventDefault() }} >
                                <TextField
                                  id="studentGrade"
                                  label="Points"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  required
                                  autoFocus
                                  defaultValue={defaultPoints}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    maxLength: 3,
                                  }}
                                  onChange={handleChangeGrade}
                                />
                                {gradeBtnLoader ? (
                                  <CircularProgress size={20} style={{ marginBottom: '-9px', marginLeft: '5px' }} />
                                ) : null}
                                <Button disabled={gradeBtnLoader} style={{ marginLeft: '10px', marginTop: '5px' }} variant="outlined" size="small" color="primary" onClick={(evnt) => { submitStudenGrade(evnt) }}>
                                  {studentResponses && studentResponses.user_score !== null ? 'Update' : 'Submit'}
                                </Button>
                              </form>
                            </React.Fragment>
                          ) : null}
                        </Grid>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="caption" className={classes.infoBlock}>
                              <b>Responses</b>:
                            </Typography>
                            <List>
                              {studentResponses && studentResponses.user_data && studentResponses.user_data.map((userData, index) => (
                                <div key={index}>
                                  <ListItem >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div style={{ textAlign: 'initial', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={createMarkup(userData && userData.reply_data)}></div>
                                      <div className={classes.itemInfo}>
                                        Submitted Date: {userData.submission_date ? dateTimeFormat(userData.submission_date && userData.submission_date) : ''}
                                      </div>
                                      {userData && userData.attachment_details && userData.attachment_details.file_name ? (
                                        <React.Fragment>
                                          <span>
                                            {userData.attachment_details && userData.attachment_details.file_name && userData.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-5px', fontSize: '19px', marginRight: '-10px' }} /> : userData.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-5px', fontSize: '19px', marginRight: '-10px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-5px', fontSize: '19px', marginRight: '-10px' }} />}
                                            <a href={userData.attachment_details && userData.attachment_details.file_url && userData.attachment_details.file_url} target='_blank' style={{ marginLeft: '17px' }}>
                                              {userData.attachment_details && userData.attachment_details.file_name && userData.attachment_details.file_name && userData.attachment_details.file_name}
                                            </a>
                                          </span>
                                        </React.Fragment>
                                      ) : null}
                                    </div>
                                  </ListItem>
                                  <Divider aria-hidden="true" />
                                </div>
                              ))}
                            </List>
                          </div>
                          {studentResponses && studentResponses.user_comment_data && studentResponses.user_comment_data.length ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography variant="caption" className={classes.infoBlock}>
                                <b>Comments</b>:
                              </Typography>
                              <List>
                                {studentResponses && studentResponses.user_comment_data && studentResponses.user_comment_data.map((data, index) => (
                                  <div key={index}>
                                    <ListItem>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className={classes.repliesWrapper}>{` Replied to : ${data.reply_to_first_name ? data.reply_to_first_name : null} ${data.reply_to_last_name ? data.reply_to_last_name : null}`}</div>
                                        <div style={{ marginLeft: '30px' }}>
                                          <div style={{ lineBreak: 'anywhere' }}>{data.comment ? data.comment : null}</div>
                                          <div className={classes.itemInfo}>
                                            Submitted Date: {data.comment_sub_date ? dateTimeFormat(data.comment_sub_date && data.comment_sub_date) : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </ListItem>
                                    <Divider aria-hidden="true" />
                                  </div>
                                ))}
                              </List>
                            </div>
                          ) : null}
                        </div>
                      </Grid>
                    </React.Fragment>
                  )}
                </Paper>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Hidden only={['xs', 'sm', 'md']}>
          <Grid item lg={1}>

          </Grid>
        </Hidden>
      </Grid >
    </div >
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(DiscussionReport);
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Autocomplete from "@material-ui/lab/Autocomplete"
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import IconButton from '@material-ui/core/IconButton'
import { getThumbnails } from 'video-metadata-thumbnails'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import Tooltip from '@material-ui/core/Tooltip'
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import AlertMessages from '../Common/AlertMessages'
import TextEditor from '../Common/TextEditor'
import { find } from 'lodash';
import { style } from '../../Styles/theme'
import { apiConfig } from '../../Configs/apiConfig'
import { bytesToSize } from '../../Helpers/basic';
import { courseLevel } from '../../Configs/courseLevels'
import { courseCategory, fetchCourseTagsList } from '../../store/AddingCourse/actionCreator'
import { uploadVideo, updateVideo } from '../../store/MyVideos/actionCreator'
import { ERROR_SUBMIT_VIDEO, ERROR_UPDATE_VIDEO } from '../Common/Constants/errorMessages'
import { SUCCESS_SUBMIT_VIDEO, SUCCESS_UPDATE_VIDEO } from '../Common/Constants/successMessages'
import EditImageIcon from '../../static/editImageIcon.png'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '157px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '70px',
      paddingRight: '70px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-84px'
  },
  label: {
    marginTop: 15,
    fontSize: '1.5rem'
  },
  field: {
    marginTop: 30,
    backgroundColor: '#f5f5f5'
  },
  dateWrapper: {
    marginLeft: '13px',
  },
  datePicker: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
  endDateWrapper: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 231,
  },
  titleWrapper: {
    fontSize: '26px',
    fontWeight: '500',
    color: style.fontColour._blue,
    marginBottom: '22px'
  },
  buttonProgress: {
    marginBottom: '-30px',
    marginRight: '10px',
    color: '#2e8eec'
  },
  button: {
    textTransform: 'none',
    marginLeft: '10px',
    flexGrow: 1,
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
    marginTop: '36px'
  },
  uploadIcon: {
    color: '#2e8eec',
    width: 40,
    height: 29,
    marginTop: '-4px',
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  errorMsgWrapper: {
    color: 'red',
    marginBottom: '-10px'
  },
  errorMsg: {
    color: 'red',
    marginBottom: '11px',
    textAlign: 'left',
    marginLeft: '12px'
  },
  uploadedVideo: {
    width: 62,
    marginLeft: 20,
    color: '#2e8eec',
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  iconButtonMargin: {
    float: 'right',
    marginBottom: '10px',
    marginLeft: '10px'
  },
  thumbnailWrapper: {
    height: '110px',
    width: '200px',
    borderRadius: '5px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: '#90caf9'
  },
  optWrapper: {
    height: '110px',
    width: '200px',
    borderRadius: '5px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: '#6BC787',
    padding: '4px'
  },
  thumbnailImg: {
    width: '167px',
    height: '104px',
    marginLeft: '-1px',
  },
  uploadWrapper: {
    marginRight: '4px',
    marginBottom: '10px',
    marginLeft: '12px',
    display: 'flex',
    height: '110px',
    width: '172px',
    borderRadius: '5px',
    borderStyle: 'solid',
    float: 'left',
    borderColor: '#90caf9',
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  helperText: {
    color: '#999'
  }
}))

function AddVideo(props) {
  const classes = useStyles();
  const defaultVideoTitle = props.videoDetails && props.videoDetails.title ? props.videoDetails.title : ''
  const [videoTitle, setVideoTitle] = React.useState(defaultVideoTitle)
  
  const defaultVideoDesc = props.videoDetails && props.videoDetails.description ? props.videoDetails.description : ''
  const [videoDesc, setVideoDesc] = React.useState(defaultVideoDesc)
  
  const defaultVideoLevel = props.videoDetails && props.videoDetails.level ? props.videoDetails.level : ''
  const [selectedVideoLevel, setSelectedVideoLevel] = React.useState(defaultVideoLevel)
  
  const defaultCategory = props.videoDetails && props.videoDetails.category ? props.videoDetails.category : ''
  const [selectedCategory, setSelectedCategory] = React.useState(defaultCategory)
  const [selectedCourseTags, setSelectedCourseTags] = React.useState([]);
  const [courseCategoryList, setCourseCategoryList] = React.useState([]);
  const [fetchCourseTags, setFetchCourseTags] = React.useState([])
  const [inProgress, setInprogress] = React.useState(false)

  const [uploadedVideoFile, setUploadedVideoFile] = React.useState({})
  const defaultVideoPrv = props.videoDetails && props.videoDetails.video_url ? props.videoDetails.video_url : null
  const [uploadedVideoPreview, setUplodedVideoPreview] = React.useState(defaultVideoPrv)

  const [uploadedTranscript, setUploadedTranscript] = React.useState({})
  const defaultTranscript = props.videoDetails && props.videoDetails.transcript ? props.videoDetails.transcript : null
  const [transcriptPreview, setTranscriptPreview] = React.useState(defaultTranscript)
  //THUMBNAILS SET STATES
  const [thumbnailsData, setThumbnailsData] = React.useState([])
  const [thumbnailLoader, setThumbnailLoader] = React.useState(false)
  const [uploadedThumbnail, setUploadedThumbnail] = React.useState({})
  
  const defaultThumbnail = props.videoDetails && props.videoDetails.thumbnail_url ? props.videoDetails.thumbnail_url : null
  const [thumbnailPreview, setThumbnailPreview] = React.useState(defaultThumbnail)

  const values = {
    videoTitle: props.videoDetails && props.videoDetails.title ? props.videoDetails.title : null,
    videoDesc: props.videoDetails && props.videoDetails.description ? props.videoDetails.description : null,
    bannerImage: props.videoDetails && props.videoDetails.thumbnail_url ? props.videoDetails.thumbnail_url : null,
    video_url: props.videoDetails && props.videoDetails.video_url ? props.videoDetails.video_url : null,
    transcript_url: props.videoDetails && props.videoDetails.transcript ? props.videoDetails.transcript : null,
    videoLevel: props.videoDetails && props.videoDetails.level ? props.videoDetails.level : null,
    videoCategory: props.videoDetails && props.videoDetails.category ? props.videoDetails.category : null,
    videoTags: props.videoDetails && props.videoDetails.tags ? props.videoDetails.tags : null
  }

  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    }
  )
  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })

  let defaultSelectedCourseTags = []
  for(let i in values.videoTags){
    defaultSelectedCourseTags.push(values.videoTags[i])
  }

  const courseCategorySuccessCallBack = res => {
    setCourseCategoryList(res.results)
  }
  const courseCategoryFailureCallBack = error => {
    setCourseCategoryList([])
  }

  const courseTagsSuccessCallBack = res => {
    setFetchCourseTags(res.results)
  }
  const courseTagsFailureCallBack = error => {
    setFetchCourseTags([])
  }

  useEffect(()=>{
    courseCategory({}, courseCategorySuccessCallBack, courseCategoryFailureCallBack)
    fetchCourseTagsList({}, courseTagsSuccessCallBack, courseTagsFailureCallBack)
  },[])
  
  const handleChangeTitle = (event) => {
    setVideoTitle(event.target.value)
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const onChangeEditorContent = data => {
    setVideoDesc(data)
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const handleChangeSelectLevel = event => {
    setSelectedVideoLevel(event.target.value)
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const handleChangeVideoCategory = event => {
    setSelectedCategory(event.target.value)
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const getCourseTags = (value, getTagProps) => {
    setSelectedCourseTags(value)
    return value.map((option, index) => (
      <Chip
        variant="outlined"
        label={option}
        {...getTagProps({ index })}
      />
    ))
  }

  const successUploadVideo = () => {
    setInprogress(false)
    props.listAllVideos()
    props.showSnackBar(
      {
        state: true,
        message: props.videoDetails ? SUCCESS_UPDATE_VIDEO : SUCCESS_SUBMIT_VIDEO,
        type: 'success'
      }
    )
    props.cancelAddVideos()
  }
  const failureUploadVideo = () => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: props.videoDetails ? ERROR_UPDATE_VIDEO : ERROR_SUBMIT_VIDEO,
        type: 'error'
      }
    )
  }

  const submitTrigger = (event) => {
    event.preventDefault()

    let isNewVideoValid = uploadedVideoFile.file ? true : false
    if (props.videoDetails && values.video_url) {
      isNewVideoValid = true
    }

    let isNewTranscriptValid = uploadedTranscript.file ? true : false
    if(props.videoDetails && values.transcript_url){
      isNewTranscriptValid = true
    }

    let isNewThumbnailValid = uploadedThumbnail.file ? true : false
    if(props.videoDetails && values.bannerImage){
      isNewThumbnailValid = true
    }else if(uploadedThumbnail && uploadedThumbnail.blob){
      isNewThumbnailValid = true
    }

    if(!videoTitle){
      setFormFieldStatus({
        videoTitle: { error: true },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please add video title!'
      })
    }
    else if(!videoDesc){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: true },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please add video description!'
      })
    }
    else if(!selectedVideoLevel){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: true },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please select video level!'
      })
    }else if(!selectedCategory){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: true },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please select video category!'
      })
    }else if(!selectedCourseTags.length){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: true },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please select video tags!'
      })
    }else if(!isNewVideoValid){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please upload video file!'
      })
    }else if(!isNewThumbnailValid){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please select thumbnail file!'
      })
    }else if(!isNewTranscriptValid){
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: true,
        errorMessage: 'Please upload transcript file!'
      })
    }
    else{
      setFormFieldStatus({
        videoTitle: { error: false },
        videoDesc: { error: false },
        videoLevel: { error: false },
        videoCategory: { error: false },
        videoTags: { error: false },
      })
      setValidationError({
        error: false,
        errorMessage: ''
      })
      setInprogress(true)
      let formData = new FormData()
      const newTags = []
      for (let i = 0; i < selectedCourseTags.length; i++) {
        const tag = find(fetchCourseTags, { 'skill': selectedCourseTags[i] })
        tag ? newTags.push({ "id": tag.id, "courseTag": selectedCourseTags[i] }) : newTags.push({ "id": "", "courseTag": selectedCourseTags[i] })
      }
      formData.append('title', videoTitle)
      formData.append('description', videoDesc)
      formData.append('level', selectedVideoLevel)
      formData.append('category', selectedCategory)
      formData.append('video', uploadedVideoFile.file ? uploadedVideoFile.file : null)
      formData.append('transcript', uploadedTranscript.file ? uploadedTranscript.file : null)
      formData.append('thumbnail', uploadedThumbnail.file ? uploadedThumbnail.file : uploadedThumbnail.blob ? uploadedThumbnail.blob : null)
      formData.append('tags', JSON.stringify(newTags))
      if(props.videoDetails){
        const payload = {
          id: props.videoDetails && props.videoDetails.id && props.videoDetails.id
        }
        updateVideo(payload, formData, successUploadVideo, failureUploadVideo )
      }else{
        uploadVideo(formData, successUploadVideo, failureUploadVideo)
      }
    }
  } 
  
  const closeTrigger = () => {
    props.cancelAddVideos()
  }

  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }

  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMsg: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMsg: ''
      }
    }else{
      const file = selectedFile[0]
      if(type === 'uploadvideo' && file.size > apiConfig.addingCourse.acceptedVideoFileSize){
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 60MB'
        }
      }else if(type === 'uploadimage' && file.size > apiConfig.addingCourse.acceptedImageFileSize){
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 2MB'
        }
      }else if(type === 'uploadtranscript' && file.size > apiConfig.addingCourse.acceptedTranScriptSize){
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 10MB'
        }
      }
      else{
        const fileFormats = () => {
          if(type === 'uploadvideo'){
            return apiConfig.addingCourse.acceptedVideoFileFormats
          }
          else if(type === 'uploadimage'){
            return apiConfig.addingCourse.acceptedImageFileFormats
          }
          else if(type === 'uploadtranscript'){
            return apiConfig.addingCourse.acceptedTranscriptFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMsg: 'Invalid file format.'
          }
        }else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMsg: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }

  const getThumbnailData = async(url, video) => {
    const thumbnails = await getThumbnails(url, {
      quality: 0.7,
      interval: video && video.size > 318465 ? 10 : 5
    })
    setThumbnailsData(thumbnails)
    setThumbnailLoader(false)
  }

  const onVideoUpload = event => {
    setThumbnailsData([])
    setUploadedThumbnail({})
    setThumbnailPreview(null)
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
    const videoFile = validatedFile('uploadvideo')
    setUploadedVideoFile(videoFile)
    setUplodedVideoPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
    if(event.target.files[0]){
      setThumbnailLoader(true)
      let url = URL.createObjectURL(event.target.files && event.target.files[0])
      let video = event.target.files && event.target.files[0]
      getThumbnailData(url, video)
    }
    if(event.target.files[0] === undefined){
      setThumbnailsData([])
      setUploadedThumbnail({})
      setThumbnailPreview(null)
    }
  }

  const onThumbnailUpload = event => {
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
    const thumbnailFile = validatedFile('uploadimage')
    setUploadedThumbnail(thumbnailFile)
    setThumbnailPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const onTranscriptUpload = event => {
    setFormFieldStatus({
      videoTitle: { error: false },
      videoDesc: { error: false },
      videoLevel: { error: false },
      videoCategory: { error: false },
      videoTags: { error: false },
    })
    setValidationError({
      error: false,
      errorMessage: ''
    })
    const transcriptFile = validatedFile('uploadtranscript')
    setUploadedTranscript(transcriptFile)
    setTranscriptPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const onThumbnailClick = (event, data) => {
    setUploadedThumbnail({})
    setThumbnailPreview(null)
    setUploadedThumbnail(data)
  }
  return(
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>{ props.videoDetails ? 'Update video form' : 'Upload video form'}</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            <Typography aira-hidden="true" className={classes.titleWrapper}>{ props.videoDetails ? 'Update Video' : 'Upload Video' }</Typography>
            {validationError.error ? (
              <div style={{ width: '24%', marginBottom: '18px', display: 'inline-block' }}>
                <AlertMessages status="error" message={validationError.errorMessage} />
              </div>
            ) : null}
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="title"
                    placeholder="Video title *"
                    helperText="max: 100 chars"
                    required
                    variant="outlined"
                    error={formFieldStatus.videoTitle.error}
                    defaultValue={values.videoTitle}
                    inputProps={{
                      maxLength: 100
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: !formFieldStatus.videoTitle.error ? classes.searchBoxOutline : ''
                      }
                    }}
                    autoFocus={true}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeTitle}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px' }}>
                    About this video
                    <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk" aria-hidden="true">&thinsp;*</span>
                  </Typography>
                  <TextEditor onChangeEditorContent={onChangeEditorContent} helperText={'About Video'} description={values.videoDesc}/>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{ paddingLeft: '2px'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    {/* <InputLabel shrink id="courseLevel" >
                      Video Level
                      <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                    </InputLabel> */}
                    <Select
                      labelId="courseLevel"
                      id="courseLevel"
                      value={selectedVideoLevel}
                      error={formFieldStatus.videoLevel.error}
                      onChange={handleChangeSelectLevel}
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {courseLevel.map((item, i) => {
                        return <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                      })}
                    </Select>
                    <FormHelperText>Select video level *</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{ paddingLeft: '16px'}}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    {/* <InputLabel shrink id="courseCategory">
                      Video Category
                      <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                    </InputLabel> */}
                    <Select
                      labelId="courseCategory"
                      id="courseCategory"
                      value={selectedCategory}
                      error={formFieldStatus.videoCategory.error}
                      onChange={handleChangeVideoCategory}
                      displayEmpty
                      className={classes.selectEmpty}
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      {courseCategoryList.map((item, i) => {
                        return <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                      })}
                    </Select>
                    <FormHelperText>Select video category *</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: '23px', marginBottom: '13px', paddingLeft: '46px'}}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={fetchCourseTags.map(option => option.skill)}
                    value={values.courseTags}
                    defaultValue={defaultSelectedCourseTags}
                    freeSolo
                    renderTags={(value, getTagProps) => { return getCourseTags(value, getTagProps) }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={formFieldStatus.videoTags.error}
                        id="courseTags"
                        variant="outlined"
                        required
                        name="videotags"
                        placeholder="Video tags *"
                        helperText="Type tags here and press 'ENTER' or, to add new tag"
                        fullWidth
                      //onChange={handleChange('courseTags')}
                      //value={values.CourseTags}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div style={{ float: 'left' }}>
                    {uploadedVideoFile.fileError ? (
                      <Typography variant="caption" display="block" gutterBottom className={classes.errorMsgWrapper}>
                        {uploadedVideoFile.fileErrorErrorMsg}
                      </Typography>
                    ) : null}
                    <span style={{ fontSize: '16px', marginLeft: '12px', fontWeight: 400  }} aria-hidden="true">
                      Upload Video*: 
                    </span>
                    <IconButton onClick={() => { onFileToUploadClick('uploadvideo') }} aria-label="upload video" aria-required="true">
                      <CloudUploadIcon className={classes.uploadIcon}/>
                    </IconButton>
                    <span className={classes.helperText}> ( max.size: 60MB, format: mp4 )</span>
                    <input type="file" accept=".mp4" style={{ display: 'none' }} id="uploadvideo" onChange={onVideoUpload} />
                    { uploadedVideoFile && uploadedVideoFile.file && uploadedVideoFile.file.name && uploadedVideoPreview ? (
                      <span className={classes.uploadedVideo}>
                        {uploadedVideoFile.file.name.length > 50 ? (
                          uploadedVideoFile.file.name.substr(0, 45) + '...'
                        ) :
                          uploadedVideoFile.file.name
                        }
                      </span>
                    ) : (
                      values.video_url ? (
                        <IconButton edge="start" color="primary" className={classes.iconButtonMargin} aria-label="uploaded video preview">
                          <VideoLibraryIcon style={{ marginTop: '-9px', color: '#2e8eec' }} />
                        </IconButton>
                      ) : null
                    ) }
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  { thumbnailLoader ? ( 
                    <div className="loadingcss">
                      <span>L</span><span>o</span><span>a</span><span>d</span><span>i</span><span>n</span><span>g</span>
                      <span></span>
                      <span>T</span><span>h</span><span>u</span><span>m</span><span>b</span><span>n</span><span>a</span><span>i</span><span>l</span>
                      <span>s</span>
                      <span>...</span>
                    </div>
                  ) : null }
                  { (thumbnailsData && thumbnailsData.length) || thumbnailPreview ? (
                    <React.Fragment>
                      <Typography style={{ lineHeight: '7px', textAlign: 'left', marginLeft: '11px', fontWeight: 500, fontSize: '15px'}}>Thumbnail*</Typography>
                      <Typography style={{ lineHeight: '20px', textAlign: 'left', marginLeft: '11px', fontWeight: 300, fontSize: '13px', paddingBottom: '10px', paddingTop: '10px'}}>Select or upload a picture that shows what's in your video.</Typography>
                      {uploadedThumbnail.fileError ? (
                        <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                          {uploadedThumbnail.fileErrorErrorMsg}
                        </Typography>
                      ) : null}
                      <div className={classes.uploadWrapper}>
                        { ( uploadedThumbnail && uploadedThumbnail.file)  || thumbnailPreview ? (
                          <React.Fragment>
                            <img alt="uploaded" src={thumbnailPreview} className={classes.thumbnailImg}></img>
                            <Tooltip title="Change thumbnail">
                              <Button size="small" onClick={() => { onFileToUploadClick('uploadimage')}} style={{ borderRadius: '6px', marginLeft: '-15px' }}>
                                <img alt="edit thumbnail" src={EditImageIcon} style={{ cursor: 'pointer', marginLeft: '12px', height: '25px', width: '24px' }} aria-hidden="true"></img>
                              </Button>
                            </Tooltip>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                          <Button style={{ fontSize: '15px', textTransform: 'none' }} onClick={() => { onFileToUploadClick('uploadimage') }}>
                            <AddPhotoAlternateIcon/>
                            <span>Upload thumbnail</span>
                          </Button>
                          </React.Fragment>
                        )}
                        <input type="file" accept="image/*" style={{ display: 'none' }} id="uploadimage" onChange={onThumbnailUpload} />
                      </div>
                      { thumbnailsData && thumbnailsData.length ? (
                        <React.Fragment>
                          <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                            <p>select one thumbnail from auto generated ones</p>
                          </div>
                          { thumbnailsData.map((data, index)=>(
                            <React.Fragment key={index}>
                              { data && index && index <= 4 ? (
                                <Button size="small" onClick={(event)=>{onThumbnailClick(event, data)}} style={{ marginTop: '-3px' }}>
                                  <img alt="video thumbnail" className={ data && Number(data.currentTime) === Number(uploadedThumbnail.currentTime) ? classes.optWrapper : classes.thumbnailWrapper} src={URL.createObjectURL(data.blob)}></img>
                                </Button>
                              ) : null }
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ) : null }
                    </React.Fragment>
                  ) : null }
                </Grid>
                {  ( uploadedVideoPreview || values.video_url ) && !thumbnailLoader ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ float: 'left' }}>
                      {uploadedTranscript.fileError ? (
                        <Typography variant="caption" display="block" gutterBottom className={classes.errorMsgWrapper}>
                          {uploadedTranscript.fileErrorErrorMsg}
                        </Typography>
                      ) : null}
                      <span style={{ fontSize: '16px', marginLeft: '12px', fontWeight: 400  }} aria-hidden="true">
                        Upload Transcript* :
                      </span>
                      <IconButton onClick={() => { onFileToUploadClick('uploadtranscript') }} aria-label="upload transcript" aria-required="true">
                        <CloudUploadIcon className={classes.uploadIcon}/>
                      </IconButton>
                      <span className={classes.helperText}> ( max.size: 10MB, format: vtt, srt )</span>
                      <input type="file" accept=".srt, .vtt" style={{ display: 'none' }} id="uploadtranscript" onChange={onTranscriptUpload} />
                      { uploadedTranscript && uploadedTranscript.file && uploadedTranscript.file.name && transcriptPreview ? (
                        <span className={classes.uploadedVideo}>
                          {uploadedTranscript.file.name.length > 50 ? (
                            uploadedTranscript.file.name.substr(0, 45) + '...'
                          ) :
                          uploadedTranscript.file.name
                          }
                        </span>
                      ) : (
                        values.transcript_url ? (
                          <IconButton edge="start" color="primary" className={classes.iconButtonMargin} aria-label="uploaded transcript preview">
                            <VideoLibraryIcon style={{ marginTop: '-9px', color: '#2e8eec' }} />
                          </IconButton>
                        ) : null
                      ) }
                    </div>
                  </Grid>
                ) : null }
              </Grid>
              {inProgress && (
                <CircularProgress size={30} className={classes.buttonProgress} />
              )}
              <Button variant="contained" disabled={inProgress} className={classes.button} onClick={(event) => { submitTrigger(event)}}>{ props.videoDetails ? 'Update' : 'Submit'}</Button>
              <Button variant="contained" disabled={inProgress} className={classes.button} onClick={closeTrigger}>Cancel</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
  
export default connect(
 mapStateToProps, { showSnackBar }
)(AddVideo);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Divider from '@material-ui/core/Divider';
import moment from "moment";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
//local imports
import { style } from '../../Styles/theme';
import { fetchMyNotices, updateViewNoticeStatus } from '../../store/NoticeBoard/actionCreator';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_FETCHING_NOTICE } from '../Common/Constants/errorMessages';
import { getUSerType } from '../../Helpers/basic';
import Pagination from '../ManageGroups/pagination';
import CalenderSiderLoader from '../Common/skeletonLoaders/calenderSiderLoader';
import SentToInfoViewPopup from './sentToInfoViewPopup';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  eventsTitle: {
    marginTop: "2rem",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    padding: "1rem 0 1rem 1rem",
    backgroundColor: "#eaf2f2",
    color: "#11539f",
  },
  siderCntr: {
    height: "32rem",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  itemBlock: {
    cursor: "pointer",
    '&.selected': {
      backgroundColor: '#eaf2f2'
    }
  },
  eventItem: {
    marginLeft: "0.5rem",
  },
  itemTitle: {
    textTransform: 'none',
    fontSize: "1rem",
    color: "#11539f",
    marginTop: "0.3rem",
    textAlign: "initial",
  },
  dateCtr: {
    margin: "0.2rem 0.5rem 0 0",
    display: "flex",
  },
  timeCtr: {
    margin: "0 0.5rem 0.2rem 0.5rem",
    display: "flex",
  },
  timeRecCtr: {
    margin: "0 0.5rem 0.2rem 0",
  },
  dateVal: {
    textAlign: "center",
    color: "#11539f",
    fontSize: "0.8rem",
  },
  timings: {
    fontSize: "0.8rem",
    color: "#11539f",
    textTransform: "uppercase",
  },
  label: {
    color: "#11539f",
    fontWeight: 500,
  },
  noDataWrapper: {
    color: "#11539f",
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center'
  },
  value: {
    fontWeight: 400,
    color: "#000",
    marginLeft: "0.3rem",
  },
  detailsHeader: {
    backgroundColor: "#eaf2f2",
    borderRadius: "5px",
  },
  mtitle: {
    textAlign: "initial",
    margin: "0.7rem 0 0 1rem",
    fontSize: "1rem",
    padding: "0.5rem",
  },
  recLabel: {
    textAlign: "initial",
    fontSize: "0.8rem",
    color: "#424548",
  },
  container: {
    textAlign: "initial",
    margin: "1rem 0 1rem 0",
  },
  flex: {
    display: "flex",
  },
  attachmentWrapper: {
    paddingBottom: '20px',
    textAlign: 'left'
  },
  sentToWrapper: {
    marginLeft: '5px',
    fontSize: '13px'
  }
}));

function MyNotices(props) {
  const classes = useStyles();
  const userType = getUSerType()
  const [noticeList, setNoticeList] = React.useState([])
  const [noticeListLoader, setNoticeListLoader] = React.useState(true)
  const [totalCount, setTotalCount] = React.useState(0)
  const rowsPerPage = 10
  const offset = 0
  const [page, setPage] = React.useState(0);
  const [renderNotice, setRenderNotice] = React.useState(false);
  const [selectedNotice, setSelectedNotice] = React.useState({
    created_by: {}
  });
  const [sentToInfoPopup, setSetToInfoPopup] = React.useState({
    showPopup: false,
    selectedNoticeData: {}
  })

  const createMarkup = data => {
    return {
      __html: data
    };
  }
  const fetchMyNoticesSuccessCallBack = res => {
    setTotalCount(res && res.count)
    setNoticeList(res && res.notices && res.notices)
    setNoticeListLoader(false)
    if (res.count > 0) {
      setRenderNotice(true)
      showNotice(res.notices[0])
    } else {
      setRenderNotice(false)
    }
  }
  const fetchMyNoticesfailureCallBack = err => {
    setNoticeList([])
    setNoticeListLoader(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_NOTICE,
        type: 'error'
      }
    )
  }
  useEffect(() => {
    setNoticeListLoader(true)
    const payload = {
      offset: offset,
      number: 10,
      view: 'student'
    }
    fetchMyNotices(payload, fetchMyNoticesSuccessCallBack, fetchMyNoticesfailureCallBack)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  const onPagination = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    setNoticeListLoader(true)
    const payload = {
      offset: offset,
      number: number,
      view: 'student'
    }
    fetchMyNotices(payload, fetchMyNoticesSuccessCallBack, fetchMyNoticesfailureCallBack)
  }

  const navigateTo = to => {
    props.history.push(to === 'create' ? `/createnotice` : `/sentnotices`)
  }

  const showNotice = notice => {
    document.getElementById('viewnotice') && document.getElementById('viewnotice').focus()
    setRenderNotice(true)
    setSelectedNotice(notice)
    updateViewNoticeStatus({
      notice_id: notice.id,
      is_read: true
    }, () => {
      document.getElementById('notice_title_' + notice.id).style.fontWeight = 'normal'
    }, () => { })
  }

  //FOR SHOWING THE NOTICE SENT TO INFORMATION
  const onSentToViewInfoClick = (data) => {
    setSetToInfoPopup({
      showPopup: true,
      selectedNoticeData: data
    })
  }
  const closeSentToViewInfoPopup = () => {
    setSetToInfoPopup({
      showPopup: false,
      selectedNoticeData: {}
    })
  }

  const getSentToDetails = (item) => {
    if (item && item.sent_to && item.sent_to.user_type && Object.keys(item.sent_to.user_type) && Object.keys(item.sent_to.user_type).length) {
      if (Object.values(item.sent_to.user_type).filter(value => value === true).length === 2) {
        var res = Object.keys(item.sent_to.user_type).filter(function (value) {
          return item.sent_to.user_type[value] === true;
        })
        if (res.includes('all_students') && res.includes('all_mentors')) {
          return ' All Mentors & Students'
        } else if (res.includes('all_students') && res.includes('all_instructors')) {
          return ' All Instrucotrs & Students'
        } else {
          return ' All Instrucotrs & Mentors'
        }
      } else if (Object.values(item.sent_to.user_type).filter(value => value === true).length === 3) {
        return ' All Instructors, Mentors & Students'
      } else if (item.sent_to.user_type.individual) {
        return ' Individuals'
      } else {
        if (item.sent_to.user_type.everyone) {
          return ' Everyone'
        } else if (item.sent_to.user_type.all_mentors) {
          return ' All Mentors'
        } else if (item.sent_to.user_type.all_students) {
          return ' All Students'
        } else if (item.sent_to.user_type.all_instructors) {
          return ' All Instructors'
        } else {
          return (
            <React.Fragment>
              <Link
                className={classes.sentToWrapper}
                component="button"
                variant="body2"
                onClick={() => {
                  onSentToViewInfoClick(item);
                }}
              >
                View info
              </Link>
            </React.Fragment>
          )
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>My Notice Board</h1>
      </div>
      <div>
        <Typography className={classes.titleWrapper} aria-hidden="true" style={{ float: 'left', paddingTop: 20 }}>My Inbox</Typography>
        {userType === 'Instructor' ? (
          <div style={{ padding: 10, textAlign: 'right' }}>
            <Fab variant="extended" className={classes.button} onClick={() => { navigateTo('create') }}>
              <AddIcon className={classes.extendedIcon} />
              Compose
            </Fab>
            <Fab variant="extended" className={classes.button} onClick={() => { navigateTo('sent') }}>
              <MailOutlineIcon className={classes.extendedIcon} />
              My sent messages
            </Fab>
          </div>
        ) : null}
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Paper className={classes.siderPaper}>
            <div className={classes.eventsTitle} style={{ textAlign: 'left' }}>My Notices</div>
            {noticeListLoader ? (
              <CalenderSiderLoader />
            ) : (
              <React.Fragment>
                <div className={classes.siderCntr}>
                  {noticeList && noticeList.length > 0 ? (
                    noticeList.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div
                            className={(selectedNotice.id === item.id) ? classes.itemBlock + ' selected' : classes.itemBlock}
                            onClick={() => { showNotice(item); }}
                            id={`notice_${item.id}`}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={10} sm={10} md={10} lg={10}>
                                <div className={classes.eventItem}>
                                  <div style={{ width: '100%', textAlign: 'initial' }}>
                                    <Link className={classes.itemTitle} component="button" onClick={() => { showNotice(item); }} id={`notice_title_${item.id}`} style={{ fontWeight: item.is_read ? 'normal' : 'bold' }}>
                                      {item && item.subject && item.subject.length > 30 ? item.subject.substring(0, 30) + "..." : item && item.subject}
                                    </Link>
                                  </div>
                                  <div className={classes.dateCtr}>
                                    <div className={classes.dateVal}>
                                      {new Date().getDate() === new Date(item.created_date).getDate() ? (
                                        <div style={{ color: "green", fontWeight: "600", }}>
                                          Today
                                        </div>
                                      ) : (
                                        ` ${moment(item && item.created_date && item.created_date).format("MMM DD")} ${moment(item && item.created_date && item.created_date).format("ddd")}`
                                      )}
                                    </div>
                                    <div className={classes.timeCtr}>
                                      <div className={classes.timings}>
                                        {moment(item && item.created_date && item.created_date).format("hh:mm a")}
                                      </div>
                                    </div>
                                  </div>
                                  {item && item.modified_date ? (
                                    <React.Fragment>
                                      <div className={classes.recLabel}>
                                        Updated On: {moment(selectedNotice.modified_date).format("DD-MMM-YYYY hh:mm a")}
                                      </div>
                                    </React.Fragment>
                                  ) : null}
                                  <div className={classes.timeRecCtr}>
                                    <div className={classes.recLabel}>
                                      Sent by: {item.created_by.first_name + ' ' + item.created_by.last_name}
                                    </div>
                                  </div>
                                  <div className={classes.timeRecCtr}>
                                    <div className={item && item.is_host ? classes.recHostLabel : classes.recLabel}>
                                      Sent to:
                                      {getSentToDetails(item)}
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <Divider />
                        </React.Fragment>
                      )
                    })) : (
                    <React.Fragment>
                      {!noticeListLoader ? (
                        <div className={classes.noDataWrapper}>No Notices!</div>
                      ) : null}
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
            {totalCount > 10 && !noticeListLoader ? (
              <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} />
            ) : null}
          </Paper>
        </Grid>
        {renderNotice ? (
          <React.Fragment>
            <div id="viewnotice" tabIndex="-1" className="accessibility">
              <h1>View notice</h1>
            </div>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              {noticeListLoader ? (
                <CalenderSiderLoader type={'viewNotice'} />
              ) : (
                <React.Fragment>
                  <Paper elevation={1} style={{ marginTop: "2.1rem" }}>
                    <div className={classes.detailsHeader}>
                      <Grid container spacing={1}>
                        <Grid item xs={8} sm={8} md={10} lg={10}>
                          <div className={classes.mtitle}>{selectedNotice.subject}</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                      <div className={classes.container}>
                        {selectedNotice && selectedNotice.modified_date && <div className={classes.flex}>
                          <div className={classes.label}>Last Modified On:</div>
                          <div className={classes.value} style={{ textTransform: "uppercase" }}>
                            {moment(selectedNotice.modified_date).format("DD-MMM-YYYY hh:mm a")}
                          </div>
                        </div>}
                        <div className={classes.flex}>
                          <div className={classes.label}>Sent On:</div>
                          <div className={classes.value} style={{ textTransform: "uppercase" }}>
                            {moment(selectedNotice.created_date).format("DD-MMM-YYYY hh:mm a")}
                          </div>
                        </div>
                        <div className={classes.flex}>
                          <div className={classes.label}>Sent By:</div>
                          <div className={classes.value}>
                            {selectedNotice.created_by.first_name + ' ' + selectedNotice.created_by.last_name}
                          </div>
                        </div>
                      </div>
                      <Divider aria-hidden="true" />
                      <div className={classes.container}>
                        <div className={classes.flex} aria-label="Notice Description" >
                          <div dangerouslySetInnerHTML={createMarkup(selectedNotice.message)} />
                        </div>
                      </div>
                      <div className={classes.attachmentWrapper}>
                        {selectedNotice && selectedNotice.attachment_details && selectedNotice.attachment_details.file_name ? (
                          <React.Fragment>
                            {selectedNotice.attachment_details && selectedNotice.attachment_details.file_name && selectedNotice.attachment_details.file_name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginRight: '5px', fontSize: '19px' }} /> : selectedNotice.attachment_details.file_name.split('.')[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginRight: '5px', fontSize: '19px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-5px', marginRight: '5px', fontSize: '19px' }} />}
                            <a href={selectedNotice.attachment_details && selectedNotice.attachment_details.file_url && selectedNotice.attachment_details.file_url} target='_blank'>
                              {selectedNotice.attachment_details && selectedNotice.attachment_details.file_name && selectedNotice.attachment_details.file_name && selectedNotice.attachment_details.file_name}
                            </a>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                  </Paper>
                </React.Fragment>
              )}
              <a className="skip-main" href="#mainH1Tag">
                Back to notices
              </a>
            </Grid>
          </React.Fragment>
        ) : null}
        {sentToInfoPopup.showPopup &&
          <SentToInfoViewPopup data={sentToInfoPopup} close={closeSentToViewInfoPopup} history={props.history} match={props.match} />
        }
      </Grid>
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(MyNotices);

import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import List from '@material-ui/core/List';
import { Route } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DatePicker from "react-datepicker";
import Button from '@material-ui/core/Button';
import "react-datepicker/dist/react-datepicker.css";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { style } from "../../Styles/theme";
import { showSnackBar } from "../../store/AlertMessages/actionCreator";
import { fetchZoomClasses, fetchUsers } from '../../store/ZoomClasses/actionCreator';
import VimeoPlayer from "../Common/Player/vimeoPlayer";
import { timeDiffCalc } from "../../Helpers/basic";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(3),
        padding: '3px',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            width: 'auto',
            marginTop: '10px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        padding: '5px',
        textAlign: 'center',
        marginTop: '5px',
        width: '100%',
        cursor: 'pointer'
    },
    titleWrapper: {
        fontSize: '29px',
        fontWeight: '500',
        color: style.fontColour._blue,
        marginBottom: '11px',
        marginTop: '11px',
        textAlign: 'center'
    },
    cardWrapper: {
        maxWidth: 270,
        margin: "auto",
        transition: "0.3s",
        "&:hover": {
            boxShadow: "0 10px 50px -12.125px rgba(0,0,0,0.3)",
            transition: 'transform 1s',
            transform: 'translateY(-4px)'
        }
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9,
        cursor: 'pointer'
    },
    topic: {
        fontSize: '14px',
        fontWeight: '400',
        color: style.fontColour._blue,
        textAlign: 'initial',
        lineBreak: 'anywhere'
    },
    progress: {
        marginTop: "0.5rem",
        borderRadius: "10px",
    },
    avatar: {
        backgroundColor: style.fontColour._blue,
    },
    detailsCtr: {
        display: 'flex',
        paddingTop: '0.5rem',
    },
    item: {
        fontSize: '11px',
        paddingRight: '0.5rem',
        paddingTop: '0.2rem',
        color: style.fontColour._egyptian_blue,
        textAlign: 'initial',
        lineBreak: 'anywhere'
    },
    infoCtr: {
        padding: '5px'
    },
    icn: {
        cursor: 'pointer',
        marginTop: '0.5rem',
        color: style.fontColour._blue,
    },
    paperCtr: {
        minHeight: '500px',
        padding: '0.5rem',
        overflow: 'scroll'
    },
    paperCtrMob: {
        minHeight: '200px',
        padding: '0.5rem',
        overflow: 'scroll'
    },
    filtrCtr: {
        display: 'flex',
    },
    pickerCtr: {
        display: 'flex',
        //justifyContent: 'flex-end',
    },
    datePicker: {
        border: '1px solid',
        borderRadius: '5px',
        width: 120,
        padding: '0.5rem',
        margin: '0.5rem 0.5rem 0 0'
    },
    dtPkrMob: {
        textAlign: 'initial'
    },
    dtPkr: {
        textAlign: 'initial'
    },
    btn: {
        flexGrow: 1,
        margin: '1rem',
        backgroundColor: style.fontColour._blue,
        color: '#FFF',
        '&:hover': {
            backgroundColor: style.fontColour._blue,
        },

    },
    formControl: {
        margin: '0.5rem',
        minWidth: 200,
        maxWidth: 300,
    },
    formControlMob: {
        margin: '0.5rem',
        minWidth: 300,
        maxWidth: 350,
    },
    filtrTxt: {
        fontSize: '1rem',
        color: style.fontColour._blue,
        margin: '1.5rem 0 0 0.5rem'
    },
    player: {
        marginTop: '0.5rem'
    }
}))

function MyClasses(props) {
    const classes = useStyles();
    const mobileView = useMediaQuery("(max-width:600px)");
    const xsMobileView = useMediaQuery("(max-width:400px)");
    const [inProgress, setInprogress] = useState(false);
    const [classesData, setClassesData] = useState([]);
    const [playerData, setPlayerData] = useState({});
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 7);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(new Date());
    const [personName, setPersonName] = useState([]);
    const [filterList, setFilterList] = useState([]);


    useEffect(() => {
        fetchAllClasses();
        fetchSharedUsers();
    }, [])

    const fetchSharedUsers = () => {
        fetchUsers({}, successFetchUsers, failureFetchUsers)
    }

    const successFetchUsers = res => {
        setInprogress(false);
        setFilterList(res.data)
    }

    const failureFetchUsers = error => {
        setInprogress(false);
        setFilterList([]);
    }

    const fetchAllClasses = () => {
        setInprogress(true);
        let payload = {
            start_date: startDate,
            end_date: endDate,
            user_id: personName
        }
        fetchZoomClasses(payload, successFetchClasses, failureFetchClasses)
    }

    const successFetchClasses = res => {
        setInprogress(false);
        setClassesData(res && res.data);
        if (res && res.data.length > 0) {
            let playerInfo = {
                videoURL: res.data[0].video_url,
                type: "classes",
            };
            setPlayerData(playerInfo);
        }
    }

    const failureFetchClasses = error => {
        setInprogress(false);
        setClassesData([]);
    }

    const handleSelectChange = (event) => {
        setPersonName(event.target.value);
    };

    const playRecording = (data) => {
        let playerInfo = {
            videoURL: data.video_url,
            type: "classes",
        };
        setPlayerData(playerInfo);
    }


    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        {inProgress ? <CircularProgress size={30} className={classes.buttonProgress} /> :
                            <Paper elevation={1} className={mobileView ? classes.paperCtrMob : classes.paperCtr}>
                                {classesData && classesData.length ? (
                                    <Grid container spacing={2} style={{ marginTop: '11px' }}>
                                        {classesData.map((data, index) => {
                                            let startTime = new Date(data.recording_start)
                                            let endTime = new Date(data.recording_end)
                                            let duration = timeDiffCalc(endTime, startTime);
                                            return (
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '2px' }} key={index}>
                                                    <Paper className={classes.paper} key={index} elevation={2} onClick={() => { playRecording(data) }}>
                                                        <Grid container spacing={2} style={{ padding: '5px' }}>
                                                            <Grid item xs={10} sm={10} md={10} lg={10}>
                                                                <div className={classes.topic}>{data && data.topic && data.topic.length > 38 ? data.topic.substring(0, 38) + "..." : data.topic}</div>
                                                                <div className={classes.detailsCtr}>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={12} sm={6} md={5} lg={4} style={{ padding: '2px' }} >
                                                                            <div className={classes.item}> {data.host.length > 20 ? data.host.substring(0, 20) + "..." : data.host} </div>
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={3} lg={4} style={{ padding: '2px' }}>
                                                                            <div className={classes.item}> {moment(startTime).format("DD-MM-YY")}</div>
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={4} lg={4} style={{ padding: '2px' }}>
                                                                            <div className={classes.item}>{duration} Mins </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                                                <PlayCircleOutlineIcon className={classes.icn} />
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                ) : (
                                        <div style={{ fontSize: '16px', padding: '1rem 1rem 12px 1rem', color: style.fontColour._blue }}>No Records Found.</div>
                                    )}
                            </Paper>}
                    </Grid>
                    <Grid item xs={12} sm={8} md={8} lg={9}>
                        <Paper elevation={1}>
                            <Grid container spacing={2} style={{ margin: '5px' }}>

                                <Grid item xs={12} sm={12} md={4} lg={6} style={{ padding: '1px', textAlign: 'initial' }}>
                                    {filterList.length > 0 && <FormControl className={mobileView ? classes.formControlMob : classes.formControl}>
                                        <InputLabel id="demo-mutiple-checkbox-label">Filter By</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={personName}
                                            onChange={handleSelectChange}
                                            label="Filter Member"
                                        >
                                            {filterList.length > 0 && filterList.map((item, index) => (
                                                <MenuItem key={index} value={item.id}>{item.mentor_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>}
                                </Grid>
                                <Grid item xs={8} sm={8} md={5} lg={4} style={{ padding: '1px' }}>
                                    <div style={{ display: "flex", margin: "0.8rem" }}>
                                        <DatePicker
                                            aria-label="select meeting filter start date"
                                            className={classes.datePicker}
                                            selected={startDate}
                                            maxDate={new Date()}
                                            onChange={date => setStartDate(date)}
                                            dateFormat="MMMM d, yyyy"
                                            placeholderText="Click to select a date"
                                        />
                                        <DatePicker
                                            aria-label="select meeting filter end date"
                                            className={classes.datePicker}
                                            selected={endDate}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            onChange={date => setEndDate(date)}
                                            dateFormat="MMMM d, yyyy"
                                            placeholderText="Click to select a date"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={2} style={{ padding: '1px', textAlign: 'initial' }}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={() => { fetchAllClasses() }}>Search</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        <div className={classes.player}>
                            {playerData.videoURL && <VimeoPlayer data={playerData} />}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = state => {
    return {}
}
export default connect(
    mapStateToProps, { showSnackBar }
)(MyClasses);
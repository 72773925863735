import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LeftMenu from '../AppLayout/leftMenu'
import CourseLevelDiscussions from './courseLevelDiscussions'
import DiscussionReportView from './discussionReport'

function Discussions(props) {
  const renderDiscussionView = () => {
    if (props.match.params.discussionId) {
      return (
        <DiscussionReportView history={props.history} match={props.match} />
      )
    }
    else if (props.match.params.courseId) {
      return (
        <CourseLevelDiscussions history={props.history} match={props.match} />
      )
    }
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      <div>
        {renderDiscussionView()}
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(Discussions);
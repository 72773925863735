import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { closeSnackBar } from '../../../store/AlertMessages/actionCreator';
import { getDomainInfo } from '../../../Helpers/basic';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: '#fff',
    borderLeft: '10px solid green',
    color: 'green'
  },
  error: {
    backgroundColor: '#fff',
    borderLeft: '10px solid red',
    color: 'red'
  },
  warning: {
    backgroundColor: '#fff',
    borderLeft: '10px solid #FF9800',
    color: '#FF9800'
  },
  workinprogress: {
    backgroundColor: '#fff',
    borderLeft: '10px solid #FF9800',
    color: '#2196F3',
    fontWeight: 500,
    fontSize: '17px'
  },
  workInpTextWrapper: {
    color: '#FF9800',
    fontWeight: 500,
    fontSize: '17px'
  },
  iconWrapper: {
    opacity: 0.9,
    fontSize: "22px",
    margin: '8px 7px -4px 3px'
  },
  info: {
    backgroundColor: '#fff',
    borderLeft: '10px solid #2196F3',
    color: '#2196F3'
  },
}));
function SnackBarmessages(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const state = {
    open: props.state,
    vertical: 'top',
    horizontal: 'center',
  }
  const { vertical, horizontal } = state;
  const handleClose = () => {
    props.closeSnackBar()
  };
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        open={props.state}
        onClose={handleClose}
        autoHideDuration={props.type !== 'error' ? 5000 : 5000}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes[props.type]
          }
        }}
        message={props.type === 'error' ?
          <span id="message-id">{props.message}</span> : <span id="message-id">{props.message}
            {props.type === 'workinprogress' && domainInfo.name === 'rmbl' ? (
              <div className={classes.workInpTextWrapper}>
                <ReportProblemOutlinedIcon className={classes.iconWrapper} />
                Kindly revisit this page later once the update is completed.
              </div>
            ) : null}
          </span>}
      />
    </div>
  );
}
const mapStateToProps = state => {
  return {
    alertReducer: state.alertReducer
  }
}
export default connect(
  mapStateToProps, { closeSnackBar }
)(SnackBarmessages);

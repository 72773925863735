import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'
// import { mockData } from './mockData'

export const listAssignments = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.listAssignments}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const createAssignment = (formData, successCallBack, failureCallBack) => {
    const url =`${apiConfig.assignment.create}`
    axios.post(url,formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const deleteAssignment = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.deleteAssignment}${payLoad.assignment_id}/`
  axios.delete(url, {
    data: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const updateAssignment = (payload, formData, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.update}${payload.assignment_id}/`
  axios.put(url,formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const publishAssignment = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.publishAssignment}${payload.assignment_id}/`
  axios.patch(url,payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchAssignment = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.fetchAssignment}`
  axios.get(url, {
    params: payload
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const assignmentReport = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.assignmentReport}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchStudentSubmissions = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.fetchStudentSubmissions}`
  axios.get(url, {
    params: payload
  })
  .then(function (response) {
    successCallBack(response.data)
    // successCallBack(mockData)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const submitGrade = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.submitGrade}${payload.submission_id}/`
  axios.patch(url,payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

const mockAssignmentsData = {
  totalCount: 20,
  results: [
    {
      "assignment_id": 8,
      "title": "Test assignment-8",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": false,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 7,
      "title": "Test assignment-7",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": false,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 6,
      "title": "Test assignment-6",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": false,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 5,
      "title": "Test assignment-5",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": false,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 4,
      "title": "Test assignment-4",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": false,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 3,
      "title": "Test assignment-3",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": true,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 2,
      "title": "Test assignment-2",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": true,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    },
    {
      "assignment_id": 1,
      "title": "Test assignment-1",
      "desc": "<p>desc</p>",
      "points": 100,
      "published": true,
      "attachment": null,
      "start_date": "2020-05-13T10:58:59.033000Z",
      "end_date": "2020-05-13T10:59:00.122000Z"
    }
  ]
}

//FOR EXTERNAL ASSIGNMENTS FLOW
export const fetchExternalAssignments = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.fetchExternalAssignments}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const createExternalAssignment = (formData, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.createExternalAssignment}`
  axios.post(url,formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const updateExternalAssignment = (payload, formData, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.updateExternalAssignment}${payload.assignment_id}/`
  axios.put(url,formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const deleteExternalAssignment = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.deleteExternalAssignment}${payLoad.assignment_id}/`
  axios.delete(url, {
    data: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const publishExternalAssignment = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.publishExternalAssignment}`
  axios.patch(url,payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchExternalAssignmentData = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.fetchExternalAssignmentData}${payload.assignment_id}/`
  axios.get(url, payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchExternalAssignmentReport = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.fetchExternalAssignmentReport}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchExternalAssRptTabCount = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assignment.fetchExternalAssRptTabCount}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    //successCallBack({pending:13, completed:20})
  })
}
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

export const listDiscussions = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.listDiscussions}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const createDiscussion = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.create}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const updateDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.update}${payload.id}/`
  axios.put(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const deleteDiscussion = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.deleteDiscussion}${payLoad.id}/`
  axios.delete(url, {
    data: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const publishDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.publishDiscussion}${payload.id}/`
  axios.patch(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchDiscussion}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockRes = {
  "results": [
    {
      "id": 10,
      "first_name": "Laukik",
      "last_name": "Sarode",
      "submission_date": "2021-06-14T10:36:37.865756Z",
      "reply_text": "<p>testing 12 12 12 12</p>",
      "has_comments": false,
      "attachment_details": {
        "file_name": "General.pdf",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
      "comment_count": 0,
      "role_user": [
        "Instructor",
        "Mentor"
      ]
    },
    {
      "id": 6,
      "first_name": "Laukik",
      "last_name": "Sarode",
      "submission_date": "2021-03-22T08:05:19.754085Z",
      "reply_text": "<p>testing</p>",
      "has_comments": false,
      "attachment_details": {
        "file_name": "POSH.pdf",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
      "comment_count": 0,
      "role_user": [
        "Instructor",
        "Mentor"
      ]
    },
    {
      "id": 6,
      "first_name": "Laukik",
      "last_name": "Sarode",
      "submission_date": "2021-03-22T08:05:19.754085Z",
      "reply_text": "<p>3rd response to a discussion</p>",
      "has_comments": false,
      "attachment_details": {
        "file_name": "POSH.ppt",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
      "comment_count": 0,
      "role_user": [
        "Instructor",
        "Mentor"
      ]
    }
  ]
}

export const fetchResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockRes)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchSubReplies = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchSubReplies}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const postReply = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.postReply}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const postCommentForResponse = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.postComment}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const respondedStudentsList = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.respondedStudents}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockStudentResponse = {
  "results": {
    "id": 10,
    "first_name": "Laukik",
    "last_name": "Sarode",
    "user_id": 1,
    "user_comment_data": [],
    "user_score": null,
    "graded": false,
    "user_data": [
      {
        "submission_date": "2021-03-22T08:05:19.754085Z",
        "reply_data": "<p>testing 123</p>",
        "attachment_details": {
          "file_name": "POSH.ppt",
          "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
        },
      },
      {
        "submission_date": "2021-06-14T10:53:20.778247Z",
        "reply_data": "<p>testing 456</p>",
        "attachment_details": {
          "file_name": "POSH.ppt",
          "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
        },
      }
    ],
    "reply_count": 2,
    "grade_id": null
  }
}

export const fetchStudentResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchStudentResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockStudentResponse)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const submitGrade = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.submitGrade}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const updateGrade = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.updateGrade}${payload.grade_id}/`
  axios.put(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockPinned = {
  "results": [
    {
      "id": 10,
      "first_name": "Laukik",
      "last_name": "Sarode",
      "submission_date": "2021-06-14T10:53:20.778247Z",
      "reply_text": "<p>testing</p>",
      "attachment_details": {
        "file_name": "POSH.pdf",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    }
  ],
  "pin_count": 1
}
export const fetchPinnedResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchPinnedResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockPinned)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const pinDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.pinDiscussion}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//----------------EXTERNAL DISCUSSION BOARD API'S CALLS START HERE-------------------------------//

//create external discussion
export const createExternalDiscussion = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.createExternalDiscussion}`
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//update external discussion
export const updateExternalDiscussion = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.updateExternalDiscussion}${payload.discussion_id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR ASSIGNING DISCUSSION TO A GROUPS
export const assignDiscussionToGrps = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.assignDiscussionToGrps}${payload.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//delete external discussion
export const deleteExternalDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.deleteExternalDiscussion}${payload.id}/`
  axios.delete(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//publish external discussion
export const publishExternalDiscussion = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.publishExternalDiscussion}${payload.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//fetch tab counts
export const fetchExternalTabCounts = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalTabCounts}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//fetch all the external discussions
export const fetchExternalDiscussions = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchAllExternalDiscussions}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//fetch single discussion details
export const fetchExternalDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalDiscussion}${payload.id}/`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockExternalRes = {
  "results": [
    {
      "id": 89,
      "first_name": "Laukik",
      "last_name": "Sarode",
      "submission_date": "2021-06-14T11:25:59.550560Z",
      "reply_text": "<p>First Response 124</p>",
      "has_comments": false,
      "comment_count": 0,
      "role": "Instructor",
      "attachment_details": {
        "file_name": "POSH.pdf",
        "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
      },
    }
  ]
}
//fetch external discussion responses
export const fetchExternalResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockExternalRes)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR PINNING THE DISCUSSION
export const pinExternalDiscussion = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.pinExternalDiscussion}`
  axios.put(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING EXTERNAL PINNED RESPONSES
export const fetchExternalPinnedResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalPinnedResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockExternalRes)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//for posting reply to a discussion
export const externalPostReply = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalPostReply}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR POSTING COMMENT TO A RESPONSE
export const externalPostComment = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalPostComment}`
  axios.post(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//for fetching external sub replies
export const fetchExternalSubReplies = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalSubReplies}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING RESPONDED STUDENTS LIST
export const externalRespondedStudents = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalRespondedStudents}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

const mockExternalStudentRes = {
  "results": {
    "id": 89,
    "first_name": "Laukik",
    "last_name": "Sarode",
    "user_id": 1,
    "user_comment_data": [],
    "user_score": null,
    "graded": false,
    "user_data": [
      {
        "submission_date": "2021-06-14T11:50:27.593367Z",
        "reply_data": "<p>First Response 1234</p>",
        "attachment_details": {
          "file_name": "POSH.pdf",
          "file_url": "https://cedb-c.s3.amazonaws.com/media/modules/reading/20200515194537460130_POSH.pdf?AWSAccessKeyId=AKIAJ3ZDXCMAJXHL6S4A&Signature=Jl8iDSlPUoQmLSJ2NWwSAEcK5iQ%3D&Expires=1623671188",
        },
      }
    ],
    "reply_count": 1,
    "grade_id": null
  }
}

//FOR FETCHING STUDENT RESPONSES
export const fetchExternalStudentResponses = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.fetchExternalStudentResponses}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
      //successCallBack(mockExternalStudentRes)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR ADDING GRADE TO A DISCUSSION
export const externalSumbitGrade = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalSumbitGrade}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR UPDATING GRADE TO A DISCUSSION
export const externalUpdateGrade = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.discussions.externalUpdateGrade}${payload.grade_id}/`
  axios.put(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

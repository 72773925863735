import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link'
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid'
import { Paper, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { colorStyles } from '../../Styles/theme';
import DeleteAssignment from './deleteConfirm'
import { convertUTCTimeZone, getDomainInfo } from '../../Helpers/basic'
import AssignmentPreview from '../Common/OpeningDialogView/assignmentPreview'
import AddAssignment from './addAssignment'
import AssignmentPublish from './publishPopup';
import { style } from '../../Styles/theme'
//FOR FINDING THE CO WORKERS OF THIS COURSE
import { postCourseWorkingUsersInfo } from '../../store/Authors/actionCreator';
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-81px',
    width: '100%'
  },
  rowWrapper: {
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    textAlign: 'initial',
    borderRadius: '5px',
  },
  dueDateWrapper: {
    color: '#3949ab',
    textAlign: 'initial',
    marginTop: '-19px',
    marginLeft: '49px',
    fontSize: '12px'
  },
  iconMainWrapper: {
    marginRight: '7px',
    fontSize: '28px',
    marginTop: '22px',
    border: '4px solid #ECEBFD',
    backgroundColor: '#ECEBFD',
    borderRadius: '6px',
    color: style.fontColour._blue,
  },
  listItemWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  titleWrapper: {
    cursor: 'pointer',
    color: '#42a5f5',
    fontSize: '17px',
    fontWeight: '500',
    textDecoration: 'none',
  },
  iconButtonWrapper: {
    color: '#2e8eec',
    marginTop: '10px'
  },
  addAssignmentBtn: {
    color: '#3949ab',
  },
  title: {
    fontSize: '20px',
    fontWeight: '500',
  },
  titleHead: {
    backgroundColor: '#f5f5f5',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    height: '47px'
  },
  selectedTitleHead: {
    backgroundColor: '#c8e6c9',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '5px',
    height: '47px'
  },
  assignmentText: {
    textAlign: 'initial',
    marginTop: '18px',
    marginLeft: '11px',
    fontSize: '14px',
    marginBottom: '10px'
  }
}));

export default function AssignmentsListView(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const [assignmentData, setAssignmentData] = React.useState([])
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
  const [openPreview, setOpenPreview] = React.useState(false)
  const [previewDetails, setPreviewDetails] = React.useState([])
  const [openEditAssignment, setOpenEditAssignment] = React.useState(false)
  const [openPublishPopup, setOpenPublishPopup] = React.useState(false)
  const [openUnPublishPopup, setOpenUnpublishPopup] = React.useState(false)
  const assignmentList = props.assignmentList
  const [addAssignmentMode, setAddAssignmentMode] = React.useState(false)
  const [assignmentCreateData, setAssignmentCreateData] = React.useState({})
  const [showListForm, setShowListForm] = React.useState(true)
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  //FOR FETCHING USER INFO WHO ARE WORKING ON COURSE MODULES CALLBACKS
  const successPostUserInfo = res => { }
  const failurePostUsersInfo = err => { }

  useEffect(() => {
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        url: window.location.href,
        id: props.match.params.courseId,
        type: 'assignments',
        course_id: props.match.params.courseId
      }
      postCourseWorkingUsersInfo(payload, successPostUserInfo, failurePostUsersInfo)
    }
  }, [])


  const onAssignmentClick = assignmentData => {
    if (props.match.params.lessonId) {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}/${props.match.params.moduleId}/${props.match.params.lessonId}/${assignmentData.assignment_id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}/${props.match.params.moduleId}/${props.match.params.lessonId}/${assignmentData.assignment_id}`)
      }
    }
    else if (props.match.params.moduleId) {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}/${props.match.params.moduleId}/${assignmentData.assignment_id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}/${props.match.params.moduleId}/${assignmentData.assignment_id}`)
      }
    }
    else if (props.match.params.courseId) {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}/${assignmentData.assignment_id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}/${assignmentData.assignment_id}`)
      }
    }
  }

  const onDeleteTrigger = (data) => {
    setAssignmentData(data)
    setOpenDeleteConfirm(true)
  }

  const closeDeleteDialog = () => {
    setOpenDeleteConfirm(false)
  }

  const onPreviewTrigger = (data) => {
    setPreviewDetails(data)
    setOpenPreview(true)
  }

  const closeAssignmentPreivew = () => {
    setOpenPreview(false)
    setPreviewDetails([])
  }

  const triggerAssignmentEdit = (data, courseInfo) => {
    setPreviewDetails(data)
    setAssignmentCreateData(courseInfo)
    props.setShowAddAssignmentBtn(false)
    setOpenEditAssignment(true)
  }

  const closeAssignmentEdit = () => {
    setOpenEditAssignment(false)
    props.setShowAddAssignmentBtn(true)
    setPreviewDetails([])
    setAssignmentCreateData({})
  }

  const onPublishTrigger = (data) => {
    setPreviewDetails(data)
    setOpenPublishPopup(true)
  }

  const closePublish = () => {
    setPreviewDetails([])
    setOpenPublishPopup(false)
  }

  const onUnPublishTrigger = (data) => {
    setPreviewDetails(data)
    setOpenUnpublishPopup(true)
  }

  const closeUnpublish = () => {
    setPreviewDetails([])
    setOpenUnpublishPopup(false)
  }

  const onCourseTitleClick = (data, courseId, moduleId) => {
    if (data.lesson_id) {
      if (courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode') {
        props.history.push(`/mycourse/${courseId}/${moduleId}/${data.lesson_id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${courseId}/${moduleId}/${data.lesson_id}`)
      }
    }
    else if (data.module_id) {
      if (courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode') {
        props.history.push(`/mycourse/${courseId}/${data.module_id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${courseId}/${data.module_id}`)
      }
    }
    else {
      if (courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode') {
        props.history.push(`/mycourse/${data.crs_id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${data.crs_id}`)
      }
    }
  }

  const triggerAddAssignment = (data) => {
    setAssignmentCreateData(data)
    setAddAssignmentMode(true)
    setShowListForm(false)
    props.setShowAddAssignmentBtn(false)
  }

  const cancelAddAssignment = () => {
    setAssignmentCreateData({})
    setAddAssignmentMode(false)
    setShowListForm(true)
    props.setShowAddAssignmentBtn(true)
  }
  const dateFormat = (due_days) => {
    return (`Due Date:${convertUTCTimeZone(due_days)}`)
  }
  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>assignments listing view</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {showListForm ? (
            <React.Fragment>
              {assignmentList && !openEditAssignment ? (
                <Paper className={classes.paper}>
                  {courseOpenedMode === 'edit_mode' ? (
                    <div style={{ display: 'flex', marginLeft: '11px' }}>
                      <InfoOutlinedIcon style={{ fontSize: '0.9rem', color: colorStyles.appTextColor, margin: '0.2rem', marginTop: '0.3rem', marginTop: '4px' }} />
                      <div style={{ fontSize: '1rem', color: colorStyles.appTextColor }}>You cannot edit the added assignments but you can add new assignments or unpublish/delete the existing assignments.</div>
                    </div>
                  ) : null}
                  {assignmentList && assignmentList.map((courseData, courseIndex) => (
                    <React.Fragment key={courseIndex}>
                      <Typography className={classes.title} aria-hidden="true">Assignments</Typography>
                      <List className={classes.listItemWrapper}>
                        <ListItem className={Number(props.match.params.courseId) === courseData.crs_id && !props.match.params.moduleId && !props.match.params.lessonId ? classes.selectedTitleHead : classes.titleHead}>
                          {courseOpenedMode !== 'from_mycourses' ? (
                            <Link component="button" onClick={() => { onCourseTitleClick(courseData) }} className={classes.titleWrapper}>
                              {courseData && courseData.crs_title && courseData.crs_title.length > 50 ? courseData && courseData.crs_title.substr(0, 45) + '...' : courseData.crs_title && courseData.crs_title}
                              <span style={{ fontSize: '11px' }} aria-hidden="true">{courseData && courseData.level ? `(${courseData.level} Level)` : null}</span>
                            </Link>
                          ) : (
                            <p>{courseData && courseData.crs_title && courseData.crs_title.length > 50 ? courseData && courseData.crs_title.substr(0, 45) + '...' : courseData.crs_title && courseData.crs_title}
                              <span style={{ fontSize: '11px' }} aria-hidden="true">{courseData && courseData.level ? `(${courseData.level} Level)` : null}</span>
                            </p>
                          )}
                          {courseOpenedMode !== 'visit_mode' ? (
                            <React.Fragment>
                              <IconButton aria-label="add course level assignment" onClick={() => { triggerAddAssignment(courseData) }} className={classes.addAssignmentBtn}>
                                <AddCircleIcon style={{ fontSize: 23 }} />
                              </IconButton>
                              <span style={{ marginLeft: '-9px', fontWeight: '500', fontSize: '12px' }} aria-hidden="true">Add Assignment</span>
                            </React.Fragment>
                          ) : null}
                        </ListItem>
                        {courseData && courseData.assignments && courseData.assignments.length === 0 ? (
                          <React.Fragment>
                            <Typography className={classes.assignmentText}>No Assignments added yet</Typography>
                            <Divider aria-hidden="true" />
                          </React.Fragment>
                        ) : null}
                        {courseData && courseData.assignments ? (
                          <React.Fragment>
                            {courseData && courseData.assignments && courseData.assignments.map((courseAssignmentData, index) => (
                              <React.Fragment key={index}>
                                <div className={classes.rowWrapper}>
                                  <Grid container>
                                    <ListItem>
                                      <AssignmentOutlinedIcon className={classes.iconMainWrapper} />
                                      <Grid item sm={6} xs={12} md={9} lg={9}>
                                        {courseAssignmentData.published === true ? (
                                          <Link component="button" onClick={() => { onAssignmentClick(courseAssignmentData) }} style={{ textDecoration: 'none', cursor: 'pointer', fontWeight: '500' }}>
                                            {courseAssignmentData.title ? courseAssignmentData.title : null}
                                          </Link>
                                        ) :
                                          (
                                            <React.Fragment>
                                              {courseAssignmentData.title ? courseAssignmentData.title : null}
                                            </React.Fragment>
                                          )
                                        }
                                        {courseAssignmentData.published === true ? (
                                          <Tooltip title="Published">
                                            <CloudDoneIcon style={{ fontSize: '21px', marginLeft: '11px', color: '#4caf50' }} />
                                          </Tooltip>
                                        ) : null}
                                      </Grid>
                                      <Grid item sm={6} xs={12} md={3} lg={3}>
                                        <div>
                                          <Tooltip title="Preview">
                                            <IconButton onClick={() => { onPreviewTrigger(courseAssignmentData) }} className={classes.iconButtonWrapper}>
                                              <VisibilityIcon style={{ fontSize: 17 }} />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Edit">
                                            <IconButton disabled={courseAssignmentData.published || courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode'} onClick={() => { triggerAssignmentEdit(courseAssignmentData, courseData) }} className={classes.iconButtonWrapper}>
                                              <EditIcon style={{ fontSize: 17 }} />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Delete">
                                            <IconButton disabled={courseOpenedMode === 'visit_mode'} onClick={() => { onDeleteTrigger(courseAssignmentData) }} className={classes.iconButtonWrapper} >
                                              <DeleteIcon style={{ fontSize: 17 }} />
                                            </IconButton>
                                          </Tooltip>
                                          {courseAssignmentData.published === false ? (
                                            <Tooltip title="Publish">
                                              <IconButton disabled={courseOpenedMode === 'visit_mode'} onClick={() => { onPublishTrigger(courseAssignmentData) }} className={classes.iconButtonWrapper}>
                                                <PublishIcon style={{ fontSize: 17 }} />
                                              </IconButton>
                                            </Tooltip>
                                          ) : (
                                            <Tooltip title="Unpublish">
                                              <IconButton onClick={() => { onUnPublishTrigger(courseAssignmentData) }} disabled={courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode'} className={classes.iconButtonWrapper}>
                                                <BlockIcon style={{ fontSize: 17 }} />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </div>
                                      </Grid>
                                    </ListItem>
                                    <p className={classes.dueDateWrapper}>
                                      {dateFormat(courseAssignmentData.end_date)} {courseAssignmentData.points ? (` | ${courseAssignmentData.points} pts`) : null}
                                    </p>
                                  </Grid>
                                  <Divider aria-hidden="true" />
                                </div>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : null}
                        <Grid container>
                          {courseData.modules ? (
                            <React.Fragment>
                              {courseData.modules && courseData.modules.map((moduleData, moduleIndex) => (
                                <React.Fragment key={moduleIndex}>
                                  <Grid item sm={1} xs={1} md={1} lg={1}></Grid>
                                  <Grid item sm={11} xs={11} md={11} lg={11}>
                                    {moduleData.assignments ? (
                                      <ListItem className={Number(props.match.params.moduleId) == moduleData.module_id && !props.match.params.lessonId ? classes.selectedTitleHead : classes.titleHead}>
                                        {courseOpenedMode !== 'from_mycourses' ? (
                                          <Link component="button" onClick={() => { onCourseTitleClick(moduleData, courseData.crs_id) }} className={classes.titleWrapper}>
                                            {moduleData.module_title && moduleData.module_title.length > 50 ? moduleData.module_title.substr(0, 45) + '...' : moduleData.module_title && moduleData.module_title}
                                            <span style={{ fontSize: '11px' }} aria-hidden="true">{moduleData && moduleData.level ? `(${moduleData.level} Level)` : null}</span>
                                          </Link>
                                        ) : (
                                          <p>{moduleData.module_title && moduleData.module_title.length > 50 ? moduleData.module_title.substr(0, 45) + '...' : moduleData.module_title && moduleData.module_title}
                                            <span style={{ fontSize: '11px' }} aria-hidden="true">{moduleData && moduleData.level ? `(${moduleData.level} Level)` : null}</span>
                                          </p>
                                        )}
                                        {courseOpenedMode !== 'visit_mode' ? (
                                          <React.Fragment>
                                            <IconButton aria-label="add module level assignment" onClick={() => { triggerAddAssignment(moduleData) }} className={classes.addAssignmentBtn}>
                                              <AddCircleIcon style={{ fontSize: 23 }} />
                                            </IconButton>
                                            <span style={{ marginLeft: '-9px', fontWeight: '500', fontSize: '12px' }} aria-hidden="true">Add Assignment</span>
                                          </React.Fragment>
                                        ) : null}
                                      </ListItem>
                                    ) : null}
                                    {moduleData && moduleData.assignments.length === 0 ? (
                                      <React.Fragment>
                                        <Typography className={classes.assignmentText}>No Assignments added yet</Typography>
                                        <Divider aria-hidden="true" />
                                      </React.Fragment>
                                    ) : null}
                                    {moduleData.assignments ? (
                                      <React.Fragment>
                                        {moduleData.assignments && moduleData.assignments.map((moduleAssignmentData, assignmentIndex) => (
                                          <div className={classes.rowWrapper} key={assignmentIndex}>
                                            <React.Fragment>
                                              <Grid container>
                                                <ListItem>
                                                  <AssignmentOutlinedIcon className={classes.iconMainWrapper} />
                                                  <Grid item sm={6} xs={12} md={9} lg={9}>
                                                    {moduleAssignmentData.published === true ? (
                                                      <Link component="button" onClick={() => { onAssignmentClick(moduleAssignmentData) }} style={{ textDecoration: 'none', cursor: 'pointer', fontWeight: '500' }}>
                                                        {moduleAssignmentData.title ? moduleAssignmentData.title : null}
                                                      </Link>
                                                    ) :
                                                      (
                                                        <React.Fragment>
                                                          {moduleAssignmentData.title ? moduleAssignmentData.title : null}
                                                        </React.Fragment>
                                                      )
                                                    }
                                                    {moduleAssignmentData.published === true ? (
                                                      <Tooltip title="Published">
                                                        <CloudDoneIcon style={{ fontSize: '21px', marginLeft: '11px', color: '#4caf50' }} />
                                                      </Tooltip>
                                                    ) : null}
                                                  </Grid>
                                                  <Grid item sm={6} xs={12} md={3} lg={3}>
                                                    <Tooltip title="Preview">
                                                      <IconButton onClick={() => { onPreviewTrigger(moduleAssignmentData) }} className={classes.iconButtonWrapper}>
                                                        <VisibilityIcon style={{ fontSize: 17 }} />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Edit">
                                                      <IconButton disabled={moduleAssignmentData.published || courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode'} onClick={() => { triggerAssignmentEdit(moduleAssignmentData, moduleData) }} className={classes.iconButtonWrapper}>
                                                        <EditIcon style={{ fontSize: 17 }} />
                                                      </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                      <IconButton disabled={courseOpenedMode === 'visit_mode'} onClick={() => { onDeleteTrigger(moduleAssignmentData) }} className={classes.iconButtonWrapper}>
                                                        <DeleteIcon style={{ fontSize: 17 }} />
                                                      </IconButton>
                                                    </Tooltip>
                                                    {moduleAssignmentData.published === false ? (
                                                      <Tooltip title="Publish">
                                                        <IconButton disabled={courseOpenedMode === 'visit_mode'} onClick={() => { onPublishTrigger(moduleAssignmentData) }} className={classes.iconButtonWrapper}>
                                                          <PublishIcon style={{ fontSize: 17 }} />
                                                        </IconButton>
                                                      </Tooltip>
                                                    ) : (
                                                      <Tooltip title="Unpublish">
                                                        <IconButton onClick={() => { onUnPublishTrigger(moduleAssignmentData) }} disabled={courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode'} className={classes.iconButtonWrapper}>
                                                          <BlockIcon style={{ fontSize: 17 }} />
                                                        </IconButton>
                                                      </Tooltip>
                                                    )}
                                                  </Grid>
                                                </ListItem>
                                                <p className={classes.dueDateWrapper}>
                                                  {dateFormat(moduleAssignmentData.end_date)} {moduleAssignmentData.points ? (` | ${moduleAssignmentData.points} pts`) : null}
                                                </p>
                                              </Grid>
                                              <Divider aria-hidden="true" />
                                            </React.Fragment>
                                          </div>
                                        ))}
                                      </React.Fragment>
                                    ) : null}
                                  </Grid>
                                  {moduleData.lessons ? (
                                    <React.Fragment>
                                      {moduleData.lessons && moduleData.lessons.map((lessonData, lessonIndex) => (
                                        <React.Fragment key={lessonIndex}>
                                          <Grid item sm={2} xs={2} md={2} lg={2}></Grid>
                                          <Grid item sm={10} xs={10} md={10} lg={10}>
                                            {lessonData && lessonData.assignments ? (
                                              <ListItem className={Number(props.match.params.lessonId) == lessonData.lesson_id ? classes.selectedTitleHead : classes.titleHead}>
                                                {courseOpenedMode !== 'from_mycourses' ? (
                                                  <Link component="button" onClick={() => { onCourseTitleClick(lessonData, courseData.crs_id, moduleData.module_id) }} className={classes.titleWrapper}>
                                                    {lessonData.lesson_title.length > 50 ? lessonData.lesson_title.substr(0, 45) : lessonData.lesson_title}
                                                    <span style={{ fontSize: '11px' }} aria-hidden="true">{lessonData && lessonData.level ? `(${lessonData.level} Level)` : null}</span>
                                                  </Link>
                                                ) : (
                                                  <p>{lessonData.lesson_title.length > 50 ? lessonData.lesson_title.substr(0, 45) : lessonData.lesson_title}
                                                    <span style={{ fontSize: '11px' }} aria-hidden="true">{lessonData && lessonData.level ? `(${lessonData.level} Level)` : null}</span>
                                                  </p>
                                                )}
                                                {courseOpenedMode !== 'visit_mode' ? (
                                                  <React.Fragment>
                                                    <IconButton aria-label="add lesson level assignment" onClick={() => { triggerAddAssignment(lessonData) }} className={classes.addAssignmentBtn}>
                                                      <AddCircleIcon style={{ fontSize: 23 }} />
                                                    </IconButton>
                                                    <span style={{ marginLeft: '-9px', fontWeight: '500', fontSize: '12px' }} aria-hidden="true" >Add Assignment</span>
                                                  </React.Fragment>
                                                ) : null}
                                              </ListItem>
                                            ) : null}
                                            {lessonData && lessonData.assignments.length === 0 ? (
                                              <React.Fragment>
                                                <Typography className={classes.assignmentText}>No Assignments added yet</Typography>
                                                <Divider aria-hidden="true" />
                                              </React.Fragment>
                                            ) : null}
                                            {lessonData.assignments ? (
                                              <React.Fragment>
                                                {lessonData.assignments && lessonData.assignments.map((lessonAssignmentData, index) => (
                                                  <div className={classes.rowWrapper} key={index}>
                                                    <React.Fragment>
                                                      <Grid container>
                                                        <ListItem>
                                                          <AssignmentOutlinedIcon className={classes.iconMainWrapper} />
                                                          <Grid item sm={6} xs={12} md={9} lg={9}>
                                                            {lessonAssignmentData.published === true ? (
                                                              <Link component="button" onClick={() => { onAssignmentClick(lessonAssignmentData) }} style={{ textDecoration: 'none', cursor: 'pointer', fontWeight: '500' }}>
                                                                {lessonAssignmentData.title ? lessonAssignmentData.title : null}
                                                              </Link>
                                                            ) :
                                                              (
                                                                <React.Fragment>
                                                                  {lessonAssignmentData.title ? lessonAssignmentData.title : null}
                                                                </React.Fragment>
                                                              )
                                                            }
                                                            {lessonAssignmentData.published === true ? (
                                                              <Tooltip title="Published">
                                                                <CloudDoneIcon style={{ fontSize: '21px', marginLeft: '11px', color: '#4caf50' }} />
                                                              </Tooltip>
                                                            ) : null}
                                                          </Grid>
                                                          <Grid item sm={6} xs={12} md={3} lg={3}>
                                                            <Tooltip title="Preview">
                                                              <IconButton onClick={() => { onPreviewTrigger(lessonAssignmentData) }} className={classes.iconButtonWrapper}>
                                                                <VisibilityIcon style={{ fontSize: 17 }} />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit">
                                                              <IconButton disabled={lessonAssignmentData.published || courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode'} onClick={() => { triggerAssignmentEdit(lessonAssignmentData, lessonData) }} className={classes.iconButtonWrapper}>
                                                                <EditIcon style={{ fontSize: 17 }} />
                                                              </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                              <IconButton disabled={courseOpenedMode === 'visit_mode'} onClick={() => { onDeleteTrigger(lessonAssignmentData) }} className={classes.iconButtonWrapper}>
                                                                <DeleteIcon style={{ fontSize: 17 }} />
                                                              </IconButton>
                                                            </Tooltip>
                                                            {lessonAssignmentData.published === false ? (
                                                              <Tooltip title="Publish">
                                                                <IconButton disabled={courseOpenedMode === 'visit_mode'} onClick={() => { onPublishTrigger(lessonAssignmentData) }} className={classes.iconButtonWrapper}>
                                                                  <PublishIcon style={{ fontSize: 17 }} />
                                                                </IconButton>
                                                              </Tooltip>
                                                            ) : (
                                                              <Tooltip title="Unpublish">
                                                                <IconButton onClick={() => { onUnPublishTrigger(lessonAssignmentData) }} disabled={courseOpenedMode === 'edit_mode' || courseOpenedMode === 'visit_mode'} className={classes.iconButtonWrapper}>
                                                                  <BlockIcon style={{ fontSize: 17 }} />
                                                                </IconButton>
                                                              </Tooltip>
                                                            )}
                                                          </Grid>
                                                        </ListItem>
                                                        <p className={classes.dueDateWrapper}>
                                                          {dateFormat(lessonAssignmentData.end_date)} {lessonAssignmentData.points ? (`| ${lessonAssignmentData.points} pts`) : null}
                                                        </p>
                                                      </Grid>
                                                      <Divider aria-hidden="true" />
                                                    </React.Fragment>
                                                  </div>
                                                ))}
                                              </React.Fragment>
                                            ) : null}
                                          </Grid>
                                        </React.Fragment>
                                      ))}
                                    </React.Fragment>
                                  ) : null}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ) : null}
                        </Grid>
                      </List>
                    </React.Fragment>
                  ))}
                </Paper>
              ) : null}
            </React.Fragment>
          ) : null}
        </Grid>
      </Grid>
      {addAssignmentMode ? (
        <div style={{ marginTop: '-100px' }}>
          <AddAssignment
            match={props.match}
            history={props.history}
            cancelAddAssignment={cancelAddAssignment}
            listAllAssignment={props.listAllAssignment}
            assignmentCreateData={assignmentCreateData}
          />
        </div>
      ) : null}
      {openDeleteConfirm ? (
        <DeleteAssignment
          history={props.history}
          match={props.match}
          assignmentData={assignmentData}
          openDeleteConfirm={openDeleteConfirm}
          closeDeleteDialog={closeDeleteDialog}
          listAllAssignment={props.listAllAssignment}
        />
      ) : null}
      {openPreview ? (
        <AssignmentPreview
          openPreview={openPreview}
          closeAssignmentPreivew={closeAssignmentPreivew}
          previewDetails={previewDetails}
        />
      ) : null}
      {openEditAssignment ? (
        <div style={{ marginTop: '-100px' }}>
          <AddAssignment
            listAllAssignment={props.listAllAssignment}
            closeAssignmentEdit={closeAssignmentEdit}
            history={props.history}
            match={props.match}
            assignmentDetails={previewDetails}
            assignmentCreateData={assignmentCreateData}
          />
        </div>
      ) : null}
      {openPublishPopup ? (
        <AssignmentPublish
          match={props.match}
          history={props.history}
          listAllAssignment={props.listAllAssignment}
          assignmentDetails={previewDetails}
          openPublishPopup={openPublishPopup}
          closePublish={closePublish}
        />
      ) : null}
      {openUnPublishPopup ? (
        <AssignmentPublish
          match={props.match}
          history={props.history}
          listAllAssignment={props.listAllAssignment}
          assignmentDetails={previewDetails}
          openMode='unPublish'
          openUnPublishPopup={openUnPublishPopup}
          closeUnpublish={closeUnpublish}
        />
      ) : null}
    </div>
  );
}
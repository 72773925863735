import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'

export const loadAllGroup = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.fetchAllGroup}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const sendNoticeMessage = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.sendNotice}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchSentNotices = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.fetchSentNotices}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const fetchMyNotices = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.fetchMyNotices}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const updateViewNoticeStatus = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.updateViewNoticeStatus}${payload.notice_id}/`
  let newPayload = { ...payload }
  delete newPayload.notice_id
  axios.get(url, {
    params: newPayload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
export const fetchHeaderNoticeCount = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.fetchHeaderNoticeCount}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const deleteNotice = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.deleteNotice}`
  axios.delete(url, {
    data: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      //failureCallBack(error)
      successCallBack()
    })
}

export const updateNoticeMessage = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.noticeBoard.updateNotice}`
  axios.put(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LeftMenu from '../AppLayout/leftMenu'
import AssessmentList from './assessmentList'
import ModuleLevelAssessment from './moduleAssessment'
import LessonLevelAssessment from './lessonAssessment'

function AddModuleInput(props){
    const renderingAssessMentLevel = () => {
      if(props.match.params && props.match.params.lessonId){
        return(
          <LessonLevelAssessment match={props.match} history={props.history}/>
        )
      }
      else if(props.match.params && props.match.params.moduleId){
        return(
          <ModuleLevelAssessment match={props.match} history={props.history} />
        )
      }
      else if(props.match.params && props.match.params.courseId){
        return(
          <AssessmentList match={props.match} history={props.history} />
        )
      }
    }
    return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
       <div>
        {renderingAssessMentLevel()}
       </div>
    </React.Fragment>
    );
}
const mapStateToProps = state => {
   return {}
 }

 export default connect(
   mapStateToProps, {}
 )(AddModuleInput);

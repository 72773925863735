import axios from 'axios'
import { apiConfig } from '../../Configs/apiConfig'
//import { mockGroupsListing, mockTabCounts, mockStudentsData, mockMentorsData, mockInstructorsData, mockSingleGrpData, mockSingleGrpDataAssigned, mockGroupStructure, mockMentorStudentsList, mockOrgGrpsList } from './mockData'

//FOR CREATING GROUP
export const createGroup = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.createGroup}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING ALL THE CREATED GRPS LIST
export const fetchGroupsList = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchGroupsList}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING SINLGE GRP DATA FETCH
export const fetchSingleGrpData = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchSingleGrpData}${payload.group_id}/`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR UPDATING GROUP
export const updateGroup = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.updateGroup}${payLoad.id}/`
  axios.put(url, payLoad)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING USERDATA TAB COUNTS
export const fetchMemberDataListTabCnt = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchUsersDataTabCnt}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING ALL THE STUDENTS LIST, Mentor list, Instructor list
export const fetchOrgMembersListing = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchOrgMembersListing}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR SUBMITTING SELECTED MEMBER IDS
export const submitGrpMembers = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.submitGrpMembers}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING ENTIRE GRP STRUCTURE WITH MEMBERS
export const fetchGrpStructure = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchGrpStructure}${payload.group_id}/`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR ASSIGNING MENTOR TO STUDENT
export const assignMentorToStudent = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.assignMentorToStudent}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING STUDENTS UNDER MENTOR LIST
export const fetchMentorStudentsList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchMentorStudentsList}${payload.mentor_id}/`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR ASSIGNING students TO mentor
export const assignStudentsToMentor = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.assignStudentsToMentor}`
  axios.post(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR FETCHING ENTIRE ORG GRPS LIST STRUCTURE FOR DROPDOWN SELECTION
export const fetchOrgGrpsList = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.manageGroups.fetchOrgGrpsList}`
  axios.get(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
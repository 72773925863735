import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TextEditor from '../Common/TextEditor';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
//LOCAL IMPORTS
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { bytesToSize, getDomainInfo } from '../../Helpers/basic';
import { createAssignment, updateAssignment, createExternalAssignment, updateExternalAssignment } from '../../store/Assignments/actionCreator'
import AlertMessages from '../Common/AlertMessages';
import { ERROR_CREATE_ASSIGNMENT, ERROR_UPDATE_ASSIGNMENT } from '../Common/Constants/errorMessages';
import { SUCCESS_ASSIGNMENT_CREATE, SUCCESS_ASSIGNMENT_UPDATE } from '../Common/Constants/successMessages'
import { apiConfig } from '../../Configs/apiConfig'
import DocsLibrarySelectionPopup from '../Common/OpeningDialogView/docsLibrarySelection';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-84px'
  },
  label: {
    marginTop: 15,
    fontSize: '1.5rem'
  },
  field: {
    marginTop: 30,
    backgroundColor: '#f5f5f5'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 231,
    marginTop: '-15px',
    marginLeft: '20px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  uploadIcon: {
    color: '#2e8eec',
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedAttachement: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  erromMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    width: 62,
    border: '2px solid #ccc',
  },
  departmentWrapper: {
    margin: theme.spacing(1),
    minWidth: 231,
    marginTop: '1px',
    marginLeft: '20px'
  },
  dateWrapper: {
    marginLeft: '13px',
  },
  readingsButton: {
    float: 'right',
    marginBottom: '10px',
    marginLeft: '10px',
    marginTop: '15px',
    color: '#3f51b5'
  },
  helpertext: {
    lineHeight: '6%',
    marginLeft: '-8px',
    fontSize: '11px'
  },
  datePicker: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
  endDateWrapper: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
  button: {
    flexGrow: 1,
    marginRight: '7px',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  },
}));

function AddAssignment(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const [inProgress, setInProgress] = React.useState(false);

  const assignmentCreateData = props.assignmentCreateData

  const defaultTitle = props.assignmentDetails && props.assignmentDetails.title ? props.assignmentDetails.title : ''
  const [assignmentTitle, setAssignmentTitle] = React.useState(defaultTitle)

  const defaultPoints = props.assignmentDetails && props.assignmentDetails.points ? props.assignmentDetails.points : ''
  const [assignmentPoints, setAssignmentPoints] = React.useState(defaultPoints)

  const defaultDesc = props.assignmentDetails && props.assignmentDetails.desc ? props.assignmentDetails.desc : ''
  const [assignmentDesc, setAssignmentDesc] = React.useState(defaultDesc)

  const [assignmentFileDetails, setAssignmentFileDetails] = React.useState({});
  const defaultFile = props.assignmentDetails && props.assignmentDetails.attachment ? props.assignmentDetails.attachment : null
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState(defaultFile)

  const defaultStartDate = props.assignmentDetails && props.assignmentDetails.start_date ? new Date(props.assignmentDetails.start_date) : ''
  const [startDate, setStartDate] = React.useState(defaultStartDate);

  const defaulEndDate = props.assignmentDetails && props.assignmentDetails.end_date ? new Date(props.assignmentDetails.end_date) : ''
  const [endDate, setEndDate] = React.useState(defaulEndDate);

  const [formFieldStatus, setFormFieldStatus] = React.useState({
    assignmentTitle: { error: false },
    assignmentDesc: { error: false },
    points: { error: false },
    startdate: { error: false },
    enddate: { error: false }
  });

  const values = {
    assignmentTitle: props.assignmentDetails && props.assignmentDetails.title ? props.assignmentDetails.title : '',
    assignmentDesc: props.assignmentDetails && props.assignmentDetails.desc ? props.assignmentDetails.desc : '',
    assignmentPoints: props.assignmentDetails && props.assignmentDetails.points ? props.assignmentDetails.points : '',
    assignmentFile: props.assignmentDetails && props.assignmentDetails.attachment ? props.assignmentDetails.attachment : null,
    assignmentFileName: props.assignmentDetails && props.assignmentDetails.attachment_filename ? props.assignmentDetails.attachment_file_name : '',
    assignmentStartDate: props.assignmentDetails && props.assignmentDetails.start_date ? new Date(props.assignmentDetails.start_date) : '',
    assignmentEndDate: props.assignmentDetails && props.assignmentDetails.end_date ? new Date(props.assignmentDetails.end_date) : ''
  }

  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })

  //FOR SELECTING THE FILES FROM DOCS LIBRARY
  const [openDocsLibrary, setOpenDocsLibrary] = React.useState({
    type: '',
    dialog: false
  })

  const handleChangeTitle = evnt => {
    setAssignmentTitle(evnt.target.value)
    setFormFieldStatus(
      {
        assignmentTitle: {
          error: false
        },
        assignmentDesc: {
          error: false
        },
        points: {
          error: false
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }
  const handleChangePoints = evnt => {
    setAssignmentPoints(evnt.target.value)
  }

  const onChangeEditorContent = data => {
    setAssignmentDesc(data)
    setFormFieldStatus(
      {
        assignmentTitle: {
          error: false
        },
        assignmentDesc: {
          error: false
        },
        points: {
          error: false
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'assignmentFile' && file.size > apiConfig.assignment.acceptedAttachmentFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'assignmentFile') {
            return apiConfig.assignment.acceptedAssignmentFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const fileExtension = file && file.name && file.name.lastIndexOf('.')
        const currentExtension = file && file.name && file.name.slice(fileExtension) && file.name.slice(fileExtension).split('.')[1] && file.name.slice(fileExtension).split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }

  const onAttachmentUpload = event => {
    const attachment = validatedFile('assignmentFile')
    setAssignmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const successCreateAssignment = res => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ASSIGNMENT_CREATE,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    props.listAllAssignment()
    props.cancelAddAssignment()
  }
  const failureCreateAssignment = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_CREATE_ASSIGNMENT,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const updateSuccessCallBack = () => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ASSIGNMENT_UPDATE,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    props.closeAssignmentEdit()
    props.listAllAssignment()
  }

  const updateFailureCallBack = () => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UPDATE_ASSIGNMENT,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const initiateAssignment = evnt => {
    evnt.preventDefault()

    let isNewUploadedFile = true
    if (assignmentFileDetails.fileError) {
      isNewUploadedFile = false
    } else if (assignmentFileDetails.fileError === false && !assignmentFileDetails.name) {
      isNewUploadedFile = false
    }

    let startDateValid = true
    if (startDate) {
      startDateValid = true
    } else if (props.assignmentDetails && props.assignmentDetails.start_date) {
      startDateValid = true
    }

    let endDateValid = true
    if (endDate) {
      endDateValid = true
    } else if (props.assignmentDetails && props.assignmentDetails.end_date) {
      endDateValid = true
    }

    //if(!assignmentTitle || !assignmentDesc || !startDate || !endDate || !startDateValid || !endDateValid || !assignmentPoints || !isNewUploadedFile ){
    if (!assignmentTitle) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: true
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: false
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Assignment Title!'
      })
    } else if (!assignmentDesc) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: true
          },
          points: {
            error: false
          },
          startdate: {
            error: false
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Assignment Description!'
      })
    } else if (assignmentPoints !== '' && assignmentPoints < 1 || assignmentPoints > 100) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: true
          },
          startdate: {
            error: false
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Valid Points!'
      })
    } else if (!startDate) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: true
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Assignment StartDate!'
      })
    } else if (!startDateValid) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: true
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Assignment StartDate!'
      })
    } else if (!endDate) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: false
          },
          enddate: {
            error: true
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Assignment EndDate!'
      })
    } else if (!endDateValid) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: false
          },
          enddate: {
            error: true
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Add Assignment EndDate!'
      })
    } else if (startDate > endDate) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: true
          },
          enddate: {
            error: true
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'End Date Should Be Greater Than Start Date!'
      })
    } else if (!isNewUploadedFile) {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: false
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: true,
        errorMessage: 'Please Upload Attachment!'
      })
    } else {
      setFormFieldStatus(
        {
          assignmentTitle: {
            error: false
          },
          assignmentDesc: {
            error: false
          },
          points: {
            error: false
          },
          startdate: {
            error: false
          },
          enddate: {
            error: false
          }
        }
      )
      setValidationError({
        error: false,
        errorMessage: ''
      })
      var startDateFormat = new Date(startDate);
      var endDateFormat = new Date(endDate)
      var startDateFrmt = startDateFormat.toISOString();
      var endDateFrmt = endDateFormat.toISOString();
      setInProgress(true)
      if (assignmentCreateData && assignmentCreateData.lesson_id) {
        let formData = new FormData()
        formData.append('lesson_id', assignmentCreateData.lesson_id ? assignmentCreateData.lesson_id : null)
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null
          }
          updateAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      }
      else if (assignmentCreateData && assignmentCreateData.module_id) {
        let formData = new FormData()
        formData.append('module_id', assignmentCreateData.module_id ? assignmentCreateData.module_id : null)
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null
          }
          updateAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      }
      else if (assignmentCreateData && assignmentCreateData.crs_id) {
        let formData = new FormData()
        formData.append('course_id', assignmentCreateData.crs_id ? assignmentCreateData.crs_id : null)
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null
          }
          updateAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      }
      else if (props.match.params.lessonId) {
        let formData = new FormData()
        formData.append('lesson_id', props.match.params.lessonId ? props.match.params.lessonId : null)
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null
          }
          updateAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      }
      else if (props.match.params.moduleId) {
        let formData = new FormData()
        formData.append('module_id', props.match.params.moduleId ? props.match.params.moduleId : null)
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null
          }
          updateAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      } else if (props.match.params.courseId) {
        let formData = new FormData()
        formData.append('course_id', props.match.params.courseId ? props.match.params.courseId : null)
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null
          }
          updateAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      } else if (props && props.from === 'externalType') {
        let formData = new FormData()
        formData.append('title', assignmentTitle)
        formData.append('desc', assignmentDesc)
        formData.append('points', assignmentPoints ? assignmentPoints : null)
        formData.append('attachment', assignmentFileDetails.file ? assignmentFileDetails.file : null)
        formData.append('start_date', startDateFrmt)
        formData.append('end_date', endDateFrmt)

        if (assignmentFileDetails && assignmentFileDetails.doc_id) {
          formData.append('doc_id', assignmentFileDetails && assignmentFileDetails.doc_id)
        }

        if (props.assignmentDetails) {
          const payload = {
            assignment_id: props.assignmentDetails && props.assignmentDetails.id ? props.assignmentDetails.id : null
          }
          updateExternalAssignment(payload, formData, updateSuccessCallBack, updateFailureCallBack)
        } else {
          createExternalAssignment(formData, successCreateAssignment, failureCreateAssignment)
        }
      }
    }
  }

  const closeTrigger = () => {
    if (props.assignmentDetails) {
      props.closeAssignmentEdit()
    } else {
      props.cancelAddAssignment()
    }
  }

  //FOR LINKING THE FILE FROM DOCS LIBRARY
  const linkAFileDocsLibrary = (type) => {
    setOpenDocsLibrary({
      type: type,
      dialog: true
    })
  }

  //ON SELECTION FROM DOCS LIBRARY
  const onSelecteDocsLibraryFile = (data, fileType) => {
    if (fileType === 'other_docs') {
      let fileDetails = {
        fileError: false,
        fileErrorErrorMsg: '',
        name: data && data.original_filename && data && data.original_filename,
        type: 'application/pdf',
        file: data && data.doc_url,
        doc_id: data && data.id && data.id
      }
      setAssignmentFileDetails(fileDetails)
      setUploadedAttachmentPreview(data && data.doc_url)
    }
  }

  //FOR CLOSING THE DOCS LIBRARY SELECTION POPUP
  const closeDocsLibrayPopup = () => {
    setOpenDocsLibrary({
      type: '',
      dialog: false
    })
  }

  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Add assignment form</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            {validationError.error ? (
              <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block' }}>
                <AlertMessages status="error" message={validationError.errorMessage} />
              </div>
            ) : null}
            <form noValidate autoComplete="off" onSubmit={initiateAssignment}>
              <TextField
                id="title"
                label="Assignment title"
                helperText="max: 100 chars"
                required
                error={formFieldStatus.assignmentTitle.error}
                defaultValue={values.assignmentTitle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
                inputProps={{
                  maxLength: 100,
                  className: classes.field
                }}
                autoFocus={true}
                style={{
                  width: '100%'
                }}
                onChange={handleChangeTitle}
              />
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Typography style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px' }}>
                  About this Assignment
                  <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                </Typography>
                <TextEditor onChangeEditorContent={onChangeEditorContent} helperText={'About Assignment'} description={values.assignmentDesc} />
              </div>
              <div style={{ marginTop: '33px', marginBottom: '20px', textAlign: 'initial' }}>
                <TextField
                  id="assignmentpoints"
                  label="Possible Points"
                  type="number"
                  variant="outlined"
                  helperText="max: 100 points"
                  error={formFieldStatus.points.error}
                  defaultValue={values.assignmentPoints}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  onChange={handleChangePoints}
                />
              </div>
              <div style={{ marginTop: '33px', marginBottom: '20px', textAlign: 'initial' }}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormHelperText >Start Date *</FormHelperText>
                    <DatePicker
                      aria-label="select assignment start date"
                      className={classes.datePicker}
                      selected={startDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      onChange={date => setStartDate(date)}
                      minDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Click to select a date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormHelperText >End Date *</FormHelperText>
                    <DatePicker
                      aria-label="select assignment end date"
                      className={classes.endDateWrapper}
                      selected={endDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      onChange={date => setEndDate(date)}
                      minDate={new Date()}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Click to select a date"
                    />
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginBottom: '91px' }}>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={4} sm={4}>
                      {assignmentFileDetails.fileError ? (
                        <Typography variant="caption" display="block" gutterBottom className={classes.erromMsg}>
                          {assignmentFileDetails.fileErrorErrorMessage}
                        </Typography>
                      ) : null}
                      <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                        <span className={classes.uploadLabel} aria-hidden="true">
                          Upload Attachment:
                        </span>
                        <Tooltip title="Upload from Computer">
                          <IconButton aria-label="upload attachment" onClick={() => { onFileToUploadClick('assignmentFile') }}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                          </IconButton>
                        </Tooltip>
                        {domainInfo.hasMyAthinaCorpAcc && domainInfo.enableDocsLibraryUpload ? (
                          <React.Fragment>
                            <span style={{ marginLeft: '7px', marginRight: '7px' }}>or</span>
                            <Tooltip title="Upload from Docs Library">
                              <IconButton aria-label="Upload video" onClick={() => { linkAFileDocsLibrary('other_docs') }}>
                                <InsertLinkIcon className={classes.uploadIcon} />
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                        ) : null}
                      </Typography>
                      <div style={{ color: '#999', fontSize: '12px', textAlign: 'left', marginTop: '-11px' }}>max.size: 10MB, format: pdf, docx, ppt</div>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ paddingTop: '16px', textAlign: 'left' }}>
                      {assignmentFileDetails && assignmentFileDetails.name && uploadedAttachmentPreview ? (
                        <React.Fragment>
                          <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginLeft: '-14px' }} />
                          <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                            {assignmentFileDetails && assignmentFileDetails.name && assignmentFileDetails.name.length > 30 ? (
                              assignmentFileDetails.name.substr(0, 25) + '....'
                            ) :
                              assignmentFileDetails && assignmentFileDetails.name && assignmentFileDetails.name
                            }
                          </a>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.assignmentDetails && values.assignmentFile ? (
                            <React.Fragment>
                              <Tooltip title="Attachment">
                                <a href={values.assignmentFile} target='_blank' edge="start" color="primary" className={classes.readingsButton} aria-label="close">
                                  {values.assignmentFile.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ marginTop: '-9px' }} /> : values.assignmentFile.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ marginTop: '-9px' }} /> : <DescriptionIcon style={{ marginTop: '-9px' }} />}
                                </a>
                              </Tooltip>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      )}
                    </Grid>
                    <input type="file" accept=".ppt,.pdf,.docx" style={{ display: 'none' }} id="assignmentFile" onChange={onAttachmentUpload} />
                  </Grid>
                </Grid>
              </div>
              <Button variant="contained" className={classes.button} onClick={initiateAssignment} disabled={inProgress}>
                {inProgress ? (
                  <CircularProgress size={30} style={{ marginRight: 10 }} />
                ) : null}
                {props.assignmentDetails ? 'Update Assignment' : 'Create Assignment'}
              </Button>
              <Button variant="contained" disabled={inProgress} onClick={closeTrigger}>Cancel</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {openDocsLibrary.dialog &&
        <DocsLibrarySelectionPopup
          title={assignmentTitle}
          data={openDocsLibrary}
          close={closeDocsLibrayPopup}
          onSelecteDocsLibraryFile={onSelecteDocsLibraryFile}
        />
      }
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AddAssignment);

import AnsrLogo from '../static/ansrLogo.png'
import GoniyoLogo from '../static/goniyo_logo.png'
import CeLogo from '../static/ceLogo.png'
import ZopSmartLogo from '../static/zopsmart_logo.png'
import FalabellaLogo from '../static/falabella_logo.png'
import GmuLogo from '../static/gmulogo.jpg'
import rmblLogo from "../static/rmbl_logo_md.png";
import caseysLogo from "../static/caseys.svg";
import freoLogo from "../static/freo.svg";

export const clientImgMap = {
  ansr: {
    img: AnsrLogo
  },
  ANSR: {
    img: AnsrLogo
  },
  ansrsource: {
    img: AnsrLogo
  },
  ANSRSOURCE: {
    img: AnsrLogo
  },
  goniyo: {
    img: GoniyoLogo
  },
  GONIYO: {
    img: GoniyoLogo
  },
  CE: {
    img: CeLogo
  },
  ZS: {
    img: ZopSmartLogo
  },
  ce: {
    img: CeLogo
  },
  sandbox: {
    img: CeLogo
  },
  testce: {
    img: CeLogo
  },
  TESTCE: {
    img: CeLogo
  },
  abcuniversity: {
    img: GmuLogo
  },
  falabella: {
    img: FalabellaLogo
  },
  rmbl: {
    img: rmblLogo
  },
  caseys: {
    img: caseysLogo
  },
  freo: {
    img: freoLogo
  }
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { CSVLink } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress'
import { ERROR_FETCH_COURSE_DETAILS, ERROR_FETCHING_STUDENT_SUBMISSIONS, ERROR_FETCH_GRADE_BOOK, ERROR_UPDATE_GRADE, ERROR_FETCHING_STUDENT_RESPONSES } from '../Common/Constants/errorMessages';
import { SUCCESS_UPDATE_GRADE } from '../Common/Constants/successMessages';
import Header from './header'
import Pagination from './pagination'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { fetchGradeBook, fetchStudentSubmissions } from '../../store/GradeBook/actionCreator';
import { fetchStudentResponses } from '../../store/Discussions/actionCreator';
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator'
import GradePopper from './gradePopper'
import StudentReport from './studentReport'
import AssessmentReport from './assessmentReport'
import ExportButton from './exportButton'
import AssignmentReport from '../Assignment/studentReportpopup'
import DiscussionReport from './discussionReport'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  studentInfo: {
    backgroundColor: '#f5f5f5',
    zIndex: 3
  },
  assessmentInfo: {
    backgroundColor: '#eceff1',
    // left: 164,
    // position: 'sticky'
  },
  assessmentTotoal: {
    backgroundColor: '#cfd8dc',
    // color: '#f5f5f5'
  },
  assignmentInfo: {
    backgroundColor: '#eceff1',
    // left: 164,
    // position: 'sticky'
  },
  assignmentTotal: {
    backgroundColor: '#cfd8dc',
    // color: '#f5f5f5'
  },
  discussionTotal: {
    backgroundColor: '#cfd8dc'
  },
  overallGrade: {
    backgroundColor: '#b2dfdb',
    position: 'sticky'
  },
  tableContainer: {
    display: 'inline-flex',
    maxHeight: 440,
    width: '90%',
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  selectedGradeBox: {
    backgroundColor: '#07635b',
    color: '#fff'
  },
  buttonProgress: {
    marginTop: '3px',
    marginRight: '16px',
    color: '#2e8eec'
  },
}));

function GradeList(props) {
  const classes = useStyles();
  const [courseData, setCourseData] = React.useState({});
  const [exportData, setExportData] = React.useState([])

  const [openReportEditPopup, setOpenReportEditPopup] = React.useState(false)
  const [assignmentDetails, setAssignmentDetails] = React.useState({})
  const [discussionReportPopup, setDiscussionReportPopup] = React.useState(false)
  const [discussionDetails, setDiscussionDetails] = React.useState({})
  const [discussionStudentResponses, setStudentDiscussionResponses] = React.useState({})
  const [completedUserList, setCompletedList] = React.useState([])
  const [studentSubmissionsDetails, setStudentSubmissonDetails] = React.useState({})
  const [reportLoader, setReportLoader] = React.useState(false);
  const [totalUsersCount, setTotalUsersCount] = React.useState(0);

  let today = new Date();
  let time = today.getHours() + ":" + today.getMinutes();
  let dateTime = today.toDateString() + ' ' + time;
  const [sortingObj, setSortingObj] = React.useState({
    orderBy: 'manual_calculated',
    order: 'desc'
  });
  const [inProgress, setInProgress] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const rowsPerPage = 15
  const [gradeBook, setGradeBook] = React.useState({
    assessments: [],
    assignments: [],
    discussions: [],
    grade_list: []
  })
  const [gradingObj, setGradingObj] = React.useState({
    open: false,
    courseId: props.match.params.courseId,
    user_id: '',
    graderElement: null,
    grade: 0,
    remarks: ''
  })
  const [studentReport, setStudentReport] = React.useState({
    open: false,
    studentData: {}
  })
  const [selectedAssessment, setSelectedAssessment] = React.useState({
    open: false,
    assessmentId: '',
    studentData: {}
  })

  const clearStudentReport = () => {
    setStudentReport({
      open: false,
      studentData: {}
    })
  }
  const clearGrader = () => {
    setGradingObj({
      open: false,
      courseId: props.match.params.courseId,
      user_id: '',
      graderElement: null,
      grade: 0,
      remarks: ''
    })
  }
  const clearSelectedAssessment = () => {
    setSelectedAssessment({
      open: false,
      assessmentId: '',
      studentData: {}
    })
  }
  const courseSuccessCallBack = res => {
    setCourseData(res.results)
  }
  const courseFailureCallBack = error => {
    setCourseData({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_COURSE_DETAILS,
        type: 'error'
      }
    )
  }
  const listGradeSuccessCallback = res => {
    setInProgress(false)
    setGradeBook(res.results)
    setTotalUsersCount(res && res.results && res.results.total_count)
  }
  const listGradeFailureCallback = error => {
    setGradeBook({})
    setInProgress(false)
    setTotalUsersCount(0)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_GRADE_BOOK,
        type: 'error'
      }
    )
  }
  const listGrade = (pageNum) => {
    setInProgress(true)
    fetchGradeBook(
      {
        course_id: props.match.params.courseId,
        order_by: sortingObj.orderBy,
        order: sortingObj.order,
        number: rowsPerPage,
        offset: pageNum * rowsPerPage,
      },
      listGradeSuccessCallback,
      listGradeFailureCallback
    )
  }
  useEffect(() => {
    courseDetailsFetch({
      id: props.match.params.courseId
    }, courseSuccessCallBack, courseFailureCallBack)
    listGrade(0)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  const textTrim = (str, charLength) => {
    if (str.length > charLength) {
      return str.substr(0, charLength) + '...'
    } else {
      return str
    }
  }
  const onPagination = pageNum => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    listGrade(pageNum)
  }
  const sortBy = field => {
    setSortingObj({
      orderBy: field,
      order: sortingObj.order === 'desc' ? 'asc' : 'desc'
    })
    listGrade(0)
  }
  const triggerGradder = gradeObj => {
    setGradingObj({
      open: true,
      course_id: props.match.params.courseId,
      user_id: gradeObj.user_id,
      graderElement: document.getElementById(`manualGrade_${gradeObj.user_id}`),
      grade: gradeObj.manual_overall_grade,
      remarks: gradeObj.overall_remark
    })
  }
  const updateGradeSuccessCallBack = res => {
    clearStudentReport()
    props.showSnackBar(
      {
        state: false,
        message: SUCCESS_UPDATE_GRADE,
        type: 'success'
      }
    )
    clearGrader()
    listGrade(0)
  }
  const updateGradeFailureCallBack = error => {
    clearStudentReport()
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UPDATE_GRADE,
        type: 'error'
      }
    )
    clearGrader()
  }
  const openStudentReport = obj => {
    setStudentReport({
      open: true,
      studentData: {
        assessments: gradeBook.assessments,
        assignments: gradeBook.assignments,
        discussions: gradeBook.discussions,
        gradeList: obj
      }
    })
  }
  const openStudentAssessmentReport = obj => {
    setSelectedAssessment({
      open: true,
      studentData: {
        user_name: obj.user_name,
        user_id: obj.user_id,
      },
      assessmentData: {
        assessment_id: obj.assessment_id,
        assessment_name: obj.assessment_name,
        assessment_grade: obj.assessment_grade
      }
    })
  }

  const listGradeReportSuccessCallback = (response) => {
    let header = ['Student Name', "Email ID"]
    let res = response.results
    res.assessments.forEach((assessment, i) => {
      header.push(assessment.title)
    })
    if (res.assessments.length) {
      header.push('Auto Quiz Grade')
    }
    res.assignments.forEach((assignment, i) => {
      header.push(assignment.title)
    })
    if (res.assignments.length) {
      header.push('Auto Assignment Grade')
    }
    res && res.discussions && res.discussions.forEach((discussion, i) => {
      header.push(discussion.forum_title)
    })
    if (res && res.discussions && res.discussions.length) {
      header.push('Auto Discussion Grade')
    }
    header.push('Automated Grade')
    header.push('Given Grade')
    header.push('Remarks')
    let csvData = [header]
    res.grade_list.forEach((entry, i) => {
      let entryData = [entry.user_name, entry.email]

      // entry.assessment_grade.forEach((assessment, aI) => {
      //   entryData.push(assessment.score_percent)
      // })
      res.assessments.forEach((assessgnment, asI) => {
        entryData.push(entry.assessment_grade[asI] && entry.assessment_grade[asI].score_percent !== null ? entry.assessment_grade[asI].score_percent : 'NA')
      })
      if (res.assessments.length) {
        entryData.push(entry.system_calculated_assessment_percent)
      }

      res.assignments.forEach((assignment, asI) => {
        entryData.push(entry.assignment_grade[asI] && entry.assignment_grade[asI].grade_point !== null ? entry.assignment_grade[asI].grade_point : 'NA')
      })
      if (res.assignments.length) {
        entryData.push(entry.system_calculated_assignment_percent)
      }

      res && res.discussions && res.discussions.forEach((discussion, asI) => {
        entryData.push(entry.discussion_grade[asI] && entry.discussion_grade[asI].grade_point !== null ? entry.discussion_grade[asI].grade_point : 'NA')
      })
      if (res && res.discussions && res.discussions.length) {
        entryData.push(entry.system_calculated_discussion_percent)
      }
      entryData.push(entry.over_all_percent)
      entryData.push(!entry.manual_overall_grade || entry.manual_overall_grade === '' ? 'NA' : entry.manual_overall_grade)
      entryData.push(entry.overall_remark)
      csvData.push(entryData)
    })
    setExportData(csvData)
    setTimeout(() => {
      document.getElementById('exportReport').click()
      setReportLoader(false);
    }, 5000)
  }

  const listGradeReportFailureCallback = (err) => {
    setReportLoader(false);
  }

  const onClickExport = () => {
    setReportLoader(true);
    fetchGradeBook(
      {
        course_id: props.match.params.courseId,
        order_by: sortingObj.orderBy,
        order: sortingObj.order,
        number: totalUsersCount,
        offset: 0 * totalUsersCount,
      },
      listGradeReportSuccessCallback,
      listGradeReportFailureCallback
    )
  }

  const successStudentSubmissonFetch = res => {
    setStudentSubmissonDetails(res.results)
    setOpenReportEditPopup(true)
  }

  const failureStudentSubmissionFetch = error => {
    setStudentSubmissonDetails({})
    setOpenReportEditPopup(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_STUDENT_SUBMISSIONS,
        type: 'error'
      }
    )
  }

  const triggerReportEdit = obj => {
    const payload = {
      assignment_id: obj.assignment_id,
      user_id: obj.user_id
    }
    fetchStudentSubmissions(payload, successStudentSubmissonFetch, failureStudentSubmissionFetch)
    setAssignmentDetails({
      title: obj.assignment_name,
      assignment_id: obj.assignment_id,
      points: obj.grade_points,
      duration_details: obj.days,
      user_id: obj.user_id,
      user_name: obj.user_name,
    })
    setCompletedList([obj])
  }

  const successDiscussionReport = res => {
    setStudentDiscussionResponses(res.results)
    setDiscussionReportPopup(true)
  }
  const failureDiscussionReport = error => {
    setStudentDiscussionResponses({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_STUDENT_RESPONSES,
        type: 'error'
      }
    )
  }
  const openDiscussionReportPopup = obj => {
    const payload = {
      forum_id: obj.discussion_id,
      user_id: obj.user_id
    }
    fetchStudentResponses(payload, successDiscussionReport, failureDiscussionReport)
    setDiscussionDetails({
      title: obj.discussion_name,
      discussion_id: obj.discussion_id,
      points: obj.grade_points,
      user_id: obj.user_id,
      user_name: obj.user_name
    })
  }

  const closeDiscussionReport = () => {
    setDiscussionDetails({})
    setDiscussionReportPopup(false)
    setStudentDiscussionResponses({})
  }

  const closeReportEditMode = () => {
    setAssignmentDetails({})
    setCompletedList([])
    setStudentSubmissonDetails({})
    setOpenReportEditPopup(false)
  }
  return (
    <div>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>GradeBook page</h1>
      </div>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <div style={{ marginBottom: 20 }}>
        <Header courseData={courseData} match={props.match} history={props.history} />
      </div>
      <Container maxWidth="xl">
        {inProgress ? (
          <CircularProgress size={45} style={{ color: '#2e8eec', marginBottom: '-6px' }} />
        ) : (
          <React.Fragment>
            {gradeBook && gradeBook.grade_list && gradeBook.grade_list.length ? (
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} aria-label="Gradebook details">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={2} className={classes.studentInfo}>
                        Student Details
                      </TableCell>
                      {gradeBook.assessments && gradeBook.assessments.length ? (
                        <TableCell align="center" colSpan={gradeBook.assessments.length + 1} className={classes.assessmentTotoal} style={{ left: 164, zIndex: 1 }}>
                          Quiz %
                        </TableCell>
                      ) : null}
                      {gradeBook.assignments && gradeBook.assignments.length ? (
                        <TableCell align="center" colSpan={gradeBook.assignments.length + 1} className={classes.assignmentTotal} >
                          Assignments %
                        </TableCell>
                      ) : null}
                      {gradeBook.discussions && gradeBook.discussions.length ? (
                        <TableCell align="center" colSpan={gradeBook.discussions.length + 1} className={classes.discussionTotal}>
                          Discussions %
                        </TableCell>
                      ) : null}
                      <TableCell align="center" colSpan={2} className={classes.overallGrade} style={{ right: 0 }}>
                        Grade %
                        <div style={{ float: 'right', marginTop: '-10px' }}>
                          {gradeBook && gradeBook.grade_list.length ? (
                            <ExportButton reportLoader={reportLoader} onClickExport={onClickExport} />
                          ) : null}
                        </div>
                        <CSVLink data={exportData} id="exportReport" style={{ display: 'none' }} filename={`Gradebook_MyathinaReport_${dateTime}.csv`}>
                          <GetAppIcon />
                        </CSVLink>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center" className={classes.studentInfo} style={{ top: 57 }}>Student Name</TableCell>
                      <TableCell align="center" className={classes.studentInfo} style={{ top: 57, left: 82 }}>Email ID</TableCell>
                      {gradeBook.assessments && gradeBook.assessments.length ? (
                        <React.Fragment>
                          {gradeBook.assessments.map((assessment, index) => (
                            <React.Fragment key={index}>
                              <TableCell align="center" className={classes.assessmentInfo} style={{ top: 57 }}>
                                {textTrim(assessment.title, 17)}
                              </TableCell>
                            </React.Fragment>
                          ))}
                          <TableCell align="center" className={classes.assessmentTotoal} style={{ top: 57 }}>Auto Quiz Grade</TableCell>
                        </React.Fragment>
                      ) : null}
                      {gradeBook.assignments && gradeBook.assignments.length ? (
                        <React.Fragment>
                          {gradeBook.assignments.map((assignment, index) => (
                            <React.Fragment key={index}>
                              <TableCell align="center" className={classes.assignmentInfo} style={{ top: 57 }}>
                                {textTrim(assignment.title, 17)}
                              </TableCell>
                            </React.Fragment>
                          ))}
                          <TableCell align="center" className={classes.assignmentTotal} style={{ top: 57 }}>Auto Assignment Grade</TableCell>
                        </React.Fragment>
                      ) : null}
                      {gradeBook.discussions && gradeBook.discussions.length ? (
                        <React.Fragment>
                          {gradeBook.discussions.map((discussion, index) => (
                            <React.Fragment key={index}>
                              <TableCell align="center" className={classes.assignmentInfo} style={{ top: 57 }}>
                                {textTrim(discussion.forum_title, 17)}
                              </TableCell>
                            </React.Fragment>
                          ))}
                          <TableCell align="center" className={classes.assignmentTotal} style={{ top: 57 }}>Auto Discussion Grade</TableCell>
                        </React.Fragment>
                      ) : null}
                      <TableCell align="center" className={classes.overallGrade} style={{ top: 57, right: 94 }}>
                        <TableSortLabel
                          active={sortingObj.orderBy === 'system_calculated'}
                          direction={sortingObj.orderBy === 'system_calculated' ? sortingObj.order : 'desc'}
                          onClick={() => { sortBy('system_calculated') }}
                        >
                          Automated
                          {sortingObj.orderBy === 'system_calculated' ? (
                            <span className={classes.visuallyHidden}>
                              {sortingObj.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center" className={classes.overallGrade} style={{ top: 57, right: 0 }}>
                        <TableSortLabel
                          active={sortingObj.orderBy === 'manual_calculated'}
                          direction={sortingObj.orderBy === 'manual_calculated' ? sortingObj.order : 'desc'}
                          onClick={() => { sortBy('manual_calculated') }}
                        >
                          Given
                          {sortingObj.orderBy === 'manual_calculated' ? (
                            <span className={classes.visuallyHidden}>
                              {sortingObj.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gradeBook.grade_list.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" align="center" className={classes.studentInfo} style={{ position: 'sticky', left: 0, zIndex: 2 }}>
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              openStudentReport(row)
                            }}
                          >
                            {textTrim(row.user_name, 10)}
                          </Link>
                        </TableCell>
                        <TableCell align="center" className={classes.studentInfo} width={200} style={{ position: 'sticky', left: 82, zIndex: 2 }}>
                          {row.email}
                        </TableCell>
                        {gradeBook.assessments && gradeBook.assessments.length ? (
                          <React.Fragment>
                            {gradeBook.assessments.map((assessmentsGrade, asIndex) => (
                              <React.Fragment key={asIndex}>
                                <TableCell align="center" className={classes.assessmentInfo}>
                                  {row.assessment_grade[asIndex] && row.assessment_grade[asIndex].score_percent !== null ? (
                                    <Link
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        openStudentAssessmentReport({
                                          assessment_id: assessmentsGrade.assessment_id,
                                          assessment_name: assessmentsGrade.title,
                                          assessment_grade: row.assessment_grade[asIndex].score_percent,
                                          user_id: row.user_id,
                                          user_name: row.user_name,
                                        })
                                      }}
                                    >
                                      {row.assessment_grade[asIndex].score_percent}
                                    </Link>
                                  ) : 'NA'}
                                </TableCell>
                              </React.Fragment>
                            ))}
                            <TableCell align="center" className={classes.assessmentTotoal}>{row.system_calculated_assessment_percent}</TableCell>
                          </React.Fragment>
                        ) : null}
                        {gradeBook.assignments && gradeBook.assignments.length ? (
                          <React.Fragment>
                            {gradeBook.assignments.map((assignmentsGrade, asinIndex) => (
                              <React.Fragment key={asinIndex}>
                                <TableCell align="center" className={classes.assignmentInfo}>
                                  {row.assignment_grade[asinIndex] && row.assignment_grade[asinIndex].grade_point !== null ?
                                    <Link
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        triggerReportEdit({
                                          assignment_name: assignmentsGrade.title,
                                          assignment_grade: row.assignment_grade[asinIndex].grade_point,
                                          assignment_id: assignmentsGrade.assingment_id,
                                          grade_points: assignmentsGrade.grade_point,
                                          days: assignmentsGrade.due_date,
                                          user_id: row.user_id,
                                          user_name: row.user_name,
                                        })
                                      }}
                                    >
                                      {row.assignment_grade[asinIndex].grade_point}
                                    </Link>
                                    : 'NA'}</TableCell>
                              </React.Fragment>
                            ))}
                            <TableCell align="center" className={classes.assignmentTotal}>{row.system_calculated_assignment_percent}</TableCell>
                          </React.Fragment>
                        ) : null}
                        {gradeBook.discussions && gradeBook.discussions.length ? (
                          <React.Fragment>
                            {gradeBook.discussions.map((discussionGrade, asinIndex) => (
                              <React.Fragment key={asinIndex}>
                                <TableCell align="center" className={classes.assignmentInfo}>
                                  {row.discussion_grade[asinIndex] && row.discussion_grade[asinIndex].grade_point !== null ?
                                    <Link
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        openDiscussionReportPopup({
                                          discussion_name: discussionGrade.forum_title,
                                          discussion_grade: row.discussion_grade[asinIndex].grade_point,
                                          discussion_id: discussionGrade.forum_id,
                                          grade_points: discussionGrade.points,
                                          user_id: row.user_id,
                                          user_name: row.user_name,
                                        })
                                      }}
                                    >
                                      {row.discussion_grade[asinIndex].grade_point}
                                    </Link>
                                    : 'NA'}</TableCell>
                              </React.Fragment>
                            ))}
                            <TableCell align="center" className={classes.assignmentTotal}>{row.system_calculated_discussion_percent}</TableCell>
                          </React.Fragment>
                        ) : null}
                        <TableCell align="center" className={classes.overallGrade} style={{ right: 94 }}>{row.over_all_percent}</TableCell>
                        <TableCell align="center" className={classes.overallGrade} style={{ right: 0 }}>
                          <Button
                            id={`manualGrade_${row.user_id}`}
                            aria-describedby={`manualGrade_${row.user_id}`}
                            type="button"
                            onClick={() => { triggerGradder(row) }}
                            className={gradingObj.user_id === `${row.user_id}` ? classes.selectedGradeBox : ''}
                          >
                            {row.manual_overall_grade === '' || !row.manual_overall_grade ? 'NA' : row.manual_overall_grade}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Paper>{inProgress ? 'Loading...' : 'No reports available'}</Paper>
            )}
          </React.Fragment>
        )}

        {!inProgress && gradeBook && gradeBook.total_count > 10 ? (
          <div className={classes.tableContainer}>
            <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={gradeBook.total_count} />
          </div>
        ) : null}
        {gradingObj.open ? (
          <GradePopper
            gradingObj={gradingObj}
            location="left-start"
            onClose={clearGrader}
            onUpdateSuccess={updateGradeSuccessCallBack}
            onUpdateFailure={updateGradeFailureCallBack}
            width="30%"
          />
        ) : null}
      </Container>
      {studentReport.open ? (
        <StudentReport
          open={studentReport.open}
          studentData={studentReport.studentData}
          closeStudentReport={clearStudentReport}
          onGradeUpdateSuccess={updateGradeSuccessCallBack}
          onGradeUpdateFailure={updateGradeFailureCallBack}
          courseId={props.match.params.courseId}
        />
      ) : null}
      {selectedAssessment.open ? (
        <AssessmentReport
          open={selectedAssessment.open}
          data={selectedAssessment}
          onClose={clearSelectedAssessment}
          courseId={props.match.params.courseId}
        />
      ) : null}
      {openReportEditPopup ? (
        <AssignmentReport
          openReportEditPopup={openReportEditPopup}
          closeReportEditMode={closeReportEditMode}
          openMode='editGrade'
          view='gradeBook'
          assignmentDetails={assignmentDetails}
          completedUserList={completedUserList}
          triggerReportEdit={triggerReportEdit}
          studentSubmissions={studentSubmissionsDetails}
        />
      ) : null}
      {discussionReportPopup ? (
        <DiscussionReport
          discussionReportPopup={discussionReportPopup}
          closeDiscussionReport={closeDiscussionReport}
          discussionDetails={discussionDetails}
          discussionStudentResponses={discussionStudentResponses}
        />
      ) : null}
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(GradeList);
import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddModule from './addModule'
import LeftMenu from '../AppLayout/leftMenu'

function AddModuleInput(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      <div>
        <AddModule match={props.match} history={props.history} showRestrictPopup={props.showRestrictPopup} courseMenuSuccessCallBack={props.courseMenuSuccessCallBack} courseModuleDetails={props.courseModuleDetails} triggerAddLessonForm={props.triggerAddLessonForm} courseLeftMenuTrigger={props.courseLeftMenuTrigger} onCourseModuleMenuClick={props.onCourseModuleMenuClick} />
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(AddModuleInput);
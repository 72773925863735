import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link'
import { useMediaQuery } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PublishIcon from '@material-ui/icons/Publish';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import { style } from '../../Styles/theme'
import { convertUTCTimeZone } from '../../Helpers/basic'
import Pagination from './pagination'
import AssignmentPreview from '../Common/OpeningDialogView/assignmentPreview'
import AddAssignment from '../Assignment/addAssignment'
import DeleteAssignment from '../Assignment/deleteConfirm'
import AssignmentPublish from '../Assignment/publishPopup'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      marginTop: '10px',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%'
  },
  titleWrapper: {
    fontSize: '29px',
    fontWeight: '500',
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  button: {
    flexGrow: 1,
    marginRight: '38px',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  },
  iconWrapper: {
    color: style.fontColour._blue,
    fontSize: '23px',
    marginRight: '10px',
  },
  iconButtonWrapper: {
    color: '#2e8eec',
    marginTop: '10px'
  },
  dueDateWrapper: {
    color: '#2e8eec',
    textAlign: 'initial',
    fontSize: '12px',
    marginTop: '3px',
    marginBottom: '-7px'
  },
  dateWrapper: {
    color: '#999',
    textAlign: 'initial',
    fontSize: '12px',
    marginTop: '3px',
    marginBottom: '-7px'
  }
}));

export default function AssignmentsListView(props) {
  const classes = useStyles()
  const maxWidth700 = useMediaQuery('(max-width:700px)');
  const assignmentsData = props && props.assignmentsData && props.assignmentsData.length ? props.assignmentsData : []

  const [openPreview, setOpenPreview] = React.useState(false)
  const [previewDetails, setPreviewDetails] = React.useState([])
  const [openEditAssignment, setOpenEditAssignment] = React.useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
  const [openPublishPopup, setOpenPublishPopup] = React.useState(false)
  const [openUnPublishPopup, setOpenUnpublishPopup] = React.useState(false)

  const dateFormat = (due_days) => {
    return(`Due Date:${convertUTCTimeZone(due_days)}`)
  }

  const onAssignmentClick = assignmentsData => {
    props.history.push(`externalassignments/${assignmentsData.id}`)
  }

  const onPreviewTrigger = (data) => {
    setPreviewDetails(data)
    setOpenPreview(true)
  }
  const closeAssignmentPreivew = () => {
    setOpenPreview(false)
    setPreviewDetails([])
  }

  const triggerAssignmentEdit = (data,courseInfo) => {
    setPreviewDetails(data)
    setOpenEditAssignment(true)
  }
  const closeAssignmentEdit = () => {
    setOpenEditAssignment(false)
    setPreviewDetails([])
  }

  const onDeleteTrigger = (data) => {
    setPreviewDetails(data)
    setOpenDeleteConfirm(true)
  }
  const closeDeleteDialog = () => {
    setOpenDeleteConfirm(false)
    setPreviewDetails([])
  }

  const onPublishTrigger = (data) => {
    setPreviewDetails(data)
    setOpenPublishPopup(true)
  }

  const closePublish = () => {
    setPreviewDetails([])
    setOpenPublishPopup(false)
  }

  const onUnPublishTrigger = (data) => {
    setPreviewDetails(data)
    setOpenUnpublishPopup(true)
  }

  const closeUnpublish = () => {
    setPreviewDetails([])
    setOpenUnpublishPopup(false)
  }

  return(
    <div className={classes.root}>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          { !openEditAssignment ? (
            <Paper className={classes.paper}>
              <Typography className={classes.titleWrapper} aria-hidden="true">Assignments</Typography>
              <Divider style={{ marginBottom: '20px'}} aria-hidden="true"/>
              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <h1>My Assingnments listing view</h1>
              </div>
              { assignmentsData && assignmentsData.length ? (
                <React.Fragment>
                  <List style={{ paddingLeft: !maxWidth700 ? '41px' : '', paddingRight: !maxWidth700 ? '41px' : ''}}>
                    {assignmentsData && assignmentsData.map((data, index)=>(
                      <React.Fragment key={index}>
                        <ListItem>
                          <AssignmentOutlinedIcon className={classes.iconWrapper}/>
                          <Grid item sm={12} xs={12} md={10} lg={10}>
                            { data.published === true ? (
                              <div>
                                <React.Fragment>
                                  <Link component="button" style={{ cursor: 'pointer' }} onClick={() => {onAssignmentClick(data)}}>
                                    <Typography style={{ fontSize: '14px', color: '#2e8eec', fontWeight: 600 }}>{data.title ? data.title : null}</Typography>
                                  </Link>
                                  <Grid container>
                                    <Grid item sm={8} xs={8} md={8} lg={3}>
                                      <p className={classes.dueDateWrapper}>
                                        {dateFormat(data.end_date)} | { data.points } pts
                                      </p>
                                    </Grid>
                                    <Grid item sm={2} xs={2} md={2} lg={2}>
                                      { data.published === true ? (
                                        <Tooltip title="Published">
                                          <CheckCircleIcon style={{ fontSize: '21px', marginLeft: '11px', color: '#4caf50', marginLeft: !maxWidth700 ? '-40px' : ''}}/>
                                        </Tooltip>
                                      ) : null }
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              </div>
                            ) : (
                              <React.Fragment>
                                <Typography style={{ fontSize: '14px', color: '#999', fontWeight: 600 }}>{ data.title ? data.title : null }</Typography>
                                <p className={classes.dateWrapper}>
                                  {dateFormat(data.end_date)} | { data.points } pts
                                </p>
                              </React.Fragment>
                            )}
                          </Grid>
                          <Grid item sm={12} xs={12} md={2} lg={2}>
                            <div>
                              <Tooltip title="Preview">
                                <IconButton onClick={() => {onPreviewTrigger(data)}} className={classes.iconButtonWrapper}>
                                  <VisibilityIcon style={{ fontSize: 17 }}/>
                                </IconButton>
                              </Tooltip>
                              { data.published === false ? (
                                <Tooltip title="Edit">
                                  <IconButton onClick={() => {triggerAssignmentEdit(data)}} className={classes.iconButtonWrapper}>
                                    <EditIcon style={{ fontSize: 17 }}/>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <IconButton disabled={data.published} className={classes.iconButtonWrapper}>
                                  <EditIcon style={{ fontSize: 17 }}/>
                                </IconButton>
                              )}
                              <Tooltip title="Delete">
                                <IconButton onClick={() => {onDeleteTrigger(data)}} className={classes.iconButtonWrapper} >
                                  <DeleteIcon style={{ fontSize: 17 }}/>
                                </IconButton>
                              </Tooltip>
                              { data.published === false ? (
                                <Tooltip title="Publish">
                                  <IconButton onClick={() => {onPublishTrigger(data)}} className={classes.iconButtonWrapper}>
                                    <PublishIcon style={{ fontSize: 17 }}/>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Unpublish">
                                  <IconButton onClick={() => {onUnPublishTrigger(data)}} className={classes.iconButtonWrapper}>
                                    <BlockIcon style={{ fontSize: 17 }}/>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </Grid>
                        </ListItem>
                        <Divider aria-hidden="true"/>
                      </React.Fragment>
                    ))}
                  </List>
                  { props && props.totalCount > 10 ? (
                    <Pagination onPagination={props.onPaginationHit} enableRowsPerPage={false} page={props.page} rowsPerPage={props.rowsPerPage} totalCount={props.totalCount}/>
                  ) : null }
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography style={{ marginTop: '12px', color: '#546e7a'}}>Please click button to create assignment and get started</Typography>
                    <Typography style={{ fontSize: '13px', marginTop: '3px', marginBottom: '21px', color: '#9e9e9e', fontWeight: '500'}}>Assignments you created will show up here.</Typography>
                  </div>
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={props.triggerAddAssignment}
                  >
                    <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd'}} aria-hidden="true"/>
                    Assignment
                  </Button>
                </React.Fragment>
              )}
            </Paper>
          ) : null }
        </Grid>
      </Grid>
      { openPreview ? (
        <AssignmentPreview
          openPreview={openPreview}
          closeAssignmentPreivew={closeAssignmentPreivew}
          previewDetails={previewDetails}
        />
      ) : null }
      { openEditAssignment ? (
        <div style={{ marginTop: '-31px'}}>
          <AddAssignment
            listAllAssignment={props.listAllAssignment}
            closeAssignmentEdit={closeAssignmentEdit} 
            history={props.history} 
            match={props.match} 
            assignmentDetails={previewDetails}
            from='externalType'
          />
        </div>
      ) : null }
      { openDeleteConfirm ? (
        <DeleteAssignment
          history={props.history}
          match={props.match}
          assignmentData={previewDetails}
          openDeleteConfirm={openDeleteConfirm}
          closeDeleteDialog={closeDeleteDialog}
          listAllAssignment={props.listAllAssignment}
          from='externalType'
        />
      ) : null }
      { openPublishPopup ? (
        <AssignmentPublish
          match={props.match}
          history={props.history}
          listAllAssignment={props.listAllAssignment}
          assignmentDetails={previewDetails}
          openPublishPopup={openPublishPopup}
          closePublish={closePublish}
          from='externalType'
        />
      ) : null }
      { openUnPublishPopup ? (
        <AssignmentPublish
          match={props.match}
          history={props.history}
          listAllAssignment={props.listAllAssignment}
          assignmentDetails={previewDetails}
          openMode='unPublish'
          from='externalType'
          openUnPublishPopup={openUnPublishPopup}
          closeUnpublish={closeUnpublish}
        />
      ) : null }
    </div>
  )
}
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';


export const addModule = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addModule.initiateModule}`
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const updateModuleInfo = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addModule.updateModuleInfo}${payload.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchModuleDetails = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addModule.fetchModuleDetails}`
  axios.get(url, {
    params: payLoad
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR DELETING THE UPLOADED READINGS FROM BACKEND
export const deleteUploadedReadings = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addModule.deleteUploadedReadings}`
  axios.delete(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}






import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 50
  },
  panel: {
    backgroundColor: '#f7f7f7'
  },
  table: {
    backgroundColor: '#fff'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '50%',
    flexShrink: 0,
    marginTop: 10
  },
  link: {
    color: 'blue',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  phraseCountWrapper: {
    float: 'left',
    marginRight: 20,
    textAlign: 'center'
  },
  phraseCount: {
    fontWeight: 700,
    marginRight: 20
  },
  label: {
    marginTop: 10
  }
}));

export default function PlagiarismReport(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>PlagiarismReport view</h1>
      </div>
      {props.selectedReportType === 'plagiarized' ? (
        <div>
          {props && props.detailedReports && props.detailedReports.plagiarized && props.detailedReports.plagiarized.length ? (
            <div>
            {props && props.detailedReports && props.detailedReports.plagiarized && props.detailedReports.plagiarized.map((data, index) => (
              <ExpansionPanel key={index} className={classes.panel}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id={`panel_${index}`}
                >
                  <Typography className={classes.heading}>
                    <a className={classes.link} href={data && data.sources && data.sources.link && data.sources.link} target="_blank">{data && data.sources && data.sources.link && data.sources.link}</a>
                  </Typography>
                  <Typography component="h2" className={classes.secondaryHeading}>
                    <div style={{ display: 'inline-block' }}>
                      <span>Phrase count: </span>
                      <span className={classes.phraseCount}>
                        {data && data.sources && data.sources.count && data.sources.count}
                      </span>
                      <span>Plagiarized: </span>
                      <div style={{ display: 'inline-block', height: '3rem', width: '3rem', margin: 'auto' }}>
                        <CircularProgressbar
                          value={data.sources.percent}
                          text={`${data.sources.percent}%`}
                          styles={buildStyles({
                            textColor: "#dd2c00",
                            pathColor: "#ef9a9a",
                            textSize: "25px"
                          })}
                        />
                      </div>
                    </div>
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <TableContainer>
                    <Table className={classes.table} aria-label="plagiarism report">
                      <TableHead>
                        <TableRow>
                          <TableCell>Submitted Solution</TableCell>
                          <TableCell>Content Source</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data && data.details && data.details.map((detail, detIndex) => (
                          <TableRow key={`row_${index}_${detIndex}`}>
                            <TableCell component="th" scope="row">
                              {detail && detail.query && detail.query}
                            </TableCell>
                            <TableCell>
                              {detail && detail.display && detail.display.des}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          {props && props.detailedReports && props.detailedReports.unique && props.detailedReports.unique.length ? (
            <TableContainer>
              <Table className={classes.table} aria-label="plagiarism submissions report">
                <TableHead>
                  <TableRow>
                    <TableCell>Submitted Solution</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props && props.detailedReports && props.detailedReports.unique && props.detailedReports.unique.map((detail, index) => (
                    <TableRow key={`row_${index}`}>
                      <TableCell component="th" scope="row">
                        {detail.query}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import RadioType from '../../static/questionType/radio.png'
import CheckBoxType from '../../static/questionType/checkbox.png'
import TextType from '../../static/questionType/text.png'
import Objective from './objective.js'
import MultiSelect from './multiSelect.js'
import TextAnswer from './textAnswer.js'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'inline-flex',
  },
  button: {
    display: 'block',
    margin: 20,
    '&.selected': {
      backgroundColor: 'cadetblue',
      color: '#fff'
    }
  },
}));
function AssessmentQuestion(props) {
  const questionDetails = props.questionDetails || {}
  const classes = useStyles();
  const [selectedType, setSelectedType] = React.useState(questionDetails.type || '');
  const selectQuestionType = type => {
    setSelectedType(type)
  }
  const renderingObjectTypeLevel = () => {
    if (props.lessonId) {
      return (
        <Objective lessonId={props.lessonId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
    else if (props.moduleId) {
      return (
        <Objective moduleId={props.moduleId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
    else if (props.courseId) {
      return (
        <Objective courseId={props.courseId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    } else if (props.from === 'externalType') {
      return (
        <Objective from={props.from} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
  }

  const renderingCheckboxTypeLevel = () => {
    if (props.lessonId) {
      return (
        <MultiSelect lessonId={props.lessonId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
    else if (props.moduleId) {
      return (
        <MultiSelect moduleId={props.moduleId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
    else if (props.courseId) {
      return (
        <MultiSelect courseId={props.courseId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    } else if (props.from === 'externalType') {
      return (
        <MultiSelect from={props.from} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
  }

  const renderingTextanswerTypeLevel = () => {
    if (props.lessonId) {
      return (
        <TextAnswer lessonId={props.lessonId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
    else if (props.moduleId) {
      return (
        <TextAnswer moduleId={props.moduleId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
    else if (props.courseId) {
      return (
        <TextAnswer courseId={props.courseId} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    } else if (props.from === 'externalType') {
      return (
        <TextAnswer from={props.from} assessmentId={props.assessmentId} onQuestionAction={props.onQuestionAction} questionDetails={questionDetails} />
      )
    }
  }
  return (
    <div>
      {!props.questionDetails ? (
        <Paper>
          <Typography variant="subtitle2" gutterBottom>
            Select question type
          </Typography>
          <div className={classes.root}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button + (selectedType === 'objective' ? ' selected' : '')}
              onClick={() => { selectQuestionType('objective') }}
            >
              <Typography variant="button" display="block" gutterBottom aria-hidden="true">
                Objective type
              </Typography>
              <img src={RadioType} alt="Objective type question" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button + (selectedType === 'checkbox' ? ' selected' : '')}
              onClick={() => { selectQuestionType('checkbox') }}
            >
              <Typography variant="button" display="block" gutterBottom aria-hidden="true">
                Multi selection
              </Typography>
              <img src={CheckBoxType} alt="Multi selection question" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button + (selectedType === 'text' ? ' selected' : '')}
              onClick={() => { selectQuestionType('text') }}
            >
              <Typography variant="button" display="block" gutterBottom aria-hidden="true">
                Short answer
              </Typography>
              <img src={TextType} alt="Short answer question" />
            </Button>
          </div>
        </Paper>
      ) : null}
      {selectedType === 'objective' ? (
        renderingObjectTypeLevel()
      ) : null}
      {selectedType === 'checkbox' ? (
        renderingCheckboxTypeLevel()
      ) : null}
      {selectedType === 'text' ? (
        renderingTextanswerTypeLevel()
      ) : null}
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AssessmentQuestion);

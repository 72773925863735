import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { style } from '../../Styles/theme';
import { Typography } from '@material-ui/core';
import { convertUTCDatewithTime, convertUTCTimeZone } from '../../Helpers/basic'
import { fetchDiscussion } from '../../store/Discussions/actionCreator'
import { ERROR_FETCHING_DISCUSSION } from '../Common/Constants/errorMessages';
import responseIcon from '../../static/responseIcon.png'

const useStyles = makeStyles(theme => ({
  gradeTitle: {
    fontSize: '19px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'center'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  itemInfo: {
    padding: '0.2rem',
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._naviBlue,
    textAlign: 'left',
    lineBreak: 'anywhere'
  },
  discussionDateWrpper:{
    padding: '0.2rem',
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._naviBlue,
    textAlign: 'center',
    lineBreak: 'anywhere'
  },
  descWrapper: {
    textAlign: 'left',
    lineBreak: 'anywhere'
  },
  title: {
    fontSize: '19px',
    fontWeight: '500',
    color: style.fontColour._blue,
    textAlign: 'left',
    lineBreak: 'anywhere'
  },
  repliesWrapper: {
    fontSize: '15px',
    marginBottom: '10px',
    color: style.fontColour._blue,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 11,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '4px 4px',
  },
}))(Badge);

function Report(props) {
  const classes = useStyles();
  const studentResponses = props.discussionStudentResponses ? props.discussionStudentResponses : {}
  const discussionDetails = props.discussionDetails ? props.discussionDetails : {}
  const [discussionData, setDiscussionData] = React.useState({})

  const discussionSuccessCB = res => {
    setDiscussionData(res.results)
  }

  const discussionFailureCB = error => {
    setDiscussionData({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_DISCUSSION,
        type: 'error'
      }
    )
  }

  useEffect(() => {
    const payload = {
        discussion_id: discussionDetails.discussion_id ? discussionDetails.discussion_id : ''
    }
    fetchDiscussion(payload, discussionSuccessCB, discussionFailureCB)
  },[])

  function createMarkup(data) {
    return {
      __html: data
    };
  }
  const dateTimeFormat = (due_days) => {
    return(convertUTCDatewithTime(due_days))
  }
  const dateFormat = (due_days) => {
    return(convertUTCTimeZone(due_days))
  }
  return (
    <Dialog
      open={true}
      onClose={props.closeDiscussionReport}
      fullWidth
      maxWidth='xl'
      scroll='paper'
      aria-label="Discussion Report dialog"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Discussion Report</h1>
      </div>
      <DialogTitle id="scroll-dialog-title" className={classes.gradeTitle}>
        {discussionDetails && discussionDetails.title && discussionDetails.title.length > 95 ? (
          discussionDetails.title.substr(0, 90) + '...'
        ) : (
          discussionDetails && discussionDetails.title && discussionDetails.title
        )}
        <span style={{ float: 'right'}}>
          <div id="mainH1Tag" tabIndex="-1" className="accessibility">
            <p>{`discussion responses count ${studentResponses.reply_count}`}</p>
          </div>
          <Tooltip title={`${studentResponses.reply_count} responses`} aria-hidden="true">
            <StyledBadge badgeContent={studentResponses.reply_count} color='secondary' aria-hidden="true">
              <img src={responseIcon} alt="response count" style={{ height: '24px', marginTop: '11px'}} aria-hidden="true"/>
            </StyledBadge>
          </Tooltip>
        </span>
        <div className={classes.discussionDateWrpper}>Start Date: { discussionData && discussionData.start_date ?  dateFormat( discussionData && discussionData.start_date) : "NA"} &nbsp;&nbsp; Due Date: {dateFormat( discussionData && discussionData.end_date)} &nbsp;&nbsp; { discussionData && discussionData.graded_reply && discussionData.points ? (`Pts: ${discussionData.points}`) : null } &nbsp;&nbsp;</div>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} md={6} lg={6} sm={6}>
            <Typography className={classes.title}>Description</Typography>
            <div className={classes.descWrapper} dangerouslySetInnerHTML={createMarkup( discussionData && discussionData.description)}></div>
            <Typography variant="caption" display="block" className={classes.infoBlock}>
              <p><b>Name:</b> {`${studentResponses.first_name ? studentResponses.first_name : ''} ${studentResponses.last_name ? studentResponses.last_name : ''}`}</p>
            </Typography>
          </Grid>
          { studentResponses.graded ? (
            <Grid item xs={6} md={6} lg={6} sm={6}>
              <Typography variant="caption" display="block" className={classes.gradeTitle}>
                <p><b>Grade Points:</b> {studentResponses.user_score !== null ? studentResponses.user_score : 'NA'}</p>
              </Typography>
            </Grid>
          ) : null }
          <div style={{display:'flex',flexDirection:'column'}}>
            <div style={{display:'flex',flexDirection:'column'}}>
              <Typography variant="caption" className={classes.infoBlock}>
                <b>Responses</b>:
              </Typography>
              <List>
                { studentResponses && studentResponses.user_data && studentResponses.user_data.map((userData, index) => (
                  <ListItem key={index}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <div style={{ textAlign: 'initial', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={createMarkup( userData && userData.reply_data)}></div>
                      <div className={classes.itemInfo}>
                        Submitted Date: {userData.submission_date ? dateTimeFormat(userData.submission_date && userData.submission_date) : '' }
                      </div>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            { studentResponses && studentResponses.user_comment_data && studentResponses.user_comment_data.length ? (
              <div style={{display:'flex',flexDirection:'column'}}>
                <Typography variant="caption" className={classes.infoBlock}>
                  <b>Comments</b>:
                </Typography>
                <List>
                  { studentResponses && studentResponses.user_comment_data && studentResponses.user_comment_data.map((data, index) => (
                    <ListItem key={index}>
                      <div style={{display:'flex', flexDirection:'column'}}>
                        <div className={classes.repliesWrapper}>{` Replied to : ${data.reply_to_first_name ? data.reply_to_first_name : null } ${data.reply_to_last_name ? data.reply_to_last_name : null }`}</div>
                        <div style={{ marginLeft: '30px'}}>
                          <div style={{ lineBreak: 'anywhere'}}>{data.comment ? data.comment : null }</div>
                          <div className={classes.itemInfo}>
                            Submitted Date: {data.comment_sub_date ? dateTimeFormat(data.comment_sub_date && data.comment_sub_date) : '' }
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </div>
            ) : null }
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeDiscussionReport} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(Report);

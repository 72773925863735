export const courseLevel = [
    {
        id: 1,
        name: 'Beginner Level'
    },
    {
        id: 2,
        name: 'Intermediate Level'
    },
    {
        id: 3,
        name: 'Expert Level'
    }
]

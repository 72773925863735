import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
//Local imports
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { createGroup, updateGroup } from '../../store/ManageGroups/actionCreator';
import { SUCCESS_MANAGEGRP_CREATION, SUCCESS_MANAGEGRP_UPDATE } from '../Common/Constants/successMessages';
import { ERROR_MANAGEGRP_CREATION, ERROR_MANAGEGRP_UPDATE } from '../Common/Constants/errorMessages';


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: style.backgroundColor._lightWhite,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '70px',
      paddingRight: '70px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  buttonProgress: {
    marginBottom: '-13px',
    marginRight: '10px',
    color: style.fontColour._blue
  },
}))

function CreateGroup(props) {
  const classes = useStyles()
  const defaultTitle = props && props.editGrpData && props.editGrpData.group_title && props.editGrpData.group_title ? props.editGrpData.group_title : ''
  const [groupTitle, setGroupTitle] = useState(defaultTitle)
  const defaultDesc = props && props.editGrpData && props.editGrpData.group_title && props.editGrpData.group_desc ? props.editGrpData.group_desc : ''
  const [groupDesc, setGroupDesc] = useState(defaultDesc)
  const [inProgress, setInProgress] = useState(false)
  const [formFieldStatus, setFormFieldStatus] = useState({
    groupTitle: { error: false },
    groupDesc: { error: false }
  })

  //title handle change
  const handleChangeTitle = (event) => {
    setGroupTitle(event.target.value)
    setFormFieldStatus({
      groupTitle: { error: false },
      groupDesc: { error: false }
    })
  }
  //desc handle change
  const handleChangeDescription = (event) => {
    setGroupDesc(event.target.value)
    setFormFieldStatus({
      groupTitle: { error: false },
      groupDesc: { error: false }
    })
  }

  //close create/update view
  const cancelTrigger = () => {
    props.closeAddGrpMode()
  }

  //GRP CREATION CALLBACKS 
  const successCreateGrp = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: SUCCESS_MANAGEGRP_CREATION,
      type: 'success'
    })
    props.getGroupsListing()
    cancelTrigger()
  }
  const failureCreateGrp = err => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: ERROR_MANAGEGRP_CREATION,
      type: 'error'
    })
  }

  //FOR UPDATE CALLBACKS
  const successUpdateGrp = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: SUCCESS_MANAGEGRP_UPDATE,
      type: 'success'
    })
    props.getGroupsListing()
    cancelTrigger()
  }
  const failureUpdateGrp = err => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: ERROR_MANAGEGRP_UPDATE,
      type: 'error'
    })
  }

  //ON SUBMIT TRIGGER for adding/updating grp
  const submitTrigger = (event) => {
    event.preventDefault()
    if (groupTitle === '') {
      setFormFieldStatus({
        groupTitle: { error: true },
        groupDesc: { error: false }
      })
    } else if (groupDesc === '') {
      setFormFieldStatus({
        groupTitle: { error: false },
        groupDesc: { error: true }
      })
    } else {
      setInProgress(true)
      setFormFieldStatus({
        groupTitle: { error: false },
        groupDesc: { error: false }
      })
      if (props && props.editGrpData && Object.keys(props.editGrpData) && Object.keys(props.editGrpData).length) {
        const payload = {
          title: groupTitle,
          desc: groupDesc,
          id: props && props.editGrpData && props.editGrpData.id
        }
        updateGroup(payload, successUpdateGrp, failureUpdateGrp)
      } else {
        const payload = {
          title: groupTitle,
          desc: groupDesc
        }
        createGroup(payload, successCreateGrp, failureCreateGrp)
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{ justifyContent: 'center' }}>
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
          <Paper className={classes.paper}>
            <Typography className={classes.titleWrapper} aria-hidden="true">{props && props.editGrpData && Object.keys(props.editGrpData) && Object.keys(props.editGrpData).length ? 'Update Group' : 'Create Group'}</Typography>
            <Divider style={{ marginBottom: '20px' }} aria-hidden="true" />
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="title"
                    placeholder="Group Title *"
                    helperText="max: 100 chars"
                    required
                    variant="outlined"
                    value={groupTitle}
                    error={formFieldStatus.groupTitle.error}
                    inputProps={{
                      maxLength: 100
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: !formFieldStatus.groupTitle.error ? classes.searchBoxOutline : ''
                      }
                    }}
                    autoFocus={true}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeTitle}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="desc"
                    placeholder="Group Description *"
                    helperText="max: 250 chars"
                    variant="outlined"
                    required
                    multiline
                    value={groupDesc}
                    error={formFieldStatus.groupDesc.error}
                    rows={4}
                    InputProps={{
                      classes: {
                        notchedOutline: !formFieldStatus.groupDesc.error ? classes.searchBoxOutline : ''
                      }
                    }}
                    inputProps={{
                      maxLength: 250
                    }}
                    style={{
                      width: '100%'
                    }}
                    onChange={handleChangeDescription}
                  />
                </Grid>
              </Grid>
              {inProgress && (
                <CircularProgress size={30} className={classes.buttonProgress} />
              )}
              <Button variant="contained" disabled={inProgress} className={classes.button} onClick={(event) => { submitTrigger(event) }}>{props && props.editGrpData && Object.keys(props.editGrpData) && Object.keys(props.editGrpData).length ? 'Update' : 'Create'}</Button>
              <Button variant="contained" disabled={inProgress} className={classes.button} onClick={(event) => { cancelTrigger(event) }}>Cancel</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(CreateGroup);
import React from 'react';
import Skeleton from 'react-skeleton-loader';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

export default function CalenderSiderListLoader(props) {
  return (
    <div style={{ marginTop: '20px' }}>
      <Grid container spacing={2}>
        {props.type === 'viewNotice' ? (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '13px' }}>
              <Skeleton widthRandomness={0} height="159px" width="100%" color="#ccc" />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton widthRandomness={0} height="60px" width="90%" color="#ccc" />
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton widthRandomness={0} height="60px" width="90%" color="#ccc" />
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton widthRandomness={0} height="60px" width="90%" color="#ccc" />
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton widthRandomness={0} height="60px" width="90%" color="#ccc" />
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Skeleton widthRandomness={0} height="60px" width="90%" color="#ccc" />
              <Divider />
            </Grid>
            {props.type === 'calender' ? (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Skeleton widthRandomness={0} height="60px" width="90%" color="#ccc" />
                <Divider />
              </Grid>
            ) : null}
          </React.Fragment>
        )}
      </Grid>
    </div>
  )
}
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import AlertMessages from '../Common/AlertMessages';
import { addQuestion } from '../../store/Assessments/actionCreator'
import { ERROR_ADD_QUESTION } from '../Common/Constants/errorMessages';
import { SUCCESS_ADD_QUESTION } from '../Common/Constants/successMessages';
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    border: '3px solid #f5f5f5',
    marginTop: 20
  },
  form: {
    '& .MuiTextField-root': {
      margin: 20,
    },
  },
  field: {
    width: '90%',
    '&.option': {
      width: '40%',
      margin: 0
    }
  },
  duplicate: {
    color: 'red',
    marginRight: 10,
    display: 'none',
    '&.show': {
      display: 'inline-block'
    }
  },
  addOptionButton: {
    textAlign: 'left',
    marginLeft: 50
  }

}));

function TextAnswer(props) {
  const questionDetails = props.questionDetails
  const classes = useStyles();
  const [inProgress, setInProgress] = React.useState(false)
  const [validationStatus, setValidationStatus] = React.useState({
    isValid: true,
    errorMessages: '',
    isDuplicateOption: false
  });
  const courseOpenedMode = getCourseOpenModeType();

  const successCallBack = res => {
    setInProgress(false)
    props.onQuestionAction()
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ADD_QUESTION,
        type: 'success'
      }
    )
  }
  const failureCallBack = error => {
    setInProgress(false)
    props.onQuestionAction()
    props.showSnackBar(
      {
        state: true,
        message: ERROR_ADD_QUESTION,
        type: 'error'
      }
    )
  }

  const triggerAddQuestion = evnt => {
    evnt.preventDefault()
    setValidationStatus({
      isValid: true,
      errorMessages: '',
    })
    const question = document.getElementById('question').value
    const answer = document.getElementById('answer').value

    if (question === '' || answer === '') {
      setValidationStatus({
        isValid: false,
        errorMessages: 'Please fill all required fields!',
      })
      return
    }
    setInProgress(true)
    if (props.lessonId) {
      const payload = {
        lesson_id: Number(props.lessonId),
        assessment_id: props.assessmentId,
        type: 'text',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        obj_answer: answer,
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    }
    else if (props.moduleId) {
      const payload = {
        module_id: Number(props.moduleId),
        assessment_id: props.assessmentId,
        type: 'text',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        obj_answer: answer,
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    }
    else if (props.courseId) {
      const payload = {
        course_id: Number(props.courseId),
        assessment_id: props.assessmentId,
        type: 'text',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        obj_answer: answer,
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    } else if (props && props.from === 'externalType') {
      const payload = {
        assessment_id: props.assessmentId,
        type: 'text',
        scenario: document.getElementById('scenario').value,
        question: document.getElementById('question').value,
        obj_answer: answer,
      }
      if (questionDetails.id) {
        payload.id = questionDetails.id
      }
      addQuestion(payload, successCallBack, failureCallBack)
    }
  }
  return (
    <Paper elevation={0} className={classes.root}>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={triggerAddQuestion}>
        {!validationStatus.isValid ? (
          <div style={{ marginTop: '20px', width: '42%', marginBottom: '18px', display: 'inline-block' }}>
            <AlertMessages status="error" message={validationStatus.errorMessages} />
          </div>
        ) : null}
        <TextField
          id="scenario"
          label="Scenario"
          aria-label="Add Question scenario"
          defaultValue={questionDetails.scenario || ''}
          multiline
          rows="4"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 1000 }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText="max: 1000 chars"
          autoFocus={true}
        />
        <TextField
          id="question"
          defaultValue={questionDetails.question || ''}
          required={true}
          label="Question"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 500 }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText="max: 500 chars"
        />
        <TextField
          id="answer"
          required={true}
          defaultValue={questionDetails.text_answer || ''}
          label="Expected Answer"
          multiline
          rows="4"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 1000 }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText="max: 1000 chars"
        />
        <div>
          {courseOpenedMode !== 'visit_mode' ? (
            <React.Fragment>
              <Button variant="contained" color="primary" onClick={triggerAddQuestion} disabled={inProgress}>
                {inProgress ? (
                  <CircularProgress size={30} style={{ marginRight: 10 }} />
                ) : null}
                {questionDetails.id ? 'Update question' : 'Add question'}
              </Button>
            </React.Fragment>
          ) : null}
        </div>
      </form>
    </Paper>
  )

}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(TextAnswer);

import React from 'react';
import Skeleton from 'react-skeleton-loader';
import Grid from '@material-ui/core/Grid';

export default function CardListingLoader(props) {
  return(
    <div style={{ marginTop: '20px', marginLeft: '10px' }}>
        <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Skeleton widthRandomness={0} height="60px" width="100%" color="#ccc" />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10}>
              <Skeleton widthRandomness={0} count={10} height="20px" width="100%" color="#e0e0e0" />
            </Grid>
        </Grid>
    </div>
  )
}
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import QueueIcon from '@material-ui/icons/Queue';
import EditIcon from '@material-ui/icons/Edit';
import ListItem from '@material-ui/core/ListItem';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LaunchIcon from '@material-ui/icons/Launch';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ForumIcon from '@material-ui/icons/Forum';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RateReviewIcon from '@material-ui/icons/RateReview';
import InitiateCourse from '../InitiateCourse';
import AddModule from '../AddModule';
import AddLesson from '../AddLesson';
import ManageAuthors from '../../Components/ManageAuthors';
import { goToCourseView } from '../../Helpers/courseNavigation';
import { getDomainInfo, redirectTo } from '../../Helpers/basic';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { courseDetailsFetch, fetchCourseLeftMenu } from '../../store/AddingCourse/actionCreator';
import { fetchModuleDetails } from '../../store/AddModule/actionCreator';
import { fetchLessonDetails } from '../../store/AddLesson/actionCreator';
import { ERROR_FETCH_COURSE_DETAILS, ERROR_FETCH_MODULE_DETAILS, ERROR_FETCH_LESSON_DETAILS } from '../Common/Constants/errorMessages';
import DetailsFormLoader from '../Common/skeletonLoaders/detailsFormLoader';
import CoursePublishPopup from './coursePublishPopup';
import SubmitReviewPopup from './submitReviewPopup';
//FOR FINDING THE CO WORKERS OF THIS COURSE
import { postCourseWorkingUsersInfo, fetchCourseWorkingUsersInfo } from '../../store/Authors/actionCreator'
import MultiAuthorWarningPopup from './multiAuthorWarningPopup';
import { getCourseOpenModeType } from '../../Helpers/basic';

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '10px',
    marginTop: '10px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '10px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 1200,
      marginLeft: '40px',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '600px',
    overflow: 'auto',
    paddingBottom: '30px'
  },
  buttons: {
    width: '100%',
    marginTop: '10px',
    textTransform: 'none'
  },
  buttonWrapper: {
    borderRadius: '8px',
    padding: '14px',
    backgroundColor: '#fff',
    fontSize: '15px',
    width: '100%',
    marginTop: '10px',
    textTransform: 'none',
    justifyContent: "flex-start",
    color: '#2e8eec',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#2e8eec',
    },
  },
  iconWrapper: {
    marginRight: '15px',
    fontSize: '29px',
    border: '4px solid #ECEBFD',
    backgroundColor: '#ECEBFD',
    borderRadius: '6px',
  },
  numberWrapper: {
    color: '#2e8eec',
    padding: '4px',
    fontSize: '17px',
    fontWeight: 500,
    marginRight: '7px',
    marginLeft: '-4px',
  },
  courseLeftMenuWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  selectedMenu: {
    backgroundColor: 'red'
  }
}));

function EditCourse(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const [courseIntroDetails, setCourseIntroDetails] = React.useState({})
  const [courseModuleDetails, setCourseModuleDetails] = React.useState({})
  const [courseLessonDetails, setCourseLessonDetails] = React.useState({})
  const [courseLeftMenu, setCourseLeftMenu] = React.useState([])
  const [showCourseEditForm, setShowCourseEditForm] = React.useState(false)
  const [showAddModuleForm, setShowAddModuleForm] = React.useState(false)
  const [editModuleForm, setEditModuleForm] = React.useState(false)
  const [editLessonForm, setEditLessonForm] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false);
  const [showAddLessonForm, setShowAddLessonForm] = React.useState(false);
  const [showManageAuthorsForm, setShowManageAuthorsForm] = React.useState(false);
  const courseId = props.match.params.courseId ? props.match.params.courseId : null
  const moduleId = props.match.params.moduleId ? props.match.params.moduleId : null
  const lessonId = props.match.params.lessonId ? props.match.params.lessonId : null
  const [inProgress, setInProgress] = React.useState(false)
  const [openCoursePublish, setOpenCoursePublishPopup] = React.useState(false)
  const [openSubmitReview, setOpenSubmitReviewPopup] = React.useState(false)
  const [totalInstructorsCount, setTotalInstructorsCount] = React.useState('');
  const [isAuthor, setIsAuthor] = React.useState(false);
  //FOR STORING CURRENTLY WORKING USERS
  const [workingAuthorsInfo, setWorkingAuthorsInfo] = React.useState({})
  const [showRestrictPopup, setShowRestrictPopup] = React.useState({
    open: false,
    userInfo: {}
  });
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  //for asking author to restrct other others
  const [multiAuthorWarningPopup, setShowMultiAuthorWarningPopup] = React.useState(false);

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const courseMenuSuccessCallBack = res => {
    setCourseLeftMenu(res && res.modules)
    setTotalInstructorsCount(res && res.instructors_count - 1)
    setIsAuthor(res && res.is_author)
  }
  const courseMenuFailureCallBack = error => {
    setCourseLeftMenu([])
    setTotalInstructorsCount('')
  }
  //Course details Form callbacks
  const editCourseSuccessCallBack = res => {
    setCourseIntroDetails(res.results)
    setShowCourseEditForm(true)
    setInProgress(false)
    setShowAddModuleForm(false)
    setEditModuleForm(false)
    setEditLessonForm(false)
    setShowAddLessonForm(false)
    setShowManageAuthorsForm(false)
  }
  const editCourseFailureCallBack = error => {
    setInProgress(false)
    setShowCourseEditForm(true)
    setShowAddModuleForm(false)
    setEditModuleForm(false)
    setEditLessonForm(false)
    setShowAddLessonForm(false)
    setShowManageAuthorsForm(false)
    setCourseIntroDetails({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_COURSE_DETAILS,
        type: 'error'
      }
    )
  }
  //module details callbacks
  const editModuleSuccessCallBack = res => {
    setEditModuleForm(false)
    setInProgress(false)
    setCourseModuleDetails(res.results)
    setEditModuleForm(true)
    setShowCourseEditForm(false)
    setShowAddModuleForm(false)
    setShowAddLessonForm(false)
    setShowManageAuthorsForm(false)
    setEditLessonForm(false)
  }
  const editModuleFailureCallBack = error => {
    setInProgress(false)
    setCourseModuleDetails({})
    setEditModuleForm(true)
    setShowCourseEditForm(false)
    setShowAddModuleForm(false)
    setShowAddLessonForm(false)
    setShowManageAuthorsForm(false)
    setEditLessonForm(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_MODULE_DETAILS,
        type: 'error'
      }
    )
  }
  //lesson details callbacks
  const fetchLessonSuccessCallBack = res => {
    setEditLessonForm(false)
    setInProgress(false)
    setCourseLessonDetails(res.results)
    setEditLessonForm(true)
    setEditModuleForm(false)
    setShowCourseEditForm(false)
    setShowAddModuleForm(false)
    setShowManageAuthorsForm(false)
    setShowAddLessonForm(false)
  }
  const fetchLessonFailureCallBack = error => {
    setInProgress(false)
    setCourseLessonDetails({})
    setEditLessonForm(true)
    setEditModuleForm(false)
    setShowCourseEditForm(false)
    setShowAddModuleForm(false)
    setShowManageAuthorsForm(false)
    setShowAddLessonForm(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_LESSON_DETAILS,
        type: 'error'
      }
    )
  }
  const courseLeftMenuTrigger = () => {
    const payload = {
      id: courseId
    }
    fetchCourseLeftMenu(payload, courseMenuSuccessCallBack, courseMenuFailureCallBack)
  }

  //FOR FETCHING USER INFO WHO ARE WORKING ON COURSE MODULES CALLBACKS
  const successPostUserInfo = res => { }
  const failurePostUsersInfo = err => { }

  //FOR POSTING USER INFO WHO ARE WORKING ON COURSE MODULES CALLBACKS
  const successFetchWorkingUserInfo = (res, clickedId, clickedType) => {
    let instructorsCount = ''
    let showAuthorWarnPopup = false
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      setWorkingAuthorsInfo(res.results)
      instructorsCount = res && res.total_instrucotrscount
      showAuthorWarnPopup = res && res.show_warning_popup
      setShowMultiAuthorWarningPopup(false)
      if (res && res.results && res.results.type === 'lesson') {
        setShowRestrictPopup({
          open: true,
          userInfo: res.results
        });
        setEditLessonForm(false)
        setInProgress(false)
        setCourseLessonDetails([])
        setEditLessonForm(true)
        setEditModuleForm(false)
        setShowCourseEditForm(false)
        setShowAddModuleForm(false)
        setShowManageAuthorsForm(false)
        setShowAddLessonForm(false)
      } else if (res && res.results && res.results.type === 'module') {
        setShowRestrictPopup({
          open: true,
          userInfo: res.results
        });
        setEditModuleForm(false)
        setInProgress(false)
        setCourseModuleDetails([])
        setEditModuleForm(true)
        setShowCourseEditForm(false)
        setShowAddModuleForm(false)
        setShowAddLessonForm(false)
        setShowManageAuthorsForm(false)
        setEditLessonForm(false)
      } else if (res && res.results && res.results.type === 'course') {
        setShowRestrictPopup({
          open: true,
          userInfo: res.results
        });
        setCourseIntroDetails([])
        setShowCourseEditForm(true)
        setInProgress(false)
        setShowAddModuleForm(false)
        setEditModuleForm(false)
        setEditLessonForm(false)
        setShowAddLessonForm(false)
        setShowManageAuthorsForm(false)
      }
    } else {
      let payload = {
        url: mainUrl,
        course_id: props.match.params.courseId,
        id: clickedId,
        type: clickedType
      }
      postCourseWorkingUsersInfo(payload, successPostUserInfo, failurePostUsersInfo)
      setWorkingAuthorsInfo({})
      setShowRestrictPopup({
        open: false,
        userInfo: {}
      });

      //for showing information to instructors
      // if (courseOpenedMode === 'edit_mode' && showAuthorWarnPopup && instructorsCount > 1) {
      //   setShowMultiAuthorWarningPopup(true)
      // }
    }
  }
  const failureFetchWorkingUsersInfo = err => {
    setWorkingAuthorsInfo({})
    setShowRestrictPopup({
      open: false,
      userInfo: {}
    });
  }

  const fetchWorkingUsers = (typeId, type) => {
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: typeId ? typeId : props && props.match && props.match.params && props.match.params.lessonId ? props.match.params.lessonId : props.match.params.moduleId ? props.match.params.moduleId : props.match.params.courseId,
        type: type ? type : props && props.match && props.match.params && props.match.params.lessonId ? 'lesson' : props.match.params.moduleId ? 'module' : 'course'
      }
      const typeClickedId = typeId ? typeId : props && props.match && props.match.params && props.match.params.lessonId ? props.match.params.lessonId : props.match.params.moduleId ? props.match.params.moduleId : props.match.params.courseId
      const typeClick = type ? type : props && props.match && props.match.params && props.match.params.lessonId ? 'lesson' : props.match.params.moduleId ? 'module' : 'course'

      fetchCourseWorkingUsersInfo(payload, successFetchWorkingUserInfo, failureFetchWorkingUsersInfo, typeClickedId, typeClick)
    }
  }

  useEffect(() => {
    setInProgress(true)
    courseLeftMenuTrigger()
    if (props.match.params.lessonId) {
      const payload = {
        course_id: courseId,
        module_id: moduleId,
        lesson_id: lessonId,
      }
      if (domainInfo.name === 'rmbl') {
        fetchWorkingUsers(props.match.params.lessonId ? props.match.params.lessonId : null, 'lesson')
      }
      fetchLessonDetails(payload, fetchLessonSuccessCallBack, fetchLessonFailureCallBack)
    }
    else if (props.match.params.moduleId) {
      const payload = {
        course_id: courseId,
        module_id: moduleId
      }
      if (domainInfo.name === 'rmbl') {
        fetchWorkingUsers(props.match.params.moduleId ? props.match.params.moduleId : null, 'module')
      }
      fetchModuleDetails(payload, editModuleSuccessCallBack, editModuleFailureCallBack)
    }
    else if (props.match.params.courseId) {
      const payload = {
        id: courseId
      }
      if (domainInfo.name === 'rmbl') {
        fetchWorkingUsers(props.match.params.courseId ? props.match.params.courseId : null, 'course')
      }
      courseDetailsFetch(payload, editCourseSuccessCallBack, editCourseFailureCallBack)
    }
  }, []);

  //Course Edit Function
  const onClickShowCourseEditForm = () => {
    if (domainInfo.name === 'rmbl') {
      fetchWorkingUsers(props.match.params.courseId ? props.match.params.courseId : null, 'course');
    }
    setInProgress(true)
    const courseId = props.match.params.courseId ? props.match.params.courseId : null
    if (base64EncodeValue) {
      props.history.push(`/mycourse/${courseId}?${base64EncodeValue}`)
    } else {
      props.history.push(`/mycourse/${courseId}`)
    }
    const payload = {
      id: courseId
    }
    courseDetailsFetch(payload, editCourseSuccessCallBack, editCourseFailureCallBack)
  }

  //Add moduel Function
  const onClickShowAddModuleForm = () => {
    setShowAddModuleForm(true)
    setEditLessonForm(false)
    setShowCourseEditForm(false)
    setShowManageAuthorsForm(false)
    setEditModuleForm(false)
    setShowAddLessonForm(false)
    setWorkingAuthorsInfo({})
    setShowRestrictPopup({
      open: false,
      userInfo: {}
    });
  }
  //LeftMenu module click
  const onCourseModuleMenuClick = (data) => {
    if (domainInfo.name === 'rmbl') {
      fetchWorkingUsers(data.module_id, 'module');
    }
    setInProgress(true)
    const payload = {
      course_id: props.match.params.courseId ? props.match.params.courseId : null,
      module_id: data.module_id,
    }
    const moduleId = data.module_id

    if (base64EncodeValue) {
      props.history.push(`/mycourse/${courseId}/${moduleId}?${base64EncodeValue}`)
    } else {
      props.history.push(`/mycourse/${courseId}/${moduleId}`)
    }
    fetchModuleDetails(payload, editModuleSuccessCallBack, editModuleFailureCallBack)
  }

  //leftMenu lesson click
  const onCourseLessonMenuClick = (lessonData, moduleId) => {
    if (domainInfo.name === 'rmbl') {
      fetchWorkingUsers(lessonData.lesson_id, 'lesson');
    }
    setInProgress(true)
    const payload = {
      course_id: props.match.params.courseId ? props.match.params.courseId : null,
      module_id: moduleId ? moduleId : props.match.params.moduleId,
      lesson_id: lessonData.lesson_id ? lessonData.lesson_id : null
    }
    const lessonId = payload.lesson_id
    const moduleLevelId = payload.module_id
    if (base64EncodeValue) {
      props.history.push(`/mycourse/${courseId}/${moduleLevelId}/${lessonId}?${base64EncodeValue}`)
    } else {
      props.history.push(`/mycourse/${courseId}/${moduleLevelId}/${lessonId}`)
    }
    fetchLessonDetails(payload, fetchLessonSuccessCallBack, fetchLessonFailureCallBack)
  }

  const triggerAddLessonForm = () => {
    setShowAddLessonForm(true)
    setShowAddModuleForm(false)
    setShowCourseEditForm(false)
    setEditModuleForm(false)
    setEditLessonForm(false)
    setShowManageAuthorsForm(false)
    setWorkingAuthorsInfo({})
    setShowRestrictPopup({
      open: false,
      userInfo: {}
    });
  }

  //FOR CHECKING THE CO WORKER FOR ASSESSMENTS
  const successCoworkAssessment = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The assessments page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assessments/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assessments/${props.match.params.courseId}`)
      }
    }
  }
  const failureCoworkAssessment = err => { }

  const landToAssessment = () => {
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.courseId,
        type: 'courselevelassessments'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworkAssessment, failureCoworkAssessment)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assessments/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assessments/${props.match.params.courseId}`)
      }
    }
  }

  //FOR CHECKING THE CO WORKER FOR ASSIGNMENT
  const successCoworkAssignMent = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The assignments page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}`)
      }
    }
  }
  const failureCoworkAssignment = err => { }

  const landToAssignment = () => {
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.courseId,
        type: 'assignments'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworkAssignMent, failureCoworkAssignment)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}`)
      }
    }
  }

  const onClickShowCoursePreview = evnt => {
    evnt.preventDefault();
    if (courseIntroDetails && courseIntroDetails.scorm_url) {
      const url = courseIntroDetails && courseIntroDetails.scorm_url && courseIntroDetails.scorm_url;
      redirectTo(url, true)
    } else {
      const courseId = props.match.params.courseId ? props.match.params.courseId : null
      goToCourseView(courseId)
    }
  }

  //for opening manage authors form
  const onClickShowManageAuthors = () => {
    setShowManageAuthorsForm(true)
    setShowAddLessonForm(false)
    setShowAddModuleForm(false)
    setShowCourseEditForm(false)
    setEditModuleForm(false)
    setEditLessonForm(false)
    setWorkingAuthorsInfo({})
    setShowRestrictPopup({
      open: false,
      userInfo: {}
    });
  }
  //for closing manage authors form
  const onCloseManageAuthors = (type, count) => {
    setShowManageAuthorsForm(false)
    onClickShowCourseEditForm()
    if (type === 'successClose') {
      setTotalInstructorsCount(count)
    }
  }

  const openCoursePublishPopup = () => {
    setOpenCoursePublishPopup(true)
  }
  const closeCoursePopup = () => {
    setOpenCoursePublishPopup(false)
  }

  const openSubmitForReviewPopup = () => {
    setOpenSubmitReviewPopup(true)
  }
  const closeSubmitReviewPopup = () => {
    setOpenSubmitReviewPopup(false)
  }

  //FOR CO WORKER DISCUSSION CALLBACKS
  const successCoworDiscussions = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The discussions page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/discussions/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/discussions/${props.match.params.courseId}`)
      }
    }
  }
  const failureCoworkDiscussions = err => { }

  const onClickShowDiscussionBoard = () => {
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.courseId,
        type: 'discussions'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworDiscussions, failureCoworkDiscussions)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/discussions/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/discussions/${props.match.params.courseId}`)
      }
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <a className="skip-main" href="#leftMenu">
            Back to main menu
          </a>
          <Paper className={classes.paper}>
            <Button className={classes.buttonWrapper} variant="contained" onClick={onClickShowCourseEditForm}>
              <EditIcon className={classes.iconWrapper} /> Course Information
            </Button>
            <div className={classes.courseLeftMenuWrapper}>
              {courseLeftMenu ? (
                <React.Fragment>
                  <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                    <p>Modules under course</p>
                    {window.scrollTo(0, 0)}
                  </div>
                  {courseLeftMenu && courseLeftMenu.map((data, index) => (
                    <ExpansionPanel
                      key={index}
                      square
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                    >
                      <ExpansionPanelSummary
                        expandIcon={data.lessons.length ? <ExpandMoreIcon /> : null}
                        aria-controls="content"
                        id="header"
                      >
                        <Link onClick={() => { onCourseModuleMenuClick(data) }} style={{ color: '#2e8eec', fontWeight: 500, fontSize: '14px', textDecoration: 'none' }} >
                          <span className={classes.numberWrapper}>{index + 1}</span>
                          {data && data.module_title && data.module_title.length > 28 ? (
                            <React.Fragment>
                              {data && data.module_title && data.module_title.substr(0, 28) + "..."}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {data && data.module_title && data.module_title}
                            </React.Fragment>
                          )}
                        </Link>
                      </ExpansionPanelSummary>
                      {data && data.lessons && data.lessons.length > 0 ? (
                        <React.Fragment>
                          <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                            <p>Lessons under course</p>
                          </div>
                          {data && data.lessons && data.lessons.map((lessonData, lessonIndex) => (
                            <ListItem key={lessonIndex} >
                              <SubdirectoryArrowRightIcon style={{ color: '#2e8eec', fontSize: '17px', marginLeft: '24px' }} />
                              <Link onClick={() => { onCourseLessonMenuClick(lessonData, data.module_id) }} style={{ color: '#2e88ec', fontWeight: 500, fontSize: '14px', cursor: 'pointer', textDecoration: 'none' }}>
                                <span style={{ color: '#2e8eec', padding: '0.5rem', fontWeight: '500' }}>{lessonIndex + 1}</span>
                                {lessonData && lessonData.lesson_title && lessonData.lesson_title.length > 32 ? (
                                  <React.Fragment>
                                    {lessonData && lessonData.lesson_title && lessonData.lesson_title.substr(0, 28) + "..."}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    {lessonData && lessonData.lesson_title && lessonData.lesson_title}
                                  </React.Fragment>
                                )}
                              </Link>
                            </ListItem>
                          ))}
                        </React.Fragment>
                      ) : null}
                    </ExpansionPanel>
                  ))}
                </React.Fragment>
              ) : null}
            </div>
            {courseOpenedMode !== 'visit_mode' && <Button className={classes.buttonWrapper} variant="contained" onClick={onClickShowAddModuleForm}>
              <QueueIcon className={classes.iconWrapper} /> Module
            </Button>}
            <Button className={classes.buttonWrapper} variant="contained" onClick={landToAssessment}>
              <AssignmentTurnedInIcon className={classes.iconWrapper} /> Quiz
            </Button>
            <Button className={classes.buttonWrapper} variant="contained" onClick={landToAssignment}>
              <AssignmentIcon className={classes.iconWrapper} />Assignment
            </Button>
            {domainInfo.type === 'b2b' ? (
              <Button className={classes.buttonWrapper} variant="contained" onClick={onClickShowDiscussionBoard}>
                <ForumIcon className={classes.iconWrapper} /> Discussions
              </Button>
            ) : null}
            <Button className={classes.buttonWrapper} variant="contained" onClick={onClickShowCoursePreview} >
              <VisibilityIcon className={classes.iconWrapper} /> Course Preview
            </Button>
            {domainInfo.name === "rmbl" ? (
              <React.Fragment>
                {courseOpenedMode !== 'visit_mode' && <Button className={classes.buttonWrapper} variant="contained" onClick={onClickShowManageAuthors} disabled={!isAuthor} >
                  <GroupAddIcon className={classes.iconWrapper} /> {`Manage Instructors ${totalInstructorsCount ? (`( ${totalInstructorsCount} )`) : ''}`}
                </Button>}
              </React.Fragment>
            ) : null}
            {domainInfo.type === 'b2b' ? (
              <React.Fragment>
                {courseOpenedMode !== 'visit_mode' && <Button className={classes.buttonWrapper} variant="contained" onClick={openCoursePublishPopup} disabled={(base64EncodeValue === 'ZWRpdF9jb3Vyc2U=')} >
                  <LaunchIcon className={classes.iconWrapper} /> Course Publish
                </Button>}
              </React.Fragment>
            ) : (
              <Button className={classes.buttonWrapper} variant="contained" onClick={openSubmitForReviewPopup} >
                <RateReviewIcon className={classes.iconWrapper} /> Submit for review
              </Button>
            )}
          </Paper>
        </Grid>
        {!showCourseEditForm && !showAddModuleForm && !showAddLessonForm && !showManageAuthorsForm && !editModuleForm && !editLessonForm && inProgress ? (
          <Grid item xs={12} sm={6} >
            <DetailsFormLoader />
          </Grid>
        ) : null}
        {showRestrictPopup && showRestrictPopup.userInfo.type === 'course' && showRestrictPopup.open ? (
          <Grid item xs={12} sm={6} style={{ marginTop: '-100px' }}>
            <InitiateCourse introDetails={courseIntroDetails} history={props.history} showRestrictPopup={showRestrictPopup} match={props.match} onClickShowAddModuleForm={onClickShowAddModuleForm} courseLeftMenuTrigger={courseLeftMenuTrigger} />
          </Grid>
        ) : (
          <React.Fragment>
            {showCourseEditForm ? (
              <React.Fragment>
                {inProgress ? (
                  <Grid item xs={12} sm={6} >
                    <DetailsFormLoader />
                  </Grid>
                ) :
                  <Grid item xs={12} sm={6} style={{ marginTop: '-100px' }}>
                    <InitiateCourse introDetails={courseIntroDetails} history={props.history} match={props.match} onClickShowAddModuleForm={onClickShowAddModuleForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onClickShowCourseEditForm={onClickShowCourseEditForm} />
                  </Grid>
                }
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
        {showAddModuleForm ? (
          <Grid item xs={12} sm={8}>
            <AddModule match={props.match} history={props.history} triggerAddLessonForm={triggerAddLessonForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onCourseModuleMenuClick={onCourseModuleMenuClick} />
          </Grid>
        ) : null}
        {showRestrictPopup && showRestrictPopup.userInfo.type === 'module' && showRestrictPopup.open ? (
          <Grid item xs={12} sm={8}>
            <AddModule match={props.match} history={props.history} showRestrictPopup={showRestrictPopup} courseModuleDetails={courseModuleDetails} triggerAddLessonForm={triggerAddLessonForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onCourseModuleMenuClick={onCourseModuleMenuClick} />
          </Grid>
        ) : (
          <React.Fragment>
            {editModuleForm ? (
              <React.Fragment>
                {inProgress ? (
                  <Grid item xs={12} sm={6}>
                    <DetailsFormLoader />
                  </Grid>
                ) :
                  <Grid item xs={12} sm={8}>
                    <AddModule match={props.match} history={props.history} courseModuleDetails={courseModuleDetails} triggerAddLessonForm={triggerAddLessonForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onCourseModuleMenuClick={onCourseModuleMenuClick} />
                  </Grid>
                }
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
        {showAddLessonForm ? (
          <Grid item xs={12} sm={8}>
            <AddLesson match={props.match} history={props.history} triggerAddLessonForm={triggerAddLessonForm} onClickShowAddModuleForm={onClickShowAddModuleForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onCourseLessonMenuClick={onCourseLessonMenuClick} />
          </Grid>
        ) : null}
        {showRestrictPopup && showRestrictPopup.userInfo.type === 'lesson' && showRestrictPopup.open ? (
          <Grid item xs={12} sm={8}>
            <AddLesson match={props.match} history={props.history} showRestrictPopup={showRestrictPopup} courseLessonDetails={courseLessonDetails} triggerAddLessonForm={triggerAddLessonForm} onClickShowAddModuleForm={onClickShowAddModuleForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onCourseLessonMenuClick={onCourseLessonMenuClick} />
          </Grid>
        ) : (
          <React.Fragment>
            {editLessonForm ? (
              <React.Fragment>
                {inProgress ? (
                  <Grid item xs={12} sm={8} >
                    <DetailsFormLoader />
                  </Grid>
                ) :
                  <Grid item xs={12} sm={8}>
                    <AddLesson match={props.match} history={props.history} courseLessonDetails={courseLessonDetails} triggerAddLessonForm={triggerAddLessonForm} onClickShowAddModuleForm={onClickShowAddModuleForm} courseLeftMenuTrigger={courseLeftMenuTrigger} onCourseLessonMenuClick={onCourseLessonMenuClick} />
                  </Grid>
                }
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
        {showManageAuthorsForm ? (
          <Grid item xs={12} sm={8}>
            <ManageAuthors onCloseManageAuthors={onCloseManageAuthors} match={props.match} history={props.history} />
          </Grid>
        ) : null}
        {openCoursePublish ? (
          <CoursePublishPopup
            match={props.match}
            history={props.history}
            openCoursePublish={openCoursePublish}
            closeCoursePopup={closeCoursePopup}
          />
        ) : null}
        {openSubmitReview ? (
          <SubmitReviewPopup
            match={props.match}
            history={props.history}
            openSubmitReview={openSubmitReview}
            closeSubmitReviewPopup={closeSubmitReviewPopup}
          />
        ) : null}
        {multiAuthorWarningPopup ? (
          <MultiAuthorWarningPopup match={props.match} history={props.history} open={multiAuthorWarningPopup} />
        ) : null}
      </Grid>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(EditCourse);

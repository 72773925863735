import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Vimeo from '@u-wave/react-vimeo';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VideoPrvDialog(props) {
  const transcriptUrl = props.transcriptFileDetails && props.transcriptFileDetails.file && props.transcriptFileDetails.file.name ? props.transcriptFileDetails.file.name : null
  const openPopup = props.openIntroPrvDialog

  const [startTime, setStartTime] = useState(0.01);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [props])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={props && props.videoUrl && props.videoUrl.includes('blob') ? 'sm' : 'md'}
      open={openPopup}
      onClose={props.closeUploadedIntroVideoPopup}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="title"
      aria-describedby="description"
    >
      <DialogTitle id="alert-dialog-title">Video Preview</DialogTitle>
      <DialogContent>
        {props && props.videoUrl && props.videoUrl.includes('blob') ? (
          <React.Fragment>
            <video width="543" controls>
              <source src={props.videoUrl} type="video/mp4" />
              <track src={transcriptUrl} kind="subtitles" srcLang="en" label="English" />
            </video>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!isError ? <React.Fragment>
              {startTime > 0 && <Vimeo
                video={props.videoUrl}
                onError={() => { setIsError(true) }}
                autoplay={false}
                controls={true}
                responsive={true}
                style={{ justifyContent: 'center', padding: '1rem', backgroundColor: '#e9f6f6' }}
                start={startTime}
              />}
            </React.Fragment> : <div style={{ backgroundColor: '#e9f6f6', textAlign: 'center', padding: '1.5rem' }}><VideocamOffOutlinedIcon style={{ color: '#2e8eec', fontSize: "40px" }} /> <div style={{ color: '#2e8eec', fontSize: '1rem' }} >Oops! Error fetching video.</div><div style={{ color: '#2e8eec', marginTop: '0.3rem', fontSize: '0.9rem' }} >Please try again later.</div></div>}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeUploadedIntroVideoPopup} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(VideoPrvDialog);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GradePopper from './gradePopper';

const useStyles = makeStyles(theme => ({
  subGradePercent: {
    color: '#6ac685',
    fontSize: '1.5rem',
    fontWeight: 600
  },
  heading: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: '1.6',
    letterSpacing: '0.0075em'
  }
}))
export default function StudentReport(props) {
  const classes = useStyles();
  const gradeList = props.studentData.gradeList
  const assessments = props.studentData.assessments || []
  const assignments = props.studentData.assignments || []
  const discussions = props.studentData.discussions || []
  const [expandedObj, setExpandedObj] = React.useState({
    assessment: true,
    assignment: true,
    discussion: true
  });
  const [gradingObj, setGradingObj] = React.useState({
    open: false,
    course_id: props.courseId,
    user_id: '',
    graderElement: null,
    grade: 0,
    remarks: ''
  })
  const clearGrader = () => {
    setGradingObj({
      open: false,
      course_id: props.courseId,
      user_id: '',
      graderElement: null,
      grade: 0,
      remarks: ''
    })
  }
  const handleClose = () => {
    props.closeStudentReport()
  };

  // const descriptionElementRef = React.useRef(null);
  // React.useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);
  const onExpand = panel => {
    let panelObj = {...expandedObj}
    panelObj[panel] = !panelObj[panel]
    setExpandedObj(panelObj)
  }
  const triggerGradder = () => {
    setGradingObj({
      open: true,
      user_id: gradeList.user_id,
      course_id: props.courseId,
      graderElement: document.getElementById(`studentGraderBtn`),
      grade: gradeList.over_all_percent,
      remarks: gradeList.overall_remark
    })
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll='paper'
        aria-labelledby="student-details"
        aria-describedby="scroll-dialog-description"
        fullWidth
        aria-label="student details"
        maxWidth="md"
      >
        <DialogTitle id="student-name" style={{ color: '#888' }}>
          {gradeList.user_name}
          <Typography variant="subtitle1" component="div" gutterBottom style={{ color: '#999' }}>
            Student ID: {gradeList.user_id}
          </Typography>
        </DialogTitle>
        {/* <DialogContent dividers={scroll === 'paper'}> */}
        <DialogContent>
          {assessments.length ? (
            <ExpansionPanel expanded={expandedObj.assessment} onChange={() => {onExpand('assessment')}} style={{ backgroundColor: '#f9f9f9' }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="assessment-content"
                id="assessment-header"
              >
              <Typography variant="h6" component="span" gutterBottom>
                Quiz <span className={classes.subGradePercent}>{gradeList.system_calculated_assessment_grade}%</span>
              </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="Quiz Grade Details">
                    <TableHead>
                      <TableRow>
                        <TableCell>Quiz</TableCell>
                        <TableCell align="right">Grade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {gradeList && gradeList.assessment_grade && gradeList.assessment_grade.map((row, index) => (
                      <TableRow key={index}>
                         <TableCell component="th" scope="row">
                           {assessments[index] && assessments[index].title ? assessments[index].title : 'NA'}
                         </TableCell>
                         <TableCell align="right">{row && row.score_percent !== null ? row.score_percent : 'NA' }</TableCell>
                       </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          {assignments.length ? (
            <ExpansionPanel expanded={expandedObj.assignment} onChange={() => {onExpand('assignment')}} style={{ backgroundColor: '#f9f9f9' }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="assignment-content"
                id="assignment-header"
              >
              <Typography variant="h6" component="span" gutterBottom>
                Assignment <span className={classes.subGradePercent}>{gradeList.system_calculated_assignment_grade}%</span>
              </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="Assignment Grade Details">
                    <TableHead>
                      <TableRow>
                        <TableCell>Assignment</TableCell>
                        <TableCell align="right">Grade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {gradeList && gradeList.assignment_grade && gradeList.assignment_grade.map((row, index) => (
                      <TableRow key={index}>
                         <TableCell component="th" scope="row">
                           {assignments[index] && assignments[index].title ? assignments[index].title : 'NA'}
                         </TableCell>
                         <TableCell align="right">{row && row.grade_point !== null ? row.grade_point : 'NA'}</TableCell>
                       </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          {discussions.length ? (
            <ExpansionPanel expanded={expandedObj.discussion} onChange={() => {onExpand('discussion')}} style={{ backgroundColor: '#f9f9f9' }}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="discussion-content"
                id="discussion-header"
              >
              <Typography variant="h6" component="span" gutterBottom>
                Discussion <span className={classes.subGradePercent}>{gradeList.system_calculated_discussion_grade}%</span>
              </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="Discussion Grade Details">
                    <TableHead>
                      <TableRow>
                        <TableCell>Discussion</TableCell>
                        <TableCell align="right">Grade</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {gradeList && gradeList.discussion_grade && gradeList.discussion_grade.map((row, index) => (
                      <TableRow key={index}>
                         <TableCell component="th" scope="row">
                           {discussions[index] && discussions[index].forum_title ? discussions[index].forum_title : 'NA'}
                         </TableCell>
                         <TableCell align="right">{row && row.grade_point !== null ? row.grade_point : 'NA'}</TableCell>
                       </TableRow>
                    ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={triggerGradder} color="primary" id="studentGraderBtn">
            Overall Student Grade:&nbsp;<span className={classes.subGradePercent}>{gradeList.over_all_percent}%</span>
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
        {gradingObj.open ? (
          <GradePopper
            gradingObj={gradingObj}
            location="left-start"
            onClose={clearGrader}
            onUpdateSuccess={props.onGradeUpdateSuccess}
            onUpdateFailure={props.onGradeUpdateFailure}
          />
        ) : null}
      </Dialog>
    </div>
  );
}

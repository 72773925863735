import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { find } from 'lodash';
import Container from '@material-ui/core/Container';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
//LOCAL IMPORTS
import { bytesToSize } from '../../Helpers/basic';
import AlertMessages from '../Common/AlertMessages'
import { apiConfig } from '../../Configs/apiConfig'
import ImagePreview from '../Common/Previews/imagePreview'
import VideoPreview from '../Common/Previews/videoPreview'
import HelpView from '../Common/Previews/helpView'
import myAthinaLogo from '../../static/myAthinaLogoWhite.png';
import TextEditor from '../Common/TextEditor'
import CourseFormPreview from '../Common/OpeningDialogView/CoursePreview'
import { courseLevel } from '../../Configs/courseLevels'
import LeftMenu from '../AppLayout/leftMenu'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { colorStyles } from '../../Styles/theme';
import { addingCourse, updatingCourse, courseCategory, fetchCourseTagsList, uploadScormCourse } from '../../store/AddingCourse/actionCreator'
import { ERROR_ADD_COURSE, ERROR_UPDATE_COURSE_INFO, ERROR_SCORM_FILE_INVALID } from '../Common/Constants/errorMessages';
import { SUCCESS_ADD_COURSE, SUCCESS_UPDATE_COURSE_INFO } from '../Common/Constants/successMessages'
import RestrictUsersPopup from '../Common/OpeningDialogView/userRestrictPopup';
//FOR FINDING THE COWORKER OF COURSE DETAILS
import { fetchCourseWorkingUsersInfo } from '../../store/Authors/actionCreator';
import { getCourseOpenModeType, getDomainInfo } from '../../Helpers/basic';
import UploadProgress from './uploadProgress';
import DocsLibrarySelectionPopup from '../Common/OpeningDialogView/docsLibrarySelection';

const ScormFormData = require('form-data');

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '100px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  uploadIcon: {
    color: '#2e8eec',
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  buttonProgress: {
    marginBottom: '10px',
    marginRight: '10px',
    color: colorStyles.fontColor._blue
  },
  uploadedImg: {
    width: 62,
    marginLeft: 20,
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  uploadedVideo: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 231,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    textTransform: 'none',
    backgroundColor: colorStyles.btnColor,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
    color: colorStyles.btnText,
  },
  uploadButton: {
    float: 'right',
    backgroundColor: colorStyles.btnColor,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
    color: colorStyles.btnText,
    marginBottom: '10px',
    marginLeft: '10px',
    textTransform: 'none'
  },
  iconButtonMargin: {
    float: 'right',
    marginBottom: '10px',
    marginLeft: '10px'
  },
  iconWrapper: {
    fontSize: '24px',
    marginRight: '10px',
  },
  uploadTitle: {
    fontSize: '1rem',
    color: '#2e8eec'
  },
  warning: {
    color: 'red',
    marginBottom: '0.5rem'
  },
  uploadSuccess: {
    fontSize: '1rem',
    color: '#2e8eec'
  },
  succIcon: {
    fontSize: '2rem',
    marginTop: '0.5rem',
    color: '#6bc787'
  },
  iconCntr: {
    textAlign: 'center'
  },
  helperText: {
    color: '#999'
  }
}));


function InitiateCourse(props) {
  const classes = useStyles();
  const showHelpButton = false
  const domainInfo = getDomainInfo();
  const showRestrictPopupInfo = props.showRestrictPopup
  const defaultCourseTitle = props.introDetails && props.introDetails.title ? props.introDetails.title : ''
  const [courseTitle, setCourseTitle] = useState(defaultCourseTitle);
  const [courseDesc, setCourseDesc] = useState('');
  const [selecteCourseLevel, setSelectedCourseLevel] = useState('');
  const [courseCategoryList, setCourseCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [bannerImageDetails, setbannerImageDetails] = useState({});
  const [uploadedImagePreview, setUploadedImagePreview] = useState('')
  const [introVideoFileDetails, setIntroVideoFileDetails] = useState({});
  const [uploadedIntroVideoPreview, setUplodedIntroVideoPreview] = useState('')
  const [transcriptFileDetails, setTranscriptFileDetails] = useState({});
  const [uploadedTranscriptFile, setUploadedTranscriptFile] = useState('')
  const [openHelpImageView, setOpenHelpImageView] = useState(false)
  const [inProgress, setInprogress] = useState(false);
  const [openImgPrvDialog, setOpenImgPrvDialog] = useState(false)
  const [openIntroPrvDialog, setOpenIntroPrvDialog] = useState(false)
  const [openFormPrev, setOpenFormPrev] = useState(false)
  const [fetchCourseTags, setFetchCourseTags] = useState([])
  const [selectedCourseTags, setSelectedCourseTags] = useState([]);
  const [renderUpload, setRenderUpload] = useState(true);
  const [scormUploadSuccess, setScormUploadSuccess] = useState();
  const [courseType, setCourseType] = useState('scorm');
  const [scormUploadStatus, setScormUploadStatus] = useState('');
  const [scormUploadPercentage, setScormUploadPercentage] = useState(0);
  const [scormxApiSpecificErrorMsg, setScormxApiSpecificErrorMsg] = useState('');
  const courseOpenedMode = getCourseOpenModeType();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  //FOR SELECTING THE FILES FROM DOCS LIBRARY
  const [openDocsLibrary, setOpenDocsLibrary] = useState({
    type: '',
    dialog: false
  })


  useEffect(() => {
    if (props && props.introDetails) {
      setSelectedCourseLevel(props.introDetails && props.introDetails.level && props.introDetails.level)
      setCourseDesc(props.introDetails && props.introDetails.desc && props.introDetails.desc)
      setSelectedCategory(props.introDetails && props.introDetails.category && props.introDetails.category)
      setUploadedImagePreview(props.introDetails && props.introDetails.banner_url && props.introDetails.banner_url)
      setUplodedIntroVideoPreview(props.introDetails && props.introDetails.video_url && props.introDetails.video_url)
      setUploadedTranscriptFile(props.introDetails && props.introDetails.transcript && props.introDetails.transcript)
    }
  }, [props])
  const onCourseTypeChange = evnt => {
    setCourseType(evnt.target.value)
  }
  const defaultForPop = () => {
    if (!props.introDetails) {
      return true
    }
    else {
      return false
    }
  }

  const [showPopUp, setShowPopUp] = useState(defaultForPop());
  const [validationError, setValidationError] = useState({
    error: false,
    errorMessage: ''
  })

  const [formFieldStatus, setFormFieldStatus] = useState(
    {
      courseTitle: {
        error: false
      },
      courseDesc: {
        error: false
      },
      level: {
        error: false
      },
      category: {
        error: false
      },
      tags: {
        error: false
      }
    }
  );

  const values = {
    courseTitle: props.introDetails && props.introDetails.title ? props.introDetails.title : null,
    courseDesc: props.introDetails && props.introDetails.desc ? props.introDetails.desc : null,
    bannerImage: props.introDetails && props.introDetails.banner_url ? props.introDetails.banner_url : null,
    introVideo: props.introDetails && props.introDetails.video_url ? props.introDetails.video_url : null,
    course_video_filename: props.introDetails && props.introDetails.video_filename ? props.introDetails.video_filename : '',
    transcriptFile: props.introDetails && props.introDetails.transcript ? props.introDetails.transcript : null,
    course_transcript_filename: props.introDetails && props.introDetails.transcript_filename ? props.introDetails.transcript_filename : '',
    level: props.introDetails && props.introDetails.level ? props.introDetails.level : null,
    category: props.introDetails && props.introDetails.category ? props.introDetails.category : null,
    courseTags: props.introDetails && props.introDetails.tags ? props.introDetails.tags : null
  }
  let defaultSelectedCourseTags = []
  for (let i in values.courseTags) {
    defaultSelectedCourseTags.push(values.courseTags[i])
  }
  const courseCategorySuccessCallBack = res => {
    setCourseCategoryList(res.results)
  }
  const courseCategoryFailureCallBack = error => {
    setCourseCategoryList([])
  }

  const courseTagsSuccessCallBack = res => {
    setFetchCourseTags(res.results)
  }
  const courseTagsFailureCallBack = error => {
    setFetchCourseTags([])
  }

  useEffect(() => {
    if (domainInfo && domainInfo.name !== 'rmbl') {
      courseCategory({}, courseCategorySuccessCallBack, courseCategoryFailureCallBack)
    }
    fetchCourseTagsList({}, courseTagsSuccessCallBack, courseTagsFailureCallBack)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])


  const displayUploadPopup = () => {
    setShowPopUp(true);
  }

  const handleReportClose = () => {
    setShowPopUp(false)
    setScormUploadSuccess('')
    setInprogress(false)
    setRenderUpload(true);
  }

  const scromSuccessCB = (status) => {
    setScormxApiSpecificErrorMsg('')
    if (status === 'Successfully Deployed') {
      setScormUploadStatus(status);
      setScormUploadSuccess(true);
      setRenderUpload(false);
      setInprogress(false)
      props.showSnackBar(
        {
          state: true,
          message: 'Successfully Deployed',
          type: 'success'
        }
      )
      props.history.push(`/mycourse`)
    } else {
      setScormUploadSuccess(true);
      setRenderUpload(false);
      setScormUploadPercentage(status);
    }

  }

  const scormFailureCB = (error) => {
    const errorMsg = (error.response && error.response.data && error.response.data.message) ? error.response && error.response.data && error.response.data.message : 'Something went wrong, Please try again!'
    setScormxApiSpecificErrorMsg(errorMsg)
    props.showSnackBar(
      {
        state: false,
        message: errorMsg,
        type: 'error'
      }
    )
    setRenderUpload(true);
    setScormUploadSuccess(false)
    setInprogress(false)
  }

  const onCourseUpload = event => {
    setScormUploadSuccess('')
    setInprogress(true)
    setScormxApiSpecificErrorMsg('')
    // let file = event.target.files[0];
    let selFile = document.getElementById('uploadCourse').files;
    let file = selFile[0];
    let allowedExtensions = apiConfig.addingCourse.acceptedScormUploadFileFormats;
    const currentExtension = file.name.split('.')[1].toLowerCase()
    if (allowedExtensions.indexOf(currentExtension) === -1) {
      props.showSnackBar(
        {
          state: false,
          message: ERROR_SCORM_FILE_INVALID,
          type: 'error'
        }
      )
      setInprogress(false)
    } else {
      let formData = new ScormFormData();
      formData.append('myfile', file, file.name)
      // formData.append('courseType', courseType)
      uploadScormCourse(formData, courseType, scromSuccessCB, scormFailureCB);
    }
  }

  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files;
    let fileDetails = {
      fileError: false,
      fileErrorErrorMsg: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMsg: ''
        // fileError: true,
        // fileErrorErrorMsg: (type === 'bannerImage') ? 'Please upload course bannerImage.' : 'Please upload course introVideo'
      }
    } else {
      const file = selectedFile[0]
      if (type === 'bannerImage' && file.size > apiConfig.addingCourse.acceptedImageFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 2MB'
        }
      } else if (type === 'introVideo' && file.size > apiConfig.addingCourse.acceptedVideoFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 600MB'
        }
      } else if (type === 'transcriptFile' && file.size > apiConfig.addingCourse.acceptedTranScriptSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMsg: 'File size should be less than 200MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'bannerImage') {
            return apiConfig.addingCourse.acceptedImageFileFormats
          } else if (type === 'introVideo') {
            return apiConfig.addingCourse.acceptedVideoFileFormats
          } else if (type === 'transcriptFile') {
            return apiConfig.addingCourse.acceptedTranscriptFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const fileExtension = file && file.name && file.name.lastIndexOf('.')
        const currentExtension = file && file.name && file.name.slice(fileExtension) && file.name.slice(fileExtension).split('.')[1] && file.name.slice(fileExtension).split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMsg: 'Invalid file format.'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMsg: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }

  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const onBannerImageUpload = event => {
    const certificateFile = validatedFile('bannerImage')
    setbannerImageDetails(certificateFile)
    setUploadedImagePreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }
  const onIntroVideoUpload = event => {
    const videoFile = validatedFile('introVideo')
    setIntroVideoFileDetails(videoFile)
    setUplodedIntroVideoPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }
  const onTranscriptUpload = event => {
    const transcriptFile = validatedFile('transcriptFile')
    setTranscriptFileDetails(transcriptFile)
    setUploadedTranscriptFile(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }
  const handleChangeCourseTitle = evnt => {
    setCourseTitle(evnt.target.value)
  }
  const onChangeEditorContent = data => {
    setCourseDesc(data)
  }

  const successAddingCourseWelcome = res => {
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ADD_COURSE,
        type: 'success'
      }
    )
    const courseId = res.id ? res.id : null
    props.history.push(`/mycourse/${courseId}`)
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const failureAddingCourseWelcome = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ADD_COURSE,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setInprogress(false)
  }

  const successUpdateCourseInfo = res => {
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_UPDATE_COURSE_INFO,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setInprogress(false)
    props.onClickShowCourseEditForm()
  }

  const failureUpdateCourseInfo = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UPDATE_COURSE_INFO,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setInprogress(false)
  }
  const updateCourse = () => {
    successUpdateCourseInfo()
  }
  const updateAndAddModule = () => {
    successUpdateCourseInfo()
    props.onClickShowAddModuleForm()
  }
  const openFormPreview = () => {
    setInprogress(false)
    setOpenFormPrev(true)
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }
  const submitForm = (evnt, type) => {
    evnt.preventDefault()
    let bannerImageValid = bannerImageDetails.file ? true : false
    if (props.introDetails && props.introDetails.banner_url) {
      bannerImageValid = true
    }
    let isNewBannerValid = true
    if (bannerImageDetails.fileError) {
      isNewBannerValid = false
    }
    let isNewIntroVideoValid = true
    if (introVideoFileDetails.fileError) {
      isNewIntroVideoValid = false
    }

    let isNewTranscriptValid = true
    if (transcriptFileDetails.fileError) {
      isNewTranscriptValid = false
    } else if (transcriptFileDetails.fileError === false && !transcriptFileDetails.file) {
      isNewTranscriptValid = false
    }

    let transcriptData = true
    if (introVideoFileDetails.file && values.transcriptFile) {
      transcriptData = true
    } else if (introVideoFileDetails.file && !transcriptFileDetails.file) {
      transcriptData = false
    }

    if (!courseTitle) {
      setFormFieldStatus({
        courseTitle: {
          error: true
        },
        courseDesc: {
          error: false
        },
        level: {
          error: false
        },
        category: {
          error: false
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Course Title!'
      })
    } else if (!courseDesc) {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: true
        },
        level: {
          error: false
        },
        category: {
          error: false
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Course Description!'
      })
    } else if (domainInfo && domainInfo.name !== 'rmbl' && !selecteCourseLevel) {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: false
        },
        level: {
          error: true
        },
        category: {
          error: false
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Course Level!'
      })
    } else if (domainInfo && domainInfo.name !== 'rmbl' && !selectedCategory) {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: false
        },
        level: {
          error: false
        },
        category: {
          error: true
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Course Category!'
      })
    } else if (!selectedCourseTags.length) {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: false
        },
        level: {
          error: false
        },
        category: {
          error: false
        },
        tags: {
          error: true
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Course Tags!'
      })
    } else if (!bannerImageValid || !isNewBannerValid) {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: false
        },
        level: {
          error: false
        },
        category: {
          error: false
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Thumbnail Image!'
      })
    } else if (!isNewIntroVideoValid) {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: false
        },
        level: {
          error: false
        },
        category: {
          error: false
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Intro Video!'
      })
    }
    else {
      setFormFieldStatus({
        courseTitle: {
          error: false
        },
        courseDesc: {
          error: false
        },
        level: {
          error: false
        },
        category: {
          error: false
        },
        tags: {
          error: false
        }
      })
      setValidationError({
        error: false,
        errorMessage: ''
      })
      setInprogress(true)
      let formData = new FormData()
      const newTags = []
      for (let i = 0; i < selectedCourseTags.length; i++) {
        const tag = find(fetchCourseTags, { 'skill': selectedCourseTags[i] })
        tag ? newTags.push({ "id": tag.id, "courseTag": selectedCourseTags[i] }) : newTags.push({ "id": "", "courseTag": selectedCourseTags[i] })
      }
      formData.append('title', courseTitle)
      formData.append('description', courseDesc)
      formData.append('banner', bannerImageDetails.file ? bannerImageDetails.file : null)
      formData.append('video', introVideoFileDetails.file ? introVideoFileDetails.file : null)
      formData.append('transcript', transcriptFileDetails.file ? transcriptFileDetails.file : null)
      formData.append('category', domainInfo && domainInfo.name !== 'rmbl' ? selectedCategory : 1)
      formData.append('level', domainInfo && domainInfo.name !== 'rmbl' ? selecteCourseLevel : 1)
      formData.append('tags', JSON.stringify(newTags))

      if (introVideoFileDetails && introVideoFileDetails.fileId && introVideoFileDetails.fileId) {
        formData.append('is_doc_video', true)
        formData.append('doc_video_id', introVideoFileDetails.fileId ? introVideoFileDetails.fileId : null)
      }

      if (type === 'update' || type === 'update&AddModule') {
        const payLoad = {
          id: props.match.params.courseId ? props.match.params.courseId : null
        }
        updatingCourse(payLoad, formData, type === 'update' ? updateCourse : updateAndAddModule, failureUpdateCourseInfo)
      } else if (type === 'showPreview') {
        openFormPreview()
      } else if (type === 'save' || type === 'save&AddModule') {
        addingCourse(formData, successAddingCourseWelcome, failureAddingCourseWelcome)
      }
    }
  }
  const openUploadedBannerImagePopup = () => {
    setOpenImgPrvDialog(true)
  }
  const closeUploadedBannerImagePopup = () => {
    setOpenImgPrvDialog(false)
  }
  const openUploadedIntroVideoPopup = () => {
    setOpenIntroPrvDialog(true)
  }
  const closeUploadedIntroVideoPopup = () => {
    setOpenIntroPrvDialog(false)
  }
  const openHelpView = () => {
    setOpenHelpImageView(true)
  }
  const closeHelpView = () => {
    setOpenHelpImageView(false)
  }
  const closeFormPreview = () => {
    setOpenFormPrev(false)
  }

  const handleChangeSelectLevel = event => {
    setSelectedCourseLevel(event.target.value);
  };
  const handleChangeCourseCategory = evnt => {
    setSelectedCategory(evnt.target.value)
  }

  const getCourseTags = (value, getTagProps) => {
    setSelectedCourseTags(value)
    return value.map((option, index) => (
      <Chip
        variant="outlined"
        label={option}
        {...getTagProps({ index })}
      />
    ))
  }
  //FOR CHECKING THE CO WORKER FOR ASSESSMENTS
  const successCoworkAssessment = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The assessments page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assessments/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assessments/${props.match.params.courseId}`)
      }
    }
  }
  const failureCoworkAssessment = err => { }

  const landToAssessment = () => {
    if (courseOpenedMode !== 'visit_mode' && defaultCourseTitle && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.courseId,
        type: 'courselevelassessments'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworkAssessment, failureCoworkAssessment)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assessments/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assessments/${props.match.params.courseId}`)
      }
    }
  }

  //FOR CHECKING THE CO WORKER FOR ASSIGNMENT
  const successCoworkAssignMent = res => {
    if (res && res.results && Object.keys(res.results) && Object.keys(res.results).length) {
      props.showSnackBar({
        state: true,
        message: `The assignments page is currently being working by ${res.results && res.results.instructor_name && res.results.instructor_name}`,
        type: 'workinprogress'
      })
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}`)
      }
    }
  }
  const failureCoworkAssignment = err => { }

  const landToAssignment = () => {
    if (courseOpenedMode !== 'visit_mode' && defaultCourseTitle && domainInfo.name === 'rmbl') {
      const payload = {
        course_id: props.match.params.courseId,
        id: props.match.params.courseId,
        type: 'assignments'
      }
      fetchCourseWorkingUsersInfo(payload, successCoworkAssignMent, failureCoworkAssignment)
    } else {
      if (base64EncodeValue) {
        props.history.push(`/assignment/${props.match.params.courseId}?${base64EncodeValue}`)
      } else {
        props.history.push(`/assignment/${props.match.params.courseId}`)
      }
    }
  }

  //FOR LINKING THE FILE FROM DOCS LIBRARY
  const linkAFileDocsLibrary = (type) => {
    setOpenDocsLibrary({
      type: type,
      dialog: true
    })
  }

  //ON SELECTION FROM DOCS LIBRARY
  const onSelecteDocsLibraryFile = (data, fileType) => {
    let videoFileDetails = {
      fileError: false,
      fileErrorErrorMsg: '',
      name: data && data.video_filename && data && data.video_filename,
      type: 'video/mp4',
      file: data && data.video,
      fileId: data && data.id
    }
    let transcriptFileDetails = {
      fileError: false,
      fileErrorErrorMsg: '',
      name: data && data.transcript_filename && data && data.transcript_filename,
      type: '',
      file: data && data.transcript
    }
    setIntroVideoFileDetails(videoFileDetails)
    setUplodedIntroVideoPreview(data && data.video)
    setTranscriptFileDetails(transcriptFileDetails)
    setUploadedTranscriptFile(data && data.transcript)
  }

  //FOR CLOSING THE DOCS LIBRARY SELECTION POPUP
  const closeDocsLibrayPopup = () => {
    setOpenDocsLibrary({
      type: '',
      dialog: false
    })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      {showRestrictPopupInfo && showRestrictPopupInfo.open ? (
        <RestrictUsersPopup data={showRestrictPopupInfo} />
      ) : null}
      <main>
        <a className="skip-main" href="#leftMenu">
          Back to main menu
        </a>
        {!showPopUp && <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={1} lg={4}>
            </Grid>
            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
              <h1>{props.introDetails ? 'Update course details form' : 'Upload course details form'}</h1>
            </div>
            <Grid item xs={12} sm={12} md={7} lg={4}>
              <Typography style={{ marginBottom: '15px' }} component="h1" variant="h4" align="center" aria-hidden="true">
                {props.introDetails ? 'Update Course' : 'Add Course'}
              </Typography>
            </Grid>
            {showHelpButton ? (
              <Tooltip title="Help">
                <IconButton edge="start" color="primary" className={classes.iconButtonMargin} onClick={openHelpView} aria-label="close">
                  <HelpOutlineIcon style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            ) : null}
            {!props.introDetails ? (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Button className={classes.uploadButton} id="save" variant="contained" onClick={() => { displayUploadPopup() }}>
                  Upload a Course
                </Button>
              </Grid>
            ) : null}
          </Grid>
          {openHelpImageView ? (
            <HelpView
              openHelpImageView={openHelpImageView}
              closeHelpView={closeHelpView}
              imgUrl={myAthinaLogo}
            />
          ) : null}
          {validationError.error ? (
            <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block' }}>
              <AlertMessages status="error" message={validationError.errorMessage} />
            </div>
          ) : null}
          <form autoComplete="off" onSubmit={evnt => { evnt.preventDefault() }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  variant="outlined"
                  id="courseTitile"
                  name="coursetitle"
                  label="Course Title"
                  aria-required="true"
                  autoFocus={true}
                  fullWidth
                  error={formFieldStatus.courseTitle.error}
                  defaultValue={values.courseTitle}
                  helperText={`max ${apiConfig.addingCourse.courseTitleLimit} char`}
                  inputProps={{
                    maxLength: apiConfig.addingCourse.courseTitleLimit
                  }}
                  onChange={handleChangeCourseTitle}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography aria-required="true" style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px' }}>
                  About this course
                  <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk" aria-hidden="true">&thinsp;*</span>
                </Typography>
                <TextEditor onChangeEditorContent={onChangeEditorContent} description={values.courseDesc} />
              </Grid>
              {domainInfo && domainInfo.name !== 'rmbl' ? (
                <React.Fragment>
                  <Grid item xs={12} sm={4}>
                    <FormControl aria-required="true" variant="outlined" className={classes.formControl}>
                      <InputLabel shrink id="courseLevel">
                        Course Level
                        <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk" aria-hidden="true">&thinsp;*</span>
                      </InputLabel>
                      <Select
                        id="courseLevel"
                        value={selecteCourseLevel}
                        error={formFieldStatus.level.error}
                        onChange={handleChangeSelectLevel}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {courseLevel.map((item, i) => {
                          return <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl aria-required="true" variant="outlined" className={classes.formControl}>
                      <InputLabel shrink id="courseCategory">
                        Course Category
                        <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk" aria-hidden="true">&thinsp;*</span>
                      </InputLabel>
                      <Select
                        id="courseCategory"
                        value={selectedCategory}
                        error={formFieldStatus.category.error}
                        onChange={handleChangeCourseCategory}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        {courseCategoryList.map((item, i) => {
                          return <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : null}
              <Grid item xs={12} sm={12}>
                <div className={classes.dropdown} aria-required="true">
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={fetchCourseTags.map(option => option.skill)}
                    //value={values.courseTags}
                    defaultValue={defaultSelectedCourseTags}
                    freeSolo
                    renderTags={(value, getTagProps) => { return getCourseTags(value, getTagProps) }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        error={formFieldStatus.tags.error}
                        id="courseTags"
                        variant="outlined"
                        name="coursetags"
                        label="Course Tags"
                        placeholder="Type tags here and press 'ENTER' or, to add new tag"
                        helperText="Type tags here and press 'ENTER' or, to add new tag"
                        fullWidth
                      //onChange={handleChange('courseTags')}
                      //value={values.CourseTags}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div style={{ float: 'left' }}>
                  {bannerImageDetails.fileError ? (
                    <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                      {bannerImageDetails.fileErrorErrorMsg}
                    </Typography>
                  ) : null}
                  <Typography gutterBottom variant="body2">
                    <span className={classes.uploadLabel} aria-hidden="true">
                      Thumbnail image
                      <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk" aria-hidden="true">&thinsp;*</span> :
                    </span>
                    <Tooltip title="Upload from Computer">
                      <IconButton aria-label="Upload thumbnail image" aria-required="true" onClick={() => { onFileToUploadClick('bannerImage') }}>
                        <CloudUploadIcon className={classes.uploadIcon} />
                      </IconButton>
                    </Tooltip>
                    {bannerImageDetails.file && bannerImageDetails.file.name ? (
                      <span>{`${bannerImageDetails.file.name.substr(0, 10) + '...'}, ${bannerImageDetails.file.size}`}</span>
                    ) : null}
                    <span className={classes.helperText}>( max.size: 2MB, format: jpg, jpeg, png )</span>
                    {uploadedImagePreview ? (
                      <img className={classes.uploadedImg} alt="bannerimage" src={uploadedImagePreview} onClick={() => { openUploadedBannerImagePopup(uploadedImagePreview) }} />
                    ) : null}
                    {openImgPrvDialog ? (
                      <ImagePreview
                        openImgPrvDialog={openImgPrvDialog}
                        imgUrl={uploadedImagePreview}
                        closeUploadedBannerImagePopup={closeUploadedBannerImagePopup}
                      />
                    ) : null}
                  </Typography>
                  <input type="file" accept=".png, .jpg, .jpeg" style={{ display: 'none' }} id="bannerImage" onChange={onBannerImageUpload} />
                  <span style={{ fontSize: '12px', float: 'inherit', marginTop: '-15px', color: '#999' }}>Recommended Image size (1024x210)</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={6} sm={6}>
                    {introVideoFileDetails.fileError ? (
                      <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                        {introVideoFileDetails.fileErrorErrorMsg}
                      </Typography>
                    ) : null}
                    <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                      <span className={classes.uploadLabel} aria-hidden="true">
                        Upload Intro Course Video :
                      </span>
                      <Tooltip title="Upload from Computer">
                        <IconButton aria-label="Upload intro course video" onClick={() => { onFileToUploadClick('introVideo') }}>
                          <CloudUploadIcon className={classes.uploadIcon} />
                        </IconButton>
                      </Tooltip>
                      {domainInfo.hasMyAthinaCorpAcc && domainInfo.enableDocsLibraryUpload ? (
                        <React.Fragment>
                          <span style={{ marginLeft: '7px', marginRight: '7px' }}>or</span>
                          <Tooltip title="Upload from Docs Library">
                            <IconButton aria-label="Upload video" onClick={() => { linkAFileDocsLibrary('videos') }}>
                              <InsertLinkIcon className={classes.uploadIcon} />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      ) : null}
                    </Typography>
                    <div style={{ color: '#999', fontSize: '12px', textAlign: 'left', marginTop: '-11px' }}>max.size: 600MB, format: mp4, optional</div>
                  </Grid>
                  <Grid item xs={6} sm={6} style={{ paddingTop: '16px', textAlign: 'left' }}>
                    {introVideoFileDetails && introVideoFileDetails.name && uploadedIntroVideoPreview ? (
                      <React.Fragment>
                        <VideoLibraryIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px' }} />
                        <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>
                          {introVideoFileDetails && introVideoFileDetails.file && introVideoFileDetails.name && introVideoFileDetails.name.length > 30 ? (
                            introVideoFileDetails.name.substr(0, 25) + '...'
                          ) :
                            introVideoFileDetails && introVideoFileDetails.name && introVideoFileDetails.name
                          }
                        </span>
                      </React.Fragment>
                    ) : values.introVideo ? (
                      <React.Fragment>
                        <VideoLibraryIcon onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px', cursor: 'pointer' }} />
                        {values && values.course_video_filename && values.course_video_filename && <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>{values.course_video_filename}</span>}
                      </React.Fragment>
                    ) : null}
                    {openIntroPrvDialog ? (
                      <VideoPreview
                        openIntroPrvDialog={openIntroPrvDialog}
                        videoUrl={uploadedIntroVideoPreview}
                        closeUploadedIntroVideoPopup={closeUploadedIntroVideoPopup}
                      />
                    ) : null}
                  </Grid>
                  <input type="file" accept=".mp4" style={{ display: 'none' }} id="introVideo" onChange={onIntroVideoUpload} />
                </Grid>
              </Grid>
              {(introVideoFileDetails && introVideoFileDetails.name) || values.introVideo ? (
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      {transcriptFileDetails.fileError ? (
                        <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                          {transcriptFileDetails.fileErrorErrorMsg}
                        </Typography>
                      ) : null}
                      <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                        <span className={classes.uploadLabel} aria-hidden="true">
                          Upload TranscriptFile :
                        </span>
                        <Tooltip title="Upload from Computer">
                          <IconButton aria-label="Upload transcript file" onClick={() => { onFileToUploadClick('transcriptFile') }}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <div style={{ color: '#999', fontSize: '12px', textAlign: 'left', marginTop: '-11px' }}>Optional, max.size: 200MB, format: vtt, srt</div>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ paddingTop: '16px', textAlign: 'left' }}>
                      {transcriptFileDetails.file && transcriptFileDetails.name && uploadedTranscriptFile ? (
                        <React.Fragment>
                          <VideoLibraryIcon style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px' }} />
                          <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>
                            {transcriptFileDetails && transcriptFileDetails.file && transcriptFileDetails.name && transcriptFileDetails.name.length > 30 ? (
                              transcriptFileDetails.name.substr(0, 25) + '...'
                            ) :
                              transcriptFileDetails && transcriptFileDetails.file && transcriptFileDetails.name && transcriptFileDetails.name
                            }
                          </span>
                        </React.Fragment>
                      ) : values.transcriptFile ? (
                        <React.Fragment>
                          <VideoLibraryIcon onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} style={{ color: '#2e8eec', marginBottom: '-7px', marginRight: '-14px', cursor: 'pointer' }} />
                          {values && values.course_transcript_filename && values.course_transcript_filename && <span onClick={() => { openUploadedIntroVideoPopup(uploadedIntroVideoPreview) }} className={classes.uploadedVideo}>{values.course_transcript_filename}</span>}
                        </React.Fragment>
                      ) : null}
                      {openIntroPrvDialog ? (
                        <VideoPreview
                          openIntroPrvDialog={openIntroPrvDialog}
                          videoUrl={uploadedIntroVideoPreview}
                          closeUploadedIntroVideoPopup={closeUploadedIntroVideoPopup}
                          transcriptFileDetails={transcriptFileDetails}
                        />
                      ) : null}
                    </Grid>
                    <input type="file" accept=".srt, .vtt" style={{ display: 'none' }} id="transcriptFile" onChange={onTranscriptUpload} />
                  </Grid>
                </Grid>
              ) : null}
              {inProgress && (
                <CircularProgress size={30} className={classes.buttonProgress} />
              )}
              <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
                {props.introDetails ? (
                  <React.Fragment>
                    {courseOpenedMode !== 'visit_mode' ? (
                      <React.Fragment>
                        <Button className={classes.buttons} id="save" variant="contained" disabled={inProgress} onClick={(evnt) => { submitForm(evnt, 'update') }}>
                          Update
                        </Button> &thinsp;&thinsp;&thinsp;&thinsp;
                        <Button className={classes.buttons} id="saveandsubmit" variant="contained" disabled={inProgress} onClick={(evnt) => { submitForm(evnt, 'update&AddModule') }}>
                          {`Update & Add Module`}
                        </Button>&thinsp;&thinsp;&thinsp;&thinsp;
                      </React.Fragment>
                    ) : null}
                    <Button className={classes.buttons} variant="contained" disabled={inProgress} onClick={landToAssessment} endIcon={<AssignmentTurnedInIcon className={classes.iconWrapper} />}> Quiz </Button>&thinsp;&thinsp;&thinsp;&thinsp;
                    <Button className={classes.buttons} variant="contained" disabled={inProgress} onClick={landToAssignment} endIcon={<AssignmentOutlinedIcon className={classes.iconWrapper} />}> Assignment </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button className={classes.buttons} id="save" variant="contained" disabled={inProgress} onClick={(evnt) => { submitForm(evnt, 'save') }}>
                      Save
                    </Button>&thinsp;&thinsp;&thinsp;&thinsp;
                    <Button className={classes.buttons} id="saveandsubmit" variant="contained" disabled={inProgress} onClick={(evnt) => { submitForm(evnt, 'save&AddModule') }}>
                      {`Save & Add Module`}
                    </Button>
                  </React.Fragment>
                )}
                <Tooltip title="Form Preview">
                  <IconButton edge="start" className={classes.iconButtonMargin} onClick={(evnt) => { submitForm(evnt, 'showPreview') }} aria-label="form preview">
                    <VisibilityIcon style={{ fontSize: 20, marginTop: '-3px', color: '#2e8eec' }} />
                  </IconButton>
                </Tooltip>
                {openFormPrev ? (
                  <div>
                    <CourseFormPreview openFormPrev={openFormPrev} closeFormPreview={closeFormPreview} courseCategoryList={courseCategoryList} courseLevel={courseLevel} courseTitle={courseTitle} courseDesc={courseDesc} bannerImageDetails={uploadedImagePreview} introVideoFileDetails={uploadedIntroVideoPreview} transcriptFileDetails={uploadedTranscriptFile} selectedCategory={selectedCategory} selectedCourseLevel={selecteCourseLevel} selectedCourseTags={selectedCourseTags} />
                  </div>
                ) : null}
              </div>
            </Grid>
          </form>
        </Paper>}
        <Dialog
          open={showPopUp}
          onClose={handleReportClose}
          fullWidth={true}
          maxWidth='md'
          scroll='paper'
          aria-label="Upload course zip file"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Add a New Course</DialogTitle>
          <DialogContent dividers={true} className={classes.reportContainer}>
            {renderUpload ? <div style={{ textAlign: 'center' }}>

              {scormUploadSuccess === false ? (
                <div style={{ color: 'red', marginBottom: 10 }}>
                  <div> Error Uploading course.</div>
                  {scormxApiSpecificErrorMsg !== '' ? (
                    <div>{scormxApiSpecificErrorMsg}</div>
                  ) : null}
                </div>
              ) : null}

              <div className={classes.uploadTitle}>
                Upload your SCORM course
              </div>
              <div className={classes.scormTypeWrapper}>
                <FormControl component="fieldset">
                  <RadioGroup style={{ display: 'inline-block' }} aria-label="course type" name="courseType" value={courseType} onChange={onCourseTypeChange}>
                    <FormControlLabel value="scorm" control={<Radio />} label="SCORM" />
                    <FormControlLabel value="xApi" control={<Radio />} label="xAPI" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={classes.warning}>
                (only .zip,.tar,.gz files allowed)
              </div>



              <div>
                <Button
                  variant="contained"
                  color="default"
                  disabled={inProgress}
                  className={classes.buttons}
                  aria-label="Upload your scorm file"
                  onClick={() => { onFileToUploadClick('uploadCourse') }}
                  startIcon={<CloudUploadIcon />}
                >
                  {inProgress && (
                    <CircularProgress size={30} className={classes.buttonProgress} />
                  )} Upload
                </Button>
              </div>
              <div style={{ marginTop: '0.5rem' }}>
                or
              </div>
              <div style={{ marginTop: '0.5rem', color: '#2e8eec', fontSize: '1rem' }}>
                <Button
                  variant="contained"
                  color="default"
                  aria-label="create course manually"
                  className={classes.buttons}
                  onClick={() => { setShowPopUp(false) }}

                >
                  Create Manually
                </Button>
              </div>
              <input type="file" accept=".zip,.tar.gz,.gz,.jpg" style={{ display: 'none' }} id="uploadCourse" onChange={onCourseUpload} />
            </div> : <React.Fragment>{scormUploadSuccess === true &&
              <div>
                <Grid container spacing={2}>
                  <Grid item lg={1} md={1} sm={12} xs={12} className={classes.iconCntr}>
                    <CheckCircleIcon className={classes.succIcon} />
                  </Grid>
                  <Grid item lg={11} md={11} sm={12} xs={12}>
                    {inProgress ? (
                      <div>
                        <UploadProgress progress={scormUploadPercentage} />
                      </div>
                    ) : null}
                    <div className={classes.uploadSuccess}>{scormUploadStatus}</div>


                  </Grid>
                </Grid>
              </div>
            }
            </React.Fragment>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReportClose} className={classes.buttons} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {openDocsLibrary.dialog &&
          <DocsLibrarySelectionPopup
            title={courseTitle}
            data={openDocsLibrary}
            close={closeDocsLibrayPopup}
            onSelecteDocsLibraryFile={onSelecteDocsLibraryFile}
          />
        }
      </main>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(InitiateCourse);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from '@material-ui/core/Chip';
import Skeleton from 'react-skeleton-loader';
//local imports
import { style } from '../../Styles/theme';
import { getUserDetails, getUSerType, getDomainInfo } from '../../Helpers/basic';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { updateProfile, fetchProfile } from '../../store/Auth/actionCreator';
import { fetchOrgGrpsList, fetchMentorStudentsList } from '../../store/ManageGroups/actionCreator';
import { ERROR_ADD_ZOOM_SECRET_KEYS, ERROR_UPDATE_ZOOM_SECRET_KEYS, ERR_FETCH_STUDENTS_UNDER_MENTOR } from '../Common/Constants/errorMessages';
import { SUCCESS_ADD_ZOOM_SECRET_KEYS, SUCCESS_UPDATE_ZOOM_SECRET_KEYS } from '../Common/Constants/successMessages';
import ViewDetailsPopup from './viewDetailsPopup';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '60px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '250px',
      paddingRight: '250px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    border: '1px solid #ccc',
    borderRadius: '10px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'left'
  },
  button: {
    marginTop: '17px',
    backgroundColor: style.fontColour._blue,
    textTransform: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  iconWrapper: {
    color: style.fontColour._blue
  },
  chipWrapper: {
    marginTop: '10px',
    marginBottom: '10px',
    height: '24px',
    color: style.fontColour._blue,
    border: "1px solid #2e8eec",
    marginLeft: '3px'
  },
  headWrapper: {
    color: style.fontColour._blue,
    fontWeight: 500
  },
  chipLoader: {
    color: '#f5f5f5'
  }
}))

function ProfileView(props) {
  const classes = useStyles();
  const userDetails = getUserDetails();
  const userType = getUSerType();
  const domainInfo = getDomainInfo();
  const userName = userDetails && userDetails.firstName ? `${userDetails.firstName} ${userDetails.lastName}` : userDetails.first_name ? `${userDetails.first_name} ${userDetails.last_name}` : null;
  const [inProgress, setInProgress] = useState(false);
  const [defaultZoomApiKey, setDefaultZoomKey] = useState('')
  const [zoomApiKey, setZoomApiKey] = useState(defaultZoomApiKey);
  const [defaultZoomApiSecretKey, setDefaultZoomApiSecretKey] = useState('');
  const [zoomApiSecretKey, setZoomApiSecretKey] = useState(defaultZoomApiSecretKey);
  const [defaultZoomEmailId, setDefaultZoomEmailId] = useState('');
  const [zoomEmailId, setZoomEmailId] = useState(defaultZoomEmailId);
  const [showKeySecret, setShowSecretKey] = useState(false);
  const [grpsLoader, setGrpsLoader] = useState(true);
  const [orgGrpsList, setOrgGrpsList] = useState([]);
  const [openMenteesPopup, setOpenMenteesPopup] = useState(false);
  const [openGrpDetailsPopup, setGrpDetailsPopup] = useState(false);
  const [selectedGrpData, setSelectedGrpData]  = useState([]);
  const [mentorStudLoader, setMentStudLoader] = useState(true);
  const [assignedStudentsArr, setAssignedStudentsArr] = useState([]);
  const [validation, setValidation] = useState({
    emailId: false,
    apiKey: false,
    apiSecretKey: false
  })

  //for fetching zoom keys from profile if already present callbacks
  const fetchProfileSuccess = res => {
    if(res && res.user && Object.keys(res.user) && Object.keys(res.user).length){
      setDefaultZoomKey(res.user.zoom_api_key && res.user.zoom_api_key)
      setDefaultZoomApiSecretKey(res.user.zoom_api_secret_key && res.user.zoom_api_secret_key)
      setDefaultZoomEmailId(res.user.zoom_email_id && res.user.zoom_email_id)
      setZoomApiKey(res.user.zoom_api_key && res.user.zoom_api_key)
      setZoomApiSecretKey(res.user.zoom_api_secret_key && res.user.zoom_api_secret_key)
      setZoomEmailId(res.user.zoom_email_id && res.user.zoom_email_id)
    }
  }
  const fetchProfileFailure = err => {}

  //FOR FETCHING THE USER GROPS DETAILS CALLBACKS
  const successOrgGrpList = res => {
    setGrpsLoader(false)
    setOrgGrpsList(res && res.data)
  }
  const failureOrgGrpList = err => {
    setGrpsLoader(false)
    setOrgGrpsList([])
  }

  //FOR FETCHING PARTICULAR MENTOR STUDENTS CALLBACKS
  const successMentorStudentsList = res => {
    setMentStudLoader(false)
    let alreadyAssignedStu = res && res.data && res.data.length ? res.data : []
    let studentArrSet = []
    alreadyAssignedStu.filter(function(student){
      return studentArrSet.push(`${student.student_name} (${student.email})`)
    })
    setAssignedStudentsArr(studentArrSet)
  }
  const failureMentorStudentsList = err => {
    setMentStudLoader(false)
    setAssignedStudentsArr([])
    props.showSnackBar({
      state: false,
      message: ERR_FETCH_STUDENTS_UNDER_MENTOR,
      type: 'error'
    })
  }

  useEffect(()=>{
    if(domainInfo && domainInfo.client === 'rmbl'){
      fetchProfile({}, fetchProfileSuccess, fetchProfileFailure)
      if(userType === 'Instructor'){
        const payload = {
          is_reporting: true
        }
        fetchOrgGrpsList(payload, successOrgGrpList, failureOrgGrpList)
      }else{
        fetchOrgGrpsList({}, successOrgGrpList, failureOrgGrpList)
      }
      if(userType === 'Mentor' || ( userType === 'Instructor' && userDetails && userDetails.roles && userDetails.roles.length && userDetails.roles.length === 2)){
        const payload = { mentor_id: userDetails.id }
        fetchMentorStudentsList(payload, successMentorStudentsList, failureMentorStudentsList)
      }
    }
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  },[])

  const handleZoomKeysChange = (event,type) => {
    if(type === 'zoomemail'){
      setZoomEmailId(event.target.value)
    }else if(type === 'apiKey'){
      setZoomApiKey(event.target.value)
    }else{
      setZoomApiSecretKey(event.target.value)
    }
    setValidation({
      emailId: false,
      apiKey: false,
      apiSecretKey: false
    })
  }

  //ZOOM SEND KEYS CALLBACKS
  const successZoomSendKeys = res => {
    setInProgress(false)
    setShowSecretKey(false)
    props.showSnackBar({
      state: true,
      type: 'success',
      message: (defaultZoomApiKey || defaultZoomApiSecretKey ) ? SUCCESS_UPDATE_ZOOM_SECRET_KEYS : SUCCESS_ADD_ZOOM_SECRET_KEYS
    })
    fetchProfile({}, fetchProfileSuccess, fetchProfileFailure)
  }
  const failureZoomSendKeys = err => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      type: 'error',
      message: (defaultZoomApiKey || defaultZoomApiSecretKey ) ? ERROR_UPDATE_ZOOM_SECRET_KEYS : ERROR_ADD_ZOOM_SECRET_KEYS
    })
  }

  //ON SUBMIT ZOOM KEYS
  const onSubmitKeys = () => {
    if(!zoomEmailId){
      setValidation({
        emailId: true,
        apiKey: false,
        apiSecretKey: false
      })
    }else if(!zoomApiKey){
      setValidation({
        emailId: false,
        apiKey: true,
        apiSecretKey: false
      })
    }else if(!zoomApiSecretKey){
      setValidation({
        emailId: false,
        apiKey: false,
        apiSecretKey: true
      })
    }else{
      setValidation({
        emailId: false,
        apiKey: false,
        apiSecretKey: false
      })
      setInProgress(true)
      const payload = {
        zoom_email_id: zoomEmailId,
        zoom_api_key: zoomApiKey,
        zoom_api_secret_key: zoomApiSecretKey
      }
      updateProfile(payload, successZoomSendKeys, failureZoomSendKeys)
    }
  }

  const handleClickShowPassword = () => {
    setShowSecretKey(!showKeySecret)
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const getLoaderView = count => {
    let children = []
    for (let i = 0; i < count; i++) {
      children.push(
        <Skeleton widthRandomness={0} key={i} count={1} width="15%" height='20px' color='#e0e0e0' borderRadius="25px" />
      )
    }
    return (
      children
    )
  }

  const onViewMentees = (type, data) => {
    if(type === 'showmentees'){
      setOpenMenteesPopup(true)
    }else{
      setGrpDetailsPopup(true)
      let grpMembers = data && Object.keys(data) && Object.keys(data).length && data.group_members
      let grpMemNames = []
      grpMembers.filter(function(grp){
        return grpMemNames.push(`${grp.name} (${grp.email}) - ${grp.role}`)
      })
      setSelectedGrpData(grpMemNames)
    }
  }
  const onCloseMentees = () => {
    setOpenMenteesPopup(false)
    setGrpDetailsPopup(false)
    setSelectedGrpData([])
  }

  return (
    <div className={classes.root}>
      <main>
        <div id="mainH1Tag" tabIndex="-1" className="accessibility">
          <h1>User Profile</h1>
        </div>
      </main>
      <Paper className={classes.paper}>
        <Typography component="h6" variant="h5" align="center">
          <b style={{ color: style.fontColour._blue }}>User Profile</b>
        </Typography>
        <Typography variant="caption" display="block" className={classes.infoBlock}>
          <span className={classes.headWrapper}>Name: </span>
          <span>{userName}</span>
        </Typography>
        <Typography variant="caption" display="block" className={classes.infoBlock}>
          <span className={classes.headWrapper}>User Id: </span>
          <span>{userDetails.id}</span>
        </Typography>
        <Typography variant="caption" display="block" className={classes.infoBlock}>
          <span className={classes.headWrapper}>Email: </span>
          <span>{userDetails.username}</span>
        </Typography>
        <Typography variant="caption" display="block" className={classes.infoBlock}>
          <span className={classes.headWrapper}>{ userDetails.roles && userDetails.roles.length && userDetails.roles.length === 2 ? 'Roles: ' : 'Role: ' }</span>
          <span>
            {userType === 'Instructor' ? (
              <React.Fragment>
                {userDetails && userDetails.roles && userDetails.roles.length && userDetails.roles.length === 2 ? (
                  userDetails.roles.map((role, index) => {
                    return (
                      <span key={index}>{role}{userDetails.roles[index+1] ? ',' : ''} </span>
                    )
                  })
                ) : (
                  userType
                )}
              </React.Fragment>
            ) : (
              userType
            )}
          </span>
        </Typography>
        { domainInfo && domainInfo.client === 'rmbl' ? (
          <React.Fragment>
            <Typography variant="caption" display="block" className={classes.infoBlock}>
              <span className={classes.headWrapper}>My Group(s): </span>
              { grpsLoader ? (
                getLoaderView(4)
              ) : (
                <React.Fragment>
                  {orgGrpsList && orgGrpsList.length ? (
                    orgGrpsList.map((grp, index) => {
                      return (
                        <span key={index}>
                          <Chip
                            variant="outlined"
                            label={`${grp.group_title} - (${grp.members_count})`}
                            className={classes.chipWrapper}
                            onClick={() => {onViewMentees('showgrpdetails', grp)}}
                          />
                        </span>
                      )
                    })
                  ) : (<span style={{ marginLeft: '3px', fontSize: '14px' }}>No groups found!</span>) }
                </React.Fragment>
              )}
            </Typography>
            { userType === 'Mentor' || (userType === 'Instructor' && userDetails && userDetails.roles && userDetails.roles.length && userDetails.roles.length === 2) ? (
              <React.Fragment>
                <Typography variant="caption" display="block" className={classes.infoBlock}>
                  <span className={classes.headWrapper}>My Mentee(s): </span>
                  { mentorStudLoader ? (
                    getLoaderView(1)
                  ) : (
                    <span>
                      {assignedStudentsArr && assignedStudentsArr.length ? (
                        <React.Fragment>
                          <Button color="primary" size="small" onClick={()=>{onViewMentees('showmentees')}} style={{ marginLeft: '3px', textTransform: 'none' }}>View Mentees</Button>
                        </React.Fragment>
                      ) : (<span style={{ marginLeft: '3px', fontSize: '14px' }}>No mentees found!</span>) }
                    </span>
                  )}
                </Typography>
              </React.Fragment>
            ) : null }
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={12}>
                <TextField
                  className={classes.nameFields}
                  id="zoomemail"
                  name="Zoom Email"
                  label="Zoom Email ID"
                  helperText="The email which you use for creating zoom event"
                  required
                  error={validation.emailId}
                  fullWidth
                  onChange={(event)=>{handleZoomKeysChange(event,'zoomemail')}}
                  value={zoomEmailId}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6} xl={12}>
                <TextField
                  className={classes.nameFields}
                  id="API KEY"
                  name="Api Key"
                  label="Zoom API Key"
                  autoComplete="new-password"
                  error={validation.apiKey}
                  required
                  fullWidth
                  onChange={(event)=>{handleZoomKeysChange(event,'apiKey')}}
                  value={zoomApiKey}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '15px' }}>
                <TextField
                  className={classes.nameFields}
                  id="API SECRET KEY"
                  name="Api SECRET Key"
                  autoComplete="new-password"
                  label="Zoom API Secret Key"
                  type={showKeySecret ? 'text' : 'password'}
                  error={validation.apiSecretKey}
                  required
                  fullWidth
                  onChange={(event)=>{handleZoomKeysChange(event,'secretKey')}}
                  value={zoomApiSecretKey}
                  InputProps={{
                    endAdornment: 
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle secret visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showKeySecret ? <Visibility className={classes.iconWrapper}/> : <VisibilityOff className={classes.iconWrapper}/>}
                        </IconButton>
                      </InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              onClick={onSubmitKeys}
              className={classes.button}
              disabled={inProgress}
            >
              {inProgress ? (
                <CircularProgress size={25} className={classes.buttonProgress} />
              ) : ( null )}
              { (defaultZoomApiKey || defaultZoomApiSecretKey) ? 'Update Zoom Keys' : 'Add Zoom Keys' }
            </Button>
          </React.Fragment>
        ) : null }
      </Paper>
      { openMenteesPopup ? (
        <ViewDetailsPopup
          open={openMenteesPopup}
          close={onCloseMentees}
          data={assignedStudentsArr}
          type="showmentees"
        />
      ) : null }
      { openGrpDetailsPopup ? (
        <ViewDetailsPopup
          open={openGrpDetailsPopup}
          close={onCloseMentees}
          data={selectedGrpData}
          type="showgrpdetails"
        />
      ) : null }
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(ProfileView);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link'
import ResizablePanels from "resizable-panels-react";
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import Divider from '@material-ui/core/Divider';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import AlertMessages from '../Common/AlertMessages';
import { submitGrade } from '../../store/Assignments/actionCreator'
import { convertUTCTimeZone } from '../../Helpers/basic'
import { SUCCESS_SUBMIT_ASSIGNMENT_GRADE, SUCCESS_UPDATE_ASSIGNMENT_GRADE } from '../Common/Constants/successMessages'
import { ERROR_SUBMIT_ASSIGNMENT_GRADE, ERROR_UPDATE_ASSIGNMENT_GRADE } from '../Common/Constants/errorMessages'
import SubmissionFetchLoader from '../Common/skeletonLoaders/assignmentSubmissonLoader'
import GraderLoader from '../Common/skeletonLoaders/graderLoader'
import PlagiarisimChecker from '../Common/PlagiarisimChecker'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    height: '49px',
    backgroundColor: '#F5F5F5',
  },
  title: {
    flex: 1,
    marginTop: '-4px',
    fontWeight: '600',
    color: '#3f51b5',
    fontSize: '17px',
  },
  iconWrapper: {
    marginTop: '-24px'
  },
  textWrapper: {
    fontWeight: '600',
    color: '#3f51b5',
    fontSize: '17px',
    textAlign: 'center',
    marginTop: '10px'
  },
  detailsHead: {
    fontSize: '15px'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  dueDateWrapper: {
    color: '#616161',
    textAlign: 'initial',
    marginTop: '-2px',
    fontSize: '12px'
  },
  textAreaWrapper: {
    resize: 'vertical',
    height: '71px',
    width: '100%',
    borderRadius: '5px',
    overflowY: 'auto',
    '&:focus': {
      borderColor:'#01579b'
    },
    textIndent: '9px',
    fontSize: '15px'
  },
  BtnWrapper: {
    marginTop: '10px',
    float: 'right'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '20px',
    flexGrow: 1,
    marginLeft: '10px',
    width: '100px',
    backgroundColor: '#f5f5f5'
  },
  iconButton: {
    color: '#01579b'
  },
  UserListWrapper: {
    height: '447px',
    overflow: 'auto',
    border: '18px',
    width: '139px',
    boxShadow: '1px 2px 3px 1px #ccc',
    borderRadius: '5px',
    marginTop: '20px'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StudentReports(props) {
  const classes = useStyles();
  const open = props.openMode === 'editGrade' ? props.openReportEditPopup : props.openReportPopup
  const completedUserList = props.completedUserList ? props.completedUserList : []

  const defaultComment = props.studentSubmissions && props.studentSubmissions.comment ? props.studentSubmissions.comment : ''
  const defaultPoints = props.studentSubmissions && props.studentSubmissions.grade_point ? props.studentSubmissions.grade_point : 0

  const [gradePoints, setGradePoints] = React.useState(defaultPoints)
  const [gradeComments,setGradeComments] = React.useState(defaultComment)

  const [inProgress,setInProgress] = React.useState(false);
  const [loaderInProgress, setLoaderInProgress] = React.useState(false)
  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })

  const defaultState = props.studentSubmissions && props.studentSubmissions.plag_data && props.studentSubmissions.plag_data.solution && props.studentSubmissions.plag_data.solution ? props.studentSubmissions.plag_data.solution : null
  const [plagiarismForSolution, setPlagiarismForSolution] = React.useState(defaultState)
  const [plagiarismForFiles, setPlagiarismForFiles] = React.useState({})

  const textFiledReadAccess = props.view === 'gradeBook' ? true : false

  const assignmentDetails = {
    title: props.assignmentDetails && props.assignmentDetails.title ? props.assignmentDetails.title : '',
    due_days: props.assignmentDetails && props.assignmentDetails.duration_details ? props.assignmentDetails.duration_details : 0,
    assignment_id: props.assignmentDetails && props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null,
    points: props.assignmentDetails && props.assignmentDetails.points ? props.assignmentDetails.points : 0,
  }

  const studentSubmissions = {
    name: props.studentSubmissions && props.studentSubmissions.user_name ? props.studentSubmissions.user_name : '',
    desc: props.studentSubmissions && props.studentSubmissions.content ? props.studentSubmissions.content : '',
    user_id: props.studentSubmissions && props.studentSubmissions.user_id ? props.studentSubmissions.user_id: '',
    attachments: props.studentSubmissions && props.studentSubmissions.attachments ? props.studentSubmissions.attachments : []
  }

  const values = {
    comments: props.studentSubmissions && props.studentSubmissions.comment ? props.studentSubmissions.comment : '',
    grade_points: props.studentSubmissions && props.studentSubmissions.grade_point ? props.studentSubmissions.grade_point : 0
  }

  function createMarkup(data) {
    return {
      __html: data
    };
  }
  const onPlagiarisimSuccessCheck = (res, contentSource) => {
    if(contentSource === 'solution') {
      setPlagiarismForSolution(res.results)
    } else {
      let updatedPlagiarismForFiles = {
        ...plagiarismForFiles,
        [contentSource]: res.results
      }
      setPlagiarismForFiles(updatedPlagiarismForFiles)
    }
  }
  const renderDownoadableFile = () => {
    let arr = [];
    studentSubmissions && studentSubmissions.attachments && studentSubmissions.attachments.map((file, index) => {
      let subName = file.split('?')[0];
      var lastIndex = subName.lastIndexOf('.');
      let fileType = subName.slice(lastIndex);
      let type = '';
      if (fileType === '.ppt' || fileType === '.pptx') {
        type = 'ppt';
      } else if (fileType === '.doc' || fileType === '.docx') {
        type = 'doc';
      }
      else if (fileType === '.pdf') {
        type = 'pdf';
      }
      /*
      arr.push(
        <Tooltip key={index} title={fileType}>
          <IconButton aria-label="Download Document">
            <a href={file} target='_blank'>{type === 'pdf' ? <PictureAsPdfIcon className={classes.iconButton}/> : type === 'ppt' ? <PictureInPictureIcon className={classes.iconButton}/> : <DescriptionIcon className={classes.iconButton}/>}</a>
          </IconButton>
        </Tooltip>
      )
      */
      const fName1 = file && file.split('?')[0]
      const fName2 = fName1 && fName1.split('/media')[1]
      const fName = 'media' + fName2

      let plagData = null
      if(Object.keys(plagiarismForFiles).length) {
        if(plagiarismForFiles[fName]) {
          plagData = plagiarismForFiles[fName]
        }
      } else {
        if(props.studentSubmissions && props.studentSubmissions.plag_data[fName]) {
          plagData = props.studentSubmissions.plag_data[fName]
        }
      }
      arr.push(
        <TableRow key={index}>
          <TableCell component="th" scope="row" align="center">
            {type === 'pdf' ? <PictureAsPdfIcon className={classes.iconButton}/> : type === 'ppt' ? <PictureInPictureIcon className={classes.iconButton}/> : <DescriptionIcon className={classes.iconButton}/>}
          </TableCell>
          <TableCell align="center">
            <IconButton aria-label="Download Document">
              <a href={file} target='_blank'><CloudDownloadIcon /></a>
            </IconButton>
          </TableCell>
          <TableCell align="center">
            <PlagiarisimChecker
              plagiarismObj={plagData}
              contentSource={fName}
              displayInfo={{
                studentName: props.studentSubmissions.user_name,
                assignmentName: props.assignmentDetails.title,
              }}
              onSubmitData={ {
                    user_id: props.studentSubmissions.user_id,
                    submission_id: props.submissionId,
                    assignment_id: props.assignmentDetails.assignment_id,
                    attachment_index: index,
                    isDoc: true
                  }}
              onPlagiarisimSuccessCheck={onPlagiarisimSuccessCheck}
              buttonComponent="eye-with-no-text"
            />
          </TableCell>
        </TableRow>
      )
    })
    return arr;
  }

  const handleChangeGrade = event => {
    setGradePoints(event.target.value)
  }

  const handleChangeGradeComments = event => {
    setGradeComments(event.target.value)
  }

  const submitSuccessCallBack = res => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_SUBMIT_ASSIGNMENT_GRADE,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    props.assignmentReportFetch()
  }

  const submitFailureCallBack = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_SUBMIT_ASSIGNMENT_GRADE,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const updateSuccessCallBack = res => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_UPDATE_ASSIGNMENT_GRADE,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    props.assignmentReportFetch()
  }

  const updateFailureCallBack = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UPDATE_ASSIGNMENT_GRADE,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }

  const submitForm = event => {
    event.preventDefault()
    if(gradePoints === ''){
      setValidationError({
        error: true,
        errorMessage: 'Please fill Information'
      })
    }else if(gradePoints > (assignmentDetails && assignmentDetails.points)){
      setValidationError({
        error: true,
        errorMessage: `grade should be less than ${assignmentDetails.points} pts`
      })
    }else if(isNaN(gradePoints) || gradePoints < 0 || gradePoints > 100){
      setValidationError({
        error: true,
        errorMessage: 'Please give valid points'
      })
    }
    else{
      setInProgress(true)
        const payload = {
          submission_id: props.submissionId,
          grade_point: Number(gradePoints),
          comment: gradeComments
        }
        submitGrade(payload, props.openMode === 'editGrade' ? updateSuccessCallBack : submitSuccessCallBack , props.openMode === 'editGrade' ? updateFailureCallBack : submitFailureCallBack)
      }
  }
  const handleClosePopup = () => {
    if(props.openMode === 'editGrade'){
      props.closeReportEditMode()
    }else{
      props.closeReportPopup()
    }
  }

  const onstudentNameClick = data => {
    setLoaderInProgress(true)
    if(props.openMode === 'editGrade'){
      props.triggerReportEdit(data)
    }else{
      props.triggerReportViewPopup(data)
    }
    setTimeout(()=>{
      setLoaderInProgress(false)
    },1000)
  }

    useEffect(() => {
      const defaultComment = props.studentSubmissions && props.studentSubmissions.comment ? props.studentSubmissions.comment : ''
      const defaultPoints = props.studentSubmissions && props.studentSubmissions.grade_point ? props.studentSubmissions.grade_point : 0
      setGradeComments(defaultComment)
      setGradePoints(defaultPoints)
    }, [props.studentSubmissions])

  const dateFormat = (due_days) => {
    return(`Due Date: ${convertUTCTimeZone(due_days)}`)
  }

  const plagiarismActive = true
  return (
    <div>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Assignment grade view popup</h1>
      </div>
      <Dialog fullScreen open={open} onClose={handleClosePopup} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              { assignmentDetails.title ? assignmentDetails.title : '' }
              <p className={classes.dueDateWrapper}>
                {dateFormat(assignmentDetails && assignmentDetails.due_days)} { assignmentDetails.points ? (`| ${assignmentDetails.points} pts`) : null }
              </p>
            </Typography>
            <IconButton edge="start" className={classes.iconWrapper} onClick={handleClosePopup} aria-label="close dialog">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main>
        <form autoComplete="off" onSubmit={evnt => { evnt.preventDefault() }} >
        <div className="App">
          <ResizablePanels
            displayDirection="row"
            width="100%"

            panelsSize={ assignmentDetails && assignmentDetails.points ? [75, 25] : [90,10]}
            sizeUnitMeasure="%"
            resizerColor="darkgray"
            resizerSize="6px"
          >
            <div style={{ display: 'flex', minWidth: "100px", marginLeft: '20px', marginRight: '20px' }} >
              { props.view !== 'gradeBook' ? (
                <div className={classes.UserListWrapper}>
                  <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                    <p>Completed user list</p>
                  </div>
                  {completedUserList && completedUserList.map((data, index) => (
                    <React.Fragment key={index}>
                      <ListItem style={{ textAlign: 'center'}}>
                        <Link onClick={() => { onstudentNameClick(data) }} style={{color: data.user_name === studentSubmissions.name ? '#795548' : '#009688' , textDecoration: 'none', cursor: 'pointer'}} >
                          { data && data.user_name && data.user_name.length > 15 ? (
                            data && data.user_name && data.user_name.substr(0,10) + '...'
                          ) : (
                            data && data.user_name && data.user_name
                          )}
                          <span style={{ color: data.user_name === studentSubmissions.name ? '#795548' : '#009688' , display: 'flex', fontSize: '8px', marginLeft: '63px'}}>{`ID (${data.user_id && data.user_id})`}</span>
                        </Link>
                      </ListItem>
                      <Divider aria-hidden="true"/>
                    </React.Fragment>
                  ))}
                </div>
              ) : null }
              { loaderInProgress ? (
                <div style={{ marginLeft: '16px'}}>
                  <SubmissionFetchLoader/>
                </div>
              ) :
                <Paper className={classes.paper}>
                  <Typography variant="caption" display="block" className={classes.infoBlock}>
                    <p><b>Name:</b> </p>
                    <p>{studentSubmissions.name}</p>
                    { studentSubmissions.desc !== 'undefined' ? (
                      <React.Fragment>
                      <span><b>Solution:</b> </span>
                      <Paper elevation={3} style={{ padding: 10 }}>
                        <span className={classes.detailsHead}>
                          <div dangerouslySetInnerHTML={createMarkup(studentSubmissions.desc)}></div>
                        </span>
                      </Paper>
                      <div>
                          <PlagiarisimChecker
                            plagiarismObj={plagiarismForSolution}
                            contentSource="solution"
                            displayInfo={{
                              studentName: props.studentSubmissions.user_name,
                              assignmentName: props.assignmentDetails.title,
                            }}
                            onSubmitData={ {
                                  user_data: props.studentSubmissions.content,
                                  user_id: props.studentSubmissions.user_id,
                                  submission_id: props.submissionId,
                                  assignment_id: props.assignmentDetails.assignment_id,
                                  content: props.studentSubmissions.content
                                }

                            }
                            onPlagiarisimSuccessCheck={onPlagiarisimSuccessCheck}
                            buttonComponent="eye-with-text"
                          />
                      </div>
                      </React.Fragment>
                    ) : null }
                    { renderDownoadableFile().length ? (
                      <React.Fragment>
                        <b>Uploaded attachments:</b>
                        <TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Attachment</TableCell>
                                <TableCell align="center">Download</TableCell>
                                <TableCell align="center">Plagiarism</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {renderDownoadableFile()}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </React.Fragment>
                    ) : null }
                  </Typography>
                </Paper>
              }
              </div>
            <div style={{ marginLeft: '10px', marginTop: '10px', height: "100%", padding: '14px', textAlign: 'initial' }}>
              {validationError.error ? (
                <div style={{ width: '85%'}}>
                  <AlertMessages status="error" message={validationError.errorMessage} />
                </div>
              ) : null}
              { loaderInProgress ? (
                <div>
                  <GraderLoader/>
                </div>
              ) :
              <React.Fragment>
                { assignmentDetails && assignmentDetails.points ? (
                  <React.Fragment>
                    <p><b>Assignment</b> </p>
                    <p>Grade out of {assignmentDetails.points ? `${assignmentDetails.points} pts` : 0 }</p>
                    <TextField
                      id="gradepoints"
                      label="Points"
                      type="number"
                      variant="outlined"
                      size="small"
                      required
                      defaultValue={values.grade_points}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 3,
                        readOnly: textFiledReadAccess
                      }}
                      onChange={handleChangeGrade}
                    />
                    <p><b>Assignment Comment</b></p>
                    <TextareaAutosize
                      className={classes.textAreaWrapper}
                      aria-label="minimum height"
                      rowsMin={4}
                      defaultValue={values.comments}
                      disabled={textFiledReadAccess}
                      placeholder="Add a Comment"
                      onChange={handleChangeGradeComments}
                    />
                    {inProgress ? (
                      <CircularProgress size={30} className={classes.buttonProgress} />
                    ) : null }
                    { props.view !== 'gradeBook' ? (
                      <Button className={classes.BtnWrapper } disabled={inProgress} onClick={(evnt) => {submitForm(evnt)}} variant="contained" size="small" color="primary">
                        {props.openMode === 'editGrade' ? 'Update' : 'Submit'}
                      </Button>
                    ) : null }
                  </React.Fragment>
                ) : null }
              </React.Fragment>
            }
            </div>
          </ResizablePanels>
        </div>
        </form>
        </main>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(StudentReports);

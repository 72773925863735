import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from "@material-ui/core/Chip";
import Link from '@material-ui/core/Link';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import SearchIcon from '@material-ui/icons/Search';
//LOCAL IMPORTS
import { bytesToSize } from '../../Helpers/basic';
import { apiConfig } from '../../Configs/apiConfig'
import { style } from '../../Styles/theme';
import LeftMenu from "../AppLayout/leftMenu";
import { showSnackBar } from "../../store/AlertMessages/actionCreator";
import { fetchOrgGrpsList } from '../../store/ManageGroups/actionCreator';
import { getDomainInfo } from '../../Helpers/basic';
import { colorStyles } from '../../Styles/theme';
import CalenderSiderLoader from '../Common/skeletonLoaders/calenderSiderLoader';
import { fetchProfile } from '../../store/Auth/actionCreator';
import StoreLocatorPopup from './caseysStoreLocatorPopup';
import { listEventsCalendar, getCourseList, createMeetingEvent, deleteMeetingEvent, modifyMeetingEvent, fetchEventLabels } from "../../store/Meetings/actionCreator";
import ViewEventParticipantsPopup from './caseysEvntSlotMembersPopup';
import StoreLogo from '../../static/caseysstorelogo.png';

//TEXT EDITOT CHANGES
import FormHelperText from '@material-ui/core/FormHelperText';
import TextEditor from '../Common/TextEditor';


const localizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: { backgroundColor: "lightblue" }
  });

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const inpersonMettingOptions = [
  {
    "id": 1,
    "location": "Remote"
  }
]

function Meetings(props) {
  const classes = useStyles();
  const domainDetails = getDomainInfo()
  const mobileView = useMediaQuery('(max-width:600px)');
  const showNormalTextFieldAgenda = true;

  //FOR FETCHING THE ENCODED URL FROM MAIN URL
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1] && array[1] === 'ZnJvbWIyYg==' ? 'fromB2BCreateEvent' : null

  const [userDetails, setUserDetails] = useState({})
  const [eventsListLoader, setEventsListLoader] = useState(true)
  const [eventsSiderList, setEventsSiderList] = useState([]);
  const [eventsListData, setEventsListData] = useState([]);
  const [eventsListDataCal, setEventsListDataCal] = useState([]);
  const [meetingUniqId, setMeetingUniqId] = useState();
  const [title, setTitle] = useState("");

  const defaultMeetingType = domainDetails.client !== 'caseys' ? 2 : 10
  const [meetingType, setMeetingType] = useState(defaultMeetingType);

  const [meetingTypeName, setMeetingTypeName] = useState("");
  const [agenda, setAgenda] = useState("");
  const [createEvent, setCreateEvent] = useState(false);
  const [renderMeetingInfo, setRenderMeetingInfo] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [recType, setRecType] = useState(1);
  const [recInterval, setRecInterval] = useState(1);
  const [approvalType, setApprovalType] = useState(0);
  const [approvalTypeName, setApprovalTypeName] = useState("");
  const [recordingType, setRecordingType] = useState("none");
  const [courseList, setCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [recTypeName, setRecTypeName] = useState("");
  const [recurringDays, setRecurringDays] = useState([]);
  const [startURL, setStartURL] = useState("");
  const [joinURL, setJoinURL] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [meetingId, setMeetingId] = useState();
  const [errRecInteval, setErrRecInterval] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [modifyEventInfo, setModifyEventInfo] = useState(false);
  const [renderStartBtn, setRenderStartBtn] = useState(false);
  const [dtTimeErrFlag, setDtTimeErrFlag] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedGroupMembersList, setSelectedGroupMembersList] = useState([]);
  const [defaultSelGrpMemList, setDefaultSelGrpMemList] = useState([])
  const [displayMembersList, setDisplayMembersList] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [membersEmptyCheck, setMemEmptyCheck] = useState(false)
  const [participantsList, setParticipantsList] = useState([]);
  const [eventLables, setEventLables] = useState([])
  const [selectedEventLabels, setSelectedEventLabels] = useState([])
  const [attachmentFileDetails, setAttachmentFileDetails] = useState({});
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = useState('')
  //FOR INPERSON MEETING SELECTION
  const [selectedInPersLoc, setSelectedInPersLoc] = useState([]);
  const [defaultInPernLoacations, setDefaultInPernLocations] = useState([]);
  const [locEmptyErr, setLocEmptyErr] = useState(false);
  //FOR SEARCH FILETER
  const [filterSearchTerm, setFilterSearchTerm] = React.useState('')
  const [filterApplied, setFilterApplied] = React.useState(false);
  //FOR OTHER CLINT START DATES, DURATIONS, NUMBER OF SEATS
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD[T]HH:mm")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [duration, setDuration] = useState(30);
  const [numberOfSeats, setNumberOfSeats] = useState(1);

  //FOR CASEYS START DATE FOR EVENTS
  const [eventNumOfSlots, setEventNumOfSlots] = useState([{
    event_starttime: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
    event_duration: 30,
    event_seats: 1
  }]);
  const [openStoreLocatorPopup, setOpenStoreLocatorPopup] = useState(false);
  const [selectedStoreLoaction, setSelectedStoreLocation] = useState({})

  const [daysCheckBox, setDaysCheckBox] = useState({
    sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false
  });
  const [meetingSettings, setMeetingSettings] = useState({
    host_video: false,
    participant_video: false,
    join_before_host: false,
    watermark: false,
    waiting_room: false,
    registrants_email_notification: false,
    meeting_authentication: false,
  });

  const daysOfWeek = [
    { id: 1, name: "sun", label: "SUN" },
    { id: 2, name: "mon", label: "MON" },
    { id: 3, name: "tue", label: "TUE" },
    { id: 4, name: "wed", label: "WED" },
    { id: 5, name: "thu", label: "THU" },
    { id: 6, name: "fri", label: "FRI" },
    { id: 7, name: "sat", label: "SAT" },
  ];

  const recurrenceTypeList = [
    { id: 1, name: "Daily" },
    { id: 2, name: "Weekly" },
    { id: 3, name: "Monthly" },
  ];

  //FOR OPENING THE CASEYS PARTICIPANTS POPUP
  const [eventParticipantsPopup, setEventParticipantsPoup] = useState({
    slotData: {},
    dialogPopup: false
  })

  //FOR DELETE DIALOG POPUP
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSettingsChange = (event) => {
    setMeetingSettings({
      ...meetingSettings,
      [event.target.name]: event.target.checked,
    });
  };

  const handleDaysCheckBoxChanged = (event) => {
    setDaysCheckBox({
      ...daysCheckBox,
      [event.target.name]: event.target.checked,
    });
  };


  //FOR OPENING THE CREATE EVENT FROM REDIRECT FROM B2B BUTTON
  useEffect(() => {
    if (base64EncodeValue === 'fromB2BCreateEvent') {
      createNewEvent()
    }
  }, [])

  //create event
  const createNewEvent = () => {
    setModifyEventInfo(false);
    setMemEmptyCheck(false)
    setTitleError(false)
    setSelectedGroupMembersList([]);
    setSelectedGroup([]);
    setSelectedMembers([]);
    setSelectedUsers([]);
    setSelectedEventLabels([])
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
    setDtTimeErrFlag(false);
    setCreateEvent(true);
    setTitle("");
    setAgenda("");
    setDuration(30);
    setMeetingType(domainDetails.client !== 'caseys' ? 2 : 10);
    setRecType(1);
    setRecInterval(1);
    setApprovalType(0);
    setRecordingType("none");
    setStartDate(moment(new Date()).format("YYYY-MM-DD[T]HH:mm"));
    setMeetingSettings({
      host_video: false,
      participant_video: false,
      join_before_host: false,
      watermark: false,
      waiting_room: false,
      registrants_email_notification: false,
      meeting_authentication: false,
    });
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);

    //FOR CASEYS
    setEventNumOfSlots([{
      event_starttime: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
      event_duration: 30,
      event_seats: 1
    }])
    setSelectedStoreLocation({})
  };

  const handleMeetingTypeChange = (event) => {
    setMemEmptyCheck(false)
    setLocEmptyErr(false)
    if (meetingType !== 10) {
      setEventNumOfSlots([{
        event_starttime: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
        event_duration: 30,
        event_seats: 1
      }])
      setSelectedStoreLocation({});
    }
    setMeetingType(event.target.value);
  };

  const handleRecordingTypeChange = (event) => {
    setRecordingType(event.target.value);
  };

  const handleApprovalTypeChange = (event) => {
    setApprovalType(event.target.value);
  };

  const handleRecTypeChange = (event) => {
    let daysObj = {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    };
    setDaysCheckBox(daysObj)
    setRecType(event.target.value);
  };

  const handleIntervalChange = (event) => {
    setErrRecInterval(false);
    setRecInterval(event.target.value);
  };

  const successLabelFetch = res => {
    setEventLables(res && res.data && res.data)
  }
  const failureLabelsFetch = err => {
    setEventLables([])
    props.showSnackBar({
      state: false,
      message: "Something went wrong! while fetching labels!",
      type: "error",
    });
  }

  //FOR FETHCING THE ZOOM KEYS AND SECRET KEYS TO UNABLE CREATE EVENT BUTTON
  const fetchProfileSuccess = res => {
    if (res && res.user && Object.keys(res.user) && Object.keys(res.user).length) {
      setUserDetails(res.user)
    }
  }
  const fetchProfileFailure = err => {
    props.showSnackBar({
      type: 'error',
      message: 'Something went wrong! While fetching zoom keys!',
      status: false
    })
    setUserDetails({})
  }

  useEffect(() => {
    if (getDomainInfo().client !== 'caseys') {
      fetchCourseList();
    }
    fetchEvents();
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
    if (getDomainInfo().client === 'rmbl' || getDomainInfo().client === 'caseys') {
      fetchGroupLists();
      if (getDomainInfo().client === 'rmbl') {
        fetchEventLabels({}, successLabelFetch, failureLabelsFetch)
        fetchProfile({}, fetchProfileSuccess, fetchProfileFailure)
      }
    }
  }, []);

  useEffect(() => {
    if (selectedGroupMembersList.length > 0 || selectedUsers.length > 0) {
      setDisplayMembersList(true);
    } else if (selectedGroupMembersList.length === 0 && selectedUsers.length === 0) {
      setDisplayMembersList(false);
    }
  }, [selectedGroupMembersList, selectedUsers])

  const succCB = (res) => {
    setGroupList(res && res.data && res.data)
    let members = res && res.data.length && res.data.flatMap((item) => item.group_members).flat();
    let uniqueResData = members && members.length && members.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    setMembers(uniqueResData);
  }
  const failCB = (err) => {
    setGroupList([]);
    setMembers([]);
  }

  const fetchGroupLists = () => {
    fetchOrgGrpsList({}, succCB, failCB)
  }

  const fetchCourseList = () => {
    getCourseList(successCB, failureCB);
  };

  const successCB = (res) => {
    let crsList = res.results.filter(function (obj) {
      return obj.published == true;
    });
    setCourseList(crsList);
  };
  const failureCB = (error) => {
    setCourseList([]);
  };

  const handleSelectGroup = (grpName) => {
    setMemEmptyCheck(false)
    setDisplayMembersList(true);
    setSelectedGroup(grpName);
    let groupListVal = groupList;
    let arr = [...defaultSelGrpMemList];
    let existingGroupMemberList = [...defaultSelGrpMemList];
    if (groupListVal && groupListVal.length > 0) {
      if (grpName && grpName.length) {
        grpName.forEach((item) => {
          let groupObj = groupListVal.find((grp) => grp.group_title === item.group_title)
          let selObj = existingGroupMemberList.find((selTeam) => selTeam.group_title === item.group_title)
          if ((defaultSelGrpMemList && !defaultSelGrpMemList.length) && selObj) {
            let grpMembers = selObj.group_members
            let Obj = { group_title: item.group_title, group_members: grpMembers }
            arr.push(Obj)
          } else if (groupObj) {
            let grpMembers = groupObj.group_members
            let Obj = {}
            if (arr && arr.length) {
              const newData = arr.find((data => data.group_title === item.group_title))
              if (newData && Object.keys(newData) && Object.keys(newData).length) {
                newData['group_members'] = item.group_members
              } else {
                arr.push({ 'group_title': item.group_title, 'group_members': grpMembers })
              }
            } else {
              Obj = { group_title: item.group_title, group_members: grpMembers }
              arr.push(Obj)
            }
          }
        })
        setSelectedGroupMembersList(arr)
      } else {
        setSelectedGroupMembersList([])
      }
    }
  }

  const handleTeamMemberDelete = (grpToDelete) => {
    let selectedMemList = [...selectedGroupMembersList];
    if (selectedMemList.length > 0) {
      selectedMemList.forEach((item) => {
        let removedMemList = [];
        let teamObj = item.group_members.find((member) => member.id === grpToDelete.id);
        if (teamObj) {
          removedMemList = item.group_members.filter((member) => member.id !== grpToDelete.id);
          item["group_members"] = removedMemList;
        }
      });
      setSelectedGroupMembersList(selectedMemList);
    }
  };

  const renderMeetingDetails = (meetingNum) => {
    setLocEmptyErr(false)
    setParticipantsList([])
    setDefaultSelGrpMemList([])
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    let selectedInfo = eventsListData.find((obj) => obj.id == meetingNum);

    //FOR CASYES MOCK FLOW
    let slotsData = []
    selectedInfo.slot_timings && selectedInfo.slot_timings.length && selectedInfo.slot_timings.forEach((slot) => {
      return slotsData.push({
        event_starttime: moment(slot.event_starttime).format("YYYY-MM-DD[T]HH:mm"),
        event_endtime: moment(slot.event_endtime).format("YYYY-MM-DD[T]HH:mm"),
        event_duration: slot.event_duration,
        event_seats: slot.event_seats,
        participants: slot.participants,
        id: slot.id
      })
    })
    setEventNumOfSlots(slotsData)

    setSelectedStoreLocation(selectedInfo.store_location)
    setSelectedGroup(selectedInfo.assigned_to)

    setMemEmptyCheck(false)
    if (selectedInfo && selectedInfo.assigned_to && selectedInfo.assigned_to.length) {
      let membersArr = []
      selectedInfo.assigned_to.forEach((group) => {
        group && group.group_members && group.group_members.length && group.group_members.forEach((members) => {
          membersArr.push(members)
        })
      })
      let uniqueResData = membersArr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
      setParticipantsList(uniqueResData);
    }

    let defaultSelMembers = []
    if (selectedInfo && selectedInfo.assigned_to && Object.keys(selectedInfo.assigned_to) && Object.keys(selectedInfo.assigned_to).length) {
      defaultSelMembers = selectedInfo.assigned_to
      setDefaultSelGrpMemList(defaultSelMembers)
    }

    //for saving the inperson meeting location
    let defaultSelectedInPernLoc = []
    for (let i in selectedInfo.meeting_location) {
      defaultSelectedInPernLoc.push(selectedInfo.meeting_location[i])
    }
    setDefaultInPernLocations(defaultSelectedInPernLoc);
    setSelectedInPersLoc(defaultSelectedInPernLoc);

    setSelectedGroupMembersList(defaultSelMembers);
    setTitle(selectedInfo.topic);
    setMeetingTypeName(selectedInfo.meeting_type_display_name);
    setMeetingType(selectedInfo.meeting_type);
    setStartDate(moment(selectedInfo.start_time).format("YYYY-MM-DD[T]HH:mm"));
    setEndDate(selectedInfo.end_time);
    setDuration(selectedInfo.duration);
    setAgenda(selectedInfo.agenda);
    setMeetingUniqId(selectedInfo.id);
    setUploadedAttachmentPreview(selectedInfo && selectedInfo.attachment && selectedInfo.attachment)
    setSelectedEventLabels(selectedInfo && selectedInfo.event_labels && selectedInfo.event_labels)
    if (selectedInfo.course) {
      setSelectedCourseName(selectedInfo.course.title);
      setSelectedCourse(selectedInfo.course.id);
    } else {
      setSelectedCourseName("");
      setSelectedCourse("");
    }

    //FOR RENDERING THE START BUTTON IN DETAILS VIEW
    if (domainDetails.client !== 'caseys') {
      let startTime = selectedInfo.start_time;
      let currTime = new Date();
      let showStartButton = moment(currTime).isAfter(moment(startTime).subtract(1, "hours"));
      setRenderStartBtn(showStartButton);
    } else {
      setRenderStartBtn(false);
    }

    if (selectedInfo.recurrence_type && selectedInfo.recurrence_type !== null) {
      let reccType = recurrenceTypeList.find(
        (obj) => obj.id == selectedInfo.recurrence_type
      );
      setRecTypeName(reccType.name);
      setRecType(selectedInfo.recurrence_type);
      setRecInterval(
        selectedInfo.recurrence_repeat_interval !== null
          ? selectedInfo.recurrence_repeat_interval
          : ""
      );
    } else {
      setRecType(1);
      setRecTypeName("");
      setRecInterval(1);
    }

    if (selectedInfo.meeting_type == 8 && selectedInfo.recurrence_type !== 1) {
      let recDays = [];
      let rec = [];
      if (selectedInfo.weekly_days !== null) {
        rec = selectedInfo.weekly_days.split(",");
      }
      let daysObj = { sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false };
      rec = rec.map(Number);
      rec.map((item, index) => {
        daysOfWeek.map((day, ind) => {
          if (item == day.id) {
            daysObj[day.name] = true;
          }
        });
      });
      setDaysCheckBox(daysObj);

      if (rec.length > 0) {
        daysOfWeek &&
          daysOfWeek.map((day) => {
            if (rec.includes(day.id)) {
              recDays.push(day.label.substring(0, 3));
            }
          });
      }
      setRecurringDays(recDays);
    } else {
      setRecurringDays([]);
    }

    if (domainDetails.client !== 'caseys') {
      setMeetingId(selectedInfo.meeting_id);
    } else {
      setMeetingId(selectedInfo.id);
    }
    setStartURL(selectedInfo.start_url);
    setJoinURL(selectedInfo.join_url);
    setIsHost(selectedInfo.is_host);
    setApprovalType(selectedInfo !== null ? selectedInfo.approval_type : "");

    let approvalName =
      selectedInfo !== null && selectedInfo.approval_type == 0 ? "Automatically Approve"
        : selectedInfo.approval_type == 1
          ? "Manually Approve"
          : "No Registration Required";
    setApprovalTypeName(approvalName);
    setRecordingType(selectedInfo !== null ? selectedInfo.auto_recording : "");

    let settingsObj = {
      host_video: false,
      participant_video: false,
      join_before_host: false,
      watermark: false,
      waiting_room: false,
      registrants_email_notification: false,
      meeting_authentication: false,
    };

    Object.keys(meetingSettings).length > 0 &&
      Object.keys(meetingSettings).map((name, index) => {
        if (Object.keys(settingsObj).includes(name)) {
          settingsObj[name] = selectedInfo[name];
        }
      });
    setMeetingSettings(settingsObj);

    setRenderMeetingInfo(true);
  };

  //FOR FETCHING THE EVENTS
  const fetchEvents = () => {
    setEventsListLoader(true)
    listEventsCalendar(successCallBack, failureCallBack);
  };

  //FETCH EVENTS SUCCESS CALLBACK
  const successCallBack = (res) => {
    setEventsListLoader(false)
    let resultData = res && res.data && res.data.length ? res.data : [];
    let resObjCal = {};
    let resArrCal = [];
    let resArrayForCaseysCalender = [];
    let resObj = {};
    let resArr = [];
    let uniqueResData = []
    if (domainDetails.client !== 'caseys') {
      uniqueResData = resultData.filter((v, i, a) => a.findIndex((t) => t.meeting_id === v.meeting_id) === i);
    } else {
      uniqueResData = resultData.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    }

    setEventsListData(resultData);
    resultData && resultData.length > 0 && resultData.forEach((item) => {
      let recDays = [];
      let rec = [];
      if (item && item.weekly_days && item.weekly_days !== null) {
        rec = item.weekly_days.split(",");
      }
      rec = rec.map(Number);
      if (rec.length > 0) {
        daysOfWeek &&
          daysOfWeek.map((day) => {
            if (rec.includes(day.id)) {
              recDays.push(day.label.substring(0, 3));
            }
          });
      }
      resObjCal = {};
      if (domainDetails.client !== 'caseys') {
        resObjCal = {
          id: item.id,
          start: new Date(item.start_time),
          end: new Date(item.end_time),
          duration: item.duration,
          title: item.topic,
          agenda: item.agenda,
          courseId: item.course,
          isRecurringEvent: item.meeting_type == 8 ? true : false,
          recurringDays: recDays,
          isHost: item.is_host,
          hostDetails: item.host_details,
          joinURL: item.join_url,
          event_labels: item && item.event_labels,
          attachment: item && item.attachment,
          meeting_location: item && item.meeting_location,
          meeting_type: item.meeting_type,
          meeting_type_display_name: item.meeting_type_display_name
        };
      } else {
        item && item.slot_timings && item.slot_timings.length && item.slot_timings.map((data, index) => {
          resArrayForCaseysCalender.push({
            id: item.id,
            start: new Date(data.event_starttime),
            end: new Date(data.event_endtime),
            duration: data.event_duration,
            title: item.topic,
            agenda: item.agenda,
            courseId: item.course,
            isRecurringEvent: item.meeting_type == 8 ? true : false,
            recurringDays: recDays,
            isHost: item.is_host,
            hostDetails: item.host_details,
            joinURL: item.join_url,
            event_labels: item && item.event_labels,
            attachment: item && item.attachment,
            meeting_location: item && item.meeting_location,
            meeting_type: item.meeting_type,
            meeting_type_display_name: item.meeting_type_display_name,
            slot_timings: item.slot_timings,
            store_location: item.store_location
          })
        })
      }
      resArrCal.push(resObjCal);
    });
    if (domainDetails.client !== 'caseys') {
      setEventsListDataCal(resArrCal);
    } else {
      setEventsListDataCal(resArrayForCaseysCalender);
    }
    uniqueResData && uniqueResData.length > 0 && uniqueResData.map((item, index) => {
      let recDays = [];
      let rec = [];
      if (item && item.weekly_days && item.weekly_days !== null) {
        rec = item.weekly_days.split(",");
      }
      rec = rec.map(Number);
      if (rec.length > 0) {
        daysOfWeek &&
          daysOfWeek.map((day) => {
            if (rec.includes(day.id)) {
              recDays.push(day.label.substring(0, 3));
            }
          });
      }

      let recType = {};
      if (item.recurrence_type && item.recurrence_type !== null) {
        recType = recurrenceTypeList.find(
          (obj) => obj.id == item.recurrence_type
        );
      }

      resObj = {};
      resObj = {
        id: item.id,
        start: new Date(item.start_time),
        end: new Date(item.end_time),
        duration: item.duration,
        title: item.topic,
        agenda: item.agenda,
        courseId: item.course,
        recurrenceType: recType.name && recType.name,
        isRecurringEvent: item.meeting_type == 8 ? true : false,
        recurringDays: recDays,
        startURL: item.start_url,
        isHost: item.is_host,
        hostDetails: item.host_details,
        joinURL: item.join_url,
        event_labels: item && item.event_labels,
        attachment: item && item.attachment,
        meeting_location: item && item.meeting_location,
        meeting_type: item.meeting_type,
        meeting_type_display_name: item.meeting_type_display_name,
        slot_timings: item.slot_timings,
        store_location: item.store_location
      };
      resArr.push(resObj);
    });
    let arrList = resArr.sort(function (a, b) {
      return new Date(a.start) - new Date(b.start);
    });
    setEventsSiderList(arrList);
  };
  //FOR FETCH EVENTs FAILURE CALLBACK
  const failureCallBack = (error) => {
    setEventsListData([])
    setEventsListDataCal([])
    setEventsSiderList([])
    setEventsListLoader(false)
  };

  //FOR SELECTING COURSE WHILE CREATING EVENT
  const handleSelectChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  //ON CALENDER CLICK EVENT FROM UI
  const handleClickOpen = ({ start, end }) => {
    setLocEmptyErr(false)
    if (domainDetails.client === 'rmbl') {
      if (userDetails && Object.keys(userDetails) && (Object.keys(userDetails).length || !Object.keys(userDetails).length) && !userDetails.zoom_api_key) {
        props.showSnackBar({
          type: 'warning',
          message: 'Please add Zoom API and Secret Keys to start an event!',
          status: true
        })
      }
      else {
        setSelectedGroupMembersList([]);
        setMemEmptyCheck(false)
        setSelectedGroup([]);
        setStartDate(moment(start).format("YYYY-MM-DD[T]HH:mm"));
        setTitle("");
        setAgenda("");
        setDuration(30);
        setMeetingType(2);
        setRecType(1);
        setRecInterval(1);
        setApprovalType(0);
        setRecordingType("none");
        setMeetingSettings({
          host_video: false,
          participant_video: false,
          join_before_host: false,
          watermark: false,
          waiting_room: false,
          registrants_email_notification: false,
          meeting_authentication: false,
        });
        setCreateEvent(true);
      }
    } else {
      setSelectedGroupMembersList([]);
      setMemEmptyCheck(false)
      setSelectedGroup([]);
      setStartDate(moment(start).format("YYYY-MM-DD[T]HH:mm"));

      //FOR CASEYS
      setEventNumOfSlots([{
        event_starttime: moment(start).format("YYYY-MM-DD[T]HH:mm"),
        event_duration: 30,
        event_seats: 1
      }])
      setSelectedStoreLocation({})

      setTitle("");
      setAgenda("");
      setDuration(30);
      setMeetingType(domainDetails.client !== 'caseys' ? 2 : 10);
      setRecType(1);
      setRecInterval(1);
      setApprovalType(0);
      setRecordingType("none");
      setMeetingSettings({
        host_video: false,
        participant_video: false,
        join_before_host: false,
        watermark: false,
        waiting_room: false,
        registrants_email_notification: false,
        meeting_authentication: false,
      });
      setCreateEvent(true);
    }
  };

  //FOR TITLE HANDLE CHANGE EVENT
  const handleTitleChange = (event) => {
    setTitleError(false);
    setTitle(event.target.value);
  };

  //FOR EVENT AGENDA HANDLE CHANGE EVENT
  const handleAgendaChange = (event) => {
    setAgenda(event.target.value);
  };
  const onChangeEditorContent = (data) => {
    if (data.length >= 10000) {
      // props.showSnackBar({
      //   state: false,
      //   message: "max 10,000 chars only!",
      //   type: "warning",
      // });
      let text = data && data.length && data.substr(0, 10000);
      setAgenda(text);
    } else {
      setAgenda(data);
    }
  }
  //for rendering the html view
  const createMarkup = data => {
    return {
      __html: data
    };
  }

  //FOR CLICKING THE MODIFY EVENT BUTTON
  const modifyEvent = () => {
    setRenderMeetingInfo(false);
    setCreateEvent(true);
    setModifyEventInfo(true);
    setTitleError(false)
  };

  //MODIFY EVENT RESCHEDULE API
  const reScheduleMeeting = (event, type) => {
    event.preventDefault();
    setLocEmptyErr(false)
    let error = false;
    if (!meetingId) {
      props.showSnackBar({
        state: false,
        message: "Please Select an Existing Meeting to Re-schedule!",
        type: "error",
      });
      error = true;
    }
    if (title === "" || title == null) {
      setTitleError(true);
      error = true;
    }
    if ((recType == 1 && parseInt(recInterval) > 90)
      || (recType == 2 && parseInt(recInterval) > 12)
      || (recType == 3 && parseInt(recInterval) > 3)) {
      setErrRecInterval(true);
      error = true;
    }

    let recurrence_days = [];
    Object.keys(daysCheckBox).map((item, index) => {
      if (daysCheckBox[item] === true) {
        let dataObj = daysOfWeek.find((obj) => obj.name == item);
        let dayId =
          dataObj && Object.keys(dataObj).length > 0 ? dataObj.id : "";
        if (dayId) {
          recurrence_days.push(dayId);
        }
      }
    });

    if (recType == 2 && recurrence_days.length === 0) {
      props.showSnackBar({
        state: false,
        message: "Please Select atleast a day for Weekly Recurring!",
        type: "error",
      });
      error = true;
    }
    //for inperson meeting location empty check
    if (meetingType == 9 && !selectedInPersLoc.length) {
      error = true;
      setLocEmptyErr(true)
    }

    let membersArr = [];
    if (domainDetails.client !== 'caseys') {
      let membersList = selectedGroupMembersList.flatMap((list) => list.group_members);
      let usersIds = selectedUsers && selectedUsers.length > 0 ? selectedUsers.map(item => item.id) : [];
      let membersListIds = membersList && membersList.length > 0 ? membersList.map(item => item.id) : [];
      let resArr = membersListIds.concat(usersIds);
      membersArr = [...new Set(resArr)];
    }

    if (domainDetails.client === 'rmbl' && membersArr && !membersArr.length) {
      setMemEmptyCheck(true)
      error = true
    }

    if (domainDetails.client === 'rmbl' && attachmentFileDetails.fileError) {
      error = true
    }

    let slotTimeErrors = {
      id: '',
      error: false,
      type: ''
    }
    if (domainDetails.client === 'caseys' && meetingType == 10) {
      eventNumOfSlots && eventNumOfSlots.length && eventNumOfSlots.forEach((data, index) => {
        if (selectedStoreLoaction && Object.keys(selectedStoreLoaction) && !Object.keys(selectedStoreLoaction).length) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'location'
          }
        } else if (data && !data.event_starttime) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'startTime'
          }
        } else if (data && (!data.event_duration || data.event_duration <= 0)) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'duration'
          }
        } else if (data && (!data.event_seats || data.event_seats <= 0)) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'seats'
          }
        }
      })
    }

    //FOR SENDING THE CASEYS TO BACKEND
    let eventsSlotsInfo = []
    eventNumOfSlots && eventNumOfSlots.length && eventNumOfSlots.forEach((data) => {
      eventsSlotsInfo.push({
        event_starttime: data && data.event_starttime && new Date(data.event_starttime).toUTCString(),
        event_endtime: data && data.event_endtime && new Date(data.event_endtime).toUTCString(),
        event_duration: data && data.event_duration && data.event_duration,
        event_seats: data && data.event_seats && data.event_seats,
        id: data && data.id,
        participants: data & data.participants && data.participants
      })
    })

    if (!error && domainDetails.client === 'caseys' && slotTimeErrors.error) {
      props.showSnackBar({
        state: false,
        message: slotTimeErrors.type === 'startTime' ? "Please add evnt timings!" : slotTimeErrors.type === 'duration' ? 'Please select event duration!' : slotTimeErrors.type === 'seats' ? 'Please select Number of seats!' : 'Please select store loaction!',
        type: "error",
      });
    } else {
      if (!error) {
        setInProgress(true);
        setLocEmptyErr(false)
        let settingsObj = {
          approval_type: approvalType,
          auto_recording: recordingType,
          host_video: meetingSettings.host_video,
          participant_video: meetingSettings.participant_video,
          join_before_host: meetingSettings.join_before_host,
          watermark: meetingSettings.watermark,
          waiting_room: meetingSettings.waiting_room,
          registrants_email_notification: meetingSettings.registrants_email_notification,
          meeting_authentication: meetingSettings.meeting_authentication,
        };
        let eventLablesArr = []
        if (selectedEventLabels && selectedEventLabels.length) {
          selectedEventLabels.forEach((emp, index) => {
            eventLablesArr.push(emp.id);
          });
        }
        let newSchedule = {
          meeting_id: parseInt(meetingId),
          topic: title,
          type: parseInt(meetingType),
          start_time: new Date(startDate).toUTCString(),
          end_date: new Date(startDate).toUTCString(),
          duration: duration,
          agenda: agenda,
          courseId: selectedCourse,
          settings: settingsObj,
          user_id: membersArr
        };

        let recurrenceObj = {
          type: meetingType == 8 ? recType : null,
          repeat_interval: meetingType == 8 ? recInterval : null,
        };

        if (recType == 2) {
          recurrenceObj["weekly_days"] = meetingType == 8 ? recurrence_days : [];
        }
        if (recType == 3) {
          let dateVal = new Date(startDate).getDate();
          recurrenceObj["monthly_day"] = meetingType == 8 ? parseInt(dateVal) : null
        }

        if (meetingType == 8) {
          newSchedule["recurrence"] = recurrenceObj;
        }

        //FOR CASESYS GRP SELECTION IDS
        let casesysSeletedGpIDS = selectedGroup && selectedGroup.length ? selectedGroup.map(item => item.id) : []

        let formData = new FormData()
        formData.append('topic', title)
        formData.append('type', parseInt(meetingType))
        if (meetingType == 9) {
          formData.append('inperson_meeting_locations', JSON.stringify(selectedInPersLoc))
        }
        if (meetingType == 10) {
          formData.append('id', parseInt(meetingId))
          formData.append('store_location', selectedStoreLoaction.id)
          formData.append('slot_timings', JSON.stringify(eventsSlotsInfo))
          formData.append('selected_groups', JSON.stringify(casesysSeletedGpIDS))
        }
        if (domainDetails.client !== 'caseys') {
          formData.append('meeting_id', parseInt(meetingId))
          formData.append('start_time', new Date(startDate).toUTCString())
          formData.append('end_date', new Date(startDate).toUTCString())
          formData.append('duration', duration)
          formData.append('courseId', selectedCourse)
          formData.append('settings', JSON.stringify(settingsObj))
          formData.append('user_id', JSON.stringify(membersArr))
          formData.append('recurrence', JSON.stringify(recurrenceObj))
          formData.append('event_labels', JSON.stringify(eventLablesArr))
          formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
        }
        formData.append('agenda', agenda)
        modifyMeetingEvent(
          formData,
          //meetingId,
          successModifyCB,
          failureModifyCB
        );
      }
    }
  };

  //MODIFY EVENT SUCCESS CALLBACK
  const successModifyCB = (res) => {
    props.showSnackBar({
      state: false,
      message: "Event Modified Successfully",
      type: "success",
    });
    setInProgress(false);
    setModifyEventInfo(false)
    fetchEvents();
    setSelectedGroupMembersList([]);
    setSelectedGroup([]);
    setSelectedMembers([]);
    setSelectedUsers([]);
    setSelectedEventLabels([])
    setDefaultSelGrpMemList([])
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setCreateEvent(false);
  };
  //FOR MODIFY EVENT FAILURE CALLBACK
  const failureModifyCB = (res) => {
    const statusCode = res && res.response && res.response.status && res.response.status
    setInProgress(false);
    // if (statusCode === 400) {
    //   props.showSnackBar({
    //     state: false,
    //     message: "Please verify your zoom keys!",
    //     type: "warning",
    //   });
    // } else {
    props.showSnackBar({
      state: false,
      message: "Something went wrong while modifying Event!",
      type: "error",
    });
    // }
  };

  //SCHEDULE EVENT API CALL
  const scheduleMeeting = (event, type) => {
    event.preventDefault();
    let error = false;
    if (title === "" || title == null) {
      setTitleError(true);
      error = true;
    }

    if ((recType == 1 && parseInt(recInterval) > 90) ||
      (recType == 2 && parseInt(recInterval) > 12) ||
      (recType == 3 && parseInt(recInterval) > 3)
    ) {
      setErrRecInterval(true);
      error = true;
    }

    let recurrence_days = [];
    Object.keys(daysCheckBox).map((item, index) => {
      if (daysCheckBox[item] === true) {
        let dataObj = daysOfWeek.find((obj) => obj.name == item);
        let dayId =
          dataObj && Object.keys(dataObj).length > 0 ? dataObj.id : "";
        if (dayId) {
          recurrence_days.push(dayId);
        }
      }
    });

    if (recType == 2 && recurrence_days.length === 0) {
      props.showSnackBar({
        state: false,
        message: "Please Select atleast a day for Weekly Recurring!",
        type: "error",
      });
      error = true;
    }

    //for inperson meeting location empty check
    if (meetingType == 9 && !selectedInPersLoc.length) {
      error = true;
      setLocEmptyErr(true)
    }

    let membersArr = [];
    let membersList = selectedGroupMembersList.flatMap((list) => list.group_members);
    let usersIds = selectedUsers.length > 0 ? selectedUsers.map(item => item.id) : [];
    let membersListIds = membersList.length > 0 ? membersList.map(item => item.id) : [];
    let resArr = membersListIds.concat(usersIds);
    membersArr = [...new Set(resArr)];

    if (domainDetails.client === 'rmbl' && membersArr && !membersArr.length) {
      setMemEmptyCheck(true)
      error = true
    }

    if (domainDetails.client === 'rmbl' && attachmentFileDetails.fileError) {
      error = true
    }

    let slotTimeErrors = {
      id: '',
      error: false,
      type: ''
    }
    if (domainDetails.client === 'caseys' && meetingType == 10) {
      eventNumOfSlots && eventNumOfSlots.length && eventNumOfSlots.forEach((data, index) => {
        if (selectedStoreLoaction && Object.keys(selectedStoreLoaction) && !Object.keys(selectedStoreLoaction).length) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'location'
          }
        } else if (data && !data.event_starttime) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'startTime'
          }
        } else if (data && (!data.event_duration || data.event_duration <= 0)) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'duration'
          }
        } else if (data && (!data.event_seats || data.event_seats <= 0)) {
          slotTimeErrors = {
            id: index,
            error: true,
            type: 'seats'
          }
        }
      })
    }

    //FOR SENDING THE CASEYS TO BACKEND
    let eventsSlotsInfo = []
    eventNumOfSlots && eventNumOfSlots.length && eventNumOfSlots.forEach((data) => {
      eventsSlotsInfo.push({
        event_starttime: data && data.event_starttime && new Date(data.event_starttime).toUTCString(),
        event_duration: data && data.event_duration && data.event_duration,
        event_seats: data && data.event_seats && data.event_seats
      })
    })

    if (!error && domainDetails.client === 'caseys' && slotTimeErrors.error) {
      props.showSnackBar({
        state: false,
        message: slotTimeErrors.type === 'startTime' ? "Please add evnt timings!" : slotTimeErrors.type === 'duration' ? 'Please select event duration!' : slotTimeErrors.type === 'seats' ? 'Please select Number of seats!' : 'Please select store loaction!',
        type: "error",
      });
    } else {
      if (!error) {
        setMemEmptyCheck(false)
        setLocEmptyErr(false)
        setInProgress(true);
        let settingsObj = {
          approval_type: approvalType,
          auto_recording: recordingType,
          host_video: meetingSettings.host_video,
          participant_video: meetingSettings.participant_video,
          join_before_host: meetingSettings.join_before_host,
          watermark: meetingSettings.watermark,
          waiting_room: meetingSettings.waiting_room,
          registrants_email_notification:
            meetingSettings.registrants_email_notification,
          meeting_authentication: meetingSettings.meeting_authentication,
        };

        let eventLablesArr = []
        if (selectedEventLabels && selectedEventLabels.length) {
          selectedEventLabels.forEach((emp, index) => {
            eventLablesArr.push(emp.id);
          });
        }

        let newSchedule = {
          topic: title,
          type: parseInt(meetingType),
          start_time: new Date(startDate).toUTCString(),
          end_date: new Date(startDate).toUTCString(),
          duration: duration,
          agenda: agenda,
          courseId: selectedCourse,
          settings: settingsObj,
          user_id: membersArr
        };

        let recurrenceObj = {
          type: recType,
          repeat_interval: recInterval,
        };

        if (recType == 2) {
          recurrenceObj["weekly_days"] = meetingType == 8 ? recurrence_days : [];
        }
        if (recType == 3) {
          let dateVal = new Date(startDate).getDate();
          recurrenceObj["monthly_day"] = meetingType == 8 ? parseInt(dateVal) : null
        }

        if (meetingType == 8) {
          newSchedule["recurrence"] = recurrenceObj;
        }

        //FOR CASESYS GRP SELECTION IDS
        let casesysSeletedGpIDS = selectedGroup && selectedGroup.length ? selectedGroup.map(item => item.id) : []

        let formData = new FormData()
        formData.append('topic', title)
        formData.append('type', parseInt(meetingType))
        if (meetingType == 9) {
          formData.append('inperson_meeting_locations', JSON.stringify(selectedInPersLoc))
        }
        if (meetingType == 10) {
          formData.append('store_location', JSON.stringify(selectedStoreLoaction))
          formData.append('slot_timings', JSON.stringify(eventsSlotsInfo))
          formData.append('selected_groups', JSON.stringify(casesysSeletedGpIDS))
        }
        if (domainDetails.client !== 'caseys') {
          formData.append('start_time', new Date(startDate).toUTCString())
          formData.append('end_date', new Date(startDate).toUTCString())
          formData.append('duration', duration)
          formData.append('user_id', JSON.stringify(membersArr))
          formData.append('recurrence', JSON.stringify(recurrenceObj))
          formData.append('event_labels', JSON.stringify(eventLablesArr))
          formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
          formData.append('courseId', selectedCourse)
          formData.append('settings', JSON.stringify(settingsObj))
        }
        formData.append('agenda', agenda)
        createMeetingEvent(formData, type ? successAndAddEvntCB : successCreateCB, failureCreateCB);
      }
    }
  };
  //CREATE EVENT SUCCESS CALLBACK
  const successCreateCB = (res) => {
    props.showSnackBar({
      state: false,
      message: "Event Created Successfully",
      type: "success",
    });
    setInProgress(false);
    setModifyEventInfo(false)
    fetchEvents();
    setSelectedGroupMembersList([]);
    setSelectedGroup([]);
    setSelectedMembers([]);
    setSelectedUsers([]);
    setSelectedEventLabels([])
    setAttachmentFileDetails({})
    setDefaultSelGrpMemList([])
    setUploadedAttachmentPreview('')
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setCreateEvent(false);
  };

  //SUCCESS CALLBACK OF CREATE EVENT AND ADD EVENT
  const successAndAddEvntCB = () => {
    props.showSnackBar({
      state: false,
      message: "Event Created Successfully",
      type: "success",
    });
    setInProgress(false);
    setCreateEvent(true);
    fetchEvents();
    setSelectedStoreLocation({});
  }
  //CREATE EVENT FAILURE CALLBACK
  const failureCreateCB = (res) => {
    let statusCode = res.response && res.response.status && res.response.status
    setInProgress(false);
    // if (statusCode === 400) {
    //   props.showSnackBar({
    //     state: false,
    //     message: "Please verify your zoom keys!",
    //     type: "warning",
    //   });
    // } else {
    props.showSnackBar({
      state: false,
      message: "Something went wrong while Creating Event! Please try again!",
      type: "error",
    });
    // }
  };

  //FOR DELETE EVENT
  const deleteEvent = () => {
    setInProgress(true);
    if (meetingUniqId) {
      deleteMeetingEvent(meetingUniqId, successDeleteCB, failureDeleteCB);
    } else {
      setInProgress(false);
      handleDialogClose();
      props.showSnackBar({
        state: false,
        message: "Please select an Event to delete!",
        type: "error",
      });
    }
  };
  //DELETE EVENT SUCCESS CALLBACK
  const successDeleteCB = (res) => {
    props.showSnackBar({
      state: false,
      message: "Event Deleted Successfully",
      type: "success",
    });
    setInProgress(false);
    handleDialogClose();
    fetchEvents();
    setSelectedGroupMembersList([]);
    setSelectedGroup([]);
    setSelectedMembers([]);
    setModifyEventInfo(false)
    setDefaultSelGrpMemList([])
    setSelectedUsers([]);
    setSelectedEventLabels([])
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setCreateEvent(false);
    setRenderMeetingInfo(false);
  };
  //EVENT DELETE FAILURE CALLBACK
  const failureDeleteCB = (err) => {
    setInProgress(false);
    handleDialogClose();
    props.showSnackBar({
      state: false,
      message: "Something went wrong while deleting Event!",
      type: "error",
    });
  };

  //ON CANCEL EVENT BUTTON CLICK
  const cancelAddEvent = () => {

    //FOR CASEYS
    setEventNumOfSlots([{
      event_starttime: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
      event_duration: 30,
      event_seats: 1
    }])
    setSelectedStoreLocation({})

    setSelectedGroupMembersList([])
    setDefaultSelGrpMemList([])
    setParticipantsList([])
    setCreateEvent(false)
    setModifyEventInfo(false)
    setSelectedGroup([]);
    setSelectedMembers([]);
    setSelectedUsers([]);
    setSelectedEventLabels([])
    setAttachmentFileDetails({})
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setUploadedAttachmentPreview('')
  }

  const sortMembers = (membersList) => {
    let sortedList = membersList.sort(function (a, b) {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0;
    })
    return sortedList;
  }

  //FOR RENDERING THE SELECTED USERS IN CHIP
  const renderTeamListChip = (groupList) => {
    let groupsList = sortMembers(groupList);
    if (groupsList.length > 0) {
      let arr = [];
      groupsList.forEach((item, index) => {
        let label = item.name + " - " + item.email;
        let labelName = label.length > 40 ? label.substring(0, 35) + "..." : label;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              variant="outlined"
              label={labelName}
              deleteIcon={<HighlightOffIcon style={{ color: style.iconColor._color }} />}
              onDelete={() => {
                handleTeamMemberDelete(item);
              }}
              className={classes.selMemChip}
            />
          </React.Fragment>
        );
      });
      return arr;
    }
  };

  //FOR ADDING THE MEMBERS WHILE CREATING EVENT
  const handleAddMembers = (members) => {
    setMemEmptyCheck(false)
    setSelectedMembers(members);
    let oldMembers = selectedUsers;
    if (members.length > 0) {
      members.forEach((item, index) => {
        if (oldMembers.length > 0) {
          let existingNode = oldMembers.find((member) => member.id === item.id);
          if (!existingNode) {
            oldMembers.push(item);
          }
        } else {
          oldMembers.push(item);
        }
      });
    }
    setSelectedUsers(oldMembers);
  };

  //FOR CASEYS SELECTING THE SLOTS OF START DATE
  const handleStartDateChange = (event, key) => {
    if (domainDetails.client !== 'caseys') {
      setStartDate(event.target.value);
    } else {
      let evntSlotsData = [...eventNumOfSlots]
      let item = evntSlotsData[key];
      item = { ...item, [event.target.name]: event.target.value };
      evntSlotsData[key] = item
      setEventNumOfSlots(evntSlotsData)
    }
  };
  //FOR CASEYS SELECTING THE SLOTS OF NUMBER OF SEATS
  const handleNumberOfSeatsChange = (event, key) => {
    if (domainDetails.client !== 'caseys') {
      setNumberOfSeats(event.target.value)
    } else {
      let evntSlotsData = [...eventNumOfSlots]
      let item = evntSlotsData[key];
      item = { ...item, [event.target.name]: Number(event.target.value) };
      evntSlotsData[key] = item
      setEventNumOfSlots(evntSlotsData)
    }
  }
  //FOR CASEYS SELECTING THE SLOTS OF DURATION CHANGE
  const handleDurationChange = (event, key) => {
    if (domainDetails.client !== 'caseys') {
      setDuration(event.target.value);
    } else {
      let evntSlotsData = [...eventNumOfSlots]
      let item = evntSlotsData[key];
      item = { ...item, [event.target.name]: Number(event.target.value) };
      evntSlotsData[key] = item
      setEventNumOfSlots(evntSlotsData)
    }
  };

  const clearRenderInfo = () => {
    setSelectedGroupMembersList([]);
    setMemEmptyCheck(false)
    setDefaultSelGrpMemList([])
    setSelectedGroup([]);
    setSelectedMembers([]);
    setSelectedUsers([]);
    setSelectedEventLabels([])
    setAttachmentFileDetails({})
    setUploadedAttachmentPreview('')
    setDefaultInPernLocations([]);
    setSelectedInPersLoc([]);
    setRenderMeetingInfo(false);
    setCreateEvent(false);
  };

  //FOR REMOVING THE SELECTED USER FROM CHIP
  const handleDelete = (memberToDelete) => () => {
    let selectedUsersloyees = selectedUsers;
    let selectedChipUsers = selectedMembers;
    let newData = selectedUsersloyees.filter(
      (member) => member.id !== memberToDelete.id
    );
    let chipUsers = selectedChipUsers.filter(
      (member) => member.id !== memberToDelete.id
    );
    setSelectedMembers(chipUsers)
    setSelectedUsers(newData);
  };

  //FOR ADDING THE EVENT LABLES HANDLE CHANGE
  const handleAddEventLabels = (label) => {
    setSelectedEventLabels(label)
  }
  //for file upload to event
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  //VALIDATE FILE FORMAT
  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'attachmentfile' && file.size > apiConfig.assignment.acceptedAttachmentFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'attachmentfile') {
            return ["pdf", "docx", "ppt"]
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }
  //ON UPLOAD ATTACHMENT
  const onAttachmentUpload = event => {
    const attachment = validatedFile('attachmentfile')
    setAttachmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }
  //TO GET INPERSON MEETING LOCATIONS
  const getInPersonMeetingLocation = (value, getTagProps) => {
    setSelectedInPersLoc(value)
    setLocEmptyErr(false)
    return value.map((option, index) => (
      <Chip
        style={{ backgroundColor: '#E0E0E0' }}
        variant="outlined"
        label={option}
        {...getTagProps({ index })}
      />
    ))
  }
  //FOR EVENT SERACH ALOGO
  const handleEventSearchTerm = event => {
    setFilterSearchTerm(event.target.value)
  }
  const onEventSearch = evnt => {
    evnt.preventDefault();
    const searchTerm = document.getElementById('search Event')
    if (filterSearchTerm === '' || !filterSearchTerm.length) {
      searchTerm.focus()
    } else {
      const payload = {
        participant_filter: filterSearchTerm
      }
      setEventsListLoader(true)
      setFilterApplied(true)
      listEventsCalendar(payload, successCallBack, failureCallBack);
    }
  }
  //ON CLEARING THE SEARCH FILTER
  const clearSearchFilter = () => {
    if (filterApplied && filterSearchTerm) {
      setEventsListLoader(true)
      listEventsCalendar(successCallBack, failureCallBack);
      setFilterSearchTerm('')
      setFilterApplied(false)
    }
  }

  //FOR OPENING THE STORE LOCATION POPUP CASEYS
  const toggleStoreLocatorPopup = event => {
    setOpenStoreLocatorPopup(!openStoreLocatorPopup)
  }
  const closeStoreLocatorPopup = event => {
    setOpenStoreLocatorPopup(false)
  }

  //FOR GETTING THE SELECTED STORE LOCATION
  const getStoreSelectedLocation = (location) => {
    setSelectedStoreLocation(location)
  }

  //FOR SETTING THE EVENT NUMBER OF SLOTS
  const cloneDurationElements = () => {
    let events = [...eventNumOfSlots];
    events.push({
      event_starttime: moment(new Date()).format("YYYY-MM-DD[T]HH:mm"),
      event_duration: 30,
      event_seats: 1
    });
    setEventNumOfSlots(events);
  }

  //FOR REMOVING THE EVENT SLOTS
  const removeEventSlot = (index) => {
    eventNumOfSlots.splice(index, 1)
    setEventNumOfSlots([...eventNumOfSlots])
  }

  //FOR SHOWING PARTICIPANTS LIST FOR CASEYS
  const onViewParticipantsList = (eventSlotData) => {
    setEventParticipantsPoup({
      slotData: eventSlotData,
      dialogPopup: true
    })
  }
  const closeViewParticipantsPopup = () => {
    setEventParticipantsPoup({
      dialogPopup: false
    })
  }


  const durationFieldSet = () => {
    return (
      <Grid container spacing={1} style={{ position: 'relative' }} className="duration-field-set">
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItem}>
          <TextField
            id="dateStarttime-local"
            label="Starts On"
            type="datetime-local"
            value={startDate}
            error={dtTimeErrFlag}
            helperText={
              dtTimeErrFlag
                ? "Invalid Date/Time"
                : "Valid Time: 09:00 AM - 06:00 PM"
            }
            onChange={handleStartDateChange}
            className={classes.textField}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItem}>
          <TextField
            error={!duration}
            helperText={!duration ? "Invalid Duration" : ""}
            id="duration"
            label="Duration (Minutes)"
            value={duration}
            type="number"
            onChange={handleDurationChange}
            className={classes.textField}
          />
        </Grid>
      </Grid>
    )
  }

  //WHEN CLICKING ON THE EVENT NAME FROM CALENDER
  const onCalenderEventClick = (event) => {
    renderMeetingDetails(event && event.id && event.id)
  }

  //FOR ENABLING THE START EVENT BUTTON
  const getShowStartEvntIcon = (data) => {
    let currTime = new Date();
    if (domainDetails.client !== 'caseys') {
      return moment(currTime).isAfter(moment(data.start).subtract(1, "hours"));
    } else {
      return false
    }
  }

  //FOR DISAPLYING THE LEFT HAND SIDE PANEL MEETING TIMINGS
  const getLeftPanelMeetingTimings = (item) => {
    if (domainDetails.client !== 'caseys') {
      return (
        <React.Fragment>
          <div className={classes.dateVal}>
            {new Date().getDate() === item.start.getDate() ? (
              <div style={{ color: "green", fontWeight: "600" }}>
                Today
              </div>
            ) : (
              ` ${moment(item.start).format("MMM DD")} ${moment(item.start).format("ddd")}`
            )}
          </div>
          <div className={classes.timeCtr}>
            <div className={classes.timings}>
              {moment(item.start).format("hh:mm a")}
              &nbsp;-&nbsp;
              {item.isRecurringEvent ? `${item.duration} Minutes` : moment(item.end).format("hh:mm a")}
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      if (item && item.slot_timings && item.slot_timings.length) {
        let startTime = []
        let endTime = []
        let todayEvents = []
        item.slot_timings.forEach((slot) => {
          startTime.push(slot.event_starttime)
          endTime.push(slot.event_endtime)
          todayEvents.push(new Date().getDate() === new Date(slot.event_starttime).getDate())
        })
        return (
          <React.Fragment>
            <div className={classes.dateVal}>
              {todayEvents && todayEvents.length && todayEvents.includes(true) &&
                <div style={{ color: "green", fontWeight: "600", textAlign: 'left' }}>
                  Today
                </div>
              }
              <div style={{ marginTop: '-9px', marginBottom: '12px' }}>
                <div style={{ fontSize: "0.8rem", color: "#11539f" }}>
                  {startTime && startTime.length && startTime.map((time, index) => (
                    <p style={{ marginBottom: '-8px' }} key={index}>{`${moment(time).format("DD-MMM-YYYY, hh:mm a")}, ${item.slot_timings && item.slot_timings[index] && item.slot_timings[index].event_duration ? item.slot_timings[index].event_duration : 0} mins`}</p>
                  ))}
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }
    }
  }

  const showSearchFilter = false
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid item style={{ maxWidth: "14%", flexBasis: "14%" }}>
        <LeftMenu history={props.history} />
      </Grid>
      <Grid container spacing={1} style={{ width: '99%' }}>
        <div id="mainH1Tag" tabIndex="-1" className="accessibility">
          <h1>Host meetings page</h1>
        </div>
        <a className="skip-main" href="#leftMenu">
          Back to main menu
        </a>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Paper className={classes.siderPaper}>
            <div className={classes.eventsTitle}>Upcoming Events</div>
            {showSearchFilter && <React.Fragment>
              <div style={{ textAlign: 'initial' }} onSubmit={onEventSearch} >
                <TextField
                  autoComplete="off"
                  id='search Event'
                  className={classes.searchBox}
                  placeholder={`Find Event (Participants) by typing name here`}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: {
                      input: classes.input,
                      notchedOutline: classes.searchBoxOutline
                    }
                  }}
                  variant="outlined"
                  onChange={handleEventSearchTerm}
                  value={filterSearchTerm}
                />
                <IconButton onClick={(event) => { onEventSearch(event) }} size="small" style={{ marginTop: '6px' }}>
                  <SearchIcon className={classes.searchIcon} />
                </IconButton>
                {filterSearchTerm && filterApplied && <div>
                  <Link className={classes.clearFilterWrap} component="button" variant="body2"
                    onClick={() => { clearSearchFilter() }}
                  >
                    Clear Search
                  </Link>
                </div>}
              </div>
              <Divider />
            </React.Fragment>}
            {eventsListLoader ? (
              <CalenderSiderLoader type="calender" />
            ) : (
              <div className={mobileView ? classes.siderCntrMob : classes.siderCntr}>
                {eventsSiderList && eventsSiderList.length > 0 ? (
                  eventsSiderList.map((item, index) => {
                    let showIcon = getShowStartEvntIcon(item);
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={classes.itemBlock}
                          onClick={() => {
                            renderMeetingDetails(item.id);
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={10} sm={10} md={10} lg={10}>
                              <div className={classes.eventItem}>
                                <div className={classes.itemTitle}>
                                  {item.title && item.title.length > 30 ? item.title.substring(0, 30) + "..." : item.title}
                                </div>
                                <div className={classes.dateCtr}>
                                  {getLeftPanelMeetingTimings(item)}
                                </div>
                                {item && item.isRecurringEvent ? (
                                  <div className={classes.timeRecCtr}>
                                    <span className={classes.recLabel}>
                                      Type : Recurs&nbsp;
                                    </span>
                                    <span className={classes.recDays}>
                                      {item && item.recurringDays && item.recurringDays.length > 0 ? `every - ${item && item.recurringDays && item.recurringDays.toString()}` : item && item.recurrenceType}
                                    </span>
                                  </div>
                                ) : (
                                  <div className={classes.timeRecCtr}>
                                    <div className={classes.recLabel}>
                                      Type : {item.meeting_type_display_name}
                                    </div>
                                  </div>
                                )}
                                <div className={classes.recLabel}>
                                  <div className={item && item.isHost ? classes.recHostLabel : classes.recLabel}>
                                    Host: {Object.keys(item.hostDetails).length > 0 && item.hostDetails.name && item.hostDetails.name.length > 30
                                      ? item.hostDetails.name.substring(0, 30) + "..."
                                      : item.hostDetails.name}
                                  </div>
                                </div>
                                <div className={classes.recLabel}>
                                  {item && item.meeting_location && item.meeting_location.length > 0 ? (
                                    <React.Fragment>
                                      Location :
                                      {item.meeting_location.map((name, index) => (
                                        <span key={index}>{''} {name} {item.meeting_location[index + 1] ? ', ' : ''}</span>
                                      ))}
                                    </React.Fragment>
                                  ) : null}
                                </div>
                                <div className={classes.recLabel}>
                                  {item && item.event_labels && item.event_labels.length > 0 ?
                                    (item.event_labels.map((item, index) => {
                                      let name = item && item.name && item.name
                                      return (
                                        <Chip
                                          key={index}
                                          variant="outlined"
                                          label={name}
                                          className={classes.evntLabelWrapper}
                                        />
                                      );
                                    })
                                    ) : null}
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                              {showIcon && (
                                <Tooltip title={item.isHost ? "Start Meeting" : "Join Now"}>
                                  {item.isHost ?
                                    <a href={item.startURL} target="_blank" >
                                      <PlayCircleOutlineIcon className={classes.event} />
                                    </a> : <a href={item.joinURL} target="_blank">
                                      <PersonAddIcon className={classes.event} />
                                    </a>}
                                </Tooltip>)}
                            </Grid>
                          </Grid>
                        </div>
                        <Divider />
                      </React.Fragment>
                    );
                  })) : (
                  <React.Fragment>
                    {!eventsListLoader ? (
                      <div className={classes.noDataWrapper}>No upcoming events!</div>
                    ) : null}
                  </React.Fragment>
                )}
              </div>
            )}
          </Paper>
        </Grid>
        {!renderMeetingInfo ? (
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <div style={{ marginTop: "6rem", textAlign: "end" }}>
              {domainDetails.client === 'rmbl' ? (
                userDetails && Object.keys(userDetails) && Object.keys(userDetails).length && userDetails.zoom_api_key ? (
                  <React.Fragment>
                    {!createEvent && <Button
                      className={classes.buttons}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        createNewEvent();
                      }}
                    >
                      Create Event
                    </Button>}
                  </React.Fragment>
                ) : null) : (
                <React.Fragment>
                  {!createEvent && <Button
                    className={classes.buttons}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      createNewEvent();
                    }}
                  >
                    Create Event
                  </Button>}
                </React.Fragment>
              )}
            </div>
            {!createEvent ? (
              <div style={{ margin: "1rem 1rem 0 1rem", overflow: "scroll" }}>
                <Calendar
                  selectable
                  events={eventsListDataCal}
                  startAccessor="start"
                  endAccessor="end"
                  components={{
                    timeSlotWrapper: ColoredDateCellWrapper
                  }}
                  className={mobileView ? classes.calendarMob : classes.calendar}
                  localizer={localizer}
                  onSelectSlot={handleClickOpen}
                  onSelectEvent={event => onCalenderEventClick(event)}
                />
              </div>
            ) : (
              <React.Fragment>
                <form autoComplete="off" style={{ paddingBottom: "2rem", margin: "1rem" }} onSubmit={(evnt) => { evnt.preventDefault(); }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                      <TextField
                        required
                        variant="outlined"
                        id="Title"
                        name="Title"
                        label="Title"
                        value={title}
                        error={titleError}
                        onChange={handleTitleChange}
                        fullWidth
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                          <FormControl variant="outlined" className={classes.selectFormControl}>
                            <InputLabel htmlFor="outlined-meeting-native-simple">
                              Meeting Type
                            </InputLabel>
                            <Select
                              native
                              required
                              value={meetingType}
                              onChange={handleMeetingTypeChange}
                              label="Meeting Type"
                              inputProps={{
                                name: "meetingtype",
                                id: "outlined-meeting-native-simple",
                              }}
                            >

                              {domainDetails.client === 'caseys' ? (
                                <option value={10}>
                                  Instructor Led Training
                                </option>
                              ) : (
                                <React.Fragment>
                                  <option value={2}>Scheduled Meeting</option>
                                  <option value={8}>
                                    Recurring Meeting with Fixed Time
                                  </option>
                                  <option value={9}>
                                    In-person Meeting
                                  </option>
                                </React.Fragment>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {meetingType == 10 ? (
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: 'left' }}>
                            <Button
                              className={classes.buttons}
                              color="default"
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                toggleStoreLocatorPopup(event);
                              }}
                              startIcon={<img src={StoreLogo} style={{ width: 32 }} alt="Caseys logo" />}
                            >
                              Location
                            </Button>
                          </Grid>
                          {selectedStoreLoaction && Object.keys(selectedStoreLoaction) && Object.keys(selectedStoreLoaction).length ? (
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                              <Typography variant="body2" className={classes.storeList} style={{ fontSize: '13px', textAlign: 'initial', marginTop: '7px' }}>
                                <span className={classes.storeTitle}>{selectedStoreLoaction.store}</span>
                                <span>{`${selectedStoreLoaction.address}, `}
                                  {`${selectedStoreLoaction.city}, `}
                                  {`${selectedStoreLoaction.state}, `}
                                  {`${selectedStoreLoaction.country}, `}
                                  {`${selectedStoreLoaction.zip_code} `}</span>
                              </Typography>
                            </Grid>) : null}
                        </Grid>
                      ) : null}
                      <div id="durationWrapper" style={{ position: 'relative' }}>
                        {domainDetails.client !== 'caseys' ? (
                          durationFieldSet('')
                        ) : (
                          <React.Fragment>
                            {eventNumOfSlots && eventNumOfSlots.length && eventNumOfSlots.map((row, key) => (
                              <Grid key={key} container spacing={1} style={{ position: 'relative' }} className="duration-field-set">
                                <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridItem}>
                                  <TextField
                                    id={`evntStarttime_${key + 1}`}
                                    label="Starts On"
                                    name="event_starttime"
                                    type="datetime-local"
                                    value={row && row.event_starttime}
                                    error={dtTimeErrFlag}
                                    helperText={dtTimeErrFlag ? "Invalid Date/Time" : "Valid Time: 09:00 AM - 06:00 PM"}
                                    onChange={(event) => { handleStartDateChange(event, key) }}
                                    className={classes.textField}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItem}>
                                  <TextField
                                    error={!row.event_duration || row.event_duration <= 0}
                                    helperText={!duration ? "Invalid Duration" : ""}
                                    id={`duration${key + 1}`}
                                    label="Duration (Minutes)"
                                    name="event_duration"
                                    value={row && row.event_duration.toString()}
                                    type="number"
                                    onChange={(event) => { handleDurationChange(event, key) }}
                                    className={classes.textField}
                                  />
                                </Grid>
                                {meetingType == 10 ? (
                                  <React.Fragment>
                                    <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItem}>
                                      <TextField
                                        error={!row.event_seats || row.event_seats <= 0}
                                        id={`Number of Seats${key + 1}`}
                                        label="Number of Seats"
                                        value={row && row.event_seats.toString()}
                                        type="number"
                                        name="event_seats"
                                        onChange={(event) => { handleNumberOfSeatsChange(event, key) }}
                                        className={classes.textField}
                                      />
                                    </Grid>
                                  </React.Fragment>
                                ) : null}
                                {domainDetails.client === 'caseys' && meetingType == 10 && key === 0 && <div style={{ marginTop: '15px' }}>
                                  <Tooltip title="Add New Slot">
                                    <IconButton onClick={cloneDurationElements} aria-label="add" >
                                      <AddIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>}
                                {domainDetails.client === 'caseys' && meetingType == 10 && key !== 0 ? (
                                  <Grid item xs={12} sm={12} md={1} lg={1} className={classes.gridItem}>
                                    <IconButton className="remove-icon" aria-label="delete" onClick={() => { removeEventSlot(key) }}>
                                      <ClearIcon />
                                    </IconButton>
                                  </Grid>
                                ) : null}
                              </Grid>
                            ))}
                          </React.Fragment>
                        )}
                      </div>
                      {meetingType == 9 && (
                        <React.Fragment>
                          <Grid container spacing={1} className={classes.gridCtr}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Autocomplete
                                multiple
                                id="tags-filled"
                                options={inpersonMettingOptions.map(option => option.location)}
                                //value={selectedInPersLoc}
                                defaultValue={defaultInPernLoacations}
                                freeSolo
                                renderTags={(value, getTagProps) => { return getInPersonMeetingLocation(value, getTagProps) }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    required
                                    error={locEmptyErr}
                                    id="inpersonMetting"
                                    variant="outlined"
                                    name="In-person Meeting"
                                    label="Meeting Location"
                                    placeholder="Type tags here and press 'ENTER' or, to add location"
                                    helperText="Type tags here and press 'ENTER' or, to add location"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      )}
                      {meetingType == 8 && (
                        <React.Fragment>
                          <Grid container spacing={1} className={classes.gridCtr}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <FormControl
                                variant="outlined"
                                className={classes.selectFormControl}
                              >
                                <InputLabel htmlFor="outlined-type-native-simple">
                                  Recurrence Type
                                </InputLabel>
                                <Select
                                  native
                                  value={recType}
                                  onChange={handleRecTypeChange}
                                  label="Recurrence Type"
                                  inputProps={{
                                    name: "type",
                                    id: "outlined-type-native-simple",
                                  }}
                                >
                                  <option value={1}>Daily</option>
                                  <option value={2}>Weekly</option>
                                  <option value={3}>Monthly</option>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <TextField
                                required={meetingType == 8}
                                id="outlined-Interval-Text"
                                label="Interval"
                                variant="outlined"
                                value={recInterval}
                                error={errRecInteval}
                                onChange={handleIntervalChange}
                                helperText={`Maximum ${recType == 1
                                  ? "90"
                                  : recType == 2
                                    ? "12"
                                    : "3"
                                  } ${recType == 1
                                    ? "Days"
                                    : recType == 2
                                      ? "Weeks"
                                      : "Months"
                                  }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                            {daysOfWeek.length > 0 &&
                              recType == 2 &&
                              daysOfWeek.map((item, index) => {
                                let name = item.name;
                                return (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={daysCheckBox[name]}
                                        onChange={handleDaysCheckBoxChanged}
                                        name={item["name"]}
                                      />
                                    }
                                    label={item["label"]}
                                  />
                                );
                              })}
                          </Grid>
                        </React.Fragment>
                      )}
                      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                        {domainDetails.client === 'rmbl' || domainDetails.client === 'caseys' ? (
                          <React.Fragment>
                            <Autocomplete
                              value={selectedGroup.length > 0 ? selectedGroup : []}
                              onChange={(event, group) => {
                                handleSelectGroup(group);
                              }}
                              multiple
                              id="checkboxes-tags-demo"
                              options={groupList && groupList.length > 0 ? groupList : []}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.group_title}
                              getOptionSelected={(option, value) => option.id === value.id}
                              className={classes.memberListAutoSuggest}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8, color: "#2e8eec" }}
                                    checked={selected}
                                  />
                                  <React.Fragment>
                                    <div>
                                      {option.group_title}
                                      <p style={{ fontSize: '11px', color: '#999', fontWeight: 500, marginTop: '-2px' }}>{` (Members count - ${option.members_count}) `}</p>
                                    </div>
                                  </React.Fragment>
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={(meetingType == 8 || meetingType == 2) && membersEmptyCheck}
                                  style={{ color: "red", fontSize: "1rem" }}
                                  variant="outlined"
                                  label="Search for Groups..."
                                />
                              )}
                            />
                            {domainDetails.client === 'rmbl' &&
                              <React.Fragment>
                                <Autocomplete
                                  value={selectedMembers.length > 0 ? selectedMembers : []}
                                  onChange={(event, newValue) => {
                                    handleAddMembers(newValue);
                                  }}
                                  multiple
                                  id="checkboxes-tags-members-demo"
                                  options={members && members.length > 0 ? members : []}
                                  getOptionLabel={(option) => option.email}
                                  className={classes.memberListAutoSuggest}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      error={meetingType == 9 && membersEmptyCheck}
                                      style={{ color: "red", fontSize: "1rem" }}
                                      variant="outlined"
                                      label="Search Members..."
                                    />
                                  )}
                                />
                                {selectedGroupMembersList.length > 0 || selectedUsers.length > 0 ? <div className={classes.visIcnCtr}>
                                  <VisibilityIcon className={classes.visIcn} />
                                  <div className={classes.visTxt} onClick={() => { setDisplayMembersList(true) }}>
                                    View selected members
                                  </div>
                                </div> : null}
                                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                                  <div className={classes.autoCompWrapper}>
                                    <Autocomplete
                                      value={selectedEventLabels}
                                      onChange={(event, newValue) => {
                                        handleAddEventLabels(newValue);
                                      }}
                                      multiple
                                      id="checkboxes-tags-members-demo"
                                      options={eventLables && eventLables.length > 0 ? eventLables : []}
                                      getOptionLabel={(option) => option.name}
                                      className={classes.grpListWrapper}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          style={{ color: "red", fontSize: "1rem" }}
                                          variant="outlined"
                                          label="Add event labels..."
                                        />
                                      )}
                                    />
                                  </div>
                                </Grid>
                              </React.Fragment>}
                          </React.Fragment>
                        ) : null}
                        {domainDetails.client !== 'caseys' ? (
                          <FormControl
                            variant="outlined"
                            className={classes.selectFormControl}
                          //error={errSelCourse}
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Courses
                            </InputLabel>
                            <Select
                              native
                              value={selectedCourse}
                              onChange={handleSelectChange}
                              label="Courses"
                              inputProps={{
                                name: "courses",
                                id: "outlined-age-native-simple",
                              }}
                            >
                              <option aria-label="None" value="" />
                              {courseList.length > 0 &&
                                courseList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.title}
                                    </option>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        ) : null}
                        {showNormalTextFieldAgenda ? (
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                            <TextField
                              variant="outlined"
                              id="agenda"
                              name="agenda"
                              label="Agenda"
                              value={agenda}
                              helperText="max: 2000 chars"
                              inputProps={{
                                maxLength: 2000
                              }}
                              onChange={handleAgendaChange}
                              fullWidth
                              multiline
                              rows={4}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridItem}>
                            <TextEditor
                              onChangeEditorContent={onChangeEditorContent}
                              helperText={'Type meeting agenda here...'}
                              description={agenda}
                            />
                            <FormHelperText id="meeting agenda">
                              max: 10,000 chars
                              <span style={{ marginLeft: '10px', color: agenda && agenda.length && agenda.length === 10000 ? 'red' : '' }}>
                                {agenda && agenda.length ? `Char count: ${agenda.length}` : ''}
                              </span>
                            </FormHelperText>
                          </Grid>
                        )}
                        {domainDetails.client === 'rmbl' ? (
                          <React.Fragment>
                            {attachmentFileDetails.fileError ? (
                              <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                                {attachmentFileDetails.fileErrorErrorMessage}
                              </Typography>
                            ) : null}
                            <Typography gutterBottom variant="body2" style={{ textAlign: 'left' }}>
                              <span className={classes.uploadLabel} aria-hidden="true">
                                Upload Attachment
                              </span>
                              <IconButton aria-label="upload attachment" onClick={() => { onFileToUploadClick('attachmentfile') }}>
                                <CloudUploadIcon className={classes.uploadIcon} />
                              </IconButton>
                              {attachmentFileDetails.file && attachmentFileDetails.file.name && uploadedAttachmentPreview ? (
                                <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                                  {attachmentFileDetails.file.name.length > 25 ? (
                                    attachmentFileDetails.file.name.substr(0, 20) + '....'
                                  ) :
                                    attachmentFileDetails.file.name
                                  }
                                </a>
                              ) : (
                                <React.Fragment>
                                  {uploadedAttachmentPreview ? (
                                    <React.Fragment>
                                      <Tooltip title="Attachment">
                                        <a href={uploadedAttachmentPreview} target='_blank' edge="start" color="primary" className={classes.readingsButton} aria-label="close">
                                          {uploadedAttachmentPreview.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec', marginBottom: '-9px' }} /> : uploadedAttachmentPreview.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec', marginBottom: '-9px' }} /> : <DescriptionIcon style={{ color: '#2e8eec', marginBottom: '-9px' }} />}
                                        </a>
                                      </Tooltip>
                                    </React.Fragment>
                                  ) : null}
                                </React.Fragment>
                              )}
                            </Typography>
                            <div className={classes.helperText}>max.size: 10MB, format: pdf, docx, ppt</div>
                            <input type="file" accept=".ppt,.pdf,.docx" style={{ display: 'none' }} id="attachmentfile" onChange={onAttachmentUpload} />
                          </React.Fragment>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                      {domainDetails.client !== 'caseys' && displayMembersList ?
                        <Grid container spacing={2} style={{ justifyContent: "center" }}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Paper elevation={3} className={classes.membersCntr}>
                              <div className={classes.lstHdr}>
                                <CloseIcon className={classes.icn} onClick={() => { setDisplayMembersList(false) }} />
                              </div>
                              {domainDetails.client !== 'caseys' ? (
                                <React.Fragment>
                                  {selectedGroupMembersList && selectedGroupMembersList.length > 0 &&
                                    <div className={classes.memCtr}>
                                      <div className={classes.teamName}>Selected Groups </div>
                                      {selectedGroupMembersList.map((item, index) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {item.group_members.length > 0 && (
                                              <div className={classes.teamWrapper}>
                                                <div className={classes.teamName}>
                                                  {item.group_title} - ({item.group_members.length} Members)
                                                </div>
                                                <div style={{ textAlign: 'initial' }}>{renderTeamListChip(item.group_members)}</div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        );
                                      })}
                                    </div>
                                  }
                                </React.Fragment>
                              ) : null}
                              {selectedUsers.length > 0 && (
                                <div className={classes.memCtr}>
                                  <div className={classes.teamName}>Selected Members </div>
                                  {selectedUsers.map((item, index) => {
                                    let label = item.name + " - " + item.email;
                                    let labelName =
                                      label.length > 40
                                        ? label.substring(0, 35) + "..."
                                        : label;
                                    return (
                                      <React.Fragment key={index}>
                                        <Chip
                                          key={index}
                                          variant="outlined"
                                          deleteIcon={<HighlightOffIcon style={{ color: style.icon.color }} />}
                                          label={labelName}
                                          onDelete={handleDelete(item)}
                                          className={classes.selMemChip}
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              )}
                            </Paper>
                          </Grid>
                        </Grid> :
                        <React.Fragment>
                          {!domainDetails.client == 'rmbl' ?
                            <React.Fragment>
                              <FormControl
                                component="fieldset"
                                className={classes.formControl}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={meetingSettings.host_video}
                                        onChange={handleSettingsChange}
                                        name="host_video"
                                      />
                                    }
                                    label="Enable Host Video"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={meetingSettings.participant_video}
                                        onChange={handleSettingsChange}
                                        name="participant_video"
                                      />
                                    }
                                    label="Enable Participant Video"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={meetingSettings.join_before_host}
                                        onChange={handleSettingsChange}
                                        name="join_before_host"
                                      />
                                    }
                                    label="Allow Participants to Join Before Host"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={meetingSettings.watermark}
                                        onChange={handleSettingsChange}
                                        name="watermark"
                                      />
                                    }
                                    label="Show WaterMark"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={meetingSettings.waiting_room}
                                        onChange={handleSettingsChange}
                                        name="waiting_room"
                                      />
                                    }
                                    label="Enable Waiting Room"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          meetingSettings.registrants_email_notification
                                        }
                                        onChange={handleSettingsChange}
                                        name="registrants_email_notification"
                                      />
                                    }
                                    label="Notify Paticipants through Mail"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          meetingSettings.meeting_authentication
                                        }
                                        onChange={handleSettingsChange}
                                        name="meeting_authentication"
                                      />
                                    }
                                    label="Make Meeting Authenticated"
                                  />
                                </FormGroup>
                              </FormControl>
                              <FormControl
                                variant="outlined"
                                className={classes.approvalTypeSelect}
                              >
                                <InputLabel htmlFor="outlined-approval-native-simple">
                                  Approval Type
                                </InputLabel>
                                <Select
                                  native
                                  value={approvalType}
                                  onChange={handleApprovalTypeChange}
                                  label="Approval Type"
                                  inputProps={{
                                    name: "approvaltype",
                                    id: "outlined-approval-native-simple",
                                  }}
                                >
                                  <option value={0}>Automatically Approve</option>
                                  <option value={1}>Manually Approve</option>
                                  <option value={2}>No Registration Required</option>
                                </Select>
                              </FormControl>
                            </React.Fragment>
                            : null}
                          {domainDetails.client !== 'caseys' ? (
                            <FormControl
                              variant="outlined"
                              className={classes.recordingTypeSelect}
                            >
                              <InputLabel htmlFor="outlined-recording-native-simple">
                                Recording
                              </InputLabel>
                              <Select
                                native
                                value={recordingType}
                                onChange={handleRecordingTypeChange}
                                label="Recording Type"
                                inputProps={{
                                  name: "recordingtype",
                                  id: "outlined-recording-native-simple",
                                }}
                              >
                                <option value="none">Disable Recording</option>
                                {/* <option value="local">Record on Local</option> */}
                                <option value="cloud">Record to Cloud</option>
                              </Select>
                            </FormControl>
                          ) : null}
                        </React.Fragment>
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={7}>
                      <div className={classes.formButtonContainer}>
                        {inProgress ? (
                          <CircularProgress
                            size={30}
                            className={classes.buttonProgress}
                          />
                        ) : null}
                        {!modifyEventInfo ? (
                          <React.Fragment>
                            <Button
                              className={classes.buttons}
                              color="primary"
                              variant="contained"
                              disabled={inProgress || dtTimeErrFlag}
                              onClick={(event) => {
                                scheduleMeeting(event);
                              }}
                            >
                              Confirm
                            </Button>
                            {domainDetails.client === 'caseys' &&
                              <Button
                                className={classes.buttons}
                                color="primary"
                                variant="contained"
                                disabled={inProgress || dtTimeErrFlag}
                                onClick={(event) => {
                                  scheduleMeeting(event, 'cloneEvent');
                                }}
                              >
                                Add same event in another location
                              </Button>
                            }
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Button
                              className={classes.buttons}
                              color="primary"
                              variant="contained"
                              disabled={inProgress}
                              onClick={(event) => {
                                reScheduleMeeting(event);
                              }}
                            >
                              Re-Schedule
                            </Button>
                          </React.Fragment>
                        )}
                        <Button
                          className={classes.buttons}
                          color="primary"
                          variant="contained"
                          disabled={inProgress}
                          onClick={() => { cancelAddEvent() }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>

                  </Grid>
                </form>
              </React.Fragment>
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <Paper elevation={1} style={{ marginTop: "6rem", minHeight: '574px' }}>
              <div className={classes.detailsHeader}>
                <Grid container spacing={1}>
                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <div className={classes.mtitle}>{title}</div>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <div style={{ display: "flex" }}>
                      {isHost ? (
                        <Tooltip title="Edit">
                          <IconButton onClick={() => { modifyEvent() }} className={classes.iconBtn}>
                            <EditIcon className={classes.editIcon} />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      <Tooltip title="Calendar View">
                        <IconButton
                          onClick={() => {
                            clearRenderInfo();
                          }}
                          className={classes.iconBtn}
                        >
                          <DateRangeIcon className={classes.calIcon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div style={{ marginLeft: "1rem" }}>
                {selectedCourseName !== "" && <div className={classes.container}>
                  <div className={classes.flex}>
                    <div className={classes.label}>Course Name:</div>
                    <div className={classes.value}>{selectedCourseName}</div>
                  </div>
                </div>}

                {domainDetails.client !== 'caseys' ? (
                  <React.Fragment>
                    <div className={classes.container}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className={classes.flex}>
                            <div className={classes.label}>Date:</div>
                            <div
                              className={classes.value}
                              style={{ textTransform: "uppercase" }}
                            >
                              {moment(startDate).format("DD-MMM-YYYY hh:mm a")}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className={classes.flex}>
                            <div className={classes.label}>Duration:</div>
                            <div className={classes.value}>
                              {duration} &nbsp;Minutes
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <Divider />
                  </React.Fragment>
                ) : null}
                {domainDetails.client !== 'caseys' && <React.Fragment><div className={classes.container}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Meeting Id:</div>
                        <div className={classes.value}>{meetingId}</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        {renderStartBtn && (
                          <React.Fragment>
                            {isHost ?
                              <a href={startURL} className={classes.hrefBtn} type="btn" target="_blank">
                                Start Now
                              </a> : <a href={joinURL} className={classes.hrefBtn} type="btn" target="_blank">
                                Join Now
                              </a>}
                          </React.Fragment>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
                  <Divider />
                </React.Fragment>}
                {agenda && agenda.length &&
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ maxHeight: '150px', overflowY: 'scroll' }}>
                      <div className={classes.container}>
                        <div className={classes.flex}>
                          <div className={classes.label}>Agenda:</div>
                          {showNormalTextFieldAgenda ? (
                            <div className={classes.value}>{agenda}</div>
                          ) : (
                            <div style={{ marginTop: '13px', marginRight: '23px', marginLeft: '-53px', lineBreak: 'anywhere' }} dangerouslySetInnerHTML={createMarkup(agenda)} />
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Divider />
                  </React.Fragment>}
                {domainDetails.client !== 'caseys' && participantsList && participantsList.length ?
                  <React.Fragment>
                    <div className={classes.container}>
                      <Grid container spacing={2} style={{ maxHeight: '150px', overflowY: 'scroll' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <div className={classes.label}>Participants:</div>
                          {participantsList && participantsList.length && participantsList.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  label={(item.name + " - " + item.email).length > 30 ? (item.name + " - " + item.email).substring(0, 25) + "..." : (item.name + " - " + item.email)}
                                  className={classes.selMemChip}
                                />
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </div>
                    <Divider />
                  </React.Fragment> : null}
                {selectedEventLabels && selectedEventLabels.length ? (
                  <React.Fragment>
                    <div className={classes.container}>
                      <Grid container spacing={2} style={{ maxHeight: '100px', overflowY: 'scroll' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <div className={classes.label}>Event Labels:</div>
                          {selectedEventLabels && selectedEventLabels.length && selectedEventLabels.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  label={item.name}
                                  className={classes.selMemChip}
                                />
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </div>
                    <Divider />
                  </React.Fragment>
                ) : null}
                {selectedInPersLoc && selectedInPersLoc.length ? (
                  <React.Fragment>
                    <div className={classes.container}>
                      <Grid container spacing={2} style={{ maxHeight: '100px', overflowY: 'scroll' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <div className={classes.label}>Location:</div>
                          {domainDetails.client !== 'caseys' && selectedInPersLoc && selectedInPersLoc.length && selectedInPersLoc.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  label={item}
                                  className={classes.selMemChip}
                                />
                              </React.Fragment>
                            );
                          })}
                          {domainDetails.client === 'caseys' ? (
                            <Typography variant="body2" className={classes.storeList} style={{ fontSize: '13px', textAlign: 'initial', marginTop: '7px' }}>
                              <span className={classes.storeTitle}>{selectedStoreLoaction.store}</span>
                              <span>{`${selectedStoreLoaction.address}, `}
                                {`${selectedStoreLoaction.city}, `}
                                {`${selectedStoreLoaction.state}, `}
                                {`${selectedStoreLoaction.country}, `}
                                {`${selectedStoreLoaction.zip_code} `}</span>
                            </Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </div>
                    <Divider />
                  </React.Fragment>
                ) : null}
                <div className={classes.container}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Meeting Type:</div>
                        <div className={classes.value}>{meetingTypeName}</div>
                      </div>
                    </Grid>
                    {domainDetails.client !== 'caseys' && <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Recurrence Type:</div>
                        <div className={classes.value}>
                          {recTypeName ? recTypeName : "NA"}
                        </div>
                      </div>
                    </Grid>}
                  </Grid>
                  {domainDetails.client !== 'caseys' && <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Interval:</div>
                        <div className={classes.value}>
                          {recInterval ? recInterval : "NA"}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Recurrences:</div>
                        <div className={classes.value}>
                          {recurringDays && recurringDays.length > 0
                            ? recurringDays.toString()
                            : "NA"}
                        </div>
                      </div>
                    </Grid>
                  </Grid>}
                </div>
                <Divider />
                <div className={classes.container}>
                  <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                    {domainDetails.client !== 'caseys' && <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Attachment:</div>
                        <div className={classes.value}>
                          {uploadedAttachmentPreview && uploadedAttachmentPreview ? (
                            <React.Fragment>
                              <Tooltip title="Attachment">
                                <a href={uploadedAttachmentPreview} target='_blank' edge="start" color="primary" className={classes.calIcon} aria-label="close">
                                  {uploadedAttachmentPreview.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec' }} /> : uploadedAttachmentPreview.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec' }} /> : <DescriptionIcon style={{ color: '#2e8eec' }} />}
                                </a>
                              </Tooltip>
                            </React.Fragment>
                          ) : ('No attachment found')}
                        </div>
                      </div>
                    </Grid>}
                    {domainDetails.client !== 'caseys' && <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className={classes.flex}>
                        <div className={classes.label}>Store Recordings:</div>
                        <div
                          className={classes.value}
                          style={{ textTransform: "capitalize" }}
                        >
                          {recordingType}
                        </div>
                      </div>
                    </Grid>}
                    {domainDetails.client === 'caseys' ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                          <div style={{ color: '#11539f', fontWeight: 500, marginBottom: '11px' }}>Event Slots:</div>
                          {eventNumOfSlots && eventNumOfSlots.length && eventNumOfSlots.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <span style={{ color: '#2e8eec', paddingLeft: '14px' }}>Time:</span> {moment(item.event_starttime).format("DD-MMM-YYYY, hh:mm a")}<br />
                                <span style={{ color: '#2e8eec', paddingLeft: '14px' }}>Duration:</span> {item.event_duration} &nbsp;Minutes<br />
                                <span style={{ color: '#2e8eec', paddingLeft: '14px' }}>Seats Availability:</span> {(item.event_seats - (item.participants && item.participants.length))}/{item.event_seats}<br />
                                <span style={{ color: '#2e8eec', paddingLeft: '14px' }}>Participants:</span> {item && item.participants && item.participants.length ? <Button color="primary" size="small" onClick={() => { onViewParticipantsList(item) }} style={{ marginLeft: '-3px', textTransform: 'none' }}>View Participants</Button> : 'No participants enrolled'}
                                {eventNumOfSlots[index + 1] ? <Divider style={{ marginTop: '10px', marginBottom: '10px' }} /> : null}
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                  {!domainDetails.client == 'rmbl' ?
                    <React.Fragment>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={meetingSettings.participant_video}
                              />
                            }
                            label="Enable Participant Video"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={meetingSettings.host_video} />
                            }
                            label="Enable Host Video"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={meetingSettings.join_before_host}
                              />
                            }
                            label=" Allow Participant to Join before Host"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={meetingSettings.watermark} />
                            }
                            label="Show Watermark:"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={meetingSettings.waiting_room} />
                            }
                            label="Enable Waiting Room"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  meetingSettings.registrants_email_notification
                                }
                              />
                            }
                            label="Notify Participants through Mail"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={meetingSettings.meeting_authentication}
                              />
                            }
                            label=" Make Meeting Authenticated"
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment> : null}
                  <Divider />
                  {isHost && (<div className={classes.btnCtr}>
                    <Button
                      variant="contained"
                      disabled={inProgress}
                      onClick={() => {
                        handleDialogOpen();
                      }}
                      className={classes.buttons}
                    >
                      Delete Event
                      {!dialogOpen && inProgress ? (
                        <CircularProgress
                          size={30}
                          className={classes.btnDeleteLoader}
                        />
                      ) : null}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        clearRenderInfo();
                      }}
                      className={classes.buttons}
                    >
                      Cancel
                    </Button>
                  </div>)}
                </div>
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Are you sure you want to delete selected Event ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={20} className={classes.btnDeleteLoader} />
          ) : null}
          <Button
            onClick={() => {
              deleteEvent();
            }}
            disabled={inProgress}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
          <Button disabled={inProgress} onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openStoreLocatorPopup ? (
        <StoreLocatorPopup
          open={openStoreLocatorPopup}
          closeStoreLocatorPopup={closeStoreLocatorPopup}
          getStoreSelectedLocation={getStoreSelectedLocation}
        />
      ) : null}
      {eventParticipantsPopup.dialogPopup && <ViewEventParticipantsPopup eventTitle={title} eventData={eventParticipantsPopup} close={closeViewParticipantsPopup} />}
    </React.Fragment >
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(Meetings);

const useStyles = makeStyles((theme) => ({
  memberListAutoSuggest: {
    marginBottom: "1rem"
  },
  visIcnCtr: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  visIcn: {
    color: style.iconColor._color,
    fontSize: '16px',
    paddingRight: '5px'
  },
  visTxt: {
    color: style.fontColour._blue,
    fontSize: '12px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  selMemChip: {
    color: "#2e8eec",
    marginTop: "0.3rem",
    marginLeft: "0.3rem",
    border: "1px solid #2e8eec",
  },
  evntLabelWrapper: {
    color: '#2e8eec',
    border: '1px solid #2e8eec',
    fontSize: '11px',
    height: '21px',
    marginBottom: '5px',
    marginTop: '10px',
    marginLeft: '3px'
  },
  teamName: {
    fontSize: "1rem",
    color: "#15528d",
    textAlign: 'initial',
    padding: '0.3rem'
  },
  calendar: {
    height: 500,
    minWidth: '800px',
    overflowX: 'scroll'
  },
  calendarMob: {
    height: 500,
    minWidth: '600px',
    width: "800px",
    overflowX: 'scroll'
  },
  memCtr: {
    paddingBottom: '1rem'
  },
  lstHdr: {
    textAlign: 'end',
    padding: '0.5rem',
    cursor: 'pointer'
  },
  iconBtn: {
    borderRadius: "5px",
  },
  eventItem: {
    marginLeft: "0.5rem",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  selectFormControl: {
    width: "100%"
  },
  membersCntr: {
    maxHeight: "400px",
    overflowY: 'scroll',
  },
  formButtonContainer: {
    textAlign: "center",
    float: 'right'
  },
  buttons: {
    margin: '0.5rem',
    backgroundColor: colorStyles.btnColor,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
    color: colorStyles.btnText,
    textTransform: 'none'
  },
  hrefBtn: {
    borderRadius: '5px',
    padding: '0.5rem 0.8rem',
    textDecoration: 'none',
    backgroundColor: colorStyles.btnColor,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
    color: colorStyles.btnText,
  },
  gridItem: {
    marginTop: "1rem",
    marginBottom: '1rem'
  },
  siderPaper: {
    marginLeft: '0.5rem',
    minWidth: "300px"
  },
  siderCntr: {
    height: "508px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  siderCntrMob: {
    height: "15rem",
    overflowY: "scroll",
  },
  gridCtr: {
    marginTop: "1rem",
  },
  textField: {
    width: "100%",
  },
  dateCtr: {
    margin: "0.2rem 0.5rem 0 0",
    display: "flex",
  },
  timeCtr: {
    margin: "0 0.5rem 0.2rem 0.5rem",
    display: "flex",
  },
  timeRecCtr: {
    margin: "0 0.5rem 0.2rem 0",
    display: "flex",
  },
  recordingTypeSelect: {
    width: "100%"
  },
  dateVal: {
    textAlign: "center",
    color: "#11539f",
    fontSize: "0.8rem",
  },
  eventsTitle: {
    marginTop: "6rem",
    marginBottom: "0.5rem",
    fontSize: "1rem",
    padding: "1rem 0 1rem 0",
    backgroundColor: "#eaf2f2",
    color: "#11539f",
  },
  itemTitle: {
    fontSize: "1rem",
    color: "#11539f",
    marginTop: "0.3rem",
    textAlign: "initial",
  },
  calIcon: {
    margin: "5px",
    color: "#11539f",
  },
  buttonProgress: {
    marginLeft: "0.5rem",
    textAlign: 'center',
    color: style.fontColour._blue,
    justifyContent: 'center',
    marginBottom: '-10px'
  },
  btnDeleteLoader: {
    marginLeft: "0.5rem",
    textAlign: 'center',
    color: style.fontColour._blue,
    justifyContent: 'center',
  },
  editIcon: {
    color: "#11539f",
  },
  event: {
    marginTop: "25px",
    color: style.fontColour._blue,
  },
  timings: {
    fontSize: "0.8rem",
    color: "#11539f",
    textTransform: "uppercase",
  },
  recHostLabel: {
    fontSize: "0.8rem",
    color: '#2e8eec',
    fontWeight: 500
  },
  recLabel: {
    textAlign: "initial",
    fontSize: "0.8rem",
    color: "#424548",
  },
  recDays: {
    fontSize: "0.8rem",
    color: "#424548",
  },
  approvalTypeSelect: {
    marginLeft: "1rem",
    marginTop: "0.5rem",
    width: "80%",
  },
  container: {
    textAlign: "initial",
    margin: "1rem 0 1rem 0",
  },
  mtitle: {
    textAlign: "initial",
    margin: "0.7rem 0 0 1rem",
    fontSize: "1rem",
    padding: "0.5rem",
  },
  btnCtr: {
    display: "flex",
    justifyContent: "center",
    marginRight: "0.5rem",
    paddingBottom: "0.5rem",
    marginTop: '1rem'
  },
  detailsHeader: {
    backgroundColor: "#eaf2f2",
    borderRadius: "5px",
  },
  flex: {
    display: "flex",
  },
  label: {
    color: "#11539f",
    fontWeight: 500,
  },
  value: {
    fontWeight: 400,
    color: "#000",
    marginLeft: "0.3rem",
  },
  uploadIcon: {
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    width: 62,
    border: '2px solid #ccc',
  },
  helperText: {
    textAlign: 'left',
    color: '#999',
    fontWeight: 500,
    marginTop: '-15px',
    fontSize: '12px'
  },
  itemBlock: {
    cursor: 'pointer'
  },
  noDataWrapper: {
    color: "#11539f",
    fontWeight: 400,
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '10px'
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  searchBox: {
    margin: 5,
    marginBottom: '10px',
    width: '292px'
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  searchIcon: {
    color: '#2e8eec',
    fontSize: '34px',
  },
  input: {
    '&::placeholder': {
      fontSize: '13px',
      fontStyle: 'italic',
      fontWeight: 500
    }
  },
  clearFilterWrap: {
    fontSize: '13px',
    color: '#2e8eec',
    marginLeft: '7px',
    marginBottom: '5px'
  },
  storeList: {
    fontSize: '10px',
    width: '100%',
    textAlign: 'center'
  },
  storeTitle: {
    display: 'block',
    color: '#2e8eec',
    fontWeight: 700
  },
}));

import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
//LOCAL IMPORTS
import GoogleMap from '../Common/GoogleMap'
import CaseysLogo from '../../static/caseyswhitefontlogo.png'
import { showSnackBar } from "../../store/AlertMessages/actionCreator";
import { fetchAllStores } from '../../store/CaseysStoreLocator/actionCreator'
import CalenderSiderLoader from '../Common/skeletonLoaders/calenderSiderLoader'
import { getUserDetails } from '../../Helpers/basic';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    backgroundColor: '#ed3833'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  locatorWrapper: {
    marginTop: 50
  },
  selectionList: {
    width: 200,
    borderRight: '1px solid #f5f5f5',
    display: 'inline-block',
    verticalAlign: 'text-top'
  },
  coloumnTitle: {
    textAlign: 'center',
    padding: 20
  },
  storeList: {
    fontSize: '10px',
    width: '100%',
    textAlign: 'center'
  },
  storeTitle: {
    display: 'block',
    color: '#ed3833',
    fontWeight: 700
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StoreLocatorPopup(props) {
  const classes = useStyles();
  const userDetails = getUserDetails();
  const [open, setOpen] = useState(props.OpenStoreLocatorPopup);
  const [checked, setChecked] = useState([0]);
  const [fetchAllStoresInProgress, setFetchAllStoresInProgress] = useState(false)
  const [allStores, setAllStores] = useState([]);
  const [searchFilterStores, setSearchFilterStores] = useState([]);
  const [searchFilterTerm, setSearchFilterTerm] = useState('');
  const [selectedStores, setSelectedStores] = useState(userDetails.office && userDetails.office)

  const updateSelectedList = store => {
    setSelectedStores(store)
  }
  const fetchAllStoresSuccessCallBack = res => {
    setAllStores(res && res.data && res.data)
    setFetchAllStoresInProgress(false)
  }
  const fetchAllStoresFailureCallBack = err => {
    setFetchAllStoresInProgress(false)
    setAllStores([])
    props.showSnackBar(
      {
        state: true,
        message: 'Something went wrong while fetching stores list!',
        type: 'error'
      }
    )
  }
  useEffect(() => {
    setFetchAllStoresInProgress(true)
    fetchAllStores({}, fetchAllStoresSuccessCallBack, fetchAllStoresFailureCallBack)
  }, [])
  const handleClose = () => {
    props.closeStoreLocatorPopup()
  };
  const handleLocationToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSearchStore = (evnt) => {
    setSearchFilterTerm(evnt.target.value);
    var name = evnt.target.value && evnt.target.value
    var results = [];
    var entry;

    name = name.toUpperCase()
    allStores && allStores.length && allStores.forEach((data, index) => {
      entry = data;
      if (entry && entry.store && entry.store.toUpperCase().indexOf(name) !== -1) {
        results.push(entry)
      }
    })
    setSearchFilterStores(results)
  }

  const onProceedClick = (location) => {
    props.getStoreSelectedLocation(location);
    props.closeStoreLocatorPopup();
  }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <img src={CaseysLogo} style={{ width: 100, marginRight: 10 }} alt="Caseys logo" />
            <Typography variant="h6" className={classes.title}>
              Event Store Locator
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div className={classes.locatorWrapper}>
          <div className={classes.selectionList}>
            <Typography variant="body1" gutterBottom className={classes.coloumnTitle} style={{ margin: 0, paddingBottom: 0 }}>
              Select store
            </Typography>
            <TextField autoComplete="off" id="standard-search" label="Search Store" type="search" style={{ marginLeft: 20, marginBottom: 20 }} defaultValue={searchFilterTerm} onChange={handleSearchStore} />
            {fetchAllStoresInProgress ? (
              <CalenderSiderLoader />
            ) : (
              <React.Fragment>
                <List style={{ maxHeight: '492px', overflowY: 'auto' }}>
                  {!searchFilterTerm ? (
                    <React.Fragment>
                      {allStores && allStores.length ? (
                        <React.Fragment>
                          {allStores && allStores.length && allStores.map((data, index) => (
                            <React.Fragment key={index}>
                              <ListItem button onClick={() => updateSelectedList(data)} style={{ backgroundColor: selectedStores && selectedStores.store_id === data.store_id ? '#999' : '' }} >
                                <Typography variant="body2" gutterBottom className={classes.storeList}>
                                  <span className={classes.storeTitle}>{data.store}</span>
                                  {data.address}<br />
                                  {data.city}<br />
                                  {data.state}<br />
                                  {data.country}<br />
                                  {data.zip_code}<br />
                                </Typography>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ) : (
                        <div style={{ fontSize: '13px', textAlign: 'center' }}>No stores found</div>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {searchFilterStores && searchFilterStores.length ? (
                        <React.Fragment>
                          {searchFilterStores && searchFilterStores.length && searchFilterStores.map((data, index) => (
                            <React.Fragment key={index}>
                              <ListItem button onClick={() => updateSelectedList(data)} style={{ backgroundColor: selectedStores && selectedStores.store_id === data.store_id ? '#999' : '' }}>
                                <Typography variant="body2" gutterBottom className={classes.storeList}>
                                  <span className={classes.storeTitle}>{data.store}</span>
                                  {data.address}<br />
                                  {data.city}<br />
                                  {data.state}<br />
                                  {data.country}<br />
                                  {data.zip_code}<br />
                                </Typography>
                              </ListItem>
                              <Divider />
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ) : (
                        <div style={{ fontSize: '13px', textAlign: 'center' }}>No stores found</div>
                      )}
                    </React.Fragment>
                  )}
                </List>
              </React.Fragment>
            )}
          </div>
          <div className={classes.selectionList} style={{ width: '84%', marginTop: '10px' }}>
            {selectedStores ? (
              <div style={{ padding: '0px 20px 20px 20px' }}>
                <GoogleMap allStores={allStores} selectedStores={selectedStores} onProceedClick={onProceedClick} />
              </div>
            ) : (
              <Typography variant="body2" gutterBottom className={classes.storeList} style={{ color: 'red' }}>
                Please select the store.
              </Typography>
            )}
          </div>

        </div>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { showSnackBar })(StoreLocatorPopup);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
//local imports
import { style } from '../../Styles/theme';
import AlertMessages from '../Common/AlertMessages';
import { clientImgMap } from '../../Configs/clientImgConfig';
import myAthinaLogo from '../../static/athinaLogo.png'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { requestForgotPassword } from '../../store/Auth/actionCreator';
import { getDomainInfo } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: '15%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '14px 13px 21px 14px'
  },
  myAthinaLogoWrapper: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '23px',
    marginLeft: 10
  },
  logoImg: {
    width: '43px'
  },
  buttonProgress: {
    color: style.fontColour._blue,
  },
  label: {
    width: '100%',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    color: '#777'
  },
  logoImg: {
    width: '70px',
    marginTop: '12px'
  },
  logoImgRMBL: {
    width: '80px',
    height: '61px',
    marginTop: '18px'
  },
  form: {
    width: '70%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  textFiledWrapper: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    marginRight: '10px',
    marginTop: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  }
}));

function ForgetPassword(props) {
  const classes = useStyles();
  const url = window.location.href
  const domainDetails = getDomainInfo()
  const subDomain = url.slice(url.indexOf('//') + 2).split('.')
  const clientImg = clientImgMap[subDomain[0]] && clientImgMap[subDomain[0]].img ? clientImgMap[subDomain[0]].img : null
  const [inProgress, setInProgress] = useState(false)
  const [reqForgotPassword, setReqForgotPassword] = useState(false)
  const [errMsg, setErrMsg] = useState({
    err: false,
    msg: ''
  })

  useEffect(() => {
    window.FreshworksWidget('hide')
  }, [])

  const successForgot = res => {
    setErrMsg({
      err: false,
      msg: ''
    })
    setInProgress(false)
    setReqForgotPassword(true)
  }
  const failureForgot = res => {
    let statusCode = res.response && res.response.status && res.response.status
    if (statusCode === 400) {
      setErrMsg({
        err: true,
        msg: 'There is no active user associated with this e-mail address. Please try with the different e-mail address or contact myAthina support.'
      })
    } else {
      setErrMsg({
        err: true,
        msg: 'Invalid e-mail address!'
      })
    }
    setInProgress(false)
    setReqForgotPassword(false)
  }

  const onForgotPassword = event => {
    event.preventDefault()
    const userName = document.getElementById('userName').value
    if (!userName) {
      setErrMsg({
        err: true,
        msg: 'Please add email address!'
      })
    } else {
      setErrMsg({
        err: false,
        msg: ''
      })
      setInProgress(true)
      requestForgotPassword({
        email: userName
      }, successForgot, failureForgot)
    }
  }

  const goToLogin = () => {
    props.history.push('/')
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          {clientImg ? (
            <img src={clientImg} className={subDomain.length > 0 && subDomain[0] && subDomain[0] == 'rmbl' ? classes.logoImgRMBL : classes.logoImg} alt={`${domainDetails.client} logo`} />
          ) : null}
          <img src={myAthinaLogo} className={classes.myAthinaLogoWrapper} alt="myathina" />
        </Typography>
        {errMsg.err ? (
          <div style={{ marginTop: '20px', width: '60%' }}>
            <AlertMessages status={errMsg.err ? 'error' : 'success'} message={errMsg.msg} />
          </div>
        ) : null}
        {reqForgotPassword ? (
          <div style={{ marginTop: '20px', width: '90%' }}>
            <AlertMessages status="success" message="We have sent change password link to your email. Please click that link and change your password." />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => { goToLogin() }}
              disabled={inProgress}
            >
              Go to login
            </Button>
          </div>
        ) : (
          <React.Fragment>
            <Typography component="h3" variant="h6" className={classes.label}>Forgot / Reset password</Typography>
            <Typography style={{ fontWeight: 500, color: '#999', fontSize: '15px', marginBottom: '10px' }}>Enter the email address associated with your account</Typography>
            <form className={classes.form} noValidate onSubmit={(event) => { onForgotPassword(event) }} autoComplete="off">
              <TextField
                variant="outlined"
                required
                fullWidth
                size="small"
                id="userName"
                placeholder="Email Address"
                name="email"
                autoFocus
                InputProps={{
                  classes: {
                    notchedOutline: classes.textFiledWrapper
                  }
                }}
              />
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                className={classes.button}
                onClick={(event) => { onForgotPassword(event) }}
                disabled={inProgress}
              >
                {inProgress && (
                  <CircularProgress size={25} className={classes.buttonProgress} />
                )}
                Request to change password
              </Button>
              <Button
                variant="contained"
                color="default"
                size="small"
                className={classes.button}
                onClick={() => { goToLogin() }}
                disabled={inProgress}
                style={{ marginLeft: 10 }}
              >
                Cancel
              </Button>
            </form>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(ForgetPassword);
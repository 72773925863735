import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { scormReportFetch } from '../../store/MycourseListing/actionCreator'
import { ERROR_FETCH_COURSE_DETAILS, ERROR_FETCH_SCORM_REPORT } from '../Common/Constants/errorMessages'
import { convertUTCTimeZone } from '../../Helpers/basic'
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator'
import Header from './header'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    paddingTop: '8px',
    backgroundColor: '#f5f5f5'
  },
  tableHeadingCell: {
    padding: '12px',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#333',
    backgroundColor: '#dedede'
  },
  mainWrapper: {
    paddingLeft: '120px',
    paddingRight: '120px',
    width: '100%',
    marginTop: '2rem'
  },
  buttonProgress: {
    marginTop: '10px'
  },
  textWrap: {
    textAlign: 'initial',
    marginBottom: '10px',
    color: '#3e98c8',
    fontWeight: 500
  },
  visitorContainer: {
    margin: '0.5rem'
  },
  tableCell: {
    padding: "12px"
  }

});

function CourseReport(props) {
  const classes = useStyles();
  const [courseData, setCourseData] = React.useState({});
  const [reportData, setReportData] = React.useState([])
  const [inProgress, setInProgress] = React.useState(true)

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 3; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  const courseSuccessCallBack = res => {
    setCourseData(res.results)
  }
  const courseFailureCallBack = error => {
    setCourseData({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_COURSE_DETAILS,
        type: 'error'
      }
    )
  }

  const reportSuccessCallBack = res => {
    setInProgress(false)
    setReportData(res)
  }

  const reportFailureCallBack = error => {
    setReportData([])
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_SCORM_REPORT,
        type: 'error'
      }
    )
  }

  useEffect(() => {
    setInProgress(true)
    courseDetailsFetch({
      id: props.match.params.courseId
    }, courseSuccessCallBack, courseFailureCallBack)
    scormReportFetch({
      course_id: props.match.params.courseId
    }, reportSuccessCallBack, reportFailureCallBack)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  return (
    <div >
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Course report Page</h1>
      </div>
      <div style={{ marginTop: '-80px' }}>
        <Header courseData={courseData} match={props.match} history={props.history} />
      </div>
      <div className={classes.mainWrapper}>
        {inProgress ? (
          listLoader()
        ) : (
          <React.Fragment>
            {reportData && reportData.results && reportData.results.length ? (
              <React.Fragment>
                {reportData.total_visitor ? (
                  <div className={classes.visitorContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Typography className={classes.textWrap}>Total : {reportData.total_visitor}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Typography className={classes.textWrap}>Yet-to-Start : {reportData.yet_to_start_obj}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Typography className={classes.textWrap}>In-Progress : {reportData.inprogress_obj}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Typography className={classes.textWrap}>Completed : {reportData.completed_obj}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ) : null}
                <TableContainer component={Paper}>
                  {inProgress ? (
                    <CircularProgress size={50} className={classes.buttonProgress} />
                  ) : null}
                  <Table className={classes.table} aria-label="course report table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className={classes.tableHeadingCell}>Name</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Last Visited</TableCell>
                        <TableCell align="center" className={classes.tableHeadingCell}>Percent</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportData.results.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                            {row.first_name ? row.first_name : ''} {row.last_name ? row.last_name : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                            {convertUTCTimeZone(row.visited_date)}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center" className={classes.tableCell}>

                            {row && row.percent ?
                              <div style={{ height: '3rem', width: '3rem', margin: 'auto' }}>
                                <CircularProgressbar
                                  value={row.percent}
                                  text={`${row.percent}%`}
                                />
                              </div>
                              :
                              <div style={{ height: '3rem', width: '3rem', margin: 'auto' }}>
                                <CircularProgressbar
                                  value={0}
                                  text={`${0}%`} />
                              </div>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            ) : (
              <Typography style={{ textAlign: 'center', fontSize: '25px' }}>No data found</Typography>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(CourseReport);
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AdjustIcon from '@material-ui/icons/Adjust';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List'
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import { style } from '../../Styles/theme'
import { convertUTCTimeZone, redirectTo, getDomainInfo } from '../../Helpers/basic'
import AddVideo from './addVideos'
import AthenaImage from '../../static/myAthinaCourse.png'


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      marginTop: '10px',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%'
  },
  cardWrapper: {
    maxWidth: 273,
    margin: "auto",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 10px 50px -12.125px rgba(0,0,0,0.3)",
      transition: 'transform 1s',
      transform: 'translateY(-4px)'
    }
  },
  button: {
    flexGrow: 1,
    marginTop: '10px',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  },
  titleWrapper: {
    fontSize: '29px',
    fontWeight: '500',
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  avatar: {
    backgroundColor: '#2e8eec'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    cursor: 'pointer'
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#2e8eec',
    textAlign: 'initial',
    lineBreak: 'anywhere'
  },
  circleWrapper: {
    cursor: 'pointer',
    width: '136px',
    height: '136px',
    backgroundColor: 'lightgrey',
    position: 'relative',
    borderRadius: '68px'
  },
  uploadWrapper: {
    position: 'absolute',
    top: '18px',
    left: '48px',
    color: '#949494',
    width: '40px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '100px'
  }
}))

function MyVideosList(props) {
  const classes = useStyles()
  const videosData = props.videosList && props.videosList.length && props.videosList
  const [selectedCard, setSelectedCard] = React.useState({})
  const [openEditVideo, setOpenEditVideo] = React.useState(false)

  const onVideoEditClick = (event, data) => {
    setSelectedCard(data)
    setOpenEditVideo(true)
  }

  const closeEditMode = () => {
    setOpenEditVideo(false)
  }

  const openVideoPreview = (videoId) => {
    let domainDet = getDomainInfo()
    redirectTo(`${domainDet.renderingURL}/video/${videoId}/`, true)
  }

  const loadAlterImage = evnt => {
    evnt.target.src = AthenaImage
  }

  return (
    <div className={classes.root}>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!openEditVideo ? (
            <Paper className={classes.paper}>
              <Typography className={classes.titleWrapper} aria-hidden="true">My Videos</Typography>
              <Divider style={{ marginBottom: '20px' }} aria-hidden="true" />
              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <h1>My videos listing view</h1>
              </div>
              { videosData && videosData.length ? (
                <React.Fragment>
                  <List>
                    <Grid container spacing={3}>
                      {videosData && videosData.map((data, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={12} sm={4} md={3} lg={3} xl={3} style={{ marginBottom: '10px' }}>
                            <Card className={classes.cardWrapper}>
                              <div style={{ position: 'relative' }} >
                                <CardMedia
                                  onError={loadAlterImage}
                                  className={classes.media}
                                  image={data && data.thumbnail_url ? data.thumbnail_url : AthenaImage}
                                  title={data && data.title && data.title}
                                  onClick={() => { openVideoPreview(data.id) }}
                                />
                                <div style={{
                                  position: 'absolute',
                                  color: 'white',
                                  top: 126,
                                  left: '86%',
                                  backgroundColor: '#000000',
                                  borderRadius: '3px',
                                  paddingLeft: '3px',
                                  paddingRight: '3px',
                                  transform: 'translateX(-50%)'
                                }} aria-hidden="true">{data && data.duration && data.duration}</div>
                              </div>
                              <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                                <p>{`video duration ${data && data.duration && data.duration}`}</p>
                              </div>
                              <CardContent className={classes.cardTitle}>
                                {data && data.title && data.title.length > 25 ? (
                                  data.title.substr(0, 20) + '...'
                                ) : (
                                    data && data.title
                                  )}
                              </CardContent>
                              <div style={{ textAlign: 'left', marginBottom: '7px', color: '#43494e', fontSize: '14px', fontWeight: '400' }}>
                                <AdjustIcon aria-label="views count" style={{ fontSize: '13px', color: style.fontColour._blue, marginBottom: '-2px', marginLeft: '13px', marginTop: '13px', marginRight: '3px' }} />
                                <span>Published: </span><span>{convertUTCTimeZone(data.created_date)}</span>
                              </div>
                              <CardActions>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginBottom: '10px' }}>
                                    <Button
                                      aria-label="Edit video"
                                      onClick={(event) => { onVideoEditClick(event, data) }}
                                      size="small"
                                      variant="outlined"
                                      startIcon={<EditIcon />}
                                      style={{ color: style.fontColour._blue, border: '1px solid #2e8eec', fontSize: '12px' }}>
                                      Edit video
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: '3px' }}>
                                    <VisibilityIcon aria-label="views count" style={{ fontSize: '21px', color: style.fontColour._blue, marginBottom: '-5px' }} /> <span style={{ fontSize: '15px', marginTop: '4px', fontWeight: 400, color: style.fontColour._blue }}>{`${data && data.views_count && data.views_count} views`}</span>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </List>
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <div onClick={props.triggerAddVideo} className={classes.circleWrapper}>
                        <PublishIcon className={classes.uploadWrapper} />
                      </div>
                      <Typography style={{ marginTop: '12px', color: '#546e7a' }}>Please click here to upload your videos and get started</Typography>
                      <Typography style={{ fontSize: '13px', marginTop: '3px', marginBottom: '21px', color: '#9e9e9e', fontWeight: '500' }}>Start sharing your videos with the team. Videos you upload will show up here.</Typography>
                    </div>
                    <Button
                      variant="contained"
                      className={classes.button}
                      startIcon={<PublishIcon />}
                      onClick={props.triggerAddVideo}
                    >
                      <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd' }} aria-hidden="true" />
                    Upload video
                  </Button>
                  </React.Fragment>
                )}
            </Paper>
          ) : null}
        </Grid>
      </Grid>
      {openEditVideo ? (
        <div style={{ marginTop: '-100px' }}>
          <AddVideo
            listAllVideos={props.listAllVideos}
            cancelAddVideos={closeEditMode}
            videoDetails={selectedCard}
            history={props.history}
            match={props.match}
          />
        </div>
      ) : null}
    </div>
  )

}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(MyVideosList);
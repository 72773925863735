import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import SendIcon from '@material-ui/icons/Send';
import { style } from '../../../Styles/theme';
import { sendNoticeMessage, updateNoticeMessage } from '../../../store/NoticeBoard/actionCreator';

const useStyles = makeStyles(theme => ({
  sendToWrapper: {
    paddingLeft: 24
  },
  groupChip: {
    marginTop: '10px',
    marginBottom: '10px',
    height: '24px',
    color: style.fontColour._blue,
    border: "1px solid #2e8eec",
    marginLeft: '3px'
  },
  groupName: {
    display: 'block'
  },
  membersCount: {
    fontSize: '10px'
  },
  button: {
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  uploadAttchment: {
    width: 62,
    color: 'blue',
    cursor: 'pointer'
  },
  iconWrapper: {
    color: '#2e8eec',
    marginBottom: '-4px',
    fontSize: '20px',
    marginRight: '3px'
  }
}));

export default function NoticePreview(props) {
  const classes = useStyles();
  const open = props.openPreview
  const [scroll, setScroll] = React.useState('paper');
  const [sendNoticeInPtogress, setSendNoticeInPtogress] = React.useState(false);

  const handleClose = () => {
    props.closePreview()
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const createMarkup = data => {
    return {
      __html: data
    };
  }
  const sendNoticeSuccess = res => {
    setSendNoticeInPtogress(false)
    props.sendNoticeSuccess()
  }
  const sendNoticeFailiure = error => {
    setSendNoticeInPtogress(false)
    props.sendNoticeFailiure()
  }

  //FOR UPDATING THE NOTICE CALLBACKS
  const updateNoticeSuccess = res => {
    setSendNoticeInPtogress(false)
    props.sendNoticeSuccess()
  }
  const updateNoticeFailiure = error => {
    setSendNoticeInPtogress(false)
    props.sendNoticeFailiure()
  }

  const collectIds = list => {
    let arr = []
    list.forEach((emp, index) => {
      arr.push(emp.id);
    });
    return arr
  }
  const sendNotice = () => {
    setSendNoticeInPtogress(true)
    if (props.noticeEditMode) {
      updateNoticeMessage({
        everyone: props.sendToList.everyone,
        all_students: props.sendToList.allStudents,
        all_mentors: props.sendToList.allMentors,
        all_instructors: props.sendToList.allInstructors,
        select_by_groups: props.sendToList.selectByGroups,
        groups: collectIds(props.selectedGroups),
        individual: props.sendToList.individual,
        individual_users: collectIds(props.selectedIndividuals),
        subject: props.subject,
        message: props.message
      }, updateNoticeSuccess, updateNoticeFailiure)
    } else {
      sendNoticeMessage({
        everyone: props.sendToList.everyone,
        all_students: props.sendToList.allStudents,
        all_mentors: props.sendToList.allMentors,
        all_instructors: props.sendToList.allInstructors,
        select_by_groups: props.sendToList.selectByGroups,
        groups: collectIds(props.selectedGroups),
        individual: props.sendToList.individual,
        individual_users: collectIds(props.selectedIndividuals),
        subject: props.subject,
        message: props.message
      }, sendNoticeSuccess, sendNoticeFailiure)
    }
  }
  const renderGroupList = () => {
    return (
      props.selectedGroups.map((data, index) => {
        let labelName = data && data.group_title && data.group_title + " (" + data.members_count + ")"
        return (
          <Chip
            key={index}
            variant="outlined"
            className={classes.groupChip}
            label={labelName}
          />
        )
      })
    )
  }
  const renderIndividualList = () => {
    return (
      props.selectedIndividuals.map((data, index) => (
        <Chip
          key={index}
          variant="outlined"
          className={classes.groupChip}
          label={data && data.email && data.email}
        />
      ))
    )
  }
  const sendToDispStruct = {
    allStudents: 'All Students',
    allMentors: 'All Mentors',
    allInstructors: 'All Instructors',
    everyone: 'Everyone'
  }
  const rendderToList = () => {
    if (props.selectedGroups.length) {
      return renderGroupList()
    } else {
      if (!props.sendToList.individual) {
        return (
          Object.keys(props.sendToList).map(function (key, val) {
            if (props.sendToList[key]) {
              return (
                <Chip
                  key={key}
                  variant="outlined"
                  className={classes.groupChip}
                  label={sendToDispStruct[key]}
                />
              )
            }
          })
        )
      } else {
        return renderIndividualList()
      }
    }
  }
  return (
    <div>
      <Dialog
        open={true}
        fullWidth
        maxWidth='md'
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.subject}</DialogTitle>
        <div className={classes.sendToWrapper}>
          Sent to:
          <span>
            {rendderToList()}
          </span>
        </div>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            style={{ lineBreak: 'anywhere' }}
            dangerouslySetInnerHTML={createMarkup(props.message)}
          >
          </DialogContentText>
          {props && props.attachment && props.attachment.name ? (
            <React.Fragment>
              {props.attachment && props.attachment.name && props.attachment.name.split('.')[1] === 'pdf' ? <PictureAsPdfIcon className={classes.iconWrapper} /> : props.attachment.name.split('.')[1] === 'ppt' ? <PictureInPictureIcon className={classes.iconWrapper} /> : <DescriptionIcon className={classes.iconWrapper} />}
              <a href={props.attachmentPreview && props.attachmentPreview} target='_blank' className={classes.uploadAttchment}>
                {props.attachment && props.attachment.name && props.attachment.name && props.attachment.name}
              </a>
            </React.Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          {props.onlyPreview ? (
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          ) : (
            <React.Fragment>
              <Button
                onClick={sendNotice}
                variant="contained"
                disabled={sendNoticeInPtogress}
                endIcon={<SendIcon />}
                className={classes.button}
              >
                {sendNoticeInPtogress ? (
                  <CircularProgress size={30} style={{ marginRight: 10 }} />
                ) : null}
                {props.noticeEditMode ? 'Update Notice' : 'Send Notice'}
              </Button>
              <Button onClick={handleClose} color="primary" disabled={sendNoticeInPtogress}>
                Cancel
              </Button>
            </React.Fragment>
          )}

        </DialogActions>
      </Dialog>
    </div>
  );
}

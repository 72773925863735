import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function CurrencyFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
      decimalScale={0}
    />
  );
}

CurrencyFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
function DurationFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix=""
      allowNegative={false}
      decimalScale={0}
    />
  );
}

DurationFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function CurrencyInput(props) {
  let defaultValue = props.defaultValue ? props.defaultValue : ''
  const [values, setValues] = React.useState({
    numberformat: defaultValue,
  });
  const label = props.label

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    });
    props.setValue(event.target.value)
  };

  return (
    <React.Fragment>
      {props.type && props.type === 'duration' ? (
        <TextField
          required
          label={label}
          value={values.numberformat}
          autoComplete="off"
          onChange={handleChange('numberformat')}
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: DurationFormatCustom,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={props.helperText ? props.helperText : ''}
        />
      ) : (
        <TextField
          required
          label={label}
          value={values.numberformat}
          onChange={handleChange('numberformat')}
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: CurrencyFormatCustom,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={props.helperText ? props.helperText : ''}
        />
      )}

    </React.Fragment>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { getDomainInfo } from '../../../../Helpers/basic'
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import VimeoPlayer from '../../Player/vimeoPlayer';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    height: '42px',
    backgroundColor: '#F5F5F5',
  },
  title: {
    flex: 1,
    marginTop: '-24px',
    color: 'black'
  },
  iconWrapper: {
    marginTop: '-24px'
  },
  paper: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 1098,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '20px'
    },
  },
  uploadedImgWrapper: {
    width: '1056px',
    height: '147px'
  },
  videoPlayerWrapper: {
    width: '1056px',
    height: '252px'
  },
  infoBlock: {
    fontSize: '1rem',
    marginTop: '5px',
    marginBottom: '5px',
    textAlign: 'left'
  },
  detailsHead: {
    fontSize: '15px'
  },
  detailTagsWrapper: {
    border: '1px solid',
    padding: '3px',
    marginRight: '15px',
    borderRadius: '20px',
    borderColor: 'cornflowerblue'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPopup(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const open = props.openFormPrev
  const courseCategoryList = props.courseCategoryList ? props.courseCategoryList : null
  const courseLevel = props.courseLevel ? props.courseLevel : null
  const initiateCoursevalues = {
    title: props.courseTitle ? props.courseTitle : null,
    desc: props.courseDesc ? props.courseDesc : null,
    banner_url: props.bannerImageDetails ? props.bannerImageDetails : null,
    video_url: props.introVideoFileDetails ? props.introVideoFileDetails : null,
    transcript: props.transcriptFileDetails ? props.transcriptFileDetails : null,
    level: props.selectedCourseLevel ? props.selectedCourseLevel : null,
    category: props.selectedCategory ? props.selectedCategory : null,
    courseTags: props.selectedCourseTags ? props.selectedCourseTags : null
  }
  let defaultSelectedCourseTags = []
  for (let i in initiateCoursevalues.courseTags) {
    defaultSelectedCourseTags.push(initiateCoursevalues.courseTags[i])
  }
  function createMarkup(data) {
    return {
      __html: data
    };
  }
  const courseCategory = courseCategoryList && courseCategoryList.map(item => item.id === initiateCoursevalues.category ? item.name : null)
  const selectedCategory = courseCategoryList && courseCategory.filter(Boolean) ? courseCategory.filter(Boolean)[0] : null
  const courseLevelList = courseLevel && courseLevel.map(item => item.id === initiateCoursevalues.level ? item.name : null)
  const selectedLevel = courseLevel && courseLevelList.filter(Boolean) ? courseLevelList.filter(Boolean)[0] : null
  return (
    <div>
      <Dialog fullScreen open={open} onClose={props.closeFormPreview} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title} aria-hidden="true">
              Course Preview
            </Typography>
            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
              <h1>Course details preview popup</h1>
            </div>
            <IconButton edge="start" className={classes.iconWrapper} onClick={props.closeFormPreview} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                {initiateCoursevalues.banner_url ? <img className={classes.uploadedImgWrapper} src={initiateCoursevalues.banner_url} alt="BannerImage" /> : null}
              </Grid>
              <Grid item xs={12}>
                {initiateCoursevalues && initiateCoursevalues.video_url && initiateCoursevalues.video_url.includes('blob') ? (
                  <video className={classes.videoPlayerWrapper} id="player" playsInline controls>
                    <source src={initiateCoursevalues.video_url} type="video/mp4" />
                  </video>
                ) : (
                  <React.Fragment>
                    {initiateCoursevalues && initiateCoursevalues.video_url && initiateCoursevalues.video_url ? (
                      <VimeoPlayer videoUrl={initiateCoursevalues.video_url} />
                    ) : null}
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" display="block" className={classes.infoBlock}>
                  <span className={classes.detailsHead}><b>{initiateCoursevalues.title ? initiateCoursevalues.title : null}</b> </span>
                </Typography>
              </Grid>
              {domainInfo && domainInfo.name !== 'rmbl' ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Typography variant="caption" display="block" className={classes.infoBlock}>
                      <span className={classes.detailsHead}>{selectedLevel ? selectedLevel : null} </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" display="block" className={classes.infoBlock}>
                      <span className={classes.detailsHead}>{selectedCategory ? selectedCategory : null}</span>
                      <span></span>
                    </Typography>
                  </Grid>
                </React.Fragment>
              ) : null}
              <Grid item xs={12}>
                <Typography variant="caption" display="block" className={classes.infoBlock}>
                  <span className={classes.detailsHead}><div dangerouslySetInnerHTML={createMarkup(initiateCoursevalues.desc)}></div></span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" >
                  {defaultSelectedCourseTags.map((data, index) => (
                    <React.Fragment key={index}>
                      <Chip label={data} style={{ marginLeft: '4px' }} />
                    </React.Fragment>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </Dialog>
    </div>
  );
}
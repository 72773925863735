import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { submitForReview } from '../../store/AddingCourse/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_COURSE_SUBMIT_REVIEW } from '../Common/Constants/errorMessages'
import { SUCCESS_COURSE_SUBMIT_REVIEW } from '../Common/Constants/successMessages'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    display: 'block'
  },
}));


function CoursePublishPopup(props) {
  const classes = useStyles();
  const open = props.openSubmitReview
  const [inProgress, setInprogress] = React.useState(false)

  const handleClose = () => {
    props.closeSubmitReviewPopup();
  };

  const successCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_COURSE_SUBMIT_REVIEW,
        type: 'success'
      }
    )
    handleClose()
    props.history.push('/mycourse')
  }

  const failureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_COURSE_SUBMIT_REVIEW,
        type: 'error'
      }
    )
  }
  const publishCourseonClick = () => {
      const payload = {
        course_id: props.match.params.courseId ? props.match.params.courseId : null,
        review_status: 'pending'
      }
      submitForReview(payload, successCallBack, failureCallBack)
    }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{" Are you sure you want to submit for review?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This course will be submitted for the review. myAthina team will get back to you with the results within 3-4 business days. Thank you!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { inProgress ? (
            <CircularProgress size={30} className={classes.buttonProgress} />
          ): null }
          <Button onClick={publishCourseonClick} disabled={inProgress} color="primary" variant="contained">
            Confirm
          </Button>
          <Button onClick={handleClose} disabled={inProgress} color="primary" variant="outlined" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(CoursePublishPopup);

import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';

let authorInfo = [
    {
        'id': 1,
        'firstName': 'Francis',
        'lastName': 'Martin',
        'email': 'francis@athina.com',
        'profileImage': 'https://raw.githubusercontent.com/mui-org/material-ui/master/docs/public/static/images/avatar/2.jpg',
        'desc': 'Francis Martin serves as counsel to Bosnia and Herzegovina and to the Provisional Government of the Palestinian Authority. He also represents two associations of citizens within Bosnia and was involved in developing the indictment against Slobodan Milosević for genocide, crimes against humanity, and war crimes in Bosnia and Herzegovina. Over his career, he has represented national and international bodies including the Blackfoot Nation (Canada), the Nation of Hawaii, and the Lakota Nation, as well as numerous individual death penalty and human rights cases. He has advised numerous international bodies in the areas of human rights, war crimes and genocide, nuclear policy, and bio-warfare. From 1991-92, Boyle served as Legal Advisor to the Palestinian Delegation to the Middle East Peace Negotiations.'
    },
    {
        'id': 2,
        'firstName': 'Mark',
        'lastName': 'Twain',
        'email': 'mark@athina.com',
        'profileImage': '',
        'desc': 'Mark Twain serves as counsel to Bosnia and Herzegovina and to the Provisional Government of the Palestinian Authority. He also represents two associations of citizens within Bosnia and was involved in developing the indictment against Slobodan Milosević for genocide, crimes against humanity, and war crimes in Bosnia and Herzegovina. Over his career, he has represented national and international bodies including the Blackfoot Nation (Canada), the Nation of Hawaii, and the Lakota Nation, as well as numerous individual death penalty and human rights cases. He has advised numerous international bodies in the areas of human rights, war crimes and genocide, nuclear policy, and bio-warfare. From 1991-92, Boyle served as Legal Advisor to the Palestinian Delegation to the Middle East Peace Negotiations.'
    },
    {
        'id': 3,
        'firstName': 'Steve',
        'lastName': 'McGarret',
        'email': 'steve@athina.com',
        'profileImage': '',
        'desc': 'Steve McGarret serves as counsel to Bosnia and Herzegovina and to the Provisional Government of the Palestinian Authority. He also represents two associations of citizens within Bosnia and was involved in developing the indictment against Slobodan Milosević for genocide, crimes against humanity, and war crimes in Bosnia and Herzegovina. Over his career, he has represented national and international bodies including the Blackfoot Nation (Canada), the Nation of Hawaii, and the Lakota Nation, as well as numerous individual death penalty and human rights cases. He has advised numerous international bodies in the areas of human rights, war crimes and genocide, nuclear policy, and bio-warfare. From 1991-92, Boyle served as Legal Advisor to the Palestinian Delegation to the Middle East Peace Negotiations.'
    },
    {
        'id': 4,
        'firstName': 'Cathy',
        'lastName': 'Pope',
        'email': 'francis@athina.com',
        'profileImage': 'https://raw.githubusercontent.com/mui-org/material-ui/master/docs/public/static/images/avatar/3.jpg',
        'desc': 'Cathy Pope serves as counsel to Bosnia and Herzegovina and to the Provisional Government of the Palestinian Authority. He also represents two associations of citizens within Bosnia and was involved in developing the indictment against Slobodan Milosević for genocide, crimes against humanity, and war crimes in Bosnia and Herzegovina. Over his career, he has represented national and international bodies including the Blackfoot Nation (Canada), the Nation of Hawaii, and the Lakota Nation, as well as numerous individual death penalty and human rights cases. He has advised numerous international bodies in the areas of human rights, war crimes and genocide, nuclear policy, and bio-warfare. From 1991-92, Boyle served as Legal Advisor to the Palestinian Delegation to the Middle East Peace Negotiations.'
    },
    {
        'id': 5,
        'firstName': 'Ray',
        'lastName': 'Green',
        'email': 'mark@athina.com',
        'profileImage': '',
        'desc': 'Ray Green serves as counsel to Bosnia and Herzegovina and to the Provisional Government of the Palestinian Authority. He also represents two associations of citizens within Bosnia and was involved in developing the indictment against Slobodan Milosević for genocide, crimes against humanity, and war crimes in Bosnia and Herzegovina. Over his career, he has represented national and international bodies including the Blackfoot Nation (Canada), the Nation of Hawaii, and the Lakota Nation, as well as numerous individual death penalty and human rights cases. He has advised numerous international bodies in the areas of human rights, war crimes and genocide, nuclear policy, and bio-warfare. From 1991-92, Boyle served as Legal Advisor to the Palestinian Delegation to the Middle East Peace Negotiations.'
    },
    {
        'id': 6,
        'firstName': 'Paul',
        'lastName': 'John',
        'email': 'steve@athina.com',
        'profileImage': '',
        'desc': 'Paul John serves as counsel to Bosnia and Herzegovina and to the Provisional Government of the Palestinian Authority. He also represents two associations of citizens within Bosnia and was involved in developing the indictment against Slobodan Milosević for genocide, crimes against humanity, and war crimes in Bosnia and Herzegovina. Over his career, he has represented national and international bodies including the Blackfoot Nation (Canada), the Nation of Hawaii, and the Lakota Nation, as well as numerous individual death penalty and human rights cases. He has advised numerous international bodies in the areas of human rights, war crimes and genocide, nuclear policy, and bio-warfare. From 1991-92, Boyle served as Legal Advisor to the Palestinian Delegation to the Middle East Peace Negotiations.'
    },
]


export const fetchAuthorDetails = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.authorInfo.fetchAuthors}`
    axios.get(url, {
        params: payLoad
    })
        .then(function (response) {
            //successCallBack(response.data)
        })
        .catch(function (error) {
            //failureCallBack(error)
            //successCallBack(authorInfo)
        })
    successCallBack(authorInfo)

}

//FOR POSTING COMMENT TO A RESPONSE
export const assignAutorsToCourse = (payLoad, successCallBack, failureCallBack) => {
    const url = `${apiConfig.authorInfo.assignAuthorsToCourse}`
    axios.put(url, payLoad)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}


//FOR FETCHING SINGLE COURSE INSTRUCTORS LIST
export const fetchCourseContributors = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.authorInfo.fetchCourseContributors}`
    axios.get(url, {
        params: payload
    })
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

//FOR POSTING THE USERS INFO WHO ARE WORKING ON COURSE
export const postCourseWorkingUsersInfo = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.authorInfo.postCourseWorkingUsersInfo}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response.data)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

//FOR FETCHING THE USERS WHO ARE WORKING ON COURSE
export const fetchCourseWorkingUsersInfo = (payload, successCallBack, failureCallBack, typeClickedId, typeClick) => {
    const url = `${apiConfig.authorInfo.fetchCourseWorkingUsersInfo}`
    axios.get(url, {
        params: payload
    })
        .then(function (response) {
            if (typeClickedId === '' || typeClick === '') {
                successCallBack(response.data)
            } else {
                successCallBack(response.data, typeClickedId, typeClick)
            }
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

//FOR DELETING THE USER RECORD ONCE WORK IN DONE BY THE AUTHOR
export const deleteCourseWorkingUserInfo = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.authorInfo.deleteCourseWorkingUserInfo}`
    axios.delete(url, payload)
        .then(function (response) {
            successCallBack(response)
        })
        .catch(function (error) {
            failureCallBack(error)
        })
}

//FOR NOT SHOWING THE AUTHORS WARNING POPUP AGAIN AND AGAIN
export const showMultiAuthorWarnPopup = (payload, successCallBack, failureCallBack) => {
    const url = `${apiConfig.authorInfo.showMultiAuthorWarnPopup}`
    axios.post(url, payload)
        .then(function (response) {
            successCallBack(response)
        })
        .catch(function (error) {
            //failureCallBack(error)
            successCallBack()
        })
}
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupIcon from '@material-ui/icons/Group';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import myAthinaLogo from '../../static/myAthinaLogoWhite.png';
import { colorStyles } from "../../Styles/theme";
import { redirectTo, getDomainInfo, getUserDetails, getUSerType, openFreshworksWidget } from "../../Helpers/basic";
import { fetchHeaderNoticeCount } from "../../store/NoticeBoard/actionCreator";
import { deleteCourseWorkingUserInfo } from '../../store/Authors/actionCreator';
import { getCourseOpenModeType } from '../../Helpers/basic';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#FFF",
    color: "#FFF",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: "#6bc787",
    color: "#FFF",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  noticeIcon: {
    color: '#fff',
    fontSize: '27px'
  },
  badgeCount: {
    color: '#fff',
    fontSize: '16px'
  },
  popover: {
    pointerEvents: 'none',
    marginTop: '1rem'
  },
  paper: {
    padding: theme.spacing(1),
  },
  userName: {
    color: colorStyles.appTextColor,
    fontWeight: 400,
    fontSize: "16px",
    margin: '0.5rem 1rem'
  },
  badgeValue: {
    background: colorStyles.highlight,
    borderRadius: "20px",
    fontWeight: 700,
    color: colorStyles.btnText,
    padding: "3px 5px"
  },
  badgeValueUp: {
    background: colorStyles.highlight,
    borderRadius: "20px",
    fontWeight: 700,
    color: colorStyles.btnText,
    padding: "7px 5px"
  },
  userIcon: {
    color: '#fff',
    fontSize: '30px'
  },
  appLogo: {
    height: "60px"
  },
  appLogoMob: {
    height: "45px",
    width: "130px"
  },
  rmblLogo: {
    width: "100px",
    height: "65px",
    marginRight: '20px'
  },
  rmblLogoMob: {
    width: "70px",
    height: "50px",
    marginRight: '10px'
  },
  freoLogo: {
    width: "90px"
  },
  freoLogoMob: {
    width: "70px",
    marginRight: '10px'
  },
  footerMain: {
    flexGrow: 1,
    backgroundColor: '#fcfcfc',
    position: 'fixed',
    bottom: 0,
    marginLeft: 50
  },
  pageFooter: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'center'
  }
}));

function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = React.useState(false);
  const cookieDetails = getDomainInfo();
  const userDetails = getUserDetails();
  const userName = userDetails && userDetails.firstName ? userDetails.firstName : userDetails.first_name ? userDetails.first_name : null;
  const userType = getUSerType();
  const [badgeCount, setBadgeCount] = React.useState(0);
  const [noticeCountInprogress, setNoticeCountInprogress] = useState(true);
  const courseOpenedMode = getCourseOpenModeType();

  //FOR SETTING UP THE PROFILE MENU STATES
  const [openProfileMenu, setOpenProfileMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  //FOR OPENING PROFILE MENU
  const handleProfileMenuToggle = () => {
    setOpenProfileMenu(prevOpen => !prevOpen)
  };
  //FOR CLOSING PROFILE MENU
  const handleProfileMenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenProfileMenu(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProfileMenu(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openProfileMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openProfileMenu === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = openProfileMenu;
  }, [openProfileMenu]);

  const fetchHeaderNoticeCountSuccessCallBack = res => {
    setBadgeCount(res.unread)
    setNoticeCountInprogress(false)
  }
  const fetchHeaderNoticeCountFailureCallBack = error => {
    setBadgeCount(0)
    setNoticeCountInprogress(false)
  }
  useEffect(() => {
    if (cookieDetails.noticeBoardFeature) {
      setNoticeCountInprogress(true)
      fetchHeaderNoticeCount({}, fetchHeaderNoticeCountSuccessCallBack, fetchHeaderNoticeCountFailureCallBack)
    }
    if (window.FreshworksWidget) {
      window.FreshworksWidget('hide')
    }
  }, [])

  const getMenuList = [
    {
      id: 1,
      menu: 'Dashboard',
      icon: <DashboardIcon />,
      path: '/app',
      enableView: false
    },
    {
      id: 2,
      menu: "My Courses",
      icon: <LibraryBooksIcon />,
      path: "/mycourse",
      enableView: true
    },
    {
      id: 3,
      menu: "Add Courses",
      icon: <LibraryAddIcon />,
      path: `/initiatecourse`,
      enableView: true
    },
    {
      id: 10,
      menu: "Manage Groups",
      icon: <GroupIcon />,
      path: '/managegroups',
      enableView: cookieDetails.name === 'rmbl' && userType === 'Instructor'
    },
    {
      id: 11,
      menu: "Discussions",
      icon: <QuestionAnswerIcon />,
      path: '/externaldiscussions',
      enableView: cookieDetails.name === 'rmbl'
    },
    {
      id: 9,
      menu: "Authors",
      icon: <LocalLibraryIcon />,
      path: "/authors",
      enableView: false
    },
    {
      id: 4,
      menu: "My Schedule",
      icon: <ScheduleIcon />,
      path: "/meetings",
      enableView: cookieDetails.client === "sandboxacademy" || cookieDetails.client === "rmbl" || cookieDetails.client === "caseys"
    },
    {
      id: 7,
      menu: "Manage Assignments",
      icon: <AssignmentIcon />,
      path: "/externalassignments",
      enableView: cookieDetails.showExternalAssessments
    },
    {
      id: 8,
      menu: "Manage Assessments",
      icon: <AssignmentTurnedInIcon />,
      path: "/externalassessments",
      enableView: cookieDetails.showExternalAssessments
    },
    {
      id: 5,
      menu: "My Videos",
      icon: <OndemandVideoIcon />,
      path: '/myvideos',
      enableView: false
    },
    {
      id: 11,
      menu: "Notice Board",
      icon: <NotificationsIcon />,
      path: '/noticeboard',
      enableView: cookieDetails.noticeBoardFeature && (userType === 'Instructor' || userType === 'Mentor')
    },
    {
      id: 6,
      menu: "My Classes",
      icon: <VideoLibraryIcon />,
      path: '/myclasses',
      enableView: cookieDetails.client === "rmbl"
    },
    {
      id: 7,
      menu: cookieDetails.type === 'b2b' && cookieDetails.hasMyAthinaCorpAcc ? "Back to MyAthina" : "Logout",
      icon: <ExitToAppIcon />,
      enableView: true
    },
  ];

  const handleDrawerToggle = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const successCallLogout = res => {
    document.cookie =
      cookieDetails.domainTokenLabel +
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" +
      cookieDetails.domainName;
    document.cookie =
      cookieDetails.domainUserLabel +
      "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" +
      cookieDetails.domainName;
    if (cookieDetails.type === "b2c") {
      props.history.push("/");
    } else {
      redirectTo(cookieDetails.hostName);
    }
  }
  const failureCallLogout = err => { }

  const onMenuClick = (menu) => {
    if (menu.menu === "Logout" || menu === 'Logout') {
      if (courseOpenedMode !== 'visit_mode' && cookieDetails.name === 'rmbl') {
        deleteCourseWorkingUserInfo(
          () => { },
          successCallLogout,
          failureCallLogout
        )
      } else {
        document.cookie =
          cookieDetails.domainTokenLabel +
          "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" +
          cookieDetails.domainName;
        document.cookie =
          cookieDetails.domainUserLabel +
          "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=" +
          cookieDetails.domainName;
        if (cookieDetails.type === "b2c") {
          props.history.push("/");
        } else {
          redirectTo(cookieDetails.hostName);
        }
      }
    } else if (menu.menu === "Back to MyAthina" || menu === 'Back to MyAthina') {
      window.close();
    }
    else {
      props.history.push(menu.path);
      if (courseOpenedMode !== 'visit_mode' && cookieDetails.name === 'rmbl') {
        deleteCourseWorkingUserInfo(
          () => { },
          () => { },
          () => { }
        )
      }
    }
  };

  const onNotificationsClick = () => {
    props.history.push('/noticeboard')
    if (courseOpenedMode !== 'visit_mode' && cookieDetails.name === 'rmbl') {
      deleteCourseWorkingUserInfo(
        () => { },
        () => { },
        () => { }
      )
    }
  }
  //FOR PROFILE MEU CLICK
  const onProfileMenuClick = () => {
    props.history.push('/userprofile')
    if (courseOpenedMode !== 'visit_mode' && cookieDetails.name === 'rmbl') {
      deleteCourseWorkingUserInfo(
        () => { },
        () => { },
        () => { }
      )
    }
  }

  const renderBadgeContent = () => {
    return badgeCount > 0 ? (
      <div className={badgeCount > 10 ? classes.badgeValueUp : classes.badgeValue}>{badgeCount}</div>
    ) : null
  }

  //for freshdesk help
  const openFeedBack = (type) => {
    openFreshworksWidget(type)
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "#6bc787" }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            id="leftMenu"
            color="inherit"
            style={{ padding: "10px" }}
            aria-label="open Left Menu"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {cookieDetails.type === "b2b" ? (
            <React.Fragment>
              {(cookieDetails.client === "ce" || cookieDetails.client === "sandbox" || cookieDetails.client === "demo") ? (
                <img
                  src={cookieDetails.logo}
                  style={{ width: "70px", marginRight: "33px" }}
                  alt={`${cookieDetails.client} logo`}
                />
              ) : null}
              {cookieDetails.client === "ansr" || cookieDetails.client === "ansrsource" ? (
                <img
                  src={cookieDetails.logo}
                  style={{ width: "143px", marginRight: "33px" }}
                  alt={`${cookieDetails.client} logo`}
                />
              ) : null}
              {cookieDetails.client === "abcuniversity" ? (
                <img
                  src={cookieDetails.logo}
                  style={{ width: "90px", marginRight: "33px" }}
                  alt={`${cookieDetails.client} logo`}
                />
              ) : null}
              {cookieDetails.client === "zopsmart" ? (
                <img
                  src={cookieDetails.logo}
                  style={{ width: "150px", marginRight: "33px", backgroundColor: '#000', padding: 10 }}
                  alt={`${cookieDetails.client} logo`}
                />
              ) : null}
              {cookieDetails.client === "rmbl" || cookieDetails.client === 'caseys' ? (
                <img
                  src={cookieDetails.logo}
                  className={mobileView ? classes.rmblLogoMob : classes.rmblLogo}
                  alt={`${cookieDetails.client} logo`}
                />
              ) : null}
              {cookieDetails.client === "freo" ? (
                <img
                  src={cookieDetails.logo}
                  className={mobileView ? classes.freoLogoMob : classes.freoLogo}
                  alt={`${cookieDetails.client} logo`}
                />
              ) : null}
            </React.Fragment>
          ) : null}
          <Grid container spacing={1}>
            {!open && (
              <img
                className={mobileView ? classes.appLogoMob : classes.appLogo}
                src={myAthinaLogo}
                alt="myathina logo"
              />
            )}
            {cookieDetails.noticeBoardFeature ? (
              <React.Fragment>
                <div className={classes.grow} />
                <IconButton onClick={onNotificationsClick} aria-label={`Notifications${badgeCount}`} component="span">
                  <Tooltip title="Notifications panel">
                    <Badge
                      className={classes.badgeCount}
                      badgeContent={renderBadgeContent()}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <NotificationsIcon className={classes.noticeIcon} />
                    </Badge>
                  </Tooltip>
                </IconButton>
              </React.Fragment>
            ) : null}
            {userDetails && (userDetails.first_name || userDetails.firstName) ? (
              <React.Fragment>
                {!cookieDetails.noticeBoardFeature ? (
                  <div className={classes.grow} />
                ) : null}
                <div>
                  <Button
                    ref={anchorRef}
                    aria-controls={openProfileMenu ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleProfileMenuToggle}
                    style={{ textTransform: 'none', color: '#fff', fontSize: '18px', marginTop: '8px' }}
                  >
                    {`Hi ${userName} !`}
                  </Button>
                  <Popper open={openProfileMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleProfileMenuClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem onClick={() => { onProfileMenuClick() }} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                Profile
                              </MenuItem>
                              {cookieDetails.type === 'b2b' && cookieDetails.hasMyAthinaCorpAcc ? (
                                <MenuItem onClick={() => { onMenuClick('Back to MyAthina') }} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                  Logout
                                </MenuItem>
                              ) : (
                                <MenuItem onClick={() => { onMenuClick('Logout') }} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                  Logout
                                </MenuItem>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </React.Fragment>
            ) : null}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={(event) => {
          handleDrawerToggle(event);
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <img
            style={{ height: "43px", marginRight: "-8px", borderRadius: "5px" }}
            src={myAthinaLogo}
            alt="myathina"
          />
          <IconButton
            aria-label="close left menu"
            onClick={(event) => {
              handleDrawerToggle(event);
            }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon style={{ color: "#fff" }} />
            ) : (
              <ChevronRightIcon style={{ color: "#fff" }} />
            )}
          </IconButton>
        </div>
        <Divider aria-hidden="true" />
        <List>
          {getMenuList.map((data, index) => {
            return (
              <React.Fragment key={index}>
                {data.enableView ? (
                  <ListItem
                    button
                    onClick={() => {
                      onMenuClick(data);
                    }}
                  >
                    <ListItemIcon style={{ color: "#2e8eec", minWidth: 36 }}>
                      {data.icon}
                    </ListItemIcon>
                    <ListItemText primary={data.menu} />
                  </ListItem>
                ) : null}
              </React.Fragment>
            )
          })}
        </List>
        {cookieDetails.client === "rmbl" ? (
          <div className={classes.footerMain}>
            <footer className={classes.pageFooter}>
              <Link component="button" color="inherit" onClick={() => { openFeedBack('feedback') }} style={{ color: 'blue', fontSize: '15px', marginBottom: '10px' }}>
                Feedback
              </Link>
            </footer>
          </div>
        ) : null}
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Header);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { fetchAssessment, fetchAssessmentReport, fetchAssRptTabCount } from '../../store/Assessments/actionCreator'
import { ERROR_FETCHING_ASSIGNMENT, ERROR_FETCH_ASSESSMENT_REPORT } from '../Common/Constants/errorMessages';
import TabView from './tabView'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    flexGrow: 1,
    marginTop: '10px'
  },
  breadCrumbcontainer: {
    padding: '15px',
    marginLeft: '10px',
    justifyContent: "center",
    marginTop: '-19px'
  }, 
  textWrapper: {
    fontWeight: 500,
    color: '#2e8eec',
    fontSize: '19px',
    textAlign: 'center'
  },
  dueDateWrapper: {
    color: '#2e8eec',
    textAlign: 'center',
    marginTop: '-2px',
    fontSize: '16px',
    marginBottom: '13px',
    fontWeight: 500
  },
}))

function AssessmentReport(props) {
  const classes = useStyles();
  const mediaWidth = useMediaQuery('(min-width:700px)')
  let rowsPerPage = 10
  let offset = 0
  const [page, setPage] = React.useState(0)
  const [paginationLoader, setPaginationLoader] = React.useState(false)
  const [hidePagination, setHidePagination] = React.useState(false)
  const [inProgress, setInprogress] = React.useState(false)
  const [selectedTab, setSelectedTab] = React.useState('')
  const [assessmentDetails, setAssessmentDetails] = React.useState({})
  const [assessmentReport, setAssessmentReport] = React.useState([])
  const [totalCount, setTotalCount] = React.useState(0)
  const [tabCount, setTabCount] = React.useState({
    pending: 0,
    completed: 0
  })

  const assessmentSuccessCB = res => {
    setAssessmentDetails(res.results)
  }
  const assessmentFailureCB = error => {
    setAssessmentDetails({})
    props.showSnackBar(
        {
          state: false,
          message: ERROR_FETCHING_ASSIGNMENT,
          type: 'error'
        }
    )
  }

  //Report call backs
  const successReportFetch = res => {
    setAssessmentReport(res && res.results && res.results)
    setTotalCount(res && res.totalCount)
    setPaginationLoader(false)
    setHidePagination(false)
    setInprogress(false)
  }
  const failureReportFetch = error => {
    setAssessmentReport([])
    setTotalCount(0)
    setPaginationLoader(false)
    setHidePagination(false)
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_ASSESSMENT_REPORT,
        type: 'error'
      }
    )
  }

  const getReport = () => {
    setPaginationLoader(true)
    setHidePagination(true)
    setInprogress(true)
    const payload = {
      assessment_id: props.match.params.assessmentId,
      type: 'pending',
      offset: offset,
      number: 10
    }
    fetchAssessmentReport(payload, successReportFetch, failureReportFetch)
  }

  const assessmentTabSuccess = res => {
    setTabCount({
      pending: res && res.pending,
      completed: res && res.completed
    })
  }

  const assessmentTabFailure = error => {
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_ASSESSMENT_REPORT,
        type: 'error'
      }
    )
  }

  useEffect(() => {
    const payload = {
      assessment_id: props.match.params.assessmentId
    }
    fetchAssessment(payload, assessmentSuccessCB, assessmentFailureCB)
    fetchAssRptTabCount(payload, assessmentTabSuccess, assessmentTabFailure)
    getReport()
  },[])

  const getBreadCrumbs = () => {
    return(
      <Route>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link style={{ textDecoration: 'none'}} color="inherit" to="/mycourse">
            Home
          </Link>
          <Link style={{ textDecoration: 'none'}} color="inherit" to="/externalassessments">
            {assessmentDetails && assessmentDetails.title}
          </Link>
          <Typography color="textPrimary">Assessment Report</Typography>
        </Breadcrumbs>
      </Route>
    )
  }

  const onTabChange = tabKey => {
    setPage(0)
    setAssessmentReport([])
    setPaginationLoader(true)
    setHidePagination(true)
    setSelectedTab(tabKey)
    const payload = {
      assessment_id: props.match.params.assessmentId,
      type: tabKey === 0 ? 'pending' : 'completed',
      offset: 0,
      number: 10
    }
    fetchAssessmentReport(payload, successReportFetch, failureReportFetch)
  }

  const onPagination = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    setPaginationLoader(true)
    setHidePagination(true)
    const payload = {
      assessment_id: props.match.params.assessmentId,
      type: selectedTab === 1 ? 'completed' : 'pending',
      offset: offset,
      number: number
    }
    fetchAssessmentReport(payload, successReportFetch, failureReportFetch)
  }

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 3; i++) {
     children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader/></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
   }

  return(
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Assessment report page</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={0} className={classes.topButtonNav}>
            <div className={classes.breadCrumbcontainer}>
              {getBreadCrumbs()}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '20px', paddingLeft: !mediaWidth ? '10px' : '200px', paddingRight: !mediaWidth ? '10px' : '200px' }}>  
          { inProgress ? (
            listLoader()
          ) :(
            <React.Fragment>
              <Typography className={classes.textWrapper}>{ assessmentDetails && assessmentDetails.title ? assessmentDetails.title : '' }</Typography>
              <Typography className={classes.dueDateWrapper}>{assessmentDetails && assessmentDetails.test_type ? `Test type: ${assessmentDetails.test_type}` : null } {assessmentDetails && assessmentDetails.percent ? `| ${assessmentDetails.percent}%` : null }</Typography>
              <TabView
                histoy={props.history}
                match={props.match}
                onTabChange={onTabChange}
                onPagination={onPagination}
                paginationLoader={paginationLoader} 
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={totalCount}
                assessmentReportData={assessmentReport}
                hidePagination={hidePagination}
                tabCount={tabCount}
              />
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(AssessmentReport);
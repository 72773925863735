import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoginForm from './login';
import { getDomainInfo, getLoggedInStatus, redirectTo, getUserDetails } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  myAthinaLogo: {
    width: '180px',
    verticalAlign: 'top',
    marginTop: '23px',
    marginLeft: 10
  },
  logoImg: {
    width: '44px',
    marginTop: '25px'
  },
  buttonProgress: {
    marginBottom: '10px',
    marginRight: '10px'
  },
  submit: {
    marginTop: '10px'
  }
}));
function Login(props) {
  const classes = useStyles();
  const [renderLogin, setRenderLogin] = useState(false);

  useEffect(() => {
    const loggedInStatus = getLoggedInStatus();
    const domainDet = getDomainInfo();
    if (loggedInStatus === true) {
      let userDetails = getUserDetails();
      if (domainDet.type === 'b2b') {
        if (Object.keys(userDetails).length > 0 && userDetails.roles.length > 0 && (userDetails.roles.includes('Author') || userDetails.roles.includes('Mentor') || userDetails.roles.includes('Instructor') || userDetails.roles.includes('Admin'))) {

          if (domainDet.client === 'rmbl') {
            props.history.push('/noticeboard')
          } else {
            props.history.push('/mycourse')
          }
        }
        else if (userDetails.roles.length > 0 && userDetails.roles.includes('Student')) {
          if (domainDet.client === 'rmbl') {
            window.location = `${domainDet.renderingURL}/noticeboard`
            return
          } else {
            window.location = `${domainDet.renderingURL}/profile`
            return
          }
        }
      } else if (domainDet.type === 'b2c') {
        let url = `${domainDet.hostName}/?doSignIn=true&navigateTo=hosting`;
        redirectTo(url, false);
      }

    } else {
      setRenderLogin(true)
    }
    window.FreshworksWidget('hide')
  }, [])


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {renderLogin ?
          (
            <LoginForm history={props.history} />
          ) : null}
      </div>
    </Container>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(Login);
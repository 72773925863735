import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import moment from "moment";
import GetAppIcon from '@material-ui/icons/GetApp';
import Divider from '@material-ui/core/Divider';
import { CSVLink } from 'react-csv';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  tabPanelWrapper: {
    marginTop: 10
  },
  tableWrapper: {
    paddingTop: '8px',
    backgroundColor: '#f5f5f5'
  },
  tableHeadingCell: {
    padding: 6,
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#777'
  },
  tableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  exportButton: {
    float: 'right',
    marginTop: '-33px',
    marginBottom: '10px'
  },
  dialogPopup: {
    width: '80%',
    maxHeight: 435,
  },
  button: {
    flexGrow: 1,
    float: 'right',
    marginBottom: '15px',
    marginRight: '38px',
    backgroundColor: '#2e8eec',
    textTransform: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2e8eec',
    },
  },
}));

function ParticipantsPopup(props) {
  const classes = useStyles();
  const openDialog = props && props.eventData && props.eventData.dialogPopup
  const eventData = props && props.eventData && props.eventData.slotData
  const [loader, setLoader] = useState(false);
  const [exportData, setExportData] = useState([]);

  const exportCSV = () => {
    setLoader(true);
    let data = eventData
    let downloadData = []
    if (eventData && eventData.participants && eventData.participants) {
      for (let i in eventData.participants) {
        let obj = data.participants[i]
        obj.eventTitle = props.eventTitle && props.eventTitle.length ? props.eventTitle : 'NA'
        obj.name = obj.name ? obj.name : 'NA'
        obj.email = obj.email ? obj.email : 'NA'
        obj.slot = eventData && eventData.event_starttime ? eventData.event_starttime : 'NA'
        downloadData.push(obj)
      }
    }
    setExportData(downloadData)
    setTimeout(() => {
      document.getElementById('exportReport').click()
      setLoader(false)
    }, 1000)
  };


  const csvHeaders = [
    { label: "Training", key: "eventTitle" },
    { label: "Employee Name", key: "name" },
    { label: "Email ID", key: "email" },
    { label: "Training Slot Time", key: "slot" },
  ]

  return (
    <Dialog open={openDialog} maxWidth={'md'} fullWidth onClose={props.close} aria-labelledby="Training documents status">
      <DialogTitle style={{ color: '#2e8eec' }}>
        Training Slot Participants Information
        <Tooltip title="Export as CSV">
          <Button
            variant="contained"
            size="small"
            disabled={loader}
            className={classes.button}
            startIcon={<GetAppIcon />}
            onClick={() => { exportCSV() }}
          >
            <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd' }} aria-hidden="true" />
            Report
          </Button>
        </Tooltip>
        {loader ? (
          <CircularProgress size={23} style={{ float: 'right', marginRight: '10px', marginTop: '5px', color: '#2e8eec' }} />
        ) : null}
        <CSVLink data={exportData} headers={csvHeaders} id="exportReport" style={{ display: 'none' }} filename={`${props.eventTitle}.csv`} aria-hidden="true">
          <GetAppIcon />
        </CSVLink>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TableContainer style={{ maxHeight: '300px' }}>
            <Table stickyHeader aria-label="Event Participants List">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>Name</TableCell>
                  <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>E-mail</TableCell>
                  <TableCell align="center" style={{ fontWeight: '700', fontSize: '1rem', color: '#2E8EEC' }}>Slot Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventData && eventData.participants.length ? (
                  <React.Fragment>
                    {eventData.participants && eventData.participants.length && eventData.participants.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row" align="center">
                          {row.name && row.name.length && row.name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.email && row.email.length && row.email}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {moment(eventData.event_starttime).format("DD-MMM-YYYY hh:mm a")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} component="th" scope="row" align="center">
                      No records found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, {}
)(ParticipantsPopup);
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-skeleton-loader';
import { makeStyles } from '@material-ui/core/styles';
import { style } from '../../../Styles/theme';
const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
  },
  txtWrapper: {
    height: '26px',
    width: '148px',
    marginBottom: '11px'
  },
}))

export default function ChipLoader(props) {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} xm={12} md={3} lg={3}>
          <div className={classes.wrapper}>
            <div className={classes.txtWrapper}>
              <Skeleton widthRandomness={0} count={1} width="72%" color={style._chipLoader} borderRadius="19px" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
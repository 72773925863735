import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom';
import { Route } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  courseInfo: {
    color: '#333',
    backgroundColor: '#cccccc59',
    padding: 21,
    borderBottom: '1px solid #ccc'
  },
}))
function Header(props) {
  const courseData = props.courseData
  const classes = useStyles();
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  return (
    <div>
      <div className={classes.courseInfo}>
        <Route>
          {
            (
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="Breadcrumb">
                <Link style={{ textDecoration: 'none'}} color="inherit" to="/mycourse">
                  Home
                </Link>
                { base64EncodeValue === 'ZnJvbV9teWNvdXJzZXM=' ? (
                  <div color="textPrimary">
                    {courseData && courseData.title ? `Course: ${courseData.title}` : 'NA'}
                  </div>
                ) : (
                  <Link style={{ textDecoration: 'none'}} color="inherit" to={`/mycourse/${courseData && courseData.id}`}>
                    {courseData && courseData.title ? `Course: ${courseData.title}` : 'NA'}
                  </Link>
                ) }
                <div color="textPrimary">
                  Gradebook
                </div>
              </Breadcrumbs>
            )
          }
        </Route>
      </div>
    </div>
)
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(Header);

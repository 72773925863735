import axios from "axios";
import moment from "moment";
import { apiConfig } from "../../Configs/apiConfig";


// const mockEvents = {
//   "data": [
//     {
//       "id": 532,
//       "author": 1,
//       "course": null,
//       "meeting_type_display_name": "Instructor Led Meeting",
//       "event_host": 2,
//       "assigned_to": [
//         {
//           "id": 1,
//           "group_title": "Store",
//           "members_count": 10,
//           "group_members": []
//         },
//         {
//           "id": 2,
//           "group_title": "Store Employees",
//           "members_count": 20,
//           "group_members": []
//         }
//       ],
//       "is_host": true,
//       "host_details": {
//         "name": "Laukik Sarode",
//         "email": "laukik.sarode@continualengine.com"
//       },
//       //"event_labels": [],
//       //"created_date": "2021-07-03T02:30:00Z",
//       //"modified_date": "2021-07-03T02:30:00Z",
//       //"is_active": true,
//       // "start_time": "2021-07-02T02:30:00Z",
//       // "end_time": "2021-07-02T03:00:00Z",
//       //"duration": 30,
//       "topic": "Instructor Led Meeting",
//       "meeting_type": 10,
//       "agenda": "Instructor Led Meeting for store employees",
//       //"meeting_uuid": "zdUo4P+0QbmeJSzU7yvvig==",
//       "meeting_id": "83593592501",
//       //"host_id": "CldOHuxxSdqNLfauVahGyA",
//       //"status": "waiting",
//       //"meeting_timezone": "UTC",
//       //"created_at": "2021-07-03T07:10:28.587124Z",
//       //"start_url": "https://us02web.zoom.us/s/83593592501?zak=eyJ0eXAiOiJKV1QiLCJzdiI6IjAwMDAwMSIsInptX3NrbSI6InptX28ybSIsImFsZyI6IkhTMjU2In0.eyJhdWQiOiJjbGllbnRzbSIsInVpZCI6IkNsZE9IdXh4U2RxTkxmYXVWYWhHeUEiLCJpc3MiOiJ3ZWIiLCJzdHkiOjEsIndjZCI6InVzMDIiLCJjbHQiOjAsInN0ayI6IkRpVnU1dUZaOFBfaU9wSVVRbEczZkxUWmdzQnBzc0h0VzdsTWNqdWpRSHMuQUcueWctSG1mSFZJaFpJS2tYSGU1eEhXM3BxYVRyYnhScFlEb2xrUmphNTJCRzNXV3JWdFVUU2QyMEhHc0F0a1c4TlN2SGNDMzBhWjEyc1hOQlMuMUdqVDRuZVlSYzNFN1ZHdXFGaHFKdy55OEtUX2ppQmhZZktnZ3N6IiwiZXhwIjoxNjI1MTI0MjI0LCJpYXQiOjE2MjUxMTcwMjQsImFpZCI6IkdmbnBvSkNDUU9DT0xubHA3ZEJGdFEiLCJjaWQiOiIifQ.yD2KX9f0Ke7gU4_QpmYxhfV3NDGLaBJ3HzLQjl8nPLU",
//       //"join_url": "https://us02web.zoom.us/j/83593592501?pwd=VERIQlF1aWs4OUZCSHNhVzZ5Tm9Zdz09",
//       //"password": "32L13FMJ5E",
//       //"h323_password": "5440729614",
//       //"pstn_password": "5440729614",
//       // "encrypted_password": "VERIQlF1aWs4OUZCSHNhVzZ5Tm9Zdz09",
//       //"host_video": false,
//       //"participant_video": false,
//       //"cn_meeting": false,
//       //"in_meeting": false,
//       //"join_before_host": false,
//       //"mute_upon_entry": false,
//       //"watermark": false,
//       //"use_pmi": false,
//       //"approval_type": null,
//       //"registration_type": 1,
//       //"audio": "both",
//       //"auto_recording": "none",
//       //"enforce_login": false,
//       //"enforce_login_domains": null,
//       //"alternative_hosts": null,
//       //"close_registration": false,
//       //"registrants_confirmation_email": true,
//       //"waiting_room": false,
//       //"contact_name": "Laukik Sarode",
//       //"contact_email": "laukik.sarode@continualengine.com",
//       //"registrants_email_notification": false,
//       //"meeting_authentication": false,
//       //"authentication_option": null,
//       //"authentication_name": null,
//       //"authentication_domains": null,
//       //"recurrence_type": null,
//       //"recurrence_repeat_interval": null,
//       //"weekly_days": null,
//       //"end_times": null,
//       //"attachment": null,
//       "meeting_location": [
//         "ALTON"
//       ],
//       //"participants": [],
//       "store_location": {
//         "store_id": "68",
//         "store": "ALTON",
//         "address": "1109 3RD AVE",
//         "city": "ALTON",
//         "state": "IOWA",
//         "zip_code": "51003",
//         "county": "SIOUX",
//         "latitude": "42.9889506",
//         "longitude": "-96.0090739",
//         "store_type": "C",
//         "store_status": "A",
//         "division_director": "DAVE JOHNSON",
//         "region_director": "ADAM NEEMEYER",
//         "district_supervisor": "HEIDI MARCYES",
//         "store_manager": "PAM PLATHE"
//       },
//       "slot_timings": [
//         {
//           "event_starttime": '2021-07-06T03:30:00Z',
//           "event_endtime": '2021-07-06T04:00:00Z',
//           "event_duration": 30,
//           "event_seats": 10,
//           "participants": [
//             {
//               "name": "kalyan kumar",
//               "email": "kalyan.kumar@continualengine.com"
//             },
//             {
//               "name": "gaensan d",
//               "email": "ganesan.d@continualengine.com"
//             }
//           ]
//         },
//         {
//           "event_starttime": '2021-07-06T04:30:00Z',
//           "event_endtime": '2021-07-06T05:10:00Z',
//           "event_duration": 40,
//           "event_seats": 5,
//           "participants": [
//             {
//               "name": "gaensan d",
//               "email": "ganesan.d@continualengine.com"
//             },
//             {
//               "name": "vikram",
//               "email": "vikram@continualengine.com"
//             }
//           ]
//         },
//         {
//           "event_starttime": '2021-07-06T06:30:00Z',
//           "event_endtime": '2021-07-06T07:00:00Z',
//           "event_duration": 30,
//           "event_seats": 3,
//           "participants": []
//         }
//       ]
//     }
//   ],
//   "message": "Retrieved 1 events"
// }

const mockEvents = {
  "data": [
    {
      "id": 532,
      "author": 1,
      "meeting_type_display_name": "Instructor Led Meeting",
      "assigned_to": [
        {
          "id": 1,
          "group_title": "Store",
          "members_count": 10,
          "group_members": []
        },
        {
          "id": 2,
          "group_title": "Store Employees",
          "members_count": 20,
          "group_members": []
        }
      ],
      "is_host": true,
      "host_details": {
        "name": "Laukik Sarode",
        "email": "laukik.sarode@continualengine.com"
      },
      "topic": "Instructor Led Meeting",
      "meeting_type": 10,
      "agenda": "Instructor Led Meeting for store employees",
      "meeting_id": "83593592501",
      "meeting_location": [
        "ALTON"
      ],
      "store_location": {
        "store_id": "68",
        "store": "ALTON",
        "address": "1109 3RD AVE",
        "city": "ALTON",
        "state": "IOWA",
        "zip_code": "51003",
        "county": "SIOUX",
        "latitude": "42.9889506",
        "longitude": "-96.0090739",
        "store_type": "C",
        "store_status": "A",
        "division_director": "DAVE JOHNSON",
        "region_director": "ADAM NEEMEYER",
        "district_supervisor": "HEIDI MARCYES",
        "store_manager": "PAM PLATHE"
      },
      "slot_timings": [
        {
          "event_starttime": '2021-07-07T03:30:00Z',
          "event_endtime": '2021-07-07T04:00:00Z',
          "event_duration": 30,
          "event_seats": 10,
          "participants": [
            {
              "name": "Kalyan Kumar",
              "email": "kalyan.kumar@continualengine.com"
            },
            {
              "name": "Ganesan d",
              "email": "ganesan.d@continualengine.com"
            }
          ]
        },
        {
          "event_starttime": '2021-07-07T04:30:00Z',
          "event_endtime": '2021-07-07T05:10:00Z',
          "event_duration": 40,
          "event_seats": 5,
          "participants": [
            {
              "name": "Prasanth P",
              "email": "prasanth@continualengine.com"
            },
            {
              "name": "Vikram P",
              "email": "vikram@continualengine.com"
            }
          ]
        },
        {
          "event_starttime": '2021-07-07T06:30:00Z',
          "event_endtime": '2021-07-07T07:00:00Z',
          "event_duration": 30,
          "event_seats": 3,
          "participants": []
        }
      ]
    },
  ],
  "message": "Retrieved 1 events"
}

export const listEventsCalendar = (successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.getEventList}`;
  axios
    .get(url)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};

export const getCourseList = (successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.getCourseList}`;
  axios
    .get(url)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};

export const deleteMeetingEvent = (
  meetingId,
  successCallBack,
  failureCallBack
) => {
  const url = `${apiConfig.meetings.deleteEvent}${meetingId}/`;
  axios
    .delete(url)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};

export const createMeetingEvent = (
  formData,
  successCallBack,
  failureCallBack
) => {
  const url = `${apiConfig.meetings.createEvent}`;
  axios
    .post(url, formData)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      failureCallBack(error);
    });
};

export const modifyMeetingEvent = (
  formData,
  successCallBack,
  failureCallBack
) => {
  const url = `${apiConfig.meetings.modifyEvent}`;
  axios
    .patch(url, formData)
    .then(function (response) {
      successCallBack(response.data);
    })
    .catch(function (error) {
      console.log(error)
      failureCallBack(error);
    });
};

export const fetchEventLabels = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.meetings.fetchEventLabels}`
  axios.get(url, payload)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import AssignmentReportView from './studentReportpopup';
import Button from '@material-ui/core/Button';
import { fetchStudentSubmissions } from '../../store/Assignments/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_FETCHING_STUDENT_SUBMISSIONS } from '../Common/Constants/errorMessages';
import { colorStyles } from '../../Styles/theme';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fff',
    marginTop: '20px',
    width: 'auto',
    // paddingLeft: '100px',
    // paddingRight: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerTab: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  dataCount: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: '1.1rem'
  },
  tabPanelWrapper: {
    marginTop: 10
  },
  tableWrapper: {
    paddingTop: '8px',
    backgroundColor: '#f5f5f5'
  },
  tableHeadingCell: {
    padding: 6,
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#777'
  },
  tableCell: {
    padding: 6,
    fontSize: '0.8rem',
    textAlign: 'center'
  },
  btnWrapper: {
    textTransform: 'none',
    color: 'blue'
  },
  headerTab: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  selectedTab: {
    fontSize: '1rem',
    textTransform: 'none',
    color: '#2e8eec',
    fontWeight: 500
  },
  tabCtr: {
    color: colorStyles.tabHeaderFnt
  },
}));

function TabView(props) {
  const classes = useStyles();
  const scrollView = useMediaQuery('(max-width:500px)');
  const assignmeReportDetails = props.assignmeReportDetails
  const assignmentDetails = props.assignmentDetails
  const completedUserList = props.assignmeReportDetails && props.assignmeReportDetails.completed ? props.assignmeReportDetails.completed : []
  const [openReportPopup, setOpenReportPopup] = React.useState(false)
  const [openReportEditPopup, setOpenReportEditPopup] = React.useState(false)
  const [studentSubmissionsDetails, setStudentSubmissonDetails] = React.useState({})
  const [submissionId, setSubmissionId] = React.useState('')
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = index => {
    setValue(index);
  };

  const tabObj = {
    pending: {
      label: 'Pending students',
      count: assignmeReportDetails.pending ? assignmeReportDetails.pending.length : 0,
      list: assignmeReportDetails.pending
    },
    completed: {
      label: 'Completed students',
      count: assignmeReportDetails.completed ? assignmeReportDetails.completed.length : 0,
      list: assignmeReportDetails.completed
    }
  }

  const renderTabs = () => {
    const label = (str, count) => {
      return (
        <div>
          <span className={classes.tabName}>{str}</span>
          <span className={classes.dataCount}>({count})</span>
        </div>
      )
    }
    const tabs = Object.keys(tabObj).map((key, index) => (
      <Tab className={value === index ? classes.selectedTab : classes.headerTab} label={label(tabObj[key].label, tabObj[key].count)} {...a11yProps(key)} key={key} />
    ))
    return tabs
  }

  const fetchSubmissonsSuccess = res => {
    let result = res.results
    if (!result.plag_data) {
      result.plag_data = {}
      result.plag_present = true
    }
    // setStudentSubmissonDetails(res.results)
    setStudentSubmissonDetails(result)
    setOpenReportPopup(true)
  }

  const fetchSubmissionsFailure = error => {
    setOpenReportPopup(false)
    setStudentSubmissonDetails({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_STUDENT_SUBMISSIONS,
        type: 'error'
      }
    )
  }

  const triggerReportViewPopup = (data) => {
    setSubmissionId(data.submission_id)
    const payload = {
      submission_id: data.submission_id
    }
    fetchStudentSubmissions(payload, fetchSubmissonsSuccess, fetchSubmissionsFailure)
  }

  const closeReportPopup = () => {
    setOpenReportPopup(false)
    setStudentSubmissonDetails({})
  }

  const fetchSubmissonsEditSuccess = res => {
    let result = res.results
    if (!result.plag_data) {
      result.plag_data = {}
      result.plag_present = true
    }
    // setStudentSubmissonDetails(res.results)
    setStudentSubmissonDetails(result)
    setOpenReportEditPopup(true)
  }

  const fetchSubmissionsEditFailure = error => {
    setStudentSubmissonDetails({})
    setOpenReportEditPopup(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_STUDENT_SUBMISSIONS,
        type: 'error'
      }
    )
  }

  const triggerReportEdit = (data) => {
    setSubmissionId(data.submission_id)
    const payload = {
      submission_id: data.submission_id
    }
    fetchStudentSubmissions(payload, fetchSubmissonsEditSuccess, fetchSubmissionsEditFailure)
  }

  const closeReportEditMode = () => {
    setOpenReportEditPopup(false)
    setStudentSubmissonDetails({})
  }

  const renderTabContentPanel = () => {
    const tabs = Object.keys(tabObj).map(key =>
      <TabPanel value={key} index={key} dir={theme.direction} key={key} className={classes.tabPanelWrapper}>
        {tabObj[key].count ? (
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell align="center" className={classes.tableHeadingCell}>Id</TableCell> */}
                  <TableCell align="center" className={classes.tableHeadingCell}>Name</TableCell>
                  <TableCell align="center" className={classes.tableHeadingCell}>Email ID</TableCell>
                  {assignmentDetails && assignmentDetails.points !== 0 && tabObj[key].label === 'Completed students' ? (
                    <TableCell align="center" className={classes.tableHeadingCell}>Grade points</TableCell>
                  ) : (
                    <TableCell align="center" className={classes.tableHeadingCell}>Submissions</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tabObj[key].list.map((row, reqKey) => (
                  <TableRow key={reqKey}>
                    {/* <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                      {row && row.user_id && row.user_id}
                    </TableCell> */}
                    {row.status === 'completed' ? (
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        <Link component="button" onClick={() => { triggerReportViewPopup(row) }}>{row && row.user_name && row.user_name}</Link>
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        {row && row.user_name && row.user_name}
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                      {row && row.email && row.email}
                    </TableCell>
                    {row.status === 'completed' ? (
                      <React.Fragment>
                        {assignmentDetails && assignmentDetails.points === 0 ? (
                          <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                            <Button className={classes.btnWrapper} onClick={() => { triggerReportViewPopup(row) }}>View</Button>
                          </TableCell>
                        ) : (
                          <React.Fragment>
                            {row.grade_point === null ? (
                              <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                <Button className={classes.btnWrapper} onClick={() => { triggerReportViewPopup(row) }}>Give grade</Button>
                              </TableCell>
                            ) : (
                              <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                                <Link component="button" onClick={() => { triggerReportEdit(row) }}>{`${row.grade_point ? row.grade_point : 0}`}</Link>
                              </TableCell>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {row.status === 'draft' ? (
                          <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                            Pending
                          </TableCell>
                        ) : null}
                      </React.Fragment>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <React.Fragment>
            <div>No data found</div>
          </React.Fragment>
        )}
      </TabPanel>
    )
    return tabs
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ padding: '20px' }}>
          <AppBar position="static" style={{ backgroundColor: colorStyles.tabHeaderBg }}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: '#2e8eec', height: '5px', borderRadius: '3px' } }}
              indicatorColor="primary"
              className={classes.tabCtr}
              variant={scrollView ? "scrollable" : 'fullWidth'}
              scrollButtons="auto"
              aria-label="full width tabs example"
            >
              {renderTabs()}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {renderTabContentPanel()}
          </SwipeableViews>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
      </Grid>
      {openReportPopup ?
        (
          <AssignmentReportView
            assignmentReportFetch={props.assignmentReportFetch}
            openReportPopup={openReportPopup}
            submissionId={submissionId}
            closeReportPopup={closeReportPopup}
            studentSubmissions={studentSubmissionsDetails}
            assignmentDetails={assignmentDetails}
            completedUserList={completedUserList}
            triggerReportViewPopup={triggerReportViewPopup}
          />
        ) : null}
      {openReportEditPopup ?
        (
          <AssignmentReportView
            assignmentReportFetch={props.assignmentReportFetch}
            openReportEditPopup={openReportEditPopup}
            submissionId={submissionId}
            closeReportEditMode={closeReportEditMode}
            studentSubmissions={studentSubmissionsDetails}
            assignmentDetails={assignmentDetails}
            openMode='editGrade'
            completedUserList={completedUserList}
            triggerReportEdit={triggerReportEdit}
          />
        ) : null}
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(TabView);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { find, filter } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import PlagiarismReport from './report';
import { ERROR_CHECKING_PLAGIARISM } from '../../Common/Constants/errorMessages'
import { getDomainInfo } from '../../../Helpers/basic'
import { checkPlagiarism } from '../../../store/PlagiarisimChecker/actionCreator'
import { showSnackBar } from '../../../store/AlertMessages/actionCreator';
import { style, colorStyles } from '../../../Styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  reportWrapper: {
    textAlign: 'center',
    padding: 5,
    backgroundColor: '#fcfcfc'
  },
  subReportWrapper: {
    backgroundColor: '#fcfcfc',
    display: 'inline-block',
    textAlign: 'center',
    margin: 15
  },
  drawerReportWrapper: {
    textAlign: 'center',
    marginTop: 25
  },
  selectedBtn: {
    borderBottom: '3px solid blue',
    borderRadius: 0,
    textTransform: 'none',
    width: 150,
    marginLeft: 30,
    paddingBottom: 20
  },
  defaultBtn: {
    borderBottom: '3px solid #fff',
    borderRadius: 0,
    textTransform: 'none',
    width: 150,
    marginLeft: 30,
    paddingBottom: 20,
    '&:hover': {
      borderBottom: '3px solid blue',
      backgroundColor: '#fff'
    }
  },
  appBar: {
    position: 'relative',
    height: '49px',
    backgroundColor: '#F5F5F5',
  },
  title: {
    flex: 1,
    marginTop: '-4px',
    fontWeight: '600',
    color: '#3f51b5',
    fontSize: '17px',
  },
  iconWrapper: {
    marginTop: '-18px'
  },
  dueDateWrapper: {
    color: '#616161',
    textAlign: 'initial',
    marginTop: '-2px',
    fontSize: '12px'
  },
  btnWrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: 7,
    left: 6,
    marginTop: 'auto'
  },
  buttonProgressWithNoText: {
    color: 'green',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto'
  }
}));

function PlagiarisimChecker(props) {
  const domainInfo = getDomainInfo();
  const [openDetailedReport, setOpenDetailedReport] = React.useState(false);
  const [reportType, setReportType] = React.useState('plagiarized');
  const [detailedReports, setDetailedReports] = React.useState({});
  const [inProgress, setInProgress] = React.useState(false);
  const classes = useStyles();

  const onSuccess = res => {
    setInProgress(false)
    props.onPlagiarisimSuccessCheck(res, props.contentSource)
  }
  const onFailure = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_CHECKING_PLAGIARISM,
        type: 'error'
      }
    )
  }
  const checkForPlagiarism = () => {
    setInProgress(true)
    checkPlagiarism(
      props.onSubmitData,
      onSuccess,
      onFailure
    )
  }
  useEffect(() => {
    if (props.plagiarismObj) {
      const sources = props.plagiarismObj.sources
      const details = props.plagiarismObj.details
      let plagiarizedReport = []
      let uniqueReport = filter(details, { 'unique': 'true' })
      for (let i in sources) {
        const filteredDetails = filter(details, { 'unique': 'false', 'display': { 'url': sources[i].link } })
        plagiarizedReport.push({ sources: sources[i], details: filteredDetails })
      }
      setDetailedReports({
        plagiarized: plagiarizedReport,
        unique: uniqueReport
      })
    }
  }, [props.plagiarismObj])
  const plagiarismButton = () => {
    switch (props.buttonComponent) {
      case 'eye-with-text':
        return (
          <div className={classes.btnWrapper}>
            {inProgress && <CircularProgress size={34} className={classes.buttonProgress} />}
            { domainInfo.client === 'rmbl' ? (
              <React.Fragment>
                <IconButton color="primary" aria-label="Check for Plagiarism" style={{ fontSize: '12px' }} onClick={checkForPlagiarism} disabled={true}>
                  <VisibilityIcon />&nbsp;check for plagiarism
                </IconButton>
                <div style={{ display: 'flex', marginLeft: '11px' }}>
                  <InfoOutlinedIcon style={{ fontSize: '0.7rem', color: colorStyles.appTextColor, margin: '0.2rem', marginTop: '0.3rem' }} />
                  <div style={{ fontSize: '0.8rem', color: colorStyles.appTextColor }}>Please contact myAthina team to activate the plagiarism feature.</div><VisibilityIcon style={{ fontSize: '19px', color: colorStyles.appTextColor, margin: '0.2rem', marginTop: '2px' }} />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <IconButton color="primary" aria-label="Check for Plagiarism" style={{ fontSize: '12px' }} onClick={checkForPlagiarism} disabled={inProgress}>
                  <VisibilityIcon />&nbsp;check for plagiarism
                </IconButton>
              </React.Fragment>
            )}
          </div>
        )
      case 'eye-with-no-text':
        return (
          <div className={classes.btnWrapper}>
            {inProgress && <CircularProgress size={34} className={classes.buttonProgressWithNoText} />}
            { domainInfo.client === 'rmbl' ? (
              <IconButton color="primary" aria-label="Check for Plagiarism" style={{ fontSize: '12px' }} onClick={checkForPlagiarism} disabled={true}>
                <VisibilityIcon />
              </IconButton>
            ) : (
              <React.Fragment>
                <IconButton color="primary" aria-label="Check for Plagiarism" style={{ fontSize: '12px' }} onClick={checkForPlagiarism} disabled={inProgress}>
                  <VisibilityIcon />
                </IconButton>
              </React.Fragment>
            )}
          </div>
        )
      default:
        return (
          <div className={classes.btnWrapper}>
            { domainInfo.client === 'rmbl' ? (
              <Button variant="contained" color="primary" onClick={checkForPlagiarism} disabled={true}>
                Check for Plagiarism
              </Button>
            ) : (
              <React.Fragment>
                <Button variant="contained" color="primary" onClick={checkForPlagiarism} disabled={inProgress}>
                  Check for Plagiarism
                </Button>
                {inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
              </React.Fragment>
            )}
          </div>
        )
    }
  }
  const showReport = type => {
    setReportType(type)
  }
  const toggleReport = () => {
    setOpenDetailedReport(!openDetailedReport)
  };
  const plagiarismReport = () => {
    const report = props.plagiarismObj
    return (
      <div>
        <main>
          <Paper className={classes.reportWrapper}>
            <div style={{ fontWeight: 700 }}>Plagiarism Report</div>
            <Paper elevation={0} className={classes.subReportWrapper}>
              <p>Plagiarism</p>
              <div style={{ height: '4rem', width: '4rem', margin: 'auto' }}>
                <CircularProgressbar
                  value={report.plagPercent}
                  text={`${report.plagPercent}%`}
                  styles={buildStyles({
                    textColor: "#dd2c00",
                    pathColor: "#ef9a9a",
                    textSize: "25px"
                  })}
                />
              </div>
            </Paper>
            <Paper elevation={0} className={classes.subReportWrapper}>
              <p>Unique</p>
              <div style={{ height: '4rem', width: '4rem', margin: 'auto' }}>
                <CircularProgressbar
                  value={report.uniquePercent}
                  text={`${report.uniquePercent}%`}
                  styles={buildStyles({
                    textColor: "green",
                    pathColor: "#4caf50",
                    textSize: "25px"
                  })}
                />
              </div>
            </Paper>
            <Button variant="outlined" color="primary" onClick={toggleReport}>
              View Report
            </Button>
          </Paper>
          <Drawer anchor="right" open={openDetailedReport} onClose={toggleReport}>
            <div style={{ width: `${window.screen.width}px` }}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <Typography variant="h6" className={classes.title}>
                    {props.displayInfo.assignmentName ? props.displayInfo.assignmentName : ''}
                    <p className={classes.dueDateWrapper}>
                      submitted by: {props.displayInfo.studentName ? props.displayInfo.studentName : ''}
                    </p>
                  </Typography>
                  <IconButton edge="start" className={classes.iconWrapper} onClick={toggleReport} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Paper elevation={0} className={classes.drawerReportWrapper}>
                <Button className={reportType === 'plagiarized' ? classes.selectedBtn : classes.defaultBtn} onClick={() => { showReport('plagiarized') }}>
                  <Paper elevation={0}>
                    <p>Plagiarized</p>
                    <div style={{ height: '4rem', width: '4rem', margin: 'auto' }}>
                      <CircularProgressbar
                        value={report.plagPercent}
                        text={`${report.plagPercent}%`}
                        styles={buildStyles({
                          textColor: "#dd2c00",
                          pathColor: "#ef9a9a",
                          textSize: "25px"
                        })}
                      />
                    </div>
                  </Paper>
                </Button>
                <Button className={reportType === 'unique' ? classes.selectedBtn : classes.defaultBtn} onClick={() => { showReport('unique') }}>
                  <Paper elevation={0}>
                    <p>Unique</p>
                    <div style={{ height: '4rem', width: '4rem', margin: 'auto' }}>
                      <CircularProgressbar
                        value={report.uniquePercent}
                        text={`${report.uniquePercent}%`}
                        styles={buildStyles({
                          textColor: "green",
                          pathColor: "#4caf50",
                          textSize: "25px"
                        })}
                      />
                    </div>
                  </Paper>
                </Button>
              </Paper>
              <PlagiarismReport detailedReports={detailedReports} selectedReportType={reportType} />
            </div>
          </Drawer>
        </main>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      {props.plagiarismObj ? plagiarismReport() : plagiarismButton()}
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(PlagiarisimChecker);

import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddLesson from './addLesson'
import LeftMenu from '../AppLayout/leftMenu'

function AddLessonInput(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
          <LeftMenu history={props.history} />
        </Grid>
      </Container>
      <div>
        <AddLesson match={props.match} history={props.history} showRestrictPopup={props.showRestrictPopup} courseLessonDetails={props.courseLessonDetails} onClickShowAddModuleForm={props.onClickShowAddModuleForm} triggerAddLessonForm={props.triggerAddLessonForm} courseLeftMenuTrigger={props.courseLeftMenuTrigger} onCourseLessonMenuClick={props.onCourseLessonMenuClick} />
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(AddLessonInput);
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
//LOCAL IMPORTS
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { submitGrpMembers } from '../../store/ManageGroups/actionCreator';
import { SUCCESS_ADD_MEMBERS_TO_GRP, SUCCESS_UPDATE_GROUP_MEMBERS } from '../Common/Constants/successMessages';
import { ERR_ADD_MEMBERS_TO_GRP } from '../Common/Constants/errorMessages';
import AssignMentorPopup from './assignMentorPopup';
import AssignStudentsPopup from './assignStudentsPopup';
import ViewGrpsInfoPopup from './viewGrpsInfoPopup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: '20px 5px 20px 3px' }} p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: style.backgroundColor._white,
  },
  headerTab: {
    fontSize: '1rem',
    textTransform: 'none'
  },
  dataCount: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: '1.1rem'
  },
  table: {
    minWidth: '1200px',
    overflowX: 'scroll'
  },
  tableHeadingCell: {
    padding: '10px',
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#777',
    backgroundColor: '#f9f9f9'
  },
  tableCell: {
    padding: '3px',
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  loadWrapper: {
    textAlign: 'left',
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '8px',
    color: style.fontColour._blue,
    marginLeft: '16px'
  },
  button: {
    flexGrow: 1,
    backgroundColor: style.button._backgroundColor,
    textTransform: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.button._backgroundColor,
    },
  },
  buttonProgress: {
    marginRight: '16px',
    color: style.button._backgroundColor
  },
  tabCtr: {
    color: style.backgroundColor._tabHeaderFnt
  },
  link: {
    color: style.fontColour._blue,
  },
  grpListHead: {
    textAlign: 'center',
    color: style.fontColour._blue,
    marginBottom: '15px',
    fontSize: '18px'
  },
  searchBox: {
    margin: 5,
    marginBottom: '10px'
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  tableContainer: {
    maxHeight: 440
  },
  listItemWrapper: {
    textAlign: 'center',
    fontSize: '13px',
    color: style.fontColour._naviBlue,
    fontWeight: style.fontWeight._heading
  },
  cardWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: "column",
    margin: "auto",
    borderRadius: '10px'
  },
  titleWrapper: {
    backgroundColor: style.backgroundColor._tabHeaderBg,
    borderRadius: '5px',
    padding: '4px',
    marginTop: '1px',
    marginBottom: '-54px',
    fontSize: '17px',
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue
  }
}));

function TabView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const scrollView = useMediaQuery('(max-width:640px)');
  const [value, setValue] = useState(0);
  const [studentsCheckedCnt, setStudentsCheckedCnt] = useState(0) //For Maintaining the selected check box count
  const [studentCheckedIds, setStudentCheckedIds] = useState([]) //For Maintaining checked row ids
  const [mentorsCheckedCnt, setMentorsCheckedCnt] = useState(0)
  const [mentorCheckedIds, setMentorCheckedIds] = useState([])
  const [instructorsCheckedCnt, setInstructorsCheckedCnt] = useState(0)
  const [instructorCheckedIds, setInstructorCheckedIds] = useState([])
  const [submitLoader, setSubmitLoader] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchData, setSearchData] = useState([])
  const [showAssignMentPopup, setShowAssMentPopup] = useState(false)
  const [assignMentorData, setAssignMentorData] = useState({})
  const [showStudentsPopup, setShowStudentsPopup] = useState(false)
  const [mentorStudentsData, setMentorStudentsData] = useState({})
  const [mentorUnchekdIds, setMentorUnchekdIds] = useState([])
  const [instrUnchekdIds, setInstrUnchekdIds] = useState([])
  const [userGrpsInfo, setUserGrpsInfo] = useState({
    role: '',
    openDialog: false,
    grpsData: []
  })

  useEffect(() => {
    //FOR SETTING UP THE STUDENT SET STATES IN EDIT MODE 
    if (props && props.grpStructureInfo && Object.keys(props.grpStructureInfo) && Object.keys(props.grpStructureInfo).length) {
      let defStuCheckedCnt = props && props.grpStructureInfo && props.grpStructureInfo.students_data && props.grpStructureInfo.students_data.length
      setStudentsCheckedCnt(defStuCheckedCnt)
      let defStuCheckedIds = []
      props && props.grpStructureInfo && props.grpStructureInfo.students_data && props.grpStructureInfo.students_data.forEach((data, index) => {
        defStuCheckedIds.push(data.id)
      })
      setStudentCheckedIds(defStuCheckedIds)

      //FOR SETTING UP THE MENTORS SET STATES IN EDIT MODE 
      let defMentCheckedCnt = props && props.grpStructureInfo && props.grpStructureInfo.mentors_data && props.grpStructureInfo.mentors_data.length
      setMentorsCheckedCnt(defMentCheckedCnt)
      let defMentCheckedIds = []
      props && props.grpStructureInfo && props.grpStructureInfo.mentors_data && props.grpStructureInfo.mentors_data.forEach((data, index) => {
        defMentCheckedIds.push(data.id)
      })
      setMentorCheckedIds(defMentCheckedIds)

      //FOR SETTING UP THE INSTRUCTORS SET STATES IN EDIT MODE
      let defInstCheckedCnt = props && props.grpStructureInfo && props.grpStructureInfo.instructors_data && props.grpStructureInfo.instructors_data.length
      setInstructorsCheckedCnt(defInstCheckedCnt)
      let defInstCheckedIds = []
      props && props.grpStructureInfo && props.grpStructureInfo.instructors_data && props.grpStructureInfo.instructors_data.forEach((data, index) => {
        defInstCheckedIds.push(data.id)
      })
      setInstructorCheckedIds(defInstCheckedIds)
    }

  }, [props.grpStructureInfo])

  const handleChange = (event, newValue) => {
    setSearchData([])
    setSearchTerm('')
    setValue(newValue);
    props.onTabChange(newValue)
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const onCheckboxTrigger = (event, key, row) => {
    if (key === 'students') {
      //FOR STUDENT SELECTION
      let checkedIdsData = [...studentCheckedIds]
      if (event && event.target.checked) {
        let count = studentsCheckedCnt + 1
        setStudentsCheckedCnt(count)
        //FOR SETTING UP THE SELECTED ROW IDS INTO AN ARRAY
        if (!checkedIdsData.includes(row.id) && row.id) {
          checkedIdsData.push(row.id)
        }
      } else {
        let count = studentsCheckedCnt - 1
        setStudentsCheckedCnt(count)
        //for removing the row id on click of uncheck from array
        const rowIdIndex = checkedIdsData.indexOf(row.id)
        checkedIdsData.splice(rowIdIndex, 1)
      }
      setStudentCheckedIds(checkedIdsData)
    } else if (key === 'mentors') {
      //FOR MENTORS SELECTION
      let checkedIdsData = [...mentorCheckedIds]
      let mentUncheckdData = [...mentorUnchekdIds]
      if (event && event.target.checked) {
        let count = mentorsCheckedCnt + 1
        setMentorsCheckedCnt(count)
        //FOR SETTING UP THE SELECTED ROW IDS INTO AN ARRAY
        if (!checkedIdsData.includes(row.id) && row.id) {
          checkedIdsData.push(row.id)
          const rowIdIndex = mentUncheckdData.indexOf(row.id)
          mentUncheckdData.splice(rowIdIndex, 1)
        }
      } else {
        let count = mentorsCheckedCnt - 1
        setMentorsCheckedCnt(count)
        //for removing the row id on click of uncheck from array
        const rowIdIndex = checkedIdsData.indexOf(row.id)
        checkedIdsData.splice(rowIdIndex, 1)
        mentUncheckdData.push(row.id)
      }
      setMentorCheckedIds(checkedIdsData)
      setMentorUnchekdIds(mentUncheckdData)
    } else {
      //FOR INSTRUCTORS SELECTION
      let checkedIdsData = [...instructorCheckedIds]
      let InstUncheckdData = [...instrUnchekdIds]
      if (event && event.target.checked) {
        let count = instructorsCheckedCnt + 1
        setInstructorsCheckedCnt(count)
        //FOR SETTING UP THE SELECTED ROW IDS INTO AN ARRAY
        if (!checkedIdsData.includes(row.id) && row.id) {
          checkedIdsData.push(row.id)
          const rowIdIndex = InstUncheckdData.indexOf(row.id)
          InstUncheckdData.splice(rowIdIndex, 1)
        }
      } else {
        let count = instructorsCheckedCnt - 1
        setInstructorsCheckedCnt(count)
        //for removing the row id on click of uncheck from array
        const rowIdIndex = checkedIdsData.indexOf(row.id)
        checkedIdsData.splice(rowIdIndex, 1)
        InstUncheckdData.push(row.id)
      }
      setInstructorCheckedIds(checkedIdsData)
      setInstrUnchekdIds(InstUncheckdData)
    }
  }

  const getCheckdData = (type) => {
    if (type === 'students') {
      return studentCheckedIds
    } else if (type === 'mentors') {
      return mentorCheckedIds
    } else {
      return instructorCheckedIds
    }
  }

  const successAddMemebers = res => {
    setSubmitLoader(false)
    let hasGrpMembers = props && props.grpStructureInfo && Object.keys(props.grpStructureInfo) && Object.keys(props.grpStructureInfo).length
    props.showSnackBar({
      state: true,
      message: hasGrpMembers ? SUCCESS_UPDATE_GROUP_MEMBERS : SUCCESS_ADD_MEMBERS_TO_GRP,
      type: 'success'
    })
    props.history.push('/managegroups')
  }
  const failureAddMembers = err => {
    setSubmitLoader(false)
    props.showSnackBar({
      state: false,
      message: ERR_ADD_MEMBERS_TO_GRP,
      type: 'error'
    })
  }

  const onAddMemTrigger = () => {
    if (props && props.openMode === 'addMode') {
      if (!studentCheckedIds.length && !mentorCheckedIds.length && !instructorCheckedIds.length) {
        props.showSnackBar({
          state: false,
          message: 'Please select at least one member!',
          type: 'error'
        })
      } else {
        setSubmitLoader(true)
        const payload = {
          group_id: Number(props.match.params.groupId),
          user_ids: [...studentCheckedIds, ...mentorCheckedIds, ...instructorCheckedIds],
        }
        submitGrpMembers(payload, successAddMemebers, failureAddMembers)
      }
    } else {
      // if (studentCheckedIds.length && (!mentorCheckedIds.length || !instructorCheckedIds.length)) {
      //   props.showSnackBar({
      //     state: false,
      //     message: 'Atleast one member should be there or zero from each tab!',
      //     type: 'error'
      //   })
      // } else if (mentorCheckedIds.length && (!studentCheckedIds.length || !instructorCheckedIds.length)) {
      //   props.showSnackBar({
      //     state: false,
      //     message: 'Atleast one member should be there or zero from each tab!',
      //     type: 'error'
      //   })
      // } else if (instructorCheckedIds.length && (!studentCheckedIds.length || !mentorCheckedIds.length)) {
      //   props.showSnackBar({
      //     state: false,
      //     message: 'Atleast one member should be there or zero from each tab!',
      //     type: 'error'
      //   })
      // } else {
      let totalSelectedData = [...studentCheckedIds, ...mentorCheckedIds, ...instructorCheckedIds]
      let totalUnSelectedData = [...mentorUnchekdIds, ...instrUnchekdIds]
      let uniqueData = totalSelectedData && totalSelectedData.filter(e => !totalUnSelectedData.includes(e));
      setSubmitLoader(true)
      const payload = {
        group_id: Number(props.match.params.groupId),
        user_ids: uniqueData //[...studentCheckedIds, ...mentorCheckedIds, ...instructorCheckedIds],
      }
      submitGrpMembers(payload, successAddMemebers, failureAddMembers)
      //}
    }
  }

  const onCloseTrigger = () => {
    props.history.push('/managegroups')
  }

  //FOR SEARCH FILTER
  const handleSearchUsers = evnt => {
    setSearchTerm(evnt.target.value)
    var name = evnt.target.value && evnt.target.value
    var results = [];
    var index;
    var entry;
    name = name.toUpperCase()
    if (value === 0) {
      let data = props && props.studentsData && props.studentsData
      for (index = 0; index < data.length; ++index) {
        entry = data[index];
        if (entry && entry.student_name && entry.student_name.toUpperCase().indexOf(name) !== -1) {
          results.push(entry)
        }
      }
      setSearchData(results)
    } else if (value === 1) {
      let data = props && props.mentorsData && props.mentorsData
      for (index = 0; index < data.length; ++index) {
        entry = data[index];
        if (entry && entry.mentor_name && entry.mentor_name.toUpperCase().indexOf(name) !== -1) {
          results.push(entry)
        }
      }
      setSearchData(results)
    } else {
      let data = props && props.instructorsData && props.instructorsData
      for (index = 0; index < data.length; ++index) {
        entry = data[index];
        if (entry && entry.instructor_name && entry.instructor_name.toUpperCase().indexOf(name) !== -1) {
          results.push(entry)
        }
      }
      setSearchData(results)
    }
  }

  const triggerMentorNameClick = (data, type) => {
    let rowData = { ...data, 'mode': type }
    setAssignMentorData(rowData)
    setShowAssMentPopup(true)
  }
  const closeAssignMentPopup = () => {
    setShowAssMentPopup(false)
    setAssignMentorData({})
  }

  const triggerViewStudents = (data) => {
    setShowStudentsPopup(true)
    setMentorStudentsData(data)
  }
  const closeViewStudentsPopup = () => {
    setShowStudentsPopup(false)
    setMentorStudentsData({})
  }

  const tabViewHeader = {
    students: {
      label: 'Students',
      count: props && props.tabDataCounts && props.tabDataCounts.students,
      list: props && props.studentsData && props.studentsData,
      name: 'student'
    },
    mentors: {
      label: 'Mentors',
      count: props && props.tabDataCounts && props.tabDataCounts.mentors,
      list: props && props.mentorsData && props.mentorsData,
      name: 'mentor'
    },
    instructors: {
      label: 'Instructors',
      count: props && props.tabDataCounts && props.tabDataCounts.instructors,
      list: props && props.instructorsData && props.instructorsData,
      name: 'instructor'
    }
  }

  const tabObj = tabViewHeader
  const renderTabs = () => {
    const label = (str, count) => {
      return (
        <div>
          <span className={classes.tabName}>{str}</span>
          <span className={classes.dataCount}>({count ? count : 0})</span>
        </div>
      )
    }
    const tabs = Object.keys(tabObj).map(key =>
      <Tab className={classes.headerTab} label={label(tabObj[key].label, tabObj[key].count)} {...a11yProps(key)} key={key} />
    )
    return tabs
  }

  //for showing the student groups information
  const triggerViewGrps = (data, role) => {
    setUserGrpsInfo({
      role: role,
      openDialog: true,
      grpsData: data && data.length ? data : []
    })
  }
  const closeGrpInfoPopup = () => {
    setUserGrpsInfo({
      role: '',
      openDialog: false,
      grpsData: []
    })
  }

  //FOR SHOWING ALL THE TABLE ROW DATA
  const getTabData = (defData, key, userType) => {
    let data = []
    if (searchTerm) {
      data = searchData
    } else {
      data = defData
    }

    // const disableCheckBox = (row) => {
    //   if (row && row.group_data && row.group_data.group_title && row.group_data.group_title !== props.groupInfo.group_title) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
    return (
      <React.Fragment>
        {data && data.length ? (
          <React.Fragment>
            {data && data.length && data.map((row, reqKey) => (
              <TableRow key={reqKey} style={{ backgroundColor: getCheckdData(key).indexOf(row.id) > -1 ? '#e8f4fd' : '' }}>
                <TableCell component="th" scope="row" agian="center" className={classes.tableCell}>
                  <Checkbox
                    style={{ color: '#2e8eec' }}
                    size="small"
                    id={`id_${row.id}`}
                    //disabled={disableCheckBox(row)}
                    checked={getCheckdData(key).indexOf(row.id) > -1 || false}
                    onChange={(event) => { onCheckboxTrigger(event, key, row) }}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                  {row && row.student_name ? row.student_name : row.mentor_name ? row.mentor_name : row.instructor_name ? row.instructor_name : 'NA'}
                </TableCell>
                <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                  {row && row.email && row.email}
                </TableCell>
                {key === 'students' ? (
                  <React.Fragment>
                    <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                      {row && row.has_mentor ? (
                        <React.Fragment>
                          <Link className={classes.link} component="button" onClick={() => { triggerMentorNameClick(row, 'edit') }}>View Mentors</Link>
                        </React.Fragment>
                      ) : (
                        <Button
                          size="small"
                          variant="outlined"
                          style={{ color: style.fontColour._blue, fontSize: '11px', textTransform: 'none' }}
                          onClick={() => { triggerMentorNameClick(row, 'add') }}
                        >
                          Assign Mentor
                        </Button>
                      )}
                    </TableCell>
                  </React.Fragment>
                ) : null}
                {key === 'mentors' ? (
                  <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                    {row && row.has_students ? (
                      <Link className={classes.link} component="button" onClick={() => { triggerViewStudents(row) }}>View Students</Link>
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        style={{ color: style.fontColour._blue, fontSize: '11px', textTransform: 'none' }}
                        onClick={() => { triggerViewStudents(row) }}
                      >
                        Assign Students
                      </Button>
                    )}
                  </TableCell>
                ) : null}
                <TableCell>
                  {row && row.group_data && row.group_data.length ? (
                    <Link className={classes.link} component="button" onClick={() => { triggerViewGrps(row.group_data, userType) }}>{`View Groups (${row && row.group_data && row.group_data.length})`}</Link>
                  ) : 'NA'}
                </TableCell>
              </TableRow>
            ))}
          </React.Fragment>
        ) : (
          <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'left', paddingLeft: '13px' }}>No data found</div>
        )}
      </React.Fragment>
    )
  }

  const renderTabContentPanel = () => {
    const tabs = Object.keys(tabObj).map(key =>
      <TabPanel key={key} value={key} index={key} dir={theme.direction}>
        {tabObj[key].count ? (
          <React.Fragment>
            <Grid item xs={12} md={4} sm={4} style={{ float: 'left' }}>
              {!props.paginationLoader && tabObj[key] && tabObj[key].list && tabObj[key].list.length ? (
                <TextField
                  id={`search-${key}`}
                  className={classes.searchBox}
                  placeholder={`Find a ${tabObj[key].name}`}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon style={{ color: style.fontColour._blue }} />
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.searchBoxOutline
                    }
                  }}
                  variant="outlined"
                  onChange={handleSearchUsers}
                  defaultValue={searchTerm}
                />
              ) : null}
            </Grid>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} stickyHeader aria-label="Manage group memebers">
                <TableHead>
                  <TableRow>
                    {tabObj[key].list && tabObj[key].list.length && !props.paginationLoader ? (
                      <TableCell align="center" className={classes.tableHeadingCell}></TableCell>
                    ) : null}
                    <TableCell align="center" className={classes.tableHeadingCell}>Name</TableCell>
                    <TableCell align="center" className={classes.tableHeadingCell}>Email</TableCell>
                    {value === 0 ? (
                      <React.Fragment>
                        <TableCell align="center" className={classes.tableHeadingCell}>Mentor Details</TableCell>
                      </React.Fragment>
                    ) : null}
                    {value === 1 ? (
                      <TableCell align="center" className={classes.tableHeadingCell}>Student Details</TableCell>
                    ) : null}
                    <TableCell align="center" className={classes.tableHeadingCell}>Group Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.paginationLoader ? (
                    <TableRow>
                      <TableCell className={classes.loadWrapper}>Loading...</TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {tabObj[key].list && tabObj[key].list.length ? (
                        <React.Fragment>
                          {getTabData(tabObj[key].list, key, tabObj[key].label)}
                        </React.Fragment>
                      ) : (
                        <TableRow>
                          <TableCell>No data found</TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>No data found</div>
          </React.Fragment>
        )}
        {!props.paginationLoader ? (
          <div style={{ marginTop: '10px' }}>
            <Button
              variant="contained"
              className={classes.button}
              disabled={submitLoader}
              onClick={() => { onAddMemTrigger() }}
            >
              {submitLoader ? (
                <CircularProgress size={23} className={classes.buttonProgress} />
              ) : null}
              {props && props.openMode === 'editMode' ? 'Update group members' : 'Add members to group'}
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              style={{ marginLeft: '10px' }}
              disabled={submitLoader}
              onClick={() => { onCloseTrigger() }}
            >
              Cancel
            </Button>
          </div>
        ) : null}
      </TabPanel >
    )
    return tabs
  }

  return (
    <div className={classes.root}>
      {props && props.grpStructureInfo && Object.keys(props.grpStructureInfo) && Object.keys(props.grpStructureInfo).length ? (
        <div style={{ marginBottom: '20px' }}>
          <Typography className={classes.grpListHead}>Group Members</Typography>
          <Grid container spacing={2}>
            {props.grpStructureInfo && props.grpStructureInfo.students_data && props.grpStructureInfo.students_data.length ? (

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.cardWrapper} variant="outlined">
                  <CardHeader
                    classes={{
                      title: classes.titleWrapper
                    }}
                    title={`Student(s) - ${props.grpStructureInfo && props.grpStructureInfo.students_data && props.grpStructureInfo.students_data.length}`}
                  />
                  <CardContent style={{ height: '300px', overflowY: 'scroll' }}>
                    {props.grpStructureInfo.students_data.map((data, index) => (
                      <React.Fragment key={index}>
                        <ListItem style={{ display: 'flex', justifyContent: 'left' }}>
                          <Typography className={classes.listItemWrapper}>
                            {`${data && data.student_name && data.student_name} (${data && data.email && data.email})`}
                          </Typography>
                        </ListItem>
                        <Divider aria-hidden="true" />
                      </React.Fragment>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

            ) : null}
            {props.grpStructureInfo && props.grpStructureInfo.mentors_data && props.grpStructureInfo.mentors_data.length ? (

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.cardWrapper} variant="outlined">
                  <CardHeader
                    classes={{
                      title: classes.titleWrapper
                    }}
                    title={`Mentor(s) - ${props.grpStructureInfo && props.grpStructureInfo.mentors_data && props.grpStructureInfo.mentors_data.length}`}
                  />
                  <CardContent style={{ height: '300px', overflowY: 'scroll' }}>
                    {props.grpStructureInfo.mentors_data.map((data, index) => (
                      <React.Fragment key={index} >
                        <ListItem style={{ display: 'flex', justifyContent: 'left' }}>
                          <Typography className={classes.listItemWrapper}>
                            {`${data && data.mentor_name && data.mentor_name} (${data && data.email && data.email})`}
                          </Typography>
                        </ListItem>
                        <Divider aria-hidden="true" />
                      </React.Fragment>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

            ) : null}
            {props.grpStructureInfo && props.grpStructureInfo.instructors_data && props.grpStructureInfo.instructors_data.length ? (

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Card className={classes.cardWrapper} variant="outlined">
                  <CardHeader
                    classes={{
                      title: classes.titleWrapper
                    }}
                    title={`Instructor(s) - ${props.grpStructureInfo && props.grpStructureInfo.instructors_data && props.grpStructureInfo.instructors_data.length}`}
                  />
                  <CardContent style={{ height: '300px', overflowY: 'scroll' }}>
                    {props.grpStructureInfo.instructors_data.map((data, index) => (
                      <React.Fragment key={index} >
                        <ListItem style={{ display: 'flex', justifyContent: 'left' }}>
                          <Typography className={classes.listItemWrapper}>
                            {`${data && data.instructor_name && data.instructor_name} (${data && data.email && data.email})`}
                          </Typography>
                        </ListItem>
                        <Divider aria-hidden="true" />
                      </React.Fragment>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

            ) : null}
          </Grid>
          <Divider aria-hidden="true" style={{ marginTop: '10px' }} />
        </div>
      ) : null}
      <AppBar position="static" style={{ backgroundColor: style.backgroundColor._tabHeaderBg }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabCtr}
          variant={scrollView ? "scrollable" : 'fullWidth'}
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          {renderTabs()}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {renderTabContentPanel()}
      </SwipeableViews>
      {showAssignMentPopup ? (
        <AssignMentorPopup
          open={showAssignMentPopup}
          assignMentorData={assignMentorData}
          closeAssignMentPopup={closeAssignMentPopup}
          fetchStudentDataList={props.fetchOrgMembersData}
          match={props.match}
          history={props.history}
        />
      ) : null}
      {showStudentsPopup ? (
        <AssignStudentsPopup
          open={showStudentsPopup}
          mentorStudentsData={mentorStudentsData}
          fetchMentorDataList={props.fetchOrgMembersData}
          closeViewStudentsPopup={closeViewStudentsPopup}
          match={props.match}
          history={props.history}
        />
      ) : null}
      {userGrpsInfo.openDialog ? (
        <ViewGrpsInfoPopup
          open={userGrpsInfo.openDialog}
          data={userGrpsInfo.grpsData}
          role={userGrpsInfo.role}
          close={closeGrpInfoPopup}
          match={props.match}
          history={props.history}
        />
      ) : null}
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(TabView);
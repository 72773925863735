import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { publishCourse, fetchDepartments, mandateCourseTo, fetchTeamsList, fetchOrgDesignations, updateCourseAudience } from '../../store/AddingCourse/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_COURSE_PUBLISH, ERROR_FETCH_DEPARTMENTS_LIST, ERROR_FETCH_GROUPS_LIST } from '../Common/Constants/errorMessages'
import { SUCCESS_COURSE_PUBLISH } from '../Common/Constants/successMessages'
import { getDomainInfo } from '../../Helpers/basic'
import AlertMessages from '../Common/AlertMessages';
import { fetchOrgGrpsList } from '../../store/ManageGroups/actionCreator';
import { style } from '../../Styles/theme';
import NumberInput from '../Common/NumberFormat';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    display: 'block'
  },
  autoCompWrapper: {
    marginTop: '1rem'
  },
  membersCntr: {
    maxHeight: "200px",
    overflowY: 'scroll'
  },
  teamsCntr: {
    border: "1px solid #999",
    maxHeight: "400px",
  },
  teamWrapper: {
    margin: "0.5rem",
  },
  teamName: {
    fontSize: "1rem",
    color: "#15528d",
  },
  selMemChip: {
    color: "#2e8eec",
    marginTop: "0.3rem",
    border: "1px solid #2e8eec",
  },
  disabledChip: {
    color: "#bdbdbd",
    border: "1px solid #bdbdbd",
    marginTop: "0.3rem"
  },
  listItemWrapper: {
    display: 'block',
    fontSize: '15px',
    marginLeft: '-10px'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CoursePublishPopup(props) {
  console.log(props)
  const classes = useStyles();
  const open = props.openCoursePublish;

  let defSelectedDesignations = props && props.courseData && props.courseData.published_designations && Object.keys(props.courseData.published_designations) && Object.keys(props.courseData.published_designations).length && props.courseData.published_designations.designations ? props.courseData.published_designations.designations : []
  let defDesignationsMandateDuration = props && props.courseData && props.courseData.published_designations && Object.keys(props.courseData.published_designations) && Object.keys(props.courseData.published_designations).length && props.courseData.published_designations.designation_duration ? props.courseData.published_designations.designation_duration : 0
  let dialogFrom = props && props.courseData && props.courseData.dialog_from;

  let defAudienceNewJoiners = props && props.courseData && props.courseData.designation_based_newjoin;
  const domainInfo = getDomainInfo()
  const [inProgress, setInprogress] = React.useState(false)
  const [departments, setDepartments] = React.useState([]);
  const [departMentList, setDepartMentList] = React.useState([])
  const [department, setSelectedDepartment] = React.useState([])
  const [orgGrpsList, setOrgGrpsList] = React.useState([])
  const [selectedGroups, setSelectedGroups] = React.useState([])
  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })

  const [setPublishLevel, setSelectedPublishLevel] = React.useState({
    organization: true,
    myathina: false,
    autoEnrollForNewUsers: false,
    mandatoryForExistingUsers: false,
    designationBased: defSelectedDesignations && defSelectedDesignations.length ? true : false
  });
  const [completionDuration, setCompletionDuration] = React.useState(0);
  const [existingEmployeeCompletionDuration, setExistingEmployeeCompletionDuration] = React.useState(0);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamList, setTeamList] = useState([])
  const [selectedTeamMemCount, setSelectedTeamMemCount] = React.useState('')
  const [alreadyRecList, setAlreadyRecList] = React.useState([])
  const [selectedTeamMembersList, setSelectedTeamMembersList] = useState([]);
  const [alreadyRecUsersList, setAlreadyRecUsersList] = React.useState([])
  const [recUniqueCount, setRecUniqueCount] = React.useState('')
  const [showErrorMsg, setShowErrorMsg] = React.useState(false)
  const [nullCheck, setNullCheck] = useState(false);

  //FOR DESIGNATION THINGS
  const showDesignationBased = true;
  const [designationsLoader, setDesignationsLoader] = React.useState(false);
  const [designationsList, setDesignationsList] = React.useState([]);
  const [designationMandateDuration, setDesignationMandateDuration] = React.useState(defDesignationsMandateDuration);
  const [selectedDesignations, setSelectedDesignations] = React.useState(defSelectedDesignations);

  const [checkDesignNewJoiners, setDesignNewJoinersCheck] = useState(defAudienceNewJoiners);

  const onResetMandatoryForExistingUsers = () => {
    setSelectedTeam([]);
    setSelectedTeamMemCount('')
    setAlreadyRecList([])
    setSelectedTeamMembersList([]);
    setAlreadyRecUsersList([])
    setRecUniqueCount('')
    setShowErrorMsg(false)
    setNullCheck(false);
    setExistingEmployeeCompletionDuration(0)
  }

  const handleClose = () => {
    props.closeCoursePopup();
  };

  const deptSuccessCallBack = res => {
    setDepartMentList(res.result)
    let arr = []
    for (let i in res.result) {
      arr.push(res.result[i].name)
    }
    setDepartments(arr)
    setSelectedDepartment(arr)
  }

  const deptFailureCallBack = error => {
    setDepartMentList([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_DEPARTMENTS_LIST,
        type: 'error'
      }
    )
  }

  const successOrgGrpsList = res => {
    setOrgGrpsList(res && res.data && res.data)
  }
  const failureOrgGrpsList = error => {
    setOrgGrpsList([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_GROUPS_LIST,
        type: 'error'
      }
    )
  }

  const fetchTeamsData = (value) => {
    fetchTeamsList(
      {
        reporting_members: false
      },
      (res) => {
        let teamListArr = res.data || [];

        // TODO: Fetching departmenus should be automated
        /*
        let deptMenus = getDepartmentMenus();
        deptMenus.length > 0 && deptMenus.forEach((dept) => {
          let selTeam = res.data.find((team) => (dept.departmentId === team.id && (dept.activeMenus.length > 0 && dept.activeMenus.includes(2))));
          if (selTeam) {
            teamListArr.push(selTeam);
          }
        })
        */
        setTeamList(teamListArr)
      },
      (error) => {
        setTeamList([])
      }
    );
  };

  //FOR DESIGNATION CALLBACKS
  const successDesignList = res => {
    let desList = res?.results
    let newDesList = [{ id: '', name: 'Select All' }, ...desList];
    setDesignationsList(newDesList);
    setDesignationsLoader(false);
  }
  const failureDesignList = err => {
    setDesignationsList([]);
    setDesignationsLoader(false);
  }

  useEffect(() => {
    if (domainInfo && domainInfo.name === 'rmbl') {
      fetchOrgGrpsList({}, successOrgGrpsList, failureOrgGrpsList)
    } else {
      fetchDepartments({}, deptSuccessCallBack, deptFailureCallBack)
      fetchTeamsData()
      if (showDesignationBased) {
        setDesignationsLoader(true);
        fetchOrgDesignations({}, successDesignList, failureDesignList)
      }
    }
  }, [])

  const handleDeptChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedDepartment(department.length === departments.length ? [] : departments);
      return;
    }
    setSelectedDepartment(value);
  };

  const handleSelectGrp = (grpName) => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    let arr = []
    let grpListVal = orgGrpsList
    if (grpListVal && grpListVal.length) {
      if (grpName && grpName.length) {
        grpName.forEach((grp) => {
          let groupObj = grpListVal.find((group) => group.id === grp.id)
          if (groupObj) {
            arr.push(groupObj.id)
          }
        })
      }
    }
    setSelectedGroups(arr)
  }

  const handleChangeLevel = (event) => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    setSelectedPublishLevel({ ...setPublishLevel, [event.target.name]: event.target.checked });
    if (event.target.name === 'mandatoryForExistingUsers') {
      onResetMandatoryForExistingUsers()
    }
  };

  const getSelectedDepartmentIds = depArr => {
    let selectedDepartmentIds = []
    for (let i in depArr) {
      const res = find(departMentList, { 'name': depArr[i] })
      selectedDepartmentIds.push(res.id)
    }
    return selectedDepartmentIds
  }

  const successCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_COURSE_PUBLISH,
        type: 'success'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    if (dialogFrom === 'edit_audience') {
      props.courseListFetch();
    } else {
      props.history.push('/mycourse')
    }
    //FOR MANDATING THE scrom COURSE TO CASEYS EMPLOYEES
    if (domainInfo.name === 'caseys') {
      var mandateDate = new Date();
      mandateDate.setDate(mandateDate.getDate() + 7)
      const payLoad = {
        course_type: 'mandate',
        course_location: 'internal',
        due_date_mandate: mandateDate,
        user_id: getSelectedDepartmentIds(department),
        course_id: props.match.params.courseId ? props.match.params.courseId : null,
      };
      mandateCourseTo(payLoad, () => { }, () => { })
    }
    handleClose()
  }

  const failureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_COURSE_PUBLISH,
        type: 'error'
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }
  const mandateCourseSelectedUSerList = () => {
    let membersList = selectedTeamMembersList.flatMap((list) => list.members);
    const membersListIds = membersList.length > 0 ? membersList.map(item => item.id) : [];
    return membersListIds;
  }
  const mandatoryValidationStatus = () => {
    let errorStatus = {
      error: false,
      errorMessage: ''
    }
    if (setPublishLevel.mandatoryForExistingUsers) {
      if (!existingEmployeeCompletionDuration || existingEmployeeCompletionDuration === '0') {
        errorStatus = {
          error: true,
          errorMessage: 'Please enter valid completion duration for existing employees'
        }
      } else {
        const selectedUserList = mandateCourseSelectedUSerList()
        if (!selectedUserList.length) {
          errorStatus = {
            error: true,
            errorMessage: 'Please select employees to assign the course mandatory'
          }
        }
      }
    }

    if (setPublishLevel.autoEnrollForNewUsers && (!completionDuration || completionDuration === '0')) {
      errorStatus = {
        error: true,
        errorMessage: 'Please enter valid completion duration for new joiners'
      }
    }
    return errorStatus;
  }
  const publishCourseonClick = () => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    if (domainInfo.type === 'b2b') {
      if (!setPublishLevel.designationBased && !setPublishLevel.mandatoryForExistingUsers && !setPublishLevel.autoEnrollForNewUsers && !setPublishLevel.myathina && !setPublishLevel.organization) {
        setValidationError({
          error: true,
          errorMessage: 'Please select atleast one'
        })
      } else {
        if (domainInfo.name === 'rmbl') {
          if (selectedGroups && !selectedGroups.length) {
            setValidationError({
              error: true,
              errorMessage: 'Please select at least one group to publish'
            })
          } else {
            setValidationError({
              error: false,
              errorMessage: ''
            })
            setInprogress(true)
            const payload = {
              course_id: props.match.params.courseId ? props.match.params.courseId : null,
              organization: setPublishLevel.organization,
              myathina: setPublishLevel.myathina,
              autoenroll: setPublishLevel.autoEnrollForNewUsers,
              selected_groups: selectedGroups,
              published: true
            }
            publishCourse(payload, successCallBack, failureCallBack)
          }
        } else {
          const mandatoryErrorStatus = mandatoryValidationStatus()
          if (mandatoryErrorStatus.error) {
            setValidationError(mandatoryErrorStatus)
          } else if (setPublishLevel.designationBased && !designationMandateDuration) {
            setValidationError({
              error: true,
              errorMessage: 'Please select duration to assign the course'
            })
          } else if (setPublishLevel.designationBased && (!selectedDesignations || !selectedDesignations.length)) {
            setValidationError({
              error: true,
              errorMessage: 'Please select designations to assign the course'
            })
          } else {
            setInprogress(true)
            const selectedDepartmentIds = getSelectedDepartmentIds(department)
            if (setPublishLevel.organization || setPublishLevel.designationBased || setPublishLevel.mandatoryForExistingUsers || setPublishLevel.autoEnrollForNewUsers) {
              let payload = {
                course_id: props && props.match && props.match.params && props.match.params.courseId ? props.match.params.courseId : props && props.courseData && props.courseData.id,
                organization: setPublishLevel.organization,
                myathina: setPublishLevel.myathina,
                selected_departments: setPublishLevel.organization ? selectedDepartmentIds : [],
                autoenroll: setPublishLevel.autoEnrollForNewUsers,
                mandatory_for_existing_users: setPublishLevel.mandatoryForExistingUsers,
                published: true,
                designation_based_newjoin: checkDesignNewJoiners
              }
              if (setPublishLevel.mandatoryForExistingUsers) {
                payload.existing_employee_completion_duration = existingEmployeeCompletionDuration
                payload.mandatory_users = mandateCourseSelectedUSerList()
              }
              if (setPublishLevel.autoEnrollForNewUsers) {
                payload.autoenroll_completion_duration = completionDuration
              }
              if (setPublishLevel.designationBased) {
                payload.designation_mandate_duration = designationMandateDuration
                payload.designation_ids = selectedDesignations.flatMap((item) => item.id).filter((item) => item !== '');
              }
              if (dialogFrom === 'edit_audience' || (setPublishLevel.designationBased && defSelectedDesignations && defSelectedDesignations.length)) {
                let audienceEdit = {
                  designation_duration: designationMandateDuration,
                  course_id: props && props.courseData && props.courseData.id,
                  designation_ids: selectedDesignations.flatMap((item) => item.id).filter((item) => item !== ''),
                  designation_based_newjoin: checkDesignNewJoiners
                }
                updateCourseAudience(audienceEdit, successCallBack, failureCallBack)
              } else {
                publishCourse(payload, successCallBack, failureCallBack)
              }
            }
            else {
              const payload = {
                course_id: props.match.params.courseId ? props.match.params.courseId : null,
                organization: setPublishLevel.organization,
                myathina: setPublishLevel.myathina,
                autoenroll: setPublishLevel.autoEnrollForNewUsers,
                selected_departments: [],
                published: true
              }
              publishCourse(payload, successCallBack, failureCallBack)
            }
          }
        }
      }
    }

  }


  const getHelperText = (type) => {
    if (type === 'autoenroll') {
      return (
        <span>By selecting this check box, the course will be available to all the <b>new joiners</b> in their <b>mandatory courses</b> section under <b>My Courses</b></span>
      )
    }
    if (type === 'mandatoryForExistingUsers') {
      return (
        <span>By selecting this check box, the course will be available to the selected <b>existing employees</b> in their <b>mandatory courses</b> section under <b>My Courses</b></span>
      )
    }
    if (type === 'designationBased') {
      return (
        <span>This course will be available to the employees within your organization selected by you</span>
      )
    }
    if (type === 'assignToNewJoinersInDesignations') {
      return (
        <span>This course will be available to the new joiners in the selected audience</span>
      )
    }
  }
  const recieveAndSetCompletionDuration = value => {
    setCompletionDuration(value)
  }
  const recieveAndSetExistingEmployeeCompletionDuration = value => {
    setExistingEmployeeCompletionDuration(value)
  }
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleSelectTeam = (teamName) => {
    setAlreadyRecList([])
    setNullCheck(false)
    setShowErrorMsg(false)
    setSelectedTeam(teamName);
    let teamListVal = teamList;
    let existingTeamMemberList = [...selectedTeamMembersList];

    let arr = [];
    if (teamListVal.length > 0) {
      if (teamName && teamName.length > 0) {
        teamName.forEach((item, index) => {
          let teamObj = teamListVal.find((team) => team.name === item.name);
          let selObj = existingTeamMemberList.find(
            (selTeam) => selTeam.team === item.name
          );
          if (selObj) {
            let teamMembers = selObj.members;
            let Obj = {
              team: item.name,
              members: teamMembers,
            };
            arr.push(Obj);
          } else if (teamObj) {
            let teamMembers = teamObj.team_members;
            let Obj = {
              team: item.name,
              members: teamMembers,
            };
            arr.push(Obj);
          }
        });
        let newMemCount = 0
        arr && arr.length && arr.forEach((item, index) => {
          newMemCount = newMemCount + item.members.length
          setSelectedTeamMemCount(newMemCount)
        })
        setSelectedTeamMembersList(arr);
      } else {
        setSelectedTeamMembersList([])
        setSelectedTeamMemCount(0)
      }
    }
  };
  const renderTeamListChip = (teamsList) => {
    if (teamsList.length > 0) {
      let arr = [];
      teamsList.forEach((item, index) => {
        let label = item.name + " - " + item.email;
        let alreadyRecName = alreadyRecList && alreadyRecList.indexOf(item.email) > -1
        let userData = alreadyRecUsersList && alreadyRecUsersList.find((data) => data.email === item.email);
        let labelName =
          label.length > 40 ? label.substring(0, 35) + "..." : label;
        arr.push(
          <React.Fragment key={index}>
            <Chip
              variant="outlined"
              label={labelName}
              deleteIcon={<HighlightOffIcon style={{ color: '#2e8eec' }} />}
              onDelete={() => {
                handleTeamMemberDelete(item);
              }}
              className={!alreadyRecName ? classes.selMemChip : classes.disabledChip}
            />
            {
              // alreadyRecName ? <span style={{ marginLeft: '15px', color: '#9e9e9e', fontSize: '12px', letterSpacing: '1px' }}>{radioValue === 'recommend' ? (userData && userData.status && userData.status) : (userData && userData.is_mandate === true ? 'Already Mandated' : (userData && userData.status && userData.status))}</span> : null
            }
            <br />
          </React.Fragment>
        );
      });
      return arr;
    }
  };
  const handleTeamMemberDelete = (teamMemberToDelete) => {
    let alreadyRecName = alreadyRecList && alreadyRecList.indexOf(teamMemberToDelete.email) > -1
    if (alreadyRecName) {
      let newCount = recUniqueCount - 1
      setRecUniqueCount(newCount)
    }
    setShowErrorMsg(false)
    let selectedMemList = [...selectedTeamMembersList];
    if (selectedMemList.length > 0) {
      selectedMemList.forEach((item, index) => {
        let removedMemList = [];
        let teamObj = item.members.find(
          (member) => member.name === teamMemberToDelete.name
        );
        if (teamObj) {
          removedMemList = item.members.filter(
            (member) => member.id !== teamMemberToDelete.id
          );
          item["members"] = removedMemList;
        }
      });
      let newMemCount = 0
      selectedMemList && selectedMemList.length && selectedMemList.forEach((item, index) => {
        newMemCount = newMemCount + item.members.length
        setSelectedTeamMemCount(newMemCount)
      })
      setSelectedTeamMembersList(selectedMemList);
    }
  };

  //ON DESIGNATIONS CHANGE
  const handleDesignationChange = data => {
    setSelectedDesignations(data);
  }
  //ON DESIGNATION SELECT ALL CHECKBOX
  const checkAllDesignChange = event => {
    if (event.target.checked) {
      setSelectedDesignations(designationsList);
    } else {
      setSelectedDesignations([]);
    }
  }

  //ON DESIGNATION BASED NEW JOINERS CHECKBOX CHANGE
  const handleDesignationNewJoiners = event => {
    setDesignNewJoinersCheck(event.target.checked);
    setSelectedPublishLevel({ ...setPublishLevel, autoEnrollForNewUsers: false });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle id="dialog" style={{ height: '10px' }}>{dialogFrom === 'edit_audience' ? 'Edit Audience' : 'Are you sure you want to publish this course?'}</DialogTitle>
        <DialogContent>
          {validationError.error ? (
            <div style={{ width: '50%', height: "100%", padding: '14px', textAlign: 'initial' }}>
              <AlertMessages status="error" message={validationError.errorMessage} />
            </div>
          ) : null}
          <DialogContentText id="dialogdesc" style={{ marginTop: 10 }}>
            Once the course is published it can't be edited. Kindly review the course again and hit the "Publish" button.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          {domainInfo.type === 'b2b' ? (
            <React.Fragment>
              {dialogFrom !== 'edit_audience' ? (
                <React.Fragment>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={setPublishLevel.organization}
                        onChange={handleChangeLevel}
                        name="organization"
                        color="primary"
                      />
                    }
                    label="To Your Organization"
                  />
                  {domainInfo && domainInfo.name === 'rmbl' ? (
                    <FormHelperText>This course will go to your organization only and to the groups selected by you.</FormHelperText>
                  ) : (
                    <FormHelperText>This course will go to your organization only and to the teams selected by you.</FormHelperText>
                  )}
                </React.Fragment>
              ) : null}
              {domainInfo && domainInfo.name === 'rmbl' ? (
                <React.Fragment>
                  {domainInfo.type === 'b2b' && setPublishLevel.organization ? (
                    <div className={classes.autoCompWrapper}>
                      <Autocomplete
                        onChange={(event, grpName) => {
                          handleSelectGrp(grpName);
                        }}
                        multiple
                        id="checkboxes-tags-demo"
                        options={orgGrpsList && orgGrpsList.length > 0 ? orgGrpsList : []}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.group_title}
                        className={classes.grpListWrapper}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8, color: style.fontColour._blue }}
                              checked={selected}
                            />
                            <React.Fragment>
                              <div>
                                {option.group_title}
                                <p style={{ fontSize: '11px', color: '#999', fontWeight: 500, marginTop: '-2px' }}>{` (Members count - ${option.members_count}) `}</p>
                              </div>
                            </React.Fragment>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ color: "red", fontSize: "1rem" }}
                            variant="outlined"
                            label="Search for groups..."
                          />
                        )}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {dialogFrom !== 'edit_audience' ? (
                    <React.Fragment>
                      {domainInfo.type === 'b2b' && setPublishLevel.organization ? (
                        <FormControl className={classes.formControl}>
                          <InputLabel id="department">Departments</InputLabel>
                          <Select
                            labelId="department"
                            id="departmentid"
                            multiple
                            value={department}
                            onChange={handleDeptChange}
                            input={<Input />}
                            renderValue={selected => selected.join(', ')}
                            MenuProps={MenuProps}
                            fullWidth
                          >
                            <MenuItem value="all">
                              <ListItemIcon>
                                <Checkbox
                                  id={'filter'}
                                  color="primary"
                                  checked={departments.length > 0 && department.length === departments.length}
                                  indeterminate={department.length > 0 && department.length < departments.length}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography className={classes.listItemWrapper} component="span">Select All</Typography>
                                }
                              />
                            </MenuItem>
                            {departments.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox id="checkBox" checked={department.indexOf(name) > -1} color="primary" />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : null}
                      <div style={{ backgroundColor: '#f5f5f5', paddingBottom: 10 }}>
                        <FormControl className={classes.formControl} style={{ maxWidth: '100%' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={setPublishLevel.mandatoryForExistingUsers}
                                onChange={handleChangeLevel}
                                name="mandatoryForExistingUsers"
                                color="primary"
                              />
                            }
                            label="Mandatory course for existing employees"
                          />
                          <FormHelperText>{getHelperText('mandatoryForExistingUsers')}</FormHelperText>
                        </FormControl>
                        {setPublishLevel.mandatoryForExistingUsers ? (
                          <div>
                            <FormControl className={classes.formControl}>
                              <NumberInput type="duration" label="Duration" defaultValue={existingEmployeeCompletionDuration} setValue={recieveAndSetExistingEmployeeCompletionDuration} helperText="enter maximum number of days to complete the course" />
                            </FormControl>
                            <div className={classes.autoCompWrapper} style={{ marginTop: 20, padding: 10 }}>
                              <Autocomplete
                                value={selectedTeam}
                                onChange={(event, teamName) => {
                                  handleSelectTeam(teamName);
                                }}
                                multiple
                                id="checkboxes-tags-demo"
                                options={teamList && teamList.length > 0 ? teamList : []}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                className={classes.memberListAutoSuggest}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8, color: "#2e8eec" }}
                                      checked={selected}
                                    />
                                    {option.name}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{ color: "red", fontSize: "1rem" }}
                                    variant="outlined"
                                    label="Search for Teams..."
                                  />
                                )}
                              />
                            </div>
                            <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginTop: 30, marginBottom: 30, maxWidth: '100%', padding: 10 }}>
                              <Grid container spacing={2} style={{ justifyContent: "center" }}>
                                {selectedTeamMembersList.length > 0 && (
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Paper elevation={3} className={classes.membersCntr}>
                                      <div className={classes.memCtr}>
                                        {selectedTeamMembersList.map((item, index) => {
                                          return (
                                            <React.Fragment key={index}>
                                              {item.members.length > 0 && (
                                                <div className={classes.teamWrapper}>
                                                  <div className={classes.teamName}>
                                                    {item.team} - Team
                                                  </div>
                                                  <div>{renderTeamListChip(item.members)}</div>
                                                </div>
                                              )}
                                            </React.Fragment>
                                          );
                                        })}
                                      </div>
                                    </Paper>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                      </div>
                      <div style={{ backgroundColor: '#f5f5f5', paddingBottom: 10 }}>
                        <FormControl className={classes.formControl} style={{ maxWidth: '100%' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={setPublishLevel.autoEnrollForNewUsers}
                                onChange={handleChangeLevel}
                                name="autoEnrollForNewUsers"
                                color="primary"
                                disabled={checkDesignNewJoiners}
                              />
                            }
                            label="Induction training course for new joiners"
                          />
                          <FormHelperText>{getHelperText('autoenroll')}</FormHelperText>
                        </FormControl>
                        {setPublishLevel.autoEnrollForNewUsers ? (
                          <FormControl className={classes.formControl}>
                            <NumberInput type="duration" label="Duration" defaultValue={completionDuration} setValue={recieveAndSetCompletionDuration} helperText="enter maximum number of days to complete the course" />
                          </FormControl>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ) : null}
                  {/* FOR DESIGNATION BASED PUBLISH CHANGES START*/}
                  {showDesignationBased &&
                    <div style={{ backgroundColor: '#f5f5f5', paddingBottom: 10 }}>
                      <FormControl className={classes.formControl} style={{ maxWidth: '100%' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={setPublishLevel.designationBased}
                              onChange={handleChangeLevel}
                              name="designationBased"
                              color="primary"
                            />
                          }
                          label="Assign courses in accordance with audience's role/designation"
                        />
                        <FormHelperText>{getHelperText('designationBased')}</FormHelperText>
                      </FormControl>
                      {setPublishLevel.designationBased ? (
                        <React.Fragment>
                          <FormControl className={classes.formControl} style={{ maxWidth: '100%' }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkDesignNewJoiners}
                                  onChange={handleDesignationNewJoiners}
                                  name="assign to new joiners"
                                  color="primary"
                                  size="small"
                                />
                              }
                              label="Assign to new joiners"
                            />
                            <FormHelperText>{getHelperText('assignToNewJoinersInDesignations')}</FormHelperText>
                          </FormControl>
                          {!defDesignationsMandateDuration ? (
                            <FormControl className={classes.formControl}>
                              <NumberInput type="duration" label="Duration" defaultValue={designationMandateDuration} setValue={setDesignationMandateDuration} helperText="enter maximum number of days to complete the course" />
                            </FormControl>
                          ) : null}
                          <div className={classes.autoCompWrapper} style={{ marginTop: 20, padding: 10 }}>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              loading={designationsLoader}
                              loadingText="fetching Designations..."
                              options={designationsList && designationsList.length > 0 ? designationsList : []}
                              disableCloseOnSelect
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(e, data) => {
                                let selectedData = data.filter((data) => data && data.name !== 'Select All')
                                handleDesignationChange(selectedData);
                              }}
                              limitTags={2}
                              value={selectedDesignations.filter((data) => data.id !== '')}
                              getOptionLabel={option => option.name}
                              renderOption={(option, { selected }) => {
                                return (
                                  <React.Fragment>
                                    {option && option.name === 'Select All' ? (
                                      <React.Fragment>
                                        <ListItemIcon>
                                          <Checkbox
                                            onChange={checkAllDesignChange}
                                            id='selectall'
                                            size="small"
                                            style={{ marginRight: 8, color: "#2e8eec" }}
                                            checked={designationsList.length > 0 && selectedDesignations.length === designationsList.length}
                                            indeterminate={selectedDesignations.length > 0 && selectedDesignations.length < designationsList.length}
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            <Typography className={classes.listItemWrapper} component="span">Select All</Typography>
                                          }
                                        />
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          checked={selected}
                                          style={{ marginRight: 8, color: "#2e8eec" }}
                                        />
                                        {option.name}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                );
                              }}
                              style={{ width: 500 }}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  style={{ color: "red", fontSize: "1rem" }}
                                  variant="outlined"
                                  placeholder="Designations"
                                  label="Search for Designations"
                                />
                              )}
                            />
                          </div>
                        </React.Fragment>
                      ) : null}
                    </div>
                  }
                  {/* FOR DESIGNATION BASED PUBLISH CHANGES END*/}
                </React.Fragment>
              )}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={setPublishLevel.myathina}
                    onChange={handleChangeLevel}
                    name="myathina"
                    color="primary"
                  />
                }
                label="To myAthina Website"
              />
              <FormHelperText>This course will be published to everyone who is searching on our external website, myathina.com. The course will be submitted for the review. myAthina team will get back to you with the results within 3-4 business days. Thank you!</FormHelperText> */}
            </React.Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={30} className={classes.buttonProgress} />
          ) : null}
          <Button onClick={publishCourseonClick} disabled={inProgress} color="primary" variant="contained">
            {defSelectedDesignations && defSelectedDesignations.length ? 'Update' : 'Confirm'}
          </Button>
          <Button onClick={handleClose} disabled={inProgress} color="primary" variant="outlined" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(CoursePublishPopup);

import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import PublishIcon from '@material-ui/icons/Publish'
import BlockIcon from '@material-ui/icons/Block'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import { convertUTCTimeZone } from '../../Helpers/basic'
import { style } from '../../Styles/theme'
import DiscussionPreview from '../Common/OpeningDialogView/discussionPreview'
import AddDiscussion from './addDiscussion'
import DeleteDiscussion from './deleteConfirm'
import DiscussionPublish from './publishPopup'
import responseIcon from '../../static/responseIcon.png'
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-81px',
    width: '100%'
  },
  title: {
    fontSize: '23px',
    fontWeight: '500',
    color: style.fontColour._blue,
  },
  iconWrapper: {
    marginRight: '8px',
    fontSize: '30px',
    marginTop: '14px',
    border: '4px solid #ECEBFD',
    backgroundColor: '#ECEBFD',
    borderRadius: '6px',
    color: style.fontColour._blue,
  },
  icon: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
    marginTop: '11px',
    marginRight: '10px'
  },
  asIcon: {
    textAlign: 'left',
    marginTop: '10px'
  },
  listContainer: {
    marginTop: '1rem',
  },
  itemInfo: {
    padding: '0.2rem',
    fontSize: style.fontSize._smallDesc,
    color: style.fontColour._naviBlue
  },
  itemTitle: {
    fontSize: style.fontSize._sideHeading,
    color: style.fontColour._blue,
    padding: "0.2rem 0 0.2rem 0 ",
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    flexGrow: 1,
    marginTop: '10px'
  },
}))

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 11,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '4px 4px',
  },
}))(Badge);

function DiscussionList(props) {
  const classes = useStyles()
  const discussionsData = props.discussionList ? props.discussionList : []
  const [previewData, setPreivewData] = React.useState([])
  const [openPreview, setOpenPreview] = React.useState(false)
  const [openEditDiscussion, setEditDiscussionMode] = React.useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)
  const [openPublishPopup, setOpenPublishPopup] = React.useState(false)
  const [openUnPublishPopup, setOpenUnPublishPopup] = React.useState(false)

  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]
  const courseOpenedMode = getCourseOpenModeType();


  const onDiscussionClick = (data) => {
    if (base64EncodeValue) {
      props.history.push(`/discussions/${props.match.params.courseId}/${data.id}?${base64EncodeValue}`)
    } else {
      props.history.push(`/discussions/${props.match.params.courseId}/${data.id}`)
    }
  }

  const onPreviewTrigger = (data) => {
    setPreivewData(data)
    setOpenPreview(true)
  }

  const closePreview = () => {
    setPreivewData([])
    setOpenPreview(false)
  }

  const triggerDiscussionEdit = (data) => {
    setPreivewData(data)
    setEditDiscussionMode(true)
  }

  const closeDiscussionEdit = () => {
    setPreivewData([])
    setEditDiscussionMode(false)
  }

  const onDeleteTrigger = (data) => {
    setPreivewData(data)
    setOpenDeleteConfirm(true)
  }

  const closeDeletePopup = () => {
    setPreivewData([])
    setOpenDeleteConfirm(false)
  }

  const onPublishTrigger = (data) => {
    setPreivewData(data)
    setOpenPublishPopup(true)
  }

  const closePublishPopup = () => {
    setPreivewData([])
    setOpenPublishPopup(false)
  }

  const onUnPublishTrigger = (data) => {
    setPreivewData(data)
    setOpenUnPublishPopup(true)
  }

  const closeUnpublishPopup = () => {
    setPreivewData([])
    setOpenUnPublishPopup(false)
  }

  const dateFormat = (due_days) => {
    return (convertUTCTimeZone(due_days))
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {!openEditDiscussion ? (
            <Paper className={classes.paper}>
              <Typography className={classes.title} aria-hidden="true">Discussions</Typography>
              {discussionsData && discussionsData.length ? (
                <React.Fragment>
                  <List>
                    {discussionsData && discussionsData.length && discussionsData.map((discussionsData, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <Divider aria-hidden="true" />
                          <QuestionAnswerIcon className={classes.iconWrapper} />
                          <Grid container className={classes.listContainer} >
                            <Grid item sm={6} xs={12} md={9} lg={9} className={classes.itemContainer}>
                              <span className={classes.itemTitle} >
                                {discussionsData.published ? (
                                  <Link component="button" onClick={() => { onDiscussionClick(discussionsData) }} style={{ textDecoration: 'none', cursor: 'pointer', fontSize: '20px', fontWeight: '500' }}>
                                    {discussionsData.title && discussionsData.title.length > 75 ? (
                                      discussionsData.title.substr(0, 70) + '...'
                                    ) : (
                                      discussionsData.title && discussionsData.title
                                    )}
                                  </Link>
                                ) : (
                                  <React.Fragment>
                                    {discussionsData.title && discussionsData.title.length > 75 ? (
                                      discussionsData.title.substr(0, 70) + '...'
                                    ) : (
                                      discussionsData.title && discussionsData.title
                                    )}
                                  </React.Fragment>
                                )}
                              </span>
                              {discussionsData.published === true ? (
                                <Tooltip title="Published">
                                  <CheckCircleRoundedIcon aria-label="discussion published" style={{ marginLeft: '11px', marginBottom: '-3px', fontSize: 20, color: 'rgb(107, 199, 135)' }} />
                                </Tooltip>
                              ) : null}
                              <div className={classes.itemInfo}>
                                Start Date: {discussionsData.start_date ? dateFormat(discussionsData.start_date) : "NA"} &nbsp;&nbsp; Due Date: {dateFormat(discussionsData.end_date)} &nbsp;&nbsp; {discussionsData.points ? (`Pts: ${discussionsData.points}`) : null} &nbsp;&nbsp;
                              </div>
                            </Grid>
                            <Grid item sm={6} xs={12} md={3} lg={3} className={classes.itemContainer}>
                              <div>
                                {discussionsData.published === false ? (
                                  <Tooltip title="Publish">
                                    <IconButton onClick={() => { onPublishTrigger(discussionsData) }} disabled={courseOpenedMode === 'visit_mode'} className={classes.icon}>
                                      <PublishIcon style={{ fontSize: 17 }} />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Unpublish">
                                    <IconButton onClick={() => { onUnPublishTrigger(discussionsData) }} disabled={courseOpenedMode === 'visit_mode'} className={classes.icon}>
                                      <BlockIcon style={{ fontSize: 17 }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Preview">
                                  <IconButton onClick={() => { onPreviewTrigger(discussionsData) }} className={classes.icon}>
                                    <VisibilityIcon style={{ fontSize: 17 }} />
                                  </IconButton>
                                </Tooltip>
                                {discussionsData.published === true ? (
                                  <IconButton disabled={discussionsData.published || courseOpenedMode === 'visit_mode'} aria-label="discussion Edit" className={classes.icon}>
                                    <EditIcon style={{ fontSize: 17 }} />
                                  </IconButton>
                                ) : (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={() => { triggerDiscussionEdit(discussionsData) }} disabled={courseOpenedMode === 'visit_mode'} className={classes.icon}>
                                      <EditIcon style={{ fontSize: 17 }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => { onDeleteTrigger(discussionsData) }} disabled={courseOpenedMode === 'visit_mode'} className={classes.icon} >
                                    <DeleteIcon style={{ fontSize: 17 }} />
                                  </IconButton>
                                </Tooltip>
                                <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                                  <p>{`discussion responses count ${discussionsData.reply_count}`}</p>
                                </div>
                                <Tooltip title={`${discussionsData.reply_count} Responses`} aria-hidden="true">
                                  <StyledBadge badgeContent={discussionsData.reply_count} color='secondary'>
                                    <img src={responseIcon} alt="response count" style={{ height: '24px', marginTop: '11px' }} aria-hidden="true" />
                                  </StyledBadge>
                                </Tooltip>
                              </div>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider aria-hidden="true" />
                      </React.Fragment>
                    ))}
                  </List>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography style={{ marginTop: '26px' }}>No discussions added yet!</Typography>
                  <Button
                    variant="contained"
                    aria-label="add discussion"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={props.triggerAddDiscussion}
                  >
                    Discussion
                  </Button>
                </React.Fragment>
              )}
            </Paper>
          ) : null}
        </Grid>
      </Grid>
      {openPreview ? (
        <DiscussionPreview
          openPreview={openPreview}
          closePreview={closePreview}
          previewDetails={previewData}
        />
      ) : null}
      {openEditDiscussion ? (
        <div style={{ marginTop: '-100px' }}>
          <AddDiscussion
            listAllDiscussions={props.listAllDiscussions}
            cancelAddDiscussion={closeDiscussionEdit}
            history={props.history}
            match={props.match}
            discussionDetails={previewData}
          />
        </div>
      ) : null}
      {openDeleteConfirm ? (
        <DeleteDiscussion
          history={props.history}
          match={props.match}
          discussionData={previewData}
          openDeleteConfirm={openDeleteConfirm}
          closeDeleteDialog={closeDeletePopup}
          listAllDiscussions={props.listAllDiscussions}
        />
      ) : null}
      {openPublishPopup ? (
        <DiscussionPublish
          match={props.match}
          history={props.history}
          listAllDiscussions={props.listAllDiscussions}
          discussionData={previewData}
          openPublishPopup={openPublishPopup}
          closePublish={closePublishPopup}
        />
      ) : null}
      {openUnPublishPopup ? (
        <DiscussionPublish
          match={props.match}
          history={props.history}
          listAllDiscussions={props.listAllDiscussions}
          discussionData={previewData}
          openMode='unPublish'
          openUnPublishPopup={openUnPublishPopup}
          closeUnpublish={closeUnpublishPopup}
        />
      ) : null}
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(DiscussionList);

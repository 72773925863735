import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LeftMenu from '../AppLayout/leftMenu';
import ClassList from './classList';

function MyVideos(props) {
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="md">
                <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <LeftMenu history={props.history} />
                    </Grid>
                </Grid>
            </Container>
            <div style={{ marginTop: '5rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ClassList />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {}
}

export default connect(
    mapStateToProps, {}
)(MyVideos);
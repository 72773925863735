import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import Chip from '@material-ui/core/Chip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
//local imports
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { fetchOrgMembersListing, assignMentorToStudent } from '../../store/ManageGroups/actionCreator';
import { SUCCESS_ASSIGN_MENTOR_TO_STUDENT, SUCCESS_UPDATE_MENTOR_TO_STUDENT } from '../Common/Constants/successMessages'
import { ERROR_FETCHING_GRP_MENTORS_INFO, ERR_ASSIGN_MENTOR_TO_STUDENT } from '../Common/Constants/errorMessages';


const useStyles = makeStyles(theme => ({
  highlightText: {
    fontSize: '1rem',
    fontWeight: 700
  },
  buttonProgress: {
    color: style.fontColour._blue
  },
  headerWrapper: {
    color: style.fontColour._blue
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  mobileForm: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  button: {
    color: style.fontColour._blue
  },
  chipWrapper: {
    color: style.fontColour._blue,
    borderColor: style.fontColour._blue,
    marginRight: '10px',
    marginBottom: '10px'
  },
  iconSelected: {
    marginRight: 5,
    marginLeft: -2,
    color: '#6BC787',
    fontSize: 20
  }
}));

function ManageMentorPopup(props) {
  const classes = useStyles()
  const scrollView = useMediaQuery('(max-width:600px)');
  const userData = props && props.assignMentorData && Object.keys(props.assignMentorData) && Object.keys(props.assignMentorData).length ? props.assignMentorData : {}
  const alreadyHasMentor = props && props.assignMentorData && props.assignMentorData.has_mentor
  const open = props.open
  const [inProgress, setInProgress] = useState(false)
  const [mentorEmptyCheck, setMentorEmptyCheck] = useState(false)
  const [mentorsListingData, setMentorsListingData] = useState([])

  //for mentor dropdown selection
  const [selectedMentor, setMentorSelection] = useState()
  const [mentorInputValue, setMentorInputValue] = useState()
  const [assignedMentorsArr, setAssignedMentorsArr] = useState([])
  const [mentorLengthCheck, setMentorLengthCheck] = useState(0)
  
  //for dropdown handle change
  const mentorsSet = () => {
    let mentorArr = [...assignedMentorsArr]
    if(!mentorArr.includes(selectedMentor) && selectedMentor && selectedMentor.length){
      mentorArr.push(selectedMentor)
    }
    setMentorLengthCheck(mentorArr && mentorArr.length)
    setAssignedMentorsArr(mentorArr)
    setMentorEmptyCheck(false)
    setMentorSelection('')
    setMentorInputValue('')
  }
  useEffect(() => {
    mentorsSet()
  },[selectedMentor])

   //for removing student from assigned list
   const handleMentorRemoveClick = (name) => {
    let mentorArr = [...assignedMentorsArr]
    const mentorIndex = mentorArr.indexOf(name)
    mentorArr.splice(mentorIndex,1)
    setAssignedMentorsArr(mentorArr)
    setMentorLengthCheck(mentorArr && mentorArr.length)
  };


  const successMentorsData = res => {
    setMentorsListingData(res && res.data && res.data)
  }

  const failureMentorsData = err => {
    setMentorsListingData([])
    props.showSnackBar({
      state: false,
      message: ERROR_FETCHING_GRP_MENTORS_INFO,
      type: 'error'
    })
  }

  useEffect(() => {
    //for setting up the already assigned mentors default state
    let alredyAssignedMent = props && props.assignMentorData && Object.keys(props.assignMentorData) && Object.keys(props.assignMentorData).length ? props.assignMentorData.mentor_data : []
    let mentorArrSet = []
    alredyAssignedMent && alredyAssignedMent.length && alredyAssignedMent.filter(function(mentor){
      return mentorArrSet.push(`${mentor.mentor_name} (${mentor.email})`)
    })
    setAssignedMentorsArr(mentorArrSet)
    setMentorLengthCheck(mentorArrSet && mentorArrSet.length)

    const payload = {
      group: 'Mentor'
    }
    fetchOrgMembersListing(payload, successMentorsData, failureMentorsData)
  }, [])

  const handleClose = () => {
    props.closeAssignMentPopup()
    setMentorEmptyCheck(false)
    setMentorSelection('')
    setMentorInputValue('')
    setAssignedMentorsArr([])
    setMentorLengthCheck(0)
  };

  const successAssignMentor = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: userData.mode === 'edit' ? SUCCESS_UPDATE_MENTOR_TO_STUDENT : SUCCESS_ASSIGN_MENTOR_TO_STUDENT,
      type: 'success'
    })
    handleClose()
    props.fetchStudentDataList('Student')
  }
  const failureAssignMentor = err => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: ERR_ASSIGN_MENTOR_TO_STUDENT,
      type: 'error'
    })
  }

  const assignMentor = () => {
    let mentorIds = []
    assignedMentorsArr && assignedMentorsArr.length && assignedMentorsArr.forEach((item)=>{
      let emailSplit = item.split('(')
      let emailId = emailSplit && emailSplit.length && emailSplit[1] && emailSplit[1].slice(0,-1)
      let mentorEntry = mentorsListingData && mentorsListingData.find((data) => data.email === emailId)
      mentorIds.push(mentorEntry && mentorEntry.id)
    })
    if(!alreadyHasMentor && mentorIds && !mentorIds.length){
      setMentorEmptyCheck(true)
    }else{
      setInProgress(true)
      setMentorEmptyCheck(false)
      const payload = {
        student_id: userData.id,
        mentor_id: mentorIds
      }
      assignMentorToStudent(payload, successAssignMentor, failureAssignMentor)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="manage mentor"
        aria-describedby="managing mentors for student"
      >
        <DialogTitle id="Assing Mentors Process" className={classes.headerWrapper}>Manage Mentors For Student</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{userData.mode === 'edit' ? 'Change Mentor' : 'Assign Mentor'}</span> for <span className={classes.highlightText}>{userData.student_name && userData.student_name}{` (${userData.email})`}</span>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          { assignedMentorsArr && assignedMentorsArr.length ? (
            <React.Fragment>
              { assignedMentorsArr.map((row, index) => (
                <Chip
                  key={index}
                  className={classes.chipWrapper}
                  label={row}
                  variant="outlined"
                  deleteIcon={<HighlightOffIcon style={{ color: style.fontColour._blue }} />}
                  onClick={() => {handleMentorRemoveClick(row)}}
                  onDelete={() => {handleMentorRemoveClick(row)}}
                />
              ))}
            </React.Fragment>
          ) : null }
        </DialogContent>
        <div style={{ marginLeft: '16px', width: scrollView ? '250px' : '400px', marginTop: '25px' }}>
          <Autocomplete
            value={selectedMentor}
            onChange={(event, newValue) => {
              setMentorSelection(newValue);
            }}
            inputValue={mentorInputValue}
            onInputChange={(event, newInputValue) => {
              setMentorInputValue(newInputValue);
            }}
            id="mentorSelection"
            options={mentorsListingData.map((option) => `${option.mentor_name} (${option.email})`)}
            getOptionDisabled={(option) => assignedMentorsArr && assignedMentorsArr.indexOf(option) > -1 }
            renderOption={(option) => (
              <React.Fragment>
                <DoneIcon
                  className={classes.iconSelected}
                  style={{ visibility: assignedMentorsArr && assignedMentorsArr.indexOf(option) >-1 ? 'visible' : 'hidden' }}
                />
                <div className={classes.text}>
                  {option}
                </div>
              </React.Fragment>
            )}
            renderInput={(params) => <TextField
              {...params}
              label="Mentor Selection"
              error={mentorEmptyCheck}
              variant="outlined"
              placeholder="Search mentor and select from dropdown"
              helperText="Select mentor for assigning to a student!"
            />}
          />
        </div>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={22} className={classes.buttonProgress} />
          ) : null}
           { mentorLengthCheck && mentorLengthCheck > 2 ? (
            <div style={{ textAlign: 'center', fontSize: '0.8rem', color: 'red' }}>{`*One student can have only two mentors`}</div>
          ) : null }
          <Button onClick={assignMentor} disabled={inProgress || (mentorLengthCheck > 2) } className={classes.button}>
            {userData.mode === 'edit' ? 'Update' : 'Assign'}
          </Button>
          <Button onClick={handleClose} disabled={inProgress || (mentorLengthCheck > 2) } className={classes.button} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(ManageMentorPopup);
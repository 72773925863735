import React from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ListSentNotices from './listSentNotices'
import LeftMenu from '../../AppLayout/leftMenu'

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(8),
  }
}));
function SentNotices(props){
    const classes = useStyles();
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <Container className={classes.cardGrid} maxWidth="md">
            <Grid item style={{ maxWidth: '14%', flexBasis: '14%' }}>
              <LeftMenu history={props.history} />
            </Grid>
          </Container>
        </main>
        <div>
          <ListSentNotices match={props.match} history={props.history}/>
        </div>
      </React.Fragment>
    );
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, {}
)(SentNotices);

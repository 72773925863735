import ansrLogo from '../static/ansrLogoBlack.png'
import ceLogo from '../static/ce.png'
import gmuLogo from '../static/gmulogo.jpg'
import prepLogo from '../static/prep-logo-white.svg'
import zopsmartLogo from '../static/zopsmart_logo.png'
import falabellaLogo from '../static/falabella_logo.png'
import rmblLogo from "../static/rmbl_logo_md.png";
import goniyoLogo from '../static/goniyo_logo.png'
import caseysLogo from '../static/caseys.svg'
import freoLogo from '../static/freo.svg'

const jwt = require('jsonwebtoken');

export const getEnv = () => {
  switch (window.location.origin) {
    case 'http://localhost:3000':
      return 'dev'
    case 'http://myathina-hosting.s3-website.ap-south-1.amazonaws.com':
      return 'test'
    case 'http://myathina-hosting-clone.s3-website.ap-south-1.amazonaws.com':
      return 'clone'
    case 'https://hosting.myathina.com':
    case 'https://goniyo.hosting.myathina.com':
    case 'https://continualengine.hosting.myathina.com':
    case 'https://sandbox.hosting.myathina.com':
    case 'https://prep.hosting.myathina.com':
    case 'https://zopsmart.hosting.myathina.com':
    case 'https://falabella.hosting.myathina.com':
    case 'https://sandboxacademy.hosting.myathina.com':
    case 'https://sandboxacademy.myathina.com':
    case 'https://rmbl.myathina.com':
    case 'https://caseys.myathina.com':
    case 'https://demo.hosting.myathina.com':
    case 'https://freo.hosting.myathina.com':
    case 'https://ansrsource.hosting.myathina.com':
      return 'prod'
    default:
      return 'dev'
  }
}

export const openInNewTab = (url = '/') => {
  const win = window.open(url, '_blank');
  win.focus();
}
export const redirectTo = (url = '/', newTab = false) => {
  if (newTab) {
    openInNewTab(url)
  } else {
    window.location = url
  }
}

export const generateJwtToken = obj => {
  return jwt.sign(obj, 'shhhhh')
}

export const convertUTCTimeZone = utcStr => {
  var option = { year: 'numeric', month: 'short', day: "2-digit" }
  var indiaTime = new Date(utcStr).toLocaleString("en-US", option);
  return indiaTime.toLocaleString();
}

export const convertUTCDatewithTime = utcStr => {
  var option = { year: 'numeric', month: 'short', day: "2-digit", hour: '2-digit', minute: '2-digit' }
  var indiaTime = new Date(utcStr).toLocaleString("en-US", option);
  return indiaTime.toLocaleString();
}

export const getUrlParams = (url, param) => {
  let hashes = url.slice(url.indexOf('?') + 1).split('&')
  let params = []

  hashes.forEach(hash => {
    let [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params[param]
}

export const bytesToSize = bytes => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  /*eslint radix: ["error", "as-needed"]*/
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const getDomainInfo = () => {
  switch (window.location.origin) {
    case 'http://ansr.hosting.localhost:3000':
      return {
        domainName: 'ansr.hosting.localhost',
        hostName: 'http://ansr.localhost:3001',
        hostingURL: 'http://ansr.hosting.localhost:3000',
        renderingURL: 'http://ansr.rendering.localhost:3000/mdb-ab050520',
        domainTokenLabel: `ansr_localhost_token`,
        domainUserLabel: `ansr_localhost_user`,
        type: 'b2b',
        logo: ansrLogo,
        client: 'ansr',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://ansr.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://ansr.moocpedia.com',
        hostingURL: 'https://ansr.hosting.moocpedia.com',
        renderingURL: 'https://ansr.rendering.moocpedia.com/mdb-ab050520',
        domainTokenLabel: `ansr_moocpedia_token`,
        domainUserLabel: `ansr_moocpedia_user`,
        type: 'b2b',
        logo: ansrLogo,
        client: 'ansr',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'http://falabella.hosting.localhost:3000':
      return {
        domainName: 'falabella.hosting.localhost',
        hostName: 'http://falabella.localhost:3001',
        hostingURL: 'http://falabella.hosting.localhost:3000',
        renderingURL: 'http://falabella.rendering.localhost:3000/mdb-ag140920',
        domainTokenLabel: `falabella_localhost_token`,
        domainUserLabel: `falabella_localhost_user`,
        type: 'b2b',
        logo: falabellaLogo,
        client: 'falabella',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://falabella.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://falabella.moocpedia.com',
        hostingURL: 'https://falabella.hosting.moocpedia.com',
        renderingURL: 'https://falabella.rendering.moocpedia.com/mdb-ag140920',
        domainTokenLabel: `falabella_moocpedia_token`,
        domainUserLabel: `falabella_moocpedia_user`,
        type: 'b2b',
        logo: falabellaLogo,
        client: 'falabella',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'http://zopsmart.hosting.localhost:3000':
      return {
        domainName: 'zopsmart.hosting.localhost',
        hostName: 'http://zopsmart.localhost:3001',
        hostingURL: 'http://zopsmart.hosting.localhost:3000',
        renderingURL: 'http://zopsmart.rendering.localhost:3000/mdb-ab050520',
        domainTokenLabel: `zopsmart_localhost_token`,
        domainUserLabel: `zopsmart_localhost_user`,
        type: 'b2b',
        logo: zopsmartLogo,
        client: 'zopsmart',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://zopsmart.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://zopsmart.moocpedia.com',
        hostingURL: 'https://zopsmart.hosting.moocpedia.com',
        renderingURL: 'https://zopsmart.rendering.moocpedia.com/mdb-ab050520',
        domainTokenLabel: `zopsmart_moocpedia_token`,
        domainUserLabel: `zopsmart_moocpedia_user`,
        type: 'b2b',
        logo: zopsmartLogo,
        client: 'zopsmart',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://myathina.com',
        hostingURL: 'https://hosting.myathina.com',
        renderingURL: 'https://rendering.myathina.com/mdb-aa050520',
        domainTokenLabel: `myathina_token`,
        domainUserLabel: `myathina_user`,
        type: 'b2c',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://moocpedia.com',
        hostingURL: 'https://hosting.moocpedia.com',
        renderingURL: 'https://rendering.moocpedia.com/mdb-aa050520',
        domainTokenLabel: `moocpedia_token`,
        domainUserLabel: `moocpedia_user`,
        type: 'b2c',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'http://hosting.localhost:3000':
      return {
        domainName: 'hosting.localhost',
        hostName: 'http://localhost:3000',
        hostingURL: 'http://hosting.localhost:3000',
        renderingURL: 'http://rendering.localhost:3000/mdb-aa050520',
        domainTokenLabel: `localhost_token`,
        domainUserLabel: `localhost_user`,
        type: 'b2c',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'http://hosting.myathina.localhost:3000':
      return {
        domainName: 'hosting.localhost',
        hostName: 'http://b2c.myathina.localhost:3001',
        domainTokenLabel: `myathina_token`,
        renderingURL: 'https://rendering.myathina.localhost:3000/mdb-aa050520',
        domainUserLabel: `myathina_user`,
        type: 'b2c',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://ansr.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://ansr.moocpedia.com',
        hostingURL: 'https://ansr.hosting.moocpedia.com',
        renderingURL: 'https://ansr.rendering.moocpedia.com/mdb-ab050520',
        domainTokenLabel: `ansr_moocpedia_token`,
        domainUserLabel: `ansr_moocpedia_user`,
        type: 'b2b',
        logo: ansrLogo,
        client: 'ansr',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://abcuniversity.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://abcuniversity.moocpedia.com',
        hostingURL: 'https://abcuniversity.hosting.moocpedia.com',
        renderingURL: 'https://abcuniversity.rendering.moocpedia.com/mdb-ac050520',
        domainTokenLabel: `abcuniversity_moocpedia_token`,
        domainUserLabel: `abcuniversity_moocpedia_user`,
        type: 'b2b',
        logo: gmuLogo,
        client: 'abcuniversity',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://abcuniversity.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://abcuniversity.moocpedia.com',
        hostingURL: 'https://abcuniversity.hosting.moocpedia.com',
        renderingURL: 'https://abcuniversity.rendering.moocpedia.com/mdb-ac050520',
        domainTokenLabel: `abcuniversity_moocpedia_token`,
        domainUserLabel: `abcuniversity_moocpedia_user`,
        type: 'b2b',
        logo: gmuLogo,
        client: 'abcuniversity',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://falabella.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://falabella.myathina.com',
        hostingURL: 'https://falabella.hosting.myathina.com',
        renderingURL: 'https://falabella.rendering.myathina.com/mdb-ag140920',
        domainTokenLabel: `falabella_myathina_token`,
        domainUserLabel: `falabella_myathina_user`,
        type: 'b2b',
        logo: falabellaLogo,
        client: 'falabella',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://sandbox.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://sandbox.myathina.com',
        hostingURL: 'https://sandbox.hosting.myathina.com',
        renderingURL: 'https://sandbox.rendering.myathina.com/mdb-ae100902',
        domainTokenLabel: `sandbox_myathina_token`,
        domainUserLabel: `sandbox_myathina_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandbox',
        name: "sandbox",
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://goniyo.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://goniyo.myathina.com',
        hostingURL: 'https://goniyo.hosting.myathina.com',
        renderingURL: 'https://goniyo.rendering.myathina.com/mdb-ae070530',
        domainTokenLabel: `goniyo_myathina_token`,
        domainUserLabel: `goniyo_myathina_user`,
        type: 'b2b',
        logo: goniyoLogo,
        client: 'goniyo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://continualengine.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://continualengine.myathina.com',
        hostingURL: 'https://continualengine.hosting.myathina.com',
        renderingURL: 'https://continualengine.rendering.myathina.com/mdb-aa050520',
        domainTokenLabel: `continualengine_myathina_token`,
        domainUserLabel: `continualengine_myathina_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'ce',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://ansrsource.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://ansrsource.myathina.com',
        hostingURL: 'https://ansrsource.hosting.myathina.com',
        renderingURL: 'https://ansrsource.rendering.myathina.com/mdb-aa050520',
        domainTokenLabel: `ansrsource_myathina_token`,
        domainUserLabel: `ansrsource_myathina_user`,
        type: 'b2b',
        logo: ansrLogo,
        client: 'ansrsource',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://zopsmart.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://zopsmart.myathina.com',
        hostingURL: 'https://zopsmart.hosting.myathina.com',
        renderingURL: 'https://zopsmart.rendering.myathina.com/mdb-aa050520',
        domainTokenLabel: `zopsmart_myathina_token`,
        domainUserLabel: `zopsmart_myathina_user`,
        type: 'b2b',
        logo: zopsmartLogo,
        client: 'zopsmart',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://prep.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://prep.myathina.com',
        hostingURL: 'https://prep.hosting.myathina.com',
        renderingURL: 'https://prep.rendering.myathina.com/mdb-prep',
        domainTokenLabel: `prep_myathina_token`,
        domainUserLabel: `prep_myathina_user`,
        type: 'b2b',
        logo: prepLogo,
        client: 'prep',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://testgoniyo.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://testgoniyo.moocpedia.com',
        hostingURL: 'https://testgoniyo.hosting.moocpedia.com',
        renderingURL: 'https://testgoniyo.rendering.moocpedia.com/mdb-ae070530',
        domainTokenLabel: `goniyo_moocpedia_token`,
        domainUserLabel: `goniyo_moocpedia_user`,
        type: 'b2b',
        logo: goniyoLogo,
        client: 'goniyo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://prep.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://prep.moocpedia.com',
        hostingURL: 'https://prep.hosting.moocpedia.com',
        renderingURL: 'https://prep.rendering.moocpedia.com/mdb-prep',
        domainTokenLabel: `prep_moocpedia_token`,
        domainUserLabel: `prep_moocpedia_user`,
        type: 'b2b',
        logo: prepLogo,
        client: 'prep',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://testce.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://testce.moocpedia.com',
        hostingURL: 'https://testce.hosting.moocpedia.com',
        renderingURL: 'https://testce.rendering.moocpedia.com/mdb-ad050520',
        domainTokenLabel: `testce_moocpedia_token`,
        domainUserLabel: `testce_moocpedia_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'ce',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://ansrsource.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://ansrsource.moocpedia.com',
        hostingURL: 'https://ansrsource.hosting.moocpedia.com',
        renderingURL: 'https://ansrsource.rendering.moocpedia.com/mdb-ab050520',
        domainTokenLabel: `ansrsource_moocpedia_token`,
        domainUserLabel: `ansrsource_moocpedia_user`,
        type: 'b2b',
        logo: ansrLogo,
        client: 'ansrsource',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://freo.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://freo.moocpedia.com',
        hostingURL: 'https://freo.hosting.moocpedia.com',
        renderingURL: 'https://freo.rendering.moocpedia.com/mdb-al200821',
        domainTokenLabel: `freo_moocpedia_token`,
        domainUserLabel: `freo_moocpedia_user`,
        type: 'b2b',
        logo: freoLogo,
        client: 'freo',
        name: 'freo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://freo.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://freo.myathina.com',
        hostingURL: 'https://freo.hosting.myathina.com',
        renderingURL: 'https://freo.rendering.myathina.com/mdb-al200821',
        domainTokenLabel: `freo_myathina_token`,
        domainUserLabel: `freo_myathina_user`,
        type: 'b2b',
        logo: freoLogo,
        client: 'freo',
        name: 'freo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://demo.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://demo.moocpedia.com',
        hostingURL: 'https://demo.hosting.moocpedia.com',
        renderingURL: 'https://demo.rendering.moocpedia.com/mdb-aj090621',
        domainTokenLabel: `demo_moocpedia_token`,
        domainUserLabel: `demo_moocpedia_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'demo',
        name: 'demo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true, //for showing the upload from docs library option
      }
    case 'https://demo.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://demo.myathina.com',
        hostingURL: 'https://demo.hosting.myathina.com',
        renderingURL: 'https://demo.rendering.myathina.com/mdb-aj090621',
        domainTokenLabel: `demo_myathina_token`,
        domainUserLabel: `demo_myathina_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'demo',
        name: 'demo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true, //for showing the upload from docs library option
      }
    case 'http://demo.hosting.localhost:3000':
    case 'http://demo.hosting.localhost:3001':
      return {
        domainName: 'demo.hosting.localhost',
        hostName: 'http://demo.localhost:3000',
        hostingURL: 'http://demo.hosting.localhost:3000',
        renderingURL: 'http://demo.rendering.localhost:3001/mdb-aj090621',
        domainTokenLabel: `demo_localhost_token`,
        domainUserLabel: `demo_localhost_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'demo',
        name: 'demo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true, //for showing the upload from docs library option
      }
    case 'http://freo.hosting.localhost:3000':
    case 'http://freo.hosting.localhost:3001':
      return {
        domainName: 'freo.hosting.localhost',
        hostName: 'http://freo.localhost:3000',
        hostingURL: 'http://freo.hosting.localhost:3000',
        renderingURL: 'http://freo.rendering.localhost:3001/mdb-al200821',
        domainTokenLabel: `freo_localhost_token`,
        domainUserLabel: `freo_localhost_user`,
        type: 'b2b',
        logo: freoLogo,
        client: 'freo',
        name: 'freo',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://sandbox.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://sandbox.moocpedia.com',
        hostingURL: 'https://sandbox.hosting.moocpedia.com',
        renderingURL: 'https://sandbox.rendering.moocpedia.com/mdb-ae100902',
        domainTokenLabel: `sandbox_moocpedia_token`,
        domainUserLabel: `sandbox_moocpedia_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandbox',
        name: "sandbox",
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'http://testce.hosting.localhost:3001':
      return {
        domainName: 'testce.hosting.localhost',
        hostName: 'http://testce.localhost:3000',
        hostingURL: 'http://testce.hosting.localhost:3001',
        renderingURL: 'http://testce.rendering.localhost:3002/mdb-ab050520',
        domainTokenLabel: `testce_localhost_token`,
        domainUserLabel: `testce_localhost_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'ce',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true, //for showing the upload from docs library option
      }
    case 'http://ansrsource.hosting.localhost:3001':
      return {
        domainName: 'ansrsource.hosting.localhost',
        hostName: 'http://ansrsource.localhost:3001',
        hostingURL: 'http://ansrsource.hosting.localhost:3000',
        renderingURL: 'http://ansrsource.rendering.localhost:3000/mdb-ab050520',
        domainTokenLabel: `ansrsource_localhost_token`,
        domainUserLabel: `ansrsource_localhost_user`,
        type: 'b2b',
        logo: ansrLogo,
        client: 'ansrsource',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://testce.hosting.localhost:3000':
    case 'http://testce.hosting.localhost:3000':
      return {
        domainName: 'testce.hosting.localhost',
        hostName: 'http://testce.localhost:3001',
        hostingURL: 'http://testce.hosting.localhost:3000',
        renderingURL: 'http://testce.rendering.localhost:3000',
        domainTokenLabel: `testce_localhost_token`,
        domainUserLabel: `testce_localhost_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'ce',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true, //for showing the upload from docs library option
      }
    case 'https://sandbox.hosting.localhost:3000':
      return {
        domainName: 'sandbox.hosting.localhost',
        hostName: 'http://sandbox.localhost:3001',
        hostingURL: 'http://sandbox.hosting.localhost:3000',
        renderingURL: 'http://sandbox.rendering.localhost:3000',
        domainTokenLabel: `sandbox_localhost_token`,
        domainUserLabel: `sandbox_localhost_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandbox',
        name: "sandbox",
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      }
    case 'https://sandboxacademy.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://sandboxacademy.moocpedia.com',
        hostingURL: 'https://sandboxacademy.hosting.moocpedia.com',
        renderingURL: 'https://sandboxacademy.rendering.moocpedia.com/mdb-ah051020',
        domainTokenLabel: `sandboxacademy_moocpedia_token`,
        domainUserLabel: `sandboxacademy_moocpedia_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandboxacademy',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://sandboxacademy.hosting.moocpedia.com':
      return {
        domainName: 'moocpedia.com',
        hostName: 'https://sandboxacademy.moocpedia.com',
        hostingURL: 'https://sandboxacademy.hosting.moocpedia.com',
        renderingURL: 'https://sandboxacademy.rendering.moocpedia.com/mdb-ah051020',
        domainTokenLabel: `sandboxacademy_moocpedia_token`,
        domainUserLabel: `sandboxacademy_moocpedia_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandboxacademy',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://sandboxacademy.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://sandboxacademy.myathina.com',
        hostingURL: 'https://sandboxacademy.hosting.myathina.com',
        renderingURL: 'https://sandboxacademy.rendering.myathina.com/mdb-ah051020',
        domainTokenLabel: `sandboxacademy_myathina_token`,
        domainUserLabel: `sandboxacademy_myathina_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandboxacademy',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
    case 'https://sandboxacademy.hosting.myathina.com':
      return {
        domainName: 'myathina.com',
        hostName: 'https://sandboxacademy.myathina.com',
        hostingURL: 'https://sandboxacademy.hosting.myathina.com',
        renderingURL: 'https://sandboxacademy.rendering.myathina.com/mdb-ah051020',
        domainTokenLabel: `sandboxacademy_myathina_token`,
        domainUserLabel: `sandboxacademy_myathina_user`,
        type: 'b2b',
        logo: ceLogo,
        client: 'sandboxacademy',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      };
    case "https://rmbl.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://rmbl.moocpedia.com",
        hostingURL: "https://rmbl.moocpedia.com",
        renderingURL: "https://rmbl.rendering.moocpedia.com/mdb-ai051020",
        domainTokenLabel: `rmbl_moocpedia_token`,
        domainUserLabel: `rmbl_moocpedia_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        client: 'rmbl',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: true,
        enableDocsLibraryUpload: false
      };
    case "http://rmbl.localhost:3000":
      return {
        domainName: "rmbl.localhost",
        hostName: "http://rmbl.localhost:3000",
        hostingURL: "http://rmbl.localhost:3000",
        renderingURL: "http://rmbl.rendering.localhost:3001/mdb-ai051020",
        domainTokenLabel: `localhost_token`,
        domainUserLabel: `localhost_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        client: 'rmbl',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: true,
        enableDocsLibraryUpload: false
      };
    case "http://rmbl.localhost:3001":
      return {
        domainName: "rmbl.localhost",
        hostName: "http://rmbl.localhost:3001",
        hostingURL: "http://rmbl.localhost:3001",
        renderingURL: "http://rmbl.rendering.localhost:3001/mdb-ai051020",
        domainTokenLabel: `localhost_token`,
        domainUserLabel: `localhost_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        client: 'rmbl',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: true,
        enableDocsLibraryUpload: false
      };
    case "https://rmbl.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://rmbl.myathina.com",
        hostingURL: "https://rmbl.myathina.com",
        renderingURL: "https://rmbl.rendering.myathina.com/mdb-ai051020",
        domainTokenLabel: `rmbl_myathina_token`,
        domainUserLabel: `rmbl_myathina_user`,
        type: "b2b",
        logo: rmblLogo,
        name: "rmbl",
        client: 'rmbl',
        hasMyAthinaCorpAcc: false,
        showExternalAssessments: false,
        noticeBoardFeature: true,
        enableDocsLibraryUpload: false
      };
    case "https://caseys.hosting.moocpedia.com":
      return {
        domainName: "moocpedia.com",
        hostName: "https://caseys.hosting.moocpedia.com",
        hostingURL: "https://caseys.hosting.moocpedia.com",
        renderingURL: "https://caseys.rendering.moocpedia.com/mdb-ai051020",
        domainTokenLabel: `caseys_moocpedia_token`,
        domainUserLabel: `caseys_moocpedia_user`,
        type: "b2b",
        logo: caseysLogo,
        name: "caseys",
        client: 'caseys',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      };
    case "http://caseys.hosting.localhost:3001":
      return {
        domainName: "caseys.hosting.localhost",
        hostName: "http://caseys.hosting.localhost:3001",
        hostingURL: "http://caseys.hosting.localhost:3001",
        renderingURL: "http://caseys.rendering.localhost:3001/mdb-ai051020",
        domainTokenLabel: `caseys_localhost_token`,
        domainUserLabel: `caseys_localhost_user`,
        type: "b2b",
        logo: caseysLogo,
        name: "caseys",
        client: 'caseys',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      };
    case "https://caseys.hosting.myathina.com":
      return {
        domainName: "myathina.com",
        hostName: "https://caseys.hosting.myathina.com",
        hostingURL: "https://caseys.hosting.myathina.com",
        renderingURL: "https://caseys.rendering.myathina.com/mdb-ai051020",
        domainTokenLabel: `caseys_myathina_token`,
        domainUserLabel: `caseys_myathina_user`,
        type: "b2b",
        logo: caseysLogo,
        name: "caseys",
        client: 'caseys',
        hasMyAthinaCorpAcc: true,
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: true
      };
    default:
      return {
        domainName: 'moocpedia.com',
        hostName: 'moocpedia.com',
        domainTokenLabel: 'moocpedia_token',
        domainUserLabel: 'moocpedia_user',
        type: 'b2c',
        showExternalAssessments: false,
        noticeBoardFeature: false,
        enableDocsLibraryUpload: false
      }
  }
}

export const getCookie = (name) => {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export const getLoggedInStatus = () => {
  var domainInfo = getDomainInfo();
  let token = getCookie(domainInfo.domainTokenLabel);
  if (token !== '' && token !== undefined && token !== null) {
    return true
  } else {
    return false
    //return true
  }
}

export const getSubdomain = () => {
  const url = window.location.href
  const subDomain = url.slice(url.indexOf('//') + 2).split('.')
  const domainName = subDomain && subDomain.length > 1 ? subDomain[0] : ''
  if (domainName) {
    return `${domainName}_token`
  }
  else {
    return 'token'
  }
}

export const getUserDetails = () => {
  var domainInfo = getDomainInfo();
  var user = getCookie(domainInfo.domainUserLabel);
  if (user != null) {
    return parseJwt(user)
  } else {
    return {
      roles: []
    }
  }
}

export const getUSerType = () => {
  const userDetails = getUserDetails()
  if (userDetails && userDetails.roles && userDetails.roles.indexOf('Author') >= 0) {
    return 'author'
  } else if (userDetails && userDetails.roles && userDetails.roles.indexOf('Admin') >= 0) {
    return 'author'
  } else if (userDetails && userDetails.roles && userDetails.roles.indexOf('Instructor') >= 0) {
    return 'Instructor'
  } else if (userDetails && userDetails.roles && userDetails.roles.indexOf('Mentor') >= 0) {
    return 'Mentor'
  } else {
    return 'student'
  }
}

const validatePercentage = s => {
  return s.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) != null;
}
export const validateAndSetPercentage = perCent => {
  let validInput = false

  const isValidNum = validatePercentage(perCent);
  if (isValidNum && !isNaN(perCent) && perCent > 0 && perCent < 100 && perCent % 1 === 0) {
    validInput = true
  }
  return validInput
}


export const timeDiffCalc = (dateFuture, dateNow) => {

  var seconds = Math.floor((dateFuture - (dateNow)) / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - (days * 24);
  minutes = minutes - (days * 24 * 60) - (hours * 60);
  seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

  let timeDiff = "";
  if (hours > 0) {
    timeDiff = hours + ":" + minutes + ":" + seconds
  } else {
    timeDiff = minutes + ":" + seconds
  }
  return timeDiff;
}

export const openFreshworksWidget = (type) => {
  if (window.FreshworksWidget) {
    const userDetails = getUserDetails()
    window.FreshworksWidget('identify', 'ticketForm', {
      name: `${userDetails.first_name}  ${userDetails.last_name}`,
      email: userDetails.email,
    });
    window.FreshworksWidget('open', 'ticketForm')
    if (type === 'help') {
      const userDetails = getUserDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails.first_name}  ${userDetails.last_name}`,
        email: userDetails.email,
      });
      window.FreshworksWidget('open')
    } else {
      const userDetails = getUserDetails()
      window.FreshworksWidget('identify', 'ticketForm', {
        name: `${userDetails.first_name}  ${userDetails.last_name}`,
        email: userDetails.email,
      });
      window.FreshworksWidget('open', 'ticketForm')
    }
  }
}

export const getCourseOpenModeType = () => {
  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]

  if (base64EncodeValue === 'Y291cnNlX3Zpc2l0X21vZGU=') {
    return 'visit_mode'
  } else if (base64EncodeValue === 'ZWRpdF9jb3Vyc2U=') {
    return 'edit_mode'
  } else if (base64EncodeValue === 'ZnJvbV9teWNvdXJzZXM=') {
    return 'from_mycourses'
  }
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import { find } from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
//FOR FETCHING ORG INSTRUCTORS LIST
import { style, colorStyles } from '../../Styles/theme'
import { getUserDetails } from '../../Helpers/basic';
import { fetchOrgMembersListing } from '../../store/ManageGroups/actionCreator';
import InstructorsLoader from '../Common/skeletonLoaders/manageAuthorsLoader';
import { assignAutorsToCourse, fetchCourseContributors } from '../../store/Authors/actionCreator';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_FETCHING_GRP_INSTRUCTORS_INFO, ERROR_ASSIGN_INSTRUCOTRS_TO_COURSE } from '../Common/Constants/errorMessages';
import { SUCCESS_ASSIGN_INSTRUCTORS_TO_COURSE } from '../Common/Constants/successMessages';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  erromMsg: {
    color: 'red'
  },
  authorCntr: {
    backgroundColor: '#f9f9f9',
    padding: '1rem 1rem 1rem 0',
    marginBottom: '1rem'
  },
  authorList: {
    paddingTop: '2rem'
  },
  header: {
    fontSize: '1.5rem',
    color: '#999',
    fontWeight: 500
  },
  buttons: {
    flexGrow: 1,
    textTransform: 'none',
    marginRight: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    }
  },
  formButtonContainer: {
    textAlign: 'end'
  },
  listItem: {
    backgroundColor: '#f6f6f6',
    marginBottom: '0.2rem',
    borderRadius: '0.5rem'
  },
  avatar: {
    backgroundColor: '#0e76a8',
    color: '#fff',
    fontSize: '0.9rem',
    textTransform: 'uppercase'
  },
}))

function ManageAuthors(props) {
  const classes = useStyles();
  const userDetails = getUserDetails();
  const courseId = props.match.params.courseId ? props.match.params.courseId : null
  const [instructorsList, setInstructorsList] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const [checked, setChecked] = React.useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [update, setUpdate] = useState(false);

  //FOR INSTRUCTORS DATA CALLBACKS
  const successInstructorsData = res => {
    let instructorData = []
    res && res.data && res.data.length && res.data.forEach((instructor) => {
      if (instructor.id !== userDetails.id) {
        instructorData.push(instructor)
      }
    })
    setInProgress(false)
    setInstructorsList(instructorData)
  }
  const failureInstructorsData = err => {
    setInstructorsList([])
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_GRP_INSTRUCTORS_INFO,
        type: 'error'
      }
    )
  }

  //FOR FETCHING SINGLE COURSE already added INSTRUCOTRS LIST BASED ON COURSE ID CALLBACKS
  const successCourseInstrList = res => {
    let alredyAssignedUserIds = [];
    let alreadyAssignedUsers = [];
    if (res && res.data && res.data.length) {
      res.data.forEach((instructor) => {
        if (instructor.id !== userDetails.id) {
          alredyAssignedUserIds.push(instructor.id);
          alreadyAssignedUsers.push(instructor);
        }
      })
      setUpdate(true)
    }
    setChecked(alredyAssignedUserIds);
    setSelectedList(alreadyAssignedUsers);
  }
  const failureCourseInstrList = err => {
    setChecked([]);
    setSelectedList([]);
    setUpdate(false)
  }

  useEffect(() => {
    setInProgress(true)
    const payload = {
      group: 'Instructor'
    }
    //for fetching org instructors list
    fetchOrgMembersListing(payload, successInstructorsData, failureInstructorsData)
    const payload1 = {
      course_id: Number(courseId)
    }
    //for fetching already added course instructors list
    fetchCourseContributors(payload1, successCourseInstrList, failureCourseInstrList)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  //FOR CHECKBOX CHANGE
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    let selList = [];
    newChecked.forEach((item) => {
      let authorInfo = find(instructorsList, { id: item })
      if (authorInfo != null) {
        selList.push(authorInfo);
      }
    })
    setSelectedList(selList);
    setChecked(newChecked);
  };

  const onCloseClick = () => {
    props.onCloseManageAuthors('directClose')
    setSelectedList([]);
    setChecked([]);
  }

  //ASSIGN INSTRUCTORS TO A COURSE CALLBACKS
  const successAssignInstructors = res => {
    setSubmitLoader(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ASSIGN_INSTRUCTORS_TO_COURSE,
        type: 'success'
      }
    );
    props.onCloseManageAuthors('successClose', res && res.instructors_count - 1)
  }
  const failureAssignInstructors = err => {
    setSubmitLoader(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ASSIGN_INSTRUCOTRS_TO_COURSE,
        type: 'error'
      }
    );
  }

  //ON SUBMITTING INSTRUCTORS LIST
  const onSubmitClick = () => {
    const payload = {
      instructor_id: checked,
      course_id: courseId
    }
    setSubmitLoader(true)
    assignAutorsToCourse(payload, successAssignInstructors, failureAssignInstructors)
  }

  //FOR RENDERING SELECTED LIST
  const renderSelectedList = () => {
    let selectedArr = [];
    selectedList && selectedList.length && selectedList.map((selected, index) => {
      let avatarTxt = selected && selected.instructor_name && selected.instructor_name.substring(0, 2);
      selectedArr.push(
        <ListItem className={classes.listItem} key={index}>
          <ListItemAvatar>
            <Avatar className={classes.avatar} alt={selected && selected.instructor_name && selected.instructor_name}>{avatarTxt}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={selected.instructor_name} secondary={selected.email} />
        </ListItem>
      )
    })
    return selectedArr;
  }

  return (
    <Paper className={classes.paper}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Manage Authors page</h1>
      </div>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <div style={{ display: 'flex', float: 'right', marginLeft: '11px', marginBottom: '14px' }}>
        <InfoOutlinedIcon style={{ fontSize: '0.9rem', color: colorStyles.appTextColor, margin: '0.2rem', marginTop: '0.3rem', marginTop: '4px' }} />
        <div style={{ fontSize: '1rem', color: colorStyles.appTextColor }}>The added instructor will be the contributor to this course.</div>
      </div>
      <Grid container>
        <Grid item lg={6} >
          <div className={classes.header}>Instructors</div>
          {inProgress ? (
            <InstructorsLoader />
          ) : (
            <React.Fragment>
              { instructorsList && instructorsList.length ? (
                <List dense className={classes.root}>
                  {instructorsList && instructorsList.length && instructorsList.map((author, index) => {
                    const labelId = `checkbox-list-secondary-label-${author.id}`;
                    let avatarTxt = author && author.instructor_name && author.instructor_name.substring(0, 2);
                    return (
                      <ListItem key={index} button onClick={handleToggle(author.id)}>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar} alt={author && author.instructor_name && author.instructor_name}>{avatarTxt}</Avatar>
                        </ListItemAvatar>
                        <ListItemText id={index} primary={author.instructor_name} secondary={author.email} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            style={{ color: '#2e8eec' }}
                            onChange={handleToggle(author.id)}
                            checked={checked.indexOf(author.id) !== -1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Typography>No Data Found!</Typography>
              )}
            </React.Fragment>
          )}
        </Grid>
        {selectedList && selectedList.length ? (
          <React.Fragment>
            <Grid item lg={6}>
              <div className={classes.header}>Contributors</div>
              <List>
                {renderSelectedList()}
              </List>
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
      <div className={classes.formButtonContainer}>
        <Button className={classes.buttons} disabled={submitLoader || (!update && !checked.length)} variant="contained" onClick={() => { onSubmitClick() }}>
          {submitLoader ? (
            <CircularProgress size={20} style={{ marginRight: 10, color: style.fontColour._blue }} />
          ) : null}
          Submit
        </Button>
        <Button className={classes.buttons} disabled={submitLoader} variant="contained" onClick={() => { onCloseClick() }}> Cancel </Button>      </div>
    </Paper>
  )
}


const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(ManageAuthors);


import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { find, filter } from 'lodash';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { fetchMycourseList } from '../../store/MycourseListing/actionCreator'
import { courseCategory } from '../../store/AddingCourse/actionCreator'
import { ERROR_FETCHING_MYCOURSE_LIST } from '../Common/Constants/errorMessages'
import CourseListLoader from '../Common/skeletonLoaders/myCourseListLoader'
import { courseLevel } from '../../Configs/courseLevels'
import TabView from './tabView'
import { getDomainInfo } from '../../Helpers/basic';
import { deleteCourseWorkingUserInfo } from '../../store/Authors/actionCreator';


function CourseCard(props) {
  const domainInfo = getDomainInfo();
  const [myCourseDetails, setMyCourseDetails] = React.useState({
    published: [],
    unPublished: []
  })
  const [inProgress, setInprogress] = React.useState(true)
  const [courseCategoryList, setCourseCatergoryList] = React.useState([])

  const getSegregatedCourses = results => {
    return {
      published: filter(results, { 'published': true }),
      unPublished: filter(results, { 'published': false })
    }
  }
  const successCallBack = res => {
    const segregatedCourses = getSegregatedCourses(res.results)
    setMyCourseDetails(segregatedCourses)
    setInprogress(false)
  }

  const failureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_MYCOURSE_LIST,
        type: 'error'
      }
    )
  }

  const successCourseCategory = res => {
    setCourseCatergoryList(res.results)
  }

  const failureCourseCategory = error => {
    setCourseCatergoryList([])
  }
  const courseListFetch = () => {
    setInprogress(true)
    fetchMycourseList({}, successCallBack, failureCallBack)
  }

  useEffect(() => {
    courseListFetch()
    courseCategory({}, successCourseCategory, failureCourseCategory)
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
    if (domainInfo.name === 'rmbl') {
      deleteCourseWorkingUserInfo(
        () => { },
        () => { },
        () => { }
      )
    }
  }, [])

  const getCourseLevel = levelId => {
    if (levelId) {
      return find(courseLevel, { 'id': levelId }).name || 'NA'
    }
  }

  const getCourseCategory = categoryId => {
    if (categoryId && courseCategoryList.length) {
      return find(courseCategoryList && courseCategoryList, { 'id': categoryId }).name || 'NA'
    } else {
      return 'NA'
    }
  }

  const onViewCourseClick = courseId => {
    props.history.push(`/mycourse/${courseId}`)
  }

  return (
    <div>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>MyCourses Listing Page</h1>
      </div>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      {inProgress ? (
        <div style={{ paddingRight: '10px', marginTop: '-56px' }}>
          <CourseListLoader />
        </div>
      ) : (
        <Grid container spacing={3} style={{ width: '100%', marginTop: '-40px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TabView
              myCourseDetails={myCourseDetails}
              getCourseCategory={getCourseCategory}
              onViewCourseClick={onViewCourseClick}
              getCourseLevel={getCourseLevel}
              courseListFetch={courseListFetch}
              history={props.history}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(CourseCard);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
//local imports
import { getDomainInfo } from '../../Helpers/basic';
import { colorStyles } from '../../Styles/theme';
import { ERROR_FETCHING_ASSESSMENTS, ERROR_DELETE_ASSESSMENT } from '../Common/Constants/errorMessages';
import { SUCCESS_DELETE_ASSESSMENT } from '../Common/Constants/successMessages';
import { listAssessments, deleAssessment } from '../../store/Assessments/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import Header from './header';
import ListCard from './listCard';
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator';
import AddAssessment from './addAssessment';
import DeleteDialog from './confirmDialog';
import ListingLoader from '../Common/skeletonLoaders/listingLoader';
//FOR FINDING THE CO WORKERS OF THIS COURSE
import { postCourseWorkingUsersInfo } from '../../store/Authors/actionCreator';
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 65
  },
  assessmentList: {
    listStyle: 'none',
    textAlign: 'left',
    padding: 30
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    padding: '10px 30px',
    textAlign: 'left'
  }
}))
function AssessmentList(props) {
  const classes = useStyles();
  const domainInfo = getDomainInfo();
  const [list, setList] = React.useState([]);
  const [inProgress, setInProgress] = React.useState(false);
  const [courseData, setCourseData] = React.useState({});
  const [selectedAssessment, setSelectedAssessment] = React.useState({});
  const [selectedAssessmentId, setSelectedAssessmentId] = React.useState();
  const [addAssessmentMode, setAddAssessmentMode] = React.useState(false);
  const [deleteDiologOpen, setDeleteDiologOpen] = React.useState(false);
  const [listInProgress, setListInProgress] = React.useState(false)
  const courseOpenedMode = getCourseOpenModeType();

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 4; i++) {
      children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader /></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
  }

  const successCallBack = res => {
    setListInProgress(false)
    setList(res.results)
    setInProgress(false)
  }
  const failureCallBack = res => {
    setListInProgress(false)
    setList([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_ASSESSMENTS,
        type: 'error'
      }
    )
  }
  const listAllAssessment = () => {
    setListInProgress(true)
    listAssessments({
      course_id: props.match.params.courseId
    },
      successCallBack,
      failureCallBack)
  }
  const courseSuccessCallBack = res => {
    listAllAssessment()
    setCourseData(res.results)
  }
  const courseFailureCallBack = error => {
    setInProgress(false)
    setListInProgress(false)
    setCourseData({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_ASSESSMENTS,
        type: 'error'
      }
    )
  }

  //FOR FETCHING USER INFO WHO ARE WORKING ON COURSE MODULES CALLBACKS
  const successPostUserInfo = res => { }
  const failurePostUsersInfo = err => { }

  useEffect(() => {
    setListInProgress(true)
    courseDetailsFetch({
      id: props.match.params.courseId
    }, courseSuccessCallBack, courseFailureCallBack)

    //FOR POSTING THE USERS WHO ARE WORKING ON SAME COURSE
    if (courseOpenedMode !== 'visit_mode' && domainInfo.name === 'rmbl') {
      const payload = {
        url: window.location.href,
        id: props.match.params.courseId,
        type: 'courselevelassessments',
        course_id: props.match.params.courseId
      }
      postCourseWorkingUsersInfo(payload, successPostUserInfo, failurePostUsersInfo)
    }
  }, [])

  const triggerAddAssessment = () => {
    setAddAssessmentMode(true)
  }
  const navigateToAssessment = assessmentId => {
    setAddAssessmentMode(true)
    setSelectedAssessmentId(assessmentId)
  }
  const onTriggerDelete = assessment => {
    setSelectedAssessment(assessment)
    setDeleteDiologOpen(true)
  }
  const onCloseDeleteDialog = assessment => {
    setSelectedAssessment({})
    setDeleteDiologOpen(false)
  }
  const successDeleteCallBack = res => {
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_DELETE_ASSESSMENT,
        type: 'success'
      })
    setSelectedAssessment({})
    setDeleteDiologOpen(false)
    listAllAssessment()
  }
  const onAggreeDeleteDialog = assessment => {
    deleAssessment({
      assessment_id: assessment.id
    },
      successDeleteCallBack,
      error => {
        props.showSnackBar(
          {
            state: true,
            message: ERROR_DELETE_ASSESSMENT,
            type: 'error'
          })
        setSelectedAssessment({})
        setDeleteDiologOpen(false)
        listAllAssessment()
      }
    )
  }
  const cancelAddAssessment = () => {
    setAddAssessmentMode(false)
  }
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = list;
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setList({ data });
    },
    nodeSelector: 'li',
    handleSelector: 'a'
  }
  return (
    <div className={classes.root}>
      {addAssessmentMode ? (
        <AddAssessment match={props.match} history={props.history} selectedAssessmentId={selectedAssessmentId} cancelAddAssessment={cancelAddAssessment} />
      ) : (
        <div>
          {!inProgress ? (
            <div>
              <Header courseData={courseData} match={props.match} history={props.history} />
            </div>
          ) : null}
          {listInProgress ? (
            listLoader()
          ) : (
            <React.Fragment>
              <div style={{ flexGrow: 1 }}>
                <Paper elevation={0} className={classes.topButtonNav}>
                  <div>
                    {courseOpenedMode !== 'visit_mode' && <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={triggerAddAssessment}
                    >
                      Add Assessment
                    </Button>}
                  </div>
                </Paper>
                {!list.length ? (
                  <div style={{ marginTop: 30 }}>
                    <div style={{ marginBottom: 10 }}>No Assessment added yet</div>
                    {courseOpenedMode !== 'visit_mode' && <Button
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={triggerAddAssessment}
                    >
                      Add Assessment
                    </Button>}
                  </div>
                ) : null}
                <div>
                  <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                    <h2>Course level assessments listing view</h2>
                  </div>
                  {list && list.length && courseOpenedMode === 'edit_mode' ? (
                    <div style={{ display: 'flex', float: 'right', marginRight: '33px' }}>
                      <InfoOutlinedIcon style={{ fontSize: '0.9rem', color: colorStyles.appTextColor, margin: '0.2rem', marginTop: '0.3rem', marginTop: '4px' }} />
                      <div style={{ fontSize: '1rem', color: colorStyles.appTextColor }}>You cannot edit the added assessments but you can add new assessments or delete the existing assessments.</div>
                    </div>
                  ) : null}
                  <ol className={classes.assessmentList}>
                    {list && list.map((item, index) => (
                      <li key={index} style={{ marginBottom: 10 }}>
                        <ListCard assessment={item} navigateToAssessment={navigateToAssessment} onTriggerDelete={onTriggerDelete} />
                        <a href="#" style={{ display: 'none' }}>Drag</a>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      {deleteDiologOpen ? (
        <DeleteDialog open={deleteDiologOpen} assessment={selectedAssessment} onCloseDeleteDialog={onCloseDeleteDialog} onAggreeDeleteDialog={onAggreeDeleteDialog} />
      ) : null}
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(AssessmentList);

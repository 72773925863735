import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { reject } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
//local imports
import { style } from '../../../Styles/theme';
import { showSnackBar } from '../../../store/AlertMessages/actionCreator';
import { fetchOrgGrpsList } from '../../../store/ManageGroups/actionCreator';
import AlertMessages from '../../Common/AlertMessages';
import { ERROR_SEND_NOTICE, ERROR_UPDATE_NOTICE_MESSAGE } from '../../Common/Constants/errorMessages';
import { SUCCESS_SEND_NOTICE, SUCCESS_UPDATE_NOTICE } from '../../Common/Constants/successMessages'
import TextEditor from '../../Common/TextEditor';
import NoticePreview from './preview';
import { bytesToSize } from '../../../Helpers/basic';
import { apiConfig } from '../../../Configs/apiConfig';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-84px'
  },
  label: {
    marginTop: 15,
    fontSize: '1.5rem',
    transform: 'translate(0, 1.5px) scale(0.75)'
  },
  field: {
    marginTop: 30,
    backgroundColor: '#f5f5f5'
  },
  formControl: {
    minWidth: 231,
  },
  groupWrapper: {
    marginLeft: 50,
    backgroundColor: '#f5f5f5',
    display: 'inline-block',
    padding: 10,
    border: '0.5px solid #ccc'
  },
  sameRow: {
    float: 'left',
    width: 'auto'
  },
  nextRow: {
    clear: 'both',
    width: 'auto'
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    marginLeft: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  loadWrapper: {
    fontSize: '15px',
    color: style.fontColour._blue,
  },
  iconWrapper: {
    color: style.fontColour._blue,
    fontSize: "1.5rem",
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px',
    textAlign: 'left'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    border: '2px solid #ccc',
    marginTop: '4px'
  },
  uploadLabel: {
    marginTop: '5px'
  },
  uploadIcon: {
    marginTop: '-3px',
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
}));

function AddNotice(props) {
  const classes = useStyles();
  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })
  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      sendToList: {
        error: false
      },
      subject: {
        error: false
      },
      noticeMessage: {
        error: false
      }
    }
  );
  const [sendToList, setSendToList] = React.useState({
    everyone: false,
    allStudents: false,
    allMentors: false,
    allInstructors: false,
    selectByGroups: false,
    individual: false
  });
  const defaultSubject = props && props.data && props.data.subject && props.data.subject
  const [noticeSubject, setNoticeSubject] = React.useState(defaultSubject || '');
  const defaultMessage = props && props.data && props.data.message && props.data.message
  const [noticeMessage, setNoticeMessage] = React.useState(defaultMessage || '');
  const [loadingAllGroupInProgress, setLoadingAllGroupInProgress] = React.useState(false);
  const [allGroup, setAllGroup] = React.useState([]);
  const [showGroupSelect, setShowGroupSelect] = React.useState(false);
  const [showIndividualSelect, setShowIndividualSelect] = React.useState(false)
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  const [alreadySelectedGrpIds, setAlreadySelectedGrpIds] = React.useState([])
  const [openPreview, setOpenPreview] = React.useState(false);
  const [loadAllMembersLoader, setLoadAllMembersLoader] = React.useState(false)
  const [allMembersData, setAllMembersData] = React.useState([])
  const [selectedIndividuals, setSelectedIndividuals] = React.useState([])
  //FOR ATTACHEMENT UPLOAD
  const [attachmentFileDetails, setAttachmentFileDetails] = React.useState({});
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState('');
  const [noticeEditMode, setNoticeEditMode] = React.useState(false);


  useEffect(() => {
    if (props && props.data && Object.keys(props.data) && Object.keys(props.data).length) {
      setNoticeEditMode(true)
      if (props.data.sent_to && props.data.sent_to.user_type && Object.keys(props.data.sent_to.user_type) && Object.keys(props.data.sent_to.user_type).length) {
        setSendToList({
          everyone: props.data.sent_to.user_type.everyone ? true : false,
          allStudents: props.data.sent_to.user_type.all_students ? true : false,
          allMentors: props.data.sent_to.user_type.all_mentors ? true : false,
          allInstructors: props.data.sent_to.user_type.all_instructors ? true : false,
          selectByGroups: props.data.sent_to.user_type.select_by_groups ? true : false,
          individual: props.data.sent_to.user_type.individual ? true : false
        })

        //FOR SETTING UP THE INDIVIDUALS IN UPDATE MODE
        if (props.data.sent_to.user_type.individual) {
          setShowIndividualSelect(true)
          setLoadAllMembersLoader(true)
          fetchOrgGrpsList({}, loadAllMembersSuccess, loadAllMembersFailure)
          let dummyData = [
            { id: 3, name: "Dhana Prabhu", email: "dhana.prabhu@continualengine.com", role: "Student" },
            { id: 6, name: "Mentor2 M", email: "mentor2@yopmail.com", role: "Mentor" }
          ]
          setSelectedIndividuals(dummyData)
        }
        //FOR SETTING UP THE SELECTED GROUPS CHECKBOXES IN UPDATE MODE
        if (props.data.sent_to.user_type.select_by_groups) {
          setLoadingAllGroupInProgress(true)
          setShowIndividualSelect(false)
          setShowGroupSelect(true)
          fetchOrgGrpsList({}, loadAllGroupSuccess, loadAllGroupFailure)
          const dummyTeams = [{
            "id": 3,
            "group_title": "Group-3",
            "members_count": 0,
            "group_members": []
          }]
          setSelectedGroups(dummyTeams)
          let defStuCheckedIds = []
          dummyTeams.forEach((data, index) => {
            defStuCheckedIds.push(data.id)
          })
          setAlreadySelectedGrpIds(defStuCheckedIds)
        }
      }
    } else {
      setNoticeEditMode(false)
    }
  }, [props.data])

  //FOR FILE UPLOAD on responses
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }
  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'attachmentfile' && file.size > apiConfig.addingCourse.acceptedReadingFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'attachmentfile') {
            return apiConfig.addingCourse.acceptedReadingFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }
  const onAttachmentUpload = event => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    const attachment = validatedFile('attachmentfile')
    setAttachmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const loadAllGroupSuccess = res => {
    setLoadingAllGroupInProgress(false)
    setAllGroup(res && res.data)
  }
  const loadAllGroupFailure = error => {
    setLoadingAllGroupInProgress(false)
    setAllGroup({})
  }

  const loadAllMembersSuccess = res => {
    setLoadAllMembersLoader(false)
    let members = res.data.flatMap((item) => item.group_members).flat()
    let uniqueResData = members.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
    setAllMembersData(uniqueResData)
  }
  const loadAllMembersFailure = error => {
    setLoadAllMembersLoader(false)
    setAllMembersData([])
  }

  const onChangeSubject = evnt => {
    setNoticeSubject(evnt.target.value)
    setFormFieldStatus(
      {
        sendToList: {
          error: false
        },
        subject: {
          error: false
        },
        noticeMessage: {
          error: false
        }
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
  }
  const handleSendToListChangeChange = (event) => {
    setFormFieldStatus(
      {
        sendToList: {
          error: false
        },
        subject: {
          error: false
        },
        noticeMessage: {
          error: false
        }
      }
    )
    setValidationError({
      error: false,
      errorMessage: ''
    })
    let updatedList = {
      ...sendToList
    }
    if (event.target.checked) {
      if (event.target.name === 'everyone') {
        updatedList = {
          ...updatedList,
          allStudents: false,
          allMentors: false,
          allInstructors: false,
          selectByGroups: false,
          individual: false
        }
        setLoadingAllGroupInProgress(false)
        setAllGroup({})
        setShowGroupSelect(false)
        setShowIndividualSelect(false)
        setSelectedGroups([])
        setAlreadySelectedGrpIds([])
        setSelectedIndividuals([])
      } else {
        updatedList = {
          ...updatedList,
          everyone: false,
          selectByGroups: false,
          individual: false
        }
        setShowGroupSelect(false)
        setShowIndividualSelect(false)
        if (event.target.name === 'selectByGroups') {
          updatedList = {
            ...updatedList,
            everyone: false,
            allStudents: false,
            allMentors: false,
            allInstructors: false,
            selectByGroups: true,
            individual: false
          }
          setLoadingAllGroupInProgress(true)
          setShowIndividualSelect(false)
          setShowGroupSelect(true)
          setSelectedIndividuals([])
          fetchOrgGrpsList({}, loadAllGroupSuccess, loadAllGroupFailure)
        } else {
          if (event.target.name === 'individual') {
            updatedList = {
              ...updatedList,
              everyone: false,
              allStudents: false,
              allMentors: false,
              allInstructors: false,
              selectByGroups: false,
              individual: true
            }
            setShowIndividualSelect(true)
            setLoadAllMembersLoader(true)
            fetchOrgGrpsList({}, loadAllMembersSuccess, loadAllMembersFailure)
          } else {
            setSelectedIndividuals([])
          }
          setAllGroup({})
          setShowGroupSelect(false)
          setSelectedGroups([])
          setAlreadySelectedGrpIds([])
        }
      }
    } else {
      setShowIndividualSelect(false)
      setLoadAllMembersLoader(false)
      if (event.target.name === 'selectByGroups') {
        setLoadingAllGroupInProgress(false)
        setAllGroup({})
        setShowGroupSelect(false)
        setSelectedGroups([])
      }
    }
    setSendToList({ ...updatedList, [event.target.name]: event.target.checked });
  };
  const displayGroupName = (name, count) => {
    return (
      <span>
        {name}
        <span style={{ display: 'block', fontSize: '10px' }}>
          ({count} members)
        </span>
      </span>
    )
  }

  const selectGroups = (evnt, group) => {
    let checkedIdsData = [...alreadySelectedGrpIds]
    let selectedGroupList = [...selectedGroups]
    if (evnt.target.checked) {
      selectedGroupList = [...selectedGroupList, group]
      if (!checkedIdsData.includes(group.id) && group.id) {
        checkedIdsData.push(group.id)
      }
    } else {
      selectedGroupList = reject(selectedGroupList, { id: group.id })
      const rowIdIndex = checkedIdsData.indexOf(group.id)
      checkedIdsData.splice(rowIdIndex, 1)
    }
    setSelectedGroups(selectedGroupList)
    setAlreadySelectedGrpIds(checkedIdsData)
  }

  const showGroupList = () => {
    return (
      <div className={classes.groupWrapper}>
        {allGroup && allGroup.length ? (
          <React.Fragment>
            <List component="nav" aria-label="main mailbox folders">
              {allGroup.map((data, index) => (
                <ListItem key={index} attr={(index) % 3} className={(index) % 3 === 0 ? classes.sameRow : classes.sameRow} style={{ clear: ((index) % 3) ? 'none' : 'both' }}>
                  <div >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(evnt) => { selectGroups(evnt, data) }}
                          checked={alreadySelectedGrpIds.indexOf(data.id) > -1}
                          name={`group_${data.id}`}
                          size="small"
                          style={{ color: style.fontColour._blue }}
                        />
                      }
                      label={displayGroupName(data.group_title, data.members_count)}
                    />
                  </div>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        ) : ('No groups found')}
      </div>
    )
  }

  const handleAddMembers = (members) => {
    setSelectedIndividuals(members);
  }

  const showAllMembersList = () => {
    return (
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Autocomplete
          value={selectedIndividuals.length > 0 ? selectedIndividuals : []}
          onChange={(event, newValue) => {
            handleAddMembers(newValue);
          }}
          multiple
          id="checkboxes-tags-members-demo"
          options={allMembersData && allMembersData.length > 0 ? allMembersData : []}
          getOptionLabel={(option) => option.email}
          getOptionSelected={(option, value) => option.id === value.id}
          className={classes.memberListAutoSuggest}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ color: "red", fontSize: "1rem" }}
              label="Search Members..."
            />
          )}
        />
      </div>
    )
  }
  const onChangeEditorContent = data => {
    setNoticeMessage(data)
  }
  const showPreview = () => {
    setValidationError({
      error: false,
      errorMessage: ''
    })
    let isValidToList = true
    if (!sendToList.everyone && !sendToList.allStudents && !sendToList.allMentors && !sendToList.allInstructors && !selectedGroups.length && !selectedIndividuals.length) {
      isValidToList = false
    }
    const subjectText = document.getElementById('noticeSubject').value
    setFormFieldStatus(
      {
        sendToList: {
          error: !isValidToList
        },
        subject: {
          error: subjectText === ''
        },
        noticeMessage: {
          error: noticeMessage === ''
        }
      }
    )
    if (!isValidToList || subjectText === '' || noticeMessage === '') {
      setValidationError({
        error: true,
        errorMessage: 'Please fill required details!'
      })
    } else if (attachmentFileDetails.fileError) {
      setValidationError({
        error: true,
        errorMessage: 'Please upload supported file only!'
      })
    } else {
      setOpenPreview(true)
    }
  }

  const navigateToSentList = () => {
    if (noticeEditMode) {
      props.closeEditNoticeForm();
      props.fetchNoticesList()
    } else {
      props.history.push(`/sentnotices`)
    }
  }

  const closePreview = () => {
    setOpenPreview(false)
  }
  const sendNoticeSuccess = () => {
    props.showSnackBar(
      {
        state: true,
        message: noticeEditMode ? SUCCESS_UPDATE_NOTICE : SUCCESS_SEND_NOTICE,
        type: 'success'
      }
    )
    setOpenPreview(false)
    navigateToSentList()
  }
  const sendNoticeFailiure = () => {
    props.showSnackBar(
      {
        state: false,
        message: noticeEditMode ? ERROR_UPDATE_NOTICE_MESSAGE : ERROR_SEND_NOTICE,
        type: 'error'
      }
    )
  }
  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Add Notice</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.titleWrapper} aria-hidden="true">{noticeEditMode ? 'Update Notice' : 'Add Notice'}</Typography>
            {validationError.error ? (
              <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block' }}>
                <AlertMessages status="error" message={validationError.errorMessage} />
              </div>
            ) : null}
            <form noValidate autoComplete="off" onSubmit={() => { }}>
              <FormLabel component="legend" style={{
                textAlign: 'left',
                color: formFieldStatus.sendToList.error ? 'red' : '#0000008a'
              }}>Send To:</FormLabel>
              <div style={{ textAlign: 'left' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sendToList.everyone || false}
                      onChange={handleSendToListChangeChange}
                      name="everyone"
                      size="small"
                      style={{ color: style.fontColour._blue }}
                    />
                  }
                  label="Everyone"
                />
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendToList.allStudents || false}
                        onChange={handleSendToListChangeChange}
                        name="allStudents"
                        size="small"
                        style={{ color: style.fontColour._blue }}
                      />
                    }
                    label="All Students"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendToList.allMentors || false}
                        onChange={handleSendToListChangeChange}
                        name="allMentors"
                        size="small"
                        style={{ color: style.fontColour._blue }}
                      />
                    }
                    label="All Mentors"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sendToList.allInstructors || false}
                        onChange={handleSendToListChangeChange}
                        name="allInstructors"
                        size="small"
                        style={{ color: style.fontColour._blue }}
                      />
                    }
                    label="All Instructors"
                  />
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendToList.individual || false}
                          onChange={handleSendToListChangeChange}
                          name="individual"
                          size="small"
                          style={{ color: style.fontColour._blue }}
                        />
                      }
                      label="Individuals"
                    />
                  </div>
                  {showIndividualSelect ? (
                    <React.Fragment>
                      {loadAllMembersLoader ? (
                        <div>
                          <CircularProgress size={25} style={{ marginBottom: '-6px', marginRight: 10, color: style.fontColour._blue }} />
                          <span className={classes.loadWrapper}>Loading Members...</span>
                        </div>
                      ) : showAllMembersList()}
                    </React.Fragment>
                  ) : null}
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendToList.selectByGroups}
                          onChange={handleSendToListChangeChange}
                          name="selectByGroups"
                          size="small"
                          style={{ color: style.fontColour._blue }}
                        />
                      }
                      label="Select by Groups"
                    />
                  </div>
                </div>
                {showGroupSelect ? (
                  <React.Fragment>
                    {loadingAllGroupInProgress ? (
                      <div className={classes.groupWrapper}>
                        <CircularProgress size={25} style={{ marginBottom: '-6px', marginRight: 10, color: style.fontColour._blue }} />
                        <span className={classes.loadWrapper}>Loading Groups...</span>
                      </div>
                    ) : showGroupList()}
                  </React.Fragment>
                ) : ('')}
              </div>
              <TextField
                id="noticeSubject"
                label="Subject"
                helperText="max: 100 chars"
                required
                error={formFieldStatus.subject.error}
                defaultValue={noticeSubject}
                InputLabelProps={{
                  shrink: true,
                  className: classes.label
                }}
                inputProps={{
                  maxLength: 100,
                  className: classes.field
                }}
                style={{
                  width: '100%'
                }}
                onChange={onChangeSubject}
              />
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Typography style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px', color: formFieldStatus.noticeMessage.error ? 'red' : '#0000008a' }}>
                  Message
                  <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                </Typography>
                <TextEditor
                  onChangeEditorContent={onChangeEditorContent}
                  helperText={'type your message here...'}
                  description={noticeMessage}
                />
                {/* {attachmentFileDetails.fileError ? (
                  <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                    {attachmentFileDetails.fileErrorErrorMessage}
                  </Typography>
                ) : null}
                <div style={{ display: 'flex', marginTop: '11px' }}>
                  <span className={classes.uploadLabel} aria-hidden="true">
                    Upload Attachment
                  </span>
                  <IconButton style={{ padding: '2px 8px' }} aria-label="upload attachment" onClick={() => { onFileToUploadClick('attachmentfile') }}>
                    <CloudUploadIcon className={classes.uploadIcon} />
                  </IconButton>
                  {attachmentFileDetails.file && attachmentFileDetails.file.name && uploadedAttachmentPreview ? (
                    <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                      {attachmentFileDetails.file.name.length > 25 ? (
                        attachmentFileDetails.file.name.substr(0, 20) + '....'
                      ) :
                        attachmentFileDetails.file.name
                      }
                    </a>
                  ) : null}
                </div>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <span style={{ color: '#999' }}>Optional, max.size: 10MB, format: pdf, docx, pptx</span>
                </div> */}
                <input type="file" accept=".pptx,.pdf, .docx, .xlsx, .csv" style={{ display: 'none' }} id="attachmentfile" onChange={onAttachmentUpload} />
              </div>
              <Button variant="contained" className={classes.button} onClick={showPreview} disabled={false}>
                {noticeEditMode ? 'Preview & Update Notice' : 'Preview & Send Notice'}
              </Button>
              <Button variant="contained" style={{ marginLeft: 20 }} disabled={false} onClick={navigateToSentList}>Cancel</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {openPreview ? (
        <NoticePreview
          open={openPreview}
          subject={noticeSubject}
          message={noticeMessage}
          closePreview={closePreview}
          sendNoticeSuccess={sendNoticeSuccess}
          sendNoticeFailiure={sendNoticeFailiure}
          sendToList={sendToList}
          selectedGroups={selectedGroups}
          selectedIndividuals={selectedIndividuals}
          onlyPreview={false}
          attachment={attachmentFileDetails}
          attachmentPreview={uploadedAttachmentPreview}
          noticeEditMode={noticeEditMode}
        />
      ) : null}
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AddNotice);

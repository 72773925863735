import React from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";
import { getLoggedInStatus } from './Helpers/basic';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        getLoggedInStatus() === true
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
)
import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';
// import { mockResut } from './mockData';

const mockResponse = {
  plagPercent: 30,
  paraphrasePercent: 0,
  uniquePercent: 70,
  links: [
    'http://www.google.com',
    'https://www.myathina.com',
  ]
}
export const checkPlagiarism = (payload, successCallBack, failureCallBack) => {
  let url =`${apiConfig.plagiarism.checkPlagiarism}`
  if(payload.isDoc) {
    url = url + 'doc/'
  }
  axios.post(url,payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function ViewRejectReson(props) {
  const open = props.viewReasonPopup
  const rejectedReason = props.rejectReason
  const handleClose = () => {
    props.closeRejectViewPopup()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Rejected Reason'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {rejectedReason}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none'}} onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
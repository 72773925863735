export const SUCCESS_UPDATE_ASSESSMENT = 'Assessment informations updated successfully!'
export const SUCCESS_ADD_COURSE = 'Successfully added! You can access this course in MyCourse Page'
export const SUCCESS_ADD_MODULE = 'Successfully added module'
export const SUCCESS_ADD_LESSON = 'Successfully added lesson'
export const SUCCESS_UPDATE_COURSE_INFO = 'Successfully updated course details'
export const SUCCESS_UPDATE_MODULE_INFO = 'Successfully updated module details'
export const SUCCESS_UPDATE_LESSON_INFO = 'Successfully updated lesson details'
export const SUCCESS_ADD_QUESTION = 'Successfully updated question'
export const SUCCESS_DELETE_ASSESSMENT = 'Assessment deleted successfully!'
export const SUCCESS_COURSE_PUBLISH = 'Course published successfully'
export const SUCCESS_COURSE_UNPUBLISH = 'Course unpublished successfully'
export const SUCCESS_ASSIGNMENT_CREATE = 'Successfully created assignment'
export const SUCCESS_DELETE_ASSIGNMENT = 'Successfully deleted assignment'
export const SUCCESS_ASSIGNMENT_UPDATE = 'Successfully updated assignment'
export const SUCCESS_ASSIGNMENT_PUBLISH = 'Successfully published assignment'
export const SUCCESS_ASSIGNMENT_UNPUBLISH = 'Successfully unpublished assignment'
export const SUCCESS_SUBMIT_ASSIGNMENT_GRADE = 'Successfully added grade'
export const SUCCESS_UPDATE_ASSIGNMENT_GRADE = 'Successfully updated grade'
export const SUCCESS_UPDATE_GRADE = 'Successfully updated the grade'
export const SUCCESS_COURSE_SUBMIT_REVIEW = 'Successfully submitted for reivew'
export const SUCCESS_DISCUSSION_CREATE = 'Discussion created successfully!'
export const SUCCESS_DISCUSSION_UPDATE = 'Discussion updated successfully!'
export const SUCCESS_DELETE_DISCUSSION = 'Discussion deleted successfully!'
export const SUCCESS_DISCUSSION_PUBLISH = 'Discussion published successfully!'
export const SUCCESS_DISCUSSION_UNPUBLISH = 'Discussion unpublished successfully!'
export const SUCCESS_POST_REPLY = 'Successfully posted response!'
export const SUCCESS_SUBMIT_DISCUSSION_GRADE = 'Grade successfully added!'
export const SUCCESS_UPDATE_DISCUSSION_GRADE = 'Grade updated successfully!'
export const SUCCESS_POST_COMMENT = 'Successfully posted comment!'
export const SUCCESS_PIN_DISCUSSION = 'Successfully response pinned!'
export const SUCCESS_UNPIN_DISCUSSION = 'Successfully response unpinned!'
export const SUCCESS_SUBMIT_VIDEO = 'Video uploaded successfully!'
export const SUCCESS_UPDATE_VIDEO = 'Video updated successfully!'
export const SUCCESS_ASSESSMENT_PUBLISH = 'Successfully published assessment'
export const SUCCESS_ASSESSMENT_UNPUBLISH = 'Successfully unpublished assessment'
export const SUCCESS_MANAGEGRP_CREATION = 'Group created successfully'
export const SUCCESS_MANAGEGRP_UPDATE = 'Group details updated successfully'
export const SUCCESS_ADD_MEMBERS_TO_GRP = 'Members added successfully'
export const SUCCESS_UPDATE_GROUP_MEMBERS = 'Group members updated successfully'
export const SUCCESS_ASSIGN_MENTOR_TO_STUDENT = 'Mentor assigned successfully'
export const SUCCESS_UPDATE_MENTOR_TO_STUDENT = 'Mentor updated successfully'
export const SUCCESS_ASSIGN_STUDENTS_TO_MENTOR = 'Students assigned successfully'
export const SUCCESS_UPDATE_ASSIGN_STUDENTS_TO_MENTOR = 'Students updated successfully'
export const SUCCESS_DISCUSSION_ASSIGN = 'Discussion assigned successfully'
export const SUCCESS_SEND_NOTICE = 'Notice sent successfully'
export const SUCCESS_UPDATE_NOTICE = 'Notice updated successfully'
export const SUCCESS_DELETE_NOTICE_MESSAGE = 'Notice deleted successfully'
export const SUCCESS_ADD_ZOOM_SECRET_KEYS = 'Zoom keys added successfully'
export const SUCCESS_UPDATE_ZOOM_SECRET_KEYS = 'Zoom keys updated successfully'
export const SUCCESS_ASSIGN_INSTRUCTORS_TO_COURSE = 'Contributors added successfully'
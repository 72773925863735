import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function UploadProgress(props) {
  console.log(props)
  const classes = useStyles();
  // const [progress, setProgress] = React.useState(props.progress);

  /*
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  */
  return (
    <div className={classes.root}>
      {props.progress === 100 ? (
        <Typography>Processing...</Typography>
      ) : (
        <React.Fragment>
          <Typography>Uploading...</Typography>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={props.progress} {...props} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                props.progress,
              )}%`}</Typography>
            </Box>
          </Box>
        </React.Fragment>
      )}


    </div>
  );
}

import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { PrivateRoute } from "../router";
import Dashboard from "../Components/Dashboard";
import InitiateCourse from "../Components/InitiateCourse";
import MyCourse from "../Components/MyCourse";
import CourseListing from "../Components/CourseListing";
import GradeBook from "../Components/GradeBook";
import Assessments from "../Components/Assessments";
import Authors from "../Components/Authors";
import SnackBarmessages from "../Components/Common/AlertMessages/snackBarMessages";
import RedirectPage from "../Components/RedirectPage";
import LoginPage from "../Components/Login";
import Assignment from "../Components/Assignment";
import ScormReportView from "../Components/ScormReport";
import XapiReportView from "../Components/XapiReport";
import ScormNewReport from "../Components/ScormNewReport";
import DiscussionBoardView from "../Components/DiscussionBoard";
import Meetings from "../Components/Meetings";
import MyVideos from "../Components/MyVideos";
import Classes from "../Components/ZoomClasses";
import ManageRedirections from "../Components/ManageRedirections"
import ExternalAssignments from "../Components/ExternalAssignments"
import ExternalAssessments from "../Components/ExternalAssessments"
import ManageGroups from "../Components/ManageGroups"
import ExternalDiscussions from "../Components/ExternalDiscussions"
import CreateNotice from "../Components/NoticeBoard/AddNotice/"
import SentNotices from "../Components/NoticeBoard/SentNotices"
import MyNoticeBoard from "../Components/NoticeBoard"
import UserProfile from "../Components/UserProfile";
import ForgotPassword from "../Components/Login/forgotPassword";
import ChangePassword from "../Components/Login/changePassword";

function Layout(props) {
  const { alertReducer } = props;
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={LoginPage} history={props.history} />
        <Route exact path="/app" component={Dashboard} history={props.history} />
        <Route exact path="/forgotpassword" component={ForgotPassword} history={props.history}/>
        <Route path={'/Y2hhbmdlX3Bhc3N3b3Jk/:userToken'} component={ChangePassword} history={props.history} />
        <PrivateRoute exact path="/mycourse" component={CourseListing} history={props.history} />
        <PrivateRoute exact path="/coursereport/:courseId" component={ScormReportView} history={props.history} />
        <PrivateRoute exact path="/xapireport/:courseId" component={XapiReportView} history={props.history} />
        <PrivateRoute exact path="/scormnewreport/:courseId" component={ScormNewReport} history={props.history} />
        <PrivateRoute exact path="/gradebook/:courseId" component={GradeBook} history={props.history} />
        <PrivateRoute exact path="/authors" component={Authors} history={props.history} />
        <PrivateRoute exact path="/initiatecourse" component={InitiateCourse} history={props.history} />
        <PrivateRoute exact path="/mycourse/:courseId" component={MyCourse} history={props.history} />
        <PrivateRoute exact path="/mycourse/:courseId/:moduleId" component={MyCourse} history={props.history} />
        <PrivateRoute exact path="/mycourse/:courseId/:moduleId/:lessonId" component={MyCourse} history={props.history} />
        <PrivateRoute exact path="/assessments/course/" component={Assessments} history={props.history} />
        <PrivateRoute exact path="/assessments/:courseId" component={Assessments} history={props.history} />
        <PrivateRoute exact path="/assessments/:courseId/:assessmentId" component={Assessments} history={props.history} />
        <PrivateRoute exact path="/moduleassessment/:courseId/:moduleId" component={Assessments} history={props.history} />
        <PrivateRoute exact path="/lessonassessment/:courseId/:moduleId/:lessonId" component={Assessments} history={props.history} />
        <PrivateRoute exact path="/assignment/:courseId" component={Assignment} history={props.history} />
        <PrivateRoute exact path="/assignment/:courseId/:assignmentId" component={Assignment} history={props.history} />
        <PrivateRoute exact path="/assignment/:courseId/:moduleId/:assignmentId" component={Assignment} history={props.history} />
        <PrivateRoute exact path="/assignment/:courseId/:moduleId/:lessonId/:assignmentId" component={Assignment} history={props.history} />
        <PrivateRoute exact path="/moduleassignment/:courseId/:moduleId/" component={Assignment} history={props.history} />
        <PrivateRoute exact path="/lessonassignment/:courseId/:moduleId/:lessonId" component={Assignment} history={props.history} />
        <PrivateRoute exact path="/discussions/:courseId" component={DiscussionBoardView} history={props.history} />
        <PrivateRoute exact path="/discussions/:courseId/:discussionId" component={DiscussionBoardView} history={props.history} />
        <PrivateRoute exact path="/myvideos" component={MyVideos} history={props.history} />
        <PrivateRoute exact path="/myclasses" component={Classes} history={props.history} />
        <PrivateRoute exact path="/manageassessments" component={ManageRedirections} history={props.history} />
        <PrivateRoute exact path="/externalassignments" component={ExternalAssignments} history={props.history} />
        <PrivateRoute exact path="/externalassignments/:assignmentId" component={ExternalAssignments} history={props.history} />
        <PrivateRoute exact path="/externalassessments" component={ExternalAssessments} history={props.history}/>
        <PrivateRoute exact path="/externalassessments/:assessmentId" component={ExternalAssessments} history={props.history}/>
        <PrivateRoute exact path={["/managegroups", "/managegroups/:groupId"]} component={ManageGroups} history={props.history}/>
        <PrivateRoute exact path={["/externaldiscussions","/externaldiscussions/:discussionId"]} component={ExternalDiscussions} history={props.history}/>
        <PrivateRoute exact path="/noticeboard" component={MyNoticeBoard} history={props.history}/>
        <PrivateRoute exact path="/sentnotices" component={SentNotices} history={props.history}/>
        <PrivateRoute exact path="/createnotice" component={CreateNotice} history={props.history}/>
        <PrivateRoute exact path="/userprofile" component={UserProfile} history={props.history}/>
        <Route exact path="/redirect" component={RedirectPage} history={props.history} />
        <Route path="/meetings" component={Meetings} history={props.history} />
      </Switch>
      <SnackBarmessages
        state={alertReducer.state}
        message={alertReducer.message}
        type={alertReducer.type}
      />
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    alertReducer: state.alertReducer,
  };
};
export default connect(mapStateToProps, {})(Layout);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
//local imports
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import ChipDesignLoader from '../Common/skeletonLoaders/chipDesignLoader';
import { fetchMentorStudentsList, fetchOrgMembersListing, assignStudentsToMentor } from '../../store/ManageGroups/actionCreator';
import { SUCCESS_UPDATE_ASSIGN_STUDENTS_TO_MENTOR, SUCCESS_ASSIGN_STUDENTS_TO_MENTOR } from '../Common/Constants/successMessages'
import { ERR_FETCH_STUDENTS_UNDER_MENTOR, ERR_ASSIGN_STUDENTS_TO_MENTOR } from '../Common/Constants/errorMessages';

const useStyles = makeStyles(theme => ({
  highlightText: {
    fontSize: '1rem',
    fontWeight: 700
  },
  buttonProgress: {
    color: style.fontColour._blue
  },
  headerWrapper: {
    color: style.fontColour._blue
  },
  button: {
    color: style.fontColour._blue
  },
  chipWrapper: {
    color: style.fontColour._blue,
    borderColor: style.fontColour._blue,
    marginRight: '10px',
    marginBottom: '10px'
  },
  iconSelected: {
    marginRight: 5,
    marginLeft: -2,
    color: '#6BC787',
    fontSize: 20
  }
}));

function MentorStudentPopup(props) {
  const classes = useStyles()
  const open = props.open
  const alreadyHasStudents = props && props.mentorStudentsData && props.mentorStudentsData.has_students
  const scrollView = useMediaQuery('(max-width:640px)');
  const userData = props && props.mentorStudentsData ? props.mentorStudentsData : {}
  const [inProgress, setInProgress] = useState(false)
  const [mentorStudLoader, setMentStudLoader] = useState(false)
  //FOR FETCHING THE ENTIRE STUDENTS DROPDOWN LIST
  const [studentsData, setStudentsData] = useState([])
  //FOR DROPDOWN STUDENT SELECTION
  const [selectedStudent, setStudentSelection] = useState()
  const [studentInputValue, setStudentInputValue] = useState()
  //ALREADY ASSIGNED STUDENT LIST
  const [assignedStudentsArr, setAssignedStudentsArr] = useState([])
  const [hasMentorList, setHasMentorList] = useState([])
  const [studentsEmptyCheck, setStudentsEmptyCheck] = useState(false)


  //students under mentor fetch callbacks
  const successMentorStudentsList = res => {
    setMentStudLoader(false)
    let alreadyAssignedStu = res && res.data && res.data.length ? res.data : []
    let studentArrSet = []
    alreadyAssignedStu.filter(function (student) {
      return studentArrSet.push(`${student.student_name} (${student.email})`)
    })
    setAssignedStudentsArr(studentArrSet)
  }
  const failureMentorStudentsList = err => {
    setMentStudLoader(false)
    setAssignedStudentsArr([])
    props.showSnackBar({
      state: false,
      message: ERR_FETCH_STUDENTS_UNDER_MENTOR,
      type: 'error'
    })
  }

  //for fetching students entire list callbacks
  const successStudentsData = res => {
    setStudentsData(res && res.data && res.data)
    let data = res && res.data && res.data.length ? res.data : []
    let hasMentorArr = []
    data.filter(function (student) {
      if (student && student.has_mentor && student.mentor_data && student.mentor_data.length >= 2) {
        return hasMentorArr.push(`${student.student_name} (${student.email})`)
      }
    })
    setHasMentorList(hasMentorArr)
  }
  const failureStudentsData = err => {
    setStudentsData([])
    setHasMentorList([])
  }

  useEffect(() => {
    if (alreadyHasStudents) {
      setMentStudLoader(true)
      const payload = {
        mentor_id: userData.id
      }
      fetchMentorStudentsList(payload, successMentorStudentsList, failureMentorStudentsList)
    }
    const payload = {
      group: 'Student'
    }
    fetchOrgMembersListing(payload, successStudentsData, failureStudentsData)
  }, [])

  const handleClose = () => {
    props.closeViewStudentsPopup();
  };

  //for removing student from assigned list
  const handleStudentRemoveClick = (name) => {
    let studentArr = [...assignedStudentsArr]
    const studentIndex = studentArr.indexOf(name)
    studentArr.splice(studentIndex, 1)
    setAssignedStudentsArr(studentArr)
  };

  const studentsSet = () => {
    let studentsArr = [...assignedStudentsArr]
    if (!studentsArr.includes(selectedStudent) && selectedStudent && selectedStudent.length) {
      studentsArr.push(selectedStudent)
    }
    setAssignedStudentsArr(studentsArr)
    setStudentsEmptyCheck(false)
    setStudentSelection('')
    setStudentInputValue('')
  }
  useEffect(() => {
    studentsSet()
  }, [selectedStudent])

  //FOR SUBMITTING STUDENT IDS CALLBACKS
  const successAssignStudents = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: alreadyHasStudents ? SUCCESS_UPDATE_ASSIGN_STUDENTS_TO_MENTOR : SUCCESS_ASSIGN_STUDENTS_TO_MENTOR,
      type: 'success'
    })
    handleClose()
    props.fetchMentorDataList('Mentor')
  }
  const failureAssignStudents = err => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: ERR_ASSIGN_STUDENTS_TO_MENTOR,
      type: 'error'
    })
  }
  const onSubmitStudents = () => {
    let studentIds = []
    assignedStudentsArr && assignedStudentsArr.length && assignedStudentsArr.forEach((item, index) => {
      let emailSplit = item.split('(')
      let emailId = emailSplit && emailSplit.length && emailSplit[1] && emailSplit[1].slice(0, -1)
      let studentEntry = studentsData && studentsData.find((data) => data.email === emailId)
      studentIds.push(studentEntry && studentEntry.id)
    })
    if (!alreadyHasStudents && studentIds && !studentIds.length) {
      setStudentsEmptyCheck(true)
    } else {
      setStudentsEmptyCheck(false)
      setInProgress(true)
      const payload = {
        mentor_id: userData && userData.id,
        students_id: studentIds
      }
      assignStudentsToMentor(payload, successAssignStudents, failureAssignStudents)
    }
  }

  let studentChipsHeight = ''
  let dataLength = assignedStudentsArr && assignedStudentsArr.length
  if (mentorStudLoader) {
    studentChipsHeight = 100
  } else if (dataLength === 0) {
    studentChipsHeight = 0
  } else if (dataLength <= 5) {
    studentChipsHeight = 150
  } else if (dataLength > 20) {
    studentChipsHeight = 400
  } else if (dataLength > 10) {
    studentChipsHeight = 200
  }
  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="Manage students"
        aria-describedby="managing students for mentor"
      >
        <DialogTitle id="alert-dialog-title" className={classes.headerWrapper}>Manage Students For Mentor</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{alreadyHasStudents ? 'Change' : 'Assign'} Students</span> for <span className={classes.highlightText}>{userData.mentor_name && userData.mentor_name}{` (${userData.email})`}</span>
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ height: studentChipsHeight, overflowY: 'scroll' }}>
          <div>
            {mentorStudLoader ? (
              <ChipDesignLoader />
            ) : (
              <React.Fragment>
                { assignedStudentsArr && assignedStudentsArr.length ? (
                  <React.Fragment>
                    { assignedStudentsArr.map((row, index) => (
                      <Chip
                        key={index}
                        className={classes.chipWrapper}
                        label={row}
                        variant="outlined"
                        deleteIcon={<HighlightOffIcon style={{ color: style.fontColour._blue }} />}
                        onClick={() => { handleStudentRemoveClick(row) }}
                        onDelete={() => { handleStudentRemoveClick(row) }}
                      />
                    ))}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </DialogContent>
        <div style={{ marginLeft: '16px', width: scrollView ? '250px' : '400px', marginTop: '10px' }}>
          <Autocomplete
            value={selectedStudent}
            onChange={(event, newValue) => {
              setStudentSelection(newValue);
            }}
            inputValue={studentInputValue}
            onInputChange={(event, newInputValue) => {
              setStudentInputValue(newInputValue);
            }}
            id="studentSelection"
            options={studentsData.map((option) => `${option.student_name} (${option.email})`)}
            getOptionDisabled={(option) => hasMentorList && hasMentorList.indexOf(option) > -1}
            renderOption={(option) => (
              <React.Fragment>
                <DoneIcon
                  className={classes.iconSelected}
                  style={{ visibility: hasMentorList && hasMentorList.indexOf(option) > -1 ? 'visible' : 'hidden' }}
                />
                <div className={classes.text}>
                  {option}
                </div>
              </React.Fragment>
            )}
            renderInput={(params) => <TextField
              {...params}
              label="Student Selection"
              error={studentsEmptyCheck}
              variant="outlined"
              placeholder="Search student and select from dropdown"
              helperText="Select student for assigning to a mentor!"
            />}
          />
        </div>
        <DialogActions>
          {inProgress ? (
            <CircularProgress size={22} className={classes.buttonProgress} />
          ) : null}
          <Button onClick={onSubmitStudents} disabled={inProgress} className={classes.button}>
            {alreadyHasStudents ? 'Update' : 'Submit'}
          </Button>
          <Button onClick={handleClose} disabled={inProgress} className={classes.button}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(MentorStudentPopup);
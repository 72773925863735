import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'


export const addLesson = (formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addLesson.initiateLesson}`
  axios.post(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const updateLessonInfo = (payload, formData, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addLesson.updateLessonInfo}${payload.id}/`
  axios.put(url, formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

export const fetchLessonDetails = (payLoad, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addLesson.fetchLessonDetails}`
  axios.get(url, {
    params: payLoad,
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}

//FOR DELETING THE UPLOADED READINGS FROM BACKEND
export const deleteUploadedLessonReadings = (payload, successCallBack, failureCallBack) => {
  const url = `${apiConfig.addLesson.deleteUploadedReadings}`
  axios.delete(url, {
    params: payload
  })
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
}
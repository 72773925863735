import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig';


let gradeBook = {
  assessments: [
    {
      assessment_id: 1,
      title: 'test Assessment 1'
    },
    {
      assessment_id: 2,
      title: 'test Assessment 2',
    },
    {
      assessment_id: 3,
      title: 'test Assessment 3'
    },
    {
      assessment_id: 4,
      title: 'test Assessment 4'
    },
    {
      assessment_id: 5,
      title: 'test Assessment 5',
    },
    {
      assessment_id: 6,
      title: 'test Assessment 6'
    },
  ],
  assignments: [
    {
      assignment_id: 1,
      title: 'test Assignment 1'
    },
    {
      assignment_id: 2,
      title: 'test Assignment 2'
    },
    {
      assignment_id: 3,
      title: 'test Assignment 3'
    },
  ],
  totalCount: 30,
  gradeList: [
    {
      name: 'Student 1',
      student_id: '1',
      assessmentsGrade: [
        {
          assessment_id: 1,
          grade: 30
        },
        {
          assessment_id: 2,
          grade: 70
        },
        {
          assessment_id: 3,
          grade: 80
        },
        {
          assessment_id: 4,
          grade: 30
        },
        {
          assessment_id: 5,
          grade: 70
        },
        {
          assessment_id: 6,
          grade: 80
        }
      ],
      system_calculated_assessment_grade: 60,
      assignmentsGrade: [
        {
          assignment_id: 1,
          grade: 30
        },
        {
          assignment_id: 2,
          grade: 70
        },
        {
          assignment_id: 3,
          grade: 80
        }
      ],
      system_calculated_assignments_grade: 60,
      system_calculated_overall_grade: 50,
      manual_overall_grade: 50,
      remarks: 'test remarks from faculty...'
    },
    {
      name: 'Student 2',
      student_id: '2',
      assessmentsGrade: [
        {
          assessment_id: 1,
          grade: 60
        },
        {
          assessment_id: 2,
          grade: 30
        },
        {
          assessment_id: 3,
          grade: 70
        },
        {
          assessment_id: 4,
          grade: 60
        },
        {
          assessment_id: 5,
          grade: 30
        },
        {
          assessment_id: 6,
          grade: 70
        }
      ],
      system_calculated_assessment_grade: 53,
      assignmentsGrade: [
        {
          assignment_id: 1,
          grade: 60
        },
        {
          assignment_id: 2,
          grade: 30
        },
        {
          assignment_id: 3,
          grade: 70
        }
      ],
      system_calculated_assignments_grade: 53,
      system_calculated_overall_grade: 70,
      manual_overall_grade: 80,
      remarks: 'test remarks from faculty...'
    },
    {
      name: 'Student 3',
      student_id: '3',
      assessmentsGrade: [
        {
          assessment_id: 1,
          grade: 10
        },
        {
          assessment_id: 2,
          grade: 20
        },
        {
          assessment_id: 3,
          grade: 90
        },
        {
          assessment_id: 4,
          grade: 10
        },
        {
          assessment_id: 5,
          grade: 20
        },
        {
          assessment_id: 6,
          grade: 90
        }
      ],
      system_calculated_assessment_grade: 40,
      assignmentsGrade: [
        {
          assignment_id: 1,
          grade: 10
        },
        {
          assignment_id: 2,
          grade: 20
        },
        {
          assignment_id: 3,
          grade: 90
        }
      ],
      system_calculated_assignments_grade: 40,
      system_calculated_overall_grade: 80,
      manual_overall_grade: 90,
      remarks: 'test remarks from faculty...'
    }
  ]
}
// gradeBook.assessments = [...gradeBook.assessments, ...gradeBook.assessmentss]

gradeBook.assignments = [...gradeBook.assignments, ...gradeBook.assignments, ...gradeBook.assignments]
gradeBook.gradeList[0].assignmentsGrade = [...gradeBook.gradeList[0].assignmentsGrade, ...gradeBook.gradeList[0].assignmentsGrade, ...gradeBook.gradeList[0].assignmentsGrade]
gradeBook.gradeList[1].assignmentsGrade = [...gradeBook.gradeList[1].assignmentsGrade, ...gradeBook.gradeList[1].assignmentsGrade, ...gradeBook.gradeList[1].assignmentsGrade]
gradeBook.gradeList[2].assignmentsGrade = [...gradeBook.gradeList[2].assignmentsGrade, ...gradeBook.gradeList[2].assignmentsGrade, ...gradeBook.gradeList[2].assignmentsGrade]

// delete gradeBook.assessments;
// delete gradeBook.assignments;

export const mockGradeBook = {
  results: {
    assessments: [{assessment_id: 6, title: "assessment-1"},{assessment_id: 7,title: "assessment-2"}],
    assignments: [{assingment_id: 1, title: "Assignment-1", grade_point: 100, days: 0},{assingment_id: 3, title: "Assignment-2", grade_point: 100, days: 0}],
    discussions: [{forum_id: 8, forum_title: "Discussion 1", points: 100},{forum_id: 2, forum_title: "Discussion 2", points: 100}],
    grade_list: [
      {
        user_id: 3,
        user_name: 'student1',
        manual_overall_grade: 10,
        over_all_percent: 35,
        overall_remark: 'Test',
        system_calculated_assessment_percent: 0,
        system_calculated_assignment_percent: 35,
        system_calculated_discussion_percent: 20,
        assessment_grade: [],
        assignment_grade: [{assignment_id: 1, grade_point: 100},{assignment_id: 3, grade_point: 100}],
        discussion_grade: [{forum_id: 1, grade_point: 0},{forum_id:2, grade_point: 20}]
      },
      {
        user_id: 2,
        user_name: 'student2',
        manual_overall_grade: 20,
        over_all_percent: 50,
        overall_remark: 'Test 2',
        system_calculated_assessment_percent: 10,
        system_calculated_assignment_percent: 50,
        system_calculated_discussion_percent: 70,
        assessment_grade: [],
        assignment_grade: [{assignment_id: 1, grade_point: 20},{assignment_id: 3, grade_point: 10}],
        discussion_grade: [{duscussion_id: 1, grade_point: 100},{discussion_id:2, grade_point: 70}]
      }
    ]
  }
}



export const fetchGradeBook = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.gradebook.list}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //successCallBack(mockGradeBook)
    failureCallBack(error)
  })
}
export const updateGrade = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.gradebook.update}`
  axios.post(url,payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
    //successCallBack({})
  })
}
export const fetchAssessmentReport = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.gradebook.assessmentReport}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchStudentSubmissions = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.gradebook.fetchStudentSubmissions}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';
//LOCAL IMPORTS
import { showSnackBar } from '../../../store/AlertMessages/actionCreator';
import { fetchDocsLibraryFiles, searchDocsLibraryFiles } from '../../../store/AddingCourse/actionCreator';
import Pagination from '../pagination';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    minHeight: '200px',
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  btnWrapper: {
    marginLeft: '-31px',
    marginTop: '3px',
    marginRight: '10px',
    textAlign: 'center',
    color: '#3363ff',
    backgroundColor: '#e6ecff',
    border: 'none',
    borderRadius: '0.4rem',
    textTransform: 'none',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: '#e6ecff',
      color: '#3363ff',
    },
  },
  textWrapper: {
    padding: '3px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 368,
  },
  textWrapper: {
    padding: '3px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 368,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchIcon: {
    color: '#2e8eec',
    fontSize: '26px',
  },
  noDataWrap: {
    fontSize: '15px',
    margin: '5px 14px 7px',
    textAlign: 'left',
    color: '#2e8eec'
  },
  table: {
    minWidth: 650,
  },
  tableHeadingCell: {
    padding: '12px',
    paddingLeft: '6px',
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#333',
    backgroundColor: '#EBF7FA'
  },
  tableCell: {
    padding: '8px',
    fontSize: '0.8rem',
    textAlign: 'left',
    paddingLeft: '7px'
  },
  clrBtn: {
    textTransform: 'none',
    color: '#2e8eec',
    marginTop: '7px',
  },
  titleWrap: {
    color: '#2e8eec'
  },
  readMoreDots: {
    color: 'blue',
    fontSize: '1rem',
    marginLeft: '5px',
    fontWeight: 900,
    verticalAlign: 'baseline'
  },
  descPopover: {
    padding: 10,
  }
}))

function DocsLibraryFilesPopup(props) {
  const data = props && props.data && props.data;
  const fileType = props && props.data && props.data.type
  const classes = useStyles();
  const [dataLoader, setDataLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchFilterData, setShowSearchFilterData] = useState(false);

  const [descriptionDom, setDescriptionDom] = React.useState(null);
  const [popoverDesc, setPopoverDesc] = React.useState(null);
  const openDescPopup = Boolean(descriptionDom);
  const popoverId = openDescPopup ? 'descPopover' : undefined;
  const handleDescPopoverOpen = (evnt, desc) => {
    setDescriptionDom(evnt.currentTarget);
    setPopoverDesc(desc)
  };
  const handleDescPopoverClose = () => {
    setDescriptionDom(null)
  }

  const successDocsFiles = res => {
    setTotalCount(res && res.total_count);
    setTableData(res && res.results && res.results);
    setDataLoader(false);
  }
  const failureDocsFiles = err => {
    setTotalCount(0);
    setTableData([]);
    props.showSnackBar({
      state: true,
      message: 'Somethine went wrong while fetching the data!',
      type: 'error'
    })
    setDataLoader(false);
  }
  //FOR FETCHING THE DATA OF TABS
  const fetchDocsFiles = (offset, number, type, filesTypes) => {
    setDataLoader(true);
    const payload = {
      offset: offset,
      number: number,
      type: 'show_all',
      listing_type: filesTypes
    }
    fetchDocsLibraryFiles(payload, successDocsFiles, failureDocsFiles)
  }

  useEffect(() => {
    fetchDocsFiles(0, 10, 'created', fileType)
  }, [])

  const handleClose = () => {
    props.close();
  }

  //ON PAGINATION CHANGE
  const onPagination = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum);
    let offset = pageNum * rowsPerPage
    if (showSearchFilterData) {
      fetchFilterData(offset, rowsPerPage);
    } else {
      fetchDocsFiles(offset, rowsPerPage, 'created', fileType);
    }
  }

  //ON ROW SELECTION CALL
  const onUseConfirmClick = rowData => {
    props.onSelecteDocsLibraryFile(rowData, fileType);
    props.close();
  }

  //FOR FILTER SEARCHTERM CHANGE
  const handleSearchFilter = event => {
    setSearchTerm(event.target.value)
  }

  //FOR FETCHING THE SEARCH FILTER DATA
  const fetchFilterData = (offset, number) => {
    setShowSearchFilterData(true);
    setDataLoader(true);
    let filterParam = ''
    if (fileType === 'other_docs') {
      filterParam = 'Other Docs'
    } else {
      filterParam = 'Videos'
    }
    const payload = {
      offset: offset,
      number: number,
      search_term: searchTerm,
      search_filters: filterParam,
      show_all: true
    }
    searchDocsLibraryFiles(payload, successDocsFiles, failureDocsFiles)
  }

  //FOR SEARCH FILTER CLICK
  const searchFilterHit = () => {
    if (!searchTerm) {
      document.getElementById('searchHeader') && document.getElementById('searchHeader').focus()
    } else {
      setPage(0);
      fetchFilterData(0, 10);
    }
  }

  //FOR CLEARING THE FILTER
  const clearFilter = () => {
    setSearchTerm('');
    setPage(0);
    setShowSearchFilterData(false)
    fetchDocsFiles(0, 10, 'created', fileType)
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} aria-labelledby="docs library listing" open={data.dialog}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.titleWrap}>
          {props && props.title ? props.title : `${fileType === 'other_docs' ? 'Document' : 'Video'} slection dialog`}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Paper component="form" className={classes.textWrapper}>
                    <SearchIcon className={classes.searchIcon} />
                    <Divider className={classes.divider} orientation="vertical" />
                    <InputBase
                      value={searchTerm}
                      onChange={handleSearchFilter}
                      className={classes.input}
                      autoComplete="off"
                      id="searchHeader"
                      placeholder="Search Document"
                      inputProps={{ 'aria-label': 'search document' }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Button variant="contained" className={classes.btnWrapper} onClick={() => { searchFilterHit() }}>Search</Button>
                  {showSearchFilterData ? (
                    <Button className={classes.clrBtn} size="small" variant="outlined" onClick={() => { clearFilter() }}>Clear filter</Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px' }}>
              <TableContainer component={Paper} >
                <Table aria-label="Departments Listing" className={classes.table}>
                  <TableHead style={{ backgroundColor: '#EBF7FA' }}>
                    <TableRow>
                      <TableCell className={classes.tableHeadingCell}>Title</TableCell>
                      <TableCell className={classes.tableHeadingCell}>Description</TableCell>
                      <TableCell className={classes.tableHeadingCell}>File</TableCell>
                      <TableCell className={classes.tableHeadingCell}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {dataLoader ? (
                      <Typography className={classes.noDataWrap}>Loading...</Typography>
                    ) : (
                      <React.Fragment>
                        {tableData && tableData.length ? (
                          <React.Fragment>
                            {tableData.map((row, index) => {
                              return (
                                <TableRow key={index} >
                                  <TableCell scope="row" className={classes.tableCell}>
                                    {row.title && row.title.length > 40 ? (
                                      <div>
                                        {row.title.substr(0, 20)}
                                        <Link component="button" className={classes.readMoreDots} onClick={evnt => { handleDescPopoverOpen(evnt, row.title) }} aria-describedby={popoverId}>...</Link>
                                      </div>
                                    ) : row.title && row.title}
                                  </TableCell>
                                  <TableCell scope="row" className={classes.tableCell}>
                                    {row.desc && row.desc.length > 30 ? (
                                      <div>
                                        {row.desc.substr(0, 25)}
                                        <Link component="button" className={classes.readMoreDots} onClick={evnt => { handleDescPopoverOpen(evnt, row.desc) }} aria-describedby={popoverId}>...</Link>
                                      </div>
                                    ) : row.desc && row.desc}
                                  </TableCell>
                                  <TableCell scope="row" className={classes.tableCell}>
                                    {fileType === 'other_docs' ? (
                                      row && row.original_filename && row.original_filename
                                    ) : (
                                      row && row.video_filename && row.video_filename
                                    )}
                                  </TableCell>
                                  <TableCell scope="row" className={classes.tableCell}>
                                    <Link style={{ color: '#2e8eec', textDecoration: 'none' }} component="button" onClick={() => { onUseConfirmClick(row) }}>Upload</Link>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </React.Fragment>
                        ) : (
                          <Typography className={classes.noDataWrap}>No data found!</Typography>
                        )}
                      </React.Fragment>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {!dataLoader && totalCount && totalCount > 10 ? (
                <Pagination onPagination={onPagination} enableRowsPerPage={false} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} />
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <Popover
          id={popoverId}
          open={openDescPopup}
          anchorEl={descriptionDom}
          onClose={handleDescPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ left: '230px', right: '230px' }}
        >
          <Typography className={classes.descPopover}>{popoverDesc}</Typography>
        </Popover>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, { showSnackBar })(DocsLibraryFilesPopup)

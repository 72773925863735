import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { Map, GoogleApiWrapper, InfoWindow, Marker, Circle } from 'google-maps-react';
import Button from '@material-ui/core/Button';
import { colorStyles } from '../../../Styles/theme';
import StoreLogo from '../../../static/caseysstorelogo.png'

const useStyles = makeStyles((theme) => ({
  storeList: {
    fontSize: '10px',
    width: '100%',
    textAlign: 'center'
  },
  storeTitle: {
    display: 'block',
    color: '#ed3833',
    fontWeight: 700
  },
  buttons: {
    float: 'right',
    margin: '-38px 0 8px 0',
    borderRadius: '40px',
    padding: '3px 20px 3px 20px',
    fontFamily: 'helvetica',
    textTransform: 'none',
    backgroundColor: colorStyles.btnColor,
    color: colorStyles.btnText,
    '&:hover': {
      backgroundColor: colorStyles.btnColor,
      color: colorStyles.btnText,
    },
  },
}));

const mapStyles = {
  width: '100%',
  height: '100%'
};
const mapContainerStyles = {
  position: 'relative',
  width: '902px',
  height: '517px',
  marginTop: '12px'
};
function GoogleMap(props) {
  const classes = useStyles();
  const lat = Number(props.selectedStores.latitude)
  const lng = Number(props.selectedStores.longitude)
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState({})
  const [nearByStores, setNearByStores] = useState([])
  const allStoresList = props.allStores || []

  const nearbyRadius = Number(30)
  const radius = (nearbyRadius * 1.60934) * 1000;
  const coords = { lat: lat, lng: lng }
  const coordsFnc = {
    lat: () => { return lat },
    lng: () => { return lng }
  }
  const google = props.google

  var bounds = new google.maps.LatLngBounds();
  const nearByStoreList = () => {
    let nrBy = []
    for (let i in allStoresList) {
      let pos = { lat: Number(allStoresList[i].latitude), lng: Number(allStoresList[i].longitude) }
      let posFnc = {
        lat: () => { return Number(allStoresList[i].latitude) },
        lng: () => { return Number(allStoresList[i].longitude) }
      }
      if (google.maps.geometry.spherical.computeDistanceBetween(posFnc, coordsFnc) < radius) {
        bounds.extend(pos)
        nrBy.push(allStoresList[i])
      }
    }
    //console.log(nrBy)
    setNearByStores(nrBy)
  }
  useEffect(() => {
    nearByStoreList()
  }, [props])

  const onProceedLocationClick = () => {
    props.onProceedClick(props.selectedStores)
  }

  return (
    <div>
      <div style={{ float: 'left' }}>
        <Typography variant="body2" className={classes.storeList} style={{ fontSize: '14px', marginTop: 20 }}>
          <span className={classes.storeTitle}>{props.selectedStores.store}</span>
          <span>
            {`${props.selectedStores.address}, `}
            {`${props.selectedStores.city}, `}
            {`${props.selectedStores.state}, `}
            {`${props.selectedStores.country}, `}
            {`${props.selectedStores.zip_code} `}
          </span>
        </Typography>
        <Button className={classes.buttons} onClick={() => { onProceedLocationClick() }}>
          Proceed
        </Button>
        <Map
          google={google}
          zoom={8}
          containerStyle={mapContainerStyles}
          initialCenter={
            {
              lat: lat,
              lng: lng
            }
          }
          center={
            {
              lat: lat,
              lng: lng
            }
          }
        >
          {allStoresList && allStoresList.length && allStoresList.map((data, index) => (
            <Marker
              key={index}
              title={data.store}
              name={data.store}
              position={{ lat: Number(data.latitude), lng: Number(data.longitude) }}
              icon={{
                url: StoreLogo,
                anchor: new google.maps.Point(32, 32),
                scaledSize: new google.maps.Size(data.store === props.selectedStores.store ? 60 : 20, data.store === props.selectedStores.store ? 60 : 20)
              }}
            />
          ))}
          <Circle
            radius={radius}
            center={coords}
            onMouseover={() => console.log('mouseover')}
            onClick={() => console.log('click')}
            onMouseout={() => console.log('mouseout')}
            strokeColor='transparent'
            strokeOpacity={0}
            strokeWeight={5}
            fillColor='#FF0000'
            fillOpacity={0.2}
          />
        </Map>
      </div>
      <div style={{ paddingTop: 20 }}>
        <div>
          <Typography variant="body1" gutterBottom className={classes.coloumnTitle} style={{ margin: 0, paddingBottom: 0, textAlign: 'center' }}>
            Stores in {nearbyRadius} miles radius
            <span style={{ display: 'block', color: '#999' }}>Found: {nearByStores.length} stores</span>
          </Typography>
          {nearByStores && nearByStores.length ? (
            <List style={{ maxHeight: '530px', overflowY: 'auto' }}>
              {nearByStores && nearByStores.length && nearByStores.map((data, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <Typography variant="body2" gutterBottom className={classes.storeList}>
                      <span className={classes.storeTitle}>{data.store}</span>
                      {data.address}<br />
                      {data.city}<br />
                      {data.state}<br />
                      {data.country}<br />
                      {data.zip_code}<br />
                    </Typography>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          ) : null}
        </div>
      </div>
    </div>
  )
}
export default GoogleApiWrapper(
  (props) => ({
    apiKey: 'AIzaSyBel4JBJR_yiq9N-SV2efmBOPtBnHDDR_Q', //CE
    //apiKey: 'AIzaSyCBIRuAIL-yfGTcIAlNJ2ht2mq3mhuyCyQ', //myAthina
    //apiKey: 'AIzaSyDJQhdDldrmIUr0OZm8uD4yhDZ6-7iy8MY',
    libraries: ['places', 'geometry'],
    //apiKey: 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg'
  }
  ))(GoogleMap)
/*
export default GoogleApiWrapper({
  apiKey: 'AIzaSyDJQhdDldrmIUr0OZm8uD4yhDZ6-7iy8MY'
})(GoogleMap);
*/

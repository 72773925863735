import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Popover from '@material-ui/core/Popover';
//local imports
import { style } from '../../Styles/theme';
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import { convertUTCTimeZone } from '../../Helpers/basic'
import Pagination from './pagination'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: '1200px',
    overflowX: 'scroll'
  },
  tableHeadingCell: {
    padding: '15px',
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '1rem',
    color: '#777'
  },
  tableCell: {
    padding: '10px',
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  link: {
    color: style.fontColour._blue,
  },
  loadWrapper: {
    textAlign: 'left',
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '8px',
    color: style.fontColour._blue,
    marginLeft: '16px'
  },
  readMoreDots: {
    color: 'blue',
    fontSize: '1rem',
    marginLeft: '5px',
    fontWeight: 900,
    verticalAlign: 'baseline'
  },
  descPopover: {
    padding: '10px'
  },
}))

function GroupsListing(props) {
  const classes = useStyles()
  const groupsData = props && props.groupsData && props.groupsData
  const [descriptionDom, setDescriptionDom] = React.useState(null);
  const openDescPopup = Boolean(descriptionDom);
  const popoverId = openDescPopup ? 'descPopover' : undefined;
  const [popoverDesc, setPopoverDesc] = React.useState(null);

  const triggerManage = (id) => {
    props.history.push(`/managegroups/${id}`)
  }

  const triggerEdit = (data) => {
    props.editGroupDetails('edit', data)
  }

  const handleDescPopoverOpen = (evnt, desc) => {
    setDescriptionDom(evnt.currentTarget);
    setPopoverDesc(desc)
  };
  const handleDescPopoverClose = () => {
    setDescriptionDom(null)
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="Groups Listing" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableHeadingCell}>Group Name</TableCell>
              <TableCell align="center" className={classes.tableHeadingCell}>Group Description</TableCell>
              <TableCell align="center" className={classes.tableHeadingCell}>Created Date</TableCell>
              <TableCell align="center" className={classes.tableHeadingCell}>Members Count</TableCell>
              <TableCell align="center" className={classes.tableHeadingCell}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.paginationLoader ? (
              <TableRow>
                <TableCell className={classes.loadWrapper}>Loading...</TableCell>
              </TableRow>
            ) : (
                <React.Fragment>
                  {groupsData && groupsData.length && groupsData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        {row && row.group_title ? row.group_title : ''}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        {row && row.group_desc ? (
                          <React.Fragment>
                            { row.group_desc && row.group_desc.length > 100 ? (
                              <div>
                                {row.group_desc.substr(0, 40)}
                                <Link component="button" className={classes.readMoreDots} onClick={evnt => { handleDescPopoverOpen(evnt, row.group_desc) }} aria-describedby={popoverId}>...</Link>
                              </div>
                            ) : (
                                row && row.group_desc && row.group_desc
                              )}
                          </React.Fragment>
                        ) : 'NA'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        {row && row.created_date ? convertUTCTimeZone(row.created_date) : 'NA'}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        {row && row.members_count}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center" className={classes.tableCell}>
                        <Link className={classes.link} component="button" onClick={() => { triggerManage(row.id) }}>Manage</Link>&nbsp;| &nbsp;
                      <Link className={classes.link} component="button" onClick={() => { triggerEdit(row) }}>Edit</Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      { props.totalCount > 10 && !props.paginationLoader ? (
        <Pagination onPagination={props.onPagination} enableRowsPerPage={false} page={props.page} rowsPerPage={props.rowsPerPage} totalCount={props.totalCount} />
      ) : null}
      <Popover
        id={popoverId}
        open={openDescPopup}
        anchorEl={descriptionDom}
        onClose={handleDescPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ left: '70px', right: '93px' }}
      >
        <Typography className={classes.descPopover}>{popoverDesc}</Typography>
      </Popover>
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(GroupsListing)

import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { publishAssignment, publishExternalAssignment } from '../../store/Assignments/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { ERROR_ASSIGNMENT_PUBLISH, ERROR_ASSIGNMENT_UNPUBLISH, ERROR_FETCH_DEPARTMENTS_LIST } from '../Common/Constants/errorMessages'
import { SUCCESS_ASSIGNMENT_PUBLISH, SUCCESS_ASSIGNMENT_UNPUBLISH } from '../Common/Constants/successMessages'
import { fetchDepartments } from '../../store/AddingCourse/actionCreator'

import { find } from 'lodash'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    marginTop: '-5x'
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
    display: 'block'
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AssignmentPublishPopup(props) {
  const classes = useStyles();
  const open = props.openMode === 'unPublish' ? props.openUnPublishPopup : props.openPublishPopup
  const [inProgress, setInprogress] = React.useState(false)
  const [departments, setDepartments] = React.useState([]);
  const [departMentList, setDepartMentList] = React.useState([])
  const [department, setSelectedDepartment] = React.useState([])
  const [deptEmptyError, setDeptEmptyError] = React.useState(false)

  const deptSuccessCallBack = res => {
    setDepartMentList(res.result)
    let arr = []
    for(let i in res.result){
      arr.push(res.result[i].name)
    }
    setDepartments(arr)
    setSelectedDepartment(arr)
   }

   const deptFailureCallBack = error => {
    setDepartMentList([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCH_DEPARTMENTS_LIST,
        type: 'error'
      }
    )
   }

  useEffect(()=>{
    if(props.from === 'externalType' && props.openMode !== 'unPublish'){
      fetchDepartments({}, deptSuccessCallBack, deptFailureCallBack)
    }
  },[])

  const handleDeptChange = event => {
    setSelectedDepartment(event.target.value);
  };

  const handleClose = () => {
    if(props.openMode === 'unPublish'){
      props.closeUnpublish()
    }else{
      props.closePublish();
    }
   };

  const publishSuccessCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ASSIGNMENT_PUBLISH,
        type: 'success'
      }
    )
    handleClose()
    props.listAllAssignment()
  }

  const publishFailureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ASSIGNMENT_PUBLISH,
        type: 'error'
      }
    )  
  }
  const unPublishsuccessCallBack = res => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: true,
        message: SUCCESS_ASSIGNMENT_UNPUBLISH,
        type: 'success'
      }
    )
    handleClose()
    props.listAllAssignment()
  }

  const unPublishfailureCallBack = error => {
    setInprogress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_ASSIGNMENT_UNPUBLISH,
        type: 'error'
      }
    )
  }

  const getSelectedDepartmentIds = depArr => {
    let selectedDepartmentIds = []
    for(let i in depArr) {
      const res = find(departMentList, { 'name': depArr[i] })
      selectedDepartmentIds.push(res.id)
    }
    return selectedDepartmentIds
  }

  const publishAssignmentonClick = () => {
    if(props.openMode === 'unPublish'){
      setInprogress(true)
      if(props.match.params.lessonId){
        const payload = {
          lesson_id: props.match.params.lessonId ? props.match.params.lessonId : null,
          assignment_id: props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null,
          published: false
        }
        publishAssignment(payload, unPublishsuccessCallBack, unPublishfailureCallBack)
      }
      else if(props.match.params.moduleId){
        const payload = {
          module_id: props.match.params.moduleId ? props.match.params.moduleId : null,
          assignment_id: props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null,
          published: false
        }
        publishAssignment(payload, unPublishsuccessCallBack, unPublishfailureCallBack)
      }
      else if(props.match.params.courseId){
        const payload = {
          course_id: props.match.params.courseId ? props.match.params.courseId : null,
          assignment_id: props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null,
          published: false
        }
        publishAssignment(payload, unPublishsuccessCallBack, unPublishfailureCallBack)
      }else if(props.from === 'externalType'){
        const payload = {
          assignment_id: props.assignmentDetails.id ? props.assignmentDetails.id : null,
          published: false
        }
        publishExternalAssignment(payload, unPublishsuccessCallBack, unPublishfailureCallBack)
      }
    }else{
      if(props.from === 'externalType'){
        const selectedDepartmentIds = getSelectedDepartmentIds(department)
        const payload = {
          assignment_id: props.assignmentDetails.id ? props.assignmentDetails.id : null,
          department_id: selectedDepartmentIds,
          published: true
        }
        if(selectedDepartmentIds && !selectedDepartmentIds.length){
          setDeptEmptyError(true)
        }else{
          setInprogress(true)
          setDeptEmptyError(false)
          publishExternalAssignment(payload, publishSuccessCallBack, publishFailureCallBack)
        }
      }else{
        setInprogress(true)
        const payload = {
          assignment_id: props.assignmentDetails.assignment_id ? props.assignmentDetails.assignment_id : null,
          published: true
        }
        publishAssignment(payload, publishSuccessCallBack, publishFailureCallBack)
      }
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title"> 
          { props.openMode !== 'unPublish' ? 
            (
              'Are you sure you want to publish this Assignment?'
            ) : 
            (
              'Are you sure you want to Unpublish this Assignment?'
            )
          }
        </DialogTitle>
        { props.openMode !== 'unPublish' ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once you publish this Assignment you can't edit the Assignment
            </DialogContentText>
            { props.from  === 'externalType' ? (
              <React.Fragment>
                <FormControl className={classes.formControl} error={deptEmptyError}>
                  <InputLabel id="department">Departments</InputLabel>
                  <Select
                    labelId="department"
                    id="departmentid"
                    multiple
                    value={department}
                    onChange={handleDeptChange}
                    input={<Input />}
                    renderValue={selected => selected.join(', ')}
                    MenuProps={MenuProps}
                    fullWidth
                  >
                  {departments && departments.map(name => (
                    <MenuItem key={name} value={name}>
                      <Checkbox id="checkBox" checked={department.indexOf(name) > -1} color="primary"/>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: '#999', marginBottom: '22px' }}>This Assignment will go to your organization only and to the teams selected by you.</FormHelperText>
              </React.Fragment>
            ) : null }
          </DialogContent>
        ) : null }
        <DialogActions>
        { inProgress ? (
          <CircularProgress size={30} className={classes.buttonProgress} />
        ): null }
          <Button onClick={publishAssignmentonClick} disabled={inProgress} color="primary" variant="contained" autoFocus>
            Confirm
          </Button>
          <Button onClick={handleClose} disabled={inProgress} color="primary" variant="outlined" >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}
    
export default connect(
  mapStateToProps, { showSnackBar }
)(AssignmentPublishPopup);

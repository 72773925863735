import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AlertMessages from '../Common/AlertMessages';
import { ERROR_CREATE_ASSESSMENT } from '../Common/Constants/errorMessages';
import { fetchAssessment, createAssessment } from '../../store/Assessments/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator'
import { fetchModuleDetails } from '../../store/AddModule/actionCreator'
import { fetchLessonDetails } from '../../store/AddLesson/actionCreator'
import DummyBannerImg from '../../static/bannerDummy.jpg'
import { courseLevel } from '../../Configs/courseLevels'
import Assessment from './assessment'
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles(theme => ({
  courseWrapper: {
    marginTop: 65
  },
  rootWrapper: {
    marginTop: '-17px',
    marginLeft: '43px'
  },
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    marginTop: 15,
    fontSize: '1.5rem'
  },
  field: {
    marginTop: 30,
    backgroundColor: '#f5f5f5'
  },
  courseInfo: {
    position: 'absolute',
    top: 0,
    color: '#f5f5f5',
    backgroundColor: '#cccccc59',
    width: '100%',
    height: '100%',
    padding: 10
  },
  radioField: {
    marginTop: 30,
    display: 'block',
    textAlign: 'left'
  },
  button: {
    flexGrow: 1,
    marginRight: '7px',
    backgroundColor: '#2e8eec',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2e8eec',
    },
  },
  datePicker: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
}));
function AddAssessment(props) {
  const classes = useStyles();
  const [inProgressCourse, setInProgressCourse] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [assessment, setAssessment] = React.useState({});
  const [isAssessmentAdded, setIsAssessmentAdded] = React.useState(false);
  const [testType, setTestType] = React.useState('mandatory');
  const [assessmentDueDate, setAssessmentDueDate] = React.useState('')
  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      title: {
        error: false
      },
      passPercent: {
        error: false
      }
    }
  );
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [courseData, setCourseData] = React.useState({});
  const [erroMessage, setErroMessage] = React.useState('Please fill required informations!');

  //for checking the edit course mode and decoding the base64
  const mainUrl = window.location.href
  const array = mainUrl && mainUrl.split('?')
  const base64EncodeValue = array && array.length && array[1]
  const courseOpenedMode = getCourseOpenModeType();

  const courseSuccessCallBack = res => {

    /*
    fetchAssessment({
      courseId: props.match.params.courseId,
      assessmentId: res.results.assessmentId
    }, fetchAssessmentSuccessCallBack, fetchAssessmentFailureCallBack)
    */
    setInProgressCourse(false)
    setCourseData(res.results)
  }
  const courseFailureCallBack = error => {
    setInProgressCourse(false)
    setCourseData({})
    setAssessment({})
    setIsAssessmentAdded(false)
  }
  const fetchAssessmentSuccessCallBack = res => {
    setAssessment(res.results)
    setIsAssessmentAdded(Object.keys(res.results).length ? true : false)
    setInProgress(false)
  }
  const fetchAssessmentFailureCallBack = error => {
    setAssessment({})
    setIsAssessmentAdded(false)
    setInProgress(false)
  }
  const onAssessmentUpdate = assessmentId => {
    fetchAssessment({
      assessment_id: assessmentId
    }, fetchAssessmentSuccessCallBack, fetchAssessmentFailureCallBack)
  }
  useEffect(() => {
    if (props.match.params.lessonId) {
      setInProgressCourse(true)
      const payload = {
        course_id: props.match.params.courseId,
        module_id: props.match.params.moduleId,
        lesson_id: props.match.params.lessonId
      }
      fetchLessonDetails(payload, courseSuccessCallBack, courseFailureCallBack)
    }
    else if (props.match.params.moduleId) {
      setInProgressCourse(true)
      const payload = {
        course_id: props.match.params.courseId,
        module_id: props.match.params.moduleId
      }
      fetchModuleDetails(payload, courseSuccessCallBack, courseFailureCallBack)
    }
    else if (props.match.params.courseId) {
      setInProgressCourse(true)
      const payload = {
        id: props.match.params.courseId
      }
      courseDetailsFetch(payload, courseSuccessCallBack, courseFailureCallBack)
    }

    if (props.selectedAssessmentId) {
      onAssessmentUpdate(props.selectedAssessmentId)
    }
  }, []);

  const successCallBack = res => {
    fetchAssessment({
      // course_id: props.match.params.courseId,
      assessment_id: res.assessment_id
    }, fetchAssessmentSuccessCallBack, fetchAssessmentFailureCallBack)
    if (props && props.from === 'externalType') {
      props.listAllAssessment()
    }
  }
  const failureCallBack = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_CREATE_ASSESSMENT,
        type: 'error'
      }
    )
    setInProgress(false)
  }
  function validatePercentage(s) {
    return s.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) != null;
  }
  const validateAndSetPercentage = perCent => {
    let validInput = false

    const isValidNum = validatePercentage(perCent);
    if (isValidNum && !isNaN(perCent) && perCent > 0 && perCent < 100 && perCent % 1 === 0) {
      validInput = true
    }
    return validInput
  }
  const initiateAssessment = evnt => {
    evnt.preventDefault()
    const title = document.getElementById('title').value
    const description = document.getElementById('description').value
    let perCent = document.getElementById('passPercent').value

    const isValidPercentage = validateAndSetPercentage(perCent)
    if (title === '') {
      setFormFieldStatus(
        {
          title: {
            error: true
          },
          passPercent: {
            error: false
          }
        }
      )
      setIsFormValid(false)
    } else {
      if (!isValidPercentage) {
        setFormFieldStatus(
          {
            title: {
              error: false
            },
            passPercent: {
              error: true
            }
          }
        )
        setErroMessage('Please fill valid percentage!')
        setIsFormValid(false)
      } else if (props && props.from === 'externalType' && !assessmentDueDate) {
        setFormFieldStatus(
          {
            title: {
              error: false
            },
            passPercent: {
              error: false
            }
          }
        )
        setErroMessage('Please add assessment due date!')
        setIsFormValid(false)
      } else {
        setFormFieldStatus(
          {
            title: {
              error: false
            },
            passPercent: {
              error: false
            }
          }
        )
        setIsFormValid(true)
        setInProgress(true)
        if (props.match.params.lessonId) {
          const payload = {
            lesson_id: props.match.params.lessonId,
            title: title,
            desc: description,
            test_type: testType,
            percent: perCent
          }
          createAssessment(payload, successCallBack, failureCallBack)
        }
        else if (props.match.params.moduleId) {
          const payload = {
            module_id: props.match.params.moduleId,
            title: title,
            desc: description,
            test_type: testType,
            percent: perCent
          }
          createAssessment(payload, successCallBack, failureCallBack)
        } else if (props.match.params.courseId) {
          const payload = {
            course_id: props.match.params.courseId,
            title: title,
            desc: description,
            test_type: testType,
            percent: perCent
          }
          createAssessment(payload, successCallBack, failureCallBack)
        } else if (props.from === 'externalType') {
          const payload = {
            title: title,
            desc: description,
            test_type: testType,
            percent: perCent,
            assessment_duedate: assessmentDueDate
          }
          createAssessment(payload, successCallBack, failureCallBack)
        }
      }
    }
  }
  const getCourseLevel = levelId => {
    if (levelId) {
      return find(courseLevel, { 'id': levelId }).name || 'NA'
    }
  }
  const triggerTestType = evnt => {
    setTestType(evnt.target.value)
  }
  const navigateToCourse = () => {
    if (props.match.params.lessonId) {
      if (base64EncodeValue) {
        props.history.push(`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${props.match.params.courseId}/${props.match.params.moduleId}/${courseData.id}`)
      }
    }
    else if (props.match.params.moduleId) {
      if (base64EncodeValue) {
        props.history.push(`/mycourse/${props.match.params.courseId}/${courseData.id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${props.match.params.courseId}/${courseData.id}`)
      }
    }
    else if (props.match.params.courseId) {
      if (base64EncodeValue) {
        props.history.push(`/mycourse/${courseData.id}?${base64EncodeValue}`)
      } else {
        props.history.push(`/mycourse/${courseData.id}`)
      }
    }
  }
  const renderingAssessmentLevel = () => {
    if (props.match.params && props.match.params.lessonId) {
      return (
        <Assessment lessonId={props.match.params.lessonId} assessment={assessment} onAssessmentUpdate={onAssessmentUpdate} />
      )
    }
    else if (props.match.params && props.match.params.moduleId) {
      return (
        <Assessment moduleId={props.match.params.moduleId} assessment={assessment} onAssessmentUpdate={onAssessmentUpdate} />
      )
    }
    else if (props.match.params && props.match.params.courseId) {
      return (
        <Assessment courseId={props.match.params.courseId} assessment={assessment} onAssessmentUpdate={onAssessmentUpdate} />
      )
    } else if (props.from === 'externalType') {
      return (
        <Assessment from={props.from} clickType={props.clickType} assessment={assessment} onAssessmentUpdate={onAssessmentUpdate} listAllAssessment={props.listAllAssessment} cancelEditAssessment={props.cancelAddAssessment} />
      )
    }
  }
  return (
    <div>
      <div className={props.from === 'externalType' ? classes.rootWrapper : classes.courseWrapper}>
        {inProgressCourse ? (
          <LinearProgress />
        ) : null}
        {!inProgressCourse && props.from !== 'externalType' ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div style={{ position: 'relative', marginTop: '-1px', textAlign: 'left' }}>
                <img
                  title={courseData.title}
                  alt={courseData.title}
                  src={DummyBannerImg}
                  style={{
                    width: '100%',
                    height: 75
                  }}
                />
                <div className={classes.courseInfo}>
                  <Typography variant="h4" style={{ fontSize: '1.3rem', cursor: 'pointer' }} onClick={navigateToCourse}>
                    {courseData.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {getCourseLevel(courseData.level)}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : null}
      </div>
      {!isAssessmentAdded ? (
        <div className={classes.root}>
          <div id="mainH1Tag" tabIndex="-1" className="accessibility">
            <h1>Add assessment form</h1>
          </div>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {!isFormValid ? (
                <div style={{ marginTop: '20px', width: '42%', marginBottom: '18px', display: 'inline-block' }}>
                  <AlertMessages status="error" message={erroMessage} />
                </div>
              ) : null}
              <form noValidate autoComplete="off" onSubmit={initiateAssessment}>
                <TextField
                  id="title"
                  label="Assessment title"
                  helperText="max: 1000 chars"
                  required
                  error={formFieldStatus.title.error}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  inputProps={{
                    maxLength: 1000,
                    className: classes.field
                  }}
                  autoFocus={true}
                  style={{
                    width: '100%'
                  }}
                />
                <TextField
                  id="description"
                  label="Description"
                  helperText="brief information about this assessment"
                  multiline
                  rows="4"
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  inputProps={{
                    className: classes.field
                  }}
                  style={{
                    width: '100%'
                  }}
                />
                <FormControl component="fieldset" className={classes.radioField}>
                  <FormLabel component="legend">Priority</FormLabel>
                  <RadioGroup row aria-label="position" name="position" defaultValue="mandatory" onChange={triggerTestType}>
                    <FormControlLabel
                      value="mandatory"
                      control={<Radio color="primary" />}
                      label="Mandatory Assessment"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="practice"
                      control={<Radio color="primary" />}
                      label="Practice Test"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  id="passPercent"
                  label="Minimum percentage to pass"
                  type="number"
                  helperText="Only for display purpose for practice tests"
                  required={true}
                  error={formFieldStatus.passPercent.error}
                  InputLabelProps={{
                    shrink: true,
                    className: classes.label
                  }}
                  inputProps={{
                    maxLength: 3,
                    className: classes.field
                  }}
                  style={{
                    width: '50%',
                    display: 'block',
                    textAlign: 'left'
                  }}
                />
                <div style={{ marginTop: '10px', marginBottom: '20px', textAlign: 'initial' }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      {props && props.from === 'externalType' ? (
                        <React.Fragment>
                          <FormHelperText style={{ fontSize: '15px', fontWeight: 500, marginBottom: '8px', marginLeft: '5px' }}>Assessment Due Date *</FormHelperText>
                          <DatePicker
                            aria-label="select assignment start date"
                            className={classes.datePicker}
                            selected={assessmentDueDate}
                            onChange={date => setAssessmentDueDate(date)}
                            minDate={new Date()}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Click to select a date"
                          />
                        </React.Fragment>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
                {courseOpenedMode !== 'visit_mode' ? (
                  <React.Fragment>
                    <Button variant="contained" className={classes.button} onClick={initiateAssessment} disabled={inProgress}>
                      {inProgress ? (
                        <CircularProgress size={30} style={{ marginRight: 10 }} />
                      ) : null}
                      Create Assessment
                    </Button>
                    <Button variant="contained" disabled={inProgress} onClick={props.cancelAddAssessment}>Cancel</Button>
                  </React.Fragment>
                ) : null}
              </form>
            </Paper>
          </Grid>
        </div>
      ) : (
        <div>
          {renderingAssessmentLevel()}
        </div>
      )}


    </div>
  )
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AddAssessment);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { getCourseOpenModeType } from '../../Helpers/basic';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: 100
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
  },
  noDesc: {
    color: 'orange'
  }
});

export default function ListCard(props) {
  const classes = useStyles();
  const assessment = props.assessment
  const courseOpenedMode = getCourseOpenModeType();

  const navigateToAssessment = () => {
    props.navigateToAssessment(assessment.id)
  }
  const onDeleteClick = () => {
    props.onTriggerDelete(props.assessment)
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">
              {assessment.title}
            </Typography>
            {assessment.test_type ? (
              <Typography className={classes.pos} color="textSecondary">
                {assessment.test_type}
              </Typography>
            ) : null}
            <Typography className={classes.pos} color="textSecondary">
              <span className={classes.label}>Questions: </span>{assessment.ques_count}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2">
              {assessment.desc ? assessment.desc : (
                <span className={classes.noDesc}>No description added</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <IconButton disabled={courseOpenedMode === 'visit_mode'} aria-label="delete assessment" className={classes.margin} onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
            <IconButton aria-label="edit assessment" disabled={courseOpenedMode === 'edit_mode'} className={classes.margin} onClick={navigateToAssessment}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
//local imports
import { style } from '../../Styles/theme';
import CreateGroup from './createGroup';
import { fetchGroupsList } from '../../store/ManageGroups/actionCreator';
import { ERROR_FETCH_GROUPS_LIST } from '../Common/Constants/errorMessages'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import GroupsListingView from './groupsListing';
import ManageGrpLoader from '../Common/skeletonLoaders/manageGrpLoader';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    backgroundColor: style.backgroundColor._lightWhite,
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '50px',
      paddingRight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: '100%'
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  }
}))

function ManageGroups(props) {
  const classes = useStyles()
  let rowsPerPage = 10
  let offset = 0
  const [page, setPage] = React.useState(0)
  const [paginationLoader, setPaginationLoader] = useState(false)
  const [selectedMode, setSelectedMode] = useState('')
  const [inProgress, setInProgress] = useState(true)
  //for groups listing data
  const [totalCount, setTotalCount] = useState(0)
  const [groupsData, setGroupsData] = useState([])
  //for edit group data
  const [editGrpData, setEditGrpData] = useState({})

  //FOR FETCH GROUPS LIST CALLBACKS
  const successFetchList = res => {
    setInProgress(false)
    setPaginationLoader(false)
    setTotalCount(res && res.count)
    setGroupsData(res && res.data && res.data)
  }
  const failureFetchList = err => {
    setInProgress(false)
    setPaginationLoader(false)
    setTotalCount(0)
    setGroupsData([])
    props.showSnackBar({
      state: false,
      message: ERROR_FETCH_GROUPS_LIST,
      type: 'error'
    })
  }

  //GET GROUPS LIST
  const getGroupsListing = () => {
    setInProgress(true)
    const payload = {
      offset: offset,
      number: 10
    }
    fetchGroupsList(payload, successFetchList, failureFetchList)
  }

  useEffect(() => {
    getGroupsListing()
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  const triggerOnclick = () => {
    setEditGrpData({})
    setSelectedMode('add')
  }
  const closeAddGrpMode = () => {
    setSelectedMode('')
    setEditGrpData({})
  }

  const onPagination = (pageNum) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setPage(pageNum)
    let offset = pageNum * rowsPerPage
    let number = 10
    setPaginationLoader(true)
    const payload = {
      offset: offset,
      number: number
    }
    fetchGroupsList(payload, successFetchList, failureFetchList)
  }

  //FOR EDIT GROUP DATA FUNCTION
  const editGroupDetails = (mode, data) => {
    setSelectedMode(mode)
    setEditGrpData(data)
  }

  return (
    <div className={classes.root}>
      <a className="skip-main" href="#leftMenu">
        Back to main menu
      </a>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Manage Groups View</h1>
      </div>
      <Grid container spacing={1} style={{ justifyContent: 'center' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {inProgress ? (
            <ManageGrpLoader />
          ) : (
              <React.Fragment>
                { groupsData && groupsData.length && selectedMode === '' ? (
                  <div style={{ textAlign: 'right', marginRight: '21px', marginBottom: '10px' }}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      startIcon={<GroupAddIcon />}
                      onClick={triggerOnclick}
                    >
                      <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd' }} aria-hidden="true" />
                    Create Group
                  </Button>
                  </div>
                ) : null}
                { selectedMode === '' ? (
                  <Paper className={classes.paper}>
                    <Typography className={classes.titleWrapper} aria-hidden="true">Manage Groups</Typography>
                    <Divider style={{ marginBottom: '20px' }} aria-hidden="true" />
                    { groupsData && !groupsData.length ? (
                      <React.Fragment>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography style={{ marginTop: '12px', color: '#546e7a' }}>Please click here to create groups and get started</Typography>
                          <Typography style={{ fontSize: '13px', marginTop: '3px', marginBottom: '21px', color: '#9e9e9e', fontWeight: '500' }}>Groups you create will show up here.</Typography>
                        </div>
                        <Button
                          variant="contained"
                          className={classes.button}
                          startIcon={<GroupAddIcon />}
                          onClick={triggerOnclick}
                        >
                          <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd' }} aria-hidden="true" />
                        Create Group
                      </Button>
                      </React.Fragment>
                    ) : (
                        <React.Fragment>
                          <GroupsListingView groupsData={groupsData} paginationLoader={paginationLoader} totalCount={totalCount} onPagination={onPagination} page={page} rowsPerPage={rowsPerPage} editGroupDetails={editGroupDetails} match={props.match} history={props.history} />
                        </React.Fragment>
                      )}
                  </Paper>
                ) : (
                    <CreateGroup getGroupsListing={getGroupsListing} closeAddGrpMode={closeAddGrpMode} editGrpData={editGrpData} match={props.match} history={props.history} />
                  )}
              </React.Fragment>
            )}
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(ManageGroups);
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { showMultiAuthorWarnPopup } from '../../store/Authors/actionCreator';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  titleWrap: {
    textAlign: 'center',
    color: '#2e8eec'
  },
  headWrap: {
    fontSize: '21px',
    fontFamily: 'helvetica',
  },
  infoWrapper: {
    fontSize: '15px',
    marginTop: '-10px',
    fontFamily: 'helvetica',
  },
  btnWrapper: {
    margin: '-12px 36% 8px 0',
    borderRadius: '40px',
    padding: '3px 20px 3px 20px',
    fontFamily: 'helvetica',
    textTransform: 'none',
    backgroundColor: '#2e8eec',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#2e8eec',
      color: '#fff',
    },
  },
  iconWrapper: {
    fontSize: '37px'
  },
  buttonProgress: {
    marginBottom: '18px',
    color: '#2e8eec'
  },
}));

function WarnMultiAuthorPopup(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const [checked, setChecked] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const successCheckPopup = res => {
    handleClose()
    setLoader(false)
  }
  const failureCheckPopup = error => {
    setLoader(false)
    props.showSnackBar(
      {
        state: false,
        message: 'Sorry! something went wrong!',
        type: 'error'
      }
    )
  }

  const onProceedClick = () => {
    if (checked === false) {
      handleClose()
    } else {
      setLoader(true)
      const payload = {
        course_id: props.match.params.courseId,
        show_popup: false
      }
      showMultiAuthorWarnPopup(payload, successCheckPopup, failureCheckPopup)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="information dialog"
        aria-describedby="this dialog is for multi author restrict information"
      >
        <DialogTitle id="title" className={classes.titleWrap}>
          <InfoOutlinedIcon className={classes.iconWrapper} />
          <div className={classes.headWrap}>Note</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="description" className={classes.infoWrapper}>
            Other instuctors can't make edit's on this course
          </DialogContentText>
          <DialogContentText>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: '#2e8eec', fontFamily: 'helvetica' }}
                    size="small"
                    checked={checked}
                    onChange={handleChange}
                    name="chcked"
                    color="primary"
                  />
                }
                label="Do not show this popup again"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ width: '100%' }}>
          {loader ? (
            <CircularProgress size={28} className={classes.buttonProgress} />
          ) : null}
          <Button disabled={loader} onClick={() => { onProceedClick() }} variant="contained" className={classes.btnWrapper}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(WarnMultiAuthorPopup);
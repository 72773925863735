import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Publish';
import AddVideos from './addVideos'
import VideosList from './videosListView'
import Divider from '@material-ui/core/Divider';
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import { listVideos } from '../../store/MyVideos/actionCreator'
import { ERROR_FETCHING_VIDEOS } from '../Common/Constants/errorMessages'
import MyVideosLoader from '../Common/skeletonLoaders/myVideosLoader'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 65
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  breadCrumbcontainer: {
    padding: '15px',
    marginLeft: '10px',
    justifyContent: "center",
    marginTop: '10px'
  },
  button: {
    flexGrow: 1,
    marginRight: '38px',
    marginTop: '16px',
    backgroundColor: '#3098FF',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#3098FF',
    },
  },
}))

function MyVideos(props) {
  const classes = useStyles();
  const [videosList, setVideosList] = React.useState([])
  const [addVideoMode, setAddVideoMode] = React.useState(false)
  const [inProgress, setInprogress] = React.useState(false)

  const videosSuccessCB = (res) => {
    setInprogress(false)
    setVideosList(res && res.results && res.results)
  }

  const videosFailureCB = error => {
    setInprogress(false)
    setVideosList([])
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_VIDEOS,
        type: 'error'
      }
    )
  }

  const listAllVideos = () => {
    listVideos({}, videosSuccessCB, videosFailureCB)
  }

  useEffect(() => {
    setInprogress(true)
    listAllVideos()
    document.getElementById('mainH1Tag') && document.getElementById('mainH1Tag').focus()
  }, [])

  const triggerAddVideo = () => {
    setAddVideoMode(true)
  }
  const closeAddVideoMode = () => {
    setAddVideoMode(false)
  }

  return (
    <div className={classes.root}>
      { inProgress ? (<MyVideosLoader />) : (
        <React.Fragment>
          { addVideoMode ? (
            <AddVideos match={props.match} history={props.history} cancelAddVideos={closeAddVideoMode} listAllVideos={listAllVideos} />
          ) : (
              <React.Fragment>
                { videosList && videosList.length ? (
                  <div style={{ textAlign: 'right', marginRight: '21px' }}>
                    <Button
                      variant="contained"
                      className={classes.button}
                      startIcon={<PublishIcon />}
                      onClick={triggerAddVideo}
                    >
                      <Divider orientation="vertical" flexItem style={{ marginRight: '10px', backgroundColor: '#bdbdbd' }} aria-hidden="true" />
                  Upload video
                </Button>
                  </div>
                ) : null}
              </React.Fragment>
            )}
          { inProgress ? (<MyVideosLoader />) : (
            <React.Fragment>
              {videosList && !addVideoMode ? (
                <VideosList videosList={videosList} history={props.history} match={props.match} listAllVideos={listAllVideos} triggerAddVideo={triggerAddVideo} />
              ) : null}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(MyVideos);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import { filter } from 'lodash';
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListingLoader from '../Common/skeletonLoaders/listingLoader'
import Header from './header'
import { convertUTCTimeZone } from '../../Helpers/basic'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import { courseDetailsFetch } from '../../store/AddingCourse/actionCreator';
import { fetchModuleDetails } from '../../store/AddModule/actionCreator'
import { fetchLessonDetails } from '../../store/AddLesson/actionCreator'
import { fetchAssignment, assignmentReport } from '../../store/Assignments/actionCreator'
import { ERROR_FETCHING_ASSIGNMENT, ERROR_FETCHING_ASSIGNMENT_REPORT, ERROR_FETCH_COURSE_DETAILS, ERROR_FETCH_MODULE_DETAILS } from '../Common/Constants/errorMessages';
import TabView from './tabView'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 56
  },
  rootWrap: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: -27
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  button: {
    textTransform: 'none',
    flexGrow: 1,
    marginTop: '10px'
  },
  breadCrumbcontainer: {
    padding: '15px',
    marginLeft: '10px',
    justifyContent: "center",
    marginTop: '10px'
  }, 
  textWrapper: {
    fontWeight: 500,
    color: '#2e8eec',
    fontSize: '19px',
    textAlign: 'center',
    marginTop: '11px'
  },
  dueDateWrapper: {
    color: '#2e8eec',
    textAlign: 'center',
    marginTop: '-2px',
    fontSize: '16px',
    fontWeight: 500
  },
}))

function AssessmentReport(props) {
  const classes = useStyles();
  const [inProgress, setInprogress] = React.useState(false)
  const [courseDetails, setCourseDetails] = React.useState({})
  const [courseInfo, setCourseInfo] = React.useState({})
  const [moduleInfo, setModuleInfo] = React.useState({})
  const [assignmentDetails, setAssignmentDetails] = React.useState({})
  const [assignmeReportDetails, setAssignmentReportDetails] = React.useState({
    completed: [],
    pending: []
  })

  const courseSuccessCallBack = res => {
    setCourseDetails(res.results)
  }

  const courseFailureCallBack = error => {
    setCourseDetails({})
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_ASSIGNMENT,
        type: 'error'
      }
    )
   }

   const courseInfoSuccessCallBack = res => {
     setCourseInfo(res.results)
   }

   const courseInfoFailureCallBack = error => {
     setCourseInfo({})
     props.showSnackBar(
       {
         state: false,
         message: ERROR_FETCH_COURSE_DETAILS,
         type: 'error'
       }
     )
   }

   const moduleInfoSuccessCallBack = res => {
     setModuleInfo(res.results)
   }

   const moduleInfoFailureCallBack = error => {
     setModuleInfo({})
     props.showSnackBar(
       {
         state: false,
         message: ERROR_FETCH_MODULE_DETAILS,
         type: 'error'
       }
     )
   }

  const assignmentSuccessCallBack = res => {
    setAssignmentDetails(res.results)
  }
  const assignmentFailureCallBack = error => {
    setAssignmentDetails({})
    props.showSnackBar(
        {
          state: false,
          message: ERROR_FETCHING_ASSIGNMENT,
          type: 'error'
        }
    )
  }

  const getSegregatedReport = results => {
    return{
      completed: filter(results, { 'status' : 'completed' }),
      pending: filter(results, function(o) { return !(o.status === 'completed') })
    }
  }

  const reportSuccessCallBack = res => {
    const segregatedReport = getSegregatedReport(res.results)
    setAssignmentReportDetails(segregatedReport)
    setInprogress(false)
  }

  const reportFailureCallBack = error => {
    setAssignmentReportDetails([])
    setInprogress(false)
    props.showSnackBar(
        {
          state: false,
          message: ERROR_FETCHING_ASSIGNMENT_REPORT,
          type: 'error'
        }
    )
  }

  const assignmentReportFetch = () => {
    setInprogress(true)
    assignmentReport({
      assignment_id: props.match.params.assignmentId
    }, reportSuccessCallBack, reportFailureCallBack)
  }

  useEffect(() =>{
    if(props.match.params.lessonId){
      const payload = {
        course_id: props.match.params.courseId,
        module_id: props.match.params.moduleId,
        lesson_id: props.match.params.lessonId,
      }
      fetchLessonDetails(payload, courseSuccessCallBack, courseFailureCallBack)
      const payLoad = {
        course_id: props.match.params.courseId,
        module_id: props.match.params.moduleId
      }
      fetchModuleDetails(payLoad, moduleInfoSuccessCallBack, moduleInfoFailureCallBack)
      courseDetailsFetch({id: props.match.params.courseId}, courseInfoSuccessCallBack, courseInfoFailureCallBack)
    }
    else if(props.match.params.moduleId){
      const payload = {
        course_id: props.match.params.courseId,
        module_id: props.match.params.moduleId
      }
      fetchModuleDetails(payload, courseSuccessCallBack, courseFailureCallBack)
      courseDetailsFetch({id: props.match.params.courseId}, courseInfoSuccessCallBack, courseInfoFailureCallBack)
    }
    else if(props.match.params.courseId){
      courseDetailsFetch({
        id: props.match.params.courseId
      }, courseSuccessCallBack, courseFailureCallBack)
    }
    
    const payload = {
      assignment_id: props.match.params.assignmentId
    }
    fetchAssignment(payload, assignmentSuccessCallBack, assignmentFailureCallBack)

    assignmentReportFetch()
  },[])
  
  const dateFormat = (due_days) => {
    return(`Due Date: ${convertUTCTimeZone(due_days)}`)
  }

  const listLoader = count => {
    let children = []
    for (let i = 0; i < 3; i++) {
     children.push(<ListItem key={i} alignItems="flex-start"><ListingLoader/></ListItem>)
    }
    return (
      <List>
        {children}
      </List>
    )
   }

  return (
    <div className={props.from === 'externalType' ? classes.rootWrap : classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>Assignment report page</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={0} className={classes.topButtonNav}>
            <div className={classes.breadCrumbcontainer}>
              <Header histoy={props.history} match={props.match} courseDetails={courseDetails} courseInfo={courseInfo} moduleInfo={moduleInfo} assignmentDetails={assignmentDetails}/>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          { inProgress ? (
            listLoader()
          ) :(
            <React.Fragment>
              <Typography className={classes.textWrapper}>{ assignmentDetails && assignmentDetails.title ? assignmentDetails.title : '' }</Typography>
              <Typography className={classes.dueDateWrapper}>{dateFormat(assignmentDetails && assignmentDetails.duration_details)} {assignmentDetails && assignmentDetails.points ? `| ${assignmentDetails.points} pts` : null }</Typography>
              <TabView histoy={props.history} match={props.match} assignmentReportFetch={assignmentReportFetch} assignmeReportDetails={assignmeReportDetails} assignmentDetails={assignmentDetails}/>
            </React.Fragment>
          )}
        </Grid>
      </Grid>    
    </div>
  )
}
const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps, { showSnackBar }
)(AssessmentReport);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import AddIcon from '@material-ui/icons/Add';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ERROR_UPDATE_ASSESSMENT, ERROR_FETCHING_QUESTIONS } from '../Common/Constants/errorMessages';
import { SUCCESS_UPDATE_ASSESSMENT } from '../Common/Constants/successMessages';
import { updateAssessment, fetchAllQuestions } from '../../store/Assessments/actionCreator'
import { showSnackBar } from '../../store/AlertMessages/actionCreator';
import AlertMessages from '../Common/AlertMessages';
import AssessmentQuestion from './assessmentQuestion';
import { getCourseOpenModeType } from '../../Helpers/basic';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#f5f5f5'
  },
  tab: {
    fontSize: '12px',
    textTransform: 'none'
  },
  tabPanel: {
    flexGrow: 1
  },
  label: {
    marginTop: 15,
    fontSize: '1.5rem'
  },
  field: {
    marginTop: 30,
    backgroundColor: '#f5f5f5'
  },
  radioField: {
    marginTop: 30,
    display: 'block',
    textAlign: 'left'
  },
  topButtonNav: {
    backgroundColor: '#f5f5f5',
    padding: 10,
    textAlign: 'left'
  },
  button: {
    flexGrow: 1,
    marginRight: '38px',
    backgroundColor: '#2e8eec',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#2e8eec',
    },
  },
  datePicker: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
}));

function Assessment(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [inProgress, setInProgress] = React.useState(false);
  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      title: {
        error: false
      },
      passPercent: {
        error: false
      }
    }
  );
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [questions, setQuestions] = React.useState([]);
  const [questionsFetchingInProgress, setQuestionsFetchingInProgress] = React.useState(false);
  const [testType, setTestType] = React.useState(props.assessment.test_type || 'mandatory');
  const [passPercent, setPassPercent] = React.useState(props.assessment.percent || '');

  const defaultDueDate = props.assessment && props.assessment.due_date ? new Date(props.assessment.due_date) : ''
  const [assessmentDueDate, setAssessmentDueDate] = React.useState(defaultDueDate)
  const courseOpenedMode = getCourseOpenModeType();

  const fetchQuestionsSuccess = res => {
    setQuestions(res)
    setQuestionsFetchingInProgress(false)
  }
  const fetchQuestionsFailure = error => {
    setQuestions([])
    setQuestionsFetchingInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_FETCHING_QUESTIONS,
        type: 'error'
      }
    )
  }
  const fetchQuestions = () => {
    setQuestionsFetchingInProgress(true)
    fetchAllQuestions({
      assessment_id: props.assessment.id,
    }, fetchQuestionsSuccess, fetchQuestionsFailure)
  }
  useEffect(() => {
    fetchQuestions()
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const successCallBack = res => {
    props.onAssessmentUpdate(props.assessment.id)
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: SUCCESS_UPDATE_ASSESSMENT,
        type: 'success'
      }
    )
    if (props && props.from === 'externalType') {
      props.listAllAssessment()
    }
  }
  const failureCallBack = error => {
    setInProgress(false)
    props.showSnackBar(
      {
        state: false,
        message: ERROR_UPDATE_ASSESSMENT,
        type: 'error'
      }
    )
  }
  const updateAssessmentInfo = evnt => {
    evnt.preventDefault()
    const title = document.getElementById('title').value
    const description = document.getElementById('description').value
    if (title === '') {
      setFormFieldStatus(
        {
          title: {
            error: true
          },
          passPercent: {
            error: false
          }
        }
      )
      setIsFormValid(false)
    } else if (!passPercent) {
      setFormFieldStatus(
        {
          title: {
            error: false
          },
          passPercent: {
            error: true
          }
        }
      )
      setIsFormValid(false)
    } else if (props.from === 'externalType' && !assessmentDueDate) {
      setFormFieldStatus(
        {
          title: {
            error: false
          },
          passPercent: {
            error: false
          }
        }
      )
      setIsFormValid(false)
    } else {
      setFormFieldStatus(
        {
          title: {
            error: false
          },
          passPercent: {
            error: false
          }
        }
      )
      setIsFormValid(true)
      setInProgress(true)
      if (props.lessonId) {
        const payload = {
          lesson_id: Number(props.lessonId),
          assessment_id: props.assessment.id,
          title: title,
          desc: description,
          test_type: testType,
          percent: passPercent
        }
        updateAssessment(payload, successCallBack, failureCallBack)
      }
      else if (props.moduleId) {
        const payload = {
          module_id: Number(props.moduleId),
          assessment_id: props.assessment.id,
          title: title,
          desc: description,
          test_type: testType,
          percent: passPercent
        }
        updateAssessment(payload, successCallBack, failureCallBack)
      }
      else if (props.courseId) {
        const payload = {
          course_id: Number(props.courseId),
          assessment_id: props.assessment.id,
          title: title,
          desc: description,
          test_type: testType,
          percent: passPercent
        }
        updateAssessment(payload, successCallBack, failureCallBack)
      } else if (props.from === 'externalType') {
        const payload = {
          assessment_id: props.assessment.id,
          title: title,
          desc: description,
          test_type: testType,
          percent: passPercent
        }
        updateAssessment(payload, successCallBack, failureCallBack)
      }
    }
  }
  const getTabs = () => {
    let tabs = [
      {
        question: 'Assessment Details'
      },
      ...questions,
      {
        question: 'Add Question'
      }
    ]
    return tabs
  }
  const triggerAddQuestion = () => {
    setValue(getTabs().length - 1)
  }
  const formatedTabQuestion = (index, question) => {
    return (
      <span>
        {index > 0 && question !== 'Add Question' ? (
          <span>{index}. </span>
        ) : null}
        <span>{question.substr(0, 80)} {(question.length > 80 && index > 0 && question !== 'Add Question') ? '...' : ''}</span>
      </span>
    )
  }
  const triggerTestType = event => {
    setTestType(event.target.value)
  }
  const validateAndSetPercentage = evnt => {
    let perCent = evnt.target.value
    if (perCent < 0 || perCent > 100) {
      perCent = passPercent
    }
    setPassPercent(perCent)
  }
  const renderingAssessmentLevel = () => {
    if (props.lessonId) {
      return (
        <AssessmentQuestion lessonId={props.lessonId} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    }
    else if (props.moduleId) {
      return (
        <AssessmentQuestion moduleId={props.moduleId} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    }
    else if (props.courseId) {
      return (
        <AssessmentQuestion courseId={props.courseId} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    } else if (props.from === 'externalType') {
      return (
        <AssessmentQuestion from={props.from} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    }
  }
  const renderingAssessmentElsepart = (row) => {
    if (props.lessonId) {
      return (
        <AssessmentQuestion questionDetails={row} lessonId={props.lessonId} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    }
    if (props.moduleId) {
      return (
        <AssessmentQuestion questionDetails={row} moduleId={props.moduleId} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    }
    else if (props.courseId) {
      return (
        <AssessmentQuestion questionDetails={row} courseId={props.courseId} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    } else if (props.from === 'externalType') {
      return (
        <AssessmentQuestion from={props.from} questionDetails={row} assessmentId={props.assessment.id} onQuestionAction={fetchQuestions} />
      )
    }
  }
  return (
    <div style={{ marginTop: props.from === 'externalType' && !props.clickType ? '73px' : '', marginLeft: props.from === 'externalType' && props.clickType === 'editBtnClick' ? '-45px' : '' }}>
      <Paper className={classes.topButtonNav}>
        <div>
          {courseOpenedMode !== 'visit_mode' && <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={triggerAddQuestion}
          >
            Add Question
          </Button>}
        </div>
        {props && props.from === 'externalType' && !props.clickType ? (
          <div style={{ textAlign: 'right', marginTop: '-42px' }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.cancelEditAssessment}
            >
              Close
            </Button>
          </div>
        ) : null}
      </Paper>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Assessment"
          className={classes.tabs}
        >
          {!questionsFetchingInProgress && getTabs().map((row, key) => (
            <Tab key={key} className={classes.tab} label={formatedTabQuestion(key, row.question)} {...a11yProps(key)} />
          ))}
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabPanel}>
          {!isFormValid ? (
            <div style={{ marginTop: '20px', width: '42%', marginBottom: '18px', display: 'inline-block' }}>
              <AlertMessages status="error" message="Please fill required informations" />
            </div>
          ) : null}
          <form noValidate autoComplete="off" onSubmit={updateAssessmentInfo}>
            <TextField
              id="title"
              label="Assessment title"
              helperText="max: 1000 chars"
              defaultValue={props.assessment.title}
              required
              error={formFieldStatus.title.error}
              InputLabelProps={{
                shrink: true,
                className: classes.label
              }}
              inputProps={{
                maxLength: 1000,
                className: classes.field
              }}
              autoFocus={true}
              style={{
                width: '100%'
              }}
            />
            <TextField
              id="description"
              label="Description"
              helperText="brief information about this assessment"
              defaultValue={props.assessment.desc}
              multiline
              rows="4"
              InputLabelProps={{
                shrink: true,
                className: classes.label
              }}
              inputProps={{
                className: classes.field
              }}
              style={{
                width: '100%'
              }}
            />
            <FormControl component="fieldset" className={classes.radioField}>
              <FormLabel component="legend">Priority</FormLabel>
              <RadioGroup row aria-label="position" name="position" value={testType} defaultValue="mandatory" onChange={triggerTestType}>
                <FormControlLabel
                  value="mandatory"
                  control={<Radio color="primary" />}
                  label="Mandatory Assessment"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="practice"
                  control={<Radio color="primary" />}
                  label="Practice Test"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              id="passPercent"
              defaultValue={props.assessment.percent}
              label="Minimum percentage to pass"
              type="number"
              helperText="max: 3 digits"
              value={passPercent}
              required={true}
              error={formFieldStatus.passPercent.error}
              InputLabelProps={{
                shrink: true,
                className: classes.label
              }}
              inputProps={{
                maxLength: 254,
                className: classes.field
              }}
              style={{
                width: '50%',
                display: 'block',
                textAlign: 'left'
              }}
              onChange={validateAndSetPercentage}
            />
            <div style={{ marginTop: '10px', marginBottom: '20px', textAlign: 'initial' }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                  {props && props.from === 'externalType' ? (
                    <React.Fragment>
                      <FormHelperText style={{ fontSize: '15px', fontWeight: 500, marginBottom: '8px', marginLeft: '5px' }}>Assessment Due Date *</FormHelperText>
                      <DatePicker
                        aria-label="select assignment start date"
                        className={classes.datePicker}
                        selected={assessmentDueDate}
                        onChange={date => setAssessmentDueDate(date)}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Click to select a date"
                      />
                    </React.Fragment>
                  ) : null}
                </Grid>
              </Grid>
            </div>
            {courseOpenedMode !== 'visit_mode' ? (
              <React.Fragment>
                <Button variant="contained" color="primary" className={classes.button} onClick={updateAssessmentInfo} disabled={inProgress}>
                  {inProgress ? (
                    <CircularProgress size={30} style={{ marginRight: 10 }} />
                  ) : null}
                  Update
                </Button>
              </React.Fragment>
            ) : null}
            {props && props.from === 'externalType' ? (
              <Button variant="contained" onClick={props.cancelEditAssessment} disabled={inProgress} style={{ marginLeft: '10px' }}>
                Cancel
              </Button>
            ) : null}
          </form>
        </TabPanel>
        {getTabs().map((row, key) => (
          <React.Fragment key={key}>
            {key > 0 ? (
              <TabPanel value={value} index={key} className={classes.tabPanel} style={{ backgroundColor: '#fcfcfc' }}>
                {key === (getTabs().length - 1) ? (
                  renderingAssessmentLevel()
                ) : (
                  renderingAssessmentElsepart(row)
                )}
              </TabPanel>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(Assessment);

import React from 'react';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertMessages from '../Common/AlertMessages';
import { updateGrade } from '../../store/GradeBook/actionCreator';
import { validateAndSetPercentage } from '../../Helpers/basic';

export default function GradePopper(props) {
  const gradingObj = props.gradingObj
  const [fieldStatus, setFieldStatus] = React.useState(
    {
      error: false,
      inProgress: false
    }
  )
  const onUpdateSuccessCallBack = res => {
    setFieldStatus(
      {
        error: false,
        inProgress: false
      }
    )
    props.onUpdateSuccess()
  }
  const onUpdateFailureCallBack = error => {
    setFieldStatus(
      {
        error: false,
        inProgress: false
      }
    )
    props.onUpdateFailure()
  }
  const validateAndUpdateGrade = event => {
    event.preventDefault()
    const assignedGrade = document.getElementById('assignedGrade').value
    const remarks = document.getElementById('grade_remarks').value
    if(!validateAndSetPercentage(assignedGrade)) {
      setFieldStatus(
        {
          ...fieldStatus,
          error: true
        }
      )
    } else {
      setFieldStatus(
        {
          ...fieldStatus,
          error: false,
          inProgress: true
        }
      )
      updateGrade(
        {
        course_id: Number(gradingObj.course_id),
        user_id: Number(gradingObj.user_id),
        overall_grade: assignedGrade,
        remark: remarks
      },
      onUpdateSuccessCallBack,
      onUpdateFailureCallBack
      )
    }
  }
  const closeGrader = () => {
    setFieldStatus(
      {
        error: false,
        inProgress: false
      }
    )
    props.onClose()
  }
  return (
    <Popper
      open={gradingObj.open}
      anchorEl={gradingObj.graderElement}
      placement={props.location ? props.location : 'left'}
      transition
      style={{ zIndex: 10000, width: props.width ? props.width : '40%', border: '1px solid #ccc' }}
      disablePortal={true}
    >
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <Paper style={{ padding: 20 }} square>
          {fieldStatus.error ? (
            <div style={{marginTop: '20px', width: '100%', marginBottom: '18px', display: 'inline-block'}}>
              <AlertMessages status="error" message="Please enter valid grade" />
            </div>
          ) : null}
          <form noValidate autoComplete="off" onSubmit={validateAndUpdateGrade}>
            <TextField
              id="assignedGrade"
              label="Grade in %"
              variant="outlined"
              type="number"
              autoFocus={true}
              defaultValue={gradingObj.grade}
              helperText="This will be the final grade for this student"
              required={true}
              error={fieldStatus.error}
              maxLength={3}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                maxLength: 3
              }}
              style={{
                width: '100%',
                display: 'block',
                marginBottom: 20,
                textAlign: 'left'
              }}
            />
            <TextField
              id="grade_remarks"
              label="Remark"
              fullWidth
              multiline
              rows={4}
              defaultValue={gradingObj.remarks}
              variant="outlined"
              style={{ marginBottom: 20, textAlign: 'left' }}
            />
            <Button variant="contained" color="primary" onClick={validateAndUpdateGrade} disabled={fieldStatus.inProgress}>
              {fieldStatus.inProgress ? (
                <CircularProgress size={30} style={{ marginRight: 10 }}/>
              ) : null}
              Update
            </Button>
            <Button variant="contained" onClick={closeGrader} style={{ marginLeft: 15 }} disabled={fieldStatus.inProgress}>Close</Button>
          </form>
        </Paper>
      </Fade>
    )}
  </Popper>
  )
}

import axios from 'axios';
import { apiConfig } from '../../Configs/apiConfig'


export const listAssessments = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.listAssessments}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const createAssessment = (formData, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.create}`
  axios.post(url,formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
export const updateAssessment = (formData, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.update}${formData.assessment_id}/`
  axios.put(url,formData)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}
export const deleAssessment = (formData, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.deleteAssessment}${formData.assessment_id}/`
  axios.delete(url, {
    data: formData
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchAssessment = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.fetchAssessment}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

export const fetchAllQuestions = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.allQuestions}`
  axios.get(url, {
    params: payLoad})
  .then(function (response) {
    successCallBack(response.data.results.quiz)
  })
  .catch(function (error) {
      failureCallBack(error)
  })
}

export const addQuestion = (formData, successCallBack, failureCallBack) => {

  let url =`${apiConfig.assessment.question}`
  if(formData.id) {
    url += `${formData.id}/`
  }
  if(formData.id) {
    axios.put(url,formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
  } else {
    axios.post(url,formData)
    .then(function (response) {
      successCallBack(response.data)
    })
    .catch(function (error) {
      failureCallBack(error)
    })
  }
}

const mockAssessmentsData = {
  totalCount: 20,
  results: [
    {
      "id": 104,
      "title": "Test assessment-8",
      "desc": "Testing",
      "percent": 90,
      "published": false,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 12,
      "title": "Test assessment-7",
      "desc": "Testing",
      "percent": 80,
      "published": false,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 6,
      "title": "Test assessment-6",
      "desc": "Testing",
      "percent": 90,
      "published": false,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 5,
      "title": "Test assessment-5",
      "desc": "Testing",
      "percent": 50,
      "published": false,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 4,
      "title": "Test assessment-4",
      "desc": "Testing",
      "percent": 60,
      "published": false,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 45,
      "title": "Test assessment-3",
      "desc": "Testing",
      "percent": 70,
      "published": true,
      "test_type": "practice",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 2,
      "title": "Test assessment-2",
      "desc": "Testing",
      "percent": 80,
      "published": true,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    },
    {
      "id": 1,
      "title": "Test assessment-1",
      "desc": "Testing",
      "percent": 90,
      "published": true,
      "test_type": "mandatory",
      "ques_count": 1,
      "due_date": "2021-01-05T18:30:00Z"
    }
  ]
}
//FOR EXTERNAL ASSESSMENTS
export const fetchExternalAssessments = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.fetchExternalAssessments}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack(mockAssessmentsData)
    //successCallBack({totalCount:0, results:[]})
  })
}

export const publishAssessment = (payload, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.publishAssessment}${payload.assessment_id}/`
  axios.patch(url,payload)
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    failureCallBack(error)
  })
}

const mockReportPendingData = {
  totalCount: 20,
  results:[
    {
      "id":1,
      "user_name": "Test user-1",
      "status": "yet-to-start",
      "due_date": "2021-01-05T18:30:00Z",
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":2,
      "user_name": "Test user-2",
      "status": "in-progress",
      "due_date": "2021-01-05T18:30:00Z",
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":3,
      "user_name": "Test user-3",
      "status": "yet-to-start",
      "due_date": "2021-01-05T18:30:00Z",
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":1,
      "user_name": "Test user-1",
      "status": "in-progress",
      "due_date": "2021-01-05T18:30:00Z",
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":2,
      "user_name": "Test user-2",
      "status": "in-progress",
      "due_date": "2021-01-05T18:30:00Z",
      "department": {
        "id": 1,
        "name": "Tech"
      }
    }
  ]
}

const mockReportCompletedData = {
  totalCount: 20,
  results:[
    {
      "id":1,
      "user_name": "Test user-1",
      "status": "completed",
      "due_date": "2021-01-05T18:30:00Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "score_percent": 80,
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":2,
      "user_name": "Test user-2",
      "status": "completed",
      "due_date": "2021-01-05T18:30:00Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "score_percent": 75,
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":3,
      "user_name": "Test user-3",
      "status": "completed",
      "due_date": "2021-01-05T18:30:00Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "score_percent": 70,
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":1,
      "user_name": "Test user-1",
      "status": "completed",
      "due_date": "2021-01-05T18:30:00Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "score_percent": 50,
      "department": {
        "id": 1,
        "name": "Tech"
      }
    },
    {
      "id":2,
      "user_name": "Test user-2",
      "status": "completed",
      "due_date": "2021-01-05T18:30:00Z",
      "completed_date": "2021-01-05T18:30:00Z",
      "score_percent": 50,
      "department": {
        "id": 1,
        "name": "Tech"
      }
    }
  ]
}


export const fetchAssessmentReport = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.fetchAssessmentReport}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    if(payLoad.type === 'pending'){
      successCallBack(mockReportPendingData)
    }else{
      successCallBack(mockReportCompletedData)
    }
    //successCallBack({totalCount:0, results:[]})
  })
}

export const fetchAssRptTabCount = (payLoad, successCallBack, failureCallBack) => {
  const url =`${apiConfig.assessment.fetchAssRptTabCount}`
  axios.get(url, {
    params: payLoad
  })
  .then(function (response) {
    successCallBack(response.data)
  })
  .catch(function (error) {
    //failureCallBack(error)
    successCallBack({pending:13, completed:20})
  })
}
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import './custom.css'
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import Tooltip from '@material-ui/core/Tooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        height: '42px',
        backgroundColor: '#F5F5F5',
        marginBottom: '10px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        marginTop: '-24px',
        color: 'black'
    },
    iconWrapper: {
        marginTop: '-24px'
    },
    buttonWrapper: {
        float: 'right'
    }
}));

function TextEditor(props) {
    const classes = useStyles();
    const helperText = props.helperText
    const [openDialog, setOpenDialog] = React.useState(false)
    const data = props.description ? props.description : null
    const [defaultData, setDefaultData] = React.useState(data)
    const onChangeData = data => {
        setDefaultData(data)
    }

    const changeTextEditorView = () => {
        setOpenDialog(true)
    }
    const closeTextEditorView = () => {
        setOpenDialog(false)
    }
    const textEditor = () => {
        return (
            <CKEditor
                editor={DecoupledEditor}
                data={defaultData}
                onInit={editor => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChangeData(data)
                    props.onChangeEditorContent(data)
                }}
                config={{
                    placeholder: helperText ? helperText : 'Add description here',
                    fontColor: {
                        colors: [
                            {
                                color: 'rgb(0, 0, 0)',
                                label: 'Black'
                            },
                            {
                                color: 'rgb(77, 77, 77)',
                                label: 'Dim grey'
                            },
                            {
                                color: 'rgb(153, 153, 153)',
                                label: 'Grey'
                            },
                            {
                                color: 'rgb(230, 230, 230)',
                                label: 'Light grey'
                            },
                            {
                                color: 'rgb(255, 255, 255)',
                                label: 'White',
                                hasBorder: true
                            },
                            {
                                color: 'rgb(255, 0, 0)',
                                label: 'Red'
                            },
                            {
                                color: 'rgb(255, 102, 0)',
                                label: 'Orange'
                            },
                            {
                                color: 'rgb(255, 255, 0)',
                                label: 'Yellow'
                            },
                            {
                                color: 'rgb(102, 255, 51)',
                                label: 'Light green'
                            },
                            {
                                color: 'rgb(0, 153, 0)',
                                label: 'Green'
                            },
                            {
                                color: 'rgb(0, 0, 255)',
                                label: 'Blue'
                            }
                        ]
                    },
                    fontBackgroundColor: {
                        colors: [
                            {
                                color: 'rgb(0, 0, 0)',
                                label: 'Black'
                            },
                            {
                                color: 'rgb(77, 77, 77)',
                                label: 'Dim grey'
                            },
                            {
                                color: 'rgb(153, 153, 153)',
                                label: 'Grey'
                            },
                            {
                                color: 'rgb(230, 230, 230)',
                                label: 'Light grey'
                            },
                            {
                                color: 'rgb(255, 255, 255)',
                                label: 'White',
                                hasBorder: true
                            },
                            {
                                color: 'rgb(255, 0, 0)',
                                label: 'Red'
                            },
                            {
                                color: 'rgb(255, 102, 0)',
                                label: 'Orange'
                            },
                            {
                                color: 'rgb(255, 255, 0)',
                                label: 'Yellow'
                            },
                            {
                                color: 'rgb(102, 255, 51)',
                                label: 'Light green'
                            },
                            {
                                color: 'rgb(0, 153, 0)',
                                label: 'Green'
                            },
                            {
                                color: 'rgb(0, 0, 255)',
                                label: 'Blue'
                            }
                        ]
                    },
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'underline', 'link', 'fontSize', 'fontColor', 'fontBackgroundColor', 'numberedList', 'bulletedList',
                        '|', 'undo', 'redo']
                }}
            >
            </CKEditor>
        )
    }
    return (
        <div>
            <React.Fragment>
                <Dialog id="popupEditor" fullScreen open={openDialog} onClose={closeTextEditorView} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" className={classes.title}>
                                Description
                            </Typography>
                            <IconButton edge="start" className={classes.iconWrapper} onClick={closeTextEditorView} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div>
                        <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                            <p>Rich Text Editor</p>
                        </div>
                        {textEditor()}
                    </div>
                </Dialog>
            </React.Fragment>
            <div id="mainH1Tag" tabIndex="-1" className="accessibility">
                <p>Rich Text Editor</p>
            </div>
            {textEditor()}
            <Tooltip title="Expand Editor">
                <IconButton edge="start" color="primary" className={classes.buttonWrapper} onClick={changeTextEditorView} aria-label="Expand Editor">
                    <AspectRatioIcon style={{ fontSize: 20, color: '#2e8eec' }} />
                </IconButton>
            </Tooltip>
        </div>
    );
}
const mapStateToProps = state => {
    return {}
}

export default connect(
    mapStateToProps, {}
)(TextEditor);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    '& > *': {
      margin: theme.spacing(1)
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function AddDocButton(props) {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={props.onClick}>
      <Fab size="small" color="primary" aria-label="add option" className={classes.margin}>
        <AddIcon />
      </Fab>
    </div>
  );
}

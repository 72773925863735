import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import GetAppIcon from '@material-ui/icons/GetApp';
import Chip from "@material-ui/core/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { style, colorStyles } from '../../Styles/theme';
import MyClassesList from './myClasses';
import { timeDiffCalc } from "../../Helpers/basic";
import { showSnackBar } from "../../store/AlertMessages/actionCreator";
import VimeoPlayer from "../Common/Player/vimeoPlayer";
import { fetchOrgGrpsList } from '../../store/ManageGroups/actionCreator';
import { getMyRecordingsList, fetchAssignedClasses, updateAssignRecList, postAssignRecList, archiveRecording } from '../../store/ZoomClasses/actionCreator';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ padding: '5px' }} p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        margin: '0.3rem'
    },
    tableHeadingCell: {
        padding: '15px',
        fontWeight: '700',
        fontSize: '1rem',
        color: '#777'
    },
    tRec: {
        padding: '10px',
        fontSize: '0.8rem',
    },
    header: {
        textAlign: 'initial',
        fontSize: '1rem',
        fontWeight: 600,
        margin: '0.5rem',
        color: style.fontColour._blue
    },
    headerMob: {
        textAlign: 'center',
        fontSize: '1rem',
        fontWeight: 600,
        margin: '0.5rem',
        color: style.fontColour._blue
    },
    icn: {
        color: '#999',
        margin: '0.5rem',
        '&:hover': {
            color: style.iconColor._color,
            cursor: 'pointer'
        }
    },
    actionIcn: {
        color: '#999',
        '&:hover': {
            color: '#2e8eec'
        }
    },
    titleCtr: {
        marginBottom: '0.5rem'
    },
    filtrCtr: {
        textAlign: 'end',
        display: 'flex',
        marginRight: '1rem'
    },
    filtrCtrMob: {
        textAlign: 'initial',
        display: 'flex',
    },
    btn: {
        flexGrow: 1,
        margin: '0.5rem',
        backgroundColor: '#2e8eec',
        color: '#FFF',
        '&:hover': {
            backgroundColor: '#2e8eec',
        },
    },
    btnDialog: {
        margin: '0.5rem',
        backgroundColor: '#2e8eec',
        color: '#FFF',
        '&:hover': {
            color: '#FFF',
            backgroundColor: '#2e8eec',
        },
    },
    assignedCtr: {
        marginTop: '1rem'
    },
    recBtn: {
        color: '#2e8eec',
        '&:hover': {
            color: '#2e8eec',
        },
    },
    datePicker: {
        border: '1px solid',
        borderRadius: '5px',
        width: 120,
        padding: '0.5rem',
        margin: '0.5rem 0.5rem 0 0'
    },
    selMemChip: {
        color: "#2e8eec",
        marginTop: "0.3rem",
        border: "1px solid #2e8eec",
    },
    membersCntr: {
        maxHeight: "400px",
        overflowY: 'scroll'
    },
    teamsCntr: {
        border: "1px solid #999",
        maxHeight: "400px",
    },
    teamWrapper: {
        margin: "0.5rem",
    },
    teamName: {
        fontSize: "1rem",
        color: "#15528d",
    },
    groupLink: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: '#2e8eec',
        padding: '0.2rem'
    },
    groupLinkDis: {
        color: '#999',
        padding: '0.2rem'
    },
    memCtr: {
        paddingBottom: '1rem'
    },
    progress: {
        textAlign: 'center',
        margin: '1rem',
        color: style.fontColour._blue,
        justifyContent: 'center'
    },
    btnProgress: {
        textAlign: 'center',
        color: style.backgroundColor._white,
        justifyContent: 'center'
    },
    infoCtr: {
        display: 'flex',
        padding: '0.5rem'
    },
    link: {
        cursor: 'pointer',
        color: style.fontColour._blue,
    },
    noRecMsg: {
        margin: '1rem',
    },
    tabCtr: {
        color: colorStyles.tabHeaderFnt
    },
    table: {
        minWidth: '1200px',
        overflowX: 'scroll'
    },
}))

function ClassList(props) {
    const classes = useStyles();
    const oneDay = 24 * 60 * 60 * 1000;
    const scrollView = useMediaQuery('(max-width:600px)');
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [recordingsList, setRecordingsList] = useState([]);
    const [recLoader, setRecLoader] = useState(false);
    const [assgnClsLoader, setAssgnClsLoader] = useState(false);
    const [assignPostLoader, setAssignPostLoader] = useState(false);
    const [meetingsLen, setMeetingsLen] = useState(0);
    let stDate = new Date();
    stDate.setDate(stDate.getDate() - 30);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(new Date());
    const [assignedEndDate, setAssignedEndDate] = useState(new Date());
    const [assignedStartDate, setAssignedStartDate] = useState(stDate);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedGroupMembersList, setSelectedGroupMembersList] = useState([]);
    const [assignedClasses, setAssignedClasses] = useState([]);
    const [selectedRecInfo, setSelectedRecInfo] = useState();
    const [assignedGroups, setAssignedGroups] = useState([]);
    const [value, setValue] = useState(0);
    const [openPreview, setOpenPreview] = useState(false);
    const [playerData, setPlayerData] = useState({});
    const [selRecId, setSelRecId] = useState();
    const [updateFlag, setUpdateFlag] = useState();
    const [nextPageToken, setNextPageToken] = useState();
    const [selectedRecId, setSelectedRecId] = useState();
    const [alertOpen, setAlertOpen] = useState(false);
    const [archLoader, setArchLoader] = useState(false);


    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchMyRecordings();
        fetchGroupDetails();
        fetchAssignedClass();
    }, [])

    const fetchAssignedClass = () => {
        setAssgnClsLoader(true);
        let payload = {
            offset: page,
            recording_start: assignedStartDate,
            recording_end: assignedEndDate,
            number: rowsPerPage
        }
        fetchAssignedClasses(payload, succCB, failCB);
    }

    const succCB = (res) => {
        setAssgnClsLoader(false);
        setAssignedClasses(res && res.data && res.data);
    }

    const failCB = (err) => {
        setAssgnClsLoader(false);
        props.showSnackBar({
            state: false,
            message:
                "Error Fetching Assigned Classes!",
            type: "error",
        });
    }




    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectGroup = (group) => {
        setSelectedGroup(group);
        let groupListVal = groupList;
        let arr = [...selectedGroupMembersList];
        let existingGroupMemberList = [...selectedGroupMembersList];
        if (groupListVal.length > 0) {
            if (group && group.length > 0) {
                group.forEach((item, index) => {
                    let groupObj = groupListVal.find((group) => group.id === item.id);

                    let selObj = existingGroupMemberList.find(
                        (selGrp) => selGrp.id === item.id
                    );
                    if ((selectedGroupMembersList && !selectedGroupMembersList.length) && selObj) {
                        let grpMembers = selObj.group_members;
                        let Obj = {
                            id: item.id,
                            group_title: item.group_title,
                            group_members: grpMembers,
                        };
                        arr.push(Obj);
                    } else if (groupObj) {
                        let groupMembers = groupObj.group_members;
                        let Obj = {}
                        if (arr && arr.length) {
                            const newData = arr.find((data => data.group_title === item.group_title))

                            if (newData && Object.keys(newData) && Object.keys(newData).length) {
                                newData['group_members'] = item.group_members
                            } else {
                                arr.push({ 'group_title': item.group_title, 'group_members': groupMembers })
                            }

                        } else {
                            Obj = { group_title: item.group_title, group_members: groupMembers }
                            arr.push(Obj)
                        }
                    }
                });
                setSelectedGroupMembersList(arr);
            }
            else {
                setSelectedGroupMembersList([])
            }
        }
    }

    const handleTeamMemberDelete = (teamMemberToDelete) => {
        let existingGroupMemberList = [...selectedGroupMembersList];
        if (existingGroupMemberList.length > 0) {
            existingGroupMemberList.forEach((item, index) => {
                let removedMemList = [];
                removedMemList = item.group_members.filter(
                    (member) => member.id !== teamMemberToDelete.id
                );
                item["group_members"] = removedMemList;
            });
            setSelectedGroupMembersList(existingGroupMemberList);
        }
    };

    const onChangeFilterParams = () => {
        fetchMyRecordings();
    }

    const onChangeAssignFilterParams = () => {
        setAssgnClsLoader(true);
        let payload = {
            offset: page,
            number: rowsPerPage,
            recording_start: assignedStartDate,
            recording_end: assignedEndDate
        }
        fetchAssignedClasses(payload, succCB, failCB);

    }

    const fetchMyRecordings = () => {
        setRecLoader(true);
        let payload = {
            page: page,
            offset: rowsPerPage,
            start_date: startDate,
            end_date: endDate,
            nextPageToken: nextPageToken,
        }
        getMyRecordingsList(payload, successCB, failureCB);
    }

    const successCB = (res) => {
        setRecLoader(false);
        if (Object.keys(res.zoom_recording_list).length > 0 && res.zoom_recording_list.hasOwnProperty('meetings')) {
            setRecordingsList(res.zoom_recording_list.meetings)
        } else {
            setRecordingsList([])
        }
        setMeetingsLen(res.zoom_recording_list.total_records)
        setNextPageToken(res.zoom_recording_list.next_page_token)
    }

    const failureCB = () => {
        setRecLoader(false);
        props.showSnackBar({
            state: false,
            message:
                "Error Fetching Recordings!",
            type: "error",
        });
    }

    const fetchGroupDetails = () => {
        fetchOrgGrpsList({}, successCallBack, failureCallBack)
    }

    const successCallBack = (res) => {
        setGroupList(res.data)
    }

    const failureCallBack = (err) => {
        setGroupList([])
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        //fetchMyRecordings();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const clearLists = () => {
        setSelectedGroup([])
        setSelectedGroupMembersList([])
    }

    const assignToGroup = (recordingInfo, type = 'main') => {

        clearLists();
        setSelectedRecInfo(recordingInfo);
        setOpen(true);
        if (type == 'edit') {
            setUpdateFlag(true);
            let fetchedRecording = assignedClasses.find((rec) => rec.id == recordingInfo.id);
            if (fetchedRecording.assigned_to && fetchedRecording.assigned_to.length > 0) {
                setSelectedGroupMembersList(fetchedRecording.assigned_to);
                setSelectedGroup(fetchedRecording.assigned_to);
            }
        } else {
            setUpdateFlag(false);
        }
    }

    const assignRecordingToGroup = () => {
        setAssignPostLoader(true);
        let membersList = selectedGroupMembersList.flatMap((list) => list.group_members)
        let membersIdList = membersList.map(item => item.id);
        let uniqueListId = [...new Set(membersIdList)]
        let payload = {
            id: selectedRecInfo.id,
            membersList: uniqueListId,
            recording_start: selectedRecInfo.recording_start,
            recording_end: selectedRecInfo.recording_end
        }
        postAssignRecList(payload, succPostCallBack, failPostCallBack)
    }

    const succPostCallBack = (res) => {
        setOpen(false);
        props.showSnackBar({
            state: false,
            message:
                "Recording Assigned to Group Successfully!",
            type: "success",
        });
        setAssignPostLoader(false);
        fetchAssignedClass();
    }

    const failPostCallBack = (err) => {
        setAssignPostLoader(false);
        props.showSnackBar({
            state: false,
            message:
                "Not able to Assign Recording to Group. Please Try again Later!",
            type: "error",
        });
    }

    const handleVideoPreview = (recording) => {
        setOpenPreview(true)
        let playerInfo = {
            videoURL: recording.vimeo_url,
            type: "classes",
        };
        setPlayerData(playerInfo);
    }


    const sortMembers = (membersList) => {
        let sortedList = membersList.sort(function (a, b) {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0;
        })
        return sortedList;
    }

    const onChangeStartDate = (date) => {
        setStartDate(date);
        const diffDays = Math.round(Math.abs((endDate - date) / oneDay));
        if (diffDays > 30) {
            let myDate = new Date(date);
            myDate.setDate(myDate.getDate() + 30);
            setEndDate(myDate);
        }
    }

    const onChangeEndDate = (date) => {
        setEndDate(date);
        const diffDays = Math.round(Math.abs((startDate - date) / oneDay));
        if (diffDays > 30) {
            let myDate = new Date(date);
            myDate.setDate(myDate.getDate() - 30);
            setStartDate(myDate);
        }
    }


    const onChangeAssignedStartDate = (date) => {
        setAssignedStartDate(date);
        const diffDays = Math.round(Math.abs((assignedEndDate - date) / oneDay));
        if (diffDays > 30) {
            let myDate = new Date(date);
            myDate.setDate(myDate.getDate() + 30);
            setAssignedEndDate(myDate);
        }
    }

    const onChangeAssignedEndDate = (date) => {
        setAssignedEndDate(date);
        const diffDays = Math.round(Math.abs((assignedStartDate - date) / oneDay));
        if (diffDays > 30) {
            let myDate = new Date(date);
            myDate.setDate(myDate.getDate() - 30);
            setAssignedStartDate(myDate);
        }
    }

    const handleArchiveVideo = (meeting) => {
        setSelectedRecId(meeting.id);
        setAlertOpen(true);
    }

    const archiveRec = () => {
        setArchLoader(true);
        let payload = {
            rec_id: selectedRecId
        }
        archiveRecording(payload, archiveSuccCB, archiveFailCB);
    }

    const archiveSuccCB = (res) => {
        setArchLoader(false);
        fetchAssignedClass();
    }

    const archiveFailCB = (err) => {
        setArchLoader(false);
        setAlertOpen(false);
        props.showSnackBar({
            state: false,
            message:
                "Something went Wrong. Please Try again Later!",
            type: "error",
        });

    }

    const unArchiveRec = (meeting, type) => {
        setOpen(true);
        assignToGroup(meeting, type);
    }

    const renderTeamListChip = (groupList) => {
        let groupsList = sortMembers(groupList);
        if (groupsList.length > 0) {
            let arr = [];
            groupsList.forEach((item, index) => {
                let label = item.name + " - " + item.email;
                let labelName =
                    label.length > 40 ? label.substring(0, 35) + "..." : label;
                labelName = labelName + "-" + item.role
                arr.push(
                    <React.Fragment key={index}>
                        <Chip
                            variant="outlined"
                            label={labelName}
                            deleteIcon={<HighlightOffIcon style={{ color: style.iconColor._color }} />}
                            onDelete={() => {
                                handleTeamMemberDelete(item);
                            }}
                            className={classes.selMemChip}
                        />
                    </React.Fragment>
                );
            });
            return arr;
        }
    };

    const renderAssignedRow = (meeting) => {
        let startTime = new Date(meeting.recording_start)
        let endTime = new Date(meeting.recording_end)
        let duration = timeDiffCalc(endTime, startTime);
        let isArchived = false;
        return (
            <TableRow className={classes.tRow}>
                <TableCell width="30%" align="center">
                    <div className={classes.tRec} style={{ color: isArchived ? "#999" : "#000" }}>{meeting.id}</div>
                </TableCell>
                <TableCell width="15%" align="center">
                    <div className={classes.tRec} style={{ color: isArchived ? "#999" : "#000" }}>{moment(startTime).format("DD-MMM-YYYY")}</div>
                </TableCell>

                <TableCell width="10%" align="center">
                    <IconButton disabled={isArchived} style={{ padding: '2px', borderRadius: '5px', cursor: 'pointer' }} onClick={() => { handleVideoPreview(meeting) }}>
                        <PlayCircleOutlineIcon disabled={isArchived} className={classes.icn} />
                    </IconButton>
                </TableCell>

                <TableCell width="10%" align="center">
                    <div className={classes.tRec} style={{ color: isArchived ? "#999" : "#000" }}>{duration}</div>
                </TableCell>
                <TableCell width="10%" align="center">
                    <div onClick={() => { !isArchived && assignToGroup(meeting, 'edit') }} className={isArchived ? classes.groupLinkDis : classes.groupLink}>{meeting.assigned_to.length} Groups</div>
                </TableCell>
                <TableCell width="10%" align="center">
                    <div style={{ display: 'flex' }}>
                        <IconButton disabled={isArchived} style={{ padding: '2px', borderRadius: '5px', cursor: 'pointer' }} onClick={() => { assignToGroup(meeting, 'edit') }}>
                            <EditIcon size={25} className={classes.icn} />
                        </IconButton>
                        {/* {isArchived ? <Tooltip title="UnArchive"><UnarchiveIcon size={25} className={classes.icn} onClick={() => { unArchiveRec(meeting, 'edit') }} /></Tooltip> : <Tooltip title="Archive"><ArchiveIcon size={25} className={classes.icn} onClick={() => { handleArchiveVideo(meeting) }} /></Tooltip>} */}
                    </div>
                </TableCell>
            </TableRow>)

    }

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{ backgroundColor: colorStyles.tabHeaderBg }} >
                <Tabs
                    variant="fullWidth"
                    value={value}
                    className={classes.tabCtr}
                    variant={scrollView ? "scrollable" : 'fullWidth'}
                    scrollButtons="auto"
                    indicatorColor="primary"
                    onChange={handleChange}
                    aria-label="simple tabs example">
                    <Tab label="My Classes" {...a11yProps(0)} />
                    <Tab label="Classes Shared with me" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                    <Hidden only={['xs', 'sm', 'md']}>
                        <Grid item lg={1}>

                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                        <Paper elevation={1} className={classes.titleCtr}>
                            <Grid container spacing={2} style={{ margin: '5px' }}>
                                <Grid item xs={4} sm={3} md={4} lg={6} style={{ padding: '1px' }}>
                                    <div className={scrollView ? classes.headerMob : classes.header}>Recent Classes</div>
                                </Grid>
                                <Grid item xs={8} sm={6} md={6} lg={4} style={{ padding: '1px' }}>
                                    <div className={scrollView ? classes.filtrCtrMob : classes.filtrCtr}>
                                        <DatePicker
                                            aria-label="select meeting filter start date"
                                            className={classes.datePicker}
                                            selected={startDate}
                                            maxDate={new Date()}
                                            onChange={date => onChangeStartDate(date)}
                                            dateFormat="MMMM d, yyyy"
                                            placeholderText="Click to select a date"
                                        />
                                        <DatePicker
                                            aria-label="select meeting filter end date"
                                            className={classes.datePicker}
                                            selected={endDate}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            onChange={date => onChangeEndDate(date)}
                                            dateFormat="MMMM d, yyyy"
                                            placeholderText="Click to select a date"
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3} md={2} lg={2} style={{ padding: '1px' }}>
                                    <Button variant="outlined" color="primary" className={classes.btn} onClick={() => { onChangeFilterParams() }} >Search</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        <TableContainer component={Paper}>
                            {recLoader ?
                                <CircularProgress className={classes.progress} /> :
                                <React.Fragment>
                                    {recordingsList && recordingsList.length > 0 ?
                                        <Table className={classes.table} size="small" aria-label="">
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell className={classes.tableHeadingCell} align="center">S.No</TableCell> */}
                                                    <TableCell className={classes.tRtableHeadingCellec} align="center">Meeting Id</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} align="center">Date</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} align="center">Topic</TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <React.Fragment>
                                                    {recordingsList && recordingsList.length && recordingsList.map((item, index) =>
                                                    (<React.Fragment key={index}>
                                                        <RenderNestedRecords index={index} meetingData={item} assignToGroup={assignToGroup} />
                                                    </React.Fragment>)
                                                    )}
                                                </React.Fragment>
                                            </TableBody>
                                        </Table> : <p className={classes.noRecMsg}>No Records Found</p>}
                                </React.Fragment>
                            }
                        </TableContainer>
                        {/* <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={meetingsLen}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> */}
                        <div className={classes.assignedCtr} >
                            <Paper elevation={1} className={classes.titleCtr}>
                                <Grid container spacing={2} style={{ margin: '5px' }}>
                                    <Grid item xs={4} sm={3} md={4} lg={6} style={{ padding: '1px' }}>
                                        <div className={scrollView ? classes.headerMob : classes.header}>Assigned Classes</div>
                                    </Grid>
                                    <Grid item xs={8} sm={6} md={6} lg={4} style={{ padding: '1px' }}>
                                        <div className={scrollView ? classes.filtrCtrMob : classes.filtrCtr}>
                                            <DatePicker
                                                aria-label="select meeting filter start date"
                                                className={classes.datePicker}
                                                selected={assignedStartDate}
                                                maxDate={new Date()}
                                                onChange={date => onChangeAssignedStartDate(date)}
                                                dateFormat="MMMM d, yyyy"
                                                placeholderText="Click to select a date"
                                            />
                                            <DatePicker
                                                aria-label="select meeting filter end date"
                                                className={classes.datePicker}
                                                selected={assignedEndDate}
                                                minDate={assignedStartDate}
                                                maxDate={new Date()}
                                                onChange={date => onChangeAssignedEndDate(date)}
                                                dateFormat="MMMM d, yyyy"
                                                placeholderText="Click to select a date"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2} lg={2} style={{ padding: '1px' }}>
                                        <Button variant="outlined" color="primary" onClick={() => { onChangeAssignFilterParams() }} className={classes.btn}>Search</Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <TableContainer component={Paper}>
                                {assgnClsLoader ? <CircularProgress className={classes.progress} /> :
                                    <React.Fragment>
                                        {assignedClasses && assignedClasses.length > 0 ? <Table className={classes.table} size="small" aria-label="">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tableHeadingCell} width="30%" align="center">Recording Id</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} width="10%" align="center">Date</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} width="10%" align="center">Play</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} width="10%" align="center">Duration</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} width="10%" align="center">Groups</TableCell>
                                                    <TableCell className={classes.tableHeadingCell} width="10%" align="center">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {assignedClasses && assignedClasses.length > 0 && assignedClasses.map((item, index) =>
                                                (<React.Fragment key={index}>
                                                    {renderAssignedRow(item, index)}
                                                </React.Fragment>)
                                                )}
                                            </TableBody>
                                        </Table> : <p className={classes.noRecMsg}>No Records Found</p>} </React.Fragment>}
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={assignedClasses && assignedClasses.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Grid>
                    <Hidden only={['xs', 'sm', 'md']}>
                        <Grid item lg={1}>

                        </Grid>
                    </Hidden>
                </Grid>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <MyClassesList groupDetails={groupList} />
            </TabPanel>

            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Assign Groups</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <div className={classes.autoCompWrapper}>
                                <div className={classes.recInfoCtr}>
                                    <div className={classes.infoCtr}>
                                        Recording ID: {selectedRecInfo && selectedRecInfo.id}
                                    </div>
                                    {!updateFlag && <div className={classes.infoCtr}>
                                        Play URL: {selectedRecInfo && <a target='_blank' className={classes.link} href={selectedRecInfo.play_url}> Play Zoom Recording </a>}
                                    </div>}
                                </div>
                                <Autocomplete
                                    value={selectedGroup}
                                    onChange={(event, group) => {
                                        handleSelectGroup(group);
                                    }}
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={groupList && groupList.length > 0 ? groupList : []}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.group_title}
                                    className={classes.memberListAutoSuggest}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8, color: "#2e8eec" }}
                                                checked={selected}
                                            />
                                            {option.group_title}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ color: "red", fontSize: "1rem" }}
                                            variant="outlined"
                                            label="Search for Groups..."
                                        />
                                    )}
                                />
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Grid container spacing={2} style={{ justifyContent: "center" }}>
                                {selectedGroupMembersList.length > 0 && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Paper elevation={3} className={classes.membersCntr}>
                                            <div className={classes.memCtr}>
                                                {selectedGroupMembersList.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {item.group_members.length > 0 && (
                                                                <div className={classes.teamWrapper}>
                                                                    <div className={classes.teamName}>
                                                                        {item.group_title} - ({item.group_members.length} Members)
                                                                    </div>
                                                                    <div>{renderTeamListChip(item.group_members)}</div>
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </Paper>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!updateFlag && <div className={classes.infoCtr}>
                        <div style={{ display: 'flex' }}><InfoOutlinedIcon style={{ fontSize: '0.7rem', color: colorStyles.appTextColor, margin: '0.2rem' }} />  <div style={{ fontSize: '0.8rem', color: colorStyles.appTextColor }}>Assigning a video to groups would take sometime to reflect and play in myAthina Player.</div></div>
                    </div>}
                    <Button className={classes.btnDialog} disabled={assignPostLoader} onClick={() => { assignRecordingToGroup() }} color="primary">
                        {updateFlag ? "Update" : "Assign to Group"}
                        {assignPostLoader ? (
                            <CircularProgress size={25} className={classes.btnProgress} style={{ marginLeft: 10 }} />
                        ) : null}
                    </Button>
                    <Button onClick={handleClose} className={classes.btnDialog} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={openPreview}
                onClose={handleClosePreview}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">Preview</DialogTitle>
                <DialogContent>
                    {playerData.videoURL && <VimeoPlayer data={playerData} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePreview} color="primary">
                        Close
                     </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Archiving would remove video from all the users to whom the video has been already assigned to.
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.btnDialog} onClick={handleCloseAlert} >
                        Cancel
                    </Button>
                    <Button className={classes.btnDialog} onClick={archiveRec} autoFocus>
                        Confirm
                    {archLoader ? (
                            <CircularProgress size={25} className={classes.btnProgress} style={{ marginLeft: 10 }} />
                        ) : null}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const RenderNestedRecords = (props) => {
    let meetingInfo = props.meetingData;
    const classes = useStyles();
    const recordings = meetingInfo.recording_files.filter((item) => item.file_type === "MP4");
    const [rowOpen, setRowOpen] = React.useState(false);

    return (
        <React.Fragment>
            {recordings.length > 0 ? <React.Fragment>
                <TableRow className={classes.tRow}>
                    {/* <TableCell align="center">
                        <div className={classes.tRec}>{props.index + 1}</div>
                    </TableCell> */}
                    <TableCell align="center">
                        <div className={classes.tRec}>{meetingInfo.id}</div>
                    </TableCell>
                    <TableCell align="center">
                        <div className={classes.tRec}>{moment(meetingInfo.start_time).format("DD-MMM-YYYY")}</div>
                    </TableCell>
                    <TableCell align="center">
                        <div className={classes.tRec}>{meetingInfo.topic}</div>
                    </TableCell>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setRowOpen(!rowOpen)}>
                            {rowOpen ? <div style={{ display: 'flex' }}><div>Recordings </div><KeyboardArrowUpIcon /></div> : <div style={{ display: 'flex' }}><div>Recordings</div><KeyboardArrowDownIcon /></div>}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={rowOpen} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Recordings
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Recording Id</TableCell>
                                            <TableCell align="center">Play</TableCell>
                                            <TableCell align="center">Duration</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recordings.length > 0 && recordings.map((item, index) => {
                                            let startTime = new Date(item.recording_start)
                                            let endTime = new Date(item.recording_end)
                                            let duration = timeDiffCalc(endTime, startTime);
                                            return (
                                                <TableRow key={index}>
                                                    {/* <TableCell align="center">
                                                        <div className={classes.tRec}>{moment(meetingInfo.start_time).format("DD-MMM-YYYY")}</div>
                                                    </TableCell> */}
                                                    <TableCell >
                                                        <div className={classes.tRec}>{item.id}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <a href={item.play_url} target="_blank" >
                                                            <PlayCircleOutlineIcon className={classes.icn} />
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <div className={classes.tRec}>{duration}</div>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button variant="outlined" color="primary" className={classes.recBtn} onClick={() => { props.assignToGroup(item, 'main') }}>Assign To Group</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment> : null}
        </React.Fragment>
    )
}



const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, { showSnackBar })(ClassList);
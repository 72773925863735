import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import IconButton from '@material-ui/core/IconButton';
//local imports
import { showSnackBar } from '../../store/AlertMessages/actionCreator'
import TextEditor from '../Common/TextEditor'
import AlertMessages from '../Common/AlertMessages'
import { bytesToSize } from '../../Helpers/basic';
import { createDiscussion, updateDiscussion, createExternalDiscussion, updateExternalDiscussion } from '../../store/Discussions/actionCreator'
import { ERROR_CREATE_DISCUSSION, ERROR_UPDATE_DISCUSSION } from '../Common/Constants/errorMessages';
import { SUCCESS_DISCUSSION_CREATE, SUCCESS_DISCUSSION_UPDATE } from '../Common/Constants/successMessages'
import { style } from '../../Styles/theme'
import { apiConfig } from '../../Configs/apiConfig'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '90px',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 'auto',
      paddingLeft: '100px',
      paddingRight: '100px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    marginTop: '-84px'
  },
  label: {
    marginTop: 15,
    fontSize: '1.5rem'
  },
  field: {
    marginTop: 30,
    backgroundColor: '#f5f5f5'
  },
  erromMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  dateWrapper: {
    marginLeft: '13px',
  },
  datePicker: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
  endDateWrapper: {
    border: '1px solid',
    borderRadius: '5px',
    padding: '16px',
  },
  titleWrapper: {
    fontSize: style.fontSize._heading,
    fontWeight: style.fontWeight._heading,
    color: style.fontColour._blue,
    marginBottom: '11px'
  },
  searchBoxOutline: {
    borderWidth: "1px",
    borderColor: "#2e8eec !important"
  },
  button: {
    flexGrow: 1,
    textTransform: 'none',
    marginRight: '10px',
    backgroundColor: style.fontColour._blue,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: style.fontColour._blue,
    },
  },
  uploadIcon: {
    marginTop: '-3px',
    color: style.fontColour._blue,
    width: 30,
    height: 30,
    verticalAlign: 'bottom',
    cursor: 'pointer'
  },
  uploadedAttachement: {
    width: 62,
    marginLeft: 20,
    color: 'blue',
    border: '2px solid #ccc',
    cursor: 'pointer'
  },
  errorMsg: {
    color: 'red',
    marginBottom: '-10px'
  },
  uploadedFileWrapper: {
    textDecoration: 'none',
    marginLeft: '10px',
    border: '2px solid #ccc',
  },
  readingsButton: {
    float: 'right',
    marginBottom: '5px',
    marginLeft: '10px',
    color: '#3f51b5'
  },
  helpertext: {
    lineHeight: '6%',
    marginLeft: '-8px',
    fontSize: '11px',
    fontWeight: 500
  },
  uploadLabel: {
    marginTop: '5px'
  }
}))

function AddDiscussion(props) {
  const classes = useStyles();
  const [inProgress, setInProgress] = React.useState(false)

  const defaultTitle = props.discussionDetails && props.discussionDetails.title ? props.discussionDetails.title : ''
  const [discussionTitle, setDiscussionTitle] = React.useState(defaultTitle)

  const defaultDesc = props.discussionDetails && props.discussionDetails.description ? props.discussionDetails.description : ''
  const [discussionDesc, setDiscussionDesc] = React.useState(defaultDesc)

  const defaultThread = props.discussionDetails && props.discussionDetails.threaded_reply ? props.discussionDetails.threaded_reply : false
  const defaultCanseeBefore = props.discussionDetails && props.discussionDetails.preview_reply ? props.discussionDetails.preview_reply : false
  const defaultGrade = props.discussionDetails && props.discussionDetails.graded_reply ? props.discussionDetails.graded_reply : false
  const [selectedOptions, setSelectedOptions] = React.useState({
    allowThread: defaultThread,
    canseeBeforeReply: defaultCanseeBefore,
    graded: defaultGrade
  });

  const defaultPoints = props.discussionDetails && props.discussionDetails.points ? props.discussionDetails.points : ''
  const [discussionPoints, setDiscussionPoints] = React.useState(defaultPoints)

  const defaultStartDate = props.discussionDetails && props.discussionDetails.start_date ? new Date(props.discussionDetails.start_date) : ''
  const [startDate, setStartDate] = React.useState(defaultStartDate);

  const defaulEndDate = props.discussionDetails && props.discussionDetails.end_date ? new Date(props.discussionDetails.end_date) : ''
  const [endDate, setEndDate] = React.useState(defaulEndDate);

  const [attachmentFileDetails, setAttachmentFileDetails] = React.useState({});
  const defaultFile = props.discussionDetails && props.discussionDetails.attachment ? props.discussionDetails.attachment : null
  const [uploadedAttachmentPreview, setUploadedAttachmentPreview] = React.useState(defaultFile)

  const values = {
    discussion_id: props.discussionDetails && props.discussionDetails.id ? props.discussionDetails.id : '',
    discussionTitle: props.discussionDetails && props.discussionDetails.title ? props.discussionDetails.title : '',
    discussionDesc: props.discussionDetails && props.discussionDetails.description ? props.discussionDetails.description : '',
    discussionPoints: props.discussionDetails && props.discussionDetails.points ? props.discussionDetails.points : '',
    discussionStartDate: props.discussionDetails && props.discussionDetails.start_date ? new Date(props.discussionDetails.start_date) : '',
    discussionEndDate: props.discussionDetails && props.discussionDetails.end_date ? new Date(props.discussionDetails.end_date) : '',
    attachment: props.discussionDetails && props.discussionDetails.attachment ? props.discussionDetails.attachment : null
  }
  const [validationError, setValidationError] = React.useState({
    error: false,
    errorMessage: ''
  })

  const [formFieldStatus, setFormFieldStatus] = React.useState(
    {
      discussionTitle: {
        error: false
      },
      discussionDesc: {
        error: false
      },
      discussionPoints: {
        error: false
      },
      startdate: {
        error: false
      },
      enddate: {
        error: false
      }
    }
  );

  const handleChangeTitle = evnt => {
    setDiscussionTitle(evnt.target.value)
  }

  const onChangeEditorContent = data => {
    setDiscussionDesc(data)
  }

  const handleChangeOptions = (event) => {
    setSelectedOptions({ ...selectedOptions, [event.target.name]: event.target.checked });
  };

  const handleChangePoints = event => {
    setDiscussionPoints(event.target.value)
  }

  //atttchment uploads
  const onFileToUploadClick = type => {
    document.getElementById(type).click()
  }

  const validatedFile = type => {
    const selectedFile = document.getElementById(type).files
    let fileDetails = {
      fileError: false,
      fileErrorErrorMessage: ''
    }
    if (!selectedFile.length || selectedFile.length > 1) {
      fileDetails = {
        fileError: false,
        fileErrorErrorMessage: ''
      }
    } else {
      const file = selectedFile[0]
      if (type === 'attachmentfile' && file.size > apiConfig.assignment.acceptedAttachmentFileSize) {
        fileDetails = {
          fileError: true,
          fileErrorErrorMessage: 'File size should be less than 10MB'
        }
      } else {
        const fileFormats = () => {
          if (type === 'attachmentfile') {
            return apiConfig.assignment.acceptedAssignmentFileFormats
          }
        }
        const allowedExtensions = fileFormats()
        const currentExtension = file.name.split('.')[1].toLowerCase()
        if (allowedExtensions.indexOf(currentExtension) === -1) {
          fileDetails = {
            fileError: true,
            fileErrorErrorMessage: 'Invalid File Format'
          }
        } else {
          fileDetails = {
            fileError: false,
            fileErrorErrorMessage: '',
            name: file.name,
            size: bytesToSize(file.size),
            type: file.type,
            file: file
          }
        }
      }
    }
    return fileDetails
  }

  const onAttachmentUpload = event => {
    const attachment = validatedFile('attachmentfile')
    setAttachmentFileDetails(attachment)
    setUploadedAttachmentPreview(event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : null)
  }

  const succsessCreateDisccussion = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: SUCCESS_DISCUSSION_CREATE,
      type: 'success'
    })
    props.cancelAddDiscussion()
    props.listAllDiscussions()
    if (props && props.from === 'externalType') {
      props.fetchTabCounts()
    }
  }

  const failureCreateDiscussion = error => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: ERROR_CREATE_DISCUSSION,
      type: 'error'
    })
  }

  const successUpdateDiscussion = res => {
    setInProgress(false)
    props.showSnackBar({
      state: true,
      message: SUCCESS_DISCUSSION_UPDATE,
      type: 'success'
    })
    props.cancelAddDiscussion()
    props.listAllDiscussions()
  }

  const failureUpdateDiscussion = error => {
    setInProgress(false)
    props.showSnackBar({
      state: false,
      message: ERROR_UPDATE_DISCUSSION,
      type: 'error'
    })
  }

  const initiateDiscussion = event => {
    event.preventDefault()
    let startDateValid = true
    if (startDate) {
      startDateValid = true
    } else if (props.discussionDetails && props.discussionDetails.start_date) {
      startDateValid = true
    }

    let endDateValid = true
    if (endDate) {
      endDateValid = true
    } else if (props.discussionDetails && props.discussionDetails.end_date) {
      endDateValid = true
    }
    if (!discussionTitle) {
      setFormFieldStatus({
        discussionTitle: {
          error: true
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic Title!'
      })
    }
    else if (!discussionDesc) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: true
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic Description!'
      })
    }
    else if (selectedOptions.graded && !discussionPoints) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: true
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic Points!'
      })
    }
    else if ((selectedOptions.graded && isNaN(discussionPoints)) || (selectedOptions.graded && discussionPoints < 1) || (selectedOptions.graded && discussionPoints > 100)) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: true
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Valid Points!'
      })
    }
    else if (props.from !== 'externalType' && !startDate) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: true
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic StartDate!'
      })
    } else if (props.from !== 'externalType' && !startDateValid) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: true
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic StartDate!'
      })
    } else if (props.from !== 'externalType' && !endDate) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: false
        },
        enddate: {
          error: true
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic EndDate!'
      })
    } else if (props.from !== 'externalType' && !endDateValid) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: true
        },
        enddate: {
          error: true
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'Please Add Topic EndDate!'
      })
    } else if (props.from !== 'externalType' && startDate > endDate) {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: true
        },
        enddate: {
          error: true
        }
      })
      setValidationError({
        error: true,
        errorMessage: 'End Date Should Be Greater Than Start Date!'
      })
    }
    else {
      setFormFieldStatus({
        discussionTitle: {
          error: false
        },
        discussionDesc: {
          error: false
        },
        discussionPoints: {
          error: false
        },
        startdate: {
          error: false
        },
        enddate: {
          error: false
        }
      })
      setValidationError({
        error: false,
        errorMessage: ''
      })
      setInProgress(true)
      if (props.discussionDetails) {
        if (props && props.from !== 'externalType') {
          var startDateFormat = new Date(startDate);
          var endDateFormat = new Date(endDate)
          var startDateFrmt = startDateFormat && startDateFormat.toISOString();
          var endDateFrmt = endDateFormat && endDateFormat.toISOString();
          const payload = {
            id: props.discussionDetails && props.discussionDetails.id ? Number(props.discussionDetails.id) : '',
            course_id: props.match.params.courseId ? Number(props.match.params.courseId) : '',
            title: discussionTitle ? discussionTitle : '',
            description: discussionDesc ? discussionDesc : '',
            points: selectedOptions.graded ? Number(discussionPoints) : null,
            threaded_reply: selectedOptions.allowThread,
            preview_reply: selectedOptions.canseeBeforeReply,
            graded_reply: selectedOptions.graded,
            start_date: startDateFrmt,
            end_date: endDateFrmt
          }
          updateDiscussion(payload, successUpdateDiscussion, failureUpdateDiscussion)
        } else {
          const payload = {
            discussion_id: props && props.discussionDetails && props.discussionDetails.id
          }
          let formData = new FormData()
          formData.append('discussion_id', props && props.discussionDetails && props.discussionDetails.id)
          formData.append('title', discussionTitle ? discussionTitle : '')
          formData.append('description', discussionDesc ? discussionDesc : '')
          formData.append('points', selectedOptions.graded ? Number(discussionPoints) : null)
          formData.append('threaded_reply', selectedOptions.allowThread)
          formData.append('preview_reply', selectedOptions.canseeBeforeReply)
          formData.append('graded_reply', selectedOptions.graded)
          formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
          updateExternalDiscussion(payload, formData, successUpdateDiscussion, failureUpdateDiscussion)
        }
      }
      else if (props.from === 'externalType') {
        let formData = new FormData()
        formData.append('title', discussionTitle ? discussionTitle : '')
        formData.append('description', discussionDesc ? discussionDesc : '')
        formData.append('points', selectedOptions.graded ? Number(discussionPoints) : null)
        formData.append('threaded_reply', selectedOptions.allowThread)
        formData.append('preview_reply', selectedOptions.canseeBeforeReply)
        formData.append('graded_reply', selectedOptions.graded)
        formData.append('attachment', attachmentFileDetails.file ? attachmentFileDetails.file : null)
        createExternalDiscussion(formData, succsessCreateDisccussion, failureCreateDiscussion)
      } else {
        var startDateFormat = new Date(startDate);
        var endDateFormat = new Date(endDate)
        var startDateFrmt = startDateFormat && startDateFormat.toISOString();
        var endDateFrmt = endDateFormat && endDateFormat.toISOString();

        const payload = {
          course_id: props.match.params.courseId ? Number(props.match.params.courseId) : '',
          title: discussionTitle ? discussionTitle : '',
          description: discussionDesc ? discussionDesc : '',
          points: selectedOptions.graded ? Number(discussionPoints) : null,
          threaded_reply: selectedOptions.allowThread,
          preview_reply: selectedOptions.canseeBeforeReply,
          graded_reply: selectedOptions.graded,
          start_date: startDateFrmt,
          end_date: endDateFrmt
        }
        createDiscussion(payload, succsessCreateDisccussion, failureCreateDiscussion)
      }
    }
  }

  const closeTrigger = () => {
    props.cancelAddDiscussion()
  }

  return (
    <div className={classes.root}>
      <div id="mainH1Tag" tabIndex="-1" className="accessibility">
        <h1>{props.discussionDetails ? 'Update discussion form' : 'Add Discussion form'}</h1>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paper}>
            <Typography className={classes.titleWrapper} aria-hidden="true">{props && props.discussionDetails ? 'Edit Discussion' : 'Add Discussion'}</Typography>
            {validationError.error ? (
              <div style={{ width: '42%', marginBottom: '18px', display: 'inline-block' }}>
                <AlertMessages status="error" message={validationError.errorMessage} />
              </div>
            ) : null}
            <form noValidate autoComplete="off" onSubmit={initiateDiscussion} >
              <TextField
                id="title"
                placeholder="Topic title"
                helperText="max: 100 chars"
                required
                variant="outlined"
                error={formFieldStatus.discussionTitle.error}
                defaultValue={values.discussionTitle}
                inputProps={{
                  maxLength: 100,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: !formFieldStatus.discussionTitle.error ? classes.searchBoxOutline : ''
                  }
                }}
                autoFocus={true}
                style={{
                  width: '100%'
                }}
                onChange={handleChangeTitle}
              />
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Typography style={{ textAlign: 'initial', fontSize: 'inherit', fontWeight: '300', marginLeft: '3px', marginBottom: '3px' }}>
                  About this Discussion
                  <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>
                </Typography>
                <TextEditor onChangeEditorContent={onChangeEditorContent} helperText={'About discussion'} description={values.discussionDesc} />
              </div>

              <Grid container spacing={2}>
                {props && props.from === 'externalType' ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      {attachmentFileDetails.fileError ? (
                        <Typography variant="caption" display="block" gutterBottom className={classes.errorMsg}>
                          {attachmentFileDetails.fileErrorErrorMessage}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <div style={{ display: 'flex' }}>
                            <span className={classes.uploadLabel} aria-hidden="true">
                              Upload Attachment
                            </span>
                            <IconButton style={{ padding: '2px 8px' }} aria-label="upload attachment" onClick={() => { onFileToUploadClick('attachmentfile') }}>
                              <CloudUploadIcon className={classes.uploadIcon} />
                            </IconButton>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                          <div style={{ display: 'flex', marginTop: '5px', marginLeft: '-17px' }}>
                            <span className={classes.helperText}>(max.size: 10MB, format: pdf, docx, ppt)</span>

                            {attachmentFileDetails.file && attachmentFileDetails.file.name && uploadedAttachmentPreview ? (
                              <a href={uploadedAttachmentPreview} target='_blank' className={classes.uploadedFileWrapper}>
                                {attachmentFileDetails.file.name.length > 25 ? (
                                  attachmentFileDetails.file.name.substr(0, 20) + '....'
                                ) :
                                  attachmentFileDetails.file.name
                                }
                              </a>
                            ) : (
                              <React.Fragment>
                                {props.discussionDetails && values.attachment ? (
                                  <React.Fragment>
                                    <Tooltip title="Attachment">
                                      <a href={values.attachment} target='_blank' edge="start" color="primary" className={classes.readingsButton} aria-label="close">
                                        {values.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'pdf' ? <PictureAsPdfIcon style={{ color: '#2e8eec' }} /> : values.attachment.split('?')[0].split(/\.(?=[^\.]+$)/)[1] === 'ppt' ? <PictureInPictureIcon style={{ color: '#2e8eec' }} /> : <DescriptionIcon style={{ color: '#2e8eec' }} />}
                                      </a>
                                    </Tooltip>
                                  </React.Fragment>
                                ) : null}
                              </React.Fragment>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <input type="file" accept=".ppt,.pdf,.docx" style={{ display: 'none' }} id="attachmentfile" onChange={onAttachmentUpload} />
                  </React.Fragment>
                ) : null}
              </Grid>

              <div style={{ marginTop: '33px', marginBottom: '20px', textAlign: 'initial' }}>
                <Typography style={{ textAlign: 'initial', fontSize: '16px', marginLeft: '3px', marginBottom: '3px' }}>
                  Options
                </Typography>
                <List>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.allowThread}
                          onChange={handleChangeOptions}
                          name="allowThread"
                          style={{ color: '#2e8eec' }}
                        />
                      }
                      label="Allow threaded replies"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.canseeBeforeReply}
                          onChange={handleChangeOptions}
                          name="canseeBeforeReply"
                          style={{ color: '#2e8eec' }}
                        />
                      }
                      label="Users must post before seeing replies"
                    />
                  </ListItem>
                  <ListItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedOptions.graded}
                          onChange={handleChangeOptions}
                          name="graded"
                          style={{ color: '#2e8eec' }}
                        />
                      }
                      label="Graded"
                    />
                  </ListItem>
                </List>
              </div>
              <div style={{ marginTop: '-18px', marginBottom: '20px', textAlign: 'initial', marginLeft: '16px', marginBottom: '44px' }}>
                {selectedOptions.graded ? (
                  <TextField
                    id="assignmentpoints"
                    placeholder="Possible Points"
                    type="number"
                    variant="outlined"
                    helperText="max: 100 points"
                    error={formFieldStatus.discussionPoints.error}
                    required
                    defaultValue={values.discussionPoints}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 3,
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: !formFieldStatus.discussionPoints.error ? classes.searchBoxOutline : ''
                      }
                    }}
                    onChange={handleChangePoints}
                  />
                ) : null}
              </div>
              {props && props.from !== 'externalType' ? (
                <div style={{ marginTop: '33px', marginBottom: '20px', textAlign: 'initial' }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormHelperText >Start Date *</FormHelperText>
                      <DatePicker
                        className={classes.datePicker}
                        selected={startDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        onChange={date => setStartDate(date)}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Click to select a date"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormHelperText >End Date *</FormHelperText>
                      <DatePicker
                        className={classes.endDateWrapper}
                        selected={endDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        onChange={date => setEndDate(date)}
                        minDate={new Date()}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Click to select a date"
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              <Button variant="contained" className={classes.button} onClick={initiateDiscussion} disabled={inProgress}>
                {inProgress ? (
                  <CircularProgress size={30} style={{ marginRight: 10, color: '#2e8eec' }} />
                ) : null}
                {props.discussionDetails ? 'Update Discussion' : 'Create Discussion'}
              </Button>
              <Button variant="contained" style={{ textTransform: 'none' }} disabled={inProgress} onClick={closeTrigger}>Cancel</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div >
  )
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps, { showSnackBar }
)(AddDiscussion);

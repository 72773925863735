import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  infoWrapper: {
    backgroundColor: '#F3983E',
    borderRadius: '6px'
  },
  iconWrapper: {
    opacity: 0.9,
    fontSize: "22px",
    margin: '3px 4px -6px 7px'
  }
}));

export default function AlertDialogSlide(props) {
  const classes = useStyles();
  const mediaWidth = useMediaQuery('(min-width:600px)')

  const open = props.data && props.data.open;
  const userData = props.data && props.data
  return (
    <Grid container>
      <Dialog
        maxWidth={'md'}
        hideBackdrop
        style={{
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1300,
          inset: !mediaWidth ? '90% 0% 0% 4%' : '15% 0% 0% 31%',
          height: '90%',
          width: !mediaWidth ? '92%' : '56%',
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="restrict users popup"
        aria-describedby="for restricting the other user to not to work on same thing"
      >
        <DialogTitle id="restrict users popup" style={{ color: '#2e8eec' }}>
          {`The ${userData.userInfo && userData.userInfo.type && userData.userInfo.type} information page is currently being worked by ${userData.userInfo && userData.userInfo.instructor_name && userData.userInfo.instructor_name}`}
        </DialogTitle>
        <DialogContent>
          <div className={classes.infoWrapper}>
            <DialogContentText id="alert-dialog-slide-description" style={{ color: '#fff', fontWeight: 500, padding: '8px 0' }}>
              <ReportProblemOutlinedIcon className={classes.iconWrapper} />
              Kindly revisit this page later once the update is completed.
          </DialogContentText>
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}